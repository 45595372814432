/* eslint-disable react/no-array-index-key */
// to fix linter error, added this eslint-disable as there is no other unique identifier to apply as key
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

require('./styles.scss');

export default class Breadcrumb extends PureComponent {
    render() {
        const { items } = this.props;

        return items.length === 0 ? (
            <div className="Breadcrumb" />
        ) : (
            <div className="Breadcrumb">
                {items
                    .map((item, index) => [
                        // make all the items except the last into links:
                        index === items.length - 1 ? (
                            <span key={index + item.label}>{item.label}</span>
                        ) : (
                            <Link to={item.path} key={item.path} className="Breadcrumb__link">
                                {item.label}
                            </Link>
                        ),
                    ])
                    // add a separator between the items:
                    .reduce((acc, item, i) => [
                        ...acc,
                        // using the index is good here - before we were using 'item.label' and there were problems with loading state and the label being undefined
                        <span className="Breadcrumb_separator" key={i}>
                            /
                        </span>,
                        ...item,
                    ])}
            </div>
        );
    }
}

Breadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
        }),
    ),
};

Breadcrumb.defaultProps = {
    items: [],
};
