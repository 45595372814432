import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Input, ModalCard, CollapsibleSection } from '../../../../shared';

export default class EditDashboardModal extends PureComponent {
    render() {
        const { open, onClose, dashboard, onSubmit } = this.props;

        return (
            <ModalCard
                open={open}
                onClose={onClose}
                title="Edit Dashboard"
                formOptions={{ onSubmit }}
                primaryButtonOptions={{
                    content: 'Update',
                    id: 'submit',
                }}
            >
                <div className="DashboardList__Modal">
                    <Input id="name" type="text" label="Name" value={dashboard && dashboard.name} required gray />
                    <Input
                        id="description"
                        type="textarea"
                        label="Description"
                        value={dashboard && dashboard.description}
                        gray
                    />
                    <CollapsibleSection title="More Options">
                        {[
                            { id: 'hideCommandBar', label: 'Hide Model Bar' },
                            { id: 'hideDashboardColors', label: 'Hide Data App Colors' },
                            { id: 'hideFilters', label: 'Hide Filters' },
                        ].map((option) => (
                            <Input
                                id={option.id}
                                key={option.id}
                                type="checkbox"
                                label={option.label}
                                inlineLabel
                                labelRight
                                value={
                                    dashboard &&
                                    dashboard.display &&
                                    dashboard.display.hide &&
                                    dashboard.display.hide[option.id]
                                }
                                gray
                            />
                        ))}
                    </CollapsibleSection>
                </div>
            </ModalCard>
        );
    }
}
EditDashboardModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    dashboard: PropTypes.shape({
        loading: PropTypes.bool,
        name: PropTypes.string,
        description: PropTypes.string,
        display: PropTypes.shape({
            hide: PropTypes.shape({
                hideCommandBar: PropTypes.bool,
                hideDashboardColors: PropTypes.bool,
                hideFilters: PropTypes.bool,
            }),
        }),
    }),
};
EditDashboardModal.defaultProps = {
    dashboard: {},
};
