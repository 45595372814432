import moment from 'moment';

export const repeatOptions = [
    { label: 'Never', value: 'never' }, // run this once a year if it's set to NEVER
    { label: 'Minutely', value: 'minutes' },
    { label: 'Hourly', value: 'hours' },
    { label: 'Daily', value: 'days' },
    { label: 'Weekly', value: 'weeks' },
    { label: 'Monthly', value: 'months' },
];
export const weeklyRunScheduleOptions = [
    { label: 'Mondays', value: '0' },
    { label: 'Tuesdays', value: '1' },
    { label: 'Wednesdays', value: '2' },
    { label: 'Thursdays', value: '3' },
    { label: 'Fridays', value: '4' },
    { label: 'Saturdays', value: '5' },
    { label: 'Sundays', value: '6' },
];
export const monthlyRunScheduleOptions = [
    { label: 'First Day of the Month', value: '0' },
    { label: 'Halfway Through the Month', value: '1' },
    { label: 'Last Day of the Month', value: '2' },
];
// do later
export const scheduledRunDescription = (repeat) => {
    switch (repeat.unit) {
        case 'never':
            return 1;
        case 'minutes':
            return 2;
        case 'hours':
            return 3;
        case 'days':
            return 4;
        case 'weeks':
            return 6;
        case 'month':
            return 5;
        default:
            return 7;
    }
};
export const convertTime = (value) => {
    const timeArray = value.split(' ');
    const hasAM = value.endsWith('AM');
    const time = timeArray[0].split(':');
    const hour = hasAM ? 1 * time[0] : 1 * time[0] + 12;
    const minute = 1 * time[1];
    return moment(`1970-01-01 ${hour}:${minute}`).format('HH:mm:ss.SSS');
};

export const formatDate = (value) => moment(value).format('YYYY-MM-DD');
