import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import { Types as modelTypes } from '../../../Models/_models/models/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('dataset', {
    dataRequest: ['id'],
    dataSuccess: ['id', 'data'],
    dataFailure: ['id', 'errors'],
    featuresRequest: ['id'],
    featuresSuccess: ['id', 'features'],
    featuresFailure: ['id', 'errors'],
    logsRequest: ['id'],
    logsSuccess: ['id', 'logs'],
    logsFailure: ['id', 'errors'],
    updateImport: ['id'],
    updateAppend: ['id', 'dataset'],
    confirmDataRequest: ['id'],
    confirmDataSuccess: ['id'],
    confirmDataFailure: ['id', 'errors'],
    deleteJobDataRequest: ['id', 'jobId'],
    deleteJobDataSuccess: ['id', 'jobId'],
    deleteJobDataFailure: ['id', 'errors'],
    updateDeletedCollection: ['id', 'dataset'],
});
export { Creators as Actions, Types };

// ---------------- ACTION HANDLERS ----------------

const crudReducers = makeCrudReducers('dataset', INITIAL_STATE, Schema, Types);

const dataSuccess = (state = INITIAL_STATE, { id, data }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        data,
        loading: false,
    };
    return { ...state, byId, errors: {} };
};

const logsSuccess = (state = INITIAL_STATE, { id, logs }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], logs, loading: false };
    return { ...state, errors: {}, byId };
};

const updateImport = (state = INITIAL_STATE, action) => {
    const byId = { ...state.byId };
    if (byId[action.id]) {
        byId[action.id] = { ...byId[action.id], imported: true };
    }
    return { ...state, byId, hydrated: true, errors: {} };
};

const updateAppend = (state = INITIAL_STATE, action) => {
    const byId = { ...state.byId };
    if (byId[action.id]) {
        byId[action.id] = { ...byId[action.id], ...action.dataset };
    }
    return { ...state, byId, hydrated: true, errors: {} };
};

const updateDeletedCollection = (state = INITIAL_STATE, action) => {
    const byId = { ...state.byId };
    byId[action.id] = action.dataset;
    return { ...state, byId, errors: {} };
};

const updateModelOutputsSuccess = (state, action) => {
    const { datasets } = action.data;
    const byId = { ...state.byId };
    datasets.forEach((dataset) => {
        byId[dataset._id] = { ...byId[action.id], ...dataset };
    });
    return { ...state, byId, hydrated: true, errors: {} };
};

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(['DATA', 'FEATURES', 'LOGS', 'CONFIRM_DATA', 'DELETE_JOB_DATA'], Types, {
    [Types.UPDATE_DELETED_COLLECTION]: updateDeletedCollection,
    [Types.UPDATE_IMPORT]: updateImport,
    [Types.UPDATE_APPEND]: updateAppend,
    [Types.DATA_SUCCESS]: dataSuccess,
    [Types.LOGS_SUCCESS]: logsSuccess,
    [modelTypes.UPDATE_OUTPUTS_SUCCESS]: updateModelOutputsSuccess,
    [modelTypes.CONFIRM_SUCCESS]: updateModelOutputsSuccess,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('datasets'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
