import { types } from './constants';

export function makeInputProps(input) {
    const typeConfig = types[input.display?.inputType] || {};
    const inputType = typeConfig.inputType || input.display?.inputType || 'text';
    return {
        key: input.varName,
        id: input.varName,
        label: input.name,
        type: inputType,
        // validations, // get validations to work
        gray: true,
        value: input.value === undefined ? input.defaultValue : input.value,
        ...(input.display?.config || {}),
        ...typeConfig,
    };
}
