import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ConfirmModal,
    ContextMenu,
    ContextMenuItem,
    ContextSubMenu,
    Icon,
    Input,
    ModalCard,
    Notification,
} from '../../../shared';
import { PermissionModel, ProjectModel } from '../../_models';
import { defaultRoles, defaultRolesText } from '../../_models/permissions/constants';
import RoleDetails from '../../List/modals/RoleDetails';
import EditMembers from '../../List/modals/EditMembers';
import TransferAssetsModal from '../TransferAssetsModal';
import TransferProject from '../TransferProject';
import UserIcon from '../../../Access/Management/User/UserIcon';

require('./styles.scss');

// meant to be rendered inside a ContextMenu
export default function ProjectMenu(props) {
    const { project, open, onClickOut, onTransferAssetModalClose, checkManageOrganizationProjects } = props;

    const [roleModalId, setRoleModalId] = useState(false);
    const [editMembersModal, setEditMembersModal] = useState(false);
    const [transferProjectOpen, setTransferProjectOpen] = useState(false);
    const [renameModalOpen, setRenameModalOpen] = useState(false);
    const [transferAssetsOpen, setTransferAssetsOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) dispatch(PermissionModel.listByProject(project._id));
    }, [dispatch, open, project._id]);

    const publicSharing = Object.values(useSelector((state) => state?.organizations?.byId)).filter(
        (org) => org._id === project.organizationId,
    )[0]?.publicSharing;

    const permissions = useSelector((state) => state.permissions);
    const projectPermissions = Object.values(permissions.byId).filter(
        (permission) => permission.projectId === project._id,
    );

    const stateUsers = useSelector((state) => state.users);
    const myUser = useSelector((state) => state.app.user);
    const myPermission = Object.values(projectPermissions).find((permission) => myUser._id === permission.userId);

    const projectUsers = projectPermissions.map((permission) => stateUsers.byId[permission.userId]).filter(Boolean);
    const myRole =
        checkManageOrganizationProjects && myUser.roles?.manageOrganizationProjects
            ? defaultRoles.full
            : projectPermissions.find((permission) => permission.userId === myUser._id)?.roles;
    let myRoleText = defaultRolesText.custom;
    if (myRole && Object.values(defaultRoles).some((roles) => _.isEqual(roles, myRole))) {
        const defaultRoleEntry = Object.entries(defaultRoles).find((entry) => _.isEqual(entry[1], myRole));
        myRoleText = defaultRolesText[defaultRoleEntry[0]];
    }

    const showTransferOrgs = myUser?.roles?.manageAllOrganizations || myUser?.roles?.manageClientOrganizations;

    return (
        <React.Fragment>
            <ContextMenu open={open} onClickOut={onClickOut}>
                <div className="ProjectMenu">
                    {checkManageOrganizationProjects ? (
                        <ContextSubMenu>
                            <div className="ProjectMenu__item">
                                <div className="ProjectMenu__roleDescriptionsTitle"> Your Role: Admin </div>
                                <div className="ProjectMenu__roleDescription">
                                    This user can edit the project and manage all users in the project
                                </div>
                            </div>
                        </ContextSubMenu>
                    ) : (
                        <ContextSubMenu>
                            <ContextMenuItem
                                id={`roles-${project._id}`}
                                name=""
                                className="ProjectMenu__item"
                                onClick={() => {
                                    setRoleModalId(true);
                                    onClickOut();
                                }}
                            >
                                <div className="ProjectMenu__roleDescriptionsTitle">{`Your Role: ${myRoleText.label}`}</div>
                                <div className="ProjectMenu__roleDescription">{myRoleText.description}</div>
                            </ContextMenuItem>
                        </ContextSubMenu>
                    )}
                    {myRole?.editMembers && (
                        <ContextSubMenu>
                            <ContextMenuItem
                                id="roleID"
                                name=""
                                className="ProjectMenu__item"
                                onClick={() => {
                                    setEditMembersModal(true);
                                    onClickOut();
                                }}
                            >
                                <div className="ProjectMenu__name">Members</div>
                                <div className="ProjectMenu__memberIcons">
                                    {projectUsers.map((user) => (
                                        <UserIcon
                                            key={`userKey-${user?._id}`}
                                            user={user}
                                            firstName={user?.name.first}
                                            lastName={user?.name.last}
                                            userId={user?._id}
                                        />
                                    ))}
                                </div>
                            </ContextMenuItem>
                        </ContextSubMenu>
                    )}
                    {myRole?.editProject && publicSharing && (
                        <ContextSubMenu>
                            <div className="ProjectMenu__public">
                                <Input
                                    type="switch"
                                    id="public"
                                    label="Public Dashboards"
                                    inlineLabel
                                    dark
                                    value={project.public}
                                    onChange={(id, value) =>
                                        dispatch(ProjectModel.update(project._id, { public: value }))
                                    }
                                />
                                {project.public && (
                                    <div className="ProjectMenu__submessage">
                                        Dashboards in this project can be viewed by anyone with the public link.
                                    </div>
                                )}
                            </div>
                        </ContextSubMenu>
                    )}

                    <ContextSubMenu>
                        {myRole?.editProject && (
                            <ContextMenuItem
                                id={`rename-${project._id}`}
                                name="Rename Project"
                                type={<Icon icon="string" size="16px" />}
                                onClick={() => {
                                    setRenameModalOpen(true);
                                    onClickOut();
                                }}
                            />
                        )}
                        {myRole?.editProject && (
                            <ContextMenuItem
                                id={`transfer-${project._id}`}
                                name="Transfer Assets"
                                type={<Icon icon="swap" size="16px" />}
                                onClick={() => {
                                    setTransferAssetsOpen(true);
                                    onClickOut();
                                }}
                            />
                        )}
                        {myRole?.editProject && showTransferOrgs && (
                            <ContextMenuItem
                                id={`Transfer-${project._id}`}
                                name="Transfer Project"
                                type={<Icon icon="swap" size="16px" />}
                                onClick={() => {
                                    setTransferProjectOpen(true);
                                    onClickOut();
                                }}
                            />
                        )}
                        {myPermission && (
                            <ContextMenuItem
                                id={`leave-${project._id}`}
                                name="Leave Project"
                                type={<Icon icon="walking" size="16px" />}
                                onClick={() => {
                                    setLeaveModalOpen(true);
                                    onClickOut();
                                }}
                            />
                        )}
                        {myRole?.editProject && (
                            <ContextMenuItem
                                id={`delete-${project._id}`}
                                name="Delete Project"
                                type={<Icon icon="trash" size="16px" />}
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                    onClickOut();
                                }}
                            />
                        )}
                    </ContextSubMenu>
                </div>
            </ContextMenu>
            {showTransferOrgs && (
                <TransferProject
                    open={transferProjectOpen}
                    onClose={() => setTransferProjectOpen(false)}
                    project={project}
                />
            )}
            <ModalCard
                open={renameModalOpen}
                title="Rename Project"
                onClose={() => setRenameModalOpen(false)}
                formOptions={{
                    onSubmit: (form) => {
                        dispatch(ProjectModel.update(project._id, form));
                        setRenameModalOpen(false);
                    },
                }}
                autofocus
                primaryButtonOptions={{ content: 'Rename Project', id: 'submit' }}
                secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: () => setRenameModalOpen(false) }}
                className="ProjectList__modal"
            >
                <Input type="text" id="name" required label="Project Name" value={project.name} />
            </ModalCard>
            {transferAssetsOpen && (
                <TransferAssetsModal
                    open={transferAssetsOpen}
                    onClose={() => {
                        setTransferAssetsOpen(false);
                        onTransferAssetModalClose();
                    }}
                    project={project}
                />
            )}
            <ModalCard
                className="LeaveProject__modal"
                open={leaveModalOpen}
                title={`Leave ${project?.name}`}
                onClose={() => setLeaveModalOpen(false)}
                formOptions={{
                    onSubmit: () => {
                        dispatch(PermissionModel.destroy(myPermission._id));
                        setLeaveModalOpen(false);
                    },
                }}
                primaryButtonOptions={{ content: 'Leave Project', id: 'submit' }}
                secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: () => setLeaveModalOpen(false) }}
            >
                <Notification
                    noIcon
                    inline
                    message="If you leave this project, you will not be able to access it unless you're added as a member again."
                    status="warn"
                />
            </ModalCard>
            <ConfirmModal
                open={deleteModalOpen}
                title="Delete Project"
                onClose={() => setDeleteModalOpen(false)}
                confirmValue={project.name}
                onSubmit={() => {
                    dispatch(ProjectModel.destroy(project._id));
                    setDeleteModalOpen(false);
                }}
            />
            <RoleDetails
                open={roleModalId}
                title={`Your Role - ${project?.name}`}
                onClose={() => setRoleModalId(false)}
                role={myRole}
                roleName={myRoleText.label}
                key={roleModalId}
            />
            <EditMembers
                open={editMembersModal}
                title={`Edit Members - ${project.name}`}
                onClose={() => {
                    setEditMembersModal(false);
                }}
                projectPermissions={projectPermissions} // pass in permissions of all project members
                allUsers={stateUsers.allIds.map((userId) => stateUsers.byId[userId]).filter((user) => user.active)} // pass in all users (from org?)
                projectUsers={projectUsers} // pass in just project users
                project={project}
                myUserId={myUser._id}
                myRole={myRole}
                allowEditMy={checkManageOrganizationProjects}
            />
        </React.Fragment>
    );
}

ProjectMenu.propTypes = {
    project: PropTypes.shape({
        _id: PropTypes.string,
        organizationId: PropTypes.string,
        name: PropTypes.string,
        public: PropTypes.bool,
    }),
    onClickOut: PropTypes.func,
    open: PropTypes.bool,
    checkManageOrganizationProjects: PropTypes.bool,
    onTransferAssetModalClose: PropTypes.func,
};

ProjectMenu.defaultProps = {
    project: {},
    onClickOut: () => {},
    open: false,
    checkManageOrganizationProjects: false,
    onTransferAssetModalClose: () => {},
};
