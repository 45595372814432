import numeral from 'numeral';
import moment from 'moment/moment';
import _ from 'lodash';

const precisionFormats = {
    year: 'YYYY',
    quarter: 'YYYY Qo',
    month: 'MMMM YYYY',
    day: 'll',
    hour: 'lll', // includes minutes
    minute: 'lll',
    second: 'lll', // doesn't include seconds
    millisecond: 'lll', // doesn't include milliseconds
};

export const getFeatureFormatFunction = (feature) => {
    // returns a value format function for a feature
    switch (feature?.type) {
        case 'date': {
            const format = precisionFormats[feature.precision || 'day'];
            return (value) => {
                if (value !== null && value !== undefined) {
                    // convert absolute times (with time zone) to the browser's time zone
                    if (feature.db?.type === 'timestamp') {
                        return moment.utc(Number(value)).isValid()
                            ? moment.utc(Number(value)).format(format)
                            : moment.utc(String(value)).format(format);
                    }
        
                    return moment(Number(value)).isValid()
                        ? moment(Number(value)).format(format)
                        : moment(String(value)).format(format);
                }
                return null;
            };
        }
        
        case 'time': {
            return (value) => {
                if (value !== null) {
                    // convert absolute times (with time zone) to the browser's time zone
                    if (feature.db?.type === 'timestamp') {
                        return moment.utc(Number(value)).isValid()
                            ? moment.utc(Number(value), 'h:mm a').format('h:mm a')
                            : moment.utc(String(value), 'h:mm a').format('h:mm a');
                    }

                    return moment(Number(value)).isValid()
                        ? moment(Number(value), 'h:mm a').format('h:mm a')
                        : moment(String(value), 'h:mm a').format('h:mm a');
                }
                return null;
            };
        }

        case 'number':
            return (value) => {
                if (value === null || value === undefined || typeof value !== 'number') return value;
        
                if (Object.is(value, -0)) {
                    return (0).toLocaleString(); // Format with commas if necessary
                }
        
                // Handle whole numbers and numbers like 3.0 (which should be displayed as 3)
                if (Number.isInteger(value)) {
                    return value.toLocaleString(); // Format with commas if necessary
                }
        
                // Handle numbers with fractional parts
                const absValue = Math.abs(value);
        
                if (absValue < 1) {
                    // For numbers less than 1 (e.g., 0.00004895, 0.3854)
                    // Keep 3 significant figures
                    return value.toPrecision(3);
                } 

                // For numbers greater than or equal to 1, round to two decimal places and format with commas
                return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            };
                        
        default:
            // category or null
            return (value) => value;
    }
};

export const formatDatasetData = (dataset, features) => {
    const data = dataset?.data?.list;
    if (!data) return [];
    const datasetFeatures = Object.values(features.byId)
        .filter((feature) => feature.dataset?._id === dataset._id && !feature.hidden)
        .filter(Boolean);
    if (!datasetFeatures.length) return [];
    // not memoizing this result because the data is only 20 rows by default
    return data.map((row) =>
        datasetFeatures.reduce((acc, feature) => {
            acc[feature._id] = getFeatureFormatFunction(feature)(row[feature.db.name]);
            return acc;
        }, {}),
    );
};


export const getBinNames = (groupedFeature, serum) => {    
    const max = groupedFeature?.stats?.max;
    const min = groupedFeature?.stats?.min;
    const range = max - min;
    const numberOfBins = serum.numberOfBins || 10;
    
    // Calculate bin size and boundaries
    const binSize = range / (numberOfBins);
    const binBoundaries = Array.from({ length: numberOfBins }, (a, i) => min + i * binSize);

    // Ensure we include the max value in the last bin
    binBoundaries.push(max);

    const binNames = {};
    const numberFormatter = getFeatureFormatFunction({ type: 'number' });

    for (let i = 1; i < binBoundaries.length; i++) {
        binNames[i] = `${numberFormatter(Math.round(binBoundaries[i-1]))} to ${numberFormatter(Math.round(binBoundaries[i]))}`;
    }

    return binNames;
};


export const getImportedDatasets = (datasets) =>
    Object.values(datasets.byId)
        .filter((dataset) => dataset && dataset.imported)
        .sort((a, b) => (moment(a.timeCreated).isBefore(b.timeCreated) ? 1 : -1));

export const getIconFromDatasetType = (type) => {
    switch (type) {
        case 'modelResult':
            return 'brain';
        case 'formData':
            return 'form';
        case 'joined':
            return 'join';
        case 'file':
        default:
            return 'database';
    }
};
