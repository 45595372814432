import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../shared';

export default function BinaryConfig({ binaryText, updateConfig, editable }) {
    const [trueText, setTrueText] = useState(binaryText?.true);
    const [falseText, setFalseText] = useState(binaryText?.false);
    const updateBinaryText = () => updateConfig('binaryText', { true: trueText, false: falseText });
    return (
        <div className="FormQuestion__configInputs">
            <Input
                id="trueText"
                type="text"
                gray
                label="'True' Label"
                value={trueText}
                placeholder="Ex. Agree"
                onChange={(_, value) => setTrueText(value)}
                onSubmit={updateBinaryText}
                submitOnBlur
                disabled={!editable}
            />
            <Input
                id="falseText"
                type="text"
                gray
                label="'False' Label"
                value={falseText}
                placeholder="Ex. Disagree"
                onChange={(_, value) => setFalseText(value)}
                onSubmit={updateBinaryText}
                submitOnBlur
                disabled={!editable}
            />
        </div>
    );
}

BinaryConfig.propTypes = {
    binaryText: PropTypes.shape({ true: PropTypes.string, false: PropTypes.string }),
    updateConfig: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
};

BinaryConfig.defaultProps = {
    binaryText: null,
};
