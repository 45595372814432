import { createReducer } from 'reduxsauce';
import { makeCrudReducers, initialState, apiHandlers, actions } from '../../../../models/base';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('project', {
    clearResources: [],
    listMyRequest: [],
    listMySuccess: ['projects'],
    listMyFailure: ['errors'],
    listOrganizationRequest: ['organizationId'],
    listOrganizationSuccess: ['projects'],
    listOrganizationFailure: ['errors'],
    transferAssetsRequest: [],
    transferAssetsSuccess: ['assets'],
    transferAssetsFailure: ['errors'],
});
export { Creators as Actions, Types };

// ---------------- ACTION HANDLERS ----------------

export const transferAssetsSuccess = (resourceKey) => (state, { assets }) => {
    if (!assets[resourceKey]?.length) return state; // don't spend time on empty arrays
    const byId = { ...state.byId };
    assets[resourceKey].forEach((resourceId) => {
        delete byId[resourceId];
    });
    return { ...state, loading: false, byId, allIds: state.allIds.filter((id) => !assets[resourceKey].includes(id)) };
};

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('project', INITIAL_STATE, {}, Types);

const listSuccess = (state, { projects }) => crudReducers[Types.INDEX_SUCCESS](state, { projects: { list: projects } });

const additionalReducers = apiHandlers(['LIST_MY', 'LIST_ORGANIZATION', 'TRANSFER_ASSETS'], Types, {
    [Types.LIST_MY_SUCCESS]: listSuccess,
    [Types.LIST_ORGANIZATION_SUCCESS]: listSuccess,
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
