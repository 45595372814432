import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, ContextMenuItem, ContextSubMenu, Icon, IconButton } from '../../../shared';

require('./styles.scss');

export default function DetailHeader(props) {
    const { title, icon, menuItems, onBack } = props;
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="DetailHeader">
            <IconButton icon="arrow-left" onClick={onBack} className="DetailHeader__left-arrow" />
            <div className="DetailHeader__header">
                {title}
                <Icon size="25px" icon={icon} />
                <IconButton mini icon="menu-vertical" onClick={() => setMenuOpen(!menuOpen)} active={menuOpen}>
                    <ContextMenu open={menuOpen} onClickOut={() => setMenuOpen(false)}>
                        <ContextSubMenu>
                            {menuItems.map((item) => (
                                <ContextMenuItem
                                    key={item.id || item.name}
                                    {...item}
                                    onClick={() => {
                                        item.onClick();
                                        if (!item.children) {
                                            // If children are given we do not close the context menu
                                            // (this is for the confirm delete tooltip to be used)
                                            setMenuOpen(false);
                                        }
                                    }}
                                />
                            ))}
                        </ContextSubMenu>
                    </ContextMenu>
                </IconButton>
            </div>
        </div>
    );
}

DetailHeader.propTypes = {
    title: PropTypes.node.isRequired,
    icon: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.shape({})),
    onBack: PropTypes.func.isRequired,
};

DetailHeader.defaultProps = {
    icon: 'brain',
    menuItems: [],
};
