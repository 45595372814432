import { Component } from 'react';
import PropTypes from 'prop-types';
import Nes from 'nes';

import actionCreator from './actions';
import DatasetModel from '../../Data/_models/dataset';
// import PackageModel from '../../Models/_models/packages';

export default class Socket extends Component {
    constructor(props) {
        super(props);
        this.client = new Nes.Client(props.url);
        this.client.connect({
            auth: {
                headers: {
                    authorization: localStorage.getItem('accessToken'),
                    projectId: sessionStorage.getItem('projectId'),
                },
            },
        });
        this.client.subscribe(props.subscription, this.handleMessage);
    }

    componentWillUnmount() {
        this.client.disconnect();
    }

    handleMessage = (message) => {
        const { dispatch } = this.props;
        if (['upload', 'featureDetection', 'import', 'append'].includes(message?.type)) {
            dispatch(DatasetModel.updateUploadJob(message.payload));
        }
        dispatch(actionCreator(message));
    };

    render() {
        return null;
    }
}
Socket.propTypes = {
    subscription: PropTypes.string.isRequired,
    options: PropTypes.shape({}),
    dispatch: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

Socket.defaultProps = {
    options: {},
};
