import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DatasetModel } from '../../../../../Data/_models';
import { ModelModel } from '../../../../_models';
import ModelPanel from '../../../../_components/ModelPanel';

import UpdateDataset from './updateMapping';
import { getMergedDatasetUpdates } from './helpers';
import SelectableItem from '../../../../../shared/SelectableItem';

require('./styles.scss');

export default function UpdateOutputs(props) {
    const { model, outputMapping, setOutputMapping } = props;
    const datasets = useSelector((state) => state.datasets);
    const features = useSelector((state) => state.features);

    const [selectedTempDatasetId, setSelectedTempDatasetId] = useState(null);

    const [hasRun, setHasRun] = useState(false);
    const [justRun, setJustRun] = useState(false);
    const [justFetchedRunData, setJustFetchedRunData] = useState(false);

    const [mergedPreviousOutputs, setMergedPreviousOutputs] = useState({});
    const [mergedTempOutputs, setMergedTempOutputs] = useState({});

    const dispatch = useDispatch();
    const runModel = () => {
        dispatch(ModelModel.run(model._id, { temp: true }));
        setJustRun(true);
        setHasRun(true);
    };

    // model run just finished: load & initialize things
    useEffect(() => {
        if (justRun && !model.running && model.tempOutputs) {
            // check if new features are in redux store prior to merge
            const newFeaturesFound = model.tempOutputs.map((tempOutput) =>
                Object.values(features.byId).filter((feature) => feature.dataset._id === tempOutput.dataset._id),
            );
            const newFeaturesCount = newFeaturesFound.map((featureArray) => featureArray.length);
            const allFeaturesAcquired = newFeaturesCount.every((element) => element > 0);
            if (!justFetchedRunData && model.tempOutputs && model.tempOutputs[0] && model.outputs) {
                // get datasets and features right after running
                model.outputs.forEach((output) => {
                    dispatch(DatasetModel.features(output.dataset._id));
                });
                model.tempOutputs.forEach((tempOutput) => {
                    dispatch(DatasetModel.show(tempOutput.dataset._id));
                    dispatch(DatasetModel.features(tempOutput.dataset._id));
                });
                setJustFetchedRunData(true); // only do this once
            } else if (justFetchedRunData && model.outputs && model.tempOutputs && allFeaturesAcquired) {
                // all the features are done being fetched now generate merged data (could probably be done better with a selector)
                setOutputMapping({}); // only matters if you run the model twice
                const newMergedTempOutputs = {};
                model.tempOutputs?.forEach((tempOutput) => {
                    const dataset = { ...datasets.byId[tempOutput.dataset._id] };
                    dataset.features = Object.values(features.byId).filter(
                        (feature) => feature.dataset._id === dataset._id,
                    );
                    newMergedTempOutputs[dataset._id] = dataset;
                });

                if (model.tempOutputs && model.tempOutputs[0]) {
                    setSelectedTempDatasetId(model.tempOutputs[0].dataset._id);
                }
                setMergedTempOutputs(newMergedTempOutputs);

                const newMergedPreviousOutputs = {};
                model.outputs?.forEach((output) => {
                    const dataset = { ...datasets.byId[output.dataset._id] };
                    dataset.features = Object.values(features.byId).filter(
                        (feature) => feature.dataset._id === dataset._id,
                    );
                    newMergedPreviousOutputs[dataset._id] = dataset;
                });
                setMergedPreviousOutputs(newMergedPreviousOutputs);
                // initialize the outputMapping
                setOutputMapping(getMergedDatasetUpdates(newMergedTempOutputs, newMergedPreviousOutputs));
                setJustRun(false);
                setJustFetchedRunData(false);
            }
        }
    }, [
        justRun,
        model.running,
        model.tempOutputs,
        model.outputs,
        justFetchedRunData,
        dispatch,
        datasets.byId,
        setOutputMapping,
        features.byId,
        features,
    ]);
    return (
        <div className="UpdateOutputs">
            <ModelPanel modelId={model._id} noEditLink onRun={runModel} />
            <div className="UpdateOutputs__main">
                {hasRun && outputMapping ? (
                    <React.Fragment>
                        <div className="UpdateOutputs__outputs">
                            {Object.values(outputMapping).map((update) => {
                                const datasetId = update.tempDatasetId || update.previousDatasetId;
                                let label = datasets.byId[datasetId]?.name;
                                if (!update.previousDatasetId) label = `${label} (New)`;
                                if (!update.tempDatasetId) label = `${label} (Removed)`;
                                return (
                                    <SelectableItem
                                        key={datasetId}
                                        name={label}
                                        deactivated={!update.tempDatasetId}
                                        onClick={() =>
                                            update.tempDatasetId && setSelectedTempDatasetId(update.tempDatasetId)
                                        }
                                        selected={selectedTempDatasetId === datasetId}
                                    />
                                );
                            })}
                        </div>
                        {selectedTempDatasetId && mergedTempOutputs[selectedTempDatasetId] && (
                            <UpdateDataset
                                mapping={outputMapping}
                                setMapping={setOutputMapping}
                                datasetId={selectedTempDatasetId}
                                outputDatasets={model.outputs.map((output) => datasets.byId[output.dataset._id])}
                                datasets={datasets}
                                features={features}
                                mergedTempOutputs={mergedTempOutputs}
                                mergedPreviousOutputs={mergedPreviousOutputs}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <div className="UpdateOutputs__run">
                        <b>Run your model</b> to generate updated output data.
                    </div>
                )}
            </div>
        </div>
    );
}

UpdateOutputs.propTypes = {
    model: PropTypes.shape().isRequired,
    outputMapping: PropTypes.shape(),
    setOutputMapping: PropTypes.func.isRequired,
};

UpdateOutputs.defaultProps = {
    outputMapping: null,
};
