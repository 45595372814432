import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalCard, Icon, Input } from '../../../../shared';
import { typeOptions } from '../../../constants';
import FeaturePreview from '../FeaturePreview';
import FeatureModel from '../../../_models/feature';

require('./styles.scss');

export default function ChangeFeatureTypeModal(props) {
    const { open, onClose, activeFeature } = props;
    const dispatch = useDispatch();

    const [updateType, setUpdateType] = useState(activeFeature?.type);

    const updatePreviewType = (type) => {
        setUpdateType(type);
        dispatch(FeatureModel.preview(activeFeature._id, { castedType: type }));
    };

    const updateFeatureType = () => {
        dispatch(FeatureModel.update(activeFeature._id, { type: updateType }));
        onClose();
    };

    const closeModal = () => {
        dispatch(FeatureModel.preview(activeFeature._id));
        setUpdateType(activeFeature?.type);
        onClose();
    };

    return (
        <ModalCard
            open={open}
            title={`Change Feature Type - ${activeFeature.name}`}
            onClose={closeModal}
            primaryButtonOptions={{
                content: 'Change Feature Type',
                onClick: updateFeatureType,
            }}
            secondaryButtonOptions={{ content: 'Cancel', onClick: onClose }}
        >
            <div className="ChangeFeatureTypeModal">
                <Icon icon="exclamation-triangle" size="75px" className="ChangeFeatureTypeModal__warningIcon" />
                <p className="ChangeFeatureTypeModal__warningText">
                    Changing this feature’s type <b>could break existing charts</b> that reference it.
                </p>
                <Input
                    id="type"
                    type="select"
                    placeholder="date"
                    label="Feature Type"
                    options={typeOptions}
                    gray
                    value={updateType}
                    onChange={(_, type) => updatePreviewType(type)}
                />
                <div className="ChangeFeatureTypeModal__preview">
                    <hr />
                    <FeaturePreview activeFeature={{ ...activeFeature, type: updateType }} />
                </div>
            </div>
        </ModalCard>
    );
}

ChangeFeatureTypeModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    activeFeature: PropTypes.shape({
        type: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string,
    }),
};

ChangeFeatureTypeModal.defaultProps = {
    open: false,
    activeFeature: {},
};
