import React, { useState } from 'react';
import Privacy from './Privacy';
import TermsOfUse from './terms-of-use';
import { TextButton, ModalCard, ScrollArea } from '../../../../shared';
import logo from '../../../../../assets/logo-long.svg';
import config from '../../../../../config';

require('./styles.scss');

export default function Footer() {
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    return (
        <footer className="Footer">
            <div>
                <TextButton onClick={() => setPrivacyModalOpen(true)}>Privacy Policy</TextButton>
                <TextButton onClick={() => setTermsModalOpen(true)}>Terms of Use</TextButton>
                <div className="Footer__version">version: {config.app.version}</div>
            </div>
            <div>
                <div className="Footer__support">
                    Want help? Contact <a href="mailto:support@whitewhale.ai">support@whitewhale.ai</a>
                </div>
                <div className="Footer__whiteWhale">
                    <a href="https://www.whitewhale.ai/" target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="White Whale" className="Footer__whiteWhaleLogo" />
                    </a>
                </div>

                <ModalCard
                    open={privacyModalOpen}
                    onClose={() => setPrivacyModalOpen(false)}
                    title="Privacy Policy"
                    primaryButtonOptions={{ content: 'Done', onClick: () => setPrivacyModalOpen(false) }}
                >
                    <ScrollArea className="Footer__modal">
                        <div className="Footer__modalContent">
                            <Privacy />
                        </div>
                    </ScrollArea>
                </ModalCard>

                <ModalCard
                    open={termsModalOpen}
                    onClose={() => setTermsModalOpen(false)}
                    title="Terms of Service"
                    primaryButtonOptions={{ content: 'Done', onClick: () => setTermsModalOpen(false) }}
                >
                    <ScrollArea className="Footer__modal">
                        <div className="Footer__modalContent">
                            <TermsOfUse />
                        </div>
                    </ScrollArea>
                </ModalCard>
            </div>
        </footer>
    );
}
