import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

require('./styles.scss');

export default function ModalOverlay({ open, primaryButtonOptions, secondaryButtonOptions, className, children }) {
    const primaryButton = primaryButtonOptions ? (
        <Button blue {...primaryButtonOptions}>
            {primaryButtonOptions.content}
        </Button>
    ) : null;
    const secondaryButton = secondaryButtonOptions ? (
        <Button gray {...secondaryButtonOptions}>
            {secondaryButtonOptions.content}
        </Button>
    ) : null;

    return !open ? null : (
        <div className={`ModalOverlay ${className}`}>
            <hr />
            <div className="ModalOverlay__content">
                <div className="ModalOverlay__body">{children}</div>
                {(primaryButton || secondaryButton) && (
                    <div className="ModalOverlay__footer">
                        {secondaryButton}
                        {primaryButton}
                    </div>
                )}
            </div>
        </div>
    );
}

ModalOverlay.propTypes = {
    open: PropTypes.bool,
    primaryButtonOptions: PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        id: PropTypes.string,
    }),
    secondaryButtonOptions: PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        disabled: PropTypes.bool,
    }),
    className: PropTypes.string,
    children: PropTypes.node,
};

ModalOverlay.defaultProps = {
    open: false,
    primaryButtonOptions: null,
    secondaryButtonOptions: null,
    className: '',
    children: null,
};
