export const defaultGridProps = {
    containerPadding: [0, 0],
    margin: [20, 20],
    cols: { desktop: 12, mobile: 2 },
    breakpoints: { desktop: 700, mobile: 0 },
    useCSSTransforms: true,
    draggableHandle: '.DashboardGrid__draggableHandle',
    gapFillHeight: 3,
    rowHeight: 100,
    heightUnits: 1,
    isBounded: false,
    editable: false,
    verticalCompact: null,
    autoSize: true, // the container height swells and contracts to fit contents
    preventCollision: null, // grid items won't change position when being dragged over
};
