import React from 'react';
import { useRoutes } from 'react-router-dom';

import ErrorBoundary from '../shared/ErrorBoundary';
import routes from './routes';

require('./styles.scss');

const Models = () => {
    const modelRoutes = useRoutes(routes);
    return (
        <ErrorBoundary>
            <React.Suspense fallback="loading">{modelRoutes}</React.Suspense>
        </ErrorBoundary>
    );
};

Models.propTypes = {};
Models.defaultProps = {};

export default Models;
