import ObjectId from 'bson-objectid';
import { cloneDeep } from 'lodash';

export function updateTableObject(changeId, value, unit) {
    const updateObj = { vis: cloneDeep(unit.vis) };
    let shouldGetData = true;
    switch (changeId) {
        case 'filters':
            updateObj.vis.filters = value;
            shouldGetData = true; // should always getData on filter change
            break;
        case 'tableColumns':
            shouldGetData = value.length > updateObj.vis.table.length; // only get Data when adding a column
            updateObj.vis.table = value.map((serum) => ({
                feature: {
                    _id: serum.feature._id,
                },
                jobId: 'latest',
                id: serum.id || ObjectId().toString(),
            }));
            break;
        default:
            // eslint-disable-next-line no-console
            console.error('I didnt think there was another case', changeId, value);
            return false;
    }
    return { updateObj, shouldGetData };
}
