import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Input, Icon } from '../../../shared';

import './styles.scss';

const JoinedDatasetCreate = (props) => {
    const { createDatasetInfo, onInputChange } = props;
    const { collections } = useSelector((state) => ({
        collections: state.collections,
    }));

    const [name, setName] = useState(createDatasetInfo.name);
    const [collection, setCollection] = useState(createDatasetInfo.collections);
    const [description, setDescription] = useState(createDatasetInfo.description);

    return (
        <div className="JoinedDatasetCreate">
            <header className="JoinedDatasetWorkflow__create">
                <div className="JoinedDatasetWorkflow__nameAndCollection">
                    <Input
                        type="text"
                        id="name"
                        label="Dataset Name"
                        gray
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(_, value) => setName(value)}
                        onBlur={onInputChange}
                    />
                    <Input
                        type="select"
                        id="collections"
                        options={collections.allIds.map((collectionId) => ({
                            label: collections.byId[collectionId].name,
                            value: collectionId,
                        }))}
                        isClearable
                        label="Folder"
                        filterable
                        gray
                        placeholder="Add to Folder"
                        value={collection}
                        onChange={(_, value) => setCollection(value)}
                        onBlur={onInputChange}
                    />
                </div>
                <Input
                    type="text"
                    id="description"
                    placeholder="Edit Description"
                    label="Description"
                    gray
                    className="JoinedDatasetWorkflow__description"
                    value={description}
                    onChange={(_, value) => setDescription(value)}
                    onBlur={onInputChange}
                />
            </header>
            <div className="JoinedDatasetWorkflow__messageIcon">
                <Icon icon="large_color/join-dataset-diagram" size={null} className="JoinedDatasetWorkflow__diagram" />
                Your joined dataset will <strong>combine data from 2 existing datasets.</strong>
            </div>
        </div>
    );
};

JoinedDatasetCreate.propTypes = {
    createDatasetInfo: PropTypes.shape({
        name: PropTypes.string,
        collections: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    onInputChange: PropTypes.func.isRequired,
};

JoinedDatasetCreate.defaultProps = {};

export default JoinedDatasetCreate;
