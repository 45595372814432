import NumberConfig from './numberConfig';
import OptionsConfig from './optionsConfig';

const configTypes = {
    constraint: OptionsConfig,
    number: NumberConfig,
    select: OptionsConfig,
    multiSelect: OptionsConfig,
};

export const getConfigComponent = (input) => configTypes[input?.display?.inputType];
