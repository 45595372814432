import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Socket from '../App/Socket';
import config from '../../config';
import { ProjectModel } from './_models';
import AppModel from '../Access/_models/app';
import { DashboardModel } from '../Dashboards/_models';
import { DatasetModel, FeatureModel } from '../Data/_models';
import { ModelModel, AlgorithmModel, PackageModel } from '../Models/_models';
import FormModel from '../Forms/redux';

import { Loader } from '../shared';

export default function ProjectId() {
    const { projectId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const project = useSelector((state) => state.projects.byId[projectId]);

    useEffect(() => {
        if (projectId && (!app.permission || !project)) {
            dispatch(ProjectModel.show(projectId));
            dispatch(AppModel.getMyProjectPermission(projectId)).then((action) => {
                if (action.type.includes('FAILURE')) navigate('/projects');
            });
            // get all the resources in the project
            dispatch(DashboardModel.index());
            dispatch(DatasetModel.index());
            dispatch(FeatureModel.index());
            dispatch(ModelModel.index());
            dispatch(AlgorithmModel.index());
            dispatch(PackageModel.index());
            dispatch(FormModel.index());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    // storing projectId in session storage so we can send projectId to the backend in every request
    // I'm open to other ways to achieve this, this seems a bit hacky to me
    sessionStorage.setItem('projectId', projectId);

    useEffect(() => {
        if (
            app.permission &&
            (location.pathname === `/projects/${projectId}` || location.pathname === `/projects/${projectId}/`)
        ) {
            if (app.permission.roles.dashboard !== 'hidden')
                navigate(`/projects/${projectId}/dashboards`, { replace: true });
            else if (app.permission.roles.dataset !== 'hidden')
                navigate(`/projects/${projectId}/datasets`, { replace: true });
            else if (app.permission.roles.model !== 'hidden')
                navigate(`/projects/${projectId}/models`, { replace: true });
            else if (app.permission.roles.form !== 'hidden')
                navigate(`/projects/${projectId}/forms`, { replace: true });
            else navigate('/projects', { replace: true });
        }
    }, [location, app.permission, navigate, projectId]);

    if (!app.permission) {
        // don't render project until we have app.permission
        return <Loader type="content" loader="content" />;
    }

    return (
        <React.Fragment>
            <Outlet />
            <Socket
                subscription={`/v1/projects/${projectId}`}
                url={config.app.internalSocketHost}
                dispatch={dispatch}
            />
        </React.Fragment>
    );
}
