import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ContextMenu, ContextMenuItem, ContextSubMenu, Icon, Input } from '../../../../../shared';
import SearchInput from '../../../../../shared/Input/Search';
import MultiSelectInput from '../../../../../shared/Input/MultiSelect/input';
import { fuzzySearch } from '../../../../../../helpers/string';
import UserIcon from '../../../../../Access/Management/User/UserIcon';

require('./styles.scss');

export default function SelectUsers(props) {
    const { onChange, users, value, organizations, selectedOrganization, setSelectedOrganization } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [filterString, setFilterString] = useState('');

    const filteredUsers = fuzzySearch(filterString, users, ['email', 'name.first', 'name.last']);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const handleChange = (val) => onChange('selectMembers', val);

    return (
        <div className="SelectUsers">
            <div className="Input__label">Select Members</div>
            <MultiSelectInput
                id="selectMembers"
                gray
                className="SelectUsers"
                placeholder="Select Members"
                options={users.map((user) => ({ label: `${user.name.first} ${user.name.last}`, value: user._id }))}
                value={value}
                onChange={onChange}
                toggleMenu={toggleMenu}
                menuOpen={menuOpen}
                contextMenu={
                    <ContextMenu className="SelectUsers__contextMenu" open={menuOpen} onClickOut={toggleMenu}>
                        <ContextSubMenu>
                            <header className="SelectUsers__header">
                                <div className="SelectUsers__orgSelect">
                                    Add Users From:
                                    <Input
                                        type="select"
                                        id="organization"
                                        sm
                                        options={organizations.allIds.map((id) => ({
                                            label: organizations.byId[id].name,
                                            value: id,
                                        }))}
                                        dark
                                        value={selectedOrganization._id}
                                        onChange={(_, val) => setSelectedOrganization(val)}
                                    />
                                </div>
                                <SearchInput
                                    id="multiSelectSearch"
                                    type="search"
                                    dark
                                    onChange={(_, filterValue) => setFilterString(filterValue)}
                                    value={filterString}
                                    sm
                                />
                            </header>
                        </ContextSubMenu>
                        <ContextSubMenu>
                            {filteredUsers.length ? (
                                filteredUsers.map((user) => (
                                    <div
                                        className="SelectUsers__userItem"
                                        onClick={() => {
                                            if (value.includes(user._id)) {
                                                handleChange(value.filter((id) => id !== user._id));
                                            } else {
                                                handleChange([...value, user._id]);
                                            }
                                        }}
                                        role="menuitem"
                                        tabIndex={0}
                                    >
                                        <div className="SelectUsers__userItem__col">
                                            <Input type="checkbox" value={value.includes(user._id)} dark />
                                            <UserIcon
                                                user={user}
                                                firstName={user.name.first}
                                                lastName={user.name.last}
                                                userId={user._id}
                                            />
                                            <div>{`${user.name.first} ${user.name.last}`}</div>
                                        </div>
                                        <div>{user.email}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="SelectUsers__noUsers">
                                    <Icon icon="large_color/question-circle" size="60px" />
                                    No available users to share with in this organization.
                                </div>
                            )}
                        </ContextSubMenu>
                        <ContextSubMenu>
                            <ContextMenuItem
                                id="selectAll"
                                name="Select All"
                                onClick={() =>
                                    handleChange([...new Set([...value, ...filteredUsers.map((user) => user._id)])])
                                }
                            />
                            <ContextMenuItem id="clearAll" name="Clear All" onClick={() => handleChange([])} />
                        </ContextSubMenu>
                    </ContextMenu>
                }
            />
        </div>
    );
}

SelectUsers.propTypes = {
    onChange: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.arrayOf(PropTypes.string),
    selectedOrganization: PropTypes.shape({ _id: PropTypes.string }),
    setSelectedOrganization: PropTypes.func.isRequired,
    organizations: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
    }).isRequired,
};

SelectUsers.defaultProps = {
    onChange: () => {},
    value: [],
    users: [],
    selectedOrganization: {},
};
