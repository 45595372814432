import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Form, Input, ScrollArea } from '../../../shared';
import DatasetTable from './table';
import JoinConfig from '../../Add/Join/join';

require('./styles.scss');

export default function DatasetDataTab(props) {
    const { dataset, datasetId, updateDataset } = props;
    const handleSubmit = (form) => updateDataset(datasetId, { description: form.description });
    return (
        <ScrollArea className="DatasetDataTab">
            <Form
                key={datasetId}
                className="DatasetDataTab__details"
                onSubmit={handleSubmit}
                loading={!dataset?.timeCreated}
            >
                <Input
                    gray
                    id="dateCreated"
                    type="text"
                    label="Date Created"
                    value={moment.utc(dataset.timeCreated).local().format('lll')}
                    disabled
                />
                <Input
                    gray
                    id="description"
                    type="text"
                    label="Description"
                    placeholder="Optional"
                    value={dataset?.description}
                    submitOnBlur
                />
            </Form>
            {dataset.type === 'joined' && (
                <JoinConfig
                    joinSetup={{
                        dataset1: dataset.source.from._id,
                        dataset2: dataset.source.join[0]._id,
                        joinList: dataset.source.join[0].on,
                        joinType: dataset.source.join[0].type || 'full',
                    }}
                    updateJoinType={(type) =>
                        updateDataset(datasetId, {
                            source: { ...dataset.source, join: dataset.source.join.map((join) => ({ ...join, type })) },
                        })
                    }
                />
            )}
            <DatasetTable datasetId={datasetId} key={dataset?.source?.join && dataset.source.join[0].type} />
        </ScrollArea>
    );
}

DatasetDataTab.propTypes = {
    dataset: PropTypes.shape({
        description: PropTypes.string,
        timeCreated: PropTypes.string,
        type: PropTypes.string,
        source: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
    datasetId: PropTypes.string.isRequired,
    updateDataset: PropTypes.func.isRequired,
};
