import React from 'react';
import PropTypes from 'prop-types';

import ContextMenu from '../ContextMenu';
import Button from '../Button';

require('./styles.scss');

export default function ConfirmDeleteMenu(props) {
    const { open, position, onClose, onDelete, message } = props;
    return (
        <ContextMenu open={open} position={position} hasTip onClickOut={onClose}>
            <div className="ConfirmDeleteMenu">
                <div>{message}</div>
                <div className="ConfirmDeleteMenu__buttons">
                    <Button dark small onClick={onClose}>
                        Cancel
                    </Button>
                    <Button red small onClick={onDelete}>
                        Delete
                    </Button>
                </div>
            </div>
        </ContextMenu>
    );
}

ConfirmDeleteMenu.propTypes = {
    open: PropTypes.bool,
    position: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    message: PropTypes.string,
};

ConfirmDeleteMenu.defaultProps = {
    open: false,
    position: 'right',
    message: 'Are you sure you want to delete this?',
};
