import React from 'react';
import PropTypes from 'prop-types';

import formatFilterValue from '../helpers';
import { KPI } from '../../../shared';

// text display for a single filter
export default function FilterSummaryItem(props) {
    const { value, features, sm } = props;
    const feature = features.byId[value.feature && value.feature.id] || {};
    return <KPI horizontal={sm} label={feature.name} value={formatFilterValue(value, feature)} />;
}

FilterSummaryItem.propTypes = {
    value: PropTypes.shape({
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
    }),
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
    }),
    sm: PropTypes.bool,
};
FilterSummaryItem.defaultProps = {
    value: {},
    features: { byId: {} },
    sm: false,
};
