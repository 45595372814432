import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ModalCard, WorkflowStepper } from '../../../shared';
import DatasetModel from '../../_models/dataset';
import CreateJoin from './create';
import JoinSetup from './join';

import './styles.scss';

const JoinedDatasetWorkflow = (props) => {
    const { open, onBack, onClose } = props;

    const dispatch = useDispatch();

    const [createDatasetInfo, setCreateDatasetInfo] = useState({
        name: '',
        collections: '',
        description: '',
    });

    const [joinSetup, setJoinSetup] = useState({
        dataset1: null,
        dataset2: null,
        joinList: [[null, null]],
        joinType: 'full',
    });

    const [tabIndex, setTabIndex] = useState(0);

    const updateJoinSetup = (updateObj) => {
        setJoinSetup({ ...joinSetup, ...updateObj });
    };

    const allowSubmit =
        joinSetup.dataset1 && joinSetup.dataset2 && joinSetup.joinList.flat().every((x) => x) && joinSetup.joinType;

    const onSubmit = () => {
        const datasetConfig = {
            name: createDatasetInfo.name,
            type: 'joined',
            source: {
                from: { _id: joinSetup.dataset1 },
                join: [
                    {
                        _id: joinSetup.dataset2,
                        type: joinSetup.joinType,
                        on: joinSetup.joinList,
                    },
                ],
            },
            imported: true,
        };
        if (createDatasetInfo.collections) datasetConfig.collections = [createDatasetInfo.collections];
        if (createDatasetInfo.description) datasetConfig.description = createDatasetInfo.description;
        DatasetModel.create(dispatch, datasetConfig);
        onClose();
    };

    const tabs = [
        {
            label: 'Create Dataset',
            component: (
                <CreateJoin
                    createDatasetInfo={createDatasetInfo}
                    onInputChange={(id, value) => setCreateDatasetInfo({ ...createDatasetInfo, [id]: value })}
                />
            ),
            backButton: { id: 'back', content: 'Back', onClick: onBack },
            forwardButton: { id: 'submit', content: 'Continue', onClick: () => setTabIndex(1) },
        },
        {
            label: 'Join Columns',
            component: <JoinSetup joinSetup={joinSetup} updateJoinSetup={updateJoinSetup} />,
            backButton: { id: 'back', content: 'Back', onClick: () => setTabIndex(0) },
            forwardButton: {
                id: 'submit',
                content: 'Done',
                onClick: onSubmit,
                disabled: !allowSubmit,
            },
        },
    ];

    return (
        <ModalCard title="Create Joined Dataset" open={open} onClose={onClose}>
            <div className="JoinedDatasetWorkflow">{tabs[tabIndex].component}</div>
            <WorkflowStepper tabs={tabs} tabIndex={tabIndex} />
        </ModalCard>
    );
};

JoinedDatasetWorkflow.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onBack: PropTypes.func,
};

JoinedDatasetWorkflow.defaultProps = {
    open: false,
    onBack: () => {},
};

export default JoinedDatasetWorkflow;
