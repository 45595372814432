import Alea from 'alea';
import formatFilterValue from '../../Data/Filter/helpers';
import { getFeatureFormatFunction } from '../../Data/helpers';
import { getColorFromColorScheme } from '../colors';
import { operationsConfig } from './constants';

export const getSerumFormatter = (serum, features) => {
    if (!serum || !serum.feature) return () => '';
    const feature = features.byId[serum.feature._id];
    if (serum.operation && serum.operation !== 'none') {
        const operation = operationsConfig[serum.operation];
        if (operation.formatter) return operation.formatter;
        if (operation.makeFormatter) return operation.makeFormatter(feature, serum);
        return getFeatureFormatFunction(operation.outputType ? { type: operation.outputType } : feature);
    }
    if (!feature) return () => '';
    return getFeatureFormatFunction(feature);
};

export const getSerumDisplayName = (serum, groupSerum, features, options = { showFilter: true }) => {
    const { showFilter } = options;
    const operation = serum.operation ? `${operationsConfig[serum.operation].axisLabel} ` : '';
    const feature = features.byId[serum.feature._id];
    const featureName = feature ? feature.name : '';
    let groupString = '';
    if (serum.group !== undefined && groupSerum && groupSerum[0]) {
        const groupFormatter = getSerumFormatter(groupSerum[0], features);
        groupString = `${groupFormatter(serum.group)}, `;
    }

    let filterString = '';
    if (showFilter && serum.filters && serum.filters.length > 0) {
        filterString = serum.filters
            .map(
                (filter) =>
                    `${features.byId[filter.feature.id].name} is ${formatFilterValue(
                        filter,
                        features.byId[filter.feature.id],
                    )}`,
            )
            .join(', ');
        filterString = ` where ${filterString}`;
    }

    return `${groupString}${operation}${featureName}${filterString}`;
};

export const getOperationsFromFeature = (feature, options = {}) => {
    // used to determine 'operation' dropdown menus on unit/mapping pages
    // label, value are required for the select input
    // axisLabel is used in the default string for the axis labels
    if (!feature?.type) return [{ label: 'none', value: '' }];
    const { type, precision } = feature;
    switch (type) {
        case 'number':
            if (options.aggregation === false) return [operationsConfig[''], operationsConfig.bin];
            return [
                operationsConfig[''],
                operationsConfig.sum,
                operationsConfig.avg,
                operationsConfig.med,
                operationsConfig.max,
                operationsConfig.min,
                operationsConfig.count,
                operationsConfig.distinctCount,
                operationsConfig.bin,
                operationsConfig.list,
            ];
        case 'date':
            if (options.aggregation === false) {
                const operations = [operationsConfig.year];
                if (precision === 'year') return operations;
                operations.push(operationsConfig['year-quarter'], operationsConfig.quarter, operationsConfig.month);
                if (precision === 'month') return operations;
                operations.push(operationsConfig.day, operationsConfig.dow);
                if (precision === 'day') return operations;
                operations.push(operationsConfig.hour);
                return operations;
                // eslint-disable-next-line
            } else {
                // ignoring no-else-return so that I can have a block here (so I can have variables in an switch statement)
                const operations = [
                    operationsConfig[''],
                    operationsConfig.min,
                    operationsConfig.max,
                    operationsConfig.count,
                    operationsConfig.distinctCount,
                    operationsConfig.year,
                ];
                if (precision === 'year') return operations;
                operations.push(
                    operationsConfig['year-quarter'],
                    operationsConfig.quarter,
                    operationsConfig['year-month'],
                    operationsConfig.month,
                );
                if (precision === 'month') return operations;
                operations.push(operationsConfig['month-day'], operationsConfig.day, operationsConfig.dow);
                if (precision === 'day') return operations;
                operations.push(operationsConfig['year-month-day'], operationsConfig.hour);
                if (precision === 'hour') return operations;
                operations.push(operationsConfig['time-of-day']);
                return operations;
            }
        default:
            if (options.aggregation === false) return [operationsConfig['']];
            return [
                operationsConfig[''],
                operationsConfig.count,
                operationsConfig.distinctCount,
                operationsConfig.list
            ];
    }
};

export const getDefaultAxisTitle = (axis, features) => {
    let title = '';
    if (!axis || !axis.series.length) return title;
    axis.series.forEach((serum, i) => {
        if (serum.feature) {
            const feature = features.byId[serum.feature._id];
            if (!feature) return;
            if (i > 0) title = title.concat(' and ');
            if (serum.operation) {
                const operation = operationsConfig[serum.operation];
                if (operation) title = title.concat(`${operation.axisLabel} ${feature.name}`);
            } else if (feature.name) title = title.concat(feature.name);
        }
    });
    return title;
};

export const getSerumType = (serum, features) => {
    if (!serum || !serum.feature) return '';
    const feature = features.byId[serum.feature._id];
    if (!feature) return '';
    if (serum.operation && serum.operation !== 'none' && operationsConfig[serum.operation].outputType)
        return operationsConfig[serum.operation].outputType;
    if (feature.type === 'text') return 'category'; // this will treat charting text the same as categories, mostly
    if (feature.type === 'time') return 'category';
    return feature.type || 'category';
};

export const makeGetSerumColor = (series, colors) => (serum) => {
    // series is unit.vis.dependent.series
    //    eg. [{ id: '123', chartType: 'line', feature: { _id: '123' }, operation: 'sum', name: '' }, ...]
    // colors is the merged object of dashboard.colors and unit.vis.colors
    //    eg { colorScheme: 'spectrumOne', features: { id: { color} }, groups: { group: { color } }, series: { id: { color }} }
    // serum is EITHER a member of data.series
    //    eg. { feature: { _id: '123', name: 'sales', ... }, name: 'y0', group: 'A1', operation: 'sum', serumId: '123' }
    //          OR a member of unit.vis.dependent[0].series
    //    eg. { id: 'serumId', feature: { _id: '123' }, operation: 'sum', chartType: 'line', display: {} }
    // returns a function (serum) => hex
    const groupColor = colors.groups && colors.groups[serum.group];
    if (groupColor) return groupColor;

    if (colors.gradients) {
        if (colors.gradients.High) return colors.gradients.High;
        if (colors.gradients.Low) return colors.gradients.Low;
    }

    const serumId = serum.serumId || serum.id;
    const seriesColor = colors.series && colors.series[serumId];
    if (seriesColor && !serum.group) return seriesColor;

    const featureColor = colors.features && colors.features[serum.feature._id];
    if (featureColor && !serum.group) return featureColor;

    const serumIndex = series.findIndex((serie) => serie.id === serumId);
    const scaleIndex = new Alea(serumId.slice(-4), serum.group || ' ')(); // (str1, str2) => [0,1)
    if (!serum.group || serumIndex === -1 || series.length === 0) {
        // if the data is not grouped, or there is some configuration error, return a consistent number
        return getColorFromColorScheme(colors.colorScheme, scaleIndex);
    }
    // if there are multiple series and the data is grouped, we map the different series to different colors
    // series1 -> [0, 1/3]  series2 -> [1/3,2/3]  series3 -> [2/3, 1]
    // if we don't want this functionality, we can always return a consistent number, as above
    return getColorFromColorScheme(colors.colorScheme, (scaleIndex + serumIndex) / series.length);
};
