import React from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../_components/footer';
import UserSettings from '../_components/UserSettings';

require('./styles.scss');

export default function UserSettingsPage() {
    const params = useParams();
    return (
        <div className="UserSettingsPage">
            <UserSettings userId={params.userId} />
            <Footer />
        </div>
    );
}
