import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Chip, ContextMenu, ContextMenuItem, ContextSubMenu, DataTable, Icon, Input } from '../../../../../shared';
import { formatDatasetData } from '../../../../../Data/helpers';
import { DatasetModel } from '../../../../../Data/_models';

import { getMergedFeatureUpdates } from './helpers';

require('./styles.scss');

export default function UpdateDataset(props) {
    const {
        mapping,
        setMapping,
        datasetId,
        outputDatasets,
        datasets,
        features,
        mergedTempOutputs,
        mergedPreviousOutputs,
    } = props;
    const [tempFeatureMenu, setTempFeatureMenu] = useState(false);

    const previousDatasetId = mapping[datasetId]?.previousDatasetId;

    // get data for previews
    const dispatch = useDispatch();
    useEffect(() => {
        if (!datasets.byId[datasetId]?.loading && !datasets.byId[datasetId]?.data) {
            dispatch(DatasetModel.data(datasetId, { limit: 4 }));
        }
        if (
            previousDatasetId &&
            !datasets.byId[previousDatasetId].loading &&
            (!datasets.byId[previousDatasetId].data || datasets.byId[previousDatasetId].data.list.length > 4)
        ) {
            dispatch(DatasetModel.data(previousDatasetId, { limit: 4 }));
        }
    });
    const updatePreviousDataset = (newPreviousDatasetId) => {
        const newMapping = { ...mapping };
        newMapping[datasetId] = {
            tempDatasetId: datasetId,
            previousDatasetId: newPreviousDatasetId,
            features: getMergedFeatureUpdates(
                mergedTempOutputs[datasetId],
                mergedPreviousOutputs[newPreviousDatasetId],
            ),
        };
        setMapping(newMapping);
    };

    const addPreviousFeature = (tempFeatureId, previousFeatureId) => {
        const newMapping = { ...mapping };
        newMapping[datasetId] = {
            ...newMapping[datasetId],
            features: { ...newMapping[datasetId].features, [tempFeatureId]: { tempFeatureId, previousFeatureId } },
        };
        delete newMapping[datasetId].features[previousFeatureId];
        setMapping(newMapping);
    };

    const unlinkPreviousFeature = (tempFeatureId, previousFeatureId) => {
        const newMapping = { ...mapping };
        newMapping[datasetId] = {
            ...newMapping[datasetId],
            features: {
                ...newMapping[datasetId].features,
                [tempFeatureId]: { tempFeatureId, previousFeatureId: null },
                [previousFeatureId]: { tempFeatureId: null, previousFeatureId },
            },
        };
        setMapping(newMapping);
    };

    return (
        <div className="UpdateDataset">
            <Input
                id="datasetMerge"
                type="select"
                label="Merge Output With"
                options={outputDatasets.map((dataset) => ({
                    value: dataset._id,
                    label: dataset.name,
                }))}
                value={previousDatasetId}
                onChange={(_, value) => updatePreviousDataset(value)}
            />
            {previousDatasetId ? (
                <div className="UpdateDataset__oldData">
                    <div className="UpdateDataset__tableTitle">Old Output</div>
                    <DataTable
                        id="oldDataTable"
                        titles={mergedPreviousOutputs[previousDatasetId].features.reduce(
                            (featureMap, previousFeature) => {
                                const newFeatureMap = { ...featureMap };
                                newFeatureMap[previousFeature._id] = (
                                    <div className="UpdateDataset__tableHeading">
                                        {mapping[datasetId].features[previousFeature._id]
                                            ? `${previousFeature.name} (Deleted)`
                                            : previousFeature.name}
                                    </div>
                                );
                                return newFeatureMap;
                            },
                            {},
                        )}
                        value={formatDatasetData(datasets.byId[previousDatasetId], features)}
                        loading={!datasets.byId[previousDatasetId]?.data?.list}
                    />
                </div>
            ) : (
                <div className="UpdateDataset__oldData--empty">This output will be a new dataset.</div>
            )}
            <div className="UpdateDataset__newData">
                <div className="UpdateDataset__tableTitle">New Output</div>
                <DataTable
                    id="newDataTable"
                    titles={mergedTempOutputs[datasetId].features.reduce((featureMap, tempFeature) => {
                        const newFeatureMap = { ...featureMap };
                        const update = mapping[datasetId].features[tempFeature._id];
                        newFeatureMap[tempFeature._id] = (
                            <div className="UpdateDataset__tableHeading">
                                {update.previousFeatureId !== null
                                    ? `${tempFeature.name} (Linked)`
                                    : `${tempFeature.name} (New)`}
                                {previousDatasetId && (
                                    <Chip onClick={() => setTempFeatureMenu(tempFeature._id)}>
                                        <Icon icon="menu-vertical" size="12px" />
                                        <ContextMenu
                                            open={tempFeatureMenu === tempFeature._id}
                                            onClickOut={() => setTempFeatureMenu(false)}
                                        >
                                            <ContextSubMenu>
                                                {update.previousFeatureId ? (
                                                    <ContextMenuItem
                                                        id={`Unlink-${update.previousFeatureId}`}
                                                        name="Unlink"
                                                        onClick={() => {
                                                            setTempFeatureMenu(false);
                                                            unlinkPreviousFeature(
                                                                tempFeature._id,
                                                                update.previousFeatureId,
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <Input
                                                        type="select"
                                                        id={`Feature-${tempFeature._id}`}
                                                        label="Feature"
                                                        dark
                                                        className="UpdateDataset__linkInput"
                                                        onChange={(_, value) => {
                                                            setTempFeatureMenu(false);
                                                            addPreviousFeature(tempFeature._id, value);
                                                        }}
                                                        options={Object.values(mapping[datasetId].features)
                                                            .filter((featureUpdate) => !featureUpdate.tempFeatureId)
                                                            .map((featureUpdate) => ({
                                                                label:
                                                                    features.byId[featureUpdate.previousFeatureId].name,
                                                                value: featureUpdate.previousFeatureId,
                                                            }))}
                                                    />
                                                )}
                                            </ContextSubMenu>
                                        </ContextMenu>
                                    </Chip>
                                )}
                            </div>
                        );
                        return newFeatureMap;
                    }, {})}
                    value={formatDatasetData(datasets.byId[datasetId], features)}
                    loading={!datasets.byId[datasetId]?.data?.list}
                />
            </div>
        </div>
    );
}

UpdateDataset.propTypes = {
    mapping: PropTypes.shape({}).isRequired,
    setMapping: PropTypes.func.isRequired,
    datasetId: PropTypes.string.isRequired,
    outputDatasets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
        loading: PropTypes.bool,
        data: PropTypes.array,
    }).isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    mergedPreviousOutputs: PropTypes.shape({}).isRequired,
    mergedTempOutputs: PropTypes.shape({}).isRequired,
};

UpdateDataset.defaultProps = {};
