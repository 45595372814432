import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../Projects/_models/projects/reducers';
import { Types as tabTypes } from '../../Dashboards/_models/tabs/reducers';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------
const { Creators, Types } = actions('unit', {
    dataRequest: ['id'],
    dataSuccess: ['id', 'data'],
    dataFailure: ['id', 'errors'],
    updateRequest: ['id', 'unit', 'options'],
    updateSuccess: ['id', 'unit', 'options'],
    downloadRequest: ['id'],
    downloadSuccess: ['id', 'units'],
    downloadFailure: ['id', 'errors'],
    temporaryDataSuccess: ['id', 'data'],
    temporaryDataFailure: ['id', 'errors'],
    removeData: ['id'],
});
export { Creators as Actions, Types };
const crudReducers = makeCrudReducers('unit', INITIAL_STATE, {}, Types);

// ---------------- ACTION HANDLERS ----------------

// data calls set hydrated instead of loading, so cannot use the normal apiHandlers
const dataRequest = (state = INITIAL_STATE, { id }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], hydrated: false };
    return { ...state, byId, errors: {} };
};
const dataSuccess = (state = INITIAL_STATE, { id, data }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        data,
        hydrated: true,
    };
    return { ...state, byId, errors: {} };
};
const dataFailure = (state = INITIAL_STATE, { id }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], hydrated: true };
    delete byId[id].data;
    return { ...state, byId, errors: {}, hydrated: true };
};

const updateRequest = (state, action) => {
    const { id, options } = action;
    const { shouldGetData } = options;
    if (shouldGetData) {
        // for chart loading.  Update the unit on unitSuccess
        const updatedState = crudReducers[Types.UPDATE_REQUEST](state, {
            id,
            unit: { ...state.byId[id].unit, ...action.unit, hydrated: false },
        });
        return updatedState;
    }
    const updatedState = crudReducers[Types.UPDATE_REQUEST](state, action);
    return updatedState;
};

const updateSuccess = (state, action) => {
    const { id, unit, options } = action;
    if (options.shouldGetData) {
        // previously overwrote byId[id] with {...action.unit} - this had some small ui bugs when you change multiple things
        const byId = { ...state.byId, [id]: { ...state.byId[id], data: unit.data, loading: false, hydrated: true } };
        return { ...state, byId };
    }
    return crudReducers[Types.UPDATE_SUCCESS](state, action);
};

const tabShowSuccess = (state = INITIAL_STATE, { tab }) => {
    const { units } = tab;
    return crudReducers[Types.INDEX_SUCCESS](state, {
        units: { list: units.map((unit) => ({ ...unit, hydrated: true })) },
    });
};

const tabDestroyUnitSuccess = (state = INITIAL_STATE, { unitId }) => {
    const allIds = state.allIds.filter((id) => id !== unitId);
    const byId = { ...state.byId };
    delete byId[unitId];
    return { ...state, allIds, byId };
};

const removeData = (state = INITIAL_STATE, { id }) => {
    const byId = { ...state.byId };
    if (byId[id]) {
        delete byId[id].data;
        return { ...state, byId, errors: {} };
    }
    return state;
};

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(['DOWNLOAD'], Types, {
    [Types.TEMPORARY_DATA_SUCCESS]: dataSuccess,
    [Types.TEMPORARY_DATA_FAILURE]: (state) => state,
    [Types.REMOVE_DATA]: removeData,
    [Types.DATA_REQUEST]: dataRequest,
    [Types.DATA_SUCCESS]: dataSuccess,
    [Types.DATA_FAILURE]: dataFailure,
    [Types.UPDATE_REQUEST]: updateRequest,
    [Types.UPDATE_SUCCESS]: updateSuccess,
    [tabTypes.CREATE_UNIT_SUCCESS]: tabShowSuccess,
    [tabTypes.DUPLICATE_UNIT_SUCCESS]: tabShowSuccess,
    [tabTypes.DESTROY_UNIT_SUCCESS]: tabDestroyUnitSuccess,
    [tabTypes.SHOW_SUCCESS]: tabShowSuccess,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('units'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
