import { Actions } from './reducers';
import PackageApiInstance from './api';
import { operations } from '../../../../models/base';

const upload = (form) => (dispatch) => {
    dispatch(Actions.uploadRequest(form));
    return PackageApiInstance.upload(form).then(
        (response) => dispatch(Actions.uploadSuccess(form, response)),
        (errors) => dispatch(Actions.uploadFailure(form, errors)),
    );
};

const update = (id, form) => (dispatch) => {
    dispatch(Actions.updateRequest(id, form));

    return PackageApiInstance.update(id, form)
        .then(
            (response) => {
                dispatch(Actions.updateSuccess(form, response));
            },
            (errors) => {
                dispatch(Actions.updateFailure(form, errors));
            },
        )
        .catch((error) => {
            console.error('Error in update process:', error);
        });
};

const getSourceCode = (id) => (dispatch) => {
    dispatch(Actions.getSourceCodeRequest(id));
    return PackageApiInstance.getSourceCode(id).then(
        (response) => dispatch(Actions.getSourceCodeSuccess(id, response)),
        (errors) => dispatch(Actions.getSourceCodeFailure(id, errors)),
    );
};

const updateCode = (id, fileName, updatedCode) => (dispatch) => {
    const form = { fileName, updatedCode };
    dispatch(Actions.updateCodeRequest(id, form));
    return PackageApiInstance.updateCode(id, form).then(
        (response) => dispatch(Actions.updateCodeSuccess(id, response)),
        (errors) => dispatch(Actions.updateCodeFailure(id, errors)),
    );
};

const getDependencies = (id) => (dispatch) => {
    dispatch(Actions.getDependencies(id));
    return PackageApiInstance.getDependencies(id).then(
        (response) => dispatch(Actions.getDependenciesSuccess(id, response)),
        (errors) => dispatch(Actions.getDependenciesFailure(id, errors)),
    );
};

const updateDependencies = (id, updatedDependencies) => (dispatch) => {
    const form = { updatedDependencies };
    dispatch(Actions.updateDependenciesRequest(id, form));
    return PackageApiInstance.updateDependencies(id, form).then(
        (response) => dispatch(Actions.updateDependenciesSuccess(id, response)),
        (errors) => dispatch(Actions.updateDependenciesFailure(id, errors)),
    );
};

const getEnvironmentVariables = (id) => (dispatch) => {
    dispatch(Actions.updateDependencies(id));
    return PackageApiInstance.getEnv(id).then(
        (response) => dispatch(Actions.getEnvSuccess(id, response)),
        (errors) => dispatch(Actions.getEnvFailure(id, errors)),
    );
};

const updateEnv = (id, updatedEnv) => (dispatch) => {
    const form = { updatedEnv };
    dispatch(Actions.updateEnvRequest(id, form));
    return PackageApiInstance.updateEnv(id, form).then(
        (response) => dispatch(Actions.updateEnvSuccess(id, response)),
        (errors) => dispatch(Actions.updateEnvFailure(id, errors)),
    );
};

const commitPackageImage = (id) => (dispatch) => {
    dispatch(Actions.commitPackageImageRequest(id));
    return PackageApiInstance.commitPackageImage(id).then(
        (response) => dispatch(Actions.commitPackageImageSuccess(id, response)),
        (errors) => dispatch(Actions.commitPackageImageFailure(id, errors)),
    );
};

// PETE: THIS SHOULD GO INTO BASE MODEL
// this action will locally update the store without sending a request to our api
const updateLocal = (id, updates) => Actions.updateLocal(id, updates);

export default operations(Actions, PackageApiInstance, {
    upload,
    update,
    getSourceCode,
    updateCode,
    commitPackageImage,
    updateLocal,
    getDependencies,
    updateDependencies,
    getEnvironmentVariables,
    updateEnv,
});
