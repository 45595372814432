import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import ToolTip from '../ToolTip';

require('./styles.scss');

export default function Chip(props) {
    const { children, className, id, onClick, highlighted, active, red, tabIndex, tooltip } = props;
    const chip = (
        <button
            className={ClassNames('Chip', className, {
                'Chip--highlighted': highlighted,
                'Chip--red': red,
                'Chip--active': active,
            })}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(id, e);
            }}
            type="button"
            tabIndex={tabIndex}
        >
            {children}
        </button>
    );
    return tooltip ? (
        <ToolTip className="Chip__tooltip" delayShow={500} tooltip={tooltip} place="bottom" disable={active}>
            {chip}
        </ToolTip>
    ) : (
        chip
    );
}

Chip.propTypes = {
    highlighted: PropTypes.bool,
    active: PropTypes.bool,
    red: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
    tooltip: PropTypes.node,
};

Chip.defaultProps = {
    highlighted: false,
    active: false,
    red: false,
    id: '',
    tabIndex: 0,
    onClick: () => {},
    className: null,
    children: null,
    tooltip: '',
};
