import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as appTypes } from '../app/reducers';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('user', {
    showLogsRequest: ['id'],
    showLogsSuccess: ['userId', 'logs'],
    showLogsFailure: ['id', 'errors'],
    updateLogoRequest: ['id', 'form'],
    updateLogoSuccess: ['id', 'response'],
    updateLogoFailure: ['id', 'errors'],
    updatePasswordRequest: ['id', 'form'],
    updatePasswordSuccess: ['id', 'user'],
    updatePasswordFailure: ['id', 'errors'],
    updateDetailsRequest: ['userId', 'form'],
    updateDetailsSuccess: ['userId', 'user'],
    updateDetailsFailure: ['userId', 'errors'],
    resendValidationEmailRequest: ['userId'],
    resendValidationEmailSuccess: ['user'],
    resendValidationEmailFailure: ['errors'],
    getOrganizationUsersRequest: ['organizationId'],
    getOrganizationUsersSuccess: ['organizationId', 'users'],
    getOrganizationUsersFailure: ['organizationId', 'errors'],
});
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('user', INITIAL_STATE, {}, Types);

// ---------------- ACTION HANDLERS ----------------

export const updatePasswordRequest = (state = INITIAL_STATE, { id }) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [id]: {
            ...state.byId[id],
            hydrated: false,
        },
    },
    loading: true,
});
export const updatePasswordSuccess = (state = INITIAL_STATE, { id }) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [id]: {
            ...state.byId[id],
            hydrated: true,
        },
    },
    loading: false,
});
export const updatePasswordFailure = (state = INITIAL_STATE, { id }) => ({
    ...state,
    byId: {
        ...state.byId,
        [id]: {
            ...state.byId[id],
            hydrated: true,
        },
    },
    loading: false,
});

export const updateDetailsRequest = (state = INITIAL_STATE, { userId }) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [userId]: {
            ...state.byId[userId],
            hydrated: false,
        },
    },
    loading: true,
});
export const updateDetailsSuccess = (state = INITIAL_STATE, { userId, user }) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [userId]: {
            ...state.byId[userId],
            ...user,
            hydrated: true,
        },
    },
    loading: false,
});
export const updateDetailsFailure = (state = INITIAL_STATE, { userId, errors }) => ({
    ...state,
    byId: {
        ...state.byId,
        [userId]: {
            ...state.byId[userId],
            hydrated: true,
        },
    },
    loading: false,
    errors,
});

export const showLogsSuccess = (state = INITIAL_STATE, { userId, logs }) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [userId]: {
            ...state.byId[userId],
            logs: [...logs],
            hydrated: true,
        },
    },
    loading: false,
});

export const updateLogoSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [action.id]: {
            ...state.byId[action.id],
            errors: {},
            logo: action.response.logo,
            hydrated: true,
        },
    },
});
// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(
    [
        'SHOW_LOGS',
        'UPDATE_PASSWORD',
        'UPDATE_DETAILS',
        'UPDATE_LOGO',
        'GET_ORGANIZATION_USERS',
        'RESEND_VALIDATION_EMAIL',
    ],
    Types,
    {
        [Types.SHOW_LOGS_SUCCESS]: showLogsSuccess,
        [Types.UPDATE_LOGO_SUCCESS]: updateLogoSuccess,
        [Types.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
        [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
        [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
        [Types.UPDATE_DETAILS_REQUEST]: updateDetailsRequest,
        [Types.UPDATE_DETAILS_SUCCESS]: updateDetailsSuccess,
        [Types.UPDATE_DETAILS_FAILURE]: updateDetailsFailure,
        [Types.GET_ORGANIZATION_USERS_SUCCESS]: (state, { users }) =>
            crudReducers[Types.INDEX_SUCCESS](state, { users: { list: users } }),
        [appTypes.GET_MY_USER_SUCCESS]: (state, { user }) =>
            crudReducers[Types.SHOW_SUCCESS](state, { id: user._id, user }),
    },
);
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
