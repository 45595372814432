import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, ModalCard } from '../../../shared';
import DatasetModel from '../../_models/dataset';
import { fileValidations } from './helpers';

import './styles.scss';

const DatasetWorkflow = (props) => {
    const { open, onBack, onClose } = props;
    const collections = useSelector((state) => state.collections);
    const dispatch = useDispatch();

    const onSubmit = (form) => {
        const datasetConfig = {
            name: form.name || form.file.name.split('.').slice(0, -1).join('.'),
            type: 'file',
            source: {
                originalFileName: form.file.name,
                estSize: form.file.size,
            },
        };
        if (form.collections) datasetConfig.collections = [form.collections];
        if (form.description) datasetConfig.description = form.description;
        DatasetModel.create(dispatch, datasetConfig, form.file);
        onClose();
    };
    return (
        <ModalCard title="Upload CSV" open={open} onClose={onClose} formOptions={{ onSubmit }}>
            <div className="DatasetUpload">
                <header className="DatasetUpload__config">
                    <Input type="text" id="name" label="Dataset Name" gray placeholder="Enter Name" />
                    <Input
                        type="select"
                        id="collections"
                        options={collections.allIds.map((collectionId) => ({
                            label: collections.byId[collectionId].name,
                            value: collectionId,
                        }))}
                        isClearable
                        label="Folder"
                        filterable
                        gray
                        placeholder="Add to Folder"
                    />
                    <Input type="text" id="description" placeholder="Edit Description" label="Description" gray />
                </header>
                <hr />
                <Input type="file" id="file" validations={fileValidations} accept=".csv" gray required />
            </div>
            <div className="DatasetUpload__buttons">
                <Button id="back" gray onClick={onBack}>
                    Back
                </Button>
                <Button id="submit" blue>
                    Upload File
                </Button>
            </div>
        </ModalCard>
    );
};

DatasetWorkflow.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onBack: PropTypes.func,
};

DatasetWorkflow.defaultProps = {
    open: false,
    onBack: () => {},
};

export default DatasetWorkflow;
