import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, ContextMenu, ContextSubMenu, ContextMenuItem, Icon } from '../../../shared';

import FilterType from '../Types';
import { getDefaultFilterFromFeature, isFilterValid } from '../helpers';

require('./styles.scss');

export default function FeatureFilterIcon(props) {
    const { feature, onFilterChange, getFeatureCategories } = props;

    const [isFilterOpen, setFilterOpen] = useState(false);
    const [filter, setFilter] = useState(null);
    const [showClear, setShowClear] = useState(false);
    const handleFilterChange = (value) => {
        const newValue = { ...value };
        newValue.valid = isFilterValid(newValue);
        setFilter(newValue);
    };

    const handleFilterOpen = () => {
        if (feature.type === 'category' && !(feature.categories && feature.categories.length)) {
            getFeatureCategories(feature._id);
        }
        if (!filter) {
            setShowClear(false);
            const nextFilter = getDefaultFilterFromFeature(feature);
            nextFilter.valid = isFilterValid(nextFilter);
            setFilter(nextFilter);
        }
        if (filter) {
            setShowClear(true);
        }
        setFilterOpen(true);
    };

    const handleFilterClose = () => {
        setFilterOpen(false);
        if (!filter || (filter && !filter.valid)) {
            setFilter(null);
            onFilterChange(feature._id, null);
        } else {
            onFilterChange(feature._id, filter);
        }
    };

    const handleFilterToggle = () => {
        if (isFilterOpen) handleFilterClose();
        else handleFilterOpen();
    };

    const handleClearFilter = () => {
        if (isFilterOpen) {
            setFilterOpen(false);
        }
        setFilter(null);
        onFilterChange(feature._id, null);
    };

    return (
        <IconButton
            id={feature && feature._id}
            icon="filter"
            onClick={handleFilterToggle}
            selected={!!filter}
            active={isFilterOpen}
        >
            <ContextMenu className="FeatureFilterIcon__menu" open={isFilterOpen} hasTip onClickOut={handleFilterClose}>
                <div className="FeatureFilterIcon__filter">
                    <FilterType
                        value={filter || {}}
                        onChange={handleFilterChange}
                        onSubmit={handleFilterClose}
                        feature={feature}
                        noClickBackIcon
                    />
                </div>
                {showClear ? (
                    <ContextSubMenu>
                        <ContextMenuItem
                            id="clear"
                            name="Clear Filter"
                            type={<Icon size="16px" icon="trash" />}
                            onClick={handleClearFilter}
                        />
                    </ContextSubMenu>
                ) : null}
            </ContextMenu>
        </IconButton>
    );
}

FeatureFilterIcon.propTypes = {
    feature: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        categories: PropTypes.array,
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
    getFeatureCategories: PropTypes.func,
};

FeatureFilterIcon.defaultProps = {
    getFeatureCategories: () => {},
};
