import ApiBase from '../../../../models/base/api';

class TabsApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('tabs', addProjectIdToSearchParams);
    }

    // bulkUpdateCards = (tabId, obj) => this.request('PUT', `${this.endpoint}/${tabId}/cards`, obj);
    // createCard = (tabId, obj) => this.request('POST', `${this.endpoint}/${tabId}/cards`, obj);
    // destroyCard = (tabId, cardId) => this.request('DELETE', `${this.endpoint}/${tabId}/cards/${cardId}`);
    // updateCard = (tabId, cardId, obj) =>
    //     this.request('PUT', `${this.endpoint}/${tabId}/cards/${cardId}`, obj);

    createUnit = (id, obj) => this.request('POST', `${this.endpoint}/${id}/units`, obj);
    duplicateUnit = (id, unitId, obj) => this.request('POST', `${this.endpoint}/${id}/units/${unitId}/duplicate`, obj);
    destroyUnit = (id, unitId) => this.request('DELETE', `${this.endpoint}/${id}/units/${unitId}`);

    createFilter = (tabId, obj) => this.request('POST', `${this.endpoint}/${tabId}/filters`, obj);
    updateFilter = (tabId, filterId, obj) => this.request('PUT', `filters/${filterId}`, obj);
    destroyFilter = (tabId, filterId) => this.request('DELETE', `$filters/${filterId}`);
    destroyAllFilters = (tabId) => this.request('DELETE', `${this.endpoint}/${tabId}/filters`);
}

export default new TabsApi();
