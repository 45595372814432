import { cloneDeep } from 'lodash';

import { getSerumDisplayName } from '../../serum';

export function updateSerum(serum, key, value, features) {
    const clone = cloneDeep(serum);
    if (key === 'operation') {
        clone.operation = value === 'none' ? '' : value;
        return [clone, { shouldGetData: true }];
    }
    if (key === 'label') {
        const isDefaultValue = getSerumDisplayName(serum, null, features) === value;
        clone.label = isDefaultValue ? undefined : value;
        return [clone];
    }
    if (key === 'unitOfMeasure') {
        clone.unitOfMeasure = value;
        return [clone];
    }
    if (key === 'filters') {
        clone.filters = value;
        return [clone, { shouldGetData: true }];
    }
    return false; // bad inputs to this function
}

export function getKpiSettings(vis, features) {
    return vis.kpi.map((serum) => {
        const defaultName = getSerumDisplayName(serum, null, features);
        return {
            key: serum.id,
            name: serum.label || defaultName,
            inputs: [
                {
                    id: `label_${serum.id}`,
                    label: 'Title Text',
                    type: 'text',
                    value: serum.label,
                    placeholder: defaultName,
                },
                {
                    id: `unitOfMeasure_${serum.id}`,
                    label: 'Unit Symbol',
                    type: 'text',
                    value: serum.unitOfMeasure,
                    placeholder: '‘M’, ’$’, etc',
                },
            ],
        };
    });
}
