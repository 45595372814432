import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalCard from '../ModalCard';
import Input from '../../Input';
import Notification from '../../Notification';

require('./styles.scss');

export default function ConfirmModal({ buttonText, confirmValue, message, onSubmit, onClose, open, title }) {
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [errors, setErrors] = useState([]);

    const handleClose = () => {
        setConfirmInputValue('');
        onClose();
    };
    const handleSubmit = () => {
        if (!confirmValue || confirmInputValue === confirmValue) {
            onSubmit();
            handleClose();
        } else {
            setErrors([{ message: 'Does not match' }]);
        }
    };
    const handleChange = (id, value) => {
        setConfirmInputValue(value);
        setErrors([]);
    };

    const primaryButtonOptions = {
        blue: !confirmValue,
        red: !!confirmValue,
        id: 'submit',
        disabled: confirmValue && confirmValue !== confirmInputValue,
        onClick: handleSubmit,
        content: buttonText,
    };

    const secondaryButtonOptions = {
        id: 'close',
        onClick: handleClose,
        content: 'Close',
    };

    return (
        <ModalCard
            open={open}
            onClose={handleClose}
            title={title}
            primaryButtonOptions={primaryButtonOptions}
            secondaryButtonOptions={secondaryButtonOptions}
            className="ConfirmModal"
        >
            <Notification inline status="warn" message={message} />
            {confirmValue && (
                <div className="ConfirmModal__valueConfirmation">
                    <p>
                        Please type in &quot;<strong>{confirmValue}</strong>&quot; to confirm.
                    </p>
                    <Input
                        type="text"
                        id="confirm"
                        onChange={handleChange}
                        value={confirmInputValue}
                        onSubmit={handleSubmit}
                        errors={errors}
                        gray
                    />
                </div>
            )}
        </ModalCard>
    );
}

ConfirmModal.propTypes = {
    buttonText: PropTypes.string,
    confirmValue: PropTypes.string,
    message: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
};

ConfirmModal.defaultProps = {
    buttonText: 'Delete', // The confirm modal is most often used to confirm a delete action.
    confirmValue: null,
    message: 'This action cannot be undone.',
    onSubmit: () => {},
    title: 'Are you ABSOLUTELY sure?',
};
