import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('dashboard', {
    duplicateRequest: ['id'],
    duplicateSuccess: ['id', 'dashboard'],
    duplicateFailure: ['id', 'errors'],
    createTabRequest: ['id', 'name'],
    createTabSuccess: ['id', 'dashboard'],
    createTabFailure: ['id', 'errors'],
    duplicateTabRequest: ['id', 'tabId'],
    duplicateTabSuccess: ['id', 'dashboard'],
    duplicateTabFailure: ['id', 'errors'],
    destroyTabRequest: ['id', 'tabId'],
    destroyTabSuccess: ['id', 'tabId', 'dashboard'],
    destroyTabFailure: ['id', 'tabId', 'errors'],
    updateColorsRequest: ['id', 'colors'],
    updateColorsSuccess: ['id', 'dashboard'],
    updateColorsFailure: ['id', 'errors'],
    updatePreviewRequest: ['id'],
    updatePreviewSuccess: ['id', 'dashboard'],
    updatePreviewFailure: ['id', 'errors'],
});
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('dashboard', INITIAL_STATE, Schema, Types);

// ---------------- ACTION HANDLERS ----------------

const duplicateRequest = (state) => state;

const updatePreviewRequest = (state) => state;

const updateTabs = (state, action) => {
    const { tabs } = action.dashboard;
    const updatedDashboard = {
        ...state.byId[action.id || action._id],
        tabs: tabs.map((tab) => ({ _id: tab._id })),
        loading: false,
    };
    return { ...state, byId: { ...state.byId, [action.id]: updatedDashboard } };
};

const updateColorsRequest = (state, { id, colors }) =>
    crudReducers[Types.UPDATE_REQUEST](state, {
        id,
        dashboard: {
            colors,
        },
    });

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(['CREATE_TAB', 'DESTROY_TAB', 'DUPLICATE_TAB', 'DUPLICATE'], Types, {
    [Types.DUPLICATE_REQUEST]: duplicateRequest,
    [Types.DUPLICATE_SUCCESS]: crudReducers[Types.CREATE_SUCCESS],
    [Types.CREATE_TAB_SUCCESS]: updateTabs,
    [Types.DUPLICATE_TAB_SUCCESS]: updateTabs,
    [Types.DESTROY_TAB_SUCCESS]: updateTabs,
    [Types.UPDATE_COLORS_REQUEST]: updateColorsRequest,
    [Types.UPDATE_COLORS_SUCCESS]: crudReducers[Types.UPDATE_SUCCESS],
    [Types.UPDATE_COLORS_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
    [Types.UPDATE_PREVIEW_REQUEST]: updatePreviewRequest,
    [Types.UPDATE_PREVIEW_SUCCESS]: crudReducers[Types.UPDATE_SUCCESS],
    [Types.UPDATE_PREVIEW_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('dashboards'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
