import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OrganizationModel from '../../../_models/organizations';
import { Input, Button, Breadcrumb, Icon } from '../../../../shared';
import { fuzzySearch } from '../../../../../helpers/string';
import CreateClientOrganizationModal from './CreateClientOrganizationModal';
import OrganizationList from '../../_components/OrganizationList';

require('./styles.scss');

export default function ConsultantOrganizationsList() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [filter, setFilter] = useState('');

    const myOrganizationId = useSelector((state) => state.app?.organization?._id);
    const organizations = useSelector((state) => state.organizations);
    const { hydrated } = organizations;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(OrganizationModel.getClients(myOrganizationId));
    }, [dispatch, myOrganizationId]);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);
    const myClientOrganizations = Object.values(organizations.byId).filter(
        (org) => org.type === 'client' && org.consultantId === myOrganizationId,
    );
    const filteredOrganizations = fuzzySearch(filter, myClientOrganizations, ['name', 'plan']);

    const breadcrumbItems = [{ label: 'Client Organizations' }];

    return (
        <div className="ConsultantOrganizationsList">
            <div className="ConsultantOrganizationsList__header ThreeColumnHeader">
                <Breadcrumb items={breadcrumbItems} />
                <Input
                    type="search"
                    editable
                    placeholder="Search by Organization Name"
                    id="searchOrganizations"
                    onChange={(el, value) => setFilter(value)}
                    value={filter}
                />
                <Button id="createOrganization" blue onClick={toggleCreateModal}>
                    Create Client Organization
                </Button>
                <CreateClientOrganizationModal
                    onClose={toggleCreateModal}
                    open={createModalOpen}
                    consultantId={myOrganizationId}
                />
            </div>
            <div className="ConsultantOrganizationsList__prompt">
                <Icon icon="large_color/buildings" size="167px" height="100px" />
                <br /> <strong>Create client organizations</strong> to give your clients their own DeepSea environment.
            </div>
            <div className="ConsultantOrganizationsList__body">
                <OrganizationList
                    organizations={filteredOrganizations}
                    hydrated={hydrated}
                    myOrganizationId={myOrganizationId}
                />
            </div>
        </div>
    );
}
