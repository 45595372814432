import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import {
    IconButton,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    ToolTip,
    ConfirmDeleteMenu,
} from '../../../../shared';
import AddTabModal from '../../../_components/modals/AddTabModal';

require('./styles.scss');

export default function TabItem(props) {
    const {
        selectedTabId,
        tab,
        dashboard,
        editable,
        editTab,
        goToNewTab,
        duplicateTab,
        deleteTab,
        navigateToDashboard,
    } = props;
    const [tabMenu, setTabMenu] = useState(false);
    const [editModalOpen, setEditTabModal] = useState(false);
    const [deleteTooltipOpen, setDeleteTooltip] = useState(false);
    const [noHover, setNoHover] = useState(false);

    const toggleEditTabModal = () => {
        setEditTabModal(!editModalOpen);
        setTabMenu(false);
    };

    const handleDuplicate = () => {
        duplicateTab(dashboard._id, tab._id).then((action) => {
            setTabMenu(false);
            if (action.errors) return;
            goToNewTab(action.dashboard.tabs);
        });
    };
    const handleDeleteTab = () => {
        deleteTab(dashboard._id, tab._id);

        if (selectedTabId === tab._id) {
            const prevTabIds = dashboard && dashboard.tabs;
            const prevIdIndex = prevTabIds && prevTabIds.findIndex((t) => t._id === selectedTabId);
            if (prevIdIndex + 1 < prevTabIds.length) {
                navigateToDashboard(dashboard._id, prevTabIds[prevIdIndex + 1]._id);
            } else if (prevIdIndex > 0) {
                navigateToDashboard(dashboard._id, prevTabIds[prevIdIndex - 1]._id);
            } else {
                navigateToDashboard(dashboard._id);
            }
        }
    };
    const handleEditTab = (form) => {
        editTab(tab._id, form).then(() => setEditTabModal(false));
    };

    const tabItem = (
        <div
            className={ClassNames('TabItem', {
                'TabItem--active': selectedTabId === tab._id,
                'TabItem--noHover': noHover || tabMenu,
            })}
            role="button"
            tabIndex={-1}
            onClick={() => {
                navigateToDashboard(dashboard._id, tab._id);
            }}
        >
            <span className="TabItem__name">{tab.name}</span>
            {editable && (
                <div onMouseEnter={() => setNoHover(true)} onMouseLeave={() => setNoHover(false)}>
                    <IconButton
                        id="tabMenu"
                        icon="menu-vertical"
                        mini
                        onClick={() => setTabMenu(!tabMenu)}
                        active={tabMenu}
                    />
                </div>
            )}

            <ContextMenu
                listHeading={tab.name}
                open={tabMenu}
                onClickOut={() => {
                    setTabMenu(false);
                    setDeleteTooltip(false);
                }}
                position="top"
            >
                <ContextSubMenu>
                    <ContextMenuItem key="edit" id="edit" name="Edit Tab" onClick={toggleEditTabModal} />
                    <ContextMenuItem
                        key="delete"
                        id="delete"
                        name="Delete Tab"
                        onClick={() => setDeleteTooltip(!deleteTooltipOpen)}
                    >
                        <ConfirmDeleteMenu
                            open={deleteTooltipOpen}
                            onClose={() => setDeleteTooltip(false)}
                            onDelete={() => handleDeleteTab()}
                        />
                    </ContextMenuItem>
                    <ContextMenuItem key="duplicate" id="duplicate" name="Duplicate Tab" onClick={handleDuplicate} />
                </ContextSubMenu>
            </ContextMenu>
        </div>
    );

    return (
        <React.Fragment>
            {tab.description ? (
                <ToolTip delayShow={500} tooltip={tab.description} place="top" disable={tabMenu}>
                    {tabItem}
                </ToolTip>
            ) : (
                tabItem
            )}
            <AddTabModal
                open={editModalOpen}
                title="Edit Tab"
                onClose={toggleEditTabModal}
                onSubmit={handleEditTab}
                loading={!tab || tab.loading}
                formValue={tab}
            />
        </React.Fragment>
    );
}

TabItem.propTypes = {
    selectedTabId: PropTypes.string.isRequired,
    tab: PropTypes.shape({
        _id: PropTypes.string,
        loading: PropTypes.bool,
        name: PropTypes.string,
        description: PropTypes.string,
    }),
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        tabs: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    editable: PropTypes.bool,
    goToNewTab: PropTypes.func.isRequired,
    editTab: PropTypes.func.isRequired,
    duplicateTab: PropTypes.func.isRequired,
    deleteTab: PropTypes.func.isRequired,
    navigateToDashboard: PropTypes.func.isRequired,
};
TabItem.defaultProps = {
    tab: null,
    editable: false,
};
