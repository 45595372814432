import { Actions } from './reducers';
import LoginApi from './api';

import { validateEmail } from './helpers';
import { showNotification } from '../../../shared/Notification/helpers';

const logout = () => (dispatch) => {
    dispatch(Actions.logoutRequest());
    if (localStorage.getItem('accessToken')) {
        LoginApi.logout().then(
            (response) => dispatch(Actions.logoutSuccess(response)),
            (errors) => dispatch(Actions.logoutFailure(errors)),
        );
    }
    localStorage.removeItem('accessToken');
    localStorage.removeItem('access_token'); // clean up old local storage items
};

const login =
    ({ email, password }) =>
    (dispatch) => {
        dispatch(Actions.loginRequest(email, password));
        return LoginApi.login(email, password).then(
            (data, error) => {
                if (data?.triggerThirdPartyLogin){
                    // redirect to Microsoft login
                    return dispatch(Actions.loginMicrosoftRedirect(email));
                }

                if (data?.accessToken) {
                    // login success
                    localStorage.setItem('accessToken', data.accessToken);
                    return dispatch(Actions.loginSuccess(data.accessToken));
                }
                if (data?.reset) {
                    // password reset required
                    return dispatch(Actions.loginSuccessRedirect(data.resetCode));
                }
                return dispatch(
                    Actions.loginFailure({
                        email: [{ message: 'Something went wrong' }],
                        password: [{ message: 'Something went wrong' }],
                    }),
                );
            },
            (error) => {
                dispatch(Actions.loginFailure(error));
            },
        );
    };

const loginMicrosoft = (authCode) => (dispatch) =>
    LoginApi.loginMicrosoft(authCode).then(
        (data) => {
            if (data?.accessToken) {
                // login success
                localStorage.setItem('accessToken', data.accessToken);
                return dispatch(Actions.loginSuccess(data.accessToken));
            }
            return dispatch(
                Actions.loginFailure({ message: 'Account deactivated' }),
            );
        },

        (error) => {
            dispatch(Actions.loginFailure(error));
        },
    );

// const loginMicrosoft = (authCode) => (dispatch) => {
//
//   // const data = LoginApi.loginMicrosoft(authCode)
//   // console.log("AUTH CODE", authCode);
//   // localStorage.setItem('accessToken', '');
//   // return dispatch(Actions.loginSuccess(data.accessToken));
//
//   return LoginApi.loginMicrosoft(authCode).then(
//       (data) => {
//           console.log("RETURN DATA:", data)
//           if (data?.accessToken) {
//               // login success
//               localStorage.setItem('accessToken', data.accessToken);
//               return dispatch(Actions.loginSuccess(data.accessToken));
//           }
//           return dispatch(
//               Actions.loginFailure({
//                   'root@root.com': [{ message: 'Something went wrong' }]
//               }),
//           );
//       },
//
//       (error) => {
//           console.log("ERROR", error)
//           dispatch(Actions.loginFailure(error));
//       },
//   );
//
// };

const sendRecoveryEmail = (form) => (dispatch) => {
    dispatch(Actions.sendEmailRequest(form));
    const isValidEmail = validateEmail(form.email);
    if (isValidEmail) {
        return LoginApi.sendRecoveryEmail(form).then(
            (response) => dispatch(Actions.sendEmailSuccess(response)),
            (error) => dispatch(Actions.sendEmailFailure(error)),
        );
    }
    return dispatch(
        Actions.sendEmailFailure({
            email: [{ message: 'Not a valid email address' }],
        }),
    );
};

const checkResetCode = (resetCode) => (dispatch) => {
    dispatch(Actions.checkResetCodeRequest(resetCode));
    return LoginApi.checkResetCode(resetCode).then(
        (result) => dispatch(Actions.checkResetCodeSuccess(result)),
        (error) => dispatch(Actions.checkResetCodeFailure(error)),
    );
};

const resetPassword = (form, resetCode) => (dispatch) => {
    dispatch(Actions.resetPasswordRequest(form, resetCode));
    return LoginApi.resetPassword(form, resetCode).then(
        (response) => {
            dispatch(Actions.resetPasswordSuccess(response));
            showNotification({ status: 'success', message: 'Password successfully reset', autoDismiss: 5000 });
        },
        (error) => dispatch(Actions.resetPasswordFailure(error)),
    );
};

const removeErrors = () => (dispatch) => {
    dispatch(Actions.loginRemoveErrors());
};

export default { logout, login, loginMicrosoft, sendRecoveryEmail, checkResetCode, resetPassword, removeErrors };
