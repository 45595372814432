export const validateRole = (requiredRole, userRoles) => {
    if (!requiredRole) return true;
    if (!userRoles) return false;
    return Boolean(userRoles[requiredRole]);
};

const allowedRoles = {
    edit: ['edit'],
    view: ['view', 'edit'],
    hidden: ['view', 'edit', 'hidden'],
};

export const validatePermissionRole = (requiredRoles, roles) => {
    if (!roles) return false;
    let allowed = true;
    Object.keys(requiredRoles).forEach((key) => {
        const requiredRole = requiredRoles[key];
        const userRole = roles[key];
        if (!userRole || !allowedRoles[requiredRole].includes(userRole)) {
            allowed = false;
        }
    });
    return allowed;
};
