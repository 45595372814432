import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { AlgorithmModel, ModelModel } from '../_models';
import ModelSelector from '../_models/models/selectors';

import CreateModel from './CreateModel';
import ModelInputs from './ModelInputs';
import RunModel from './RunModel';

import { FullScreenModal, FullScreenModalWorkflowHeader, Loader } from '../../shared';

require('./styles.scss');

const ModelOnboardWorkflow = () => {
    const { modelId, projectId } = useParams();
    const models = useSelector((state) => state.models);
    const modelRuns = useSelector((state) => state.modelRuns);
    const algorithms = useSelector((state) => state.algorithms);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateModel = (updateObj) => dispatch(ModelModel.update(modelId, updateObj));

    const model = models.byId[modelId];

    useEffect(() => {
        if (modelId) dispatch(ModelModel.show(modelId));
    }, [dispatch, modelId]);

    const packageId = useSelector(ModelSelector.getPackageId(model?._id));
    const moduleFileName = useSelector(ModelSelector.getModuleFilename(model?._id));

    const [tabIndex, setTabIndex] = useState(modelId ? 1 : 0);

    const [metadata, setMetadata] = useState({});
    const [modelDraft, setModelDraft] = useState({ name: null, description: null });

    if (
        (model?.name || model?.description) &&
        modelDraft.name == null &&
        modelDraft.description === null &&
        !algorithms.loading
    ) {
        // this if statement is here because we're using a different route once the modelId is defined, meaning the component re-mounts in the middle of the workflow
        // so the state for the first step needs to be re-initialized in order to appear correctly on "Back"
        setModelDraft({ name: model.name, description: model.description });
        const algorithmId = model.algorithmId;
        const algorithm = algorithms.byId[algorithmId];
        setMetadata({ algorithmId, moduleId: algorithm?.moduleId, packageId: algorithm?.packageId });
    }

    const createModelNext = () => {
        if (modelId) {
            dispatch(ModelModel.update(modelId, modelDraft));
        } else if (metadata.algorithmId && !modelId) {
            dispatch(AlgorithmModel.createModel(metadata.algorithmId, modelDraft)).then((response) => {
                const newId = response?.model?._id;
                if (newId) navigate(`/projects/${projectId}/models/${newId}/onboard`);
            });
        }
        setTabIndex(1);
    };

    const [hasConfirmedInputs, setHasConfirmedInputs] = useState(false);
    const saveInputs = () => {
        updateModel({
            inputs: model.inputs.map((input) => {
                // fill in undefined values with defaults
                const newInput = { ...input };
                if (input.dataType === 'select') {
                    if (!input.options) newInput.options = [];
                }
                return newInput;
            }),
        });
        setHasConfirmedInputs(true);
        setTabIndex(2);
    };
    const allInputsValid = !model?.inputs?.some(
        (input) => !input.dataType || (!input.defaultValue && input.defaultValue !== false && input.defaultValue !== 0),
    );

    const hasModelBeenRun =
        model?.runs && model.runs.some((runId) => modelRuns.byId[runId] && modelRuns.byId[runId].status === 'finished');
    const confirmOnboarding = () => {
        dispatch(ModelModel.confirm(modelId));
        navigate(`/projects/${projectId}/models`);
        // window.location.reload(); // this is a bloody hack... please fix - Pete
    };

    const tabs = [
        {
            label: 'Select Source Function',
            component: (
                <CreateModel
                    metadata={metadata}
                    setMetadata={setMetadata}
                    modelDraft={modelDraft}
                    setModelDraft={setModelDraft}
                    modelId={modelId}
                />
            ),
            complete: !!metadata.algorithmId || !!model?.onboarded,
            backButton: {
                id: 'back',
                content: 'Back',
                onClick: () => {
                    setMetadata({});
                    setModelDraft({ name: null, description: null });
                    navigate(`/projects/${projectId}/models`);
                },
            },
            forwardButton: { id: 'next', onClick: createModelNext, content: 'Continue' },
        },
        {
            label: 'Configure Inputs',
            component: packageId && (
                <ModelInputs
                    model={model}
                    packageId={packageId}
                    moduleFileName={moduleFileName}
                    updateModel={updateModel}
                />
            ),
            complete: allInputsValid && (hasConfirmedInputs || model?.onboarded),
            backButton: { id: 'back', content: 'Back', onClick: () => setTabIndex(0) },
            forwardButton: { id: 'next', onClick: saveInputs, content: 'Continue' },
        },
        {
            label: 'Generate Outputs',
            component: packageId && <RunModel model={model} packageId={packageId} moduleFileName={moduleFileName} />,
            complete: hasModelBeenRun && (tabIndex === 2 || model?.onboarded),
            backButton: { id: 'back', content: 'Back', onClick: () => setTabIndex(1) },
            forwardButton: { id: 'submit', content: 'Done', onClick: confirmOnboarding },
        },
    ];

    return (
        <FullScreenModal
            open
            header={
                <FullScreenModalWorkflowHeader
                    onClose={() => navigate(`/projects/${projectId}/models`)}
                    tabIndex={tabIndex}
                    tabs={tabs}
                    title={model?.name ? `Create Model - ${model.name}` : 'Create Model'}
                />
            }
        >
            <div className="ModelOnboardWorkflow">
                {tabIndex === 0 || model ? tabs[tabIndex].component : <Loader type="spinner" loader="scale" />}
            </div>
        </FullScreenModal>
    );
};

export default ModelOnboardWorkflow;
