export const defaultKpiModel = {
    type: 'kpi',
    vis: {
        kpi: [
            // Default KPI Serum schema - not needed for defaultKpiModel
            // {
            //     feature: { _id: '' },
            //     label: '',
            //     unitOfMeasure: '',
            //     operation: '',
            //     filters: [],
            //     jobId: 'latest',
            // },
        ],
    },
};
