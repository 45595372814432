import moment from 'moment';

export const getDisplayForModelInputValue = (input, datasets) => {
    switch (input.display.inputType) {
        case 'date':
            return moment(input.value).format('lll');
        case 'constraint':
            return Object.keys(input.value)?.reduce(
                (acc, key) => `${acc}${key}: min ${input.value[key].min}, max ${input.value[key].max}. `,
                '',
            );
        case 'dataframe':
            return datasets?.byId[input.value._id]?.name || `dataframe not found: ${input.value._id}`;
        case 'json':
            return JSON.stringify(input.value || {}, null, 2);
        case 'multiSelect':
            return input.value.join(', ');
        // number, string, date, boolean, etc
        case 'select':
        default:
            return input.value.toString();
    }
};
