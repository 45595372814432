import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayForModelInputValue } from './helpers';

export default function ModelInputType(props) {
    const { inputs, title, datasets } = props;
    return (
        <React.Fragment>
            <div className="RunInfoInputs__title">{title}</div>
            {inputs.map((input) => (
                <div className="RunInfoInputs__input" key={input._id}>
                    {`${input.name}: `}
                    <span className="RunInfoInputs__inputValue">{getDisplayForModelInputValue(input, datasets)}</span>
                </div>
            ))}
        </React.Fragment>
    );
}

ModelInputType.propTypes = {
    inputs: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    datasets: PropTypes.shape({}).isRequired,
};
