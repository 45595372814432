import moment from 'moment/moment';

export const getSourceString = (model, packages, algorithms) => {
    let sourceString = 'not found';
    if (!model || !model.algorithmId) return sourceString;

    const algorithm = algorithms.byId[model.algorithmId];
    if (algorithm) {
        const sourcePackage = packages.byId[algorithm.packageId];
        if (!sourcePackage) return sourceString;
        const sourceModule = sourcePackage.modules?.find((m) => m._id === algorithm.moduleId);

        sourceString = `${sourcePackage.name || 'not found'}`;
        sourceString = `${sourceString} / ${sourceModule ? sourceModule.name : 'not found'}`;
        sourceString = `${sourceString} / ${algorithm ? algorithm.functionName : 'not found'}`;
    }

    return sourceString;
};

export const getModelState = (model, packages, algorithms) => {
    const algorithm = algorithms.byId[model.algorithmId];
    if (model.onboarded && model.projectId !== algorithm?.projectId)
        // MODEL TRANSFERRED FROM ANOTHER PROJECT. LINKED TO ORIGINAL PACKAGE
        return {
            state: 'complete',
            info: 'This model is transferred from another Project. It is executable but cannot be updated',
            icon: 'checkmark',
        };
    if (!packages.byId[algorithm?.packageId])
        return { state: 'error', info: 'Source not found', icon: 'exclamation-triangle' };

    // if (!packages.byId[algorithm]) return { state: 'error', info: 'Source not found', icon: 'exclamation-triangle' };

    if (!model.onboarded) return { state: 'draft', info: 'Draft - Click to continue setup', icon: 'large_color/draft' };
    return { state: 'complete', info: getSourceString(model, packages, algorithms), icon: 'checkmark' };
};

export const getPackageState = (pkg) => {
    const stateObjects = {
        uploading: { info: 'Uploading Package...', state: 'building', icon: 'spinner', packageIcon: 'spinner' },
        queued: { info: 'Build Queued', state: 'building', icon: 'spinner', packageIcon: 'spinner' },
        parsing: { info: 'Parsing Functions', state: 'building', icon: 'spinner', packageIcon: 'spinner' },
        installing: { info: 'Preparing Environment', state: 'building', icon: 'spinner', packageIcon: 'spinner' },
        failed: {
            state: 'error',
            info: `Package install failed. There is an issue in your uploaded code: ${pkg?.errorLog}`,
            icon: 'exclamation-triangle',
            packageIcon: 'large_color/package-upload-error',
        },
        built: {
            state: 'complete',
            info: `Uploaded ${moment.utc(pkg?.timeCreated).local().format('lll')}`,
            icon: 'checkmark',
        },
    };
    return stateObjects[pkg?.status] || { state: 'building', info: 'Unknown Status', icon: 'spinner' };
};
