import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { optionsByFilterType } from './constants';
import { getFeatureFormatFunction } from '../helpers';

export const operationFromDatePrecision = {
    // precision: operation
    day: 'year-day',
    month: 'year-month',
    quarter: 'year-quarter',
    year: 'year',
};

export function getOperationFromDatePrecision(precision) {
    return operationFromDatePrecision[precision] || 'year-day';
}

export function getDatePrecisionFromOperation(operation) {
    const precisions = Object.keys(operationFromDatePrecision);
    return precisions.find((precision) => operation === operationFromDatePrecision[precision]);
}

// filter type helper
// Note: I would like to refactor filter objects to not have types
export function getFilterTypeFromFeature(feature) {
    if (!feature || !feature.type) return null;
    if (feature.type === 'number') return 'value';
    if (feature.type === 'date') return 'range';
    if (feature.type === 'category') return 'category';
    if (feature.type === 'text') return 'text';
    if (feature.type === 'time') return 'time';
    return null; // unexpected type
}

function stringOfValuesFromList(filter, selectedItems) {
    const strLength = 40;
    let str = `${filter.option === 'not-equal' ? 'not ' : ''}${selectedItems[0]}`;
    for (let i = 1; i < selectedItems.length; i++) {
        if (str.length + selectedItems[i].length < strLength) {
            str += `, ${selectedItems[i]}`;
        } else {
            const numRemaining = selectedItems.length - i;
            if (numRemaining === 1) {
                return `${str}, ${selectedItems[i]}`;
            }
            return `${str}, ... and ${numRemaining} more`;
        }
    }
    return str;
}

// returns a short string describing a filter
export default function formatFilterValue(filter, feature) {
    if (filter.option === 'empty' || filter.option === 'not-empty') {
        return filter.value.type.replace('-', ' ');
    }
    if (feature && feature.error) {
        return 'invalid filter';
    }
    if (isEmpty(feature) || !feature.type) {
        return 'loading...';
    }
    if (filter.type !== getFilterTypeFromFeature(feature)) return 'invalid feature type';
    switch (feature.type) {
        case 'date': {
            const precision = getDatePrecisionFromOperation(filter.operation);
            const formatter = getFeatureFormatFunction({ type: 'date', precision });

            switch (filter.option) {
                case 'on':
                    if (filter.value.selectedDateItems) {
                        const { selectedDateItems } = filter.value;
                        return stringOfValuesFromList(filter, selectedDateItems);
                    }
                    return `on ${formatter(filter.value.on) || '--'}`;
                case 'before':
                    return `before ${formatter(filter.value.to) || '--'}`;
                case 'after':
                    return `after ${formatter(filter.value.from) || '--'}`;
                case 'last':
                    switch (filter.operation) {
                        case 'year':
                            return `last ${filter.value.duration || '--'} years`;
                        case 'year-month':
                            return `last ${filter.value.duration || '--'} months`;
                        case 'year-day':
                        default:
                            return `last ${filter.value.duration || '--'} days`;
                    }
                case 'between':
                default:
                    return `${formatter(filter.value.from) || '--'} to ${formatter(filter.value.to) || '--'}`;
            }
        }
        case 'category':
            if (filter.value.category) {
                const { category } = filter.value;
                return stringOfValuesFromList(filter, category);
            }
            if (filter.value.text) {
                const str = filter.value.text;
                return `${filter.option}: ${str}`;
            }
            return '--';
        case 'number':
            if (filter.option === 'between') {
                return `${
                    (filter.value.from || filter.value.from === 0) && typeof filter.value.from === 'number'
                        ? filter.value.from
                        : '--'
                } to ${
                    (filter.value.to || filter.value.to === 0) && typeof filter.value.to === 'number'
                        ? filter.value.to
                        : '--'
                }`;
            }
            return `${filter.option.toLowerCase()} ${
                typeof filter.value.value === 'number' ? filter.value.value : '--'
            }`;
        case 'time':
            if (filter.option === 'between') {
                return `${
                    (filter.value.from || filter.value.from === 0) && typeof filter.value.from === 'string'
                        ? filter.value.from
                        : '--'
                } to ${
                    (filter.value.to || filter.value.to === 0) && typeof filter.value.to === 'string'
                        ? filter.value.to
                        : '--'
                }`;
            }
            return `${filter.option.toLowerCase()} ${
                typeof filter.value.value === 'string' ? filter.value.value : '--'
            }`;
        case 'text':
            if (filter.value.text) {
                const str = filter.value.text;
                return `${filter.option}: ${str}`;
            }
            return '--';

        default:
            return '??';
    }
}
// returns a datasetId
export function getDatasetIdFromFilter(filter, features) {
    let feature;
    if (Array.isArray(features)) {
        feature = features.find((f) => f._id === (filter.feature && filter.feature.id));
    } else {
        feature = features.byId[filter.feature && filter.feature.id];
    }
    return feature && feature.dataset && feature.dataset._id;
}
// returns a dataset
export function getDatasetFromFilter(filter, features, datasets) {
    const datasetId = getDatasetIdFromFilter(filter, features);
    if (Array.isArray(datasets)) {
        return datasets.find((d) => d._id === datasetId);
    }
    return datasets.byId[datasetId];
}

export function getDefaultFilterFromFeature(feature) {
    const type = getFilterTypeFromFeature(feature);
    if (!type) return null;

    if (type === 'range') {
        const operation = getOperationFromDatePrecision(feature.precision);
        return {
            feature: { id: feature._id },
            value: {},
            option: 'after',
            type,
            operation,
        };
    }
    return {
        feature: { id: feature._id },
        value: {},
        option: optionsByFilterType[type][0].value,
        type,
    };
}

// Check whether a filter is valid
function isDate(str) {
    return Boolean(str && moment(str, moment.ISO_8601));
}

function isTime(str) {
    return Boolean(str && moment(str));
}

export function isFilterValid(filter) {
    if (!(filter.feature && filter.feature.id)) return false;

    if (filter.option === 'empty' || filter.option === 'not-empty') return true;
    if (!(filter && filter.value) || isEmpty(filter.value)) return false;

    if (filter.type === 'value') {
        switch (filter.option) {
            case 'greater':
            case 'less':
            case 'equal':
            case 'not-equal':
            case 'greater-equal':
            case 'less-equal':
                return typeof filter.value.value === 'number';
            case 'between':
                return typeof filter.value.from === 'number' && typeof filter.value.to === 'number';
            default:
                return false;
        }
    } else if (filter.type === 'category') {
        switch (filter.option) {
            case 'contains':
            case 'begins-with':
            case 'ends-with':
                return filter.value && typeof filter.value.text === 'string' && filter.value.text.length > 0;
            case 'equal':
            case 'not-equal':
                return Array.isArray(filter.value.category) && filter.value.category.length > 0;
            default:
                return false;
        }
    } else if (filter.type === 'range') {
        if (!filter.operation) return false;
        switch (filter.option) {
            case 'between':
                return isDate(filter.value.to) && isDate(filter.value.from);
            case 'before':
                return isDate(filter.value.to);
            case 'after':
                return isDate(filter.value.from);
            case 'on':
                if (filter.operation === 'dow' || filter.operation === 'month')
                    return Array.isArray(filter.value.selectedDateItems) && filter.value.selectedDateItems.length > 0;
                return isDate(filter.value.on);
            case 'last':
                return filter.value && typeof filter.value.duration === 'number';
            default:
                return false;
        }
    } else if (filter.type === 'text') {
        switch (filter.option) {
            case 'contains':
            case 'equal':
            case 'begins-with':
            case 'ends-with':
                return filter.value && typeof filter.value.text === 'string' && filter.value.text.length > 0;
            default:
                return false;
        }
    } else if (filter.type === 'time') {
        switch (filter.option) {
            case 'before':
            case 'after':
            case 'equal':
                return isTime(filter.value.value);
            case 'between':
                return isTime(filter.value.to) && isTime(filter.value.from);
            default:
                return false;
        }
    }
    return false;
}
