import moment from 'moment/moment';

export const types = {
    text: {
        validation: (value) => value !== '',
    },
    number: {
        validation: (value) => value._value,
    },
    date: {
        validation: (value) => moment(value) && moment(value).isValid() && value !== '',
    },
    boolean: {
        inputType: 'select',
        options: [
            { label: 'True', value: true },
            { label: 'False', value: false },
        ],
        validation: (value) => value === 'True' || value === 'False' || value === true || value === false,
    },
    select: {
        validation: (value) => value.length || value !== '', // value is array or string
    },
    multiSelect: {
        validation: (value) => value.length || value === [],
    },
    json: {
        validation: (value) => value !== '',
    },
};

export const getValidations = (type) => [
    { validate: types[type].validation, error: `please enter a valid ${types[type].name}` },
];
