import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Timeline } from '../../../../../shared';
import { timeLineTitles } from '../../../../constants';
import UserModel from '../../../../_models/users';

require('./styles.scss');

export default function UserLoginTimeline(props) {
    const { userId } = props;

    const dispatch = useDispatch();
    useEffect(() => {
        if (userId) dispatch(UserModel.showLogs(userId));
    }, [userId, dispatch]);

    const logs = useSelector((state) => state.users.byId[userId]?.logs || []);
    const loginTimelineItems = logs.map((loginEvent) => ({
        ...loginEvent,
        title: timeLineTitles[loginEvent.type],
        time: loginEvent.timeCreated,
    }));
    return (
        <div className="UserLoginTimeline">
            <div className="UserLoginTimeline__title">Login History</div>
            {loginTimelineItems.length > 0 ? (
                <Timeline id="TimelineID" items={loginTimelineItems} align="right" />
            ) : (
                'No account activity'
            )}
        </div>
    );
}

UserLoginTimeline.propTypes = {
    userId: PropTypes.string,
};

UserLoginTimeline.defaultProps = {
    userId: '',
};
