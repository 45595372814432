import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DatasetModel from '../../_models/dataset';
import FeatureModel from '../../_models/feature';
import { useForm } from '../../../../helpers/hooks/form';

function useFeatureWorkflowRedux(datasetId) {
    // get the dataset and features based on the datasetId
    const dataset = useSelector((state) => state.datasets.byId[datasetId]);
    const features = useSelector((state) => state.features);
    const canEditDatasets = useSelector((state) => state.app.permission?.roles?.dataset === 'edit');
    // get dispatch functions:
    const dispatch = useDispatch();
    // want to memoize these so they don't change every time
    const onUpdateFeature = (id, form) => dispatch(FeatureModel.update(id, form));
    // load the dataset and dataset features (if needed):
    useEffect(() => {
        if (dataset?.loading) return;
        if (!dataset?._id) dispatch(DatasetModel.show(datasetId));
    }, [datasetId, dataset, dispatch]);
    return { dataset, features, onUpdateFeature, canEditDatasets };
}

// returns an array of features for the dataset, or `undefined` if the data is not available
function getDatasetFeatures(dataset, features) {
    const datasetFeatures = Object.values(features.byId)
        .filter((feature) => feature.dataset._id === dataset?._id)
        .sort((a, b) => a.listIndex - b.listIndex);
    return datasetFeatures.length ? datasetFeatures : undefined;
}

function useActiveFeature({ features, datasetFeatures, onUpdateFeature }) {
    const dispatch = useDispatch();
    const [activeFeatureId, setActiveFeatureId] = useState(null);
    const activeFeature = activeFeatureId && features.byId[activeFeatureId];

    // a function to handle updating the feature
    const handleFormSubmit = (form) => {
        const objToSubmit = {
            description: form.description,
        };
        onUpdateFeature(activeFeatureId, objToSubmit);
    };
    // handlers for all the form inputs
    const [activeFeatureForm, setActiveFeatureForm] = useForm(
        {
            description: '',
        },
        {
            onSubmit: handleFormSubmit,
        },
    );
    // a handler for setting the active feature
    const handleSetActiveFeatureId = useCallback(
        (id) => {
            // set the activeFeatureId variable
            setActiveFeatureId(id);
            // calculate the next activeFeature
            const nextActiveFeature = features.byId[id] || {};
            // set the form value
            setActiveFeatureForm({
                description: nextActiveFeature.description || '',
            });
            if (!nextActiveFeature.preview) {
                dispatch(FeatureModel.preview(id));
            }
            if (nextActiveFeature.type === 'number') dispatch(FeatureModel.stats(id));
        },
        [features.byId, dispatch, setActiveFeatureForm],
    );

    // set active feature once the dataset and features are populated
    useEffect(() => {
        if (
            datasetFeatures &&
            (!activeFeatureId || !datasetFeatures.some((feature) => feature._id === activeFeatureId))
        ) {
            setActiveFeatureId(null);
        }
    }, [datasetFeatures, activeFeatureId, handleSetActiveFeatureId]);

    return {
        activeFeature,
        setActiveFeatureId: handleSetActiveFeatureId,
        activeFeatureForm,
    };
}

export function useDatasetFeaturePage(datasetId) {
    // send requests to get dataset and dataset features (if necessary)
    // get the following items from redux:
    const { dataset, features, onUpdateFeature, canEditDatasets } = useFeatureWorkflowRedux(datasetId);

    // get an array of dataset features (returns `undefined` if dataset is loading)
    const datasetFeatures = getDatasetFeatures(dataset, features);

    // get functions to handle the active feature (the right side of the modal)
    const { activeFeature, setActiveFeatureId, activeFeatureForm } = useActiveFeature({
        datasetFeatures,
        features,
        onUpdateFeature,
    });

    return {
        dataset,
        features,
        datasetFeatures,
        activeFeature,
        setActiveFeatureId,
        activeFeatureForm,
        canEditDatasets,
        onUpdateFeature,
    };
}
