import { useState, useCallback, useMemo } from 'react';

// This file is a test run for using hooks as an alternative to the `Form` component;

/** a hook for using one input.
 * Basic use:
 *   const [nameInput] = useInput('default name');
 *   return <Input id="name" type="text" {...nameInput} />
 *
 * Extra:
 *   - also exposes a setNameInput to change the input value in response to other events
 *   example:
 *   const [nameInput, setNameInput] = useInput('default name');
 *   window.setTimeout(() => nameInput.value === 'default name' && setNameInput('slow joe'), 10000);
 */
export function useInput(defaultValue, { onChange, ...handlers } = {}) {
    const [value, setValue] = useState(defaultValue);
    const onInputChange = useCallback(
        (id, val) => {
            setValue(val);
            if (onChange) onChange(id, val);
        },
        [onChange],
    );
    return [{ value, onChange: onInputChange, ...handlers }, setValue];
}

/** a hook for using multiple inputs.
 * Basic use:
 *   const [loginForm] = useForm(
 *       { email: '', password: '', confirmPassword: '' },
 *       { onSubmit: loginFunc },
 *   );
 *   return (
 *       <div>
 *           <Input id="email" type="text" {...loginForm.email} />
 *           <Input id="password" type="password" {...loginForm.password} />
 *           <Input id="confirmPassword" type="password" {...loginForm.confirmPassword} />
 *       </div>
 *   );
 *
 * Extra:
 *   - form handlers can include `onChange` and `onSubmit`
 *   - also exposes a setFormValue to change the form's value in response to other events
 *   example:
 *   const [featureForm, setFeatureForm] = useForm({ name: '', description: '' });
 *   const selectFeature = (feat) => setFeatureForm({ name: feat.name, description: feat.description })
 */
export function useForm(defaultValue, { onChange, onSubmit } = {}) {
    // value is an object `{ inputId: inputValue, id2: value2, ... }`
    const [value, setValue] = useState(defaultValue);

    // handler for input changes
    const handleInputChange = useCallback(
        (inputId, inputValue) => {
            const nextValue = { ...value, [inputId]: inputValue };
            setValue(nextValue);
            if (onChange) onChange(nextValue);
        },
        [value, onChange],
    );
    const handleInputSubmit = useCallback(() => {
        if (onSubmit) onSubmit(value);
    }, [value, onSubmit]);

    const inputHandlers = useMemo(() => {
        const handlers = {};
        Object.keys(value).forEach((inputId) => {
            handlers[inputId] = {
                value: value[inputId],
                onChange: handleInputChange,
                onSubmit: handleInputSubmit,
            };
        });
        return handlers;
    }, [value, handleInputChange, handleInputSubmit]);

    return [inputHandlers, setValue];
}
