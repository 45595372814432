/* eslint-disable */
import { Configuration, OpenAIApi } from 'openai';
export const checkApiKey = async (keys) => {
  const configuration = new Configuration({
    apiKey: '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',
  });

  const openai = new OpenAIApi(configuration);

  return openai.listModels();
};
