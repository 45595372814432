import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { textFilterOptions } from '../constants';
import { Input } from '../../../shared';
import FilterHeader from './header';

// The filter component for text features. Fully controlled.
export default function TextFilterForm(props) {
    const { value: filterValue, onSubmit, onChange } = props;
    const { option, value } = filterValue;
    const handleValueChange = (id, text) => {
        onChange('value', { text });
    };

    return (
        <div className="TextFilter">
            <FilterHeader {...props} options={textFilterOptions} />
            <main className={classNames({ 'TextFilter__main--range': option === 'contains' })}>
                {option !== 'empty' && option !== 'not-empty' ? (
                    <Input
                        id="value"
                        type="text"
                        dark
                        autoFocus
                        onChange={handleValueChange}
                        onSubmit={onSubmit}
                        value={value.text} // eslint-disable-line react/prop-types
                        placeholder="Search"
                        class="InputBase"
                    />
                ) : null}
            </main>
        </div>
    );
}
TextFilterForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.shape({
        option: PropTypes.string,
        value: PropTypes.shape({
            category: PropTypes.arrayOf(PropTypes.string),
            text: PropTypes.string,
        }),
    }),
};
TextFilterForm.defaultProps = {
    value: {
        value: {
            category: [],
        },
    },
};
