import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FeatureFilter from '../Types';
import { getDefaultFilterFromFeature } from '../helpers';
import { typeIcons } from '../../constants';
import { Icon, ListItem } from '../../../shared';

// the component that displays after you select a dataset
//  -- a list of features OR the feature filter for the selected filter
export default function FilterMenuFeature(props) {
    const { features, value, onChange, onSubmit, onFeatureSelect } = props;
    const [openFeatureId, setOpenFeatureId] = useState(value.feature && value.feature.id);
    const openFeature = openFeatureId && features && features.find((f) => f._id === openFeatureId);
    const handleFeatureSelect = (id) => {
        setOpenFeatureId(id);
        if (onFeatureSelect) onFeatureSelect(id);
        const feature = features.find((f) => f._id === id);
        onChange(getDefaultFilterFromFeature(feature));
    };

    if (!features || features.length === 0) {
        // loading state
        return Array.from({ length: 7 }, (_, i) => <ListItem key={i} dark loading />);
    }

    return openFeature ? (
        <FeatureFilter
            value={value}
            feature={openFeature}
            onChange={onChange}
            onSubmit={onSubmit}
            onClickBack={() => setOpenFeatureId(null)}
        />
    ) : (
        features.map((feature) => (
            <ListItem
                key={feature._id}
                id={feature._id}
                type={<Icon icon={typeIcons[feature.type] || 'question-circle'} size="20px" />}
                name={feature.name}
                loading={!feature.name}
                onClick={handleFeatureSelect}
                dark
            />
        ))
    );
}

FilterMenuFeature.propTypes = {
    value: PropTypes.shape({
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
    }),
    features: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onFeatureSelect: PropTypes.func,
};
FilterMenuFeature.defaultProps = {
    value: null,
    features: null,
    onChange: () => {},
    onSubmit: () => {},
    onFeatureSelect: () => {},
};
