import React from 'react';
import PropTypes from 'prop-types';

import NumberFilter from './number';
import DateFilter from './date';
import CategoryFilter from './category';
import TextFilter from './text';
import TimeFilter from './time';

import './styles.scss';

export const filtersByFeature = {
    number: NumberFilter,
    date: DateFilter,
    category: CategoryFilter,
    text: TextFilter,
    time: TimeFilter,
};
export const filtersByFilterType = {
    value: NumberFilter,
    range: DateFilter,
    category: CategoryFilter,
    text: TextFilter,
    time: TimeFilter,
};

// given a filter object (props.value), conditionally renders the category, date, or number filter component (based on type of filter or type of feature)
export default function FeatureFilter({ onChange, ...props }) {
    const { feature, value } = props;
    const FilterType = feature.type ? filtersByFeature[feature.type] : filtersByFilterType[value.type];
    // handle change is written to accept a [key: value] pair from a filter object  (eg, option: greater, value: { from: July 12 })
    // key = option by default, when user makes an input, key = value
    // returns a filter object

    const handleChange = (key, val) => {
        const nextValue = { ...value, [key]: val };
        if (key === 'option' && (val === 'empty' || val === 'not-empty')) {
            // when the option is empty or not-empty, the filterObject.value needs to be { type: 'empty'|'not-empty' }
            nextValue.value = { type: val };
        }

        if (key === 'option' && val.includes('/')) {
            const splitOption = val.split('/');
            nextValue.option = splitOption[0];
            nextValue.operation = splitOption[1];
        }
        onChange(nextValue);
    };

    return <FilterType {...props} onChange={handleChange} />;
}

FeatureFilter.propTypes = {
    feature: PropTypes.shape({ type: PropTypes.string }),
    value: PropTypes.shape({
        value: PropTypes.shape({ input: PropTypes.string }),
        option: PropTypes.string,
        type: PropTypes.string,
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onClickBack: PropTypes.func,
};
FeatureFilter.defaultProps = {
    feature: {},
    value: {
        value: {
            input: '',
        },
    },
    onChange: () => {},
    onSubmit: () => {},
    onClickBack: () => {},
};
