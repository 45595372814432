import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DragAndDropList, Loader, Icon, InfoToolTip } from '../../../shared';
import DatasetsItemMenu from '../DatasetsItemMenu';
import DatasetAdd from '../../Add';
import { getIconFromDatasetType } from '../../helpers';

require('./styles.scss');

export default function DatasetsList(props) {
    const { datasets, datasetsList, handleNavigate, canEditDatasets, handleDragDrop, defaultListId } = props;
    const [addModalOpen, setAddModalOpen] = useState(false);

    const _renderDatasetsList = (_datasets) => {
        const { hydrated, allIds, byId } = _datasets;
        if (hydrated || allIds.length) {
            if (!allIds.some((id) => byId[id]?.imported)) {
                return (
                    <div className="DataSidebar__emptyPlaceholder">
                        <div className="DataSidebar__emptyPlaceholderTitle">No datasets</div>
                        <div className="DataSidebar__emptyPlaceholderDescription">Click above to add a dataset. </div>
                    </div>
                );
            }
            return (
                <div className="DatasetsList__list">
                    <DragAndDropList
                        id={defaultListId}
                        expanded
                        items={datasetsList.map((dataset) => ({
                            id: dataset._id,
                            label: dataset.name || '',
                            type: <Icon icon={getIconFromDatasetType(dataset.type)} size="16px" />,
                            decorations: (
                                <React.Fragment>
                                    {dataset.description ? <InfoToolTip>{dataset.description}</InfoToolTip> : null}
                                    {canEditDatasets ? <DatasetsItemMenu dataset={dataset} /> : null}
                                </React.Fragment>
                            ),
                        }))}
                        onClick={handleNavigate}
                        onDrop={handleDragDrop}
                        placeholderText="Drag a dataset here from a folder to remove it"
                    />
                </div>
            );
        }
        return (
            <React.Fragment>
                <Loader type="content" loader="content" />
                <Loader type="content" loader="content" />
            </React.Fragment>
        );
    };

    return (
        <div>
            <div className="DataSidebar__title">
                <span>Datasets</span>
                {canEditDatasets && (
                    <Button blue small onClick={() => setAddModalOpen(true)}>
                        Add
                    </Button>
                )}
            </div>
            <DatasetAdd open={addModalOpen} onClose={() => setAddModalOpen(false)} />
            {_renderDatasetsList(datasets)}
        </div>
    );
}

DatasetsList.propTypes = {
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
        hydrated: PropTypes.bool,
    }).isRequired,
    canEditDatasets: PropTypes.bool.isRequired,
    datasetsList: PropTypes.array.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    handleDragDrop: PropTypes.func.isRequired,
    defaultListId: PropTypes.string.isRequired,
};
DatasetsList.defaultProps = {};
