import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorPage from '../../components/App/_components/error';
import { validatePermissionRole, validateRole } from './helpers';

export const UserRoleProtectedRoute = ({ children, requiredRole }) => {
    const { user } = useSelector((state) => state.app);
    if (!user || !user.roles) return null;
    const valid = validateRole(requiredRole, user.roles);
    return valid ? children : <ErrorPage statusCode={404} />;
};

UserRoleProtectedRoute.propTypes = {
    children: PropTypes.node,
    requiredRole: oneOfType([PropTypes.shape({}), PropTypes.string]),
};

UserRoleProtectedRoute.defaultProps = {
    children: null,
    requiredRole: {},
};

export const ProjectProtectedRoute = ({ children, requiredRole }) => {
    const { projectId } = useParams();
    const { user, permission } = useSelector((state) => state.app);
    if (!user || !permission || !permission.roles) return null;
    const valid = validatePermissionRole(requiredRole, permission.roles);
    return valid ? children : <Navigate to={`/projects/${projectId || ''}`} />;
};

ProjectProtectedRoute.propTypes = {
    children: PropTypes.node,
    permissionRoles: PropTypes.shape({}),
    requiredRole: PropTypes.shape({}),
};

ProjectProtectedRoute.defaultProps = {
    children: null,
    permissionRoles: null,
    requiredRole: {},
};
