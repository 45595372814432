import { isEqual } from 'lodash';

export const passwordValidations = [
    {
        validate: (form) => form.password.length >= 8 || form.confirmPassword.length >= 8,
        label: 'Must be at least 8 characters.',
    },
    {
        validate: (form) => Boolean((form.password + form.confirmPassword).match(/\W/)),
        label: 'Must contain a special character (%, ?, &, etc).',
    },
    {
        validate: (form) => Boolean((form.password + form.confirmPassword).match(/[A-Z]/)),
        label: 'Must contain a capital letter.',
    },
    {
        validate: (form) => form.password === form.confirmPassword && form.password !== '',
        label: 'Passwords must match.',
    },
];

export const timeLineTitles = {
    login: 'Logged in',
    logout: 'Logged out',
    'login-attempt': 'Failed login attempt',
    'login-microsoft': 'Logged in via Microsoft',
};

export const rootRoles = {
    editOrganizationSettings: true,
    manageClientOrganizations: true,
    manageOrganizationUsers: true,
    viewOrganizationLogins: true,
    manageOrganizationProjects: true,
    createProjects: true,
    manageAllOrganizations: true,
};
export const adminRoles = {
    editOrganizationSettings: true,
    manageClientOrganizations: false,
    manageOrganizationUsers: true,
    viewOrganizationLogins: true,
    manageOrganizationProjects: true,
    createProjects: true,
    manageAllOrganizations: false,
};
export const userRoles = {
    editOrganizationSettings: false,
    manageClientOrganizations: false,
    manageOrganizationUsers: false,
    viewOrganizationLogins: false,
    manageOrganizationProjects: false,
    createProjects: true,
    manageAllOrganizations: false,
};
export const viewerRoles = {
    editOrganizationSettings: false,
    manageClientOrganizations: false,
    manageOrganizationUsers: false,
    viewOrganizationLogins: false,
    manageOrganizationProjects: false,
    createProjects: false,
    manageAllOrganizations: false,
};

export const presetRoles = {
    rootRoles: {
        roles: rootRoles,
        shortName: 'rootRoles',
        name: 'DeepSea Product Admin',
        description:
            'This user is part of the White Whale product or ops team, and can create & administrate organizations that are DeepSea customers.',
    },
    adminRoles: {
        roles: adminRoles,
        shortName: 'adminRoles',
        name: 'Organization Admin',
        description:
            'This user can create and edit projects, and can create new users and change organization settings.',
    },
    userRoles: {
        roles: userRoles,
        shortName: 'userRoles',
        name: 'Standard',
        description: 'This user can create and edit projects, but cannot access organization settings.',
    },
    viewerRoles: {
        roles: viewerRoles,
        shortName: 'viewerRoles',
        name: 'View Only',
        description: 'This user can only view the content of projects shared with them.',
    },
};

// deprecated
export const getRoleString = (roles, options = {}) => {
    if (!roles || isEqual(roles, {})) return 'loading...';

    if (isEqual(userRoles, roles)) {
        return options.short ? 'userRoles' : presetRoles.userRoles.name;
    }
    if (isEqual(viewerRoles, roles)) {
        return options.short ? 'viewerRoles' : presetRoles.viewerRoles.name;
    }
    if (isEqual(adminRoles, roles)) {
        return options.short ? 'adminRoles' : presetRoles.adminRoles.name;
    }
    if (isEqual(rootRoles, roles)) {
        return options.short ? 'rootRoles' : presetRoles.rootRoles.name;
    }
    return options.short ? null : 'Custom roles';
};

export const getRoleObject = (roleId) => presetRoles[roleId]?.roles;
