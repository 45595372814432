const questionTypes = [
    'shortText',
    'longText',
    'number',
    'dropdown',
    'radio',
    'checkbox',
    'ratingScale',
    'binary',
    'date',
    'time',
];

export const isQuestion = (element) => questionTypes.includes(element.type);

export const questionTypeOptions = [
    { label: 'Short Answer', value: 'shortText' },
    { label: 'Long Answer', value: 'longText' },
    { label: 'Number', value: 'number' },
    { label: 'Date', value: 'date' },
    { label: 'Time', value: 'time' },
    { label: 'Dropdown', value: 'dropdown' },
    { label: 'Select One', value: 'radio' },
    { label: 'Select Many', value: 'checkbox' },
    { label: 'Rating Scale', value: 'ratingScale' },
    { label: 'True/False', value: 'binary' },
];
