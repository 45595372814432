import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'; // Adjust the path based on your project structure
import fr from './locales/fr.json'; // Adjust the path based on your project structure

// Retrieve the saved language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    lng: savedLanguage, // Use the saved language
    fallbackLng: 'en', // Fallback language if the desired one is unavailable
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
