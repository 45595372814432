import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalCard, ModalOverlay, Input, Button, Icon, IconButton, ScrollArea } from '../../../../shared';
import { fuzzySearch } from '../../../../../helpers/string';
import { PermissionModel } from '../../../_models';
import AccordianItem from '../AccordianItem';
import PermissionTable from '../../../_components/PermissionTable';
import { defaultRolesText } from '../../../_models/permissions/constants';
import SelectUsers from './SelectUsers';
import UserIcon from '../../../../Access/Management/User/UserIcon';

require('./styles.scss');

export default function EditMembers(props) {
    const {
        open,
        title,
        onClose,
        disabled,
        allUsers,
        projectUsers,
        project,
        projectPermissions,
        myUserId,
        myRole,
        allowEditMy,
    } = props;

    const [filter, setFilter] = useState('');

    const filteredUsers = fuzzySearch(filter, projectUsers, ['name.first', 'name.last']);
    const organizations = useSelector((state) => state.organizations);
    const myOrganization = useSelector((state) => state.app.organization);
    const dispatch = useDispatch();

    const [addMemberModal, setaddMemberModal] = useState(false);
    const [addMembers, setAddMembers] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(myOrganization._id);
    const [expandPermissions, setExpandPermissions] = useState(false);
    const unaddedMembers = allUsers.filter(
        (user) => projectUsers.indexOf(user) < 0 && user.organizationId === selectedOrganization,
    );
    const [newUserRoleText, setNewUserRoleText] = useState(defaultRolesText.viewDashboards);

    return (
        <ModalCard
            className="EditMembers__ModalCard"
            open={open}
            onClose={onClose}
            title={title}
            primaryButtonOptions={{ onClick: onClose, content: 'Done', id: 'submit' }}
        >
            <div className="EditMembers__Modal">
                <div className="EditMembers__Header">
                    <Input
                        className="EditMembers__SearchBar"
                        type="search"
                        gray
                        id="searchProjectMembers"
                        placeholder="Search This Project's Members"
                        onChange={(id, value) => setFilter(value)}
                        value={filter}
                    />
                    <Button onClick={() => setaddMemberModal(!addMemberModal)} disabled={disabled} blue>
                        Add Project Members
                    </Button>
                </div>

                <div className="EditMembers__Body">
                    <ScrollArea>
                        {filteredUsers.length ? (
                            filteredUsers.map((member) => (
                                <AccordianItem
                                    key={member._id}
                                    memberId={member._id}
                                    logo={
                                        <UserIcon
                                            user={member}
                                            firstName={member.name.first}
                                            lastName={member.name.last}
                                            userId={member._id}
                                            large
                                        />
                                    }
                                    title={`${member.name.first} ${member.name.last}`}
                                    subTitle={
                                        organizations.byId[member.organizationId]
                                            ? organizations.byId[member.organizationId].name
                                            : 'external'
                                    }
                                    userEmail={member.email}
                                    userPermissions={projectPermissions.find(
                                        (permissions) => permissions.userId === member._id,
                                    )}
                                    myRoles={myRole}
                                    disabled={disabled || (member._id === myUserId && !allowEditMy)}
                                    onChange={(permissionId, roles) => {
                                        dispatch(PermissionModel.update(permissionId, { roles }));
                                    }}
                                />
                            ))
                        ) : (
                            <div className="EditMembers__empty">
                                <Icon icon="large_color/question-circle" size="75px" />
                                <div className="EditMembers__empty--Title">No Project Members</div>
                                <div className="EditMembers__empty--Sub-Title">No members found in this project.</div>
                            </div>
                        )}
                    </ScrollArea>
                </div>
            </div>
            <ModalOverlay
                open={addMemberModal}
                primaryButtonOptions={{
                    content: 'Add Members',
                    onClick: () => {
                        addMembers.forEach((userId) => {
                            dispatch(
                                PermissionModel.create({
                                    userId,
                                    projectId: project._id,
                                    roles: newUserRoleText.roles,
                                }),
                            );
                        });
                        setaddMemberModal(false);
                        setAddMembers([]);
                    },
                }}
                secondaryButtonOptions={{ onClick: () => setaddMemberModal(false), content: 'Cancel' }}
                className="EditMembers__ModalOverlay"
            >
                <div className="EditMembers__ModalOverlay__main">
                    <SelectUsers
                        users={unaddedMembers}
                        value={addMembers}
                        onChange={(_, value) => setAddMembers(value)}
                        selectedOrganization={organizations.byId[selectedOrganization]}
                        setSelectedOrganization={(id) => {
                            setSelectedOrganization(id);
                        }}
                        organizations={organizations}
                    />
                    <div className="EditMembers__selectRoleInfo">
                        <Input
                            id="selectRoleId"
                            className="EditMembers__selectRole"
                            type="select"
                            label="Select Role"
                            gray
                            value={newUserRoleText.value}
                            options={Object.values(defaultRolesText)}
                            onChange={(id, roles) => {
                                setNewUserRoleText(defaultRolesText[roles]);
                            }}
                        />
                        <div className="EditMembers__roleInfo">{newUserRoleText.description}</div>
                    </div>
                    <IconButton
                        onClick={() => {
                            setExpandPermissions(!expandPermissions);
                        }}
                        className="EditMembers__permissionButton"
                        size="16px"
                        icon="settings"
                        selected={expandPermissions}
                        tooltip="Show Detailed Permissions"
                        mini
                    />
                </div>
                {expandPermissions && (
                    <PermissionTable
                        userId={addMembers[0]}
                        userRoles={newUserRoleText.roles}
                        onChange={(id, value) => {
                            const tempNewRole = { ...newUserRoleText };
                            tempNewRole.roles = { ...tempNewRole.roles, [id]: value };
                            if (id === 'model') tempNewRole.roles.package = value;
                            tempNewRole.label = 'Custom Role';
                            tempNewRole.value = 'custom';
                            tempNewRole.description = 'This user has customized permission settings.';
                            setNewUserRoleText(tempNewRole);
                        }}
                        myRoles={myRole}
                    />
                )}
            </ModalOverlay>
        </ModalCard>
    );
}

EditMembers.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
    projectPermissions: PropTypes.array,
    allUsers: PropTypes.array,
    projectUsers: PropTypes.array,
    project: PropTypes.shape({
        _id: PropTypes.string,
    }),
    myUserId: PropTypes.string.isRequired,
    myRole: PropTypes.shape({}),
    allowEditMy: PropTypes.bool,
};
EditMembers.defaultProps = {
    open: false,
    title: '',
    onClose: () => {},
    disabled: false,
    projectPermissions: [],
    allUsers: [],
    projectUsers: [],
    project: null,
    myRole: {},
    allowEditMy: false,
};
