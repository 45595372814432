import React from 'react';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from '../shared/ErrorBoundary';

export default function Projects() {
    return (
        <ErrorBoundary>
            <Outlet />
        </ErrorBoundary>
    );
}
