import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import Schema from './schema';

import { Types as appTypes } from '../app/reducers';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('organization', {
    toggleActivateRequest: ['id', 'form'],
    toggleActivateSuccess: ['id', 'organization'],
    toggleActivateFailure: ['id', 'errors'],
    togglePublicSharingRequest: ['id', 'form'],
    togglePublicSharingSuccess: ['id', 'organization'],
    togglePublicSharingFailure: ['id', 'errors'],
    showLogsRequest: ['id'],
    showLogsSuccess: ['id', 'logs'],
    showLogsFailure: ['id', 'errors'],
    updateLogoRequest: ['id', 'form'],
    updateLogoSuccess: ['id', 'response'],
    updateLogoFailure: ['id', 'errors'],
    getClientsRequest: ['id'],
    getClientsSuccess: ['id', 'organizations'],
    getClientsFailure: ['id', 'errors'],
});
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('organization', INITIAL_STATE, Schema, Types);

// ---------------- ACTION HANDLERS ----------------

export const toggleActivateSuccess = (state = INITIAL_STATE, { organization }) => {
    const byId = { ...state.byId };
    byId[organization._id] = { ...byId[organization._id], active: organization.active };
    return { ...state, errors: {}, byId, hydrated: true };
};

export const togglePublicSharingSuccess = (state = INITIAL_STATE, { organization }) => {
    const byId = { ...state.byId };
    byId[organization._id] = { ...byId[organization._id], publicSharing: organization.publicSharing };
    return { ...state, errors: {}, byId, hydrated: true };
};

export const showLogsSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [action.id]: {
            ...state.byId[action.id],
            errors: {},
            log: action.logs,
            hydrated: true,
        },
    },
});

export const updateLogoSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    errors: {},
    byId: {
        ...state.byId,
        [action.id]: {
            ...state.byId[action.id],
            errors: {},
            logo: action.response.logo,
            hydrated: true,
        },
    },
});

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(
    ['TOGGLE_ACTIVATE', 'TOGGLE_PUBLIC_SHARING', 'SHOW_LOGS', 'UPDATE_LOGO', 'GET_CLIENTS'],
    Types,
    {
        [Types.TOGGLE_ACTIVATE_SUCCESS]: toggleActivateSuccess,
        [Types.TOGGLE_PUBLIC_SHARING_SUCCESS]: togglePublicSharingSuccess,
        [Types.SHOW_LOGS_SUCCESS]: showLogsSuccess,
        [Types.UPDATE_LOGO_SUCCESS]: updateLogoSuccess,
        [Types.GET_CLIENTS_SUCCESS]: (state, { organizations }) =>
            crudReducers[Types.INDEX_SUCCESS](state, { organizations: { list: organizations } }),
        [appTypes.GET_MY_ORGANIZATION_SUCCESS]: (state, { organization }) =>
            crudReducers[Types.SHOW_SUCCESS](state, { id: organization._id, organization }),
    },
);
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
