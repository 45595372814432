import ApiBase from '../../../../models/base/api';

export class AlgorithmApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('algorithms', addProjectIdToSearchParams);
    }
    createModel = (id, body) => this.request('POST', `${this.endpoint}/${id}/createModel`, body);
}

export default new AlgorithmApi();
