import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Loader, Icon, Input, SidePanel, ScrollArea } from '../../../shared';
import SelectableItem from '../../../shared/SelectableItem';
import InputConfig from './InputConfig';
import DashboardPreview from './preview';
import PopOver from '../../../Access/_components/PopOver';
import SidePanelButton from '../../../shared/SidePanel/SidePanelButton';
import CodeEditor from '../../_components/CodeEditor';

require('./styles.scss');

const ModelInputs = (props) => {
    const { model, packageId, moduleFileName, updateModel } = props;

    const [inputId, setInputId] = useState(null);

    const [runButtonText, setRunButtonText] = useState(model.runButtonText);

    const previewComponent = (
        <div className="ModelInputs__previewDisplay">
            <div className="ModelInputs__previewDisplay__header">
                <span>Model UI Preview:</span>
                <Input
                    id="changeRunButtonText"
                    type="text"
                    value={runButtonText === undefined ? model.runButtonText : runButtonText}
                    label="'Run' Button Text"
                    onSubmit={() => updateModel({ runButtonText })}
                    onChange={(_, value) => setRunButtonText(value)}
                    submitOnBlur
                    info="This option will change the text of the run button on the model bar, but not in the model panel."
                    gray
                    sm
                    inlineLabel
                />
            </div>
            <DashboardPreview model={model} />
        </div>
    );
    const bodyText = (
        <div>
            You can click <SidePanelButton id="openModelPanel" icon="menu-horizontal" onClick={() => {}} /> to toggle
            between model bar and model panel.
        </div>
    );

    return model?.inputs ? (
        <div className="ModelInputs">
            <ScrollArea className="ModelInputs__selectors">
                <div className="ModelInputs__selectorsContent">
                    {model.inputs.length ? (
                        model.inputs.map((input) => {
                            const inputNeedsConfiguring =
                                !input.dataType ||
                                (!input.defaultValue && input.defaultValue !== false && input.defaultValue !== 0);
                            return (
                                <SelectableItem
                                    key={input.name}
                                    name={input.name}
                                    info={`${input.dataType || 'Type Not Detected'}${
                                        input.varName !== input.name ? ` (${input.varName})` : ''
                                    }`}
                                    onClick={() => setInputId(input._id)}
                                    selected={input._id === inputId}
                                    type={
                                        inputNeedsConfiguring ? (
                                            <Icon
                                                size="20px"
                                                icon="exclamation-triangle"
                                                className="ModelInputs__selector__warningIcon"
                                            />
                                        ) : null
                                    }
                                />
                            );
                        })
                    ) : (
                        <div className="ModelInputs__selectorsContent--empty">
                            <SelectableItem name="No inputs" deactivated onClick={() => {}} />
                        </div>
                    )}
                </div>
            </ScrollArea>

            <div className="ModelInputs__main">
                <SidePanel
                    tabs={[
                        {
                            icon: 'visible',
                            component: previewComponent,
                            tooltip: 'View Preview',
                        },
                        {
                            icon: 'code',
                            component: <CodeEditor packageId={packageId} defaultFileName={moduleFileName} />,
                            tooltip: 'Source Code',
                        },
                    ]}
                    width="50%"
                    startOpen
                >
                    <PopOver
                        body={bodyText}
                        arrow="top"
                        buttonText="Neato"
                        type="modelPopovers"
                        style={{ top: '305px', right: '10px' }}
                        neverShow
                    />
                    {model.inputs.length ? (
                        <ScrollArea>
                            <InputConfig key={inputId} model={model} updateModel={updateModel} inputId={inputId} />
                        </ScrollArea>
                    ) : (
                        <div className="ModelInputs__empty">This model has no inputs.</div>
                    )}
                </SidePanel>
            </div>
        </div>
    ) : (
        <Loader />
    );
};

ModelInputs.propTypes = {
    model: PropTypes.shape({
        _id: PropTypes.string,
        inputs: PropTypes.array,
        runButtonText: PropTypes.string,
        sourceCode: PropTypes.string,
        resultStorage: PropTypes.string,
        hydrated: PropTypes.bool,
    }),
    updateModel: PropTypes.func.isRequired,
    packageId: PropTypes.string,
    moduleFileName: PropTypes.string,
};
ModelInputs.defaultProps = {
    model: null,
    packageId: '',
    moduleFileName: null,
};

export default ModelInputs;
