import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import FeatureModel from '../../_models/feature';
import AddFilterButton from '../AddFilterButton';
import Filter from '../Filter';
import FilterSummary from '../Summary';

import { Chip, Icon, ContextMenu, ContextSubMenu, ContextMenuItem } from '../../../shared';

import './styles.scss';

export default function ChipFilter(props) {
    const dispatch = useDispatch();

    const datasets = useSelector((state) => state.datasets);
    const features = useSelector((state) => state.features);

    const loadCategories = (id) => dispatch(FeatureModel.categories(id)); // load categories if not already there

    const handleFeatureSelect = (id) => {
        const feature = features.byId[id];
        if (feature.type === 'category' && !feature.categories?.length && !feature.loading) loadCategories(id);
    };

    const { id, value, onChange, loading, left, datasetIds } = props;

    const [newFilterValue, setNewFilterValue] = useState({});
    const [openFilterContextMenu, setOpenFilterContextMenu] = useState(false);

    const handleChange = (filterId, newValue) => {
        const nextValue = [...value];
        let key = nextValue.findIndex((val) => val._id === filterId);
        if (key === -1) key = filterId;
        if (newValue) {
            nextValue.splice(key, 1, newValue);
        } else {
            nextValue.splice(key, 1);
        }
        onChange(id, nextValue);
    };

    const clearAll = () => onChange(id, []);

    const handleAddFilter = (_, newValue) => {
        setNewFilterValue({});
        onChange(id, [...value, newValue]);
    };

    const toggleFilterContextMenu = () => {
        setOpenFilterContextMenu(!openFilterContextMenu);
    };

    if (loading) {
        return (
            <div className="FilterBar FilterBar--loading">
                <Icon icon="filter" size="20px" />
            </div>
        );
    }

    return (
        <Chip
            onClick={toggleFilterContextMenu}
            highlighted={value.length > 0}
            active={openFilterContextMenu}
            tooltip={value.length > 0 ? <FilterSummary value={value} /> : 'Series Filter'}
        >
            <Icon icon="filter" size="12px" />
            {value.length > 0 && <div className="Chip__iconText">{`${value.length}`}</div>}
            <ContextMenu
                className="IconButtonFilter__contextMenu"
                open={openFilterContextMenu}
                onClickOut={() => setOpenFilterContextMenu(false)}
                listHeading="Column Filters"
            >
                {value.length === 0 ? (
                    <ContextSubMenu>
                        <AddFilterButton
                            showTextContextMenu
                            value={newFilterValue}
                            onChange={handleAddFilter}
                            right={!left}
                            left={left}
                            dark
                            datasetIds={datasetIds}
                            datasets={datasets}
                            features={features}
                            onFeatureSelect={handleFeatureSelect}
                        />
                        <ContextMenuItem
                            id="clearAll"
                            name="Clear All"
                            onClick={clearAll}
                            type={<Icon icon="times" size="16px" />}
                        />
                    </ContextSubMenu>
                ) : (
                    <React.Fragment>
                        <div className="IconButtonFilter__Array">
                            {value.map((filterValue, i) => (
                                <ContextSubMenu key={filterValue._id || i}>
                                    <Filter
                                        id={filterValue._id || i.toString()}
                                        value={filterValue}
                                        onChange={handleChange}
                                        iconRight
                                        right={!left}
                                        left={left}
                                        dark
                                        arrayDisplay
                                        datasetIds={datasetIds}
                                        datasets={datasets}
                                        features={features}
                                        onFeatureSelect={handleFeatureSelect}
                                    />
                                </ContextSubMenu>
                            ))}
                        </div>
                        <ContextSubMenu>
                            <AddFilterButton
                                showTextContextMenu
                                value={newFilterValue}
                                onChange={handleAddFilter}
                                right={!left}
                                left={left}
                                dark
                                datasetIds={datasetIds}
                                datasets={datasets}
                                features={features}
                                onFeatureSelect={handleFeatureSelect}
                            />
                            <ContextMenuItem
                                id="clearAll"
                                name="Clear All"
                                onClick={clearAll}
                                type={<Icon icon="times" size="16px" />}
                            />
                        </ContextSubMenu>
                    </React.Fragment>
                )}
            </ContextMenu>
        </Chip>
    );
}

ChipFilter.propTypes = {
    id: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            option: PropTypes.string,
            value: PropTypes.shape({}),
            feature: PropTypes.shape({ id: PropTypes.string }),
        }),
    ),
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    left: PropTypes.bool,
};
ChipFilter.defaultProps = {
    value: null,
    datasetIds: [],
    onChange: () => {},
    loading: false,
    id: '',
    left: false,
};
