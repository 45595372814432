const getUserInfo = (userId) => (state) => {
    const { users, app } = state;
    if (userId === app.user._id) {
        return { ...app.user, current: true };
    }
    return users.byId[userId];
};

export default {
    getUserInfo,
};
