/* eslint-disable */
import { ConversationChain } from 'langchain/chains';
import { ChatOpenAI } from 'langchain/chat_models/openai';

import OpenAI, { Configuration, OpenAIApi } from "openai";


import {
  ChatPromptTemplate,
  SystemMessagePromptTemplate,
  HumanMessagePromptTemplate,
  MessagesPlaceholder,
} from 'langchain/prompts';
import { BufferMemory } from 'langchain/memory';

const memory = new BufferMemory({
  returnMessages: true,
  memoryKey: 'history',
});

export const davinci = async (prompt, key, gptVersion) => {
  const chatPrompt = ChatPromptTemplate.fromMessages([
    SystemMessagePromptTemplate.fromTemplate(
      'The following is a friendly conversation between a human and an AI. The AI is talkative and provides lots of specific details from its context and always responds in markdown format. If the AI does not know the answer to a question, it truthfully says it does not know.'
    ),
    new MessagesPlaceholder('history'),
    HumanMessagePromptTemplate.fromTemplate('{input}'),
  ]);

  const model = new ChatOpenAI({
    openAIApiKey: key,
    modelName: gptVersion,
    temperature: 0.3,
  });

  // const openai = new OpenAI({
  //     apiKey: key,
  //     dangerouslyAllowBrowser: true
  //   });
  // Non-streaming:
  // const completion = await openai.chat.completions.create({
  //   model: 'gpt-4',
  //   messages: [{ role: 'user', content: 'Say this is a test' }],
  // });
  // console.log(completion.choices[0]?.message?.content);


  // openai.organization = "org-YEcxPuRXirX2P5szSqFX10DZ"
  // const configuration = new Configuration({
  //     organization: "org-YEcxPuRXirX2P5szSqFX10DZ",
  //     apiKey: key,
  // });
  // const engines = await openai.listEngines();
  //
  // console.log("OPENAI engines::", engines)


  const chain = new ConversationChain({
    memory: memory,
    prompt: chatPrompt,
    llm: model,
  });

  const response = await chain.call({ input: prompt });

  return response.response;
};
