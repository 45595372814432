import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Job from './Job';

import ScrollArea from '../ScrollArea';

require('./styles.scss');

export default class JobQueue extends Component {
    constructor(props) {
        super(props);
        const { defaultOpen } = props;
        this.state = { isOpen: defaultOpen, openedJobIndex: -1 };
    }

    componentDidUpdate(prevProps) {
        // open upload queue if new in-progress job is added
        const { jobs, defaultOpen } = this.props;
        if (prevProps.jobs.length !== jobs.length && defaultOpen) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isOpen: true });
        }
    }

    handleJobOpen = (index) => {
        const { openedJobIndex } = this.state;
        this.setState({ openedJobIndex: openedJobIndex === index ? -1 : index });
    };
    toggleOpen = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    render() {
        const { jobs, title } = this.props;
        const { isOpen, openedJobIndex } = this.state;
        return (
            <div className="JobQueue">
                <div className="JobQueue__title" onClick={this.toggleOpen} role="menuitem" tabIndex={-1}>
                    <div>{title}</div>
                    <Icon icon={isOpen ? 'angle-down' : 'angle-up'} size="20px" />
                </div>
                <ScrollArea className="JobQueue__Jobs">
                    {isOpen &&
                        jobs.map((job, i) => (
                            <Job
                                {...job}
                                onClick={() => this.handleJobOpen(i)}
                                expanded={openedJobIndex === i}
                                key={job._id}
                            />
                        ))}
                </ScrollArea>
            </div>
        );
    }
}

JobQueue.propTypes = {
    jobs: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
            user: PropTypes.shape({ id: PropTypes.string }),
            status: PropTypes.string,
            type: PropTypes.string,
            ownerId: PropTypes.string,
            ownerType: PropTypes.string,
            timeCreated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
            lastUpdated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
            action: PropTypes.func,
        }),
    ),
    defaultOpen: PropTypes.bool,
    title: PropTypes.string,
};

JobQueue.defaultProps = {
    jobs: [],
    defaultOpen: false,
    title: '',
};
