import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from 'react-grid-layout';
import UnitCard from '../../../../Units/Card';
import GridPlaceholder from './Placeholder';
import { getBreakPoint, getColumnCount, getResponsiveLayouts } from './helpers';
import { defaultGridProps } from './constants';

// previously used WidthProvider(Responsive) -- see https://github.com/STRML/react-grid-layout#providing-grid-width
// switched away from this because WidthProvider does not respond to adding a model panel to the right side of the screen
// passing a `width` prop from its parent component instead

// const GridLayout = Responsive;
const GridLayout = WidthProvider(Responsive);


require('./styles.scss');

export default class DashboardGrid extends Component {
    onLayoutChange = (currentLayout, allLayouts) => {
        const { unitsLoading, layout, onLayoutChange } = this.props;
        const breakpoint = getBreakPoint();
        if (breakpoint !== 'desktop' || JSON.stringify(currentLayout) === JSON.stringify(allLayouts.mobile)) return; // deals with cases when switching to/from mobile size, opening mapping modal (-> size goes to 0)
        if (
            currentLayout.length > 0 &&
            (!layout || (!unitsLoading && JSON.stringify(currentLayout) !== JSON.stringify(layout)))
        ) {
            onLayoutChange(currentLayout);
        }
    };

    handleCardCreate = (layoutItem) => {
        const { onCardCreate, id } = this.props;
        onCardCreate(id, { layoutItem: { ...layoutItem, moved: false, static: false } });
    };

    getCardWidth = (unitId) => {
        const { width, layout } = this.props;
        const cardItem = layout.find((item) => item.i === unitId);
        const columns = getColumnCount();
        return ((width - 20 * (columns - 1)) / columns) * cardItem.w + 20 * (cardItem.w - 1);
    };

    render() {
        const {
            id,
            editable,
            hydrated,
            unitIds,
            unitsHydrated,
            layout,
            dashboardId,
            publicFilters,
            width,
        } = this.props;

        if (unitIds.length === 0)
            return (
                <GridPlaceholder
                    id="add-prompt"
                    addPrompt
                    editable={editable}
                    layoutItem={{ x: 0, y: 0, w: 12, h: 3 }}
                    onClick={this.handleCardCreate.bind(this)}
                />
            );

        const breakpoint = getBreakPoint();

        return (
            hydrated &&
            unitsHydrated && (
                <GridLayout
                    {...defaultGridProps}
                    width={width}
                    className="Grid animated"
                    layouts={getResponsiveLayouts(layout)}
                    fillGaps={editable}
                    lastRowGaps={editable ? 2 : 0}
                    GapComponent={(gapLayoutItem) => (
                        <GridPlaceholder
                            id={gapLayoutItem.i}
                            layoutItem={gapLayoutItem}
                            editable={editable}
                            onClick={this.handleCardCreate.bind(this)}
                        />
                    )}
                    isDraggable={editable && breakpoint === 'desktop'}
                    isResizable={editable && breakpoint === 'desktop'}
                    isBounded={editable && breakpoint === 'desktop'}
                    onLayoutChange={this.onLayoutChange}
                >
                    {unitIds.map((unitId) => (
                        <div key={unitId}>
                            <UnitCard
                                layoutItem={layout.find((item) => item.i === unitId)}
                                id={unitId}
                                tabId={id}
                                dashboardId={dashboardId}
                                editable={editable}
                                draggableHandleClassName="DashboardGrid__draggableHandle"
                                publicFilters={publicFilters}
                                width={this.getCardWidth(unitId)}
                            />
                        </div>
                    ))}
                </GridLayout>
            )
        );
    }
}

DashboardGrid.propTypes = {
    id: PropTypes.string.isRequired,
    dashboardId: PropTypes.string,
    unitIds: PropTypes.arrayOf(PropTypes.string),
    onLayoutChange: PropTypes.func,
    onCardCreate: PropTypes.func,
    editable: PropTypes.bool,
    hydrated: PropTypes.bool,
    loading: PropTypes.bool,
    unitsHydrated: PropTypes.bool,
    unitsLoading: PropTypes.bool,
    layout: PropTypes.array,
    publicFilters: PropTypes.array,
    width: PropTypes.number,
};

DashboardGrid.defaultProps = {
    dashboardId: null,
    unitIds: [],
    onLayoutChange: () => {},
    onCardCreate: () => {},
    editable: true,
    hydrated: false,
    loading: false,
    unitsHydrated: false,
    unitsLoading: false,
    layout: [],
    publicFilters: [],
    width: null,
};
