import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import LoginModel from '../../_models/login';
import { passwordValidations } from '../../constants';
import logo from '../../../../assets/logo.svg';

import Validations from '../../_components/Validations';
import { Button, Card, Input } from '../../../shared';

require('./styles.scss');

export default function LoginReset() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();

    const { resetCode } = useParams();

    const loading = useSelector((state) => state.login.loading);
    const dispatch = useDispatch();
    const onSubmit = (form) => dispatch(LoginModel.resetPassword(form, resetCode));

    useEffect(() => {
        dispatch(LoginModel.checkResetCode(resetCode)).then((action) => {
            if (action.type === 'CHECK_RESET_CODE_FAILURE') navigate('/login');
        });
        // navigate function identity is not stable so I'm not including it in dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, resetCode]);

    const handleSubmit = () => {
        if (!passwordValidations.every((val) => val.validate({ password, confirmPassword }))) return;
        onSubmit({ password, confirmPassword }, resetCode).then((action) => {
            if (action.type === 'RESET_PASSWORD_SUCCESS') navigate('/login');
        });
    };

    return (
        <Card className="Login__Card">
            <img src={logo} alt="logo" />
            <div className="Login_Reset">
                <div className="Login__Title">Password Reset</div>
                <main>
                    <Input
                        type="password"
                        id="password"
                        placeholder="new password"
                        value={password}
                        onChange={(_, value) => setPassword(value)}
                        onSubmit={handleSubmit}
                        gray
                        required
                    />
                    <Input
                        type="password"
                        id="confirmPassword"
                        placeholder="confirm password"
                        value={confirmPassword}
                        onChange={(_, value) => setConfirmPassword(value)}
                        onSubmit={handleSubmit}
                        gray
                        required
                    />
                    <Validations validations={passwordValidations} form={{ password, confirmPassword }} />
                </main>
            </div>
            <footer className="Login__buttons">
                <Button blue id="submit" className="Login__button--right" onClick={handleSubmit} loading={loading}>
                    Reset Password
                </Button>
            </footer>
        </Card>
    );
}
