import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ModelModel, ModelRunModel } from '../../_models';
import { getModelRunStatusLabel, mergeModelInputs } from './helpers';

import ButtonWithOptions from '../ButtonWithOptions';
import RunInfoModal from '../RunInfoModal';
import { Loader } from '../../../shared';

import './styles.scss';

export default function ModelHistory(props) {
    const { disabled, isPublic, model, modelRuns, onSelect } = props;
    const dispatch = useDispatch();

    const modelModelRuns =
        model && model.runs
            ? model.runs
                  .map((runId) => modelRuns.byId[runId])
                  .filter((run) => run !== undefined) // This kind of logic should be in a selector not in a component.
                  .sort((run1, run2) => (run1.executionDatetime > run2.executionDatetime ? -1 : 1))
            : [];
    // a note: we're assuming modelRuns always have an executionDatetime.
    // modelRuns are not guaranteed to have lastUpdated (eg. the algo service does not create documents with a lastUpdated timestamp currently)

    let selectedRunId = model.jobId;
    let clickable = true;
    if (selectedRunId === 'latest') {
        const selectedRun = modelModelRuns.find((run) => run.status === 'finished');
        clickable = false;
        selectedRunId = selectedRun && selectedRun._id;
    }

    const [editDescriptionModalId, setEditDescriptionModal] = useState(false);
    const editDescriptionModalRun = modelRuns.byId[editDescriptionModalId] || {};

    const cancelModelRun = (modelRunId) => {
        dispatch(ModelRunModel.cancel(modelRunId));
    };

    const deleteModelRun = (modelRunId) => {
        dispatch(ModelRunModel.destroy(modelRunId));
    };

    const setInputVaues = (runId) => {
        const newInputs = mergeModelInputs(model, modelRuns.byId[runId]);
        if (newInputs) {
            dispatch(ModelModel.updateLocal(model._id, { inputs: newInputs }));
        }
    };

    const handleSelect = (runId) => {
        if (runId === modelModelRuns[0]._id) {
            if (model.jobId === 'latest') return false; // do nothing
            return onSelect('latest');
        }
        if (model.jobId === runId) return onSelect('latest'); // unselect
        return onSelect(runId);
    };

    const addToFavourite = (runId) => {
        if (modelRuns.byId[runId]?.favourite !== true || !modelRuns.byId[runId]?.favourite) {
            dispatch(ModelRunModel.update(runId, { favourite: true }));
        } else {
            dispatch(ModelRunModel.update(runId, { favourite: false }));
        }
    };
            
    const buttons = modelModelRuns.map((run) => {
        const label = getModelRunStatusLabel(run);
        let icon = 'brain';

        if (run?.favourite) {
            icon = 'star';
        } else {
            if (run.status === 'finished') icon = 'checkmark';
            if (run.status === 'failed') icon = 'exclamation-triangle';
            if (run.status === 'cancelled') icon = 'times';
            if (run.status === 'expired') icon = 'clock';    
        }
    
        const options = [
            {
                id: 'load-inputs',
                name: 'Load Input Values',
                onClick: () => setInputVaues(run._id),
            },
            {
                id: 'add-desc',
                name: 'Model Run Info',
                onClick: () => setEditDescriptionModal(run._id),
            },
        ];
        if (isPublic) options.pop(); // public users can view input values but not model run info

        let deleteFunc;
        if (!['finished', 'failed', 'cancelled'].includes(run.status)) {
            options.push({
                id: 'cancel',
                name: 'Cancel Model Run',
                onClick: () => {
                    cancelModelRun(run._id);
                },
            });
        } else {
            deleteFunc = () => deleteModelRun(run._id);
        }

        return (
            <ButtonWithOptions
                key={run._id}
                icon={icon} // Default icon or 'brain' based on state
                description={run.description}
                onClick={
                    model.resultStorage === 'all' && // if past runs aren't stored, the user doesn't have the option to click this
                    run.status === 'finished' && // only finished runs are stored
                    (run._id !== selectedRunId || clickable) && // if the selected run is the latest run, there is no hover state
                    (() => handleSelect(run._id))
                }
                name={run.name || model.name || ''}
                label={label}
                failed={run.status === 'failed'}
                selected={run._id === selectedRunId}
                loading={run.status === 'started'}
                options={options}
                deleteFunc={deleteFunc}
                deleteText="Delete Run"
                iconFunc={() => addToFavourite(run._id, icon)} // Pass original icon to toggle function
            />
        );
    });
    if (buttons.length === 0) {
        if (!model || !model.runs) return <Loader type="spinner" loader="scale" height={20} width={1} radius={1} />;
        if (disabled) return <div className="ModelHistory__message">This model has not yet been run.</div>;
        return (
            <div className="ModelHistory__message">
                This model has not yet been run. Click the run button to get started.
            </div>
        );
    }
    return (
        <div className="ModelHistory">
            {model.resultStorage !== 'all' && (
                <div className="ModelHistory__message">Data for previous runs not available for this model</div>
            )}
            {buttons}
            {!isPublic && (
                <RunInfoModal
                    modelRun={editDescriptionModalRun}
                    modelName={model.name}
                    onClose={() => setEditDescriptionModal(false)}
                />
            )}
        </div>
    );
}

ModelHistory.propTypes = {
    model: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        jobId: PropTypes.string,
        resultStorage: PropTypes.string,
        runs: PropTypes.array,
    }).isRequired,
    modelRuns: PropTypes.shape({ allIds: PropTypes.array, byId: PropTypes.shape({}) }).isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired, // whether the model panel is disabled
    isPublic: PropTypes.bool,
};

ModelHistory.defaultProps = {
    isPublic: false,
};
