export const makeLogItemTitle = (item) => {
    switch (item.type) {
        case 'login':
            return `${item.email} logged in`;
        case 'login-microsoft':
            return `${item.email} logged in via Microsoft`;
        case 'logout':
            return `${item.email} logged out`;
        case 'login-attempt':
            return `Failed login attempt for ${item.email}`;
        default:
            return 'Unknown event';
    }
};

export const logoValidations = [
    {
        validate: (file) => file.size < 16000, // validation does not match the label.
        label: 'Do not exceed 90 x 90px',
        error: 'file too large',
    },
    {
        validate: (file) => file.name.match(/\.png$/),
        label: 'accepted: png',
        error: 'wrong file type',
    },
];
