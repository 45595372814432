import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DatasetModel from '../_models/dataset';
import CollectionsList from './CollectionsList';
import DatasetsList from './DatasetsList';
import UploadQueue from './UploadQueue';
import { Input, ScrollArea } from '../../shared';

import { fuzzySearch } from '../../../helpers/string';
import { getImportedDatasets } from '../helpers';

require('./styles.scss');

const defaultListId = 'unset-WW123';

export default function DataSidebar({ handleNavigateToDataset }) {
    const datasets = useSelector((state) => state.datasets || { allIds: [], byId: {} });
    const roles = useSelector((state) => state.app.permission?.roles);
    const collectionIds = useSelector((state) => state.collections.allIds);
    const canEditDatasets = roles?.dataset === 'edit';

    const dispatch = useDispatch();
    const updateDataset = (datasetId, obj) => dispatch(DatasetModel.update(datasetId, obj));

    const [filter, setFilter] = useState('');

    const handleDragDrop = (collectionId, datasetId, oldCollectionId) => {
        let collections = datasets.byId[datasetId].collections;
        if (!collections.includes(collectionId)) collections.push(collectionId);
        if (collections.indexOf(defaultListId) !== -1) {
            collections = collections.filter((cId) => ![oldCollectionId, defaultListId].includes(cId));
        }
        updateDataset(datasetId, { collections });
    };

    const importedDatasets = getImportedDatasets(datasets);
    const filteredDatasets = fuzzySearch(filter, importedDatasets);

    const datasetsWithoutCollections = filteredDatasets.filter(
        (dataset) =>
            !dataset.collections ||
            dataset.collections.length === 0 ||
            !dataset.collections.filter((collectionId) => collectionIds.includes(collectionId)).length,
    );

    return (
        <div className="DataSidebar">
            <Input
                type="search"
                id="search"
                className="Data__searchBar"
                placeholder="Search datasets"
                value={filter}
                onChange={(id, value) => setFilter(value)}
            />
            <ScrollArea>
                <div className="DataSidebar__content">
                    <CollectionsList
                        datasets={filteredDatasets}
                        handleNavigate={handleNavigateToDataset}
                        canEditDatasets={canEditDatasets}
                        handleDragDrop={handleDragDrop}
                    />
                    <DatasetsList
                        defaultListId={defaultListId}
                        datasets={datasets}
                        datasetsList={datasetsWithoutCollections}
                        handleNavigate={handleNavigateToDataset}
                        canEditDatasets={canEditDatasets}
                        handleDragDrop={handleDragDrop}
                    />
                </div>
            </ScrollArea>
            <UploadQueue canEditDatasets={canEditDatasets} />
        </div>
    );
}

DataSidebar.propTypes = {
    handleNavigateToDataset: PropTypes.func.isRequired,
};

DataSidebar.defaultProps = {};
