import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------
// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('trigger', {
    createRequest: ['form'],
    createSuccess: ['form', 'response'],
    createFailure: ['form', 'errors'],
    pauseRequest: ['id'],
    pauseSuccess: ['id', 'response'],
    pauseFailure: ['id', 'errors'],
    activateRequest: ['id'],
    activateSuccess: ['id', 'response'],
    activateFailure: ['id', 'errors'],
});
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('trigger', INITIAL_STATE, Schema, Types);
const additionalReducers = apiHandlers(['CREATE', 'PAUSE', 'ACTIVATE'], Types, {
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('triggers'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
