import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeatureBasket from '../../../components/FeatureBasket';
import SerumOperationButton from '../../../components/SerumButton/operation';
import { Chip, Icon, ContextMenu, ContextSubMenu, ContextMenuItem } from '../../../../shared';

export default function OrderByBasket({ features, datasets, showSourceNames, operationId, onChange, dndProps, unit }) {
    const { serum, containerIndex } = dndProps;
    const feature = features.byId[serum.feature?._id];
    const dataset = datasets.byId[feature?.dataset._id];
    const datasetName = showSourceNames && dataset?.name;

    const dependentAxis = unit.vis.display.dependentAxis;

    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    const handleChange = (value) => {
        if (dependentAxis === 'x') {
            if (value === 'ASC') onChange('orderType', 'DESC');
            else onChange('orderType', 'ASC');
        } else {
            onChange('orderType', value);
        }
    };

    const iconType = () => {
        if (dependentAxis === 'x') {
            return serum.type === 'ASC' ? 'descending' : 'ascending';
        }
        return serum.type === 'ASC' ? 'ascending' : 'descending';
    };

    const iconText = () => {
        if (dependentAxis === 'x') {
            return serum.type === 'ASC' ? 'Desc' : 'Asc';
        }
        return serum.type === 'ASC' ? 'Asc' : 'Desc';
    };

    return (
        <FeatureBasket {...dndProps} datasetName={datasetName} feature={feature}>
            <div className="ChartMapping__AxisConfig">
                <SerumOperationButton
                    id={`${operationId}-${containerIndex}`}
                    value={serum.operation}
                    onChange={onChange}
                    feature={feature}
                    binValues={{ id: `${operationId}NumberOfBins-${containerIndex}`, value: serum.numberOfBins }}
                />
                <Chip onClick={toggleOpen} active={open} tooltip="Direction">
                    <Icon
                        className={
                            serum.type === 'ASC'
                                ? `ascendingIcon--${dependentAxis}`
                                : `descendingIcon--${dependentAxis}`
                        }
                        icon={iconType()}
                    />
                    <div className="Chip__iconText">{iconText()}</div>
                    <ContextMenu open={open} className="OperationBasket__menu" onClickOut={() => setOpen(false)}>
                        <ContextSubMenu>
                            <ContextMenuItem
                                id="ASC"
                                name={dependentAxis === 'x' ? 'Ascending' : 'Descending'}
                                type={
                                    <Icon className={`ascendingIcon--${dependentAxis}`} icon="ascending" size="16px" />
                                }
                                onClick={handleChange}
                            />
                            <ContextMenuItem
                                id="DESC"
                                name={dependentAxis === 'x' ? 'Descending' : 'Ascending'}
                                type={
                                    <Icon
                                        className={`descendingIcon--${dependentAxis}`}
                                        icon="descending"
                                        size="16px"
                                    />
                                }
                                onClick={handleChange}
                            />
                        </ContextSubMenu>
                    </ContextMenu>
                </Chip>
            </div>
        </FeatureBasket>
    );
}

OrderByBasket.propTypes = {
    onChange: PropTypes.func.isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        loading: PropTypes.bool,
    }).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    showSourceNames: PropTypes.bool,
    operationId: PropTypes.string,
    dndProps: PropTypes.shape({
        serum: PropTypes.shape({
            id: PropTypes.string,
            feature: PropTypes.shape({ _id: PropTypes.string }),
            operation: PropTypes.string,
            type: PropTypes.string,
            numberOfBins: PropTypes.number,
        }),
        containerIndex: PropTypes.number,
    }).isRequired,
    unit: PropTypes.shape({
        vis: PropTypes.shape({
            display: PropTypes.shape({ dependentAxis: PropTypes.string }),
        }),
    }),
};

OrderByBasket.defaultProps = {
    operationId: '',
    showSourceNames: false,
    unit: {},
};
