export const getMergedFeatureUpdates = (tempDataset, matchingPreviousDataset) => {
    const featureUpdates = {};
    const unmatchedPreviousFeatures = new Set(matchingPreviousDataset.features.map((feature) => feature._id));
    tempDataset.features.forEach((tempFeature) => {
        // find matching feature based on name or db name
        const matchingFeature = matchingPreviousDataset.features.find(
            (previousFeature) =>
                (previousFeature.name === tempFeature.name || previousFeature.db.name === tempFeature.db.name) &&
                unmatchedPreviousFeatures.has(previousFeature._id),
        );
        if (matchingFeature) {
            unmatchedPreviousFeatures.delete(matchingFeature._id);
        }
        featureUpdates[tempFeature._id] = {
            tempFeatureId: tempFeature._id,
            previousFeatureId: matchingFeature?._id || null,
        };
    });
    [...unmatchedPreviousFeatures].forEach((previousFeatureId) => {
        featureUpdates[previousFeatureId] = { tempFeatureId: null, previousFeatureId };
    });
    return featureUpdates;
};

export const getMergedDatasetUpdates = (mergedTempOutputs, mergedPreviousOutputs) => {
    const newMapping = {};
    const unmatchedPreviousDatasets = new Set(Object.values(mergedPreviousOutputs).map((dataset) => dataset._id));

    Object.values(mergedTempOutputs).forEach((tempDataset, index) => {
        let featureUpdates = {};

        const existingFeatureNames =
            Object.values(mergedPreviousOutputs)
                .map((dataset) => dataset.features)
                [index]?.map((feature) => feature.name) || [];
        const newFeatureNames = tempDataset.features.map((feature) => feature.name);
        const matchedFeatures = newFeatureNames.filter((newFeatureName) =>
            existingFeatureNames.includes(newFeatureName),
        );
        const percentageMatch = matchedFeatures.length / newFeatureNames.length;
        const matchingDataset = Object.values(mergedPreviousOutputs).find(
            (dataset) =>
                percentageMatch >= 0.5 && // if more than half of the new features are already in the existing dataset
                unmatchedPreviousDatasets.has(dataset._id),
        );

        if (matchingDataset) {
            unmatchedPreviousDatasets.delete(matchingDataset._id);
            // check all the features
            featureUpdates = getMergedFeatureUpdates(tempDataset, matchingDataset);
        } else {
            // all the features are new if dataset is new
            tempDataset.features.forEach((tempFeature) => {
                featureUpdates[tempFeature._id] = {
                    tempFeatureId: tempFeature._id,
                    previousFeatureId: null,
                };
            });
        }

        newMapping[tempDataset._id] = {
            tempDatasetId: tempDataset._id,
            previousDatasetId: matchingDataset?._id || null,
            features: featureUpdates,
        };
    });

    [...unmatchedPreviousDatasets].forEach((previousDatasetId) => {
        newMapping[previousDatasetId] = { tempDatasetId: null, previousDatasetId };
    });

    return newMapping;
};
