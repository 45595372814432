import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { getRootClickHandler } from '../../../helpers/clickOut';
import config from '../../../config';
import Socket from '../../App/Socket';
import { ModelModel } from '../../Models/_models';
import { DatasetModel, FeatureModel } from '../../Data/_models';
import { Icon } from '../../shared';
import routes from './routes';
import './styles.scss';
import Report from '../Report';

export default function Dashboards() {
    routes = [
        {
            // report with no tabs route
            path: 'report',
            exact: true,
            element: <Report isPublic />,
        },
        {
            path: 'tabs/:tabId',
            element: <Report isPublic />,
        },
    ];

    const publicDashboardRoutes = useRoutes(routes);
    const dispatch = useDispatch();
    const projectId = sessionStorage.getItem('projectId');
    useEffect(() => {
        dispatch(ModelModel.index());
        dispatch(DatasetModel.index());
        dispatch(FeatureModel.index());
    }, [dispatch, projectId]);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div onMouseDown={getRootClickHandler()} role="main" className="PublicDashboard__main">
            <div className="PublicDashboard__header">
                <Icon icon="share" size="20px" />
                <span className="PublicDashboard__shared"> This dashboard is public. </span>
                <span className="PublicDashboard__power">
                    Powered by{' '}
                    <a href="https://www.whitewhale.ai/deepsea" target="__blank">
                        DeepSea
                    </a>
                </span>
            </div>
            {publicDashboardRoutes}
            <Socket
                subscription={`/v1/projects/${projectId}`}
                url={config.app.internalSocketHost}
                dispatch={dispatch}
            />
        </div>
    );
}
