/* eslint-disable */
import { PublicClientApplication } from '@azure/msal-browser';
import config from '../../../../config';

export const msalConfig = {
  auth: {
    clientId: "75b6ae7b-358e-419a-b23e-1e458775ca6d",
    authority: "https://login.microsoftonline.com/common", // Use common authority for multi-tenant
    redirectUri: config['msal']['redir'] // LOCAL http://localhost:8080/login, CLOUD https://***.deepsea.whitewhale.ai
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance.initialize();

export default msalInstance;
