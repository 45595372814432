import React from 'react';
import PropTypes from 'prop-types';

import { Timeline } from '../../../../../shared';
import { makeLogItemTitle } from '../helpers';

require('./styles.scss');

export default function OrganizationLog(props) {
    const { listItems } = props;
    const updatedListItems = listItems.map((item) => ({
        ...item,
        title: makeLogItemTitle(item),
        time: item.timeCreated,
    }));

    return (
        <div className="OrganizationLog__Timeline">
            <div className="OrganizationLog__title">Login History</div>

            {updatedListItems.length > 0 ? (
                <Timeline id="TimelineID" items={updatedListItems} align="right" />
            ) : (
                'No activity'
            )}
        </div>
    );
}

OrganizationLog.propTypes = {
    listItems: PropTypes.arrayOf(
        PropTypes.shape({
            timeCreated: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            status: PropTypes.string,
        }),
    ),
};

OrganizationLog.defaultProps = {
    listItems: [],
};
