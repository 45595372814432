import ApiBase from '../../../../models/base/api';

export class PermissionApi extends ApiBase {
    constructor() {
        super('permissions');
    }

    listByProject = (projectId) => this.request('GET', `${this.endpoint}/projects/${projectId}`);
}

export default new PermissionApi();
