import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ@abcdefghijklmnopqrstuvwxyz$', 21); // use $ and @ instead of - and _

export function makePreviewItem(item, _additionalSerumValues) {
    let additionalSerumValues = _additionalSerumValues;
    if (item.type === 'feature') {
        const { nugget, ...meta } = item;
        const { _id } = nugget.value;
        if (typeof _additionalSerumValues === 'function') {
            // This allows keys to be added as a function of the feature eg. date groupby operation defaults to year
            additionalSerumValues = _additionalSerumValues(_id);
        }
        return {
            ...meta,
            serum: {
                id: nanoid(),
                feature: { _id },
                jobId: 'latest',
                ...additionalSerumValues,
            },
        };
    }
    return item;
}
