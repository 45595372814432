/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { LayoutContextProvider } from '../../helpers/layout';
import AppModel from '../Access/_models/app';
import UserModel from '../Access/_models/users';
import OrganizationModel from '../Access/_models/organizations';

import { getRootClickHandler } from '../../helpers/clickOut';

import Navbar from './_components/navbar';
import ErrorPage from './_components/error';
import ErrorBoundary from '../shared/ErrorBoundary';
import { Loader } from '../shared';

require('./styles.scss');

const App = () => {
    const { statusCode, hydrated, user, permission } = useSelector((state) => state.app);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const resetStatus = () => dispatch({ type: 'RESET_STATUS' });
    const onGoHome = () => {
        resetStatus();
        if (location.pathname !== '/projects') navigate('/projects');
    };

    useEffect(() => {
        dispatch(AppModel.getMyUser());
        dispatch(AppModel.getMyOrganization()).then(({ organization: myOrganization }) => {
            // get all the users & client/consultant orgs
            dispatch(UserModel.getOrganizationUsers(myOrganization._id));
            if (myOrganization.type === 'client') {
                dispatch(OrganizationModel.show(myOrganization.consultantId));
                dispatch(UserModel.getOrganizationUsers(myOrganization.consultantId));
            } else if (myOrganization.type === 'consultant') {
                dispatch(OrganizationModel.getClients(myOrganization._id)).then(({ organizations }) => {
                    organizations.forEach((org) => dispatch(UserModel.getOrganizationUsers(org._id)));
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (statusCode > 400) {
            resetStatus();
        }
        // reset status when the location changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <ErrorBoundary>
            <LayoutContextProvider>
                {statusCode >= 400 ? (
                    <ErrorPage statusCode={statusCode} onGoHome={onGoHome} />
                ) : (
                    <div className="App" onMouseDown={getRootClickHandler()} role="main">
                        <Navbar user={user} location={location} permissionRoles={permission?.roles} />
                        <DndProvider backend={HTML5Backend}>
                            <div className="App__body">
                                {hydrated ? <Outlet /> : <Loader type="content" loader="content" />}
                            </div>
                        </DndProvider>
                    </div>
                )}
            </LayoutContextProvider>
        </ErrorBoundary>
    );
};

export default App;
