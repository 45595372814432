import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Placeholder from '../components/Placeholder';
import UnitLoader from '../components/Loader';

import { customUnitSubtypes } from './subtypes';

export default function CustomUnit(props) {
    const { unit, mapping } = props;
    if (!unit.data || (unit.data.error && !unit.hydrated)) return <UnitLoader />;
    if (unit.data.error) return <Placeholder unit={unit} error={unit.data.error} mapping={mapping} />;

    const customUnit = customUnitSubtypes[unit.subtype];
    if (!customUnit) return <Placeholder unit={unit} error="unknown-subtype" />;

    const CustomComponent = customUnit.component;

    return (
        <Suspense fallback={<UnitLoader />}>
            <CustomComponent {...props} />
        </Suspense>
    );
}

CustomUnit.propTypes = {
    unit: PropTypes.shape({
        hydrated: PropTypes.bool,
        subtype: PropTypes.string,
        data: PropTypes.shape({
            error: PropTypes.string,
        }),
    }).isRequired,
    mapping: PropTypes.bool,
};

CustomUnit.defaultProps = {
    mapping: false,
};
