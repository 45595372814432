import FeatureSelectors from '../../Data/_models/feature/selectors';

// SELECTORS
const getFeatureIds = (unitIds) => (state) => {
    const _unitIds = Array.isArray(unitIds) ? unitIds : [unitIds]; // allow user to input a string or array
    const featureIdSet = new Set();
    _unitIds.forEach((unitId) => {
        const unit = state.units.byId[unitId];
        if (!unit || !unit.vis || !unit.data || !unit.data.features) return;
        Object.keys(unit.data.features).forEach((featureId) => featureIdSet.add(featureId));
    });
    return [...featureIdSet];
};

const getModelIds = (unitIds) => (state) => {
    const { models } = state;
    let modelOutputDatasets = Object.values(models.byId).reduce((arr, model) => {
        model.outputs.forEach((output) => arr.push({ datasetId: output.dataset._id, modelId: model._id }));
        return arr;
    }, []);
    const unitFeatures = getFeatureIds(unitIds)(state);
    const unitDatasets = FeatureSelectors.getDatasetIds(unitFeatures, { nested: true })(state);
    modelOutputDatasets = modelOutputDatasets.filter((modelData) =>
        unitDatasets.some((datasetId) => datasetId === modelData.datasetId),
    );
    return modelOutputDatasets.map((modelData) => modelData.modelId);
};

const getModel = (unitId) => (state) => {
    const { models } = state;
    const modelIds = getModelIds(unitId)(state);
    // return the first model
    return modelIds[0] ? models.byId[modelIds[0]] : null;
};

const allHydrated = (unitIds) => (state) => {
    const { units } = state;
    const _unitIds = Array.isArray(unitIds) ? unitIds : [unitIds]; // allow user to input a string or array
    return _unitIds.every((unitId) => units.byId[unitId] && units.byId[unitId].hydrated);
};

const getFilters = (unitId, filters, features, datasets, units) => {
    const unitFeatures = getFeatureIds(unitId)({ units, features });
    const unitDatasets = FeatureSelectors.getDatasetIds(unitFeatures)({ features, datasets });
    const allFilters = Object.values(filters.byId).concat(Object.values(filters._tmp)); // _tmp - track deleted filters not yet confirmed deleted by the backend
    const relevantFilters = allFilters.filter((item) => {
        const filterDatasetId = FeatureSelectors.getDatasetIds(item.feature.id)({ features, datasets })[0];
        return unitDatasets.includes(filterDatasetId);
    });
    return relevantFilters;
};

export default {
    getFeatureIds,
    getModelIds,
    getModel,
    allHydrated,
    getFilters,
};
