import ApiBase from '../../../../models/base/api';

export class TriggerApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('triggers', addProjectIdToSearchParams);
    }
    create = (form) => this.request('POST', `${this.endpoint}`, form);
    pause = (triggerId) => this.request('PUT', `${this.endpoint}/${triggerId}/pause`);
    activate = (triggerId) => this.request('PUT', `${this.endpoint}/${triggerId}/activate`);
}

export default new TriggerApi();
