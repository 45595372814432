export const createOptionsArray = (options) =>
    typeof options === 'string' || !options
        ? Array.from(new Set((options || '').split(',').map((i) => i.trim()))).map((item) => ({
              label: item,
              value: item,
          }))
        : options;

export const createOptionsString = (options) =>
    typeof options !== 'string' || !options ? (options || []).map((option) => option.value).join(', ') : options;
