import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isFilterValid } from '../helpers';
import FilterMenu from '../Menu';
import { Icon, IconButton } from '../../../shared';

import './styles.scss';

export default function AddFilterButton(props) {
    const {
        value,
        onChange,
        datasets,
        datasetIds,
        features,
        top,
        showText,
        showTextContextMenu,
        onFeatureSelect,
        right,
        left,
        isFilterBar,
        dark,
    } = props;

    const [tempValue, setTempValue] = useState({
        value: value.value,
        type: value.type,
        option: value.option,
        feature: value.feature,
        operation: value.operation,
    });

    const [open, setOpen] = useState(false);

    const setClosed = () => {
        if (!open) return;
        setOpen(false);
        setTempValue(value); // value resets to empty every time menu closes

        if (
            tempValue.value === value.value &&
            tempValue.feature === value.feature &&
            tempValue.type === value.type &&
            tempValue.option === value.option &&
            tempValue.operation === value.operation
        ) {
            return;
        }

        if (isFilterValid(tempValue)) onChange('add', tempValue);
    };

    const handleToggleFilter = () => {
        if (open) setClosed();
        else setOpen(true);
    };

    const feature = features.byId[tempValue.feature && tempValue.feature.id];

    let addFilterIconOrButton;
    if (showTextContextMenu) {
        addFilterIconOrButton = (
            <div
                className={classNames('AddFilterButton__textButton', {
                    'AddFilterButton__textButton--menuOpen': !!open,
                })}
                onClick={handleToggleFilter}
                role="button"
                tabIndex={0}
            >
                <Icon size="16px" icon="plus" />
                <span>Add Filter</span>
            </div>
        );
    } else if (showText) {
        addFilterIconOrButton = (
            <div
                className={classNames('AddFilterButton__textButton', {
                    'AddFilterButton__textButton--menuOpen': !!open,
                })}
                onClick={handleToggleFilter}
                role="button"
                tabIndex={0}
            >
                Add Filter
                <Icon size="20px" icon="plus" />
            </div>
        );
    } else {
        addFilterIconOrButton = (
            <IconButton icon="plus" tooltip="Add Filter" onClick={handleToggleFilter} active={open} />
        );
    }

    // load features on initial load:
    useEffect(() => {
        if (value && value.feature && value.feature.id && onFeatureSelect) onFeatureSelect(value.feature.id);
    }, [feature, value, onFeatureSelect]);

    return (
        <div
            className={classNames('AddFilterButton', {
                'AddFilterButton__iconButtonFilter--dark': dark,
            })}
        >
            {addFilterIconOrButton}
            <FilterMenu
                open={open}
                datasets={datasets}
                datasetIds={datasetIds}
                features={features}
                value={tempValue}
                top={top}
                onChange={setTempValue}
                onSubmit={setClosed}
                onClickOut={setClosed}
                onFeatureSelect={onFeatureSelect}
                right={right}
                left={left}
                isFilterBar={isFilterBar}
            />
        </div>
    );
}

AddFilterButton.propTypes = {
    value: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
        operation: PropTypes.string,
    }),
    datasets: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
        hydrated: PropTypes.bool,
    }).isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({ byId: PropTypes.shape({}) }).isRequired,
    top: PropTypes.bool,
    showText: PropTypes.bool,
    showTextContextMenu: PropTypes.bool,
    onChange: PropTypes.func,
    onFeatureSelect: PropTypes.func,
    right: PropTypes.bool,
    left: PropTypes.bool,
    isFilterBar: PropTypes.bool,
    dark: PropTypes.bool,
};
AddFilterButton.defaultProps = {
    value: null,
    datasetIds: [],
    top: false,
    showText: false,
    showTextContextMenu: false,
    onChange: () => {},
    onFeatureSelect: () => {},
    right: false,
    left: false,
    isFilterBar: false,
    dark: false,
};
