import React from 'react';
import ProjectList from './List';
import Dashboards from '../Dashboards';
import Data from '../Data';
import Models from '../Models';
import Forms from '../Forms';
import Packages from '../Packages';
import Mobi from '../Mobi';
import Mapping from '../Units/Mapping';
import ProjectId from './id';

import { ProjectProtectedRoute } from '../../helpers/router';
import Reports from '../Dashboards/Reports';

export default [
    {
        path: '',
        exact: true,
        element: <ProjectList />,
    },
    {
        path: ':projectId',
        exact: true,
        element: <ProjectId />,
        children: [
            {
                path: 'dashboards/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                        <Dashboards />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'dashboards/report/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                        <Reports />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'datasets/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ dataset: 'view' }}>
                        <Data />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'packages/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ package: 'view' }}>
                        <Packages />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'models/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ model: 'view' }}>
                        <Models />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'mobi/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ model: 'view' }}>
                        <Mobi />
                    </ProjectProtectedRoute>
                ),
            },
            {
                path: 'forms/*',
                exact: false,
                element: (
                    <ProjectProtectedRoute requiredRole={{ form: 'view' }}>
                        <Forms />
                    </ProjectProtectedRoute>
                ),
            },
            {
                // unit mapping route
                path: 'units/:unitId/mapping',
                element: (
                    <ProjectProtectedRoute requiredRole={{ dashboard: 'edit' }}>
                        <Mapping />
                    </ProjectProtectedRoute>
                ),
            },
        ],
    },
];
