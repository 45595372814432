import {
    neutral50,
    neutral60,
    neutral70,
    neutral80,
    neutral95,
    blue200,
    white,
} from '../../../../../styles/partials/colors';

const formatTooltipParameter = (param, yFormatter, tooltipFormatter) =>
    `<div>${param.marker} ${param.seriesName}: ${yFormatter(param.value[param.axisDim === 'x' ? 1 : 0])}${
        tooltipFormatter ? `<ul><li> ${tooltipFormatter(param.data).join('<li>')}</li></ul>` : ''
    }</div>`;

export const getDefaultTooltipFormatter = (xFormatter, yFormatter, tooltipFormatter) => (params) => {
    // Params[0] is undefined if using scatter plots
    if (params[0] === undefined) {
        const data = [params];
        return `${xFormatter(data[0].value[data[0].axisDim === 'x' ? 0 : 1])}<br>${data
            .filter((param) => param.value[0] !== undefined && param.value[1] !== undefined)
            .map((param) => formatTooltipParameter(param, yFormatter, tooltipFormatter))
            .join('')}`;
    }
    return `${xFormatter(params[0].value[params[0].axisDim === 'x' ? 0 : 1])}<br>${params
        .filter((param) => param.value[0] !== undefined && param.value[1] !== undefined)
        .map((param) => formatTooltipParameter(param, yFormatter, tooltipFormatter))
        .join('')}`;
};
export const defaultTooltip = {
    axisPointer: {
        type: 'shadow',
    },
    backgroundColor: white,
    appendToBody: true,
    enterable: true,
    confine: true,
    extraCssText: 'max-height: 350px; overflow: auto;',
    position(canvasMousePos, params, tooltipDom, rect, sizes) {
        const margin = 10;
        const overflowMargin = 5;
        const canvasRect = tooltipDom.parentElement.getElementsByTagName('canvas')[0].getBoundingClientRect();

        const mouseX = canvasMousePos[0] + canvasRect.x;
        const mouseY = canvasMousePos[1] + canvasRect.y;

        const tooltipWidth = sizes.contentSize[0];
        const tooltipHeight = sizes.contentSize[1];

        let xPos = mouseX + margin;
        let yPos = mouseY - margin - tooltipHeight;

        if (xPos + tooltipWidth >= document.documentElement.clientWidth) {
            xPos = mouseX - margin - tooltipWidth;
            if (xPos <= 0) xPos = overflowMargin;
        }
        if (yPos <= 0) {
            yPos = mouseY + margin;
            if (yPos + tooltipHeight >= document.documentElement.clientHeight) yPos = overflowMargin;
        }
        return [xPos - canvasRect.x, yPos - canvasRect.y];
    },
};

export const defaultStyle = {
    height: '100%',
    width: '100%',
};

export const textStyle = {
    fontFamily: 'Nunito',
    letterSpacing: '0.02em',
};

export const defaultDataZoom = {
    handleStyle: {
        color: blue200,
        borderColor: null,
    },
    handleIcon: 'circle',
    handleSize: '12',
    fillerColor: blue200,
    backgroundColor: neutral95,
    dataBackground: {
        lineStyle: { color: neutral95 },
        areaStyle: { color: neutral95 },
    },
    borderColor: 'rgba(0, 0, 0, 0)',
    selectedDataBackground: {
        lineStyle: { color: blue200 },
        areaStyle: { color: blue200 },
    },
    emphasis: {
        handleStyle: {
            color: blue200,
            borderColor: 'rgba(78, 182, 239, .25)',
            borderWidth: 6,
        },
    },
    moveHandleStyle: {
        opacity: 0,
    },
    textStyle: {
        color: neutral60,
        width: 50, // hardcoded width of text on sliders until I can find way to auto truncate if goes out of card.
        overflow: 'break',
    },
    showDetail: true,
};

export const getXAxisOptions = (props) => {
    const {
        xAxisTitle,
        xValueFormatter,
        xAxisHasLabels,
        xReversed,
        xAxisType,
        xAxisScale,
        xTickSize,
        xAxisHasTitle,
        xAxisOrientation,
        gridVertical,
        xAxisMin,
        xAxisMax,
        series,
        xAxisSlantLabels,
    } = props;

    const axisOptions = {
        name: xAxisHasTitle ? xAxisTitle : '',
        nameLocation: 'middle',
        position: xAxisOrientation,
        inverse: xReversed,
        axisLabel: {
            show: xAxisHasLabels,
            formatter: xValueFormatter,
            width: 80,
            overflow: 'truncate',
            rotate: xAxisSlantLabels ? 45 : 0,
            hideOverlap: true
        },
        axisTick: {
            show: xTickSize,
        },
        axisLine: {
            lineStyle: {
                color: neutral70,
            },
        },
        splitLine: {
            show: gridVertical,
        },
        nameTextStyle: {
            padding: xAxisSlantLabels ? [50, 0, 0, 0] : [10, 0, 0, 0],
            color: neutral50,
            fontWeight: '600',
        },
        type: xAxisScale === 'log' ? 'log' : xAxisType,
        scale: false,
        min: xAxisMin,
        max: xAxisMax,
        // this creates an array of integers to enforce x amount of bins, even if there is no data within certain ranges
        data: series?.independent[0]?.operation === 'bin' ? [...Array(series.independent[0].max+1).keys()] : undefined,
    };

    if (xAxisScale !== 'log' && xAxisMax === undefined) axisOptions.max = 'dataMax';
    return axisOptions;
};

export const getYAxisOptions = (props) => {
    const {
        yAxisTitle,
        yValueFormatter,
        yAxisHasLabels,
        yReversed,
        yAxisType,
        yAxisScale,
        yTickSize,
        yAxisHasTitle,
        yAxisOrientation,
        gridHorizontal,
        yAxisMin,
        yAxisMax,
    } = props;

    return {
        name: yAxisHasTitle ? yAxisTitle : '',
        nameLocation: yReversed ? 'start' : 'end',
        nameTextStyle: {
            align: 'left',
            padding: [0, 0, 0, -20],
            // Currently the y axis title will pass the right side if long enough; should be able to set with width but does not appear to be working properly
            color: neutral50,
            fontWeight: '600',
        },
        position: yAxisOrientation,
        inverse: yReversed,
        axisLabel: {
            show: yAxisHasLabels,
            formatter: yValueFormatter,
        },
        axisLine: {
            lineStyle: {
                color: neutral70,
            },
        },
        axisTick: {
            show: yTickSize,
        },
        splitLine: {
            show: gridHorizontal,
        },
        scale: false,
        type: yAxisScale === 'log' ? 'log' : yAxisType,
        min: yAxisMin,
        max: yAxisMax,
    };
};

// Legend Configuration
const angleLeftIcon = `path://M17.9286 26C19.5529 26 20.5221 24.1899 19.6213 22.8381L14.4061 15L19.6201 7.16189C20.5209 5.81011 19.5529 4 17.9286 4C17.249 4 16.6134 4.33978 16.2358 4.90567L10.4107 13.6446C9.86311 14.4659 9.86311 15.5353 10.4107 16.3567L16.2358 25.0956C16.6134 25.6602 17.2478 26 17.9286 26Z`;
const angleRightIcon = `path://M12.0376 4C10.4132 4 9.444 5.81011 10.3448 7.16189L15.56 15L10.346 22.8381C9.44522 24.1899 10.4132 26 12.0376 26C12.7171 26 13.3527 25.6602 13.7303 25.0943L19.5554 16.3554C20.103 15.5341 20.103 14.4647 19.5554 13.6433L13.7303 4.90444C13.3527 4.33978 12.7183 4 12.0376 4Z`;
const chartLegendPageIcons = {
    horizontal: [angleLeftIcon, angleRightIcon],
    vertical: [angleLeftIcon, angleRightIcon],
};
const chartLegendTextStyle = { color: neutral60, fontSize: 12 };

export const defaultLegend = {
    top: 'top',
    padding: [20, 25, 0, 15],
    type: 'scroll',
    itemGap: 20, // unsure what is creating space between the icon and its label, so making this larger until find a work around
    itemHeight: 16,
    animationDurationUpdate: 200,

    pageTextStyle: chartLegendTextStyle,
    textStyle: chartLegendTextStyle,
    pageIconSize: 10,
    pageIcons: chartLegendPageIcons,
    pageIconColor: neutral50,
    pageIconInactiveColor: neutral80,
};
