import { createReducer, createActions } from 'reduxsauce';
import { apiHandlers } from '../../../../models/base/reducer';

import INITIAL_STATE from './schema';

export const sendEmailRequest = (state = INITIAL_STATE) => ({
    ...state,
    errors: {},
    loading: true,
    emailSuccess: false,
});
export const sendEmailSuccess = (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
    errors: {},
    emailSuccess: true,
});

export const resetPasswordSuccess = (state = INITIAL_STATE) => ({ ...state, loading: false, errors: {} });

export const loginRemoveErrors = (state) => ({ ...state, errors: {} });

export const loginSuccessRedirect = (state = INITIAL_STATE, { resetToken }) => ({
    ...state,
    resetToken,
});

export const loginMicrosoftRedirect = (state = INITIAL_STATE, { email }) => ({
    ...state,
    email, // Add microsoftToken to state
    loading: false,
    errors: {},
});

const { Creators, Types } = createActions({
    loginRequest: ['email', 'password'],
    loginSuccess: ['accessToken'],
    loginSuccessRedirect: ['resetToken'],
    loginFailure: ['errors'],
    sendEmailRequest: ['email'],
    sendEmailSuccess: ['resetCode'],
    sendEmailFailure: ['errors'],
    checkResetCodeRequest: ['resetCode'],
    checkResetCodeSuccess: ['response'],
    checkResetCodeFailure: ['errors'],
    resetPasswordRequest: ['form', 'resetCode'],
    resetPasswordSuccess: ['response'],
    resetPasswordFailure: ['errors'],
    logoutRequest: null,
    logoutSuccess: null,
    logoutFailure: null,
    loginRemoveErrors: null,
    loginMicrosoftRedirect: ['email']
});

// HANDLERS
const HANDLERS = {
    [Types.SEND_EMAIL_REQUEST]: sendEmailRequest,
    [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
    [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
    [Types.LOGIN_REMOVE_ERRORS]: loginRemoveErrors,
    [Types.LOGIN_SUCCESS_REDIRECT]: loginSuccessRedirect,
    [Types.LOGIN_MICROSOFT_REDIRECT]: loginMicrosoftRedirect
};

export default createReducer(
    INITIAL_STATE,
    apiHandlers(['LOGIN', 'SEND_EMAIL', 'RESET_PASSWORD', 'CHECK_RESET_CODE'], Types, HANDLERS),
);

export { Creators as Actions };
// guo@whitewhaleanalytics138.onmicrosoft.com