import ApiBase from '../../../../models/base/api';

class LoginApi extends ApiBase {
    constructor() {
        super('login');
    }

    login = (email, password) => this.request('POST', 'login', { email, password });

    loginMicrosoft = (authCode) => this.request('POST', 'login/microsoft', authCode );

    logout = () => this.request('DELETE', 'logout');

    sendRecoveryEmail = (email) => this.request('POST', 'login/forgot', email);

    checkResetCode = (resetCode) => this.request('GET', `login/reset/${resetCode}`);

    resetPassword = (form, resetCode) => this.request('POST', `login/reset/${resetCode}`, form);
}

export default new LoginApi();
