import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { neutral20 } from '../../../../../styles/partials/colors';

export default class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload, valueFormatter } = this.props;

        const formattedValue = valueFormatter(payload.value);
        const textToRender = payload.value.length > 15 ? `${formattedValue.slice(0, 16)}...` : formattedValue;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={8} textAnchor="end" fill={neutral20} transform="rotate(-35)">
                    {textToRender}
                </text>
            </g>
        );
    }
}

CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape({
        value: PropTypes.array,
    }),
    valueFormatter: PropTypes.func,
};

CustomizedAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: { value: '' },
    valueFormatter: (v) => v,
};
