import ApiBase from '../../../../models/base/api';

export class FilterApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('filters', addProjectIdToSearchParams);
    }
}

export default new FilterApi();
