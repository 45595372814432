import ApiBase from '../../../../models/base/api';

export class CollectionApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('collections', addProjectIdToSearchParams);
    }
    // datasets = (datasetId) => this.request('GET', `${this.endpoint}/datasets/${datasetId}`);
    // putDataSets = (datasetId) => this.request('PUT', `${this.endpoint}/datasets/${datasetId}`);
    // deleteDataSets = (datasetId) => this.request('DELETE', `${this.endpoint}/datasets/${datasetId}`);
}

export default new CollectionApi();
