/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OrganizationModel from '../../../_models/organizations';
import { ConfirmModal, Loader, Logo } from '../../../../shared';
import SelectableBar from '../../../../shared/SelectableBar';

require('./styles.scss');

export default function OrganizationList({ myOrganizationId, organizations, hydrated }) {
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [confirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDeactivateClose = () => {
        setConfirmDeactivateOpen(false);
    };

    const handleDeleteClose = () => {
        setConfirmDeleteOpen(false);
    };

    const handleDeactivate = () => {
        dispatch(
            OrganizationModel.toggleActivate(selectedOrganization._id, {
                active: !selectedOrganization.active,
            }),
        );
        handleDeactivateClose();
    };

    const handleDelete = () => {
        dispatch(OrganizationModel.destroy(selectedOrganization._id));
        handleDeleteClose();
    };

    const ConfirmDeactivateModal = (
        <ConfirmModal
            onSubmit={handleDeactivate}
            open={confirmDeactivateOpen}
            title={`${selectedOrganization?.active ? 'Deactivate' : 'Reactivate'} Organization`}
            onClose={handleDeactivateClose}
            message={
                selectedOrganization?.active
                    ? 'Deactivating this organization will temporarily disable all accounts and projects that belong to it.'
                    : 'Reactivating this organization will reactivate all (non-deactivated) accounts and projects that belong to it.'
            }
            buttonText={selectedOrganization?.active ? 'Deactivate' : 'Reactivate'}
        />
    );

    const ConfirmDeleteModal = (
        <ConfirmModal
            title="Delete Organization"
            onSubmit={handleDelete}
            open={confirmDeleteOpen}
            onClose={handleDeleteClose}
            confirmValue={selectedOrganization?.name}
            message="If you delete this organization, all its projects and user accounts will be permanently erased."
            buttonText="Confirm"
        />
    );

    const organizationsArray = organizations.filter((org) => !org.consultantId);
    const clientsArray = organizations.filter((org) => org.consultantId);
    const organizationsList = [];

    organizationsArray.forEach((org) => {
        organizationsList.push(org);
        clientsArray.forEach((client) => {
            if (client.consultantId === org._id) {
                organizationsList.push(client);
            }
        });
    });

    return (
        <div className="OrganizationList">
            {organizationsList.length ? (
                organizationsList.map((organization) => (
                    <SelectableBar
                        key={organization._id}
                        title={organization.name}
                        subTitle={organization.active ? null : 'Deactivated'}
                        deactivated={!organization.active}
                        info={
                            organization.type === 'client'
                                ? 'Client Org'
                                : organization.type === 'consultant'
                                ? 'Consultancy'
                                : organization.type === 'independent'
                                ? 'Independent Organization'
                                : null
                        }
                        logo={<Logo logo={organization.logo} hydrated={hydrated} />}
                        onClick={() => navigate(`${organization._id}`)}
                        child={organization.type === 'client'}
                        options={
                            organization._id === myOrganizationId
                                ? []
                                : [
                                      {
                                          id: 'deactivate',
                                          name: `${organization?.active ? 'Deactivate' : 'Reactivate'} Organization`,
                                          onClick: () => {
                                              setSelectedOrganization(organization);
                                              setConfirmDeactivateOpen(true);
                                          },
                                      },
                                      {
                                          id: 'delete',
                                          name: 'Delete Organization',
                                          onClick: () => {
                                              setSelectedOrganization(organization);
                                              setConfirmDeleteOpen(true);
                                          },
                                      },
                                  ]
                        }
                    />
                ))
            ) : organizations.length ? (
                organizations.map((organization) => (
                    <SelectableBar
                        key={organization._id}
                        title={organization.name}
                        subTitle={organization.active ? null : 'Deactivated'}
                        deactivated={!organization.active}
                        info={
                            organization.type === 'client'
                                ? 'Client Org'
                                : organization.type === 'consultant'
                                ? 'Consultancy'
                                : organization.type === 'independent'
                                ? 'Independent Organization'
                                : null
                        }
                        logo={<Logo logo={organization.logo} hydrated={hydrated} />}
                        onClick={() => navigate(`${organization._id}`)}
                        options={
                            organization._id === myOrganizationId
                                ? []
                                : [
                                      {
                                          id: 'deactivate',
                                          name: `${organization?.active ? 'Deactivate' : 'Reactivate'} Organization`,
                                          onClick: () => {
                                              setSelectedOrganization(organization);
                                              setConfirmDeactivateOpen(true);
                                          },
                                      },
                                      {
                                          id: 'delete',
                                          name: 'Delete Organization',
                                          onClick: () => {
                                              setSelectedOrganization(organization);
                                              setConfirmDeleteOpen(true);
                                          },
                                      },
                                  ]
                        }
                    />
                ))
            ) : (
                <Loader type="content" loader="content" />
            )}
            {ConfirmDeactivateModal}
            {ConfirmDeleteModal}
        </div>
    );
}

OrganizationList.propTypes = {
    myOrganizationId: PropTypes.string.isRequired,
    organizations: PropTypes.array,
    hydrated: PropTypes.bool,
};

OrganizationList.defaultProps = {
    organizations: [],
    hydrated: false,
};
