import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { renderComponentAppended } from '../../../helpers/component';

require('./styles.scss');

export default class FullScreenModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showOutAnimation: false,
        };
    }

    shouldComponentUpdate(nextState) {
        // keeps closed modals from re-rendering
        const { open } = this.state;
        return open || nextState.open;
    }

    static getDerivedStateFromProps(props, state) {
        const newState = { showOutAnimation: !props.open && state.open && props.animationEnabled };
        if (props.open || !props.animationEnabled) {
            newState.open = props.open;
        }
        return newState;
    }

    animateClose() {
        setTimeout(() => {
            this.setState({ open: false, showOutAnimation: false });
        }, 1000);
    }

    render() {
        const { children, animationEnabled, className, header } = this.props;

        const { open, showOutAnimation } = this.state;
        // keep the modal open while close animation runs (this state is only reached if animationEnabled==true)
        if (open && showOutAnimation) {
            this.animateClose();
        }

        return open
            ? renderComponentAppended(
                  <div
                      className={ClassNames('FullScreenModal', className, {
                          'FullScreenModal--out': showOutAnimation,
                          'FullScreenModal--animationEnabled': animationEnabled,
                      })}
                  >
                      {header}
                      {children}
                  </div>,
                  'fullscreen-modal-root',
              )
            : null;
    }
}

FullScreenModal.propTypes = {
    open: PropTypes.bool,
    children: PropTypes.node,
    animationEnabled: PropTypes.bool,
    className: PropTypes.string,
    header: PropTypes.node,
};

FullScreenModal.defaultProps = {
    open: false,
    children: null,
    animationEnabled: false,
    className: '',
    header: null,
};
