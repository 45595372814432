import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AlgorithmModel } from '../../_models';
import { Input } from '../../../shared';

require('./styles.scss');

const PackageFunctionSelect = (props) => {
    const { packages, algorithms, metadata, updateMetaData, disabled } = props;

    const dispatch = useDispatch();
    const getAlgorithm = (id) => dispatch(AlgorithmModel.show(id));

    useEffect(() => {
        if (metadata.packageId && metadata.moduleId && packages.byId[metadata.packageId]?.modules) {
            const module = packages.byId[metadata.packageId].modules.find((m) => m._id === metadata.moduleId);
            module.algorithms.forEach((algorithm) => {
                if (!algorithms.byId[algorithm._id]) getAlgorithm(algorithm._id);
            });
        }
    });

    const handlePackageId = (id) => updateMetaData({ packageId: id });
    const handleModuleId = (id) => updateMetaData({ packageId: metadata.packageId, moduleId: id });
    const handleAlgorithmId = (id) =>
        updateMetaData({
            packageId: metadata.packageId,
            moduleId: metadata.moduleId,
            algorithmId: id,
        });

    return (
        <div className="PackageFunctionSelect">
            <Input
                id="packageId"
                type="select"
                label="Package"
                placeholder="Select Package"
                gray
                required
                onChange={(_, value) => handlePackageId(value)}
                disabled={disabled}
                options={
                    Object.values(packages.byId)
                        .filter((p) => p.status === 'built')
                        .map((packageRedux) => ({
                            label: packageRedux.name,
                            value: packageRedux._id,
                        })) || []
                }
                value={metadata.packageId}
            />
            <div className="PackageFunctionSelect__slash">/</div>
            <Input
                id="moduleId"
                type="select"
                label="File"
                placeholder="Select File"
                gray
                required
                onChange={(_, value) => handleModuleId(value)}
                disabled={!metadata.packageId || disabled}
                options={
                    (metadata.packageId &&
                        packages.byId[metadata.packageId] &&
                        packages.byId[metadata.packageId].modules?.map((module) => ({
                            label: module.filename,
                            value: module._id,
                        }))) ||
                    []
                }
                value={metadata.moduleId}
            />
            <div className="PackageFunctionSelect__slash">/</div>
            <Input
                id="algorithmId"
                type="select"
                label="Function"
                placeholder="Select Function"
                gray
                required
                onChange={(_, value) => handleAlgorithmId(value)}
                disabled={!metadata.moduleId || disabled}
                options={
                    (metadata.packageId &&
                        metadata.moduleId &&
                        packages.byId[metadata.packageId]?.modules &&
                        packages.byId[metadata.packageId].modules
                            .find((m) => m._id === metadata.moduleId)
                            .algorithms.map((algo) => ({
                                label: algorithms.byId[algo._id] && algorithms.byId[algo._id].name,
                                value: algo._id,
                            }))) ||
                    []
                }
                value={metadata.algorithmId}
            />
        </div>
    );
};

PackageFunctionSelect.propTypes = {
    metadata: PropTypes.shape().isRequired,
    updateMetaData: PropTypes.func,
    packages: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    algorithms: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    disabled: PropTypes.bool,
};

PackageFunctionSelect.defaultProps = {
    updateMetaData: () => {},
    disabled: false,
};

export default PackageFunctionSelect;
