import { Actions } from './reducers';
import DashboardsApiInstance from './api';
import { operations } from '../../../../models/base';

const createTab = (id, form) => (dispatch) => {
    dispatch(Actions.createTabRequest(id));
    return DashboardsApiInstance.addTab(id, form).then(
        (dashboard) => dispatch(Actions.createTabSuccess(id, dashboard)),
        (errors) => dispatch(Actions.createTabFailure(id, errors)),
    );
};

const duplicateTab = (id, tabId) => (dispatch) => {
    dispatch(Actions.duplicateTabRequest(id));
    return DashboardsApiInstance.duplicateTab(id, tabId).then(
        (dashboard) => dispatch(Actions.duplicateTabSuccess(id, dashboard)),
        (errors) => dispatch(Actions.duplicateTabFailure(id, errors)),
    );
};

const destroyTab = (id, tabId) => (dispatch) => {
    dispatch(Actions.destroyTabRequest(id));
    return DashboardsApiInstance.deleteTab(id, tabId).then(
        (dashboard) => dispatch(Actions.destroyTabSuccess(id, tabId, dashboard)),
        (errors) => dispatch(Actions.destroyTabFailure(id, tabId, errors)),
    );
};

const duplicate = (id) => (dispatch) => {
    dispatch(Actions.duplicateRequest(id));
    return DashboardsApiInstance.duplicate(id).then(
        (dashboard) => dispatch(Actions.duplicateSuccess(id, dashboard)),
        (errors) => dispatch(Actions.duplicateFailure(id, errors)),
    );
};

const updateColors = (dashboardId, newColors) => (dispatch) => {
    dispatch(Actions.updateColorsRequest(dashboardId, newColors));
    DashboardsApiInstance.update(dashboardId, { colors: newColors }).then(
        (dashboard) => dispatch(Actions.updateColorsSuccess(dashboardId, dashboard)),
        (errors) => dispatch(Actions.updateColorsFailure(dashboardId, errors)),
    );
};

const updatePreview = (dashboardId) => (dispatch) => {
    dispatch(Actions.updatePreviewRequest(dashboardId));
    DashboardsApiInstance.getPreview(dashboardId).then(
        (dashboard) => dispatch(Actions.updateColorsSuccess(dashboardId, dashboard)),
        (errors) => dispatch(Actions.updateColorsFailure(dashboardId, errors)),
    );
};


export default operations(Actions, DashboardsApiInstance, {
    createTab,
    destroyTab,
    duplicateTab,
    duplicate,
    updateColors,
    updatePreview,
    getPreview: DashboardsApiInstance.getPreview,
});
