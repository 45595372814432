import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../shared';
import { getUnitPlaceholder } from './helpers';

require('./styles.scss');

const Placeholder = ({ unit, mapping, error, height }) => {
    const placeholderContent = {
        'unknown-subtype': {
            header: 'Unknown subtype',
            text: 'Something has been misconfigured.  Please try again.',
        },
        'no-data': {
            header: 'No data',
            text: 'No data was found.',
            icon: 'large_color/question-circle',
        },
        'too-much-data': {
            header: 'Unable to render',
            text:
                'Please add an operation or filter to reduce the amount of data shown on this card, or change the chart type.',
        },
        'feature-deleted': {
            header: 'No dataset',
            text: 'The dataset used in this card could not be found.',
        },
        'bad-operation': {
            header: 'Data query failed',
            text:
                'A feature on this card has changed types and is no longer compatible with the configured operation. Please update the operation or feature type.',
        },
        'bad-filter': {
            header: 'Filter error',
            text:
                'A filter being applied to this card has an incompatible feature type, due to the feature type being changed. Please remove the filter.',
        },
        'not-numeric': {
            header: 'Not numeric',
            text: 'You must provide at least one numeric data value. Choose an operation or try a different feature.',
        },
        'not-mappable': {
            header: mapping ? 'Not Mappable' : 'Error',
            text: mapping ? 'No UI has been made to configure this type of unit' : 'Something went wrong',
        },
        error: {
            header: 'Error',
            text: 'An error occurred when fetching data. Please make sure the configuration of this card is correct.',
        },
        empty: {
            ...getUnitPlaceholder(unit),
            ...(mapping ? {} : { text: 'Click the ‘edit’ button on the card to finish creating it.' }),
        },
    };

    const displayPlaceholderIcon = (icon, h) => {
        let placeholderIcon = null;
        if (h >= 150 || !h) {
            if (icon) {
                placeholderIcon = <Icon icon={icon} size="auto" className="Placeholder__icon" />;
            } else {
                placeholderIcon = <Icon icon="exclamation-triangle" size="75px" className="Placeholder__icon--error" />;
            }
        }
        return placeholderIcon;
    };

    return (
        <div className="Placeholder">
            <div className="Placeholder__content">
                {displayPlaceholderIcon(placeholderContent[error]?.icon, height)}
                <span className="Placeholder__message">
                    <header>{placeholderContent[error]?.header || 'Error'}</header>
                    <div>{placeholderContent[error]?.text}</div>
                </span>
            </div>
        </div>
    );
};
Placeholder.propTypes = {
    unit: PropTypes.shape({
        type: PropTypes.string,
        vis: PropTypes.shape({}),
    }),
    mapping: PropTypes.bool,
    error: PropTypes.string,
    height: PropTypes.number,
};

Placeholder.defaultProps = {
    unit: {},
    mapping: false,
    error: null,
    height: null,
};

export default Placeholder;
