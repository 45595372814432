import moment from 'moment/moment';
import { getBinNames } from '../../Data/helpers';

export const operationsConfig = {
    // general
    '': { label: 'None (all data)', value: '', axisLabel: '', shortName: 'None' },
    none: { label: 'None (all data)', value: '', axisLabel: '', shortName: 'None' },
    count: { label: 'Count', value: 'count', axisLabel: 'Count of', shortName: 'Count', outputType: 'number' },
    distinctCount: {
        label: 'Distinct Count',
        value: 'distinctCount',
        axisLabel: 'Distinct Count of',
        shortName: 'DisCt',
        outputType: 'number',
    },

    // number
    sum: { label: 'Sum', value: 'sum', axisLabel: 'Total', shortName: 'Sum', outputType: 'number' },
    avg: { label: 'Average', value: 'avg', axisLabel: 'Average', shortName: 'Avg', outputType: 'number' },
    med: { label: 'Median', value: 'med', axisLabel: 'Median', shortName: 'Med', outputType: 'number' },

    // number & date
    max: { label: 'Maximum', value: 'max', axisLabel: 'Maximum', shortName: 'Max' },
    min: { label: 'Minimum', value: 'min', axisLabel: 'Minimum', shortName: 'Min' },

    // date
    year: {
        label: 'Year',
        value: 'year',
        axisLabel: '',
        shortName: 'Year',
        outputType: 'category',
        // formatter is default category formatter (val) => (val)
        isValid: (value) => value > 1000 && value < 3000,
    },
    month: {
        label: 'Month',
        value: 'month',
        axisLabel: '',
        shortName: 'Month',
        outputType: 'category',
        formatter: (value) => moment(value, 'M', true).format('MMMM'),
        isValid: (value) => value > 0 && value < 13,
        // values come from backend as numbers 1-12.  Format values as full month names
    },
    day: {
        label: 'Day of the month',
        value: 'day',
        axisLabel: '',
        shortName: 'Day',
        outputType: 'number',
        // formatter is default number formatter
        isValid: (value) => value > 0 && value < 32,
    },
    dow: {
        label: 'Weekday',
        value: 'dow',
        axisLabel: '',
        shortName: 'Weekday',
        outputType: 'category',
        formatter: (value) => moment(value, 'e', true).format('dddd'),
        isValid: (value) => value >= 0 && value < 7,
        // values come from backend as numbers 0-6.  Format values as full weekday names
        // TODO: SQL dow returns 0(Sunday) - 6(Saturday).  Moment parses 0-6 based on locale (could be MON-SUN)
    },
    quarter: {
        label: 'Quarter',
        value: 'quarter',
        axisLabel: '',
        shortName: 'Quarter',
        outputType: 'category',
        formatter: (value) => moment(value, 'Q', true).format('Qo'),
        isValid: (value) => value > 0 && value < 5,
        // values come from backend as numbers 1-4
    },
    'month-day': {
        label: 'Month-day',
        value: 'month-day',
        axisLabel: '',
        shortName: 'mm-dd',
        outputType: 'category',
        formatter: (value) => moment(value, 'MM-DD', true).format('MMM D'),
        isValid: (value) => moment(value, 'MM-DD', true).isValid(),
        // values come from backend as strings 'MM-DD'
    },
    'year-quarter': {
        label: 'Year-quarter',
        value: 'year-quarter',
        axisLabel: '',
        shortName: 'yy-qq',
        outputType: 'category',
        formatter: (value) => moment(value, 'YYYY Q', true).format('YYYY Qo'),
        isValid: (value) => /^\d{4} [1-4]$/.test(value),
    },
    'year-month': {
        label: 'Year-month',
        value: 'year-month',
        axisLabel: '',
        shortName: 'yy-mm',
        outputType: 'date',
        formatter: (value) => moment.utc(Number(value)).format('MMM YYYY'),
        isValid: (value) => moment(value).isValid(),
        // value comes back as the default date format
    },
    'year-month-day': {
        label: 'Year-month-day',
        value: 'year-month-day',
        axisLabel: '',
        shortName: 'yy-mm-dd',
        outputType: 'date',
        isValid: (value) => moment(value).isValid(),
        formatter: (value) => moment.utc(Number(value)).format('MMM D, YYYY'),
        // formatter defaults to the date formatter
        // value comes back as the default date format
    },
    'time-of-day': {
        label: 'Time of day',
        value: 'time-of-day',
        axisLabel: '',
        shortName: 'Time',
        outputType: 'number',
        makeFormatter: (feature) => {
            const formatter = Intl.DateTimeFormat([], {
                timeStyle: 'short',
                timeZone: feature?.db?.type === 'timestamptz' ? undefined : 'UTC',
            });
            return (value) => {
                if (value === null) return null;
                const date = new Date();
                date.setUTCHours(0);
                date.setUTCMinutes(0);
                date.setUTCSeconds(value);
                return formatter.format(date);
            };
        },
    },
    hour: {
        label: 'Hour of day',
        value: 'hour',
        axisLabel: '',
        shortName: 'Hour',
        outputType: 'category',
        makeFormatter: (feature) => {
            const formatter = Intl.DateTimeFormat([], {
                hour: 'numeric',
                timeZone: feature?.db?.type === 'timestamptz' ? undefined : 'UTC',
            });
            return (value) => {
                if (value === null) return null;
                const date = new Date();
                date.setUTCMinutes(0);
                date.setUTCHours(value);
                return formatter.format(date);
            };
        },
    },

    // number bins
    bin: {
        label: 'Segment (bin)',
        value: 'bin',
        axisLabel: 'Bins of',
        shortName: 'Segmt',
        outputType: 'category',
        makeFormatter: (feature, serum) => {
            const binNames = getBinNames(feature, serum);
            return (index) => binNames[index];
        },
    },


    list: {
        label: 'List',
        value: 'list',
        axisLabel: 'List',
        shortName: 'List',
    },



};
