import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DatasetModel from '../../_models/dataset';

import {
    IconButton,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    Icon,
    ConfirmDeleteMenu,
    ModalCard,
    Input,
} from '../../../shared';
import { fileValidations } from '../../Add/Upload/helpers';

export default function DatasetsItemMenu(props) {
    const { dataset, collectionId } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [appendModalOpen, setAppendModalOpen] = useState(false);

    const handleDeleteDataset = () => {
        navigate(`/projects/${dataset.projectId}/datasets`); // /projects/{projectId}/datasets
        dispatch(DatasetModel.destroy(dataset._id));
    };

    const handleRemoveFromCollection = () => {
        let collections = dataset.collections;
        collections = collections.filter((c) => c !== collectionId);
        dispatch(DatasetModel.update(dataset._id, { collections }));
    };

    const handleAppend = (form) => {
        dispatch(DatasetModel.append(dataset._id, form.file));
        setAppendModalOpen(false);
    };

    return (
        <IconButton mini icon="menu-vertical" onClick={() => setMenuOpen(true)} active={menuOpen}>
            <ContextMenu
                listHeading={dataset.name}
                open={menuOpen}
                onClickOut={() => {
                    setMenuOpen(false);
                }}
                position="right"
            >
                <ContextSubMenu>
                    {dataset?.type === 'file' && (
                        <ContextMenuItem
                            key="append"
                            id="append"
                            name="Append Data"
                            type={<Icon size="16px" icon="plus-o" />}
                            onClick={() => {
                                setMenuOpen(false);
                                setAppendModalOpen(true);
                            }}
                        />
                    )}
                    {collectionId && (
                        <ContextMenuItem
                            key="removeFromCollection"
                            id="removeFromCollection"
                            name="Remove from Folder"
                            type={<Icon size="16px" icon="times" />}
                            onClick={handleRemoveFromCollection}
                        />
                    )}
                    <ContextMenuItem
                        key="delete"
                        id="delete"
                        name="Delete Dataset"
                        type={<Icon size="16px" icon="trash" />}
                        onClick={() => setDeleteModalOpen(true)}
                    >
                        <ConfirmDeleteMenu
                            open={deleteModalOpen}
                            onClose={() => setDeleteModalOpen(false)}
                            onDelete={handleDeleteDataset}
                        />
                    </ContextMenuItem>
                </ContextSubMenu>
            </ContextMenu>
            <ModalCard
                open={appendModalOpen}
                title={`Append to Dataset: ${dataset.name}`}
                onClose={() => setAppendModalOpen(false)}
                className="AppendDatasetModal"
                primaryButtonOptions={{ id: 'submit', content: 'Upload File' }}
                formOptions={{ onSubmit: handleAppend }}
            >
                <p>Upload a .csv file to add new rows to your existing dataset.</p>
                <p>
                    The column names in the new file <b>must match</b> those in the original file.
                </p>
                <Input type="file" id="file" validations={fileValidations} accept=".csv" gray required />
            </ModalCard>
        </IconButton>
    );
}

DatasetsItemMenu.propTypes = {
    dataset: PropTypes.shape({
        _id: PropTypes.string,
        projectId: PropTypes.string,
        collections: PropTypes.array,
        name: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    collectionId: PropTypes.string,
};
DatasetsItemMenu.defaultProps = {
    collectionId: null,
};
