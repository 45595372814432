import { inputSetupOptions } from './options';

export const updateInputDataType = (currentInput, dataType) => {
    const { inputTypeOptions, defaultConfig } = inputSetupOptions[dataType];
    return {
        ...currentInput,
        dataType,
        defaultValue: null,
        value: null,
        display: {
            modelBar: false,
            modelPanel: 'primary',
            inputType: inputTypeOptions[0].value, // use the first option as the default to set up the input
            config: defaultConfig || {},
        },
    };
};
