import { nanoid } from 'nanoid';
import ObjectId from 'bson-objectid';
import { Actions } from './reducers';
import TabsApi from './api';
import FiltersApi from '../filters/api';
import { operations } from '../../../../models/base';

const createFilter = (tabId, obj) => {
    const newObj = { ...obj, tab: { id: tabId } };
    const tempId = nanoid();
    return (dispatch) => {
        dispatch(Actions.filtersCreateRequest(tabId, newObj, tempId));
        TabsApi.createFilter(tabId, newObj).then(
            (filter) => dispatch(Actions.filtersCreateSuccess(tabId, filter, tempId)),
            (error) => dispatch(Actions.filtersCreateFailure(tabId, error, tempId)),
        );
    };
};
const updateFilter = (tabId, filterId, obj) => (dispatch) => {
    dispatch(Actions.filtersUpdateRequest(tabId, filterId, obj));
    FiltersApi.update(filterId, obj).then(
        (filter) => dispatch(Actions.filtersUpdateSuccess(tabId, filterId, filter)),
        (error) => dispatch(Actions.filtersUpdateFailure(tabId, filterId, error)),
    );
};
const destroyFilter = (tabId, filterId) => (dispatch) => {
    dispatch(Actions.filtersDestroyRequest(tabId, filterId));
    FiltersApi.destroy(filterId).then(
        (response) => dispatch(Actions.filtersDestroySuccess(tabId, filterId, response)),
        (error) => dispatch(Actions.filtersDestroyFailure(tabId, filterId, error)),
    );
};
const destroyAllFilters = (tabId) => (dispatch) => {
    dispatch(Actions.filtersDestroyAllRequest(tabId));
    TabsApi.destroyAllFilters(tabId).then(
        (response) => dispatch(Actions.filtersDestroyAllSuccess(tabId, response)),
        (error) => dispatch(Actions.FiltersDestroyAllFailure(tabId, error)),
    );
};

// const show = (id) => {
//     return (dispatch) => {
//         dispatch(Actions.showRequest(id));
//         TabsApi
//             .show(id)
//             .then(
//                 (tab) => dispatch(Actions.showSuccess(id, tab)),
//                 (error) => dispatch(Actions.showFailure(error)),
//             );
//     };
// };

// const createUnit = (id, config) => {
//     const unitId = ObjectId().toString();
//     const bodyWithId = { ...config, _id: unitId };
//     return (dispatch) => {
//         dispatch(Actions.createUnitRequest(id, bodyWithId, unitId));
//         TabsApi.createUnit(id, bodyWithId).then(
//             (tab) => dispatch(Actions.createUnitSuccess(id, tab, unitId)),
//             (errors) => dispatch(Actions.createUnitFailure(id, errors, unitId)),
//         );
//     };
// };

const createUnit = (id, config, callback) => {
    const unitId = ObjectId().toString();
    const bodyWithId = { ...config, _id: unitId };
    return (dispatch) => {
        dispatch(Actions.createUnitRequest(id, bodyWithId, unitId));
        TabsApi.createUnit(id, bodyWithId).then(
            (tab) => {
                dispatch(Actions.createUnitSuccess(id, tab, unitId));
                if (callback) callback(unitId); // Call the callback with the new unitId
            },
            (errors) => dispatch(Actions.createUnitFailure(id, errors, unitId)),
        );
    };
};


const duplicateUnit = (id, unitId, obj, callback) => (dispatch) => {
    dispatch(Actions.duplicateUnitRequest(id, unitId, obj));
    TabsApi.duplicateUnit(id, unitId, obj).then(
        (tab) => {
            dispatch(Actions.duplicateUnitSuccess(id, tab, unitId))
            if (callback) callback(unitId); // Call the callback with the new unitId
        },
        (errors) => dispatch(Actions.duplicateUnitFailure(id, errors, unitId)),
    );
};
const destroyUnit = (id, unitId) => (dispatch) => {
    dispatch(Actions.destroyUnitRequest(id, unitId));
    TabsApi.destroyUnit(id, unitId).then(
        (tab) => dispatch(Actions.destroyUnitSuccess(id, unitId, tab)),
        (errors) => dispatch(Actions.destroyUnitFailure(id, unitId, errors)),
    );
};

const updateLayout = (id, form) => (dispatch) => {
    dispatch(Actions.updateLayoutRequest(id, form));
    TabsApi.update(id, form).then(
        (tab) => dispatch(Actions.updateLayoutSuccess(id, tab)),
        (errors) => dispatch(Actions.updateLayoutFailure(id, errors)),
    );
};

export default operations(Actions, TabsApi, {
    // show,
    createFilter,
    updateFilter,
    destroyFilter,
    destroyAllFilters,
    // share,
    updateLayout,
    createUnit,
    destroyUnit,
    duplicateUnit,
});
