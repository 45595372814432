import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OptionsConfig from './Config/options';
import RatingScaleConfig from './Config/ratingScale';
import BinaryConfig from './Config/binary';
import NumberConfig from './Config/number';

import { ContextMenu, ContextSubMenu, ContextMenuItem, IconButton, Input } from '../../../shared';
import { questionTypeOptions } from '../helpers';

const elements = {
    dropdown: OptionsConfig,
    radio: OptionsConfig,
    checkbox: OptionsConfig,
    ratingScale: RatingScaleConfig,
    number: NumberConfig,
    binary: BinaryConfig,
};

export default function FormQuestionMapping({
    element,
    deleteElement,
    updateElement,
    moveUp,
    moveDown,
    moveToTop,
    moveToBottom,
    duplicate,
    editable,
}) {
    const { type, config, _id } = element;
    const [question, setQuestion] = useState(config.question);
    const [description, setDescription] = useState(config.description);
    const [hasDescription, setHasDescription] = useState(Boolean(config.description));

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [reorderContextMenuOpen, setReorderContextMenuOpen] = useState(false);
    const closeNestedContextMenus = () => {
        setReorderContextMenuOpen(false);
        setContextMenuOpen(false);
    };

    const updateConfig = (id, value) => updateElement({ type, config: { ...config, [id]: value } });
    const updateConfigWithUniqueId = (id, value) => {
        const key = id.split('_')[1];
        updateConfig(key, value);
    };
    const ElementConfig = elements[type];

    return (
        <div className="FormQuestion">
            <div className="FormQuestion__header">
                <Input
                    id={`${_id}_required`}
                    type="switch"
                    gray
                    label="Required"
                    value={!!config.required}
                    onChange={updateConfigWithUniqueId}
                    inlineLabel
                    labelRight
                    disabled={!editable}
                />
                {editable && (
                    <IconButton
                        icon="menu-vertical"
                        mini
                        onClick={() => setContextMenuOpen(true)}
                        active={contextMenuOpen}
                    >
                        <ContextMenu
                            open={contextMenuOpen}
                            onClickOut={closeNestedContextMenus}
                            onMouseLeave={() => setReorderContextMenuOpen(false)}
                        >
                            <ContextSubMenu>
                                {duplicate && (
                                    <ContextMenuItem
                                        id="duplicate"
                                        name="Duplicate"
                                        onClick={() => {
                                            setContextMenuOpen(false);
                                            duplicate();
                                        }}
                                        onMouseEnter={() => setReorderContextMenuOpen(false)}
                                    />
                                )}
                                <ContextMenu open={reorderContextMenuOpen} position="right top">
                                    <ContextSubMenu>
                                        {moveUp && (
                                            <ContextMenuItem
                                                id="moveUp"
                                                name="Move Up"
                                                onClick={() => {
                                                    closeNestedContextMenus();
                                                    moveUp();
                                                }}
                                            />
                                        )}
                                        {moveDown && (
                                            <ContextMenuItem
                                                id="moveDown"
                                                name="Move Down"
                                                onClick={() => {
                                                    closeNestedContextMenus();
                                                    moveDown();
                                                }}
                                            />
                                        )}
                                        {moveToTop && (
                                            <ContextMenuItem
                                                id="moveTop"
                                                name="Move to Top"
                                                onClick={() => {
                                                    closeNestedContextMenus();
                                                    moveToTop();
                                                }}
                                            />
                                        )}
                                        {moveToBottom && (
                                            <ContextMenuItem
                                                id="moveBottom"
                                                name="Move to Bottom"
                                                onClick={() => {
                                                    closeNestedContextMenus();
                                                    moveToBottom();
                                                }}
                                            />
                                        )}
                                    </ContextSubMenu>
                                </ContextMenu>
                                <ContextMenuItem
                                    id="reorderButton"
                                    name="Reorder"
                                    onMouseEnter={() => setReorderContextMenuOpen(true)}
                                />
                                <ContextMenuItem
                                    id="deleteButton"
                                    name="Delete"
                                    onClick={deleteElement}
                                    onMouseEnter={() => setReorderContextMenuOpen(false)}
                                />
                            </ContextSubMenu>
                        </ContextMenu>
                    </IconButton>
                )}
            </div>
            <div className="FormQuestion__question">
                <Input
                    id={`${_id}_question`}
                    type="text"
                    gray
                    label="Question"
                    value={question}
                    onChange={(_, value) => setQuestion(value)}
                    onSubmit={updateConfigWithUniqueId}
                    submitOnBlur
                    disabled={!editable}
                    className="FormQuestion__questionInput"
                />
                <Input
                    id={`${_id}_questionType`}
                    gray
                    type="select"
                    options={questionTypeOptions}
                    value={type}
                    onChange={(id, value) => {
                        updateElement({
                            type: value,
                            config: {
                                question,
                                description,
                                required: config.required,
                                ...(['dropdown', 'radio', 'checkbox'].includes(value) && { options: config.options }),
                            },
                        });
                    }}
                    disabled={!editable}
                />
            </div>
            {hasDescription ? (
                <Input
                    id={`${_id}_description`}
                    type="text"
                    gray
                    label="Description"
                    value={description}
                    onChange={(_, value) => setDescription(value)}
                    onSubmit={updateConfigWithUniqueId}
                    submitOnBlur
                    disabled={!editable}
                    className="FormQuestion__description"
                />
            ) : (
                <IconButton
                    icon="plus"
                    onClick={() => setHasDescription(true)}
                    label="Edit Description"
                    className="FormQuestion__addDescription"
                    disabled={!editable}
                />
            )}
            {ElementConfig && <hr />}
            {ElementConfig && <ElementConfig {...config} updateConfig={updateConfig} editable={editable} />}
        </div>
    );
}

FormQuestionMapping.propTypes = {
    element: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string.isRequired,
        config: PropTypes.shape({
            question: PropTypes.string,
            description: PropTypes.string,
            required: PropTypes.bool,
            options: PropTypes.string,
        }).isRequired,
    }).isRequired,
    deleteElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    moveUp: PropTypes.func,
    moveDown: PropTypes.func,
    moveToTop: PropTypes.func,
    moveToBottom: PropTypes.func,
    duplicate: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
};

FormQuestionMapping.defaultProps = {
    moveUp: null,
    moveDown: null,
    moveToTop: null,
    moveToBottom: null,
};
