import React from 'react';
import { Loader } from '../../../shared';

import './styles.scss';

export default function UnitLoader() {
    return (
        <div className="UnitLoader">
            <Loader type="spinner" loader="scale" />
        </div>
    );
}
