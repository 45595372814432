export const getProjectRoutes = (projectId) => {
    if (!projectId) return [];
    return [
        {
            path: `/projects/${projectId}/dashboards`,
            icon: 'dashboard',
            label: 'Data Apps',
            permissionRole: { dashboard: 'view' },
        },
        {
            path: `/projects/${projectId}/datasets`,
            icon: 'database',
            label: 'Datasets',
            permissionRole: { dataset: 'view' },
        },
        {
            path: `/projects/${projectId}/models`,
            icon: 'brain',
            label: 'Models',
            permissionRole: { package: 'view', model: 'view' },
        },
        {
            path: `/projects/${projectId}/forms`,
            icon: 'form',
            label: 'Forms',
            permissionRole: { form: 'view' },
        },
        {
            path: `/projects/${projectId}/mobi`,
            icon: 'chatbubble',
            label: 'Mobi (beta)',
            permissionRole: { form: 'view' },
        },
    ];
};
export const accessSettingsRoutes = [
    {
        path: '/settings/users/my',
        icon: 'account',
        label: 'Account Settings',
    },
    {
        path: '/settings/organizations/my',
        exact: false,
        icon: 'building',
        label: 'Organization Settings',
        role: 'editOrganizationSettings',
    },
    {
        path: '/settings/clients',
        icon: 'hierarchy',
        label: 'Client Organizations',
        role: 'manageClientOrganizations',
    },
    {
        path: '/settings/root',
        icon: 'whale',
        label: 'DeepSea Admin',
        role: 'manageAllOrganizations',
    },
    {
        path: '/login',
        label: 'Log Out',
        icon: 'sign-out',
    },
];
