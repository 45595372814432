import React from 'react';

import './styles.scss';

function CurrentSVG() {
    return (
        <svg
            version="1.1"
            className="CurrentSVG"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 612 792"
            xmlSpace="preserve"
        >
            <path
                className="CurrentSVG__path CurrentSVG__path--1"
                d="M612,600.3c-13.1,14.3-25.1,31.3-39.5,39.7c-27.2,16-74.2,10.9-92.9,35.7c-18.8,24.8-1.1,68.6-9.1,99.2
                	c-1.5,5.7-4.1,11.4-7.2,17.1"
            />
            <path
                className="CurrentSVG__path CurrentSVG__path--2"
                d="M612,563.9c-12.1,8.9-21.9,20.4-30.5,30.5c-7.8,9.1-15.8,18.5-22.6,22.5c-7.4,4.4-20.1,7-32.3,9.5
                	c-24.1,5-51.4,10.6-68.5,33.2C441,682.2,443,710,444.8,734.5c0.9,12.5,1.8,25.3-0.3,33.7c-1.7,6.6-7.1,15.2-12.9,23.9"
            />
            <path
                className="CurrentSVG__path CurrentSVG__path--3"
                d="M612,641.1c-7.7,8.4-16,16.3-25.9,22.1c-15.1,8.9-32.2,12.4-48.7,15.8c-14.8,3-31.5,6.5-36.5,13
                	c-4.9,6.5-3.7,23.6-2.6,38.6c1.2,16.8,2.5,34.2-1.9,51.1c-0.9,3.5-2.1,7-3.4,10.3"
            />
        </svg>
    );
}

export default CurrentSVG;
