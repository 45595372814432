import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import { Types as DatasetTypes } from '../dataset/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('job', {});
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('job', INITIAL_STATE, Schema, Types);
const updateSuccess = (state, action) => {
    if (state.byId[action.id]) {
        return crudReducers[Types.UPDATE_SUCCESS](crudReducers[Types.UPDATE_REQUEST](state, action), action);
    }
    return crudReducers[Types.SHOW_SUCCESS](crudReducers[Types.SHOW_REQUEST](state, action), action);
};

const deleteSuccess = (state, action) => {
    const newState = { ...state };
    newState.byId = { ...newState.byId };
    delete newState.byId[action.jobId];
    newState.allIds = newState.allIds.filter((id) => id !== action.jobId);
    return newState;
};

const additionalReducers = apiHandlers([], Types, {
    [Types.UPDATE_SUCCESS]: updateSuccess,
    [DatasetTypes.DELETE_JOB_DATA_SUCCESS]: deleteSuccess,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('jobs'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
