import React from 'react';
import { BrowserRouter, useRoutes, Navigate } from 'react-router-dom';

import App from './components/App';
import Login from './components/Access/Authentication';
import loginRoutes from './components/Access/Authentication/routes';

import Public from './public';
import PublicDashboard from './components/Dashboards/Public';
import DashboardPreview from './components/Dashboards/Preview';

import Settings from './components/Access';
import settingsRoutes from './components/Access/routes';
import Projects from './components/Projects';
import projectsRoutes from './components/Projects/routes';
import ErrorPage from './components/App/_components/error';
import PublicReport from './components/Dashboards/Public/PublicReplort';

const routes = [
    {
        path: '/login',
        exact: false,
        element: <Login />,
        public: true,
        children: loginRoutes,
    },
    {
        path: '/public',
        exact: false,
        element: <Public />, // make sure we have a projectId before continuing
        children: [
            {
                path: 'dashboards/report/:dashboardId/*',
                exact: false,
                element: <PublicReport />,
                public: true,
            },
            {
                path: 'dashboards/:dashboardId/*',
                exact: false,
                element: <PublicDashboard />,
                public: true,
            },
        ],
    },
    {
        path: 'preview/projects/:projectId/dashboards/:dashboardId/tabs/:tabId',
        exact: false,
        element: <DashboardPreview />,
        public: true,
    },
    {
        path: 'preview/projects/:projectId/dashboards/report/:dashboardId/tabs/:tabId',
        exact: false,
        element: <DashboardPreview />,
        public: true,
    },
    {
        path: '/',
        exact: false,
        element: <App />, // app to check login status
        children: [
            {
                path: '',
                exact: true,
                element: <Navigate to="projects" replace />,
            },
            {
                path: 'settings/*',
                exact: false,
                element: <Settings />,
                children: settingsRoutes,
            },
            {
                path: 'projects/*',
                exact: false,
                element: <Projects />,
                children: projectsRoutes,
            },
            { path: '*', element: <ErrorPage statusCode={404} /> },
        ],
    },
];

const AppRoutes = () => {
    const elements = useRoutes(routes);
    return elements;
};

const AppRouter = () => (
    <BrowserRouter>
        <AppRoutes />
    </BrowserRouter>
);

export default AppRouter;
