import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../../../shared';
import { defaultGridProps } from '../constants';

require('./styles.scss');

export default class GridPlaceholder extends PureComponent {
    onClick = () => {
        const { layoutItem, onClick } = this.props;
        const { x, y, w, h } = layoutItem;
        onClick({ x, y, w, h });
    };
    render() {
        const { id, addPrompt, editable } = this.props;
        const { rowHeight, heightUnits } = defaultGridProps; // plus 20 to account for padding
        const readOnlyText = {
            title: 'No cards have been added yet.',
            subtitle: 'Without edit access, you are not able to add cards',
        };
        const editableText = {
            title: 'Add some cards to get started',
            subtitle: 'Click the button and a card will appear',
        };

        if (addPrompt) {
            return (
                <div className="AddPlaceholder" style={{ height: rowHeight * heightUnits + 20 }}>
                    <div className="AddPlaceholder__title">{editable ? editableText.title : readOnlyText.title}</div>
                    <div className="AddPlaceholder__subtitle">
                        {editable ? editableText.subtitle : readOnlyText.subtitle}
                    </div>
                    {editable && (
                        <div className="GridPlaceholder__button" onClick={this.onClick} role="button" tabIndex={0}>
                            <Icon id={id} icon="plus" size="22px" />
                        </div>
                    )}
                </div>
            );
        }
        if (!editable) return null;
        return (
            <div className="GridPlaceholder">
                <div className="GridPlaceholder__button" onClick={this.onClick} role="button" tabIndex={0}>
                    <Icon id={id} icon="plus" size="22px" />
                </div>
            </div>
        );
    }
}
GridPlaceholder.propTypes = {
    id: PropTypes.string,
    addPrompt: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    layoutItem: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number,
        i: PropTypes.string,
    }),
};

GridPlaceholder.defaultProps = {
    id: '',
    addPrompt: false,
    layoutItem: null,
    editable: false,
};
