import React, { useEffect, useState } from 'react';
import { useLocation, useRoutes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Page, Button, Icon, Loader } from '../shared';
import ErrorBoundary from '../shared/ErrorBoundary';
import routes from './routes';
import DataSidebar from './DataSidebar';
import DatasetModel from './_models/dataset';
import DatasetAdd from './Add';
import { FeatureModel } from './_models';

require('./styles.scss');

export default function Data() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(DatasetModel.index());
        dispatch(FeatureModel.index());
    }, [dispatch]);

    const dataRoutes = useRoutes(routes);
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigateToDataset = (id) => {
        const tabMatch = location.pathname.match(/datasets\/\w*\/(.*)/);
        const tabId = tabMatch && tabMatch[1];
        navigate(`${id}/${tabId || 'data'}`);
    };
    const [addModalOpen, setAddModalOpen] = useState(false);

    const roles = useSelector((state) => state.app.permission?.roles);
    const canEditDatasets = roles?.dataset === 'edit';
    const datasets = useSelector((state) => state.datasets);

    const emptyDatasetPage = (
        <div className="data__empty">
            <Icon icon="large_color/dataset" size="75px" />
            <div className="data__empty--Title">No datasets.</div>
            <div className="data__empty--Text">
                Datasets can be uploaded here (as a CSV), or generated from model outputs and form responses.
            </div>
            {canEditDatasets && (
                <Button gray onClick={() => setAddModalOpen(true)}>
                    Upload Dataset
                </Button>
            )}
            <DatasetAdd open={addModalOpen} onClose={() => setAddModalOpen(false)} />
        </div>
    );

    if (!datasets.hydrated && datasets.allIds.length === 0) {
        return <Loader type="content" loader="content" />;
    }

    return (
        <ErrorBoundary>
            {datasets.allIds.length > 0 ? (
                <Page className="Data" listPane={<DataSidebar handleNavigateToDataset={handleNavigateToDataset} />}>
                    <Card className="Data__main">{dataRoutes}</Card>
                </Page>
            ) : (
                emptyDatasetPage
            )}
        </ErrorBoundary>
    );
}

Data.propTypes = {};
Data.defaultProps = {};
