import { capitalize } from '../../../../helpers/string';
import { getMainChartType } from '../../Chart/helpers';

export const getUnitPlaceholder = (unit) => {
    const chartTypePlaceholders = {
        pie: {
            icon: 'large_color/pie_placeholder',
            text: 'Drag numeric data, and use ‘Group By’ to separate it by categories.',
        },
        line: { icon: 'large_color/line_placeholder' },
        scatter: { icon: 'large_color/scatter_placeholder' },
        bar: { icon: 'large_color/bar_placeholder' },
        area: { icon: 'large_color/area_placeholder' },
        radar: {
            icon: 'large_color/radar_placeholder',
            text: 'Drag a list of categories, and columns with values for each category.',
        },
        boxplot: { icon: 'large_color/boxplot_placeholder', text: 'Drag and drop columns to plot their distribution.' },
        default: {
            header: `${capitalize(getMainChartType(unit.vis))} Chart`,
            text: 'Drag and drop columns to create something beautiful.',
        },
    };

    const customUnitPlaceholder = {
        network: {
            icon: 'large_color/network_placeholder',
            header: 'Network Graph',
            text: 'Drag a list of nodes, and sources + targets for each connection.',
        },
        editable: {
            icon: 'large_color/table_placeholder',
            header: 'EdiTable',
            text: 'Drag and drop columns to add them to your table.',
        },
        default: {
            icon: 'large_color/question-circle',
            header: `${capitalize(unit.subtype)}`,
            text: 'Create something beautiful.',
        },
    };

    const unitPlaceholder = {
        kpi: {
            icon: 'large_color/kpi_placeholder',
            header: 'KPI',
            text: 'Drag columns to derive KPI values from their row data.',
        },
        table: {
            icon: 'large_color/table_placeholder',
            header: 'Table',
            text: 'Drag and drop columns to add them to your table.',
        },
        chart: {
            ...chartTypePlaceholders.default,
            ...chartTypePlaceholders[getMainChartType(unit.vis)],
        },
        custom: {
            ...customUnitPlaceholder.default,
            ...customUnitPlaceholder[unit.subtype],
        },
    };

    return unitPlaceholder[unit.type];
};
