import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Loader, ModalCard, Input, DragAndDropList, Icon, InfoToolTip } from '../../../shared';
import CollectionModel from '../../_models/collections';
import CollectionsItemMenu from '../CollectionsItemMenu';
import DatasetsItemMenu from '../DatasetsItemMenu';
import { getIconFromDatasetType } from '../../helpers';

require('./styles.scss');

class CollectionsList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCreateModalOpen: false,
        };
    }

    componentDidMount = () => {
        const { collections, getCollections } = this.props;
        if (!collections.hydrated) getCollections();
    };

    componentDidUpdate(prevProps) {
        const { collections } = this.props;
        const { isCreateModalOpen } = this.state;
        if (
            isCreateModalOpen &&
            !collections.loading &&
            prevProps.collections.loading &&
            !(collections.errors.name || collections.errors.description)
        ) {
            this.toggleCreateModal();
        }
    }

    toggleCreateModal = () => {
        const { isCreateModalOpen } = this.state;
        const { resetErrors } = this.props;
        if (isCreateModalOpen) resetErrors();
        this.setState({ isCreateModalOpen: !isCreateModalOpen });
    };

    render() {
        const {
            collections,
            userId,
            datasets,
            createCollection,
            handleNavigate,
            handleDragDrop,
            canEditDatasets,
        } = this.props;
        const { isCreateModalOpen } = this.state;

        // ---------------------------------------------------------------------
        //                    Collections List Render
        // ---------------------------------------------------------------------
        const _collectionsListRender = () => {
            if (collections.hydrated || collections.allIds.length) {
                if (collections.allIds.length === 0) {
                    return (
                        <div className="DataSidebar__emptyPlaceholder">
                            <div className="DataSidebar__emptyPlaceholderTitle">No Folders</div>
                            <div className="DataSidebar__emptyPlaceholderDescription">
                                Add a folder to organize your datasets.
                            </div>
                        </div>
                    );
                }
                return collections.allIds.map((collectionId) => {
                    const collection = collections.byId[collectionId];
                    return (
                        <DragAndDropList
                            id={collection._id}
                            key={collection._id}
                            title={collection.name}
                            expanded={false}
                            collapsible
                            type={<Icon icon="folder" size="16px" />}
                            decorations={canEditDatasets && <CollectionsItemMenu collection={collection} />}
                            items={datasets
                                .filter(
                                    (dataset) => dataset.collections && dataset.collections.includes(collection._id),
                                )
                                .map((dataset) => ({
                                    id: dataset._id,
                                    label: dataset.name || '',
                                    type: <Icon icon={getIconFromDatasetType(dataset.type)} size="16px" />,
                                    decorations: (
                                        <React.Fragment>
                                            {dataset.description ? (
                                                <InfoToolTip>{dataset.description}</InfoToolTip>
                                            ) : null}
                                            {canEditDatasets ? <DatasetsItemMenu dataset={dataset} /> : null}
                                        </React.Fragment>
                                    ),
                                }))}
                            onClick={handleNavigate}
                            onDrop={handleDragDrop}
                            placeholderText="This folder is empty. Drop a dataset here to add."
                        />
                    );
                });
            }
            return <Loader />;
        };

        // ---------------------------------------------------------------------
        //                    Create Collection Modal
        // ---------------------------------------------------------------------

        const createCollectionModal = (
            <ModalCard
                title="Create Folder"
                open={isCreateModalOpen}
                onClose={this.toggleCreateModal}
                formOptions={{ onSubmit: (form) => createCollection({ ...form, user: { id: userId } }) }}
                autofocus
                primaryButtonOptions={{ content: 'Create', id: 'submit', loading: collections.loading }}
            >
                <div className="CollectionsList__createModal">
                    <Input
                        type="text"
                        id="name"
                        placeholder="Folder name"
                        label="Name"
                        errors={collections.errors.name}
                        gray
                        required
                    />
                    <Input
                        type="textarea"
                        id="description"
                        placeholder="description"
                        label="Description"
                        errors={collections.errors.description}
                        gray
                    />
                </div>
            </ModalCard>
        );

        // ---------------------------------------------------------------------
        return (
            <div className="CollectionsList">
                <div className="DataSidebar__title">
                    <span>Folders</span>
                    {canEditDatasets && (
                        <Button blue small onClick={this.toggleCreateModal}>
                            Add
                        </Button>
                    )}
                </div>

                {_collectionsListRender()}
                {createCollectionModal}
            </div>
        );
    }
}

CollectionsList.propTypes = {
    collections: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
        hydrated: PropTypes.bool,
        loading: PropTypes.bool,
        errors: PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
    userId: PropTypes.string,
    datasets: PropTypes.array,
    canEditDatasets: PropTypes.bool.isRequired,
    getCollections: PropTypes.func.isRequired,
    createCollection: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    handleDragDrop: PropTypes.func.isRequired,
};
CollectionsList.defaultProps = {
    datasets: [],
    userId: null,
};

const mapStateToProps = (state) => {
    const { collections } = state;
    const userId = state.app.user._id;
    return {
        collections,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => {
    const getCollections = () => dispatch(CollectionModel.index());
    const createCollection = (obj) => dispatch(CollectionModel.create(obj));
    const resetErrors = () => dispatch(CollectionModel.resetErrors());
    return {
        getCollections,
        createCollection,
        resetErrors,
    };
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CollectionsList);
export default connectedComponent;
