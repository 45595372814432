import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import { TabModel } from '../../_models';
import DashboardGrid from './Grid';
import { Loader } from '../../../shared';

require('./styles.scss');

class DashboardTab extends Component {
    constructor(props) {
        super(props);
        this.state = { width: -1 };
    }
    componentDidMount() {
        const { tab, showTab, id } = this.props;
        if (!tab.hydrated) showTab(id);
    }
    componentDidUpdate(prevProps) {
        const { id, tab, showTab } = this.props;
        if (id !== prevProps.id && !tab.hydrated) showTab(id);
    }

    handleResize = (contentRect) => this.setState({ width: contentRect.bounds.width });

    render() {
        const {
            id,
            tab,
            units,
            dashboardId,
            canEditDashboards,
            onLayoutChange,
            onUnitCreate,
            publicFilters,
        } = this.props;
        const { width } = this.state;

        if (!tab.hydrated) {
            return (
                <Measure bounds onResize={this.handleResize}>
                    {({ measureRef }) => (
                        <div className="Dashboard_Loaders" ref={measureRef} key={id}>
                            <Loader type="content" loader="content" />
                        </div>
                    )}
                </Measure>
            );
        }

        const editable = canEditDashboards;

        return (
            <Measure bounds onResize={this.handleResize}>
                {({ measureRef }) => (
                    <div className="DashboardTab" ref={measureRef} key={id}>
                        <DashboardGrid
                            id={id}
                            dashboardId={dashboardId}
                            hydrated={tab.hydrated && units.hydrated}
                            loading={tab.loading}
                            unitsLoading={units.loading}
                            unitsHydrated={units.hydrated}
                            editable={editable}
                            layout={tab.layout}
                            onCardCreate={onUnitCreate}
                            onLayoutChange={(layout) => id && editable && onLayoutChange(id, layout)}
                            unitIds={tab.units}
                            publicFilters={publicFilters}
                            width={width}
                        />
                    </div>
                )}
            </Measure>
        );
    }
}

DashboardTab.propTypes = {
    id: PropTypes.string.isRequired,
    canEditDashboards: PropTypes.bool,
    dashboardId: PropTypes.string,
    tab: PropTypes.shape({
        hydrated: PropTypes.bool,
        loading: PropTypes.bool,
        units: PropTypes.arrayOf(PropTypes.string),
        layout: PropTypes.array,
    }),
    units: PropTypes.shape({
        hydrated: PropTypes.bool,
        loading: PropTypes.bool,
    }).isRequired,
    showTab: PropTypes.func.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    onUnitCreate: PropTypes.func.isRequired,
    publicFilters: PropTypes.array,
};
DashboardTab.defaultProps = {
    dashboardId: null,
    tab: {},
    publicFilters: [],
    canEditDashboards: false,
};

const mapStateToProps = (state, props) => {
    const { tabs, units, filters, app } = state;
    const tab = tabs.byId[props.id];
    const canEditDashboards = app.permission?.roles.dashboard === 'edit';

    return {
        tab,
        filters,
        units,
        canEditDashboards,
    };
};

const mapDispatchToProps = (dispatch) => {
    const showTab = (id) => id && dispatch(TabModel.show(id));
    const onLayoutChange = (id, layout) => dispatch(TabModel.updateLayout(id, { layout }));
    const onUnitCreate = (tabId, config) => dispatch(TabModel.createUnit(tabId, config));

    return {
        showTab,
        onLayoutChange,
        onUnitCreate,
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DashboardTab);
export default connectedComponent;
