// first inputTypeOption is used as default
export const inputSetupOptions = {
    boolean: {
        label: 'Boolean',
        inputTypeOptions: [{ value: 'boolean', label: 'Select One' }],
    },
    integer: {
        label: 'Integer',
        inputTypeOptions: [{ value: 'number', label: 'Number' }],
        defaultConfig: { step: 1 },
    },
    float: {
        label: 'Float',
        inputTypeOptions: [{ value: 'number', label: 'Number' }],
        defaultConfig: { step: 0.1 },
    },
    string: {
        label: 'String',
        inputTypeOptions: [
            { value: 'text', label: 'Text' },
            { value: 'select', label: 'Select One' },
        ],
    },
    date: {
        label: 'Date',
        inputTypeOptions: [{ value: 'date', label: 'Date' }],
    },
    dictionary: {
        label: 'Dictionary',
        inputTypeOptions: [
            { value: 'json', label: 'JSON Input' },
            { value: 'constraint', label: 'Constraint (Custom Input)' },
        ],
    },
    list: {
        label: 'List',
        inputTypeOptions: [{ value: 'multiSelect', label: 'Select Many' }],
    },
    dataframe: {
        label: 'Dataframe',
        inputTypeOptions: [{ value: 'dataframe', label: 'Select One' }],
    },
};

export const inputDataTypeOptions = Object.entries(inputSetupOptions).map(([key, value]) => ({
    value: key,
    label: value.label,
}));
