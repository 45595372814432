import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModelInputType from './inputType';

export default function RunInfoInputs(props) {
    const { modelRun } = props;
    // the App always indexes datasets, so I'm not ensuring that they're loaded from within this component (listing them from here causes a re-rendering loop)
    const datasets = useSelector((state) => state.datasets);

    if (modelRun.inputs.length === 0) return <div className="RunInfoInputs__input">No inputs</div>;

    const primaryInputs = modelRun.inputs.filter((input) => input.display?.modelPanel === 'primary');
    const advancedSettings = modelRun.inputs.filter((input) => input.display?.modelPanel === 'secondary');
    const modelBarOnly = modelRun.inputs.filter(
        (input) => input.display?.modelPanel === 'hidden' && input.display?.modelBar,
    );
    const hidden = modelRun.inputs.filter(
        (input) => input.display?.modelPanel === 'hidden' && !input.display?.modelBar,
    );
    return (
        <div>
            {!!modelBarOnly.length && <ModelInputType title="Model Bar" inputs={modelBarOnly} datasets={datasets} />}
            {!!primaryInputs.length && (
                <ModelInputType title="Primary Inputs" inputs={primaryInputs} datasets={datasets} />
            )}
            {!!advancedSettings.length && (
                <ModelInputType title="Advanced Settings" inputs={advancedSettings} datasets={datasets} />
            )}
            {!!hidden.length && (
                <ModelInputType title="Model Configurations (Hidden)" inputs={hidden} datasets={datasets} />
            )}
        </div>
    );
}

RunInfoInputs.propTypes = {
    modelRun: PropTypes.shape({
        inputs: PropTypes.array,
    }),
};
RunInfoInputs.defaultProps = {
    modelRun: {},
};
