import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import JobModel from '../../_models/job';
import { JobQueue } from '../../../shared';
import JobSelectors from '../../_models/job/selectors';

require('./styles.scss');

class UploadQueue extends PureComponent {
    componentDidMount = () => {
        const { hydrated, getJobs } = this.props;
        if (!hydrated) getJobs();
    };
    render() {
        const { jobList } = this.props;

        const hasRecentJob = jobList.slice(0, 3).some((job) => ['continue', 'active'].includes(job.status));

        return (
            <div className="UploadQueue">
                <JobQueue jobs={jobList} defaultOpen={hasRecentJob} title="Upload Jobs" />
            </div>
        );
    }
}

UploadQueue.propTypes = {
    getJobs: PropTypes.func.isRequired,
    jobList: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
            user: PropTypes.shape({ id: PropTypes.string }),
            status: PropTypes.string,
            type: PropTypes.string,
            ownerId: PropTypes.string,
            ownerType: PropTypes.string,
            timeCreated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
            lastUpdated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
            action: PropTypes.func,
        }),
    ),
    hydrated: PropTypes.bool.isRequired,
};

UploadQueue.defaultProps = {
    jobList: [],
};

const mapStateToProps = (state) => {
    const { jobs } = state;
    return { jobList: JobSelectors.getUploadJobsForActiveUser(state), hydrated: jobs.hydrated };
};

const mapDispatchToProps = (dispatch) => {
    const getJobs = () => dispatch(JobModel.index());
    return { getJobs };
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(UploadQueue);
export default connectedComponent;
