import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PackageModel } from '../../../_models';
import { ModalCard, Input } from '../../../../shared';

require('./styles.scss');

const UploadPackageModal = (props) => {
    const { open, onClose, packageId } = props;
    const dispatch = useDispatch();
    const createPackage = (form) => {
        // only allow submit if zipfile and name have been entered
        if (form.zipfile && form.name) {
            if (packageId) {
                dispatch(PackageModel.update(packageId, form));
            } else {
                dispatch(PackageModel.upload(form));
            }
            onClose();
        }
    };

    return (
        <ModalCard
            open={open}
            title={packageId ? 'Update Package' : 'Upload Package'}
            onClose={onClose}
            primaryButtonOptions={{ content: 'Create', id: 'submit' }}
            formOptions={{ onSubmit: createPackage }}
        >
            <div className="UploadPackageModal">
                <Input
                    id="baseImage"
                    type="select"
                    label="Version"
                    gray
                    options={[
                        { label: 'Python 3.7', value: 'python:3.7-slim' },
                        { label: 'Python 3.8', value: 'python:3.8-slim' },
                        { label: 'Python 3.9', value: 'python:3.9-slim' },
                        { label: 'Python 3.10', value: 'python:3.10-slim' },
                        { label: 'Python 3.11', value: 'python:3.11-slim' },
                    ]}
                    value="python:3.10-slim"
                />
                <div className="UploadPackageModal__info">
                    <p>
                        Please upload a <b>zipped folder (.zip)</b> containing your Python package or files.
                    </p>
                    <p>You may include a requirements.txt file containing versions of your dependencies.</p>
                </div>
                <Input id="zipfile" type="file" gray accept=".zip" />
                <Input id="name" type="text" gray label="Name" required />
            </div>
        </ModalCard>
    );
};

UploadPackageModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};
UploadPackageModal.defaultProps = {
    open: false,
};

export default UploadPackageModal;
