import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
// we are using react-beautiful-dnd in this component, which is different from our usual dnd library, react-dnd
// we would like to switch everything to react-beautiful-dnd in the future, but there is a problem making it work in DeepSea right now (see DSP-2453)
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { DashboardModel, TabModel } from '../../_models';
import AddTabModal from '../../_components/modals/AddTabModal';
import { IconButton, VerticalLine } from '../../../shared';
import { reorderList } from './helpers';
import TabItem from './TabItem';

require('./styles.scss');

class TabBar extends Component {
    constructor(props) {
        super(props);
        this.state = { renderAddTabModal: false };
    }

    goToNewTab = (tabs) => {
        const { dashboard, navigateToDashboard } = this.props;
        const newTab = tabs[tabs.length - 1];
        navigateToDashboard(dashboard._id, newTab._id);
    };
    handleAddTab = (form) => {
        const { dashboard, addTab } = this.props;
        addTab(dashboard._id, form).then((action) => {
            if (action.errors) return;
            this.toggleAddTabModal();
            this.goToNewTab(action.dashboard.tabs);
        });
    };
    toggleAddTabModal = () => {
        const { renderAddTabModal } = this.state;
        this.setState({ renderAddTabModal: !renderAddTabModal });
    };

    onDragEnd = (result) => {
        if (!result.destination) return; // dropped outside the list
        if (result.source.index === result.destination.index) return; // tab didn't move from original position

        const { tabList, dashboardId, updateDashboard } = this.props;
        const dashboardTabs = tabList.map((tab) => ({ _id: tab._id })); // sometimes the whole tab object gets passed in, we just want _id
        const newTabs = reorderList(dashboardTabs, result.source.index, result.destination.index);
        updateDashboard(dashboardId, { tabs: newTabs });
    };

    render() {
        const {
            tabList,
            dashboard,
            tabs,
            tabId,
            editable,
            editTab,
            duplicateTab,
            deleteTab,
            navigateToDashboard,
        } = this.props;
        const { renderAddTabModal } = this.state;
        return (
            <div className="TabBar">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable-tabBar" direction="horizontal" isDropDisabled={!editable}>
                        {(provided) => (
                            <div ref={provided.innerRef} className="TabBar__droppable" {...provided.droppableProps}>
                                {tabList.map(
                                    (tab, i) =>
                                        tabs.byId[tab._id] && (
                                            <React.Fragment key={tab._id}>
                                                <Draggable draggableId={tab._id} index={i} isDragDisabled={!editable}>
                                                    {(providedDraggable, snapshot) => (
                                                        <div
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}
                                                            className={ClassNames('TabBar__draggable', {
                                                                'TabBar__draggable--dragging': snapshot.isDragging,
                                                            })}
                                                            style={providedDraggable.draggableProps.style}
                                                        >
                                                            <TabItem
                                                                selectedTabId={tabId}
                                                                tab={tabs.byId[tab._id]}
                                                                dashboard={dashboard}
                                                                editable={editable}
                                                                editTab={editTab}
                                                                goToNewTab={this.goToNewTab}
                                                                duplicateTab={duplicateTab}
                                                                deleteTab={deleteTab}
                                                                navigateToDashboard={navigateToDashboard}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                                {editable && <VerticalLine />}
                                                {!editable && tabList.length > i + 1 && <VerticalLine />}
                                            </React.Fragment>
                                        ),
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {editable && (
                    <IconButton
                        onClick={this.toggleAddTabModal}
                        id="addTab"
                        icon="plus"
                        className="TabBar__iconButton"
                    />
                )}
                <AddTabModal
                    open={renderAddTabModal}
                    onClose={this.toggleAddTabModal}
                    onSubmit={this.handleAddTab}
                    loading={dashboard.loading}
                />
            </div>
        );
    }
}

TabBar.propTypes = {
    tabId: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        loading: PropTypes.bool,
    }),
    tabs: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    tabList: PropTypes.arrayOf(PropTypes.shape({})),
    deleteTab: PropTypes.func.isRequired,
    editTab: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    duplicateTab: PropTypes.func.isRequired,
    updateDashboard: PropTypes.func.isRequired,
    navigateToDashboard: PropTypes.func,
    editable: PropTypes.bool,
};
TabBar.defaultProps = {
    dashboard: {},
    tabList: [],
    editable: true,
    navigateToDashboard: () => {},
};

const mapStateToProps = (state, props) => {
    const { tabs, dashboards, app } = state;
    const dashboard = dashboards.byId[props.dashboardId];
    const tabList = (dashboard && dashboard.tabs) || [];
    const canEditDashboards = app.permission?.roles.dashboard === 'edit';

    return {
        editable: canEditDashboards,
        dashboard,
        tabs,
        tabList,
    };
};

const mapDispatchToProps = (dispatch) => {
    const addTab = (groupId, form) => dispatch(DashboardModel.createTab(groupId, form));
    const editTab = (id, form) => dispatch(TabModel.update(id, form));
    const duplicateTab = (dashboardId, tabId) => dispatch(DashboardModel.duplicateTab(dashboardId, tabId));
    const deleteTab = (groupId, tabId) => dispatch(DashboardModel.destroyTab(groupId, tabId));
    const updateDashboard = (dashboardId, obj) => dispatch(DashboardModel.update(dashboardId, obj));
    return {
        addTab,
        editTab,
        deleteTab,
        duplicateTab,
        updateDashboard,
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(TabBar);
export default connectedComponent;
