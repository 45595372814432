import React from 'react';
import { Navigate } from 'react-router-dom';
import UserSettings from './Management/User';
import OrganizationSettings from './Management/Organization';
import RootSettings from './Management/Root';
import RootOrganizationList from './Management/Root/OrganizationList';
import RootOrganizationSettings from './Management/Root/OrganizationView';
import { UserRoleProtectedRoute } from '../../helpers/router';
import ConsultantOrganizationsList from './Management/Consultant/ConsultantOrganizationsList';
import ConsultantOrganizationSettings from './Management/Consultant';

export default [
    {
        path: '',
        exact: true,
        element: <Navigate to="users/my" replace />,
    },
    {
        path: 'users/my/*', // only take you to your user - in a page
        exact: false,
        element: <UserSettings />,
    },

    {
        path: 'organizations/my/*',
        exact: false,
        element: (
            <UserRoleProtectedRoute requiredRole="editOrganizationSettings">
                <OrganizationSettings />
            </UserRoleProtectedRoute>
        ),
    },

    // Client Routes - could be under the organization/my route
    {
        path: 'clients',
        exact: false,
        element: (
            <UserRoleProtectedRoute requiredRole="manageClientOrganizations">
                <ConsultantOrganizationsList />
            </UserRoleProtectedRoute>
        ),
        role: 'manageClientOrganizations',
    },
    {
        path: 'clients/:clientId/*', // this is for accessing client organizations from a consulting organization
        exact: false,
        element: (
            <UserRoleProtectedRoute requiredRole="manageClientOrganizations">
                <ConsultantOrganizationSettings />
            </UserRoleProtectedRoute>
        ),
        role: 'manageClientOrganizations',
    },
    {
        path: 'root/*',
        exact: false,
        element: (
            <UserRoleProtectedRoute requiredRole="manageAllOrganizations">
                <RootSettings />
            </UserRoleProtectedRoute>
        ),
        children: [
            {
                path: '',
                exact: false,
                element: <Navigate to="organizations" replace />,
            },
            {
                path: 'organizations',
                exact: false,
                element: <RootOrganizationList />,
            },
            {
                path: 'organizations/:organizationId/*',
                exact: false,
                element: <RootOrganizationSettings />,
            },
        ],
    },
];
