import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCard, Tabs, EditableTitle } from '../../../shared';
import Description from './tabs/description';
import Timeline from './tabs/timeline';
import Inputs from './tabs/inputs';
import { ModelRunModel } from '../../_models';
import UserSelectors from '../../../Access/_models/users/selectors';

import './styles.scss';

export default function RunInfoModal(props) {
    const { modelRun, modelName, onClose } = props;
    const dispatch = useDispatch();
    const requestor = useSelector(UserSelectors.getUserInfo(modelRun.userId));
    const title = (
        <EditableTitle
            defaultValue={modelRun.name}
            placeholder={modelName}
            onSubmit={(_, name) => dispatch(ModelRunModel.update(modelRun._id, { name }))}
            allowEmptyString
        />
    );
    return (
        <ModalCard open={!!modelRun._id} onClose={onClose} title={title}>
            <div className="RunInfoModal">
                {requestor?.email && <div className="RunInfoModal__requestor"> Requested by: {requestor.email} </div>}
                <Tabs
                    useRoutes={false}
                    showLine
                    tabs={[
                        {
                            label: 'Status',
                            element: <Timeline modelRun={modelRun} />,
                        },
                        {
                            label: 'Inputs',
                            element: <Inputs modelRun={modelRun} />,
                        },
                        {
                            label: 'Description',
                            element: <Description modelRun={modelRun} />,
                        },
                    ]}
                />
            </div>
        </ModalCard>
    );
}

RunInfoModal.propTypes = {
    modelRun: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        userId: PropTypes.string,
    }),
    modelName: PropTypes.string,
    onClose: PropTypes.func,
};
RunInfoModal.defaultProps = {
    modelRun: {},
    modelName: '',
    onClose: () => {},
};
