import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon, ContextMenu, ContextSubMenu, ContextMenuItem } from '../../../../shared';
import { colorSchemes, getColorFromColorScheme } from '../../../../Units/colors';
import FeatureOverrideItem from './FeatureOverrideItem';

require('./styles.scss');

export default class DashboardColors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addOverride: false,
            openPickerId: null,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { addOverride } = this.state;
        const { addOverride: prevAddOverride } = prevState;

        if (addOverride && !prevAddOverride) {
            const children = this.overridesRef.children;
            const lastChild = children[children.length - 1];
            lastChild.scrollIntoView({ behavior: 'smooth' });
        }
    };

    handleClickOut = () => {
        const { onClickOut } = this.props;
        this.setState({ addOverride: false, openPickerId: null });
        onClickOut();
    };

    setOverridesRef = (element) => {
        this.overridesRef = element;
    };

    setColorPickerOpen = (featureId) => {
        const { openPickerId } = this.state;
        const { colors } = this.props;
        if (openPickerId === featureId || !Object.keys(colors.features).includes(featureId)) {
            this.setState({ openPickerId: null, addOverride: false });
        } else {
            this.setState({ openPickerId: featureId, addOverride: false });
        }
    };

    render() {
        const {
            features,
            colors,
            updateColor,
            resetColors,
            removeColor,
            addColor,
            updateColorScheme,
            open,
        } = this.props;
        const { addOverride, openPickerId } = this.state;

        if (!colors?.features) return null;

        return (
            <ContextMenu
                open={open}
                className="DashboardColors"
                onClickOut={this.handleClickOut}
                listHeading="Data App Colors"
            >
                <ContextSubMenu title="Color Themes">
                    <div className="DashboardColors__themesList">
                        {Object.keys(colorSchemes).map((name) => (
                            <div
                                key={name}
                                role="button"
                                tabIndex={0}
                                onClick={() => colors.colorScheme === name || updateColorScheme(name)}
                                className={ClassNames('DashboardColors__themePreview', {
                                    'DashboardColors__themePreview--selected': colors.colorScheme === name,
                                })}
                            >
                                {[0, 0.2, 0.4, 0.6, 0.8, 1].map((scaleIndex) => (
                                    <div
                                        key={scaleIndex}
                                        className="DashboardColors__themePreviewColor"
                                        style={{ backgroundColor: getColorFromColorScheme(name, scaleIndex) }}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </ContextSubMenu>
                {/* Feature Overrides */}
                <div className="DashboardColors__overrides" ref={this.setOverridesRef}>
                    {Object.keys(colors.features).map((featureId) => (
                        <ContextSubMenu>
                            <FeatureOverrideItem
                                features={features}
                                featureId={featureId}
                                colors={colors}
                                onClear={() => removeColor(featureId)}
                                updateColor={updateColor}
                                setOpenPicker={() => this.setColorPickerOpen(featureId)}
                                openPicker={openPickerId === featureId}
                            />
                        </ContextSubMenu>
                    ))}
                    {addOverride && (
                        <ContextSubMenu>
                            <FeatureOverrideItem
                                features={features}
                                colors={colors}
                                onClear={() => this.setState({ addOverride: false })}
                                onSelect={(featureId) => {
                                    addColor(featureId);
                                    this.setState({ addOverride: null });
                                }}
                            />
                        </ContextSubMenu>
                    )}
                </div>
                <ContextSubMenu>
                    <ContextMenuItem
                        key="addNewFeatureColor"
                        id="addNewFeatureColor"
                        name="Feature Override"
                        onClick={() => this.setState({ addOverride: !addOverride, openPickerId: null })}
                        type={<Icon className="DashboardColors__icon" icon="plus" size="16px" />}
                    />
                </ContextSubMenu>
                <ContextSubMenu>
                    <ContextMenuItem
                        key="resetColors"
                        id="resetColors"
                        name="Reset Colors"
                        onClick={resetColors}
                        type={<Icon className="DashboardColors__icon" icon="times" size="16px" />}
                    />
                </ContextSubMenu>
            </ContextMenu>
        );
    }
}

DashboardColors.propTypes = {
    features: PropTypes.shape({ byId: PropTypes.shape({}) }).isRequired,
    colors: PropTypes.shape({
        colorScheme: PropTypes.string,
        features: PropTypes.shape({}),
    }),
    open: PropTypes.bool,
    addColor: PropTypes.func.isRequired,
    removeColor: PropTypes.func.isRequired,
    resetColors: PropTypes.func.isRequired,
    updateColor: PropTypes.func.isRequired,
    updateColorScheme: PropTypes.func.isRequired,
    onClickOut: PropTypes.func.isRequired,
};

DashboardColors.defaultProps = {
    colors: null,
    open: false,
};
