import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tabs, EditableTitle } from '../../shared';
import DatasetModel from '../_models/dataset';

import DatasetType from '../_components/DatasetType';
import DatasetData from './Data';
import DataFeatures from './Features';
import DatasetHistory from './DatasetHistory';

require('./styles.scss');

export default function DataTabs() {
    const params = useParams();
    const { datasetId } = params;

    const dataset = useSelector((state) => state.datasets.byId[datasetId] || {});
    const form = useSelector((state) => state.forms.byId[dataset?.source?.form?.id] || null);
    const model = useSelector((state) => state.models.byId[dataset?.source?.model?.id] || null);
    const roles = useSelector((state) => state.app.permission?.roles);
    const canEditDatasets = roles?.dataset === 'edit';
    const canEditForms = roles?.form === 'edit';
    const canEditPackages = roles?.package === 'edit';

    const dispatch = useDispatch();
    const updateDataset = (id, obj) => dispatch(DatasetModel.update(id, obj));

    const handleNameChange = (_, name) => {
        updateDataset(datasetId, { name });
    };

    const tabs = [
        {
            path: 'data',
            params,
            label: 'Data',
            element: <DatasetData dataset={dataset} datasetId={datasetId} updateDataset={updateDataset} />,
        },
        {
            path: 'features',
            params,
            label: 'Features',
            element: <DataFeatures />,
        },
    ];
    if (dataset.type === 'file') {
        tabs.push({
            path: 'history',
            params,
            label: 'Append History',
            element: <DatasetHistory datasetId={datasetId} />,
        });
    }

    return (
        <div className="DataTabs">
            <div className="DataTabs__header">
                <EditableTitle
                    defaultValue={dataset.name}
                    disabled={!canEditDatasets}
                    key={datasetId}
                    onSubmit={handleNameChange}
                />
                <DatasetType
                    dataset={dataset}
                    form={form}
                    model={model}
                    canEditForms={canEditForms}
                    canEditPackages={canEditPackages}
                />
            </div>
            <Tabs tabs={tabs} />
        </div>
    );
}

DataTabs.propTypes = {};
DataTabs.defaultProps = {};
