import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import FilterSummaryItem from './item';
import './styles.scss';

// Text display for multiple features
export default function FilterSummary({ value }) {
    const features = useSelector((state) => state.features);
    return (
        <div className="FilterSummary">
            {value.map((filterValue) => (
                <FilterSummaryItem sm key={nanoid()} value={filterValue} features={features} />
            ))}
        </div>
    );
}

FilterSummary.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            option: PropTypes.string,
            value: PropTypes.shape({}),
            feature: PropTypes.shape({ id: PropTypes.string }),
        }),
    ),
};
FilterSummary.defaultProps = {
    value: [],
};
