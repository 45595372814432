import React from 'react';
import PropTypes from 'prop-types';

import { KPI } from '../../shared';
import { getKpiData } from './helpers';
import { getSerumDisplayName } from '../serum';

// A single KPI part to be rendered by KpiUnit (./index.js) and KpiMapping (./Mapping/index.js)
export default function SingleKpiUnit(props) {
    const { serum, data, features } = props;
    const { label, unitOfMeasure, value } = getKpiData(serum, data, features);
    return (
        <KPI
            big
            label={label === undefined ? getSerumDisplayName(serum, null, features, { showFilter: false }) : label}
            unit={unitOfMeasure}
            value={value}
        />
    );
}

SingleKpiUnit.propTypes = {
    serum: PropTypes.shape({}),
    data: PropTypes.shape({}),
    features: PropTypes.shape({}).isRequired,
};

SingleKpiUnit.defaultProps = {
    serum: null,
    data: null,
};
