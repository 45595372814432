import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon, Input } from '../../../shared';
import PackageFunctionSelect from '../../_components/PackageFunctionSelect';

require('./styles.scss');

const CreateModel = (props) => {
    const { metadata, setMetadata, modelDraft, setModelDraft, modelId } = props;

    const { packages, algorithms } = useSelector((state) => ({
        packages: state.packages,
        algorithms: state.algorithms,
    }));

    const [name, setName] = useState(modelDraft.name || '');
    const [description, setDescription] = useState(modelDraft.description || '');

    return (
        <div className="CreateModel">
            <div className="CreateModel__inputs">
                <Input
                    id="name"
                    type="text"
                    gray
                    label="Model Name"
                    className="CreateModel__name"
                    placeholder="Auto (Function Name)"
                    required
                    value={name}
                    onChange={(_, value) => setName(value)}
                    onBlur={() => setModelDraft({ ...modelDraft, name })}
                />
                <Input
                    id="description"
                    type="text"
                    gray
                    label="Description"
                    className="CreateModel__description"
                    placeholder="Optional"
                    value={description}
                    onChange={(_, value) => setDescription(value)}
                    onBlur={() => setModelDraft({ ...modelDraft, description })}
                />
            </div>
            <hr />
            <Icon icon="large_color/package-select" size="75px" />
            <div className="CreateModel__selectTitle">Select Source Function:</div>
            <div className="CreateModel__selectInfo">
                Select a function from the <Link to="/models">source code</Link> you’ve uploaded to DeepSea.
            </div>
            <PackageFunctionSelect
                metadata={metadata}
                updateMetaData={(updateMetadata) => {
                    setMetadata(updateMetadata);
                    if (updateMetadata.algorithmId && !name && algorithms.byId[updateMetadata.algorithmId]) {
                        const functionName = algorithms.byId[updateMetadata.algorithmId].name;
                        setName(functionName);
                        setModelDraft({ ...modelDraft, name: functionName });
                    }
                }}
                packages={packages}
                algorithms={algorithms}
                disabled={!!modelId} // model already exists - algo can't be changed
            />
        </div>
    );
};

CreateModel.propTypes = {
    modelDraft: PropTypes.shape({
        metadata: PropTypes.shape(),
        name: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    setModelDraft: PropTypes.func.isRequired,
    metadata: PropTypes.shape().isRequired,
    setMetadata: PropTypes.func.isRequired,
    modelId: PropTypes.string,
};
CreateModel.defaultProps = {
    modelId: null,
};

export default CreateModel;
