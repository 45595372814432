import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Chip, ColorPicker } from '../../../../shared';

export default function ChipColorPicker({ setColor, color, changed }) {
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!isOpen);

    return (
        <Chip onClick={toggleOpen} active={isOpen} highlighted={changed} tooltip="Color">
            <div style={{ height: 12, width: 12, background: color, borderRadius: 2 }} />
            <ColorPicker color={color} onPicked={setColor} open={isOpen} onClickOut={toggleOpen} position="bottom" />
        </Chip>
    );
}

ChipColorPicker.propTypes = {
    setColor: PropTypes.func,
    color: PropTypes.string,
    changed: PropTypes.bool,
};

ChipColorPicker.defaultProps = {
    setColor: () => {},
    color: '',
    changed: false,
};
