import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import the useTranslation hook

import OrganizationModel from '../../_models/organizations';
import UserModel from '../../_models/users';
import { UserRoleProtectedRoute } from '../../../../helpers/router';

import OrganizationLog from './Tabs/Log';
import OrganizationGeneral from './Tabs/General';
import OrganizationUsers from './Tabs/Users';
import OrganizationsProjectList from './Tabs/Projects';
import Footer from '../_components/footer';

import { Tabs } from '../../../shared';
import { t } from '../../../../utils/translate'

require('./styles.scss');

export default function OrganizationSettings() {
    const params = useParams();
    const myUser = useSelector((state) => state.app.user);
    const organizationId = params.organizationId || myUser.organizationId;
    const organization = useSelector((state) => state.organizations.byId[organizationId] || {});
    const users = useSelector((state) =>
        Object.values(state.users.byId).filter((user) => user.organizationId === organizationId),
    );
    const usersHydrated = useSelector((state) => state.users.hydrated);

    const dispatch = useDispatch();
    const update = (id, form) => dispatch(OrganizationModel.update(id, form));
    const updateUser = (userId, form) => dispatch(UserModel.update(userId, form));

    useEffect(() => {
        dispatch(OrganizationModel.show(organizationId));
        if (myUser.roles.manageOrganizationUsers) dispatch(UserModel.getOrganizationUsers(organizationId));
        if (myUser.roles.viewOrganizationLogins) dispatch(OrganizationModel.showLogs(organizationId));
    }, [dispatch, organizationId, myUser]);

    const tabs = [
        {
            path: 'general',
            label: 'General Settings',
            element: (
                <UserRoleProtectedRoute requiredRole="editOrganizationSettings">
                    <OrganizationGeneral organization={organization} onSubmit={update} />
                </UserRoleProtectedRoute>
            ),
        },
    ];
    if (myUser.roles.manageOrganizationUsers) {
        tabs.push({
            path: 'users',
            label: 'User Accounts',
            element: (
                <UserRoleProtectedRoute requiredRole="manageOrganizationUsers">
                    <OrganizationUsers
                        users={users}
                        myUser={myUser}
                        hydrated={usersHydrated}
                        errors={organization.errors}
                        deleteUser={(userId) => dispatch(UserModel.destroy(userId))}
                        createUser={(id, form) => dispatch(UserModel.create(form, id))}
                        updateUser={updateUser}
                        id={organizationId}
                    />
                </UserRoleProtectedRoute>
            ),
        });
    }
    if (myUser.roles.manageOrganizationProjects) {
        tabs.push({
            path: 'projects',
            label: t('Projects'),
            element: (
                <UserRoleProtectedRoute requiredRole="manageOrganizationProjects">
                    <OrganizationsProjectList organizationId={organizationId} />
                </UserRoleProtectedRoute>
            ),
        });
    }
    if (myUser.roles.viewOrganizationLogins) {
        tabs.push({
            path: 'log',
            label: 'Login History',
            element: (
                <UserRoleProtectedRoute requiredRole="viewOrganizationLogins">
                    <OrganizationLog listItems={organization.log} />
                </UserRoleProtectedRoute>
            ),
        });
    }

    return (
        <div className="OrganizationSettings">
            <Tabs tabs={tabs} />
            <Footer />
        </div>
    );
}
