import { chartTypes, getMainChartType } from '../constants';
import { _getYProps, _getXYProps, _addAllSpecificProps } from './helpers';

export const getChartProps = (vis, data, dashboardColors) => {
    const { series, features } = data || {};
    const mergedColors = { ...dashboardColors, ...vis.colors };
    const chartType = getMainChartType(vis);
    const props =
        chartTypes[chartType].dataType === 'y'
            ? _getYProps(vis, series, mergedColors, { byId: features })
            : _getXYProps(vis, series, mergedColors, { byId: features });
    return _addAllSpecificProps(props, vis, series, chartType);
};
