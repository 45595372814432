/* eslint-disable no-param-reassign */
import { getSerumType } from '../../../serum';
import { getMainChartType } from '../constants';

export const switchAxes = (vis) => {
    vis.display.dependentAxis = vis.display.dependentAxis === 'x' ? 'y' : 'x';

    const tempDependentAxes = [...vis.dependent];
    vis.dependent = [...vis.independent];
    vis.independent = tempDependentAxes;
    vis.dependent.forEach((axis) =>
        axis.series.forEach((serum) => {
            serum.display = {};
        }),
    );
    vis.independent.forEach((axis) => {
        axis.series = axis.series
            .map((serum) => ({
                ...serum,
                chartType: null,
                display: {},
            }))
            .filter((serum) => serum && serum.feature);
    });
};

export const checkAndSwitchAxes = (vis, features) => {
    if (!vis.dependent.length && !vis.independent.length) return false;
    const chartType = getMainChartType(vis);
    if (vis.display.dependentAxis !== 'x') {
        // case: dependentAxis === 'y' or default
        const pieOrRadar = ['pie', 'radar'].includes(chartType);
        if (pieOrRadar) return false;

        const xAxisHasMoreSeriesThanY =
            vis.independent[0]?.series.length > 1 && vis.independent[0].series.length > vis.dependent[0].series.length;
        const onlyXAxisHasNuggets =
            !vis.dependent[0]?.series[0]?.feature &&
            vis.independent[0]?.series[0] &&
            getSerumType(vis.independent[0].series[0], features) === 'number';
        const onlyYAxisHasNuggets =
            !vis.independent[0]?.series[0]?.feature &&
            vis.dependent[0]?.series[0] &&
            getSerumType(vis.dependent[0].series[0], features) !== 'number';
        const yAxisNotNumericOnAreaLineBar =
            ['area', 'line', 'bar', 'boxplot'].includes(chartType) &&
            vis.dependent[0]?.series[0] &&
            getSerumType(vis.dependent[0].series[0], features) !== 'number' &&
            getSerumType(vis.independent[0] && vis.independent[0].series[0], features) === 'number'; // and xAxis is numeric (otherwise no point in switching?)

        if (xAxisHasMoreSeriesThanY || onlyYAxisHasNuggets || onlyXAxisHasNuggets || yAxisNotNumericOnAreaLineBar) {
            switchAxes(vis);
            return true;
        }
    } else {
        // case: dependentAxis === 'x'
        const pieOrRadar = ['pie', 'radar'].includes(chartType);
        if (pieOrRadar) return false;

        const yNuggetExists = vis.independent[0] && vis.independent[0].series.length;
        const xAxisHasLessSeriesThanY =
            !vis.dependent[0] ||
            vis.dependent[0].series.length < 2 ||
            vis.independent[0].series.length >= vis.dependent[0].series.length;
        const yAxisIsNumeric =
            vis.independent[0]?.series[0] && getSerumType(vis.independent[0].series[0], features) === 'number';
        const notAreaLineBar = !['area', 'line', 'bar', 'boxplot'].includes(chartType);
        const onlyXAxisHasNuggets =
            !vis.independent[0]?.series[0]?.feature &&
            vis.dependent[0]?.series[0] &&
            getSerumType(vis.dependent[0].series[0], features) !== 'number';

        if (onlyXAxisHasNuggets || (yNuggetExists && xAxisHasLessSeriesThanY && (yAxisIsNumeric || notAreaLineBar))) {
            switchAxes(vis);
            return true;
        }
    }
    return false;
};

export const getCurrentAxisForNugget = (vis, changeId) => {
    const dependent = () => {
        if (!vis.dependent[0]) vis.dependent = [{ series: [], display: {} }];
        return {
            axisToUpdate: vis.dependent[0],
            otherAxis: vis.independent[0],
        };
    };
    const independent = () => {
        if (!vis.independent[0]) vis.independent = [{ series: [], display: {} }];
        return {
            axisToUpdate: vis.independent[0],
            otherAxis: vis.dependent[0],
        };
    };

    return changeId === `${vis.display.dependentAxis || 'y'}Nugget` ? dependent() : independent();
};

export const updateSeriesNugget = (vis, changeId, value, features, priorShouldClearData) => {
    let axisToUpdate = null;
    let shouldClearData = priorShouldClearData;
    if (changeId === 'xNugget' || changeId === 'yNugget') {
        axisToUpdate = getCurrentAxisForNugget(vis, changeId).axisToUpdate;
    } else if (changeId === 'zNugget') {
        if (!vis.otherDimensions[0]) vis.otherDimensions = [{ series: [], type: 'dotSize', display: {} }];
        axisToUpdate = vis.otherDimensions.find((axis) => axis.type === 'dotSize');
    } else if (changeId === 'gradientNugget') {
        if (!vis.gradientDimension[0]) vis.gradientDimension = [{ series: [], type: 'gradient', display: {} }];
        axisToUpdate = vis.gradientDimension.find((axis) => axis.type === 'gradient');
    } else if (changeId === 'tooltipNugget') {
        if (!vis.tooltip) vis.tooltip = { series: [] };
        axisToUpdate = vis.tooltip;
    }

    if (value.length < axisToUpdate.series.length) {
        shouldClearData = true;
    }

    axisToUpdate.series = value;

    if (changeId === 'xNugget' || changeId === 'yNugget') checkAndSwitchAxes(vis, features);

    // clean up any empty axes left over
    if (!vis.independent[0]?.series.length) vis.independent = [];
    if (!vis.dependent[0]?.series.length) vis.dependent = [];
    if (!vis.otherDimensions[0]?.series?.length) vis.otherDimensions = [];
    if (!vis.gradientDimension[0]?.series?.length) vis.gradientDimension = [];
    if (!vis.tooltip?.series.length) delete vis.tooltip;

    // clear chartType from independent axis
    vis.independent.forEach((axis) =>
        axis.series.forEach((serum) => {
            if (serum.chartType) serum.chartType = null;
        }),
    );
    return shouldClearData;
};
