import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { presetRoles } from '../../../../../constants';
import { Notification, Input, ModalCard } from '../../../../../../shared';
import UserRoles from '../../../../_components/UserRoles';

require('./styles.scss');

export default function CreateUserModal(props) {
    const { onSubmit, onClose, open, errors, hydrated, myRoles } = props;

    const [submitted, setSubmitted] = useState(false);
    // only closes if the user was successfully created
    useEffect(() => {
        if (open && submitted && hydrated && !errors.first && !errors.last && !errors.email && !errors.roles) {
            onClose();
            setSubmitted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hydrated, submitted, open, errors]);

    const [newUserRoles, setNewUserRoles] = useState({ ...presetRoles.userRoles.roles });

    return (
        <ModalCard
            title="Create User"
            open={open}
            onClose={onClose}
            formOptions={{
                onSubmit: (form) => {
                    setSubmitted(true);
                    onSubmit({ ...form, roles: newUserRoles });
                },
            }}
            autofocus
            primaryButtonOptions={{ content: 'Send Email Invite', id: 'submit', loading: !hydrated }}
            secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: onClose }}
        >
            <div className="CreateUserModal">
                <Notification
                    inline
                    message="This user will receive an email invite to claim their DeepSea account."
                    status="info"
                />
                <div className="CreateUserModal__row">
                    <Input type="text" id="first" label="First Name" errors={errors.first} required gray />
                    <Input type="text" id="last" label="Last Name" errors={errors.last} required gray />
                    <Input type="text" id="email" label="Email Address" errors={errors.email} required gray />
                </div>
                <hr />
                <UserRoles userRoles={newUserRoles} myRoles={myRoles} setUserRoles={setNewUserRoles} />
            </div>
        </ModalCard>
    );
}

CreateUserModal.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    hydrated: PropTypes.bool,
    errors: PropTypes.shape({
        email: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            }),
        ),
        first: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            }),
        ),
        last: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            }),
        ),
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            }),
        ),
    }),
    myRoles: PropTypes.shape({ manageAllOrganizations: PropTypes.bool }).isRequired,
};

CreateUserModal.defaultProps = {
    onSubmit: () => {},
    onClose: () => {},
    open: false,
    hydrated: true,
    errors: {},
};
