import React from 'react';
import { useRoutes } from 'react-router-dom';
import ErrorBoundary from '../shared/ErrorBoundary';

import routes from './routes';

export default function Forms() {
    const formRoutes = useRoutes(routes);
    return <ErrorBoundary>{formRoutes}</ErrorBoundary>;
}

Forms.propTypes = {};
Forms.defaultProps = {};
