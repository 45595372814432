import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';
import { SocketTypes } from '../../../App/Socket/socket_types';
import { Types as algorithmTypes } from '../algorithms/reducers';
import { Types as modelRunTypes } from '../modelRuns/types';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// Making sure base reducers do not overwrite inputs (we are storing model inputs in redux instead of component state)
const showSuccess = (state = INITIAL_STATE, { id, model }) => {
    const allIds = [...state.allIds];
    const byId = { ...state.byId };
    byId[id] = {
        ...Schema,
        ...byId[id],
        ...model,
        inputs: (byId[id] && byId[id].inputs) || model.inputs, // do not overwrite inputs in redux
        hydrated: true,
        loading: false,
    };
    if (allIds.indexOf(id) === -1) allIds.push(id);
    return { ...state, hydrated: true, loading: false, errors: {}, byId, allIds };
};
const indexSuccess = (state = INITIAL_STATE, { models }) => {
    const allIds = [...state.allIds];
    const byId = { ...state.byId };
    models.list.forEach((model, i) => {
        const id = model._id;
        byId[id] = {
            ...Schema,
            ...byId[id],
            ...model,
            inputs: (byId[id] && byId[id].inputs) || model.inputs, // do not overwrite inputs in redux
            listIndex: i,
        };
        if (!allIds.includes(id)) allIds.push(id);
    });
    return { ...state, hydrated: true, loading: false, errors: {}, byId, allIds };
};

//  TURN ON/OFF RUNNING FLAG
const updateModelRunning = (state, { modelRun }) => {
    const { status, modelId, outputs, tempOutputs } = modelRun;
    const byId = { ...state.byId };
    // only update if the person already had the model in their redux
    if (byId[modelId]) {
        const running = status === 'queued' || status === 'started'; // leaving queued here for now
        byId[modelId] = {
            ...byId[modelId],
            running,
            outputs: outputs || byId[modelId].outputs,
            tempOutputs,
        };
        if (byId[modelId].runs && !byId[modelId].runs.includes(modelRun._id)) {
            byId[modelId].runs = [...byId[modelId].runs, modelRun._id];
        }
    }
    return { ...state, byId };
};

const runSuccess = (state) => state;
// previously:
// return updateModelRunning(state, { modelRun: response.response });
// this caused the model to be stuck running sometimes:
// there is a race condition with the /model/{id} route and the sockets:
// the worker can run the model and notify the socket before the /model/{id} https request completes

// not removing this function b/c the base RUN_SUCCESS reducer would set loading: false (which isn't bad per se, but I thought not touching loading is better when the RUN_REQUEST doesn't touch loading)
const runRequest = (state, { id }) => {
    const model = { ...state.byId[id], running: true };
    return { ...state, byId: { ...state.byId, [id]: model } };
};
const runFailure = (state) => ({ ...state, errors: {} });

const getRunsSuccess = (state, { id, modelRuns }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], runs: modelRuns.map((run) => run._id), loading: false };
    return { ...state, byId };
};

const updateAlgorithmSuccess = (state, { id, model }) => {
    const byId = { ...state.byId };
    byId[id] = { ...model };
    return { ...state, byId };
};

const updateOutputsSuccess = (state, { id, data }) => {
    const { model } = data;
    const byId = { ...state.byId };
    byId[id] = { ...model };
    return { ...state, byId };
};



const updateLocal = (state, { id, updateObj }) => {
    // update redux state locally without sending requests (does not set loading)
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], ...updateObj };
    return { ...state, byId };
};

const modelRunDestroy = (state, { id: modelRunId }) => {
    const byId = { ...state.byId };
    const modelId = state.allIds.find((mId) => byId[mId].runs && byId[mId].runs.includes(modelRunId));
    if (modelId) {
        byId[modelId] = {
            ...byId[modelId],
            jobId: byId[modelId].jobId === modelRunId ? 'latest' : byId[modelId].jobId,
            runs: byId[modelId].runs.filter((mrId) => mrId !== modelRunId),
        };
        return { ...state, byId };
    }
    return state;
};

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('model', {
    runRequest: ['id'],
    runSuccess: ['id', 'modelRun'],
    runFailure: ['id', 'errors'],
    getRunsRequest: ['id'],
    getRunsSuccess: ['id', 'modelRuns'],
    getRunsFailure: ['id', 'errors'],
    duplicateRequest: ['id'],
    duplicateSuccess: ['id', 'model'],
    duplicateFailure: ['id', 'errors'],
    updateAlgorithmRequest: ['id', 'algorithmId'],
    updateAlgorithmSuccess: ['id', 'model'],
    updateAlgorithmFailure: ['id', 'errors'],
    updateModelRequest: ['id'],
    updateModelSuccess: ['id', 'model'],
    updateModelFailure: ['id', 'errors'],
    updateOutputsRequest: ['id', 'updates'],
    updateOutputsSuccess: ['id', 'data'],
    updateOutputsFailure: ['id', 'errors'],
    editOutputsRequest: ['id', 'datasetId', 'updates'],
    editOutputSuccess: ['id', 'data'],
    editOutputFailure: ['id', 'errors'],
    updateLocal: ['id', 'updateObj'],
    confirmRequest: ['id'],
    confirmSuccess: ['id', 'data'],
    confirmFailure: ['id', 'errors'],
});
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('model', INITIAL_STATE, Schema, Types);
const additionalReducers = apiHandlers(
    ['RUN', 'GET_RUNS', 'DUPLICATE', 'UPDATE_ALGORITHM', 'UPDATE_OUTPUTS', 'CONFIRM'],
    Types,
    {
        [Types.INDEX_SUCCESS]: indexSuccess,
        [Types.SHOW_SUCCESS]: showSuccess,
        [SocketTypes.MODEL_RUN]: updateModelRunning,
        [SocketTypes.MODEL_UPDATE]: updateAlgorithmSuccess,
        [algorithmTypes.CREATE_MODEL_SUCCESS]: crudReducers[Types.CREATE_SUCCESS],
        [modelRunTypes.DESTROY_SUCCESS]: modelRunDestroy,
        [Types.RUN_REQUEST]: runRequest,
        [Types.RUN_FAILURE]: runFailure,
        [Types.RUN_SUCCESS]: runSuccess,
        [Types.GET_RUNS_SUCCESS]: getRunsSuccess,
        [Types.DUPLICATE_SUCCESS]: crudReducers[Types.CREATE_SUCCESS],
        [Types.UPDATE_MODEL_SUCCESS]: updateAlgorithmSuccess,
        [Types.UPDATE_ALGORITHM_SUCCESS]: updateAlgorithmSuccess,
        [Types.UPDATE_OUTPUTS_SUCCESS]: updateOutputsSuccess,
        [Types.EDIT_OUTPUT_SUCCESS]: updateLocal,
        [Types.UPDATE_LOCAL]: updateLocal,
        [Types.CONFIRM_SUCCESS]: updateOutputsSuccess,
        [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
        [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('models'),
    },
);
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
