import ApiBase from '../../../../models/base/api';
import { createQueryString } from '../../../../models/base/api/helpers';

export class FeatureApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('features', addProjectIdToSearchParams);
    }

    data = (id, options = {}) => this.request('GET', `${this.endpoint}/${id}/data${createQueryString(options)}`);
    stats = (id) => this.request('GET', `${this.endpoint}/${id}/stats`);
}

export default new FeatureApi();
