import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { chartTypes, getMultiSeriesOptionsForType, xyChartTypes } from '../../helpers';
import { ContextMenu, ContextSubMenu, ContextMenuItem, Icon, Chip } from '../../../../shared';
import ChipColorPicker from '../components/color';
import FeatureBasket from '../../../components/FeatureBasket';
import ChipFilter from '../../../../Data/Filter/ChipFilter';
import SerumOperationButton from '../../../components/SerumButton/operation';

export default function DependentBasket({
    features,
    datasets,
    showSourceNames,
    showColors,
    getColorForSeries,
    seriesColors,
    operationId,
    mainChartType,
    onChange,
    dndProps,
}) {
    const { serum, containerIndex } = dndProps;
    const feature = features.byId[serum.feature?._id];
    const dataset = datasets.byId[feature?.dataset._id];
    const datasetName = showSourceNames && dataset?.name;
    const chartTypeOptions = getMultiSeriesOptionsForType(serum.chartType || mainChartType);

    const [chartTypeMenuOpen, setChartTypeMenuOpen] = useState(null);
    return (
        <FeatureBasket {...dndProps} datasetName={datasetName} feature={feature}>
            <div className="ChartMapping__AxisConfig">
                {showColors && (
                    <ChipColorPicker
                        setColor={(color) => {
                            onChange('addColor', {
                                serumId: serum.id,
                                color,
                            });
                        }}
                        changed={!!seriesColors[serum.id]}
                        color={getColorForSeries(serum)}
                    />
                )}
                {Object.keys(xyChartTypes).includes(mainChartType) && (
                    <Chip
                        onClick={() => setChartTypeMenuOpen(!chartTypeMenuOpen)}
                        active={chartTypeMenuOpen}
                        disabled={!chartTypeOptions}
                        highlighted={!!serum.chartType}
                        tooltip="Series Chart Type"
                    >
                        <Icon icon={chartTypes[serum.chartType || mainChartType]?.icon} />
                        <ContextMenu
                            open={!!chartTypeMenuOpen}
                            className="FeatureBasket__menu"
                            onClickOut={() => setChartTypeMenuOpen(false)}
                        >
                            <ContextSubMenu>
                                {chartTypeOptions?.map((option) => (
                                    <ContextMenuItem
                                        id={option.id}
                                        key={option.id}
                                        name={option.id}
                                        type={<Icon icon={option.icon} size="16px" />}
                                        onClick={() => onChange(`chartType-${containerIndex}`, option.id)}
                                    />
                                ))}
                                {serum.chartType && (
                                    <ContextMenuItem
                                        id="none"
                                        name="clear"
                                        type={<Icon icon="times" size="16px" />}
                                        onClick={() => onChange(`chartType-${containerIndex}`, undefined)}
                                    />
                                )}
                            </ContextSubMenu>
                        </ContextMenu>
                    </Chip>
                )}
                <SerumOperationButton
                    id={`${operationId}-${containerIndex}`}
                    value={serum.operation}
                    onChange={onChange}
                    feature={feature}
                    binValues={{ id: `${operationId}NumberOfBins-${containerIndex}`, value: serum.numberOfBins }}
                />
                <ChipFilter
                    onChange={(_, filters) =>
                        onChange('addFilters', {
                            id: serum.id,
                            filters,
                        })
                    }
                    value={serum.filters || []}
                    datasetIds={dataset?._id ? [dataset?._id] : []}
                />
            </div>
        </FeatureBasket>
    );
}

DependentBasket.propTypes = {
    onChange: PropTypes.func.isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        loading: PropTypes.bool,
    }).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    showColors: PropTypes.bool,
    showSourceNames: PropTypes.bool,
    getColorForSeries: PropTypes.func,
    operationId: PropTypes.string,
    mainChartType: PropTypes.string,
    dndProps: PropTypes.shape({
        serum: PropTypes.shape({
            id: PropTypes.string,
            feature: PropTypes.shape({ _id: PropTypes.string }),
            operation: PropTypes.string,
            filters: PropTypes.array,
            chartType: PropTypes.string,
            numberOfBins: PropTypes.number,
        }),
        containerIndex: PropTypes.number,
    }).isRequired,
    seriesColors: PropTypes.shape({}),
};

DependentBasket.defaultProps = {
    mainChartType: 'area',
    showColors: true,
    showSourceNames: false,
    getColorForSeries: () => null,
    operationId: '',
    seriesColors: {},
};
