import React from 'react';
import PropTypes from 'prop-types';

import { ModalCard, Input } from '../../../../shared';

export default function AddTabModal(props) {
    const { title, formValue, open, onClose, onSubmit, loading, noDescription } = props;
    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title={title}
            formOptions={{ onSubmit, loading }}
            autofocus
            primaryButtonOptions={{
                content: 'Submit',
                id: 'submit',
                loading,
            }}
        >
            <div className="DashboardList__Modal">
                <Input id="name" type="text" label="Name" gray value={formValue?.name} required />
                {!noDescription && (
                    <Input id="description" type="textarea" label="Description" gray value={formValue?.description} />
                )}
            </div>
        </ModalCard>
    );
}
AddTabModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    formValue: PropTypes.shape({ name: PropTypes.string, description: PropTypes.string }),
    noDescription: PropTypes.bool,
};
AddTabModal.defaultProps = {
    title: 'Create Tab',
    formValue: {},
    open: false,
    onClose: () => {},
    onSubmit: () => {},
    loading: false,
    noDescription: false,
};
