import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, Button } from '../../../../../shared';
import Validations from '../../../../_components/Validations';
import { passwordValidations } from '../../../../constants';
import { showNotification } from '../../../../../shared/Notification/helpers';

require('./styles.scss');

export default function UserPassword(props) {
    const { hydrated, onSubmit } = props;
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const form = { currentPassword, password: newPassword, confirmPassword: confirmNewPassword };

    const handleSubmit = () => {
        if (!passwordValidations.every((val) => val.validate(form))) return;
        onSubmit(form).then((action) => {
            if (action.type.includes('SUCCESS')) {
                showNotification({ status: 'success', message: 'Password updated successfully', autoaDismiss: 3000 });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            }
        });
    };

    return (
        <div className="UserPassword">
            <div className="UserPassword__title">Reset Your Password:</div>
            <Input
                type="password"
                id="currentPassword"
                label="Enter Current Password"
                gray
                value={currentPassword}
                onChange={(_, value) => setCurrentPassword(value)}
                onSubmit={handleSubmit}
            />
            <hr />
            <div className="UserPassword__newPassword">
                <Input
                    type="password"
                    id="password"
                    label="Enter New Password"
                    gray
                    value={newPassword}
                    onChange={(_, value) => setNewPassword(value)}
                    onSubmit={handleSubmit}
                />
                <Input
                    type="password"
                    id="confirmPassword"
                    label="Confirm New Password"
                    gray
                    value={confirmNewPassword}
                    onChange={(_, value) => setConfirmNewPassword(value)}
                    onSubmit={handleSubmit}
                />
            </div>
            <Validations validations={passwordValidations} form={form} />
            <Button id="submit" blue loading={!hydrated} onClick={handleSubmit}>
                Reset Password
            </Button>
        </div>
    );
}

UserPassword.propTypes = {
    onSubmit: PropTypes.func,
    hydrated: PropTypes.bool,
};

UserPassword.defaultProps = {
    onSubmit: () => {},
    hydrated: true,
};
