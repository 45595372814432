/* eslint-disable react/no-array-index-key */
// It is safe to use keys as ids in this component -- the elements we are rendering do not have component state (and do not reorder often)
// https://reactjs.org/docs/lists-and-keys.html
// https://medium.com/@robinpokorny/index-as-a-key-is-an-anti-pattern-e0349aece318
// https://stackoverflow.com/questions/29808636/when-giving-unique-keys-to-components-is-it-okay-to-use-math-random-for-gener
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import IconButton from '../IconButton';
import Form from '../Form';
import Input from '../Input';
import Loader from '../Loaders';
import ScrollArea from '../ScrollArea';

require('./styles.scss');

export default class DataTable extends PureComponent {
    static getDerivedStateFromProps() {
        return { loadingPagination: false };
    }
    constructor(props) {
        super(props);
        this.state = { expandedRows: [] };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { pagination, onPaginate } = this.props;
        const value = parseInt(values.current);
        if (value <= pagination.total && value > 0) {
            onPaginate(value);
        } else if (value < 0) {
            onPaginate(1);
        } else {
            onPaginate(pagination.total);
        }
    }

    toggleRowExpanded(rowIndex) {
        const { expandedRows } = this.state;
        if (expandedRows && expandedRows.includes(rowIndex)) {
            this.setState({ expandedRows: expandedRows.filter((value) => value !== rowIndex) });
        } else {
            this.setState({ expandedRows: expandedRows.concat([rowIndex]) });
        }
    }

    render() {
        const { pagination, onPaginate, titles, loading, loadingNumber, pageLoading, white, value } = this.props;
        const { expandedRows } = this.state;

        const data = loading
            ? Array.from({ length: loadingNumber }, () => ({ Loading: <Loader type="content" loader="content" /> }))
            : value;
        const hasData = data.length > 0;
        const hasTitle = Object.keys(titles).length > 0 && !loading;

        let headers = <th label="Empty table" />;
        if (hasData) {
            headers = Object.keys(hasTitle ? titles : data[0]).map((key) => <th key={key}>{titles[key] || key}</th>);
        } else if (hasTitle && Object.keys(titles).length === 1) {
            // for when feature preview table is empty
            headers = <th>{Object.values(titles)[0]}</th>;
        }

        // ------------------------------------------------------------------
        //                          rows
        // ------------------------------------------------------------------
        const rows = hasData ? (
            data.map((row, i) => (
                <tr
                    key={i}
                    className={ClassNames('DataTable__row', {
                        'DataTable__row--expanded': expandedRows.includes(i),
                    })}
                    onDoubleClick={() => this.toggleRowExpanded(i)}
                >
                    {Object.keys(hasTitle ? titles : row).map((key) => (
                        <td key={key}>{row[key]}</td>
                    ))}
                </tr>
            ))
        ) : (
            <tr>
                <td>No data</td>
            </tr>
        );

        // ------------------------------------------------------------------
        //                          footer
        // ------------------------------------------------------------------
        const tableFooter = () => (
            <footer className="DataTable__footer">
                <IconButton
                    icon="angle-left"
                    onClick={() => onPaginate(pagination.prev)}
                    disabled={!pagination.hasPrev}
                />

                <div className="DataTable__paginationInput">
                    <span>Page</span>
                    <Form onSubmit={this.handleSubmit} key={pagination.current || '1'}>
                        <Input
                            autosize
                            sm
                            id="current"
                            type="text"
                            placeholder="1"
                            submitOnBlur
                            value={pagination.current.toString()}
                            loading={loading}
                        />
                    </Form>
                    <span>{`of ${pagination.total}`}</span>
                </div>
                <IconButton
                    icon="angle-right"
                    onClick={() => onPaginate(pagination.next)}
                    disabled={!pagination.hasNext || pagination.current >= pagination.total}
                />
            </footer>
        );
        // ------------------------------------------------------------------

        return (
            <div
                className={ClassNames('DataTable', {
                    'DataTable--pagination': pagination.total > 1,
                    'DataTable--noData': !hasData,
                    'DataTable--loading': loading,
                    'DataTable--white': white,
                })}
                ref={(node) => {
                    this.containerRef = node;
                }}
            >
                <div className="DataTable__table">
                    <ScrollArea>
                        <table>
                            <thead>
                                <tr>{headers}</tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>

                        {pageLoading && (
                            <div className="DataTable--pageLoading">
                                <Loader type="spinner" loader="scale" />
                            </div>
                        )}
                    </ScrollArea>
                </div>
                {pagination.total > 1 && tableFooter()}
            </div>
        );
    }
}

DataTable.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    pagination: PropTypes.shape({
        current: PropTypes.number,
        hasNext: PropTypes.bool,
        hasPrev: PropTypes.bool,
        next: PropTypes.number,
        prev: PropTypes.number,
        total: PropTypes.number,
    }),
    onPaginate: PropTypes.func,
    titles: PropTypes.shape({}),
    loading: PropTypes.bool,
    loadingNumber: PropTypes.number,
    pageLoading: PropTypes.bool,
    white: PropTypes.bool,
};

DataTable.defaultProps = {
    value: [],
    pagination: { current: 0, hasNext: false, hasPrev: false, next: 0, prev: 0, total: null },
    titles: {},
    onPaginate: () => {},
    loading: false,
    loadingNumber: 10,
    pageLoading: false,
    white: false,
};
