import React from 'react';
import PropTypes from 'prop-types';

import ChartMapping from '../../Chart/Mapping';
import TableMapping from '../../Table/Mapping';
import KPIMapping from '../../KPI/Mapping';
import CustomMapping from '../../Custom/mapping';
import TypeSelection from '../../components/TypeSelection';

require('./styles.scss');

const unitMappingTypes = {
    chart: ChartMapping,
    kpi: KPIMapping,
    table: TableMapping,
    custom: CustomMapping,
};

export default function UnitMapping(props) {
    const { unit, selectType, datasetIds } = props;
    const UnitMappingType = unitMappingTypes[unit.type || 'chart'];

    return (
        <div className="UnitMapping">
            {!unit.type ? <TypeSelection editable height={3} onSelect={selectType} /> : <UnitMappingType {...props} />}
        </div>
    );
}

UnitMapping.propTypes = {
    unit: PropTypes.shape({ type: PropTypes.string }),
    selectType: PropTypes.func.isRequired,
};
UnitMapping.defaultProps = {
    unit: {},
};
