import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fuzzySearch } from '../../../helpers/string';
import { Button, Icon, Input, ModalCard, Loader } from '../../shared';
import SelectableBar from '../../shared/SelectableBar';
import { PermissionModel, ProjectModel } from '../_models';
import ProjectMenu from '../_components/ProjectMenu';
import OrganizationLogo from '../../Access/_components/OrganizationLogo';

import { t } from '../../../utils/translate'

require('./styles.scss');

export default function ProjectList() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [filter, setFilter] = useState('');

    const myUserId = useSelector((state) => state.app.user._id);
    const permissions = useSelector((state) => state.permissions);
    const projects = useSelector((state) => state.projects);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(!projects.hydrated);
    useEffect(() => {
        dispatch(ProjectModel.clearResources());
        dispatch(ProjectModel.listMy())
            .then(({ projects: projs }) =>
                Promise.all(projs.map((project) => dispatch(PermissionModel.listByProject(project._id)))),
            )
            .then(() => setLoading(false));
    }, [dispatch]);

    const myPermissions = Object.values(permissions.byId).filter((permission) => permission.userId === myUserId);
    const myProjectIds = myPermissions.map((permission) => permission.projectId);

    const myProjects = projects.allIds
        .map((id) => projects.byId[id])
        .filter((project) => myProjectIds.includes(project._id));
    const filteredProjects = fuzzySearch(filter, myProjects).sort((a, b) => a.name.localeCompare(b.name));

    sessionStorage.removeItem('projectId');

    if (loading) return <Loader type="content" loader="content" />;

    const createProjectModal = (
        <ModalCard
            open={createModalOpen}
            title="Create New Project"
            onClose={() => setCreateModalOpen(false)}
            formOptions={{
                onSubmit: (form) => {
                    dispatch(ProjectModel.create(form)).then(({ project }) =>
                        dispatch(PermissionModel.listByProject(project._id)),
                    );
                    setCreateModalOpen(false);
                },
            }}
            autofocus
            primaryButtonOptions={{ content: 'Create Project', id: 'submit' }}
            secondaryButtonOptions={{
                content: 'Cancel',
                id: 'cancel',
                onClick: () => setCreateModalOpen(false),
            }}
            className="ProjectList__modal"
        >
            <Input type="text" id="name" required label="Project Name" />
        </ModalCard>
    );

    if (myProjects.length === 0)
        return (
            <div className="ProjectList__empty">
                <Icon icon="large_color/emptyProject" size="75px" />
                <div className="ProjectList__empty--Title">No projects available.</div>
                <div className="ProjectList__empty--Text">
                    Projects contain sets of related dashboards, datasets, forms, models, and source code.
                </div>
                <Button gray onClick={() => setCreateModalOpen(true)}>
                    {t('Create Project')}
                </Button>
                {createProjectModal}
            </div>
        );

    return (
        <div className="ProjectList">
            <div className="ProjectList__full">
                <div className="ProjectList__header ThreeColumnHeader">
                    <div className="ProjectList__title">{t("Projects")}</div>
                    <Input
                        type="search"
                        id="search"
                        placeholder="Search projects"
                        value={filter}
                        onChange={(id, value) => setFilter(value)}
                    />
                    <Button blue onClick={() => setCreateModalOpen(true)}>
                        {t('Create Project')}
                    </Button>
                </div>
                <div className="ProjectList__body">
                    {filteredProjects.length > 0 ? (
                        filteredProjects.map((project) => (
                            <SelectableBar
                                key={project._id}
                                title={project.name}
                                logo={<OrganizationLogo id={project.organizationId} />}
                                onClick={() => navigate(project._id)}
                                menuComponent={ProjectMenu}
                                menuComponentProps={{
                                    project,
                                    onTransferAssetModalClose: () => dispatch(ProjectModel.clearResources()),
                                }}
                            />
                        ))
                    ) : (
                        <div className="ProjectList__empty">
                            <Icon icon="large_color/question-circle" size="75px" />
                            <div className="ProjectList__empty--Title">No projects found.</div>
                        </div>
                    )}
                </div>
            </div>
            {createProjectModal}
        </div>
    );
}

ProjectList.propTypes = {};
ProjectList.defaultProps = {};
