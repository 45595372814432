import React from 'react';
import PropTypes from 'prop-types';
import { getFeatureFormatFunction } from '../../../helpers';

import { DataTable } from '../../../../shared';

require('./styles.scss');

export default function FeaturePreveiw(props) {
    const { activeFeature } = props;

    const activeFeatureFormatter = getFeatureFormatFunction(activeFeature);

    return (
        <section className="FeaturePreview">
            <header>Column Preview</header>
            <span>How this feature will look in a Deepsea table:</span>
            <section className="FeaturePreview__background">
                <DataTable
                    titles={{ value: activeFeature.name }}
                    value={activeFeature.preview?.map((val) => ({
                        value: activeFeatureFormatter(val),
                    }))}
                    loading={!activeFeature.preview}
                    loadingNumber={4}
                />
            </section>
        </section>
    );
}

FeaturePreveiw.propTypes = {
    activeFeature: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        preview: PropTypes.array,
    }),
};

FeaturePreveiw.defaultProps = {
    activeFeature: {},
};
