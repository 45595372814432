import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../shared';
import DataMenu from './DataMenu';

require('./styles.scss');

export default function DatasetSelection(props) {
    const { datasets, collections, value, onSelectDataset } = props;
    const [isDatasetMenuOpen, setDatasetMenuOpen] = useState(false);

    return (
        <div className="DatasetSelection">
            <Input
                className="DatasetSelection__button"
                id="datasetSelect"
                type="text"
                role="button"
                onClick={() => setDatasetMenuOpen(!isDatasetMenuOpen)}
                value={datasets?.byId[value]?.name}
                placeholder="Select dataset"
                icon="angle-down"
                sm
                gray
            />
            <DataMenu
                collections={collections}
                datasets={datasets}
                open={isDatasetMenuOpen}
                onSelect={(datasetId) => {
                    onSelectDataset(datasetId);
                    setDatasetMenuOpen(false);
                }}
                onClose={() => setDatasetMenuOpen(false)}
            />
        </div>
    );
}

DatasetSelection.propTypes = {
    collections: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.arrayOf(PropTypes.string),
        hydrated: PropTypes.bool,
    }).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.arrayOf(PropTypes.string),
        hydrated: PropTypes.bool,
    }).isRequired,
    value: PropTypes.string,
    onSelectDataset: PropTypes.func,
};

DatasetSelection.defaultProps = {
    value: null,
    onSelectDataset: () => {},
};
