import React from 'react';
import PropTypes from 'prop-types';
import { ModalCard, Icon } from '../../../shared';
import FormPreview from '../FormPreview';

require('./styles.scss');

export default function PublishFormModal(props) {
    const { open, onClose, form, onPublish } = props;
    const publish = () => {
        onPublish();
        onClose();
    };

    return (
        <ModalCard
            open={open}
            title={`Publish Form - ${form.name}`}
            onClose={onClose}
            primaryButtonOptions={{ content: 'Publish Form', onClick: publish }}
        >
            <div className="PublishModal">
                <Icon icon="exclamation-triangle" size="75px" className="PublishModal__warningIcon" />
                <div className="PublishModal__warningTitle">Ready to publish?</div>
                <div className="PublishModal__warningSubtitle">
                    Once your form is live, you <b>can no longer edit it.</b>
                </div>
                <div className="PublishModal__preview">
                    <hr />
                    <FormPreview form={form} title="Form Preview" />
                </div>
            </div>
        </ModalCard>
    );
}

PublishFormModal.propTypes = {
    form: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
    }),
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
};

PublishFormModal.defaultProps = {
    form: {},
    open: false,
};
