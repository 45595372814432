import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { ModelModel, TriggerModel } from '../_models';
import { Button, Form, Input, Loader, TextButton } from '../../shared';
import PackageFunctionSelect from '../_components/PackageFunctionSelect';
import CreateScheduledModal from '../_components/CreateScheduledModal';
import EditScheduledModal from '../_components/EditScheduledModal';

require('./styles.scss');

const ModelInfo = (props) => {
    const { model, openUpdateSource, canEditModels } = props;
    const [createScheduledRuns, setCreateScheduledRuns] = useState(false);
    const [editScheduledRun, setEditScheduledRun] = useState(false);
    const [currentTrigger, setCurrentTrigger] = useState({});
    const packages = useSelector((state) => state.packages);
    const algorithms = useSelector((state) => state.algorithms);
    const datasets = useSelector((state) => state.datasets);
    const triggers = useSelector((state) => state.triggers);
    const modelTriggers =
        triggers.hydrated && Object.values(triggers.byId).filter((trigger) => trigger.modelId === model._id);

    const { projectId } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(TriggerModel.index());
    }, [dispatch]);

    const algorithmId = model?.algorithmId;
    const algorithm = algorithms.byId[algorithmId];
    const packageId = algorithm?.packageId;
    const moduleId = algorithm?.moduleId;

    if (!model?.inputs) {
        return (
            <div className="ModelInfo">
                <Loader />
            </div>
        );
    }

    return (
        <div className="ModelInfo">
            <Form
                onSubmit={(form) =>
                    dispatch(ModelModel.update(model._id, { name: form.name, description: form.description }))
                }
                className="ModelInfo__inputs"
            >
                <Input
                    id="name"
                    type="text"
                    label="Model Name"
                    value={model.name}
                    gray
                    submitOnBlur
                    disabled={!canEditModels}
                    required
                />
                <Input
                    id="description"
                    type="text"
                    label="Description"
                    value={model.description || ''}
                    gray
                    submitOnBlur
                    disabled={!canEditModels}
                />
            </Form>
            <hr />
            <div className="ModelInfo__title">
                Source Function:
                {canEditModels && (
                    <Button gray small onClick={openUpdateSource}>
                        Update Source
                    </Button>
                )}
            </div>
            <PackageFunctionSelect
                metadata={packageId ? { packageId, moduleId, algorithmId } : {}}
                packages={packages}
                algorithms={algorithms}
                disabled
            />
            <hr />
            <div className="ModelInfo__scheduledRuns">
                <div className="ModelInfo__scheduledRunsHeader">
                    Scheduled Runs:
                    {canEditModels && (
                        <Button
                            className="scheduledButton"
                            gray
                            small
                            onClick={() => {
                                setCreateScheduledRuns(!createScheduledRuns);
                            }}
                        >
                            Add
                        </Button>
                    )}
                    <CreateScheduledModal
                        open={createScheduledRuns}
                        model={model}
                        onClose={() => {
                            setCreateScheduledRuns(false);
                        }}
                    />
                </div>
                {modelTriggers && modelTriggers.length > 0 ? (
                    <div className="ModelInfo_schedule">
                        <div className="ModelInfo__links">
                            {modelTriggers.map((trigger, index) => (
                                <React.Fragment key={`${trigger?._id}`}>
                                    <div className="ModelInfo__Schedule">
                                        <TextButton
                                            className="ModelInfo__link"
                                            onClick={() => {
                                                setCurrentTrigger(trigger);
                                                setEditScheduledRun(true);
                                            }}
                                        >
                                            {`Scheduled Run ${index + 1}`}
                                        </TextButton>
                                        {trigger.pattern.repeat.unit === 'never'
                                            ? `On ${moment(trigger.pattern.start.effective_startdate, 'MMM, Do').format(
                                                  'MMM, Do',
                                              )} at ${moment(
                                                  trigger.pattern.start.scheduled_starttime,
                                                  'h:mm a',
                                              ).format('h:mm a')}`
                                            : `Every ${trigger.pattern.repeat.value} ${trigger.pattern.repeat.unit}`}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="ModelInfo_noSchedule">
                        Nothing scheduled. Any scheduled runs you create will show here.
                    </div>
                )}
            </div>
            <EditScheduledModal
                open={editScheduledRun}
                model={model}
                currentTrigger={currentTrigger}
                onClose={() => {
                    setEditScheduledRun(false);
                    setCurrentTrigger({});
                }}
            />
            <div className="ModelInfo__title">Output Datasets:</div>
            <div className="ModelInfo__links">
                {Object.values(datasets.byId)
                    .filter((dataset) =>
                        model.outputs.map((outputData) => outputData.dataset._id).includes(dataset._id),
                    )
                    .map((dataset) => (
                        <Link
                            to={`/projects/${projectId}/datasets/${dataset._id}/data`}
                            key={dataset._id}
                            className="ModelInfo__link"
                        >
                            {dataset.name}
                        </Link>
                    ))}
            </div>
        </div>
    );
};

ModelInfo.propTypes = {
    model: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        algorithmId: PropTypes.string,
        inputs: PropTypes.array,
        outputs: PropTypes.array,
    }),
    openUpdateSource: PropTypes.func.isRequired,
    canEditModels: PropTypes.bool.isRequired,
};
ModelInfo.defaultProps = {
    model: null,
};

export default ModelInfo;
