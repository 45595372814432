import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginModel from '../../_models/login';
import logo from '../../../../assets/logo.svg';
import { Button, Card, Form, Input, Notification } from '../../../shared';

require('./styles.scss');

export default function LoginRecover() {
    const { emailSuccess, errors, loading } = useSelector((state) => state.login);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const onSubmit = (form) => dispatch(LoginModel.sendRecoveryEmail(form));
    useEffect(() => dispatch(LoginModel.removeErrors()), [dispatch]);

    const handleCancel = () => navigate(-1);

    return emailSuccess ? (
        <Card className="Login__Card">
            <img src={logo} alt="logo" />
            <div className="Login_Recover">
                <div className="Login__Title">Password Recovery</div>
                <Notification inline status="success" message="Please check your email for instructions" />
            </div>
            <footer className="Login__buttons">
                <Button gray className="Login__button--right" onClick={handleCancel}>
                    Go Back
                </Button>
            </footer>
        </Card>
    ) : (
        <Card className="Login__Card">
            <Form onSubmit={onSubmit}>
                <img src={logo} alt="logo" />
                <div className="Login_Recover">
                    <div className="Login__Title">Password Recovery</div>
                    <main>
                        <Notification inline status="info" message="Enter the email associated with your account" />
                        <Input id="email" label="Email" type="text" errors={errors.email} gray required />
                    </main>
                </div>
                <footer className="Login__buttons">
                    <Button gray onClick={handleCancel}>
                        Go Back
                    </Button>
                    <Button blue id="submit" className="Login__button--right" loading={loading}>
                        Send Reset
                    </Button>
                </footer>
            </Form>
        </Card>
    );
}

LoginRecover.propTypes = {};
LoginRecover.defaultProps = {};
