import React from 'react';
import PropTypes from 'prop-types';

require('./styles.scss');

export default function VerticalLine(props) {
    const { height } = props;
    return <div className="VerticalLine" style={{ height }} />;
}

VerticalLine.propTypes = {
    height: PropTypes.string,
};

VerticalLine.defaultProps = {
    height: '20px',
};
