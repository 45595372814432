import React from 'react';
import PropTypes from 'prop-types';
import UserSettings from '.';
import { ModalCard } from '../../../../shared';

export default function UserSettingsModal({ open, onClose, userId, organizationId }) {
    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title="User Settings"
            className="UserSettingsModal"
            primaryButtonOptions={{ content: 'Close', onClick: () => onClose() }}
        >
            <UserSettings userId={userId} organizationId={organizationId} />
        </ModalCard>
    );
}

UserSettingsModal.propTypes = {
    userId: PropTypes.string,
    organizationId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

UserSettingsModal.defaultProps = {
    userId: null,
    organizationId: null,
    open: false,
};
