import Fuse from 'fuse.js';

export function capitalize(str) {
    return str && str[0].toUpperCase() + str.slice(1);
}

export function removePeriods(str) {
    if (!str) return '';
    return str.toString().replace(/\./g, '');
}

function stringToRegex(searchString) {
    try {
        const re = new RegExp(searchString.slice(3).trim());
        return re;
    } catch (e) {
        return false;
    }
}

export function fuzzySearch(searchString, objects, keys = ['name']) {
    if (!searchString) return objects || [];

    // check to see if the input is a regular expression
    if (searchString.startsWith('re:')) {
        const searchRegex = stringToRegex(searchString);
        if (searchRegex) {
            const filteredObjects = objects.filter((object) =>
                keys?.map((key) => object[key]).some((value) => value?.match(searchRegex)),
            );
            return filteredObjects;
        }
    }
    const fuse = new Fuse(objects, { keys, threshold: 0.3, includeScore: true });
    const matchingItems = fuse.search(searchString);
    const bestMatchingItems = matchingItems.filter((item) => item.score - matchingItems[0]?.score < 0.1);
    return bestMatchingItems.map((result) => result.item);
}
