import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePermissionRole, validateRole } from '../../../../helpers/router/helpers';
import ErrorBoundary from '../../../shared/ErrorBoundary';
import { accessSettingsRoutes, getProjectRoutes } from './routes';
import { PlatformContext } from '../../../../helpers/layout/platformContext';
import { SubNavBarContext } from '../../../../helpers/layout/subNavBarContext';
import OrganizationLogo from '../../../Access/_components/OrganizationLogo';
import NavbarSkin from './skin';
import ProjectMenu from '../../../Projects/_components/ProjectMenu';
import { t } from '../../../../utils/translate'

require('./styles.scss');

export default function Navbar({ permissionRoles, user, location }) {
    const navigate = useNavigate();
    const platform = useContext(PlatformContext);
    const { hasSubNav } = useContext(SubNavBarContext);

    const { projectId, clientId, organizationId } = useParams();
    const project = useSelector((state) => state.projects.byId[projectId]);

    const navItems = getProjectRoutes(project?._id)
        .filter((route) => validatePermissionRole(route.permissionRole, permissionRoles))
        .map(({ label, path, icon }) => ({
            label,
            icon,
            active: location.pathname.indexOf(path) === 0,
            onClick: () => navigate(path),
        }));

    const menuItems = accessSettingsRoutes
        .filter((route) => validateRole(route.role, user?.roles))
        .map(({ label, path, icon }) => ({
            label,
            icon,
            active: location.pathname.indexOf(path) === 0,
            onClick: () => navigate(path),
        }));

    const [projectMenuOpen, setProjectMenuOpen] = useState(false);
    let projectChip = null;
    // navItems.length prevents things jumping while loading navItems
    if (project && navItems.length) {
        projectChip = (
            <div
                className={classNames('Navbar__project', { 'Navbar__project--active': projectMenuOpen })}
                role="button"
                tabIndex={0}
                onClick={() => setProjectMenuOpen(true)}
            >
                <OrganizationLogo id={project?.organizationId} small />
                {project.name}
                <ProjectMenu project={project} open={projectMenuOpen} onClickOut={() => setProjectMenuOpen(false)} />
            </div>
        );
    }
    const title =
        projectChip ||
        ((organizationId || clientId || location.pathname.includes('organizations/my')) && 'Organization Settings') ||
        (location.pathname === '/projects' &&     (user?.name?.first ? `${t('Welcome')}, ${user.name.first}` : t('Welcome'))) ||
        (location.pathname.includes('users/my') && 'User Settings') ||
        (location.pathname.includes('clients') && 'Client Organizations') ||
        (location.pathname.includes('root') && 'White Whale Administration');

    return (
        <ErrorBoundary>
            <NavbarSkin
                homeItem={{ onClick: () => navigate('/projects') }}
                navItems={navItems}
                menuItems={menuItems}
                platform={platform}
                hasSubNav={hasSubNav}
                user={user}
                title={title}
            />
        </ErrorBoundary>
    );
}

Navbar.propTypes = {
    permissionRoles: PropTypes.shape({}),
    user: PropTypes.shape({ roles: PropTypes.shape({}), name: PropTypes.shape({ first: PropTypes.string }) }),
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

Navbar.defaultProps = {
    permissionRoles: null,
    user: null,
};
