import React from 'react';
import { useRoutes } from 'react-router-dom';

import routes from './routes';
import ErrorBoundary from '../shared/ErrorBoundary';

const Dashboards = () => {
    const dashboardRoutes = useRoutes(routes);
    return <ErrorBoundary>{dashboardRoutes}</ErrorBoundary>;
};

Dashboards.propTypes = {};
Dashboards.defaultProps = {};

export default Dashboards;
