import React from 'react';
import PropTypes from 'prop-types';

import { Input, ModalCard } from '../../../shared';

export default function EditFormModal(props) {
    const { open, onClose, form, onSubmit } = props;
    return (
        <div>
            <ModalCard
                open={open}
                onClose={onClose}
                title="Edit Form"
                formOptions={{ onSubmit }}
                primaryButtonOptions={{
                    content: 'Update',
                    id: 'submit',
                }}
            >
                <div className="EditFormModal">
                    <Input id="name" type="text" label="Name" value={form && form.name} gray required />
                    <Input id="description" type="textarea" label="Description" value={form && form.description} gray />
                </div>
            </ModalCard>
        </div>
    );
}

EditFormModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    }),
};

EditFormModal.defaultProps = {
    form: {},
};
