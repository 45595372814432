import React from 'react';
import PropTypes from 'prop-types';
import Alea from 'alea';
import chroma from 'chroma-js';
import ClassNames from 'classnames';
import { Logo, ToolTip } from '../../../../shared';

require('./styles.scss');

const userColors = chroma
    .scale(['#a0cc00', '#00B47E', '#0062b3', '#000280', '#7700b3', '#b30009', '#e66700', '#e6c700'])
    .mode('lab');

export default function UserIcon(props) {
    const { firstName, lastName, userId, user, onClick, children, large, hasTooltip } = props;
    const firstInitial = firstName[0];
    const scaleIndex = new Alea(userId + firstName[0])();
    const color = userColors(scaleIndex).hex();

    const icon = user?.logo ? (
        <div
            className={ClassNames('UserIcon--logo', { 'UserIconLogo--large': large })}
            onClick={onClick}
            role="button"
            tabIndex={0}
        >
            {children}
            <Logo logo={user?.logo} />
        </div>
    ) : (
        <div
            className={ClassNames('UserIcon', { 'UserIcon--large': large })}
            style={{ backgroundColor: color }}
            onClick={onClick}
            role="button"
            tabIndex={0}
        >
            <div className="UserIcon__text">{firstInitial}</div>
            {children}
        </div>
    );
    return hasTooltip ? (
        <ToolTip tooltip={`${firstName} ${lastName}`} delayShow={300}>
            {icon}
        </ToolTip>
    ) : (
        icon
    );
}

UserIcon.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    user: PropTypes.shape({
        logo: PropTypes.string,
    }),
    userId: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    large: PropTypes.bool,
    hasTooltip: PropTypes.bool,
};

UserIcon.defaultProps = {
    firstName: '',
    lastName: '',
    userId: '',
    user: null,
    onClick: () => {},
    children: null,
    large: false,
    hasTooltip: true,
};
