import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SerumOperationButton from '../SerumButton/operation';
import FeatureBasket from '.';
import ChipColorPicker from '../../Chart/Mapping/components/color';

export default function FeatureBasketWithOperation({
    features,
    datasets,
    showSourceNames,
    operationId,
    onChange,
    dndProps,
}) {
    const { serum, containerIndex } = dndProps;
    const feature = features.byId[serum.feature?._id];
    const dataset = datasets.byId[feature?.dataset._id];
    const datasetName = showSourceNames && dataset?.name;
    const [highColor, setHighColor] = useState('#bf444c');
    const [lowColor, setLowColor] = useState('#f6efa6');

    const updateGradientColor = (name, color) => {
        onChange('gradients', { name, color });
        if (name === 'High') setHighColor(color);
        if (name === 'Low') setLowColor(color);
    };

    return (
        <FeatureBasket {...dndProps} className="FeatureBasketOperation" datasetName={datasetName} feature={feature}>
            <div className="FeatureBasketOperation__operations">
                <SerumOperationButton
                    id={`${operationId}-${containerIndex}`}
                    value={serum.operation}
                    onChange={onChange}
                    feature={feature}
                    binValues={{ id: `${operationId}NumberOfBins-${containerIndex}`, value: serum.numberOfBins }}
                />
            </div>
            {operationId === 'gradientOperation' && (
                <div className="gradientColours">
                    <ChipColorPicker
                        setColor={(updatedColor) => updateGradientColor('High', updatedColor)}
                        color={highColor}
                        changed={highColor !== '#bf444c'}
                    />
                    <div>High</div>
                    <ChipColorPicker
                        setColor={(updatedColor) => updateGradientColor('Low', updatedColor)}
                        color={lowColor}
                        changed={lowColor !== '#f6efa6'}
                    />
                    <div>Low</div>
                </div>
            )}
        </FeatureBasket>
    );
}

FeatureBasketWithOperation.propTypes = {
    onChange: PropTypes.func.isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        loading: PropTypes.bool,
    }).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    showSourceNames: PropTypes.bool,
    operationId: PropTypes.string,
    dndProps: PropTypes.shape({
        serum: PropTypes.shape({
            id: PropTypes.string,
            feature: PropTypes.shape({ _id: PropTypes.string }),
            operation: PropTypes.string,
            numberOfBins: PropTypes.number,
        }),
        containerIndex: PropTypes.number,
    }).isRequired,
};

FeatureBasketWithOperation.defaultProps = {
    operationId: '',
    showSourceNames: false,
};
