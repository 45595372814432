import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import moment from 'moment';
import { Icon } from '../../../../shared';
import ModelRunError from '../../RunError';

const statuses = {
    queued: { icon: 'brain', label: 'Queued' },
    failed: { icon: 'exclamation-triangle', label: 'Failed' },
    started: { icon: 'model-run', label: 'Running' },
    finished: { icon: 'checkmark', label: 'Finished' },
    cancelled: { icon: 'times', label: 'Cancelled' },
    expired: { icon: 'clock', label: 'Expired' },
};

export default function RunInfoTimeline(props) {
    const { modelRun } = props;
    const { status } = modelRun;
    const statusConfig = statuses[status] || {};
    const lines = modelRun.error?.split('\n') || [];
    if (lines.length > 0) lines.splice(1, 2); // This is a bit hacky and should maybe be done on the backend
    return (
        <div className={ClassNames('RunInfoTimeline__item', { 'RunInfoTimeline__item--failed': status === 'failed' })}>
            <Icon icon={statusConfig.icon} size="20px" />
            <div className="RunInfoTimeline__text">
                <div className="RunInfoTimeline__itemTitle">{statusConfig.label}</div>
                {moment.utc(modelRun.executionDatetime).local().format('lll')}
                {status === 'failed' && (
                    <div className="RunInfoTimeline__failedMessage">
                        <ModelRunError modelRun={modelRun} />
                    </div>
                )}
            </div>
        </div>
    );
}

RunInfoTimeline.propTypes = {
    modelRun: PropTypes.shape({
        status: PropTypes.string,
        message: PropTypes.string,
        error: PropTypes.string,
        executionDatetime: PropTypes.string,
    }),
};
RunInfoTimeline.defaultProps = {
    modelRun: {},
};
