import React from 'react';
import ModelsList from './List';
import ModelOnboardWorkflow from './Onboard';
import ModelDetailsPage from './Display';
import { ProjectProtectedRoute } from '../../helpers/router';

export default [
    {
        path: ':modelId/onboard',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ model: 'edit' }}>
                <ModelOnboardWorkflow />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: '/onboard',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ model: 'edit' }}>
                <ModelOnboardWorkflow />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: ':modelId',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ model: 'view' }}>
                <ModelDetailsPage />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: '',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ package: 'view' }}>
                <ModelsList />
            </ProjectProtectedRoute>
        ),
    },
];
