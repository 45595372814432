import React from 'react';
import PropTypes from 'prop-types';

import FilterMenuFeature from './feature';
import { Icon, ListItem } from '../../../shared';

// a dataset item in the filter context menu
export default function FilterMenuDataset(props) {
    const { dataset, features, value, onChange, onSubmit, onSelect, open, onFeatureSelect } = props;
    const datasetFeatures = Object.values(features.byId).filter(
        (feature) => feature.dataset._id === dataset._id && !feature.hidden,
    );
    return (
        <div className="FilterMenuDataset">
            <ListItem
                id={dataset._id}
                name={dataset.name}
                active={open}
                decorator={open || <Icon icon="angle-down" size="20px" />}
                onClick={onSelect}
                dark
            />
            {open && (
                <FilterMenuFeature
                    features={datasetFeatures}
                    value={value}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onFeatureSelect={onFeatureSelect}
                />
            )}
        </div>
    );
}

FilterMenuDataset.propTypes = {
    value: PropTypes.shape({
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
    }),
    dataset: PropTypes.shape({
        _id: PropTypes.string,
        features: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
    }).isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onSelect: PropTypes.func,
    onFeatureSelect: PropTypes.func,
};
FilterMenuDataset.defaultProps = {
    value: null,
    open: false,
    onChange: () => {},
    onSubmit: () => {},
    onSelect: () => {},
    onFeatureSelect: null,
};
