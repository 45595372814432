import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Chip, ContextMenu, ContextSubMenu, ContextMenuItem, Icon, Input } from '../../../shared';
import { getOperationsFromFeature } from '../../serum';
import { operationsConfig } from '../../serum/constants';

require('./styles.scss');

export default function SerumOperationButton({ id, value, onChange, feature, aggregation, binValues }) {
    const { id: binId, value: binValue } = binValues;
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    const [binsOpen, setBinsOpen] = useState(false);
    const toggleBinsOpen = () => setBinsOpen(!binsOpen);

    const hasOperation = value && value !== 'none';
    const validOperations = getOperationsFromFeature(feature, { aggregation });

    return (
        <React.Fragment>
            <Chip
                onClick={toggleOpen}
                active={open}
                disabled={!validOperations}
                highlighted={!!hasOperation}
                red={!!(feature && value && !validOperations.some((op) => op.value === value))}
                tooltip="Operation"
            >
                {!hasOperation && <Icon icon="math" size="12px" />}
                <div className={!hasOperation ? 'Chip__iconText' : undefined}>
                    {operationsConfig[value]?.shortName || 'None'}
                </div>
                <ContextMenu open={open} onClickOut={toggleOpen} className="SerumOperationButton__menu">
                    <ContextSubMenu>
                        {validOperations.map((item) => (
                            <ContextMenuItem
                                key={item.value}
                                id={item.value}
                                name={item.label}
                                onClick={() => onChange(id, item.value || 'none')}
                            />
                        ))}
                    </ContextSubMenu>
                </ContextMenu>
            </Chip>
            {value === 'bin' && binValues && (
                <Chip onClick={toggleBinsOpen} active={binsOpen} highlighted tooltip="Number of bins">
                    {`Bins (${binValue || 10})`}
                    <ContextMenu open={binsOpen} onClickOut={toggleBinsOpen} className="SerumOperationButton__menu">
                        <ContextSubMenu>
                            <Input
                                type="number"
                                className="SerumOperationButton__binInput"
                                sm
                                dark
                                id={binId}
                                label="Number of bins"
                                value={binValue || binValue === 0 || binValue === '' ? binValue : 10}
                                onChange={onChange}
                                min={2}
                                max={100}
                                step={2}
                            />
                        </ContextSubMenu>
                    </ContextMenu>
                </Chip>
            )}
        </React.Fragment>
    );
}

SerumOperationButton.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    feature: PropTypes.shape({ type: PropTypes.string }),
    aggregation: PropTypes.bool,
    binValues: PropTypes.shape({ id: PropTypes.string, value: PropTypes.number }),
};

SerumOperationButton.defaultProps = {
    value: null,
    feature: null,
    aggregation: true,
    binValues: {},
};
