import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from '../shared';
import ErrorBoundary from '../shared/ErrorBoundary';

require('./styles.scss');

const AccessSettings = () => (
    <div className="AccessSettings">
        <Card>
            <ErrorBoundary>
                <Outlet />
            </ErrorBoundary>
        </Card>
    </div>
);

export default AccessSettings;
