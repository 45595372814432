import React from 'react';
import PropTypes from 'prop-types';

import { Input, Button, CollapsibleSection, ScrollArea } from '../../../shared';
import SidePanelButton from '../../../shared/SidePanel/SidePanelButton';

require('./styles.scss');

const SettingsMapping = (props) => {
    const { onChange, toggleExpanded, settings, buttons } = props;

    return (
        <div className="SettingsMapping">
            <SidePanelButton onClick={toggleExpanded} icon="angle-right" />
            <div className="SettingsMapping__header">Settings</div>
            <ScrollArea className="SettingsMapping__content" scrollX={false}>
                {settings.map((settingsSection) => (
                    <CollapsibleSection
                        line
                        key={settingsSection.key || settingsSection.name}
                        title={settingsSection.name}
                        expanded
                    >
                        {(settingsSection.inputs || []).map((input) => (
                            <Input key={input.id} onChange={onChange} inlineLabel sm {...input} />
                        ))}
                    </CollapsibleSection>
                ))}
                {buttons && buttons.length > 0 && (
                    <div className="SettingsMapping__buttons">
                        {buttons.map((button) => (
                            <Button key={button.key} gray small onClick={button.onClick}>
                                {button.content}
                            </Button>
                        ))}
                    </div>
                )}
            </ScrollArea>
        </div>
    );
};

SettingsMapping.propTypes = {
    onChange: PropTypes.func,
    toggleExpanded: PropTypes.func,
    settings: PropTypes.array,
    buttons: PropTypes.array,
};
SettingsMapping.defaultProps = {
    onChange: () => {},
    toggleExpanded: () => {},
    settings: [],
    buttons: [],
};

export default SettingsMapping;
