import ApiBase from '../../../../models/base/api';

export class DashboardsApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('dashboards', addProjectIdToSearchParams);
    }
    addTab = (id, obj) => this.request('POST', `${this.endpoint}/${id}/tabs`, obj);
    duplicateTab = (id, tabId) => this.request('POST', `${this.endpoint}/${id}/tabs/${tabId}/duplicate`);
    deleteTab = (id, tabId) => this.request('DELETE', `${this.endpoint}/${id}/tabs/${tabId}`);
    duplicate = (id) => this.request('POST', `${this.endpoint}/${id}/duplicate`);
    getPreview = (id) => this.request('GET', `${this.endpoint}/${id}/preview`);
}

export default new DashboardsApi();
