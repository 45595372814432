import { getMainChartType } from '../constants';
import { _getPieSettings, _getRadarSettings, _getXYSettings, _getSeriesSettings } from './helpers';

export function getChartSettings(vis, chartProps) {
    const mainChartType = getMainChartType(vis);
    let baseSettings = {};
    if (mainChartType === 'pie') {
        baseSettings = _getPieSettings(vis, chartProps);
    } else if (mainChartType === 'radar') {
        baseSettings = _getRadarSettings(vis);
    } else {
        baseSettings = _getXYSettings(vis, chartProps);
    }
    const setUndefinedInputValues = (section) => ({
        ...section,
        inputs: section.inputs.map((input) => ({ value: chartProps[input.id], ...input })),
    });

    return [...baseSettings, ..._getSeriesSettings(vis, chartProps)].map((section) => setUndefinedInputValues(section));
}
