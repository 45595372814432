import React from 'react';
import PropTypes from 'prop-types';
import FormDisplay from '../../Display';

require('./styles.scss');

export default function FormPreview(props) {
    const { form, title } = props;

    return (
        <React.Fragment>
            {title && <div className="FormPreview__title">{title}</div>}
            <div className="FormPreview__description">This is how your form will look to a respondent.</div>
            <div className="FormPreview__content">
                <FormDisplay form={form} />
            </div>
        </React.Fragment>
    );
}

FormPreview.propTypes = {
    form: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
    }),
    title: PropTypes.string,
};

FormPreview.defaultProps = {
    form: {},
    title: null,
};
