// These helper functions are common logic used in the datasets table and the table unit

export const updateFeatureFilterArray = (filterArray, featureId, filter) => {
    const updatedFilters = [...filterArray];
    const filterIndex = updatedFilters.length ? updatedFilters.findIndex((f) => f.feature.id === featureId) : null;
    if (!filter && filterIndex >= 0) {
        updatedFilters.splice(filterIndex, 1); // delete a filter
    } else {
        updatedFilters.splice(filterIndex, filterIndex >= 0 ? 1 : 0, filter); // add or change a filter
    }
    return updatedFilters;
};

export const determineRequestPage = (page, data) => {
    // checking upper and lower bounds of the page : 1 to total
    if (page < 1) return 1;
    if (data && data.pages && data.pages.total < page) {
        return data.pages.total;
    }
    return page;
};
