import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PlatformProvider } from './platformContext';
import { SubNavBarContext, SubNavBarProvider } from './subNavBarContext';

const LayoutContextProvider = ({ children }) => (
    <PlatformProvider>
        <SubNavBarProvider>{children}</SubNavBarProvider>
    </PlatformProvider>
);

LayoutContextProvider.propTypes = {
    children: PropTypes.node,
};
LayoutContextProvider.defaultProps = {
    children: PropTypes.node,
};

const PageWithSubNav = ({ children }) => {
    const { setHasSubNav } = useContext(SubNavBarContext);

    useEffect(() => {
        setHasSubNav(true);

        return function cleanup() {
            setHasSubNav(false);
        };
        // only run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
};

PageWithSubNav.propTypes = {
    children: PropTypes.node,
};
PageWithSubNav.defaultProps = {
    children: PropTypes.node,
};

export { LayoutContextProvider, PageWithSubNav };
