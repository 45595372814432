/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';

import AppSelectors from '../../Access/_models/app/selectors';
import TabSelectors from '../_models/tabs/selectors';
import { DashboardModel } from '../_models';

import ModelPanel from '../../Models/_components/ModelPanel';
import Header from './Header';
import Tab from './Tab';
import TabBar from './TabBar';
import Empty from './empty';

require('./styles.scss');

let memoizedValue = [];
const memoize = (modelIds) => {
    // prevent model panel from rerendering too many times
    // if both arrays contain the same values (in whatever order):
    if (lodash.isEmpty(lodash.xor(memoizedValue, modelIds))) return memoizedValue;
    memoizedValue = modelIds;
    return modelIds;
};

export default function Dashboard({ isPublic }) {
    const { projectId, dashboardId, tabId } = useParams();
    const dashboards = useSelector((state) => state.dashboards);
    // we will redirect to the List page if the dashboard doesn't exist, and we're not on the public page:
    //   -- (eg. somebody else deleted the dashboard while you were looking at it)
    const dashboard = dashboards.byId[dashboardId];
    const dashboardsHydrated = dashboards.hydrated;
    const redirectToDashboardList = dashboardsHydrated && !dashboard && !isPublic;

    const tabModels = useSelector((state) => memoize(TabSelectors.getModelIds(tabId)(state)) || []);
    const canRunModels = useSelector((state) => AppSelectors.canRunModels()(state));
    const canEditModels = useSelector((state) => AppSelectors.canEditModels()(state));
    const organizationId = useSelector((state) => state?.projects?.byId[projectId]?.organizationId);
    const publicSharing = useSelector((state) => state?.organizations?.byId[organizationId]?.publicSharing);

    const [publicFilters, setPublicFilters] = useState({}); // is a map of tabIds with lists of filters - this is so filters can persist on each tab during a session
    const [modelPanelId, setModelPanelId] = useState('');
    const [modelBarTop, setModelBarTop] = useState(0);

    const dispatch = useDispatch();
    const showDashboard = (id) => id && dispatch(DashboardModel.show(id));

    const navigate = useNavigate();

    useEffect(() => {
        showDashboard(dashboardId).then(({ dashboard: returnedDashboard }) => {
            if (!tabId) {
                if (!returnedDashboard) return;

                if (returnedDashboard.tabs[0] && returnedDashboard.tabs[0]._id) {
                    if (isPublic) {
                        navigate(`/public/dashboards/${dashboardId}/tabs/${returnedDashboard.tabs[0]._id}`);
                    } else {
                        navigate(
                            `/projects/${projectId}/dashboards/${dashboardId}/tabs/${returnedDashboard.tabs[0]._id}`,
                        );
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        if (redirectToDashboardList) {
            // redirect if the dashboard doesn't exist and we're not on the public page
            navigate(`/projects/${projectId}/dashboards`);
        }
    }, [redirectToDashboardList]);

    const getLocationURL = (dId, tId) => {
        // Similar logic in componentDidMount
        const publicURL = isPublic ? '/public' : `/projects/${projectId}`;
        const baseRoute = `${publicURL}/dashboards`;
        if (!dId) return baseRoute;
        if (!tId) return `${baseRoute}/${dId}`;
        return `${baseRoute}/${dId}/tabs/${tId}`;
    };

    const navigateToDashboard = (dId, tId) => {
        navigate(getLocationURL(dId, tId));
    };

    const handleMeasureModelBar = (contentRect) => {
        if (contentRect.bounds.top !== modelBarTop) {
            setModelBarTop(contentRect.bounds.top);
        }
    };

    if (redirectToDashboardList) return null;

    const closeButtonStyle = modelPanelId &&
        tabModels && {
            top: modelBarTop + 4 + 43 * tabModels.findIndex((modelId) => modelId === modelPanelId),
        };

    return (
        <div className="Dashboard">
            <div className="Dashboard__body">
                <Header
                    tabId={tabId}
                    dashboardId={dashboardId}
                    projectId={projectId}
                    id={tabId}
                    isPublic={isPublic}
                    publicFilters={publicFilters[tabId] || []}
                    setPublicFilters={(newPublicFilters) =>
                        setPublicFilters({ ...publicFilters, [tabId]: [...newPublicFilters] })
                    }
                    canRunModels={canRunModels}
                    modelPanelId={modelPanelId}
                    setModelPanelId={setModelPanelId}
                    handleMeasureModelBar={handleMeasureModelBar}
                    publicSharing={publicSharing}
                />
                {tabId ? (
                    <Tab dashboardId={dashboardId} id={tabId} publicFilters={publicFilters[tabId] || []} />
                ) : (
                    <Empty dashboardId={dashboardId} navigateToDashboard={navigateToDashboard} />
                )}
            </div>
            {canRunModels && modelPanelId && tabModels && tabModels.includes(modelPanelId) && (
                <ModelPanel
                    modelId={modelPanelId}
                    closeModelPanel={() => setModelPanelId('')}
                    noEditLink={!canEditModels}
                    closeButtonStyle={closeButtonStyle}
                />
            )}
            <TabBar dashboardId={dashboardId} tabId={tabId} navigateToDashboard={navigateToDashboard} />
        </div>
    );
}

Dashboard.propTypes = {
    isPublic: PropTypes.bool,
};

Dashboard.defaultProps = {
    isPublic: false,
};
