import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModelBar from '../../_components/ModelBar';
import ModelPanel from '../../_components/ModelPanel';
import { Notification } from '../../../shared';

const DashboardPreview = (props) => {
    const { model } = props;

    const [modelPanelOpen, setModelPanelOpen] = useState(false);
    const [notification, setNotification] = useState(false);
    const runModel = () => {
        setNotification(true);
        setTimeout(() => setNotification(false), 2500);
    };

    return (
        <div className="DashboardPreview">
            <div className="DashboardPreview__main">
                <ModelBar
                    hidden={modelPanelOpen}
                    key={model._id}
                    modelId={model._id}
                    runModel={runModel}
                    setModelPanelId={() => setModelPanelOpen(true)}
                    showModelPanelButton={!modelPanelOpen}
                />
                <div className="DashboardPreview__cards">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            {modelPanelOpen && (
                <ModelPanel
                    closeButtonStyle={{ top: 25 }}
                    closeModelPanel={() => setModelPanelOpen(false)}
                    modelId={model._id}
                    noEditLink
                    runModel={runModel}
                />
            )}
            {notification && <Notification message="Model cannot be run in the UI Preview" inline />}
        </div>
    );
};

DashboardPreview.propTypes = {
    model: PropTypes.shape({
        _id: PropTypes.string,
        runButtonText: PropTypes.string,
    }).isRequired,
};
DashboardPreview.defaultProps = {};

export default DashboardPreview;
