import chroma from 'chroma-js';

// The idea of our color algorithm currently is to choose random colors from off a gradient, based on the serum id & group name.
// Please take a look at the story to see what each gradient looks like.

// These color scheme gradients were made with 3 main things in mind:
// 1. enough contrast/variation that the colors will be distinguishable by the human eye when chosen randomly
// 2. all the colors along the gradient we've defined look nice in general, and together
// 3. the contrast between the colors is colorblind-friendly (in the simplest terms, this can be accomplished by varying the lightness in addition to the hue)

// resources used in designing them were:
// color brewer website, which has very good, well-researched color sets for charting/maps: https://colorbrewer2.org/
// ^ most of our themes are taken directly from color brewer or chroma, except 5 that I (Sarah) made myself.

// article I originally based things off of: https://www.vis4.net/blog/2013/09/mastering-multi-hued-color-scales/
// gradient generator that is shown in that article, which I used to pick the colors: https://gka.github.io/palettes
// my process with the gradient generator was to choose a subsection of the gradient I wanted to make and choose colors from that, then move on to the next (overlapping) subsection
// ^ this is because the gradient generator can only take ~5 colors as input before it crashes
// chroma.js library docs: https://gka.github.io/chroma.js

// ------------------ color schemes -----------------

// ------------------ rainbow spectra -----------------

// loosely based on the original 2 themes that Trent or Alex made way before we had a good algorithm for our colors
const spectrumOne = chroma
    .scale([
        '#a60d6b',
        '#f45b5b',
        '#f7a35c',
        '#f2ea7c',
        '#a5d93e',
        '#004500',
        '#25b9cc',
        '#beeaf3',
        '#2556bd',
        '#a0a2ff',
    ])
    .mode('lab');
const spectrumTwo = chroma
    .scale([
        '#f41e36',
        '#f78da7',
        '#ed6c16',
        '#f8c900',
        '#a2fe96',
        '#43965f',
        '#13dec0',
        '#006076',
        '#6957c0',
        '#ca8cf5',
    ])
    .mode('lab');

// from colorBrewer
const spectral = chroma.scale('Spectral');

// canonical rainbow colors (at a brightness level appropriate for DeepSea's background)
const rainbowSpectrum = chroma
    .scale([
        '#f07585',
        '#e61919',
        '#e68019',
        '#e6e619',
        '#5dc322',
        '#178223',
        '#8cedf2',
        '#2626d9',
        '#480d73',
        '#861fb2',
    ])
    .mode('lab');

// ------------------ WW brand colors -----------------

const whiteWhaleColors = chroma
    .scale(['#ce861c', '#F7B464', '#f4ea8a', '#3ED8AF', '#c0f5ec', '#64C3F2', '#394665', '#8B8AD0', '#d4d1ff'])
    .mode('lab');

const bioLumen = chroma
    .scale(['#d27f14', '#F0AF5C', '#f4ea8a', '#95eac8', '#52DDA5', '#26c082', '#1569b7', '#86BEF1', '#b1d5f6'])
    .mode('lab');

// ------------------ subsets of colors -----------------

// ------------------ diverging colors -----------------

// colorBrewer scales from chroma
const redYellowBlue = chroma.scale('RdYlBu');
const redYellowGreen = chroma.scale('RdYlGn');

// from color brewer with white removed
const redGray = chroma.scale([
    '#b2182b',
    '#cd5245',
    '#e48066',
    '#f5ad91',
    '#fddbc7',
    '#b9b9b9',
    '#939393',
    '#6f6f6f',
    '#4d4d4d',
]);
const brownTeal = chroma.scale([
    '#8c510a',
    '#a97530',
    '#c59a57',
    '#e0c087',
    '#f6e8c3',
    '#8fcac3',
    '#5fa8a1',
    '#34877f',
    '#01665e',
]);
const purpleGreen = chroma.scale([
    '#762a83',
    '#90569e',
    '#ab80b8',
    '#c8aad1',
    '#e7d4e8',
    '#a7d3a2',
    '#79b579',
    '#4c9656',
    '#1b7837',
]);

// ------------------ continuous colors -----------------

// combinations of multiple color brewer scales
const yellowBlue = chroma
    .scale(['#f8efd3', '#dfd620', '#27a58c', '#286ebd', '#1a237b'])
    .correctLightness()
    .domain([1, 0]);
const redOrange = chroma
    .scale(['#fffee6', '#fee634', '#f39b58', '#e4521b', '#b72b15', '#462620'])
    .correctLightness()
    .domain([1, 0]);
const yellowPurple = chroma
    .scale(['#fff7e5', '#e6bf00', '#e85285', '#6a1b9a', '#330f57'])
    .correctLightness()
    .domain([1, 0]);

// colorBrewer scales from chroma
const greenBlue = chroma.scale('GnBu').padding([0.1, 0]).domain([1, 0]);
const redPurple = chroma.scale('RdPu').padding([0.1, 0]).domain([1, 0]);
const cubeHelix = chroma.cubehelix().scale().padding(0.1);

// ------------------ single color gradients -----------------

const blue = chroma.scale('Blues').padding([0.2, 0]).domain([1, 0]);
const green = chroma.scale('Greens').padding([0.2, 0]).domain([1, 0]);
const orange = chroma.scale('Oranges').padding([0.2, 0]).domain([1, 0]);
const purple = chroma.scale('Purples').padding([0.2, 0]).domain([1, 0]);
const red = chroma.scale('Reds').padding([0.2, 0]).domain([1, 0]);

export const colorSchemes = {
    spectrumOne,
    spectrumTwo,
    spectral,
    rainbowSpectrum,
    whiteWhaleColors,
    bioLumen,
    redYellowBlue,
    redYellowGreen,
    redGray,
    brownTeal,
    purpleGreen,
    yellowBlue,
    greenBlue,
    redOrange,
    yellowPurple,
    redPurple,
    cubeHelix,
    red,
    orange,
    green,
    blue,
    purple,
};

export const getColorFromColorScheme = (colorScheme, scaleIndex) => {
    const scheme = colorScheme || 'spectrumOne';
    return colorSchemes[scheme](scaleIndex).hex();
};
