import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useDrop } from 'react-dnd';

import IconButton from '../IconButton';
import DragAndDropListItem from './DragAndDropListItem';

require('./styles.scss');

function DragAndDropList(props) {
    const {
        id,
        onDrop,
        title,
        items,
        value,
        onClick,
        placeholderText,
        type,
        decorations,
        collapsible,
        expanded: propsExpanded,
    } = props;

    const [expanded, setExpanded] = useState(propsExpanded);
    const toggleExpanded = () => setExpanded(!expanded);

    // add drag and drop interaction
    const [{ isOver }, drop] = useDrop({
        accept: 'dragAndDropListItem',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (item) => {
            onDrop(id, item.id, item.containerId);
        },
    });

    return (
        <div
            className={ClassNames('DragAndDropList', {
                'DragAndDropList--expanded': expanded,
                'DragAndDropList--isOver': isOver,
                'DragAndDropList--collapsible': collapsible,
            })}
            ref={drop}
        >
            {title && (
                <div className="DragAndDropList__display" onClick={toggleExpanded} role="button" tabIndex={0}>
                    {collapsible && (
                        <IconButton
                            icon={`angle-${expanded ? 'up' : 'down'}`}
                            className="DragAndDropList__collapsibleIcon"
                            mini
                            onClick={toggleExpanded}
                        />
                    )}
                    <span className="DragAndDropList__type">{type}</span>
                    <span className="DragAndDropList__title">{title}</span>
                    <span className="DragAndDropList__buttons">{decorations}</span>
                </div>
            )}
            {items.map((item) => (
                <DragAndDropListItem
                    {...item}
                    key={item.id || item.label}
                    id={item.id || item.label}
                    primary={value === item.id || value === item.label}
                    onClick={onClick}
                    containerId={id}
                />
            ))}
            {expanded && items.length === 0 && (
                <div className="DragAndDropList__emptyPlaceholderText">{placeholderText}</div>
            )}
        </div>
    );
}
DragAndDropList.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    onClick: PropTypes.func,
    onDrop: PropTypes.func,
    isOver: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    expanded: PropTypes.bool,
    value: PropTypes.string,
    placeholderText: PropTypes.string,
    type: PropTypes.node,
    decorations: PropTypes.node,
    collapsible: PropTypes.bool,
};

DragAndDropList.defaultProps = {
    id: '',
    title: null,
    items: [],
    value: '',
    onClick: () => {},
    onDrop: () => {},
    isOver: false,
    connectDropTarget: (node) => node,
    expanded: false,
    placeholderText: '',
    type: null,
    decorations: null,
    collapsible: false,
};

export default DragAndDropList;
