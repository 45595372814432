import { Actions } from './reducers';
import TriggerApiInstance from './api';
import { operations } from '../../../../models/base';

const create = (form) => (dispatch) => {
    dispatch(Actions.createRequest(form));
    return TriggerApiInstance.create(form).then(
        (response) => dispatch(Actions.createSuccess(form, response)),
        (errors) => dispatch(Actions.createFailure(form, errors)),
    );
};

const pause = (id) => (dispatch) => {
    dispatch(Actions.pauseRequest(id));
    return TriggerApiInstance.pause(id).then(
        (response) => dispatch(Actions.pauseSuccess(id, response)),
        (errors) => dispatch(Actions.pauseFailure(id, errors)),
    );
};

const activate = (id) => (dispatch) => {
    dispatch(Actions.activateRequest(id));
    return TriggerApiInstance.activate(id).then(
        (response) => dispatch(Actions.activateSuccess(id, response)),
        (errors) => dispatch(Actions.activateFailure(id, errors)),
    );
};

export default operations(Actions, TriggerApiInstance, { create, pause, activate });
