import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable, Nugget } from '../../../../shared';
import { formatDatasetData } from '../../../../Data/helpers';
import { typeIcons } from '../../../../Data/constants';

require('./styles.scss');

class DatasetMappingTable extends Component {
    handlePaginate = (page) => {
        const { dataset, getDatasetData } = this.props;
        getDatasetData(dataset._id, { page }, {});
    };
    render() {
        const { dataset, datasets, features } = this.props;
        const selectedDatasetFeatures = Object.values(features.byId).filter(
            (feature) => feature.dataset._id === dataset?._id && !feature.hidden,
        );
        const nuggets = selectedDatasetFeatures.reduce((featureMap, feature) => {
            const newFeatureMap = { ...featureMap };
            newFeatureMap[feature._id] = (
                <div className="DatasetMappingTable__tableHeading">
                    <Nugget
                        label={feature.name}
                        value={{ ...feature }}
                        icon={typeIcons[feature.type]}
                        removable={false}
                        type="feature"
                    />
                </div>
            );
            return newFeatureMap;
        }, {});

        const tableValue = formatDatasetData(dataset, features);

        return (
            <div className="DatasetMappingTable">
                {dataset && dataset.data ? (
                    <div className="DatasetMappingTable__MappingContainer">
                        <DataTable
                            id="dataTable"
                            titles={nuggets}
                            value={tableValue || []}
                            loading={!datasets.hydrated}
                            loadingNumber={1}
                            white
                            noVerticals
                        />
                    </div>
                ) : (
                    <div className="DatasetMappingTable__MappingContainer DatasetMappingTable__MappingContainer--empty">
                        {dataset && (dataset.loading || !dataset.data) ? 'loading...' : 'Select a dataset'}
                    </div>
                )}
            </div>
        );
    }
}

DatasetMappingTable.propTypes = {
    dataset: PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({ pages: PropTypes.shape({}) }),
        loading: PropTypes.bool,
        features: PropTypes.arrayOf(PropTypes.string),
    }),
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.arrayOf(PropTypes.string),
        hydrated: PropTypes.bool,
    }).isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.arrayOf(PropTypes.string),
        hydrated: PropTypes.bool,
    }).isRequired,
    getDatasetData: PropTypes.func.isRequired,
};
DatasetMappingTable.defaultProps = {
    dataset: null,
};

export default DatasetMappingTable;
