import { Actions } from './reducers';
import PermissionApiInstance from './api';
import { operations } from '../../../../models/base';

const listByProject = (projectId) => (dispatch) => {
    dispatch(Actions.listByProjectRequest(projectId));
    return PermissionApiInstance.listByProject(projectId).then(
        (permissions) => dispatch(Actions.listByProjectSuccess(permissions)),
        (errors) => dispatch(Actions.listByProjectFailure(errors)),
    );
};

export default operations(Actions, PermissionApiInstance, { listByProject });
