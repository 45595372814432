import ApiBase from '../../../../models/base/api';

class OrganizationApi extends ApiBase {
    constructor() {
        super('organizations');
    }

    showLogs = (id) => this.request('GET', `logs/organizations/${id}`);

    updateLogo = (id, obj) => this.request('PUT', `organizations/${id}/logo`, obj, { contentType: 'FormData' });

    getClients = (id) => this.request('GET', `organizations/${id}/clients`);
}

export default new OrganizationApi();
