import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

require('./styles.scss');

export default function MappingBucketItem({
    canDrop,
    onHover,
    onRemove,
    containerIndex,
    value,
    removeOnDropToNothing,
    removeOnSuccessfulDrop,
    horizontal,
    isPreview,
    serum,
    render,
}) {
    // add hover handler
    // this ref is defined here because we are using it in the hover handler, otherwise we could pass down setDropRef directly
    const dropRef = useRef(null);
    const [, setDropRef] = useDrop({
        accept: ['feature', 'series'],
        hover: (dragItem, monitor) => {
            if (!canDrop || !dropRef.current) return;
            // if item is hovering over itself, do nothing
            if (dragItem.value.id === value.id) return;

            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddle = horizontal
                ? (hoverBoundingRect.right - hoverBoundingRect.left) / 2
                : (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels offset from item
            const hoverClient = horizontal
                ? clientOffset.x - hoverBoundingRect.left
                : clientOffset.y - hoverBoundingRect.top;
            // Determine whether the item should be placed above or below this item in the list
            const hoverIndex = hoverClient < hoverMiddle ? containerIndex : containerIndex + 1;

            // call onHover method
            onHover(dragItem.value, hoverIndex);
        },
    });
    setDropRef(dropRef);

    // add drag interaction
    const [{ isDragging }, setDragRef, setPreviewRef] = useDrag({
        type: 'series',
        item: { type: 'series', value },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (!removeOnDropToNothing && !dropResult) return;
            if (!removeOnSuccessfulDrop && dropResult) return;
            if (dropResult && item.value.containerId === dropResult.containerId) return;
            onRemove();
        },
    });

    return render({
        serum,
        onRemove,
        isPreview,
        isDragging,
        setDropRef: dropRef,
        setPreviewRef,
        setDragRef,
        containerIndex,
    });
}

MappingBucketItem.propTypes = {
    serum: PropTypes.shape({}),
    horizontal: PropTypes.bool,
    onRemove: PropTypes.func,
    containerIndex: PropTypes.number,
    value: PropTypes.shape({ id: PropTypes.string }),
    removeOnDropToNothing: PropTypes.bool,
    removeOnSuccessfulDrop: PropTypes.bool,
    canDrop: PropTypes.bool,
    onHover: PropTypes.func,
    isPreview: PropTypes.bool,
    render: PropTypes.func.isRequired,
};

MappingBucketItem.defaultProps = {
    serum: {},
    horizontal: false,
    onRemove: () => {},
    onHover: () => {},
    containerIndex: null,
    value: null,
    removeOnDropToNothing: true,
    removeOnSuccessfulDrop: false,
    canDrop: true,
    isPreview: false,
};
