import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Icon } from '../../../shared';

require('./styles.scss');

export default function Validations(props) {
    const { validations, form } = props;
    if (validations.length === 0) return null;

    return (
        <ul className="Validations">
            {validations.map((validation) => {
                const status = validation.validate(form);
                return (
                    <li className="Validations__item" key={validation.label}>
                        <span
                            className={ClassNames('Validations__bullet', {
                                'Validations__bullet--success': status,
                            })}
                        >
                            <Icon icon="check-circle" size="20px" />
                        </span>
                        <span className="Validations__label">{validation.label}</span>
                    </li>
                );
            })}
        </ul>
    );
}

Validations.propTypes = {
    form: PropTypes.shape({}),
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            validate: PropTypes.func,
            label: PropTypes.string,
        }),
    ),
};

Validations.defaultProps = {
    form: {},
    validations: [],
};
