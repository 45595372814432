export const white = '#FFFFFF';
export const neutral98 = '#F9F9FC';
export const neutral95 = '#EFF0F6';
export const neutral90 = '#DFE2EE';
export const neutral85 = '#cfd4e5';
export const neutral80 = '#BFC6DC';
export const neutral70 = '#A1ABC8';
export const neutral60 = '#8490B1';
export const neutral50 = '#6A7798';
export const neutral40 = '#515E7E';
export const neutral30 = '#394665';
export const neutral20 = '#21304D';

export const blue200 = '#8BD4F7';
export const blue300 = '#64C3F2';
export const blue400 = '#4EB6EF';
export const blue500 = '#3FA9ED';
export const blue600 = '#3B9BDE';

export const red400 = '#D94E4E';
export const red700 = '#BB2C2F';

export const orange300 = '#F7B464';

export const green400 = '#00CE9D';
export const green500 = '#00C48B';

export const purple300 = '#8B8AD0';
