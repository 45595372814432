/* eslint-disable react/prop-types */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import {
    defaultTooltip,
    textStyle,
    defaultDataZoom,
    getXAxisOptions,
    getYAxisOptions,
    defaultLegend,
    defaultStyle,
} from '../../helpers/echarts';

const WWBoxPlot = (props) => {
    const {
        data,
        series,
        layout,
        xValueFormatter,
        yValueFormatter,
        xAxisType,
        yAxisType,
        xAxisZoomVisible,
        yAxisZoomVisible,
        hasLegend,
        hasTooltip,
        isAnimationActive,
        colorFunction,
        serumNameFormatter,
        topGridPadding,
        bottomGridPadding,
    } = props;

    /* puts data into the following form:
    {
        [dependentName1]: {
            [independentValue1]: [1,2,3,4.... (number values for this boxplot)]
            [independentValue2]: [5,6,7,8.... ]
        }
        [dependentName2]: {
            [independentValue1]: [1,2,3,4.... (number values for this boxplot)]
            [independentValue2]: [5,6,7,8.... ]
        }
    }
    this will be used to map to the format echarts wants, which is:
    [
        [1,2,3,4.... ],
        [5,6,7,8.... ]
    ] provided for each dependent series.
    */

    const dependentSeriesMap = {};
    const independentName = series.independent[0]?.name;
    series.dependent.forEach((serum) => {
        const transformedData = {};
        const dependentName = serum.name;
        data.forEach((point) => {
            if (point[dependentName] !== undefined) {
                const independentValue = point[independentName];
                if (transformedData[independentValue]) transformedData[independentValue].push(point[dependentName]);
                else transformedData[independentValue] = [point[dependentName]];
            }
        });
        dependentSeriesMap[dependentName] = transformedData;
    });

    const dependentFormatter = layout === 'horizontal' ? yValueFormatter : xValueFormatter;
    const independentFormatter = layout === 'horizontal' ? xValueFormatter : yValueFormatter;
    const tooltipKeys = ['Min', 'Q1', 'Median', 'Q3', 'Max'];

    return (
        <ReactEcharts
            notMerge
            style={defaultStyle}
            option={{
                tooltip: {
                    ...defaultTooltip,
                    trigger: 'axis',
                    show: hasTooltip,
                    formatter(params) {
                        // this gives header name in tooltip
                        return `${independentFormatter(params[0].name)}<br>${params
                            .sort((p1, p2) => {
                                // puts 'boxplot' points above 'scatter' outliers in overflowing tooltip
                                if (p1.componentSubType === p2.componentSubType) return 0;
                                return p1.componentSubType === 'boxplot' ? -1 : 1;
                            })
                            .map((p) =>
                                // defines the outliers and sets label value pair
                                p.componentSubType === 'scatter'
                                    ? `${p.marker} Outlier: ${dependentFormatter(p.data[1])}`
                                    : p.data
                                          // likewise defines the q1,min,med,max,q3
                                          .slice(1)
                                          .map((d, i) => `${p.marker} ${tooltipKeys[i]}: ${dependentFormatter(d)}`)
                                          .join('<br>'),
                            )
                            .join('<br>')}`;
                    },
                },
                legend: {
                    ...defaultLegend,
                    show: hasLegend,
                },
                xAxis: {
                    ...getXAxisOptions(props),
                    silent: false,
                },
                yAxis: {
                    ...getYAxisOptions(props),
                },
                // the following is a data pipeline that produces the boxplot data in the format echarts requires, using a built in echarts transform function.
                // echarts documentation on this is not very good (at the time of writing), so I've heavily commented how it works.
                dataset: [
                    ...Object.keys(dependentSeriesMap).flatMap((dependentName, i) => {
                        const transformedData = dependentSeriesMap[dependentName];
                        // for each dependent series, there are 3 dataset items. This is why all the calculations of dataset index are based on i * 3
                        return [
                            {
                                // loads raw data
                                // this is dataset pipeline index 0 for this serum, referenced as 0 + i * 3 (or just i * 3) elsewhere
                                source: Object.values(transformedData),
                            },
                            {
                                // produces boxplot data (no outliers)
                                // this is dataset pipeline index 1 for this serum, referenced as 1 + i * 3 elsewhere
                                fromDatasetIndex: i * 3, // references the data output from index 0 in the pipeline for this serum
                                transform: {
                                    type: 'boxplot', // this feature is new in echarts 5
                                    config: {
                                        itemNameFormatter: (params) => Object.keys(transformedData)[params.value], // params.value is the index of the data array
                                    },
                                },
                            },
                            {
                                // produces outliers data (see echarts examples... I don't 100% understand the syntax of this)
                                // this is dataset pipeline index 2 for this serum, referenced as 2 + i * 3 elsewhere
                                fromDatasetIndex: 1 + i * 3, // references the data output from index 1 in the pipeline for this serum
                                fromTransformResult: 1, // I think this is the index of the data in the transform result from the previous step?? (not sure what it means really, but this value is required to get the outliers and must be 1)
                            },
                        ];
                    }),
                ],
                series: [
                    ...series.dependent.flatMap((serum, i) => [
                        {
                            name: serumNameFormatter(serum),
                            type: 'boxplot',
                            itemStyle: {
                                borderColor: colorFunction(serum),
                                color: colorFunction(serum),
                                opacity: 0.5,
                            },
                            animation: isAnimationActive,
                            datasetIndex: 1 + i * 3, // reference to the data from the `dataset` key
                        },
                        {
                            name: `${serumNameFormatter(serum)} outliers`,
                            type: 'scatter',
                            itemStyle: {
                                borderColor: colorFunction(serum),
                                color: colorFunction(serum),
                                opacity: 0.5,
                            },
                            animation: isAnimationActive,
                            datasetIndex: 2 + i * 3, // reference to the data from the `dataset` key
                        },
                    ]),
                ],
                grid: {
                    left: yAxisZoomVisible ? 40 : 20,
                    right: 20,
                    bottom: bottomGridPadding,
                    top: topGridPadding,
                    containLabel: true,
                },
                dataZoom: [
                    {
                        ...defaultDataZoom,
                        height: 3,
                        bottom: 20,
                        show: xAxisZoomVisible,
                        labelFormatter:
                            xAxisType === 'category' ? (i, value) => xValueFormatter(value) : xValueFormatter,
                    },
                    {
                        ...defaultDataZoom,
                        width: 3,
                        left: 15,
                        show: yAxisZoomVisible,
                        orient: 'vertical',
                        labelFormatter:
                            yAxisType === 'category' ? (i, value) => yValueFormatter(value) : yValueFormatter,
                    },
                ],
                textStyle,
            }}
        />
    );
};

export default WWBoxPlot;
