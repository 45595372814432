import moment from 'moment';

export function getDefaultModelInputs(model) {
    // returns the an array of inputs with value: default value (for use in 'reset defaults' dropdown option)
    if (!model || !model.inputs) return false;
    return model.inputs.map((input) => {
        // do not change the hidden inputs:
        if (input.display.modelPanel === 'hidden') return input;
        return { ...input, value: input.default };
    });
}

export function mergeModelInputs(model, modelRun) {
    // returns an array of inputs with values taken from the model run (for use in the 'load input values' dropdown option)
    if (!model || !model.inputs || !modelRun || !modelRun.inputs || modelRun.modelId !== model._id) return false;
    return model.inputs.map((input) => {
        if (input.display.modelPanel === 'hidden') return input;
        const modelRunInput = modelRun.inputs.find((runInput) => runInput.varName === input.varName);
        if (modelRunInput) return { ...input, value: modelRunInput.value };
        return input;
    });
}

export function getModelRunStatusLabel(modelRun) {
    // label will always be execution date time (with some fallbacks)
    const { status, executionDatetime } = modelRun;
    if (executionDatetime) return moment.utc(executionDatetime).local().format('YYYY-MM-DD, h:mm:ss A');

    // fallbacks
    if (status === 'queued') return 'Model Queued';
    if (status === 'started') return 'Model Running';
    if (status === 'finished') return 'Model Run Finished';
    if (status === 'failed') return 'Model Run Failed';
    if (status === 'expired') return 'Model Run Expired';
    return status;
}

export function makeButtonOptions(model, config) {
    const options = [];
    if (config.isPublic) return [];
    options.push({
        id: 'reset',
        name: 'Reset Inputs',
        onClick: () => config.handleInputsChange(getDefaultModelInputs(model)),
    });
    if (!config.noEditLink && config.navigate) {
        options.push({
            id: 'edit',
            name: 'Edit',
            onClick: () => config.navigate(`/projects/${config.projectId}/models/${model._id}`),
        });
    }
    return options;
}
