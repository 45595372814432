/* eslint-disable react/no-array-index-key */
// It is safe to use keys as ids in this component -- the elements we are rendering do not have component state (and do not reorder often)
// https://reactjs.org/docs/lists-and-keys.html
// https://medium.com/@robinpokorny/index-as-a-key-is-an-anti-pattern-e0349aece318
// https://stackoverflow.com/questions/29808636/when-giving-unique-keys-to-components-is-it-okay-to-use-math-random-for-gener
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import Loader from '../Loaders';

require('./styles.scss');

export default class Table extends PureComponent {
    static getDerivedStateFromProps() {
        return { loadingPagination: false };
    }
    constructor(props) {
        super(props);
        this.state = props.scrollPagination ? { loadingPagination: false } : {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        const { scrollPagination, pagination, onPaginate } = this.props;
        const { loadingPagination } = this.state;
        if (scrollPagination && this.containerRef) {
            this.containerRef.addEventListener('scroll', () => {
                if (
                    !loadingPagination &&
                    pagination.hasNext &&
                    this.containerRef.scrollTop + this.containerRef.clientHeight >= this.containerRef.scrollHeight - 800
                ) {
                    // call onPaginate to load more items
                    this.setState({ loadingPagination: true });
                    onPaginate(pagination.next);
                }
            });
        }
    }
    handleSubmit(values) {
        const { pagination, onPaginate } = this.props;
        const value = parseInt(values.current);
        if (value <= pagination.total && value > 0) {
            onPaginate(value);
        } else if (value < 0) {
            onPaginate(1);
        } else {
            onPaginate(pagination.total);
        }
    }
    render() {
        const {
            id,
            pagination,
            scrollPagination,
            onPaginate,
            isSelected,
            titles,
            loading,
            loadingNumber,
            pageLoading,
            value,
        } = this.props;

        const data = loading
            ? Array.from({ length: loadingNumber }, () => ({ Loading: <Loader type="content" loader="content" /> }))
            : value;
        const hasData = data.length > 0;
        const hasTitle = Object.keys(titles).length > 0 && !loading;

        const headers = hasData ? (
            Object.keys(hasTitle ? titles : data[0]).map((key) => <th key={key}>{titles[key] || key}</th>)
        ) : (
            <th label="Empty table" />
        );
        const rows = hasData ? (
            data.map((row, i) => (
                <tr
                    key={i}
                    className={ClassNames('Table__row', {
                        'Table__row--active': isSelected(row),
                        'Table__row--disabled': row.active !== undefined && !row.active,
                    })}
                >
                    {Object.keys(hasTitle ? titles : row).map((key) => (
                        <td key={key}>{row[key]}</td>
                    ))}
                </tr>
            ))
        ) : (
            <tr>
                <td>No data</td>
            </tr>
        );
        return (
            <div
                className={ClassNames('Table', {
                    'Table--pagination': !scrollPagination && pagination.total > 0,
                    'Table--noData': !hasData,
                    'Table--loading': loading,
                })}
                ref={(node) => {
                    this.containerRef = node;
                }}
            >
                <div className="Table__table">
                    <table>
                        <thead>
                            <tr>{headers}</tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                    {pageLoading && (
                        <div className="Table--pageLoading">
                            <Loader type="spinner" loader="scale" />
                        </div>
                    )}
                </div>
                {pagination.total !== 0 && !scrollPagination && (
                    <footer>
                        <Button id={id} onClick={() => onPaginate(pagination.prev)} disabled={!pagination.hasPrev} gray>
                            Previous
                        </Button>
                        <div className="Table__paginationInput">
                            <span>Page</span>
                            <Form onSubmit={this.handleSubmit} key={pagination.current || '1'}>
                                <Input
                                    autosize
                                    sm
                                    id="current"
                                    type="text"
                                    placeholder="1"
                                    submitOnBlur
                                    loading={loading}
                                    value={pagination.current.toString()}
                                />
                            </Form>
                            <span>{`of ${pagination.total}`}</span>
                        </div>
                        <Button
                            id={id}
                            onClick={() => onPaginate(pagination.next)}
                            disabled={!pagination.hasNext || pagination.current >= pagination.total}
                            gray
                        >
                            Next
                        </Button>
                    </footer>
                )}
            </div>
        );
    }
}

Table.propTypes = {
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    isSelected: PropTypes.func,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        hasNext: PropTypes.bool,
        hasPrev: PropTypes.bool,
        next: PropTypes.number,
        prev: PropTypes.number,
        total: PropTypes.number,
    }),
    scrollPagination: PropTypes.bool,
    onPaginate: PropTypes.func,
    titles: PropTypes.shape({}),
    loading: PropTypes.bool,
    loadingNumber: PropTypes.number,
    pageLoading: PropTypes.bool,
};

Table.defaultProps = {
    id: null,
    value: [],
    isSelected: () => false,
    pagination: { current: 0, hasNext: false, hasPrev: false, next: 0, prev: 0, total: null },
    scrollPagination: false,
    titles: {},
    onPaginate: () => {},
    loading: false,
    loadingNumber: 10,
    pageLoading: false,
};
