const canRunModels = () => (state) =>
    state.app.permission?.roles
        ? state.app.permission.roles.model === 'edit' ||
          state.app.permission.roles.model === 'view' ||
          state.app.permission.roles.dashboard === 'edit' ||
          state.app.permission.roles.dashboard === 'view'
        : null;

const canEditModels = () => (state) =>
    state.app.permission?.roles ? state.app.permission.roles.model === 'edit' : null;

export default {
    canRunModels,
    canEditModels,
};
