import React from 'react';
import PropTypes from 'prop-types';
import ModelInputDisplay from './display';

export default function ModelInput({ input, inputs, onInputsChange, disabled, inputProps }) {
    const handleChange = (varName, value) => {
        const changedIndex = inputs.findIndex((_input) => _input.varName === varName);
        if (changedIndex === -1) return; // varName not found
        const newInput = { ...inputs[changedIndex], value };
        const newInputs = [...inputs];
        newInputs.splice(changedIndex, 1, newInput);
        onInputsChange(newInputs);
    };

    return <ModelInputDisplay input={input} disabled={disabled} inputProps={inputProps} handleChange={handleChange} />;
}

ModelInput.propTypes = {
    input: PropTypes.shape({}).isRequired,
    inputs: PropTypes.array.isRequired,
    onInputsChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputProps: PropTypes.shape({}),
};

ModelInput.defaultProps = {
    disabled: false,
    inputProps: {},
};
