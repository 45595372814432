import Pie from '../components/Pie';
import Radar from '../components/Radar';
import BoxPlot from '../components/BoxPlot';
import Composed from '../components/Composed';
import { xyChartTypes } from './constants';

export * from './constants';
export * from './settings';
export * from './redux';
export * from './props';

export const chartComponents = (chartType) => {
    if (Object.keys(xyChartTypes).includes(chartType)) return Composed;
    switch (chartType) {
        case 'pie':
            return Pie;
        case 'radar':
            return Radar;
        case 'boxplot':
            return BoxPlot;
        default:
            return false;
    }
};

export const handleLargerData = (hasAnimation, dependentSeries, numberOfDataPoints, chartType) => {
    if (numberOfDataPoints > 1000000) return { animationActive: false, renderChart: false };
    switch (chartType) {
        case 'area':
        case 'line':
        case 'bar':
        default:
            if (numberOfDataPoints > 200) return { animationActive: false, renderChart: true };
            break;
        case 'scatter':
            // scatter has progressive rendering, so we want animation on.
            break;
        case 'boxplot':
            // progressive rendering thing in theory also applies to boxplot because most points there are scatter
            // boxplot currently only returns one thing because of bad backend code, so this doesn't actually work
            if (dependentSeries.length > 20) return { animationActive: false, renderChart: true };
            break;
        case 'radar':
            if (numberOfDataPoints > 1000) return { animationActive: false, renderChart: false };
            if (numberOfDataPoints > 40) return { animationActive: false, renderChart: true };
            break;
        case 'pie':
            if (dependentSeries.length > 1000) return { animationActive: false, renderChart: false };
            if (dependentSeries.length > 40) return { animationActive: false, renderChart: true };
            break;
    }
    return { animationActive: hasAnimation ?? true, renderChart: true };
};
