import ApiBase from '../../../models/base/api';
import { createQueryString } from '../../../models/base/api/helpers';

class UnitApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('units', addProjectIdToSearchParams);
    }

    data = (id, queryParameters, temporaryQueries) => {
        const searchString = createQueryString(queryParameters);
        return this.request('POST', `${this.endpoint}/${id}/data${searchString}`, temporaryQueries || {});
    };

    temporaryData = (queryParameters, unitPayload) => {
        const searchString = createQueryString(queryParameters);
        return this.request('POST', `${this.endpoint}/data${searchString}`, unitPayload);
    };

    download = (id, options) => {
        const searchString = createQueryString(options);
        return this.request('GET', `${this.endpoint}/${id}/download${searchString}`);
    };

    updateWithData = (id, obj, options) => {
        const searchString = createQueryString(options);
        return this.request('PUT', `${this.endpoint}/${id}${searchString}`, obj).then((unit) =>
            this.data(id, options).then((data) => ({ ...unit, data })),
        );
    };
}

export default new UnitApi();
