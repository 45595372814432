/* eslint-disable react/prop-types */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { neutral50 } from '../../../../../styles/partials/colors';
import { defaultTooltip, textStyle, defaultLegend, defaultStyle } from '../../helpers/echarts';

const WWPie = (props) => {
    const {
        data,
        series,
        valueFormatter,
        labelFormatter,
        tooltipFormatter,
        hasLegend,
        hasTooltip,
        isAnimationActive,
        colorFunction,
        dependentAxisProps,
    } = props;

    const chartSeries = [...series.dependent];
    const chartData = series.dependent
        .map((serum, i) => {
            const serumData = data.find((datum) => datum[serum.name]);
            if (!serumData) chartSeries.splice(i, 1); // don't chart serum without data
            return (
                serumData && {
                    ...serumData,
                    name: serum.name,
                    value: serumData[serum.name],
                }
            );
        })
        .filter((d) => d);

    return (
        <ReactEcharts
            notMerge
            style={defaultStyle}
            option={{
                tooltip: {
                    ...defaultTooltip,
                    trigger: 'item',
                    show: hasTooltip,
                    formatter: (param) =>
                        `${labelFormatter(param.name)}: ${valueFormatter(param.data.value)} (${param.percent}%)${
                            tooltipFormatter ? `, ${tooltipFormatter(param.data).join(', ')}` : ''
                        }`,
                },
                legend: {
                    ...defaultLegend,
                    show: hasLegend,
                    data: chartData.map((dataPoint) => dataPoint.name),
                    formatter: labelFormatter,
                },
                color: chartSeries.map((serum) => colorFunction(serum)),
                series: [
                    {
                        type: 'pie',
                        radius: [`${dependentAxisProps[0].innerRadius}%`, `${dependentAxisProps[0].outerRadius}%`],
                        center: ['50%', '50%'],
                        startAngle: dependentAxisProps[0].startAngle + 90,
                        data: chartData,
                        animation: isAnimationActive,
                        label: dependentAxisProps[0].pieHasLabels
                            ? {
                                  position: 'outer',
                                  alignTo: 'none',
                                  bleedMargin: 5,
                                  formatter: (serum) => labelFormatter(serum.name),
                              }
                            : null,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: neutral50,
                            },
                        },
                    },
                ],
                textStyle,
            }}
        />
    );
};

export default WWPie;
