/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import ClassNames from 'classnames';
import moment from 'moment/moment';
import { PackageModel } from '../../Models/_models';
import { Input, Icon, IconButton, Loader, ConfirmDeleteMenu, Form, Breadcrumb, Button } from '../../shared';
import UserSelectors from '../../Access/_models/users/selectors';
import { getPackageState } from '../../Models/List/helpers';
import DetailHeader from '../../Models/_components/DetailHeader';
import CodeModal from '../../Models/_components/CodeModal';
import CodeEditor from '../../Models/_components/CodeEditor';

require('./styles.scss');

const PackageDisplay = () => {
    const { packageId, projectId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { packageDetail, algorithms, models, canEditPackages } = useSelector((store) => ({
        packageDetail: store.packages.byId[packageId],
        algorithms: store.algorithms,
        models: store.models,
        canEditPackages: store.app.permission?.roles?.package === 'edit',
    }));

    const packages = useSelector((state) => state.packages);
    const packageObj = packages.byId[packageId];
    const userDetail = useSelector(UserSelectors.getUserInfo(packageDetail?.userId));

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [codeModalOpen, setCodeModalOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const [selectedFile, setSelectedFile] = useState(packageDetail?.modules[0]?.filename);

    useEffect(() => {
        if (packageId && !packages.loading) {
            if (!packageObj) {
                dispatch(PackageModel.show(packageId));
            } else if (!packageObj.sourceCode && !packageObj.loading) {
                dispatch(PackageModel.getSourceCode(packageId));
            }
        }
    }, []);

    const algoMap = {};
    // check if packageDetail exists and algorithms.byId contains elements
    if (
        packageDetail?.modules?.length > 0 &&
        Object.keys(algorithms.byId).length > 0 &&
        Object.keys(models.byId).length > 0
    ) {
        // Get all algorithms from a package: Loop through each package module, extract algorithms from each module and push into a general algo dictionary
        packageDetail.modules.forEach((module) => {
            module.algorithms.forEach((algorithm) => {
                if (!algoMap[algorithm._id]) {
                    algoMap[algorithm._id] = {
                        _id: algorithm._id,
                        name: algorithms.byId[algorithm._id]?.name,
                    };
                }
            });
        });
    }

    const packageModels = Object.values(models.byId)
        .filter((model) => !!algoMap[model.algorithmId])
        .map((model) => ({
            _id: model._id,
            name: model.name,
            onboarded: model.onboarded,
            algorithm: {
                ...algoMap[model.algorithmId],
            },
        }));

    const { info, packageIcon, icon, state } = getPackageState(packageDetail);

    return packageDetail ? (
        <div className="PackageDisplay">
            <DetailHeader
                title={
                    <Breadcrumb
                        items={[
                            { label: 'Source Code', path: `/projects/${projectId}/models` },
                            { label: packageDetail?.name },
                        ]}
                    />
                }
                onBack={() => navigate(`/projects/${projectId}/models`)}
                icon="large_color/package-upload"
                menuItems={
                    canEditPackages
                        ? [
                              {
                                  id: 'updatePackage',
                                  name: 'Update Package',
                                  onClick: () => {
                                      setCodeModalOpen(true);
                                      setSelectedPackage(packageDetail._id);
                                  },
                                  onClose: () => setSelectedPackage(null),
                              },
                              {
                                  id: 'deletePackage',
                                  name: 'Delete Package',
                                  onClick: () => setDeleteModalOpen(true),
                                  children: (
                                      <ConfirmDeleteMenu
                                          open={deleteModalOpen}
                                          onClose={() => setDeleteModalOpen(false)}
                                          onDelete={() => {
                                              dispatch(PackageModel.destroy(packageDetail._id)).then(() => {
                                                  navigate(`/projects/${projectId}/models`);
                                              });
                                          }}
                                      />
                                  ),
                              },
                          ]
                        : []
                }
            />

            {state !== 'complete' ? (
                <div
                    className={ClassNames('PackageDisplay__state', {
                        'PackageDisplay__state--building': state === 'building',
                    })}
                >
                    <Icon icon={packageIcon || icon} size="50px" />
                    <span>{info}</span>
                </div>
            ) : null}

            <div className="PackageDisplay__content">
                <Form
                    className="PackageDisplay__items"
                    onSubmit={(form) => {
                        dispatch(
                            PackageModel.update(packageDetail._id, { name: form.name, description: form.description }),
                        )
                            .then((resp) => {
                                // This will be executed after the update is successful
                                console.log('PACKAGE UPDATED SUCCESSFULLY', resp);
                            })
                            .catch((error) => {
                                // This will be executed if the update fails
                                console.error('UPDATE FAILED:', error);
                            });
                    }}
                >
                    <Input
                        className="PackageDisplay__input"
                        type="text"
                        id="name"
                        label="Package Name"
                        value={packageDetail?.name}
                        gray
                        disabled={!canEditPackages}
                        submitOnBlur
                        required
                    />
                    <Input
                        className="PackageDisplay__input"
                        type="text"
                        id="date uploaded"
                        label="Date Uploaded"
                        gray
                        value={moment.utc(packageDetail?.timeCreated).local().format('lll')}
                        disabled
                        icon="lock"
                    />

                    <Input
                        className="PackageDisplay__input"
                        type="text"
                        id="uploaded by"
                        label="Uploaded By"
                        gray
                        value={userDetail?.email}
                        disabled
                        icon="lock"
                    />
                    <Input
                        className="PackageDisplay__input"
                        type="text"
                        id="filename"
                        label="File Name"
                        gray
                        value={packageDetail?.filename}
                        disabled
                        icon="lock"
                    />

                    <Input
                        className="PackageDisplay__input"
                        type="text"
                        id="description"
                        label="Description"
                        value={packageDetail?.description}
                        gray
                        disabled={!canEditPackages}
                        submitOnBlur
                    />

                    <div className="PackageDisplay__extra__title">
                        Used in {packageModels.length} model{packageModels.length === 1 ? '' : 's'}:
                    </div>
                    <div className="PackageDisplay__models">
                        {packageModels.map((model) => (
                            <div className="PackageDisplay__models-item" key={model._id}>
                                <Link
                                    to={`/projects/${projectId}/models/${model._id}${
                                        model.onboarded ? '' : '/onboard'
                                    }`}
                                    className="PackageDisplay__models-name"
                                >
                                    {model.name}
                                </Link>
                                <div>algos/{model.algorithm.name}</div>
                            </div>
                        ))}
                    </div>
                </Form>

                <div className="PackageDisplay__extra">
                    <div className="PackageDisplay__dependencies">
                        <CodeEditor packageId={packageId} fileName="dependencies" noHeader={!canEditPackages} />
                    </div>
                    <div className="PackageDisplay__environments">
                        <CodeEditor packageId={packageId} fileName="env" noHeader={!canEditPackages} />
                    </div>
                </div>

                <CodeEditor packageId={packageId} fileName="__init__.py" />
            </div>
        </div>
    ) : (
        <Loader type="spinner" />
    );
};

export default PackageDisplay;
