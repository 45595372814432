import React, { useState, useRef, useEffect, useContext } from 'react';

import OpenAI from "openai";


/* eslint-disable */
import { MdSend } from 'react-icons/md';
import { AiOutlineClear } from 'react-icons/ai';

import { replaceProfanities } from 'no-profanity';

import { davinci } from '../../utils/davinci';
import { dalle } from '../../utils/dalle';

import { ChatContext } from '../../context/chatContext';
import Message from '../Message';
import Thinking from '../Thinking';
import Modal from '../Modal';
import Setting from '../Settings';

import './styles.css';

const options = ['GPT4', 'DALL·E'];
const gptModel = ['gpt-3.5-turbo', 'gpt-4-1106-preview'];
const template = [
  {
    title: 'Plan a trip',
    prompt: 'I want to plan a trip to New York City.',
  },
  {
    title: 'how to make a cake',
    prompt: 'How to make a cake with chocolate and strawberries?',
  },
  {
    title: 'Business ideas',
    prompt: 'Generate 5 business ideas for a new startup company.',
  },
  {
    title: 'What is recursion?',
    prompt: 'What is recursion? show me an example in python.',
  },
];

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = () => {
  const messagesEndRef = useRef();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const [thinking, setThinking] = useState(false);
  const [selected, setSelected] = useState(options[0]);
  const [gpt, setGpt] = useState(gptModel[1]);
  const [messages, addMessage] = useContext(ChatContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [, , clearChat] = useContext(ChatContext);

  const clear = () => {
    clearChat();
  }

  /**
   * Scrolls the chat area to the bottom.
   */
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Adds a new message to the chat.
   *
   * @param {string} newValue - The text of the new message.
   * @param {boolean} [ai=false] - Whether the message was sent by an AI or the user.
   */
  const updateMessage = (newValue, ai = false, selected) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      ai: ai,
      selected: selected,
    };

    addMessage(newMsg);
  };

  /**
   * Sends our prompt to our API and get response to our request from openai.
   *
   * @param {Event} e - The submit event of the form.
   */
  const sendMessage = async (e) => {

    e.preventDefault();
    const key = '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'

    const cleanPrompt = replaceProfanities(formValue);

    const newMsg = cleanPrompt;
    const aiModel = selected;
    const gptVersion = gpt;

    setThinking(true);
    setFormValue('');
    updateMessage(newMsg, false, aiModel);

    try {
      if (aiModel === options[0]) {
        const LLMresponse = await davinci(cleanPrompt, key, gptVersion);
        LLMresponse && updateMessage(LLMresponse, true, aiModel);
      } else {
        const data = await dalle(cleanPrompt, key);
        data && updateMessage(data, true, aiModel);
      }
    } catch (err) {
      window.alert(`Error: ${err} please try again later`);
    }

    setThinking(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // 👇 Get input value
      sendMessage(e);
    }
  };

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  useEffect(() => {
    scrollToBottom();
  }, [messages, thinking]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);


  // FUTURE: TAB TO SELECT MODELS
  // <div className='custom-tabs'>
  //   <a
  //     onClick={() => setGpt(gptModel[0])}
  //     className={`${gpt == gptModel[0] ? 'custom-tab-active' : ''} custom-tab`}>
  //     GPT-3.5
  //   </a>
  //   <a
  //     onClick={() => setGpt(gptModel[1])}
  //     className={`${gpt == gptModel[1] ? 'custom-tab-active' : ''} custom-tab`}>
  //     GPT-4
  //   </a>
  // </div>

  return (

    <main className='custom-main'>
      <section className='custom-messages-container'>
        {messages.length ? (
          messages.map((message, index) => (
            <Message key={index} message={{ ...message }} />
          ))
        ) : (
          <div className='thinkng-container'>
            <div className='custom-template-container'>
              <ul className='custom-template-list'>
                {template.map((item, index) => (
                  <li
                    onClick={() => setFormValue(item.prompt)}
                    key={index}
                    className='custom-template-item'>
                    <p className='custom-template-title'>{item.title}</p>
                    <p className='custom-template-description'>{item.prompt}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        )}
        {thinking && <Thinking />}

        <span ref={messagesEndRef}></span>
      </section>
      <form className='custom-form'>
        <select
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          className='custom-select'>
          <option>{options[0]}</option>
          <option>{options[1]}</option>
        </select>
        <div className='custom-textarea-container'>
          <textarea
            ref={inputRef}
            className='custom-textarea'
            value={formValue}
            onKeyDown={handleKeyDown}
            onChange={(e) => setFormValue(e.target.value)}
          />
          <button type='submit' className='custom-send-btn' disabled={!formValue} onClick={sendMessage}>
            <MdSend size={30} />
          </button>
          <button type='submit' className='custom-clear-btn' onClick={clear}>
            <AiOutlineClear size={30} />
          </button>
        </div>
      </form>

    </main>

  );
};

export default ChatView;
