import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { useNavigate, useParams } from 'react-router-dom';
import TabModel from '../../Dashboards/_models/tabs';
import UnitSelectors from '../_model/selectors';
import ModelSelector from '../../Models/_models/models/selectors';
import UnitModel from '../_model';
import CustomUnitContext from './CustomUnitContext';  // Adjust the import path as necessary

import {
    Card,
    Form,
    Icon,
    IconButton,
    ToolTip,
    Input,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    ConfirmDeleteMenu,
} from '../../shared';
import FilterSummary from '../../Data/Filter/Summary';
import TypeSelection from '../components/TypeSelection';
import Unit from './unit';

require('./styles.scss');

export default function UnitCard({
    id,
    draggableHandleClassName,
    layoutItem,
    tabId,
    dashboardId,
    editable,
    publicFilters,
    width,
}) {
    const unit = useSelector((state) => state.units.byId[id] || { queries: [] });

    const [downloadCSV, setDownloadCSV] = useState(() => {});

    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [unitDescriptionOpen, setUnitDescriptionOpen] = useState(!!unit.description);
    const [showDeleteTooltip, setShowDeleteTooltip] = useState(false);

    const model = useSelector((state) => UnitSelectors.getModel(unit._id)(state) || {});
    const jobId = useSelector((state) => ModelSelector.getModelRunId(model?._id)(state) || 'latest');
    const isPublicView = useSelector((state) => state.app.account?.public);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const customUnitRef = useRef();
    const descriptionRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
                setUnitDescriptionOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [descriptionRef]);

    useEffect(() => {
        setUnitDescriptionOpen(false);
    }, []);

    // useEffect(() => {
    //     if (unitDescriptionOpen) {
    //         const timer = setTimeout(() => {
    //             setUnitDescriptionOpen(false);
    //         }, 10000); // 10000ms = 10 seconds

    //         return () => clearTimeout(timer); // Cleanup the timer on component unmount
    //     }
    //     return () => {};
    // }, [unitDescriptionOpen, unit.description]);

    const openMapping = (type) => {
        let path = `/projects/${projectId}/units/${unit._id}/mapping?tab=${tabId}`;
        if (type) path = `${path}&type=${type}`;
        navigate(path);
    };

    const handleCustom = () => {
        openMapping('custom');
    };

    const handleChange = (values) => {
        dispatch(UnitModel.update(unit._id, values, { tabId }));
    };

    const handleDescription = (values) => {
        handleChange(values);
        if (!values.description) {
            setUnitDescriptionOpen(false);
        }
    };

    const handleDuplicate = () => {
        const layoutItemForDuplicate = { ...layoutItem };
        delete layoutItemForDuplicate.i;
        dispatch(TabModel.duplicateUnit(tabId, unit._id, { layoutItem: layoutItemForDuplicate }));
        setOptionsMenuOpen(false);
    };

    const handleDownload = () => {
        setOptionsMenuOpen(false);
        setShowDeleteTooltip(false);
        return dispatch(UnitModel.download(unit._id, { jobId, tabId }));
    };

    const handleUnitSpecificDownload = () => {
        downloadCSV();
    };



    const chooseUnitType = (type) => {
        openMapping(type);
    };

    // --------------------------------------------------------------------
    //                    Card Icon Tags
    // --------------------------------------------------------------------
    const unitModelTag = () =>
        model &&
        model.name && (
            <ToolTip tooltip={model.name} place="bottom">
                <Icon id="model" icon="brain" pulsing={model && model.running} size="20px" />
            </ToolTip>
        );

    const unitFilterTag = () => {
        const chartOrTableFilter = !!(unit.vis?.filters?.length > 0);
        const kpiFilter = !!unit.vis?.kpi?.find((kpi) => kpi.filters?.length > 0);
        const unitHasFilter = chartOrTableFilter || kpiFilter;

        const unitFilterSummary = (
            <React.Fragment key={id}>
                {unit.type !== 'kpi' ? (
                    <FilterSummary value={unit.vis?.filters} />
                ) : (
                    unit.vis?.kpi && unit.vis?.kpi?.map((kpi) => <FilterSummary key={kpi.id} value={kpi.filters} />)
                )}
            </React.Fragment>
        );

        return (
            unitHasFilter && (
                <ToolTip tooltip={unitFilterSummary} place="bottom">
                    <Icon id="filter" icon="filter" size="20px" />
                </ToolTip>
            )
        );
    };

    // --------------------------------------------------------------------
    //                         Card Header
    // --------------------------------------------------------------------

    const renderFullHeaderOptions = () => (
        <React.Fragment key={id}>
            {editable && <div className={ClassNames('UnitCard__handle', draggableHandleClassName)} />}
            <div className="UnitCard__buttons">
                {unit.description && <IconButton id="unitDescription" icon="info" tooltip={unit.description} allowHtml />}
                {editable && <IconButton id="mappingIcon" icon="edit" tooltip="Edit" onClick={() => openMapping()} />}
                {isPublicView || (!editable && (unit.type === 'kpi' || unit.type === 'custom')) ? null : (
                    <IconButton
                        id="menu"
                        icon="menu-vertical"
                        onClick={() => {
                            setOptionsMenuOpen(true);
                            setShowDeleteTooltip(false);
                        }}
                        active={optionsMenuOpen}
                    />
                )}
                <ContextMenu
                    className="UnitCard__menu"
                    open={optionsMenuOpen}
                    onClickOut={() => setOptionsMenuOpen(false)}
                >
                    <ContextSubMenu>
                        {!unitDescriptionOpen && editable && (
                            <ContextMenuItem
                                id="description"
                                name="Edit Description"
                                onClick={() => {
                                    setUnitDescriptionOpen(true);
                                    setOptionsMenuOpen(false);
                                }}
                            />
                        )}
                        {editable && <ContextMenuItem id="duplicate" name="Duplicate" onClick={handleDuplicate} />}
                        {(unit.type === 'chart' || unit.type === 'table' ) && !unit?.data?.error ? (
                            <ContextMenuItem id="download-csv" name="Download as CSV" onClick={handleDownload} />
                        ) : null}
                        {(unit?.type === 'custom' ) && !unit?.data?.error ? (
                            <ContextMenuItem id="download-custom-unit" name="Download" onClick={handleUnitSpecificDownload} />
                        ) : null}
                        {editable && (
                            <ContextMenuItem
                                id="delete"
                                name="Delete"
                                onClick={() => setShowDeleteTooltip(!showDeleteTooltip)}
                            >
                                <ConfirmDeleteMenu
                                    open={showDeleteTooltip}
                                    onClose={() => setShowDeleteTooltip(false)}
                                    onDelete={() => dispatch(TabModel.destroyUnit(tabId, unit._id))}
                                />
                            </ContextMenuItem>
                        )}
                        {editable && !unit.type && (
                            <ContextMenuItem id="custom" name="Beta Card Types" onClick={handleCustom} />
                        )}
                    </ContextSubMenu>
                </ContextMenu>
            </div>
        </React.Fragment>
    );

    const renderHeader = () => {
        let titleMaxWidth = width - 100;
        if (unit.vis?.filters?.length > 0) titleMaxWidth -= 30;
        if (model?.name) titleMaxWidth -= 30;
        if (!editable) titleMaxWidth += 50;

        return (
            <header className="UnitCard__header">
                <div className="UnitCard__flags">
                    {unitModelTag()}
                    {unitFilterTag()}
                </div>
                <Form onSubmit={handleChange} loading={unit.loading}>
                    <Input
                        id="name"
                        type="editable-text"
                        hoverable
                        placeholder={editable ? 'name' : ''}
                        submitOnBlur
                        disabled={!editable}
                        autosize
                        maxWidth={titleMaxWidth}
                        sm
                        value={unit.name}
                    />
                </Form>
                {renderFullHeaderOptions()}
            </header>
        );
    };

    // --------------------------------------------------------------------
    //                      Unit Description
    // --------------------------------------------------------------------
    const renderUnitDescription = () =>
        unitDescriptionOpen ? (
            <div ref={descriptionRef} className="UnitCard__description">
                <Form onSubmit={handleDescription} loading={unit.loading}>
                    <Input
                        id="description"
                        type="editable-textarea"
                        hoverable
                        placeholder="description"
                        submitOnBlur
                        maxRows={7}
                        disabled={!editable}
                        value={unit.description || ''}
                    />
                </Form>
            </div>
        ) : null;
    // --------------------------------------------------------------------

    return (
        <CustomUnitContext.Provider value={{ downloadCSV, setDownloadCSV }}>
            <div className={ClassNames('UnitCard', { 'UnitCard--open': optionsMenuOpen })} key={unit._id}>
                <Card>
                    {renderHeader()}
                    <main className="UnitCard__main">
                        {!unit.type ? (
                            <TypeSelection height={layoutItem.h} editable={editable} onSelect={chooseUnitType} />
                        ) : (
                            <Unit id={unit._id} tabId={tabId} dashboardId={dashboardId} publicFilters={publicFilters} />
                        )}
                        {renderUnitDescription()}
                    </main>
                </Card>
            </div>
        </CustomUnitContext.Provider>
    );
}

UnitCard.propTypes = {
    id: PropTypes.string,
    draggableHandleClassName: PropTypes.string,
    layoutItem: PropTypes.shape({
        h: PropTypes.number,
    }),
    tabId: PropTypes.string,
    dashboardId: PropTypes.string,
    editable: PropTypes.bool,
    publicFilters: PropTypes.array,
    width: PropTypes.number,
};

UnitCard.defaultProps = {
    id: null,
    draggableHandleClassName: '',
    layoutItem: {
        h: 4,
    },
    tabId: null,
    dashboardId: null,
    editable: true,
    // onCreate: () => {},
    publicFilters: [],
    width: null,
};
