import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    ContextMenu,
    ContextMenuItem,
    ContextSubMenu,
    Icon,
    IconButton,
    InfoToolTip,
    Input,
} from '../../../../../shared';
import { getIconFromDatasetType, getImportedDatasets } from '../../../../../Data/helpers';
import { fuzzySearch } from '../../../../../../helpers/string';

require('./styles.scss');

export default function DataMenu(props) {
    const { open, collections, datasets, onSelect, onClose } = props;

    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [openCollection, setOpenCollection] = useState(null);

    let importedDatasets = getImportedDatasets(datasets);

    importedDatasets = fuzzySearch(searchString, importedDatasets, ['name', 'description']);

    const datasetsInCollections = [];
    const datasetsNotInCollections = [];
    importedDatasets.forEach((dataset) => {
        if (
            dataset.collections?.length > 0 &&
            dataset.collections.some((collectionId) => collections.byId[collectionId])
        ) {
            datasetsInCollections.push(dataset);
        } else {
            datasetsNotInCollections.push(dataset);
        }
    });

    return (
        <ContextMenu
            open={open}
            onClickOut={() => {
                onClose();
                setSearchString('');
            }}
            position="top"
            className="DataMenu"
        >
            <ContextSubMenu>
                <header className="DataMenu__header" onMouseEnter={() => setOpenCollection(null)}>
                    {searching ? (
                        <Input
                            id="search"
                            type="text"
                            value={searchString}
                            onChange={(id, value) => setSearchString(value)}
                            placeholder="Search"
                            dark
                            sm
                            autoFocus
                        />
                    ) : (
                        <div>Datasets</div>
                    )}
                    <IconButton
                        dark
                        id="search"
                        icon="search"
                        onClick={() => {
                            setSearching(!searching);
                            setSearchString('');
                        }}
                        className="DataMenu__searchButton"
                    />
                </header>
            </ContextSubMenu>
            <ContextSubMenu>
                {Object.values(collections.byId).length > 0 &&
                    Object.values(collections.byId).map((collection) => {
                        const collectionDatasets = datasetsInCollections.filter((dataset) =>
                            dataset.collections.includes(collection._id),
                        );
                        return (
                            collectionDatasets.length > 0 && (
                                <React.Fragment key={collection._id}>
                                    <ContextMenu
                                        className="CollectionsMenu"
                                        open={openCollection === collection._id}
                                        position="right top"
                                        key={collection._id}
                                    >
                                        <ContextSubMenu>
                                            {collectionDatasets.map((dataset) => (
                                                <ContextMenuItem
                                                    key={dataset._id}
                                                    id={dataset._id}
                                                    name={dataset.name}
                                                    type={
                                                        <Icon
                                                            size="16px"
                                                            id={dataset._id}
                                                            icon={getIconFromDatasetType(dataset.type)}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        onSelect(dataset._id);
                                                        setSearchString('');
                                                    }}
                                                    rightChildren={
                                                        dataset.description && (
                                                            <InfoToolTip>{dataset.description}</InfoToolTip>
                                                        )
                                                    }
                                                />
                                            ))}
                                        </ContextSubMenu>
                                    </ContextMenu>
                                    <ContextMenuItem
                                        key={collection._id}
                                        id={collection._id}
                                        name={collection.name}
                                        type={<Icon size="16px" id={collection._id} icon="folder" />}
                                        onMouseEnter={() => setOpenCollection(collection._id)}
                                    />
                                </React.Fragment>
                            )
                        );
                    })}
                {datasetsNotInCollections.map((dataset) => (
                    <ContextMenuItem
                        key={dataset._id}
                        id={dataset._id}
                        name={dataset.name}
                        type={<Icon size="16px" id={dataset._id} icon={getIconFromDatasetType(dataset.type)} />}
                        onClick={() => {
                            onSelect(dataset._id);
                            setSearchString('');
                        }}
                        onMouseEnter={() => setOpenCollection(null)}
                        rightChildren={dataset.description && <InfoToolTip>{dataset.description}</InfoToolTip>}
                    />
                ))}
            </ContextSubMenu>
        </ContextMenu>
    );
}

DataMenu.propTypes = {
    collections: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
    }).isRequired,
    datasets: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

DataMenu.defaultProps = {
    open: false,
};
