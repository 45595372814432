import { defaultChartModel } from '../Chart/schema';
import { defaultKpiModel } from '../KPI/schema';
import { defaultTableModel } from '../Table/schema';

export const getDefaultModel = (type) => {
    if (type === 'kpi') {
        return defaultKpiModel;
    }
    if (type === 'table') {
        return defaultTableModel;
    }
    if (type === 'chart') {
        return defaultChartModel;
    }
    if (type === 'custom') {
        return { type: 'custom', vis: {} };
    }
    return null;
};

export const getDefaultUnit = (type) => type && getDefaultModel(type);
