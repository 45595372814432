import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {
    useDispatch,
    // useSelector
} from 'react-redux';
import {
    Button,
    CollapsibleSection,
    ConfirmDeleteMenu,
    Icon,
    IconButton,
    Input,
    ModalCard,
    TabHeader,
} from '../../../shared';
import {
    repeatOptions,
    weeklyRunScheduleOptions,
    monthlyRunScheduleOptions,
    convertTime,
    formatDate,
} from '../CreateScheduledModal/constants';
import ModelInput from '../ModelInput';
import { ModelModel, TriggerModel } from '../../_models';

require('./styles.scss');

export default function EditScheduledModal(props) {
    const { open, onClose, model, currentTrigger } = props;

    const dispatch = useDispatch();
    const inputs = model && model.inputs;
    const primaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'primary') : [];
    const secondaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'secondary') : [];

    const [tabIndex, setTabIndex] = useState(0);
    const [triggerState, setTriggerState] = useState(currentTrigger?.status);
    const [runName, setRunName] = useState(currentTrigger?.name);
    const [hasDescription, setHasDescription] = useState(!!currentTrigger?.description);
    const [inputDescription, setInputDescription] = useState(currentTrigger?.description);
    const [runStartTime, setRunStartTime] = useState(currentTrigger?.pattern?.start?.scheduled_starttime);
    const [runEndTime, setRunEndTime] = useState(currentTrigger?.pattern?.end?.scheduled_endtime);
    const [runStartDate, setRunStartDate] = useState(currentTrigger?.pattern?.start?.effective_startdate);
    const [runEndDate, setRunEndDate] = useState(currentTrigger?.pattern?.start?.until_datetime);
    const [repeatFrequency, setRepeatFrequency] = useState(currentTrigger?.status?.pattern?.repeat?.unit);
    const [minuteRunSchedule, setMinuteRunSchedule] = useState(currentTrigger?.pattern?.repeat?.value);
    const [hourlyRunSchedule, setHourlyRunSchedule] = useState(currentTrigger?.pattern?.repeat?.value);
    const [weeklyRunSchedule, setWeeklyRunSchedule] = useState(currentTrigger?.pattern?.repeat?.value);
    const [monthlyRunSchedule, setMonthlyRunSchedule] = useState(currentTrigger?.pattern?.repeat?.value);
    const [deleteScheduledRunTooltipId, setDeleteScheduledRunTooltipId] = useState(false);

    useEffect(() => {
        setTriggerState(currentTrigger.status);
    }, [currentTrigger]);

    const handleClose = () => {
        setTabIndex(0);
        setTriggerState('');
        onClose();
    };
    const destroyScheduledRun = (id) => {
        dispatch(TriggerModel.destroy(id));
        setTabIndex(0);
        onClose();
    };
    const handlePause = (id) => {
        dispatch(TriggerModel.pause(id));
        setTriggerState('inactive');
    };
    const handleResume = (id) => {
        dispatch(TriggerModel.activate(id));
        setTriggerState('active');
    };
    const handleUpdate = (form) => {
        dispatch(TriggerModel.update(currentTrigger?._id, { ...form }));
        setTabIndex(0);
    };
    const handleModelInputChange = (newInputs) => dispatch(ModelModel.updateLocal(model._id, { inputs: newInputs }));
    const handleDestroyScheduledRun = () => destroyScheduledRun(deleteScheduledRunTooltipId);
    const onSubmit = () => {
        setTabIndex(0);
        onClose();
        dispatch(TriggerModel.index());
    };

    const workflowTabs = [
        {
            label: 'Run Settings',
            component: (
                <div className="EditScheduledModalWorkflow1">
                    {triggerState === 'inactive' && (
                        <div>
                            <div className="EditScheduledModalPaused">
                                <Icon icon="large_color/paused" size="75px" />
                                Theis scheduled run is paused.
                                <Button
                                    className="resumeTriggerButton"
                                    gray
                                    onClick={() => {
                                        handleResume(currentTrigger?._id);
                                    }}
                                >
                                    Resume
                                </Button>
                            </div>
                            <hr />
                        </div>
                    )}
                    <div className="EditScheduledModalWorkflowInputs">
                        <Input
                            type="select"
                            id="RepeatID"
                            label="Repeats:"
                            required
                            value={repeatFrequency || currentTrigger?.pattern?.repeat?.unit} // for some odd reason just repeatFrequency alone doesn't render when opening a trigger
                            options={repeatOptions}
                            onChange={(_, value) => {
                                setRepeatFrequency(value);
                                handleUpdate({
                                    pattern: {
                                        ...currentTrigger.pattern,
                                        repeat: {
                                            ...currentTrigger.pattern.repeat,
                                            unit: value,
                                        },
                                    },
                                });
                            }}
                            gray
                        />

                        {(repeatFrequency
                            ? repeatFrequency === 'minutes'
                            : repeatFrequency === 'minutes' || currentTrigger?.pattern?.repeat?.unit === 'minutes') && (
                            <Input
                                type="number"
                                id="minuteRunSchedule"
                                label="Number of Minutes"
                                required
                                value={minuteRunSchedule || currentTrigger?.pattern?.repeat?.value}
                                onChange={(_, value) => {
                                    setMinuteRunSchedule(value);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            repeat: {
                                                ...currentTrigger.pattern.repeat,
                                                value,
                                            },
                                        },
                                    });
                                }}
                                gray
                            />
                        )}
                        {(repeatFrequency
                            ? repeatFrequency === 'hours'
                            : repeatFrequency === 'hours' || currentTrigger?.pattern?.repeat?.unit === 'hours') && (
                            <Input
                                type="number"
                                id="hourlyRunSchedule"
                                label="Number of Hours"
                                required
                                value={hourlyRunSchedule || currentTrigger?.pattern?.repeat?.value}
                                onChange={(_, value) => {
                                    setHourlyRunSchedule(value);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            repeat: {
                                                ...currentTrigger.pattern.repeat,
                                                value,
                                            },
                                        },
                                    });
                                }}
                                gray
                            />
                        )}
                        {(repeatFrequency
                            ? repeatFrequency === 'weeks'
                            : repeatFrequency === 'weeks' || currentTrigger?.pattern?.repeat?.unit === 'weeks') && (
                            <Input
                                type="multiSelect"
                                id="weeklyRunSchedule"
                                label="Days of the Week"
                                required
                                options={weeklyRunScheduleOptions}
                                value={
                                    weeklyRunSchedule ||
                                    [] ||
                                    currentTrigger?.pattern?.filter?.day_of_the_week.map((day) => String(day))
                                }
                                onChange={(_, value) => {
                                    setWeeklyRunSchedule(value);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            filter: {
                                                day_of_the_week: value.map(Number),
                                            },
                                        },
                                    });
                                }}
                                gray
                            />
                        )}
                        {(repeatFrequency
                            ? repeatFrequency === 'months'
                            : repeatFrequency === 'months' || currentTrigger?.pattern?.repeat?.unit === 'months') && (
                            <Input
                                type="number"
                                id="monthlyRunSchedule"
                                label="Days of the Month"
                                required
                                options={monthlyRunScheduleOptions}
                                value={monthlyRunSchedule || String(currentTrigger?.pattern?.filter?.day_of_the_month)}
                                onChange={(_, value) => {
                                    setMonthlyRunSchedule([value]);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            filter: {
                                                day_of_the_month: [value],
                                            },
                                        },
                                    });
                                }}
                                gray
                            />
                        )}

                        <Input
                            type="time"
                            id="RunStartTimeID"
                            label= {repeatFrequency==='hours' || repeatFrequency ==='minutes' ? "Between (start time):" : "Start Time"}
                            required
                            value={
                                runStartTime ||
                                moment(currentTrigger?.pattern?.start?.scheduled_starttime, 'h:mm A').format('hh:mm A')
                            }
                            onChange={(_, value) => {
                                setRunStartTime(value);
                                handleUpdate({
                                    pattern: {
                                        ...currentTrigger.pattern,
                                        start: {
                                            ...currentTrigger.pattern.start,
                                            scheduled_starttime: convertTime(value),
                                        },
                                    },
                                });
                            }}
                            gray
                        />

                        {(repeatFrequency
                            ? repeatFrequency === 'minutes' || repeatFrequency === 'hours'
                            : repeatFrequency === 'minutes' || repeatFrequency === 'hours' || currentTrigger?.pattern?.repeat?.unit === 'minutes' || currentTrigger?.pattern?.repeat?.unit === 'hours') && (
                            
                            <Input
                                type="time"
                                id="RunEndTimeID"
                                label="and (end time):"
                                required
                                value={
                                    runEndTime ||
                                    moment(currentTrigger?.pattern?.end?.scheduled_endtime, 'h:mm A').format('hh:mm A')
                                }
                                onChange={(_, value) => {
                                    setRunEndTime(value);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            end: {
                                                ...currentTrigger.pattern.end,
                                                scheduled_endtime: convertTime(value),
                                            },
                                        },
                                    });
                                }}
                                gray
                            />
                        )}

                        <Input
                            type="date"
                            id="RunStartDateID"
                            label="Starting on:"
                            required
                            value={runStartDate || currentTrigger?.pattern?.start?.effective_startdate}
                            onChange={(_, value) => {
                                setRunStartDate(value);
                                handleUpdate({
                                    pattern: {
                                        ...currentTrigger.pattern,
                                        start: {
                                            ...currentTrigger.pattern.start,
                                            effective_startdate: formatDate(value),
                                        },
                                    },
                                });
                            }}
                            gray
                        />
                        {(repeatFrequency
                            ? repeatFrequency !== 'never'
                            : currentTrigger?.pattern?.repeat?.unit !== 'never') && (
                            <Input
                                type="date"
                                id="RunStartTimeID"
                                label="Ending on:"
                                required
                                value={runEndDate || currentTrigger?.pattern?.start?.until_datetime}
                                onChange={(_, value) => {
                                    setRunEndDate(value);
                                    handleUpdate({
                                        pattern: {
                                            ...currentTrigger.pattern,
                                            start: {
                                                ...currentTrigger.pattern.start,
                                                until_datetime: formatDate(value),
                                            },
                                        },
                                    });
                                }}
                                info="Model runs can be scheduled up to a year in advance."
                                gray
                            />
                        )}
                    </div>
                    <hr />
                    <div className="EditScheduledModalWorkflowInputs">
                        <Input
                            type="text"
                            id="runName"
                            label="Run Name"
                            placeholder="Optional"
                            value={runName || currentTrigger?.name}
                            onChange={(_, value) => {
                                setRunName(value);
                                handleUpdate({ name: value });
                            }}
                            gray
                        />
                        {currentTrigger?.description || hasDescription ? (
                            <Input
                                id="description"
                                className="EditScheduledModal__description"
                                type="text"
                                gray
                                label="Description"
                                value={inputDescription || currentTrigger?.description}
                                onChange={(_, value) => {
                                    setInputDescription(value);
                                    handleUpdate({ description: value });
                                }}
                                submitOnBlur
                            />
                        ) : (
                            <IconButton
                                className="EditScheduledModal__addDescription"
                                icon="plus"
                                onClick={() => setHasDescription(true)}
                                label="Edit Description"
                            />
                        )}
                    </div>
                    {Object.values(currentTrigger).length > 0 && (
                        <div className="EditScheduledModal-editCurrent">
                            <hr />
                            <div className="EditScheduledModal-editCurrentOptions">
                                {triggerState === 'active' && (
                                    <Button
                                        className="pauseTriggerButton"
                                        gray
                                        onClick={() => {
                                            handlePause(currentTrigger?._id);
                                        }}
                                    >
                                        Pause Scheduled runs
                                    </Button>
                                )}
                                <IconButton
                                    className="deleteTriggerButton"
                                    icon="trash"
                                    label="Delete Run"
                                    onClick={() => setDeleteScheduledRunTooltipId(currentTrigger._id)}
                                >
                                    <ConfirmDeleteMenu
                                        open={!!deleteScheduledRunTooltipId}
                                        message="Remove this scheduled run?"
                                        onClose={() => setDeleteScheduledRunTooltipId(false)}
                                        onDelete={() => {
                                            handleDestroyScheduledRun();
                                            setDeleteScheduledRunTooltipId(false);
                                        }}
                                    />
                                </IconButton>
                            </div>
                            <Button
                                className="updateTriggerButton"
                                blue
                                onClick={() => {
                                    onSubmit();
                                }}
                            >
                                Done
                            </Button>
                        </div>
                    )}
                </div>
            ),
            complete: true,
            forwardButton: { id: 'submit', content: 'Continue', onClick: () => setTabIndex(1) },
        },
        {
            label: currentTrigger ? 'Input Configuration' : 'Schedule Run',
            component: (
                <div className="EditScheduledModalWorkflow2">
                    <CollapsibleSection
                        className="EditScheduledModal-collapsibleSection"
                        title="Model Run Inputs"
                        line
                        expanded
                    >
                        {primaryInputs.length > 0 ? (
                            <div className="EditScheduledModalWorkflowInputs">
                                {primaryInputs.map((input) => (
                                    <ModelInput
                                        key={input.varName}
                                        inputs={inputs}
                                        input={input}
                                        onInputsChange={handleModelInputChange}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="EditScheduledModalWorkflowInputs--emptyLabel">
                                There are no input settings for this model.
                            </div>
                        )}
                    </CollapsibleSection>
                    <CollapsibleSection
                        className="EditScheduledModal-collapsibleSection"
                        title="Advanced Settings"
                        line
                    >
                        {secondaryInputs.length > 0 ? (
                            <div className="EditScheduledModalWorkflowInputs">
                                {secondaryInputs.map((input) => (
                                    <ModelInput
                                        key={input.varName}
                                        inputs={inputs}
                                        input={input}
                                        onInputsChange={handleModelInputChange}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="EditScheduledModalWorkflowInputs--emptyLabel">
                                There are no advanced input settings for this model.
                            </div>
                        )}
                    </CollapsibleSection>
                </div>
            ),
            complete: tabIndex === 1,
            backButton: { id: 'back', content: 'Back', onClick: () => setTabIndex(0) },
            forwardButton: { id: 'submit', content: 'Done', onClick: onSubmit },
        },
    ];

    return (
        open && ( // Conditionally render the modal only when 'open' is true
            <ModalCard
                key={currentTrigger._id} // Use a unique key based on currentTrigger ID
                className="EditScheduledModal"
                open={open}
                onClose={handleClose}
                title="Schedule Model Runs - sdfsdf"
                // title={`Schedule Model Runs - ${model.name}`}
            >
                <TabHeader tabs={workflowTabs} onSelect={setTabIndex} tabIndex={tabIndex} />
                <div className="EditScheduledModalWorkflow">{workflowTabs[tabIndex].component}</div>
            </ModalCard>
        )
    );
}


EditScheduledModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    model: PropTypes.shape({
        _id: PropTypes.string,
        projectId: PropTypes.string,
        name: PropTypes.string,
        inputs: PropTypes.array,
    }),
    currentTrigger: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        status: PropTypes.string,
        pattern: PropTypes.shape({
            start: PropTypes.shape({
                effective_startdate: PropTypes.string,
                scheduled_starttime: PropTypes.string,
                scheduled_endtime: PropTypes.string,
                until_datetime: PropTypes.string,
            }),
            repeat: PropTypes.shape({
                unit: PropTypes.string,
                value: PropTypes.number,
            }),
            filter: PropTypes.shape({
                day_of_the_week: PropTypes.arrayOf(PropTypes.number),
                day_of_the_month: PropTypes.arrayOf(PropTypes.number),
            }),
        }),
    }),
};
EditScheduledModal.defaultProps = {
    open: false,
    onClose: () => {},
    model: {},
    currentTrigger: {},
};
