import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../shared';

export default function FormTitleMapping({ element, updateElement, editable }) {
    const { config } = element;
    const [title, setTitle] = useState(config.title);
    const [description, setDescription] = useState(config.description);
    const updateConfig = (id, value) => updateElement({ type: 'title', config: { ...config, [id]: value } });

    return (
        <div className="FormTitle">
            <Input
                id="title"
                type="text"
                gray
                label="Form Title (as Shown to Respondent)"
                value={title}
                onChange={(_, value) => setTitle(value)}
                onSubmit={updateConfig}
                submitOnBlur
                disabled={!editable}
                className="FormTitle__titleInput"
            />
            <Input
                id="description"
                type="text"
                gray
                label="Description (as Shown to Respondent)"
                value={description}
                onChange={(_, value) => setDescription(value)}
                onSubmit={updateConfig}
                submitOnBlur
                disabled={!editable}
            />
        </div>
    );
}

FormTitleMapping.propTypes = {
    element: PropTypes.shape({
        config: PropTypes.shape({ title: PropTypes.string, description: PropTypes.string }).isRequired,
    }).isRequired,
    updateElement: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
};

FormTitleMapping.defaultProps = {};
