import { getSerumFormatter } from '../../../serum';

export const getKpiData = (serum, datum, features) => {
    if (!serum) return { label: '', value: '' };

    const { label, unitOfMeasure } = serum;
    if (datum === undefined || datum === null) return { label, value: '--' };

    const feature = serum.feature && features.byId[serum.feature._id];
    if (!feature) return { label, value: '--' };

    let value = getSerumFormatter(serum, features)(datum[serum.id]);
    if (value === undefined || value === null || Number.isNaN(value)) value = '--';

    return { label, value, unitOfMeasure };
};
