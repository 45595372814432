import ApiBase from '../../../../models/base/api';
import { createQueryString } from '../../../../models/base/api/helpers';

export class ProjectApi extends ApiBase {
    constructor() {
        super('projects');
    }

    create = (form, options) => {
        const searchString = createQueryString(options);
        return this.request('POST', `${this.endpoint}${searchString}`, form);
    };

    listMy = () => this.request('GET', `${this.endpoint}/my`);

    listByOrganization = (organizationId) => this.request('GET', `${this.endpoint}/organizations/${organizationId}`);

    transferTo = (projectId, form) => {
        const { fromProjectName, toProjectId, ...restOfForm } = form;
        return this.request('PUT', `${this.endpoint}/${projectId}/transferTo/${toProjectId}`, restOfForm);
    };
}

export default new ProjectApi();
