import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Loader from '../Loaders';

require('./styles.scss');

export default function Logo({ logo, hydrated, small }) {
    const [imageBroken, setImageBroken] = useState(false);
    const handleError = () => setImageBroken(true);

    let logoDisplay;
    if (logo === '' || imageBroken) {
        logoDisplay = hydrated ? <div /> : <Loader type="content" loader="content" />;
    } else {
        logoDisplay = <img src={logo} alt="logo" onError={handleError} />;
    }

    return (
        <div
            className={ClassNames('Logo', {
                'Logo--small': small,
            })}
        >
            <span className="Logo__inner"> {logoDisplay} </span>
        </div>
    );
}

Logo.propTypes = {
    logo: PropTypes.string,
    hydrated: PropTypes.bool,
    small: PropTypes.bool,
};

Logo.defaultProps = {
    logo: '',
    hydrated: true,
    small: false,
};
