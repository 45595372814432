/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, Input } from '../../../../../shared';
import UserRoles from '../../UserRoles';
import UserLogo from '../../../User/Logo';
import UserModel from '../../../../_models/users';

require('./styles.scss');

export default function UserProfile(props) {
    const { user, onSubmit, myRoles, onRoleChange, resendValidationEmail, showUserAdmin } = props;
    const [resetEmailToken, setEmailToken] = useState(false);

    const dispatch = useDispatch();

    const resendEmail = () => {
        resendValidationEmail().then(({ user: returnedUser }) => setEmailToken(returnedUser.reset.token));
    };

    // todo
    // replace user icons with userlogo
    // change proptype to string

    return (
        <div className="UserProfile">
            <Form
                onSubmit={(form) => {
                    const { first, last } = form;
                    if (form.first !== user.name.first || form.last !== user.name.last) {
                        onSubmit({ name: { first, last } });
                    }
                }}
                loading={!user || !user.email}
                className="UserProfile__details"
            >
                <div className="UserProfile__info">
                    <Input
                        type="text"
                        id="first"
                        label="First Name"
                        gray
                        submitOnBlur
                        value={user.name.first}
                        required
                    />
                    <Input type="text" id="last" label="Last Name" gray submitOnBlur value={user.name.last} required />
                    <Input type="text" id="email" label="Email Address" disabled gray value={user.email} />
                </div>
                <div className="UserProfile__picture">
                    Profile Picture
                    <UserLogo
                        user={user}
                        updateLogo={(form) => dispatch(UserModel.updateLogo(user?._id, form))}
                        hydrated={user.hydrated}
                        large
                    />
                </div>
            </Form>
            <hr />
            {showUserAdmin ? (
                <React.Fragment>
                    <UserRoles userRoles={user.roles} myRoles={myRoles} setUserRoles={onRoleChange} />
                    {user.needsReset && (
                        <div>
                            <p>User has not yet verified their email address.</p>
                            <Button id="resendEmail" blue onClick={resendEmail} disabled={!!resetEmailToken}>
                                {resetEmailToken ? 'Email Sent' : 'Resend Email'}
                            </Button>
                            {resetEmailToken && (
                                <p>
                                    Reset password link for <b>{user.email}</b>:<br /> {location.host}/login/reset/
                                    {resetEmailToken}
                                </p>
                            )}
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <UserRoles userRoles={user.roles} myRoles={myRoles} disabled />
            )}
        </div>
    );
}

UserProfile.propTypes = {
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.shape({
            first: PropTypes.string,
            last: PropTypes.string,
        }),
        logo: PropTypes.string,
        email: PropTypes.string,
        roles: PropTypes.shape({}),
        needsReset: PropTypes.bool,
        hydrated: PropTypes.bool,
    }),
    myRoles: PropTypes.shape({}).isRequired,
    onRoleChange: PropTypes.func.isRequired,
    resendValidationEmail: PropTypes.func.isRequired,
    showUserAdmin: PropTypes.bool,
};

UserProfile.defaultProps = {
    onSubmit: () => {},
    user: {},
    showUserAdmin: false,
};
