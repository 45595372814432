import React, { useState } from 'react';
import { EventEmitter } from 'events';
import { showNotificationPopUp } from '../../shared/NotificationPopUp/helpers';
import { SocketTypes } from './socket_types';
import { ModelModel, PackageModel } from '../../Models/_models';

// Create the EventEmitter instance
const emitter = new EventEmitter();

export default function actionCreator(payload) {
    const { task, status, updatedObj, message } = payload;
    switch (task) {
        case 'modelRun':
            return (dispatch, getState) => {
                const models = getState().models;
                if (status === 'success') {
                    showNotificationPopUp({
                        type: 'success',
                        title: 'Model Run Complete:',
                        subTitle: `${models.byId[updatedObj.modelId].name}`,
                        autoDismiss: 7000,
                    });
                }
                if (status === 'failed') {
                    showNotificationPopUp({
                        type: 'error',
                        title: 'Model Run Failed:',
                        subTitle: `${models.byId[updatedObj.modelId].name}`,
                        autoDismiss: 7000,
                    });
                }
                dispatch({ type: SocketTypes.MODEL_RUN, modelRun: updatedObj, id: updatedObj._id });
            };
        case 'modelUpdate':
            return (dispatch, getState) => {
                if (status === 'success' && updatedObj) {
                    updatedObj.forEach((m) => {
                        dispatch({ type: SocketTypes.MODEL_UPDATE, model: m, id: m._id });
                    });
                }
                if (status === 'failed') {
                    showNotificationPopUp({
                        type: 'error',
                        title: 'Source Code Update Failed:',
                    });
                }
            };
        case 'packageUpload':
            return (dispatch, getState) => {
                const packages = getState().packages;
                if (packages.byId[updatedObj?._id]?.status !== 'built' && payload.status === 'built') {
                    showNotificationPopUp({
                        type: 'success',
                        title: 'Source code uploaded:',
                        subTitle: `${updatedObj.name}`,
                        autoDismiss: 7000,
                    });
                }
                if (packages.byId[payload._id]?.status !== 'failed' && payload.status === 'failed') {
                    showNotificationPopUp({
                        type: 'error',
                        title: 'Source code upload failed:',
                        subTitle: message,
                    });
                }
                dispatch({
                    type: SocketTypes.PACKAGE_UPDATE,
                    payload: updatedObj,
                    _id: updatedObj._id,
                });
            };

        case 'packageUpdate':
            return (dispatch, getState) => {
                const packages = getState().packages;

                if (status === 'started') {
                    showNotificationPopUp({
                        type: 'started',
                        title: 'Source code update in progress...',
                        subTitle: message,
                        autoDismiss: 3000,
                    });
                }
                if (status === 'success') {
                    showNotificationPopUp({
                        type: 'success',
                        title: 'Source code updated successfully, COMMIT these changes to make them persist',
                        subTitle: message,
                        primaryButtonLabel: 'Commit Changes',
                        primaryButtonOnClick: () => {
                            dispatch(PackageModel.commitPackageImage(updatedObj._id));
                        },
                    });
                }

                if (status === 'failed') {
                    showNotificationPopUp({
                        type: 'failed',
                        title: 'Source code update failed',
                        subTitle: message,
                        primaryButtonLabel: 'Revert Changes',
                        secondaryButtonLabel: 'Continue Editing Code',
                        primaryButtonOnClick: () => {
                            emitter.emit("resetCodeEditor", updatedObj._id);  // Emit event with specific ID
                        },
                        secondaryButtonOnClick: () => {
                            emitter.emit("openCodeEditor", updatedObj._id);  // Emit event with specific ID
                        }
                    });
                }
            };
        case 'environmentVariableUpdate':
            return (dispatch, getState, displayNotification) => {
                if (status === 'started') {
                    showNotificationPopUp({
                        type: 'started',
                        title: 'Updating environment variables...',
                        autoDismiss: 3000,
                    });
                }
                if (status === 'success') {
                    showNotificationPopUp({
                        type: 'success',
                        title: 'Environment variables updated successfully',
                        autoDismiss: 4000,
                    });
                }
                if (status === 'failed') {
                    showNotificationPopUp({
                        type: 'error',
                        title: 'Environment variables update failed',
                        subTitle: message,
                    });
                }
            };
        case 'dependencyUpdate':
            return (dispatch, getState) => {
                if (status === 'started') {
                    showNotificationPopUp({
                        type: 'started',
                        title: 'Updating package dependencies...',
                        autoDismiss: 3000,
                    });
                }
                if (status === 'success') {
                    showNotificationPopUp({
                        type: 'success',
                        title: 'Package dependencies updated successfully, COMMIT these changes to make them persist',
                        subTitle: message,
                        primaryButtonLabel: 'Commit Changes',
                        primaryButtonOnClick: () => {
                            dispatch(PackageModel.commitPackageImage(updatedObj._id));
                        },
                    });
                }
                if (status === 'failed') {
                    showNotificationPopUp({
                        type: 'error',
                        title: 'Package dependencies update failed',
                        subTitle: message,
                    });
                }
            };

        case 'upload':
            return { type: SocketTypes.UPLOAD, job: payload };
        case 'featureDetection':
            return { type: SocketTypes.FEATURES, job: payload };
        case 'import':
            return { type: SocketTypes.IMPORT, job: payload };
        case 'append':
            return { type: SocketTypes.APPEND, job: payload };
        default:
            return { ...payload, type: `SOCKET_${task}` };
    }
}

export { emitter };
