/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TabModel from '../../Dashboards/_models/tabs';
import UnitSelectors from '../_model/selectors';
import UnitModel from '../_model';
import {
    Card,
    Icon,
    IconButton,
    ToolTip,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    ConfirmDeleteMenu,
} from '../../shared';
import FilterSummary from '../../Data/Filter/Summary';
import TypeSelection from '../components/TypeSelection';
import ReportUnit from './ReportUnit';

require('./styles.scss');

export default function ReportCard(props) {
    const { dashboardId, sectionId, unitIds: initialUnitIds, section, editable, currentSectionId, publicFilters } = props;
    const isPublicView = useSelector((state) => state.app.account?.public);
    const modelIds = useSelector((state) => UnitSelectors.getModelIds(initialUnitIds)(state));
    const models = useSelector((state) => state.models.byId);
    const units = useSelector((state) => state.units.byId);
    const tabs = useSelector((state) => state.tabs.byId);
    const jobId = 'latest';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [unitIds, setUnitIds] = useState(initialUnitIds);
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [showDeleteTooltip, setShowDeleteTooltip] = useState(false);

    // load all units properly
    const showTab = (id) => id && dispatch(TabModel.show(id));
    useEffect(() => {
        showTab(sectionId);
    }, []);

    const handleMove = (unitId, direction) => {
        const index = unitIds.findIndex((e) => unitId === e);
        if (direction === 'up' && index > 1) {
            [unitIds[index - 1], unitIds[index]] = [unitIds[index], unitIds[index - 1]];
        }
        if (direction === 'down') {
            [unitIds[index], unitIds[index + 1]] = [unitIds[index + 1], unitIds[index]];
        }
        const wrappedUnitIds = unitIds.map(item => ({ id: item }));
        dispatch(TabModel.update(sectionId, {units: wrappedUnitIds}));
    };

    const getModels = () => {
        const modelArray = [];
        Object.values(models).map((model) =>
            modelIds.forEach((id) => {
                if (id === model._id) {
                    modelArray.push(model);
                }
            }),
        );
        return modelArray;
    };
    const cardModelTag = () => {
        const cardModels = getModels();
        const tooltipText = [];
        let modelPulsing = false;
        if (cardModels.length !== 0) {
            cardModels.forEach((model) => {
                tooltipText.push(model.name);
                if (model?.running) modelPulsing = true;
            });
        }
        return (
            cardModels.length !== 0 && (
                <ToolTip tooltip={tooltipText.toString() || ''} place="bottom">
                    <Icon
                        id="model"
                        icon="brain"
                        selected={currentSectionId === sectionId}
                        pulsing={modelPulsing}
                        size="20px"
                    />
                </ToolTip>
            )
        );
    };
    const cardFilterTag = () => {
        let chartOrTableFilter = false;
        let kpiFilter = false;
        const filterSummary = [];
        for (let i = 0; i < unitIds.length; i++) {
            if (units[unitIds[i]]?.vis?.filters?.length > 0) {
                chartOrTableFilter = true;
                filterSummary.push(units[unitIds[i]].vis?.filters[0]);
            }
            if (units[unitIds[i]]?.vis?.kpi?.find((kpi) => kpi.filters?.length > 0)) {
                kpiFilter = true;
                const filters = units[unitIds[i]]?.vis?.kpi?.find((kpi) => kpi.filters).filters;
                filters.forEach((filter) => filterSummary.push(filter));
            }
        }
        const unitHasFilter = chartOrTableFilter || kpiFilter;
        const chartFilterSummary = (
            <React.Fragment>
                <FilterSummary key={sectionId} value={filterSummary} />
            </React.Fragment>
        );
        return (
            unitHasFilter && (
                <ToolTip tooltip={chartFilterSummary} place="bottom">
                    <Icon id="filter" icon="filter" selected={currentSectionId === sectionId} size="20px" />
                </ToolTip>
            )
        );
    };
    const openMapping = (type, unitId) => {
        let path = `/projects/${projectId}/units/${unitId}/mapping?tab=${sectionId}`;
        if (type) path = `${path}&type=${type}`;
        navigate(path);
    };
    const chooseUnitType = (type, unitId) => openMapping(type, unitId);
    
    const handleDuplicate = (unitId) => {
        dispatch(TabModel.duplicateUnit(sectionId, unitId, {}, (newDuplicatedUnitId) => {
            setUnitIds([...unitIds, newDuplicatedUnitId]);
        }));
    };

    const handleDownload = (unitId) => dispatch(UnitModel.download(unitId, { jobId, tabId: sectionId }));
    const handleCustom = (unitId) => openMapping('custom', unitId);
    const onUnitCreate = () => {
        dispatch(TabModel.createUnit(sectionId, { layoutItem: { x: 6, y: 3, w: 6, h: 3 } }, (newUnitId) => {
            setUnitIds([...unitIds, newUnitId]);
        }));
    };
        

    const renderCardHeader = () => (
        <header className="ReportCard__header">
            {cardModelTag()}
            {cardFilterTag()}
            {tabs[sectionId]?.name}
        </header>
    );

    const renderUnitHeader = (unitId) => (
        <header className="ReportCard__unitHeader">
            <IconButton
                id="mappingIcon"
                icon="edit"
                tooltip="Edit"
                onClick={() => openMapping(units[unitId]?.type, unitId)}
                small
            />
            {isPublicView ||
            (!editable && (units[unitId]?.type === 'kpi' || units[unitId]?.type === 'custom')) ? null : (
                <IconButton
                    id="menu"
                    icon="menu-vertical"
                    onClick={() => {
                        setOptionsMenuOpen(unitId);
                        setShowDeleteTooltip(false);
                    }}
                    active={!!optionsMenuOpen}
                    small
                />
            )}
            <ContextMenu
                className="UnitCard__menu"
                open={unitId === optionsMenuOpen}
                onClickOut={() => setOptionsMenuOpen(false)}
            >
                <ContextSubMenu>
                    {editable && (
                        <ContextMenuItem
                            id="moveUp"
                            name="Move Up"
                            // type={<Icon icon="arrow-up" size="16px" />}
                            onClick={() => {
                                handleMove(unitId, 'up');
                                setOptionsMenuOpen(false);
                            }}
                        />
                    )}
                    {editable && (
                        <ContextMenuItem
                            id="moveDown"
                            name="Move Down"
                            // type={<Icon icon="arrow-down" size="16px" />}
                            onClick={() => {
                                handleMove(unitId, 'down');
                                setOptionsMenuOpen(false);
                            }}
                        />
                    )}
                    {editable && (
                        <ContextMenuItem
                            id="duplicate"
                            name="Duplicate"
                            onClick={() => {
                                handleDuplicate(unitId);
                                setOptionsMenuOpen(false);
                            }}
                        />
                    )}
                    {(units[unitId]?.type === 'chart' || units[unitId]?.type === 'table') &&
                    !units[unitId]?.data?.error ? (
                        <ContextMenuItem
                            id="download-csv"
                            name="Download as CSV"
                            onClick={() => {
                                handleDownload(unitId);
                                setOptionsMenuOpen(false);
                            }}
                        />
                    ) : null}
                    {editable && (
                        <ContextMenuItem id="delete" name="Delete" onClick={() => setShowDeleteTooltip(unitId)}>
                            <ConfirmDeleteMenu
                                open={!!showDeleteTooltip}
                                onClose={() => setShowDeleteTooltip(false)}
                                onDelete={() => {
                                    dispatch(TabModel.destroyUnit(sectionId, unitId));
                                    setOptionsMenuOpen(false);
                                    setUnitIds(unitIds.filter(id => id !== unitId));
                                }}
                            />
                        </ContextMenuItem>
                    )}
                    {editable && !units[unitId]?.type && (
                        <ContextMenuItem id="custom" name="Beta Card Types" onClick={() => handleCustom(unitId)} />
                    )}
                </ContextSubMenu>
            </ContextMenu>
        </header>
    );

    return (
        <div className="ReportCard" id={sectionId}>
            {section?.hydrated && unitIds.length > 0 && (
                <Card>
                    {renderCardHeader()}
                    <main className="ReportCard__main">
                        {unitIds.map((unitId) => (
                            <div className="ReportCard__ReportUnit" key={unitId}>
                                {renderUnitHeader(unitId)}
                                {units[unitId]?.type === undefined ? (
                                    <TypeSelection
                                        height={4}
                                        editable={editable}
                                        onSelect={(type) => {
                                            chooseUnitType(type, unitId);
                                        }}
                                    />
                                ) : (
                                    <ReportUnit
                                        id={unitId}
                                        tabId={sectionId}
                                        dashboardId={dashboardId}
                                        publicFilters={publicFilters}
                                    />
                                )}
                            </div>
                        ))}
                        <Card className="Report__AddCard">
                            <div className="Report__AddCard__Button" onClick={onUnitCreate} role="button" tabIndex={0}>
                                <Icon icon="plus" size="16px" />
                            </div>
                        </Card>
                    </main>
                </Card>
            )}
            {unitIds.length === 0 && (
                <Card className="Report__EmptyCard">
                    <div className="Report__EmptyCard__Button" onClick={onUnitCreate} role="button" tabIndex={0}>
                        <Icon icon="plus" size="16px" />
                    </div>
                    <div className="Report__EmptyCard__Text">Add a visualization to get started.</div>
                </Card>
            )}
        </div>
    );
}

ReportCard.propTypes = {
    sectionId: PropTypes.string,
    dashboardId: PropTypes.string,
    currentSectionId: PropTypes.string,
    editable: PropTypes.bool,
    publicFilters: PropTypes.array,
    unitIds: PropTypes.array,
    section: PropTypes.shape({
        units: PropTypes.array,
        hydrated: PropTypes.bool,
    }),
};

ReportCard.defaultProps = {
    sectionId: null,
    dashboardId: null,
    currentSectionId: null,
    editable: true,
    publicFilters: [],
    unitIds: [],
    section: {},
};
