import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SubNavBarContext = React.createContext({ value: false, setNavBarContext: () => {} });

const SubNavBarProvider = ({ children }) => {
    const [hasSubNav, setHasSubNav] = useState(false);
    return <SubNavBarContext.Provider value={{ hasSubNav, setHasSubNav }}>{children}</SubNavBarContext.Provider>;
};

SubNavBarProvider.propTypes = {
    children: PropTypes.node,
};
SubNavBarProvider.defaultProps = {
    children: PropTypes.node,
};

export { SubNavBarContext, SubNavBarProvider };
