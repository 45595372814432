import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FeatureModel from '../../_models/feature';
import IconButtonFilterSkin from './skin';

// TODO: stop using this?
// This component is used in several places (it used to be VerticalFilter), so I didn't want to paste its code 4 or 5 times in order to get rid of it. Some of this code is also duplicated in the Filter component I think.
// Should filters know about datasets / features?
export default function IconButtonFilter(props) {
    const dispatch = useDispatch();

    const datasets = useSelector((state) => state.datasets);
    const features = useSelector((state) => state.features);

    const loadCategories = (id) => dispatch(FeatureModel.categories(id)); // load categories if not already there

    const handleFeatureSelect = (id) => {
        const feature = features.byId[id];
        if (feature.type === 'category' && !feature.categories?.length && !feature.loading) loadCategories(id);
    };

    return (
        <IconButtonFilterSkin
            {...props}
            datasets={datasets}
            features={features}
            onFeatureSelect={handleFeatureSelect}
        />
    );
}
