import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, IconButton, ModalCard } from '../../../../shared';
import { getMainChartType, xyChartTypes, otherChartTypes } from '../../helpers';
import IconButtonFilter from '../../../../Data/Filter/IconButtonFilter';

require('./styles.scss');

export default function ChartHeader(props) {
    const {
        children,
        unit,
        onChange,
        disableFullSettings,
        toggleSettings,
        settingsOpen,
        datasetIds,
        handleFilterChange,
    } = props;
    const { vis } = unit;
    const mainChartType = getMainChartType(vis);

    const [proposedChartType, setProposedChartType] = useState(false);
    const discardChartWarningModal = (
        <ModalCard
            className="ChartHeader__chartChangeWarningModal"
            title="Discard chart setup?"
            secondaryButtonOptions={{ content: 'Cancel', onClick: () => setProposedChartType(false) }}
            open={!!proposedChartType}
            onClose={() => setProposedChartType(false)}
            redButtonOptions={{
                content: 'Start from Scratch',
                onClick: () => {
                    if (settingsOpen) toggleSettings();
                    onChange('mainChartType', proposedChartType);
                    setProposedChartType(false);
                },
            }}
        >
            <div>
                To make a {proposedChartType} chart, you will need to start from scratch. Do you want to continue?
            </div>
        </ModalCard>
    );

    function updateMainChartType(id, value) {
        const chartHasConfiguration =
            vis.dependent[0]?.series?.length ||
            vis.independent[0]?.series?.length ||
            vis.tooltip?.series?.length ||
            vis.groupBy?.length ||
            vis.orderBy?.length ||
            vis.otherDimensions[0]?.series.length ||
            vis.gradientDimension[0]?.series.length;
        const bothTypesAreXy =
            Object.keys(xyChartTypes).includes(vis.chartType) && Object.keys(xyChartTypes).includes(value);
        if (bothTypesAreXy || !chartHasConfiguration) {
            onChange(id, value);
        } else {
            setProposedChartType(value);
        }
    }

    const unitFilters = unit?.vis?.filters || [];

    return (
        <div className="ChartHeader">
            <header className="ChartHeader__header">
                <Input
                    buttons={Object.keys(xyChartTypes).map((chartType) => ({
                        id: chartType,
                        icon: xyChartTypes[chartType].icon,
                        tooltip: xyChartTypes[chartType].tooltip,
                    }))}
                    light
                    id="mainChartType"
                    type="buttonList"
                    value={mainChartType}
                    onChange={updateMainChartType}
                />
                <Input
                    buttons={Object.keys(otherChartTypes).map((chartType) => ({
                        id: chartType,
                        icon: otherChartTypes[chartType].icon,
                        tooltip: otherChartTypes[chartType].tooltip,
                    }))}
                    light
                    id="mainChartType"
                    type="buttonList"
                    value={mainChartType}
                    onChange={updateMainChartType}
                />
                <div className="ChartHeader__controls">
                    <IconButtonFilter
                        onChange={handleFilterChange}
                        value={unitFilters}
                        datasetIds={datasetIds}
                        label={unitFilters.length ? `Filter (${unitFilters.length})` : 'Filter'}
                        listHeading="Card Filters"
                    />
                    <IconButton
                        icon="settings"
                        onClick={toggleSettings}
                        label="Settings"
                        disabled={disableFullSettings}
                        active={settingsOpen}
                    />
                </div>
                {!!proposedChartType && discardChartWarningModal}
            </header>
            <main>{children}</main>
        </div>
    );
}

ChartHeader.propTypes = {
    children: PropTypes.node.isRequired,
    unit: PropTypes.shape({
        vis: PropTypes.shape({
            chartType: PropTypes.string,
            dependent: PropTypes.array,
            independent: PropTypes.array,
            tooltip: PropTypes.shape(),
            groupBy: PropTypes.array,
            orderBy: PropTypes.array,
            otherDimensions: PropTypes.array,
            gradientDimension: PropTypes.array,
            filters: PropTypes.array,
        }),
    }),
    onChange: PropTypes.func,
    disableFullSettings: PropTypes.bool,
    settingsOpen: PropTypes.bool,
    toggleSettings: PropTypes.func,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    handleFilterChange: PropTypes.func.isRequired,
};

ChartHeader.defaultProps = {
    unit: null,
    onChange: () => {},
    disableFullSettings: false,
    settingsOpen: false,
    toggleSettings: () => {},
    datasetIds: [],
};
