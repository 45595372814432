import React, { useState } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, Icon, Input } from '../../../../shared';
import { getRoleString, presetRoles } from '../../../constants';

require('./styles.scss');

export default function UserRoles(props) {
    const { setUserRoles, disabled, userRoles, myRoles } = props;
    const onChange = (id, value) => setUserRoles({ ...userRoles, [id]: value });

    const [showMessage, setShowMessage] = useState(false);

    const roleOptions = [
        { label: presetRoles.userRoles.name, value: presetRoles.userRoles.shortName },
        { label: presetRoles.adminRoles.name, value: presetRoles.adminRoles.shortName },
        { label: presetRoles.viewerRoles.name, value: presetRoles.viewerRoles.shortName },
    ];
    if (myRoles.manageAllOrganizations) {
        // only White Whale users can create other White Whale users
        roleOptions.push({ label: presetRoles.rootRoles.name, value: presetRoles.rootRoles.shortName });
    }

    const currentPresetRole = getRoleString(userRoles, { short: true });

    return (
        <div
            className={ClassNames('UserRoles', {
                'UserRoles--disabled': disabled,
            })}
        >
            <div className="UserRoles__select">
                <Input
                    id="roles"
                    type="select"
                    options={roleOptions}
                    label="Role"
                    placeholder="Custom Roles"
                    value={currentPresetRole}
                    onChange={(_, value) => setUserRoles(presetRoles[value].roles)}
                    required
                    disabled={disabled}
                    gray
                />
                <IconButton mini icon="settings" onClick={() => setShowMessage(!showMessage)} />
                {showMessage && currentPresetRole && (
                    <div className="UserRoles__description">{presetRoles[currentPresetRole].description}</div>
                )}
            </div>
            {showMessage && (
                <div className="UserRoles__table">
                    <div className="UserRoles__header">
                        <div className="projects__header">
                            <div className="text">Projects</div>
                        </div>
                        <div className="admin__header">
                            <Icon size="16px" icon="settings" />
                            <div className="text">Administrative Settings</div>
                        </div>
                    </div>
                    <div className="UserRoles__options">
                        <div className="UserRoles__radio">
                            <Input
                                id="createProjects"
                                type="radio"
                                options={[
                                    {
                                        id: '1',
                                        label: 'View-Only',
                                        value: false,
                                    },
                                    {
                                        id: '2',
                                        label: 'Create/Edit Projects',
                                        value: true,
                                    },
                                ]}
                                onChange={onChange}
                                value={userRoles.createProjects}
                                disabled={disabled || !myRoles.createProjects}
                            />
                        </div>
                        <div className="UserRoles__checkBox">
                            <div className="col1">
                                <Input
                                    id="editOrganizationSettings"
                                    type="checkbox"
                                    label="Edit Organization Settings"
                                    value={userRoles.editOrganizationSettings}
                                    disabled={disabled || !myRoles.editOrganizationSettings}
                                    onChange={onChange}
                                    inlineLabel
                                    labelRight
                                />
                                <Input
                                    id="manageOrganizationUsers"
                                    type="checkbox"
                                    label="Create/Edit User Accounts"
                                    value={userRoles.manageOrganizationUsers}
                                    disabled={disabled || !myRoles.manageOrganizationUsers}
                                    onChange={onChange}
                                    inlineLabel
                                    labelRight
                                />
                                <Input
                                    id="manageOrganizationProjects"
                                    type="checkbox"
                                    label="Manage All Projects"
                                    value={userRoles.manageOrganizationProjects}
                                    disabled={disabled || !myRoles.manageOrganizationProjects}
                                    onChange={onChange}
                                    inlineLabel
                                    labelRight
                                />
                            </div>
                            <div className="col2">
                                <Input
                                    id="manageClientOrganizations"
                                    type="checkbox"
                                    label="Create/Edit Client Organizations"
                                    value={userRoles.manageClientOrganizations}
                                    disabled={disabled || !myRoles.manageClientOrganizations}
                                    onChange={onChange}
                                    inlineLabel
                                    labelRight
                                />
                                <Input
                                    id="viewOrganizationLogins"
                                    type="checkbox"
                                    label="View Login History"
                                    value={userRoles.viewOrganizationLogins}
                                    disabled={disabled || !myRoles.viewOrganizationLogins}
                                    onChange={onChange}
                                    inlineLabel
                                    labelRight
                                />
                                {myRoles.manageAllOrganizations && (
                                    <Input
                                        id="manageAllOrganizations"
                                        type="checkbox"
                                        label="Manage All Organizations/Users"
                                        value={userRoles.manageAllOrganizations}
                                        disabled={disabled || !myRoles.manageAllOrganizations}
                                        onChange={onChange}
                                        inlineLabel
                                        labelRight
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

UserRoles.propTypes = {
    setUserRoles: PropTypes.func,
    disabled: PropTypes.bool,
    userRoles: PropTypes.shape({
        createProjects: PropTypes.bool,
        editOrganizationSettings: PropTypes.bool,
        manageOrganizationUsers: PropTypes.bool,
        manageOrganizationProjects: PropTypes.bool,
        manageClientOrganizations: PropTypes.bool,
        viewOrganizationLogins: PropTypes.bool,
        manageAllOrganizations: PropTypes.bool,
    }).isRequired,
    myRoles: PropTypes.shape({
        createProjects: PropTypes.bool,
        editOrganizationSettings: PropTypes.bool,
        manageOrganizationUsers: PropTypes.bool,
        manageOrganizationProjects: PropTypes.bool,
        manageClientOrganizations: PropTypes.bool,
        viewOrganizationLogins: PropTypes.bool,
        manageAllOrganizations: PropTypes.bool,
    }).isRequired,
};
UserRoles.defaultProps = {
    disabled: false,
    setUserRoles: () => {},
};
