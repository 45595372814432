export const defaultRoles = {
    full: {
        dashboard: 'edit',
        dataset: 'edit',
        form: 'edit',
        model: 'edit',
        package: 'edit',
        editMembers: true,
        editProject: true,
    },
    edit: {
        dashboard: 'edit',
        dataset: 'edit',
        form: 'edit',
        model: 'edit',
        package: 'edit',
        editMembers: false,
        editProject: false,
    },
    view: {
        dashboard: 'view',
        dataset: 'view',
        form: 'view',
        model: 'view',
        package: 'view',
        editMembers: false,
        editProject: false,
    },
    viewDashboards: {
        dashboard: 'view',
        dataset: 'hidden',
        form: 'hidden',
        model: 'hidden',
        package: 'hidden',
        editMembers: false,
        editProject: false,
    },
};

export const defaultRolesText = {
    full: {
        label: 'Full Access',
        value: 'full',
        description: 'Can manage this project, and edit everything in it.',
        roles: defaultRoles.full,
    },
    edit: {
        label: 'Contributor',
        value: 'edit',
        description: 'Can edit everything in the project, but can’t manage members or delete it.',
        roles: defaultRoles.edit,
    },
    view: {
        label: 'Viewer',
        value: 'view',
        description: 'Can view everything in the project, but can’t edit it.',
        roles: defaultRoles.view,
    },
    viewDashboards: {
        label: 'View Dashboards',
        value: 'viewDashboards',
        description: 'Can only view the dashboards in this project.',
        roles: defaultRoles.viewDashboards,
    },
    custom: {
        label: 'Custom Role',
        value: 'custom',
        description: 'Customized Permission Settings',
        roles: {
            dashboard: 'hidden',
            dataset: 'hidden',
            form: 'hidden',
            model: 'hidden',
            package: 'hidden',
            editMembers: false,
            editProject: false,
        },
    },
};
