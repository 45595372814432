import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../shared/Input';
import ContextMenu from '../../../../../shared/ContextMenu';
import ContextSubMenu from '../../../../../shared/ContextMenu/ContextSubMenu';
import { createOptionsArray } from '../../../../../shared/Input/helpers';

require('./styles.scss');

const ConstraintInput = (props) => {

    const { inputObj, readOnly, updateConstraint, options: optionsProp, value: valueProp } = props;
    const options = createOptionsArray(optionsProp);
    const defaultConstraint = { min: 0, max: 0, disabled: false };

    const [currentValue, setCurrentValue] = useState(
        options.reduce(
            (obj, option) => ({
                ...obj,
                [option.value]: { ...defaultConstraint, ...(valueProp ? valueProp[option.value] : {}) },
            }),
            {},
        ),
    );

    useEffect(() => {
      setCurrentValue(inputObj.value);
    }, [inputObj]);

    const updateConstraintValue = (key, updateObject) => {
        const updatedValue = { ...currentValue[key], ...updateObject };
        const updatedConstraint = { ...currentValue, [key]: updatedValue };
        setCurrentValue(updatedConstraint);
        updateConstraint(updatedConstraint);
    };

    const updateAllConstraints = (updateObject) => {
        const updatedConstraint = Object.keys(currentValue).reduce(
            (obj, key) => ({ ...obj, [key]: { ...currentValue[key], ...updateObject } }),
            {},
        );
        setCurrentValue(updatedConstraint);
        updateConstraint(updatedConstraint);
    };

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="ConstraintInput">
            <div className="ConstraintInput__input" onClick={() => setIsOpen(!isOpen)} role="button" tabIndex={-1}>
                <Input
                    {...props}
                    autosize={false} // otherwise the input will grow very large
                    type="text"
                    icon="optimize"
                    value={JSON.stringify(currentValue || {})}
                />
            </div>
            <ContextMenu
                className="ConstraintInput__menu"
                open={isOpen}
                onClickOut={() => setIsOpen(false)}
                listHeading={inputObj.name}
            >
                <div className="ConstraintInput__ignoreAll">
                    <Input
                        type="checkbox"
                        id="ignoreAll"
                        label="Ignore all"
                        inlineLabel
                        labelRight
                        dark
                        value={options.every((option) => currentValue[option.value]?.disabled)}
                        onChange={(id, value) => {
                            updateAllConstraints({ disabled: value });
                        }}
                        disabled={readOnly}
                    />
                </div>
                <div className="ConstraintInput__menuItems">
                    {options.map((option) => (
                        <ContextSubMenu key={option.value}>
                            <Input
                                id="enabled"
                                type="switch"
                                label={option.label}
                                dark
                                inlineLabel
                                labelRight
                                value={!currentValue[option.value]?.disabled}
                                onChange={(id, value) => {
                                    updateConstraintValue(option.value, { disabled: !value });
                                }}
                                disabled={readOnly}
                            />
                            <div className="ConstraintInput__minMax">
                                <Input
                                    id="min"
                                    type="number"
                                    label="Min"
                                    value={currentValue[option.value]?.min}
                                    max={currentValue[option.value]?.max}
                                    disabled={currentValue[option.value]?.disabled || inputObj.disabled || readOnly}
                                    dark
                                    onChange={(id, value) => {
                                        updateConstraintValue(option.value, { [id]: value });
                                    }}
                                />
                                <Input
                                    id="max"
                                    type="number"
                                    label="Max"
                                    value={currentValue[option.value]?.max}
                                    min={currentValue[option.value]?.min}
                                    disabled={currentValue[option.value]?.disabled || inputObj.disabled || readOnly}
                                    dark
                                    onChange={(id, value) => {
                                        updateConstraintValue(option.value, { [id]: value });
                                    }}
                                />
                            </div>
                        </ContextSubMenu>
                    ))}
                </div>
            </ContextMenu>
        </div>
    );
};

ConstraintInput.propTypes = {
    value: PropTypes.shape({}),
    inputObj: PropTypes.shape({
        name: PropTypes.string,
        disabled: PropTypes.bool,
        value: PropTypes.shape({}),
    }).isRequired,
    readOnly: PropTypes.bool,
    updateConstraint: PropTypes.func.isRequired,
    options: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            }),
        ),
    ]),
};

ConstraintInput.defaultProps = {
    value: {},
    readOnly: false,
    options: [],
};

export default ConstraintInput;
