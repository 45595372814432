import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, Button, ConfirmModal, Loader } from '../../../../../shared';
import SelectableBar from '../../../../../shared/SelectableBar';
import { fuzzySearch } from '../../../../../../helpers/string';
import { getRoleString } from '../../../../constants';
import CreateUserModal from './CreateUserModal';
import UserSettingsModal from '../../../_components/UserSettings/modal';
import UserIcon from '../../../User/UserIcon';

require('./styles.scss');

export default function OrganizationUsers({ updateUser, id, users, hydrated, errors, deleteUser, myUser, createUser }) {
    const [createUserModal, setCreateUserModal] = useState(false);
    const [filter, setFilter] = useState('');
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userSettingOpen, setUserSettingsOpen] = useState(false);

    const toggleCreateUserModal = () => {
        setCreateUserModal(!createUserModal);
    };

    const toggleDeleteModal = () => {
        setConfirmDeleteOpen(!confirmDeleteOpen);
    };

    const handleCreateUser = (form) => {
        const lowerCaseEmail = form.email.toLowerCase();
        createUser(id, { ...form, email: lowerCaseEmail }).then((action) => {
            if (!action.errors) setCreateUserModal(false);
        });
    };

    const filteredUsers = fuzzySearch(filter, users, ['name.first', 'name.last', 'email']);

    const ConfirmDeleteModal = (
        <ConfirmModal
            title="Delete User"
            onSubmit={() => deleteUser(selectedUserId)}
            open={confirmDeleteOpen}
            onClose={toggleDeleteModal}
        />
    );

    const emptyContent = hydrated ? (
        <div className="OrganizationUsers__empty">
            There are no <b>users</b> in this organization.
        </div>
    ) : (
        <Loader type="content" loader="content" />
    );

    return (
        <div className="OrganizationUsers">
            <div className="OrganizationUsers__header">
                <div className="OrganizationUsers__title">User Accounts</div>
                <Input
                    className="OrganizationUsers__Search"
                    type="search"
                    placeholder="Search users"
                    id="searchUsers"
                    onChange={(el, value) => setFilter(value)}
                    value={filter}
                    gray
                />
                <Button id="addUser" blue onClick={toggleCreateUserModal}>
                    Add User
                </Button>
                <CreateUserModal
                    onSubmit={handleCreateUser}
                    onClose={toggleCreateUserModal}
                    open={createUserModal}
                    hydrated={hydrated}
                    errors={errors}
                    myRoles={myUser ? myUser.roles : {}}
                />
            </div>
            <div className="OrganizationUsers__List">
                {filteredUsers.length
                    ? filteredUsers.map((user) => (
                          <SelectableBar
                              key={user._id}
                              title={`${user.name.first} ${user.name.last}`}
                              subTitle={user.needsReset ? <b>Unclaimed Account</b> : getRoleString(user.roles)}
                              info={`${user.email}`}
                              deactivated={!user.active}
                              logo={
                                  <UserIcon
                                      user={user}
                                      userId={user._id}
                                      firstName={user.name.first}
                                      lastName={user.name.last}
                                  />
                              }
                              onClick={() => {
                                  setSelectedUserId(user._id);
                                  setUserSettingsOpen(true);
                              }}
                              options={
                                  user._id === myUser._id
                                      ? []
                                      : [
                                            {
                                                id: 'deactivate',
                                                name: !user.active ? 'Reactivate User' : 'Deactivate User',
                                                onClick: () => {
                                                    updateUser(user._id, { active: !user.active });
                                                },
                                            },
                                            {
                                                id: 'delete',
                                                name: 'Delete User',
                                                onClick: () => {
                                                    setSelectedUserId(user._id);
                                                    setConfirmDeleteOpen(true);
                                                },
                                            },
                                        ]
                              }
                          />
                      ))
                    : emptyContent}
                {ConfirmDeleteModal}
                <UserSettingsModal
                    userId={selectedUserId}
                    organizationId={id}
                    open={userSettingOpen}
                    onClose={() => {
                        setUserSettingsOpen(false);
                        setSelectedUserId(null);
                    }}
                />
            </div>
        </div>
    );
}

OrganizationUsers.propTypes = {
    myUser: PropTypes.shape({
        _id: PropTypes.string,
        roles: PropTypes.shape({}),
    }).isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            roles: PropTypes.shape({}),
            _id: PropTypes.string,
            name: PropTypes.shape({
                first: PropTypes.string,
                last: PropTypes.string,
            }),
            email: PropTypes.string,
            active: PropTypes.bool,
        }),
    ),
    hydrated: PropTypes.bool,
    errors: PropTypes.shape({ name: PropTypes.arrayOf(PropTypes.shape({})) }),
    deleteUser: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    id: PropTypes.string,
    updateUser: PropTypes.func,
};

OrganizationUsers.defaultProps = {
    users: [],
    hydrated: false,
    errors: {},
    id: '',
    updateUser: () => {},
};
