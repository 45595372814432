import React, { useState, useEffect, useContext } from 'react';

/* eslint-disable */

import {
  MdClose,
  MdMenu,
  MdOutlineCoffee,
  MdOutlineVpnKey,
  MdDelete,
} from 'react-icons/md';
import { AiOutlineGithub } from 'react-icons/ai';
import { ChatContext } from '../../context/chatContext';
import bot from '../../assets/logo.svg';
import ToggleTheme from '../ToggleTheme';
import Modal from '../Modal';
import Setting from '../Settings';

import styles from './SideBar.module.css';

/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBar = () => {
  const [open, setOpen] = useState(true);
  const [, , clearChat] = useContext(ChatContext);
  const [modalOpen, setModalOpen] = useState(false);

  function handleResize() {
    window.innerWidth <= 720 ? setOpen(false) : setOpen(true);
  }

  useEffect(() => {
    handleResize();
  }, []);

  function clear() {
    clearChat();
  }

  return (
    <section className={`${open ? styles?.sidebaropen : ""} ${styles.section}`}>
      <div className={styles.headerFlex}>
        <div className={`${!open ? styles.hidden : ""}`}>
          <img src={bot} alt='logo' className={styles.logo} />
          <h1>GPT4</h1>
        </div>
        <div className={styles.btn} onClick={() => setOpen(!open)}>
          {open ? <MdClose size={15} /> : <MdMenu size={15} />}
        </div>
      </div>

      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <a onClick={clear}>
            <MdDelete size={15} />
            <p className={`${!open ? styles.hidden : ""}`}>Clear chat</p>
          </a>
        </li>
      </ul>

      <ul className={`${styles.menu} ${styles.bottomMenu}`}>
        <li>
          <ToggleTheme open={open} />
        </li>
        <li>
          <a onClick={() => setModalOpen(true)}>
            <MdOutlineVpnKey size={15} />
            <p className={`${!open ? styles.hidden : ""}`}>OpenAI Key</p>
          </a>
        </li>
      </ul>
      <Modal title='Setting' modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>
    </section>
  );
};
export default SideBar;
