import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';

import { AlgorithmModel, ModelModel, PackageModel, TriggerModel } from '../_models';
import { Button, Card, Icon, ConfirmDeleteMenu, Loader, ScrollArea, ToolTip } from '../../shared';
import SelectableItem from '../../shared/SelectableItem';
import { getModelState, getPackageState } from './helpers';
import UpdateModelModal from './modals/UpdateModel';
import UploadPackageModal from './modals/UploadPackage';
import EditScheduledModal from '../_components/EditScheduledModal';

require('./styles.scss');

const Models = () => {
    const [createPackageModal, setCreatePackageModal] = useState(false);
    const [updateModelModalId, setUpdateModelModalId] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);

    const [deletePackageTooltipId, setDeletePackageTooltipId] = useState(false);
    const [deleteModelTooltipId, setDeleteModelTooltipId] = useState(false);
    const [deleteScheduledRunTooltipId, setDeleteScheduledRunTooltipId] = useState(false);
    const [editScheduledRun, setEditScheduledRun] = useState(false);
    const [currentTrigger, setCurrentTrigger] = useState({});
    const navigate = useNavigate();
    const { projectId } = useParams();

    const { models, packages, algorithms, triggers, canEditModels, canEditPackages } = useSelector((state) => ({
        models: state.models,
        packages: state.packages,
        algorithms: state.algorithms,
        triggers: state.triggers,
        canEditModels: state.app.permission?.roles?.model === 'edit',
        canEditPackages: state.app.permission?.roles?.package === 'edit',
    }));

    const dispatch = useDispatch();
    const destroyPackage = (id) => dispatch(PackageModel.destroy(id));
    const destroyModel = (id) => dispatch(ModelModel.destroy(id));
    const destroyScheduledRun = (id) => dispatch(TriggerModel.destroy(id));
    const duplicateModel = (id) => {
        dispatch(ModelModel.duplicate(id)).then((response) => {
            const newId = response && response.model && response.model._id;
            if (newId) navigate(`${newId}/onboard`);
        });
    };

    const handleDestroyPackage = () => destroyPackage(deletePackageTooltipId);
    const handleDestroyModel = () => destroyModel(deleteModelTooltipId);
    const handleDestroyScheduledRun = () => destroyScheduledRun(deleteScheduledRunTooltipId);

    useEffect(() => {
        // should dispatch once when component mounts (dispatch function identity is guaranteed to be stable between renders)
        dispatch(ModelModel.index());
        dispatch(PackageModel.index());
        dispatch(AlgorithmModel.index());
        dispatch(TriggerModel.index());
    }, [dispatch]);

    if ((!models.hydrated && models.allIds.length === 0) || (!packages.hydrated && packages.allIds.length === 0)) {
        return <Loader type="content" loader="content" />;
    }

    const emptyPackagePrompt = () => (
        <div className="ModelsList__card__prompt">
            <Icon icon="large_color/package-upload" size="75px" />
            <div className="ModelsList__card__title"> No source code.</div>
            <div className="ModelsList__card__text">
                First, upload a zipped folder of Python source code.{' '}
                <a href="https://www.whitewhale.ai/getting-started-tutorials/6-build-your-first-model">Learn more.</a>
            </div>
            {canEditPackages && (
                <Button
                    id="createPackage"
                    gray
                    className="ModelsList__createPackageButton"
                    onClick={() => {
                        setCreatePackageModal(true);
                        setSelectedPackageId(null);
                    }}
                >
                    Add package
                </Button>
            )}
        </div>
    );

    const emptyModelPrompt = () => (
        <div className="ModelsList__card__prompt">
            <Icon icon="large_color/model" size="75px" />
            <div className="ModelsList__card__title"> No models.</div>
            <div className="ModelsList__card__text">
                Create models to run your python functions and veiw their output data on dashboards.
            </div>
            {canEditModels && (
                <ToolTip tooltip="Add a package to create a model">
                    <Button
                        id="createModel"
                        gray
                        className="ModelsList__createModelButton"
                        onClick={() => navigate(`onboard`)}
                        disabled={packages.allIds.length === 0}
                    >
                        Add model
                    </Button>
                </ToolTip>
            )}
        </div>
    );

    return (
        <div className="ModelsList">
            <UploadPackageModal
                open={createPackageModal}
                onClose={() => setCreatePackageModal(false)}
                packageId={selectedPackageId}
            />
            <Card className="ModelsList__card ModelsList__card--package">
                {packages.allIds.length > 0 ? (
                    <React.Fragment>
                        <div className="ModelsList__card__header">
                            Source Code
                            {canEditPackages && (
                                <Button
                                    id="createPackage"
                                    blue
                                    className="ModelsList__createPackageButton"
                                    onClick={() => {
                                        setCreatePackageModal(true);
                                        setSelectedPackageId(null);
                                    }}
                                >
                                    Add package
                                </Button>
                            )}
                        </div>
                        <div className="ModelsList__card__prompt">
                            <Icon icon="large_color/package-upload" size="75px" />
                            <div>
                                Upload <b>zipped Python packages</b> to create models using your existing functions.
                            </div>
                        </div>
                        <div className="ModelsList__card__list">
                            <ScrollArea>
                                {packages.allIds.length > 0 ? (
                                    Object.values(packages.byId)
                                        .sort((a, b) => (moment(a.timeCreated).isBefore(b.timeCreated) ? 1 : -1))
                                        .map((packageRedux) => {
                                            // package is a reserved word
                                            const { state, info, icon } = getPackageState(packageRedux);
                                            return (
                                                <SelectableItem
                                                    key={packageRedux._id}
                                                    name={packageRedux.name}
                                                    info={info}
                                                    type={<Icon icon={icon} size="16px" />}
                                                    className={`ModelsList__card__listItem--${state}`}
                                                    onClick={() =>
                                                        navigate(`/projects/${projectId}/packages/${packageRedux._id}`)
                                                    }
                                                    options={
                                                        canEditPackages
                                                            ? [
                                                                  {
                                                                      id: packageRedux._id,
                                                                      name: 'Delete Package',
                                                                      onClick: () =>
                                                                          setDeletePackageTooltipId(packageRedux._id),
                                                                      children: (
                                                                          <ConfirmDeleteMenu
                                                                              open={!!deletePackageTooltipId}
                                                                              onClose={() => {
                                                                                  setDeletePackageTooltipId(false);
                                                                              }}
                                                                              onDelete={() => {
                                                                                  handleDestroyPackage();
                                                                                  setDeletePackageTooltipId(false);
                                                                              }}
                                                                          />
                                                                      ),
                                                                  },

                                                                  {
                                                                      id: `update_${packageRedux._id}`,
                                                                      name: 'Update Package',
                                                                      onClick: () => {
                                                                          setCreatePackageModal(true);
                                                                          setSelectedPackageId(packageRedux._id);
                                                                      },
                                                                      onClose: () => {
                                                                          setSelectedPackageId(null);
                                                                      },
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            );
                                        })
                                ) : (
                                    <React.Fragment>
                                        <div className="ModelsList__card__listItem--empty" />
                                        <div className="ModelsList__card__listItem--empty" />
                                        <div className="ModelsList__card__listItem--empty" />
                                    </React.Fragment>
                                )}
                            </ScrollArea>
                        </div>
                    </React.Fragment>
                ) : (
                    emptyPackagePrompt()
                )}
            </Card>
            {models.byId[updateModelModalId] && (
                <UpdateModelModal
                    open={!!updateModelModalId}
                    onClose={() => setUpdateModelModalId(false)}
                    packages={packages}
                    algorithms={algorithms}
                    model={models.byId[updateModelModalId]}
                    modelId={updateModelModalId}
                />
            )}
            <Card className="ModelsList__card">
                {models.allIds.length > 0 && (
                    <div className="ModelsList__card__header">
                        Models
                        {canEditModels && (
                            <Button
                                id="createModel"
                                blue
                                className="ModelsList__createModelButton"
                                onClick={() => navigate(`onboard`)}
                            >
                                Add model
                            </Button>
                        )}
                    </div>
                )}

                {models.allIds.length > 0 ? (
                    <React.Fragment>
                        <div className="ModelsList__card__prompt">
                            <Icon icon="large_color/model" size="75px" />
                            Create models to run your Python functions and view their output data on dashboards.
                        </div>
                        <div className="ModelsList__card__list">
                            <ScrollArea>
                                {Object.values(models.byId)
                                    .sort((a, b) => (moment(a.timeCreated).isBefore(b.timeCreated) ? 1 : -1))
                                    .map((model) => {
                                        const { state, info, icon } = getModelState(model, packages, algorithms);
                                        return (
                                            <div key={model._id}>
                                                <SelectableItem
                                                    name={model.name}
                                                    info={info}
                                                    type={<Icon icon={icon} size="16px" />}
                                                    className={`ModelsList__card__listItem--${state}`}
                                                    onClick={() =>
                                                        navigate(
                                                            state === 'draft' ? `${model._id}/onboard` : `${model._id}`,
                                                        )
                                                    }
                                                    options={
                                                        canEditModels
                                                            ? [
                                                                  {
                                                                      id: `${model._id}_duplicate`,
                                                                      name: 'Duplicate Model',
                                                                      onClick: () => duplicateModel(model._id),
                                                                  },
                                                                  {
                                                                      id: `${model._id}_update`,
                                                                      name: 'Update Source',
                                                                      onClick: () => setUpdateModelModalId(model._id),
                                                                  },
                                                                  {
                                                                      id: `${model._id}_delete`,
                                                                      name: 'Delete Model',
                                                                      onClick: () => setDeleteModelTooltipId(model._id),
                                                                      children: (
                                                                          <ConfirmDeleteMenu
                                                                              open={!!deleteModelTooltipId}
                                                                              onClose={() =>
                                                                                  setDeleteModelTooltipId(false)
                                                                              }
                                                                              onDelete={() => {
                                                                                  handleDestroyModel();
                                                                                  setDeleteModelTooltipId(false);
                                                                              }}
                                                                          />
                                                                      ),
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />

                                                {Object.values(triggers.byId).filter(
                                                    (trigger) => trigger?.modelId === model._id,
                                                ).length > 0
                                                    ? Object.values(triggers.byId)
                                                          .filter((trigger) => trigger?.modelId === model._id)
                                                          .map((trigger) => (
                                                              <SelectableItem
                                                                  key={trigger._id}
                                                                  name="Scheduled Run:"
                                                                  subName={
                                                                      trigger.pattern.repeat.unit === 'never'
                                                                          ? `On ${moment(
                                                                                trigger.pattern.start
                                                                                    .effective_startdate,
                                                                                'MMM, Do',
                                                                            ).format('MMM, Do')} at ${moment(
                                                                                trigger.pattern.start
                                                                                    .scheduled_starttime,
                                                                                'h:mm a',
                                                                            ).format('h:mm a')}`
                                                                          : `Every ${trigger.pattern.repeat.value} ${trigger.pattern.repeat.unit}`
                                                                  }
                                                                  className="ModelsList__card__scheduledRuns"
                                                                  type={<Icon icon="date" size="16px" />}
                                                                  flag={trigger?.status === 'inactive'}
                                                                  endDate={moment(
                                                                      trigger?.pattern?.start?.until_datetime,
                                                                  ).format('MMM, D, Y')}
                                                                  onClick={() => {
                                                                      setEditScheduledRun(true);
                                                                      setCurrentTrigger(trigger);
                                                                  }}
                                                                  scheduledRun
                                                                  options={[
                                                                      {
                                                                          id: `${trigger._id}_delete`,
                                                                          name: 'Delete Scheduled Run',
                                                                          onClick: () =>
                                                                              setDeleteScheduledRunTooltipId(
                                                                                  trigger._id,
                                                                              ),
                                                                          children: (
                                                                              <ConfirmDeleteMenu
                                                                                  open={!!deleteScheduledRunTooltipId}
                                                                                  message="Remove this scheduled run?"
                                                                                  onClose={() =>
                                                                                      setDeleteScheduledRunTooltipId(
                                                                                          false,
                                                                                      )
                                                                                  }
                                                                                  onDelete={() => {
                                                                                      handleDestroyScheduledRun();
                                                                                      setDeleteScheduledRunTooltipId(
                                                                                          false,
                                                                                      );
                                                                                  }}
                                                                              />
                                                                          ),
                                                                      },
                                                                  ]}
                                                              />
                                                          ))
                                                    : null}
                                            </div>
                                        );
                                    })}
                            </ScrollArea>
                        </div>
                    </React.Fragment>
                ) : (
                    emptyModelPrompt()
                )}
            </Card>
            <EditScheduledModal
                open={editScheduledRun}
                model={models.byId[updateModelModalId]}
                modelTriggers={Object.values(triggers.byId).filter(
                    (trigger) => trigger.modelId === currentTrigger.modelId,
                )}
                currentTrigger={currentTrigger}
                onClose={() => {
                    setEditScheduledRun(false);
                    dispatch(TriggerModel.index());
                }}
            />
        </div>
    );
};

Models.propTypes = {};
Models.defaultProps = {};

export default Models;
