import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState } from '../../../../models/base';
import { Types as projectTypes } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('collection');
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('collection', INITIAL_STATE, Schema, Types);
export default createReducer(INITIAL_STATE, {
    ...crudReducers,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
});
