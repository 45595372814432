export const fileValidations = [
    {
        validate: (form) => !form.file || form.file.size < 2000000000,
        label: 'Max size: 2 GB',
        message: 'file too large',
    },
    {
        validate: (form) => !form.file || form.file.name?.match(/\.csv$/),
        label: 'Accepted: .csv',
        message: 'wrong file type',
    },
];
