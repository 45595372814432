import { Actions } from './reducers';
import UnitsApi from './api';
import { operations } from '../../../models/base';

const data = (id, options = {}, temporaryQueries = {}) => {
    const { tabId, jobId, page, limit } = options;
    const { filters, orderBy } = temporaryQueries;
    return (dispatch) => {
        dispatch(Actions.dataRequest(id));
        UnitsApi.data(id, { tabId, page, jobId, limit }, { filters, orderBy }).then(
            (response) => dispatch(Actions.dataSuccess(id, response)),
            (error) => dispatch(Actions.dataFailure(id, error)),
        );
    };
};

const update = (id, obj, options = {}) => {
    const { tabId, shouldGetData } = options;
    return (dispatch) => {
        dispatch(Actions.updateRequest(id, obj, { shouldGetData }));
        const unitApiCall = shouldGetData ? UnitsApi.updateWithData : UnitsApi.update;
        const payload = { ...obj, data: undefined }; // don't send data to backend
        return unitApiCall(id, payload, { tabId }).then(
            (responseUnit) => dispatch(Actions.updateSuccess(id, responseUnit, { shouldGetData })),
            (errors) => dispatch(Actions.updateFailure(id, errors)),
        );
    };
};

const download = (unitId, options = {}) => {
    const { jobId, tabId } = options;
    return (dispatch) => {
        dispatch(Actions.downloadRequest(unitId));
        UnitsApi.download(unitId, { jobId, tabId }).then(
            (response) => {
                const downloadFile = document.createElement('a');
                downloadFile.href = `${'data:text/csv;base64,'}${btoa(unescape(encodeURIComponent(response.data)))}`;
                downloadFile.download = `deepsea-${Date.now()}.csv`;
                document.body.appendChild(downloadFile);
                downloadFile.click();
                return dispatch(Actions.downloadSuccess(unitId, response));
            },
            (error) => dispatch(Actions.downloadFailure(unitId, error)),
        );
    };
};

const temporaryData = (unitId, queryParameters, unitPayload) => (dispatch) =>
    UnitsApi.temporaryData(queryParameters, unitPayload).then(
        (response) => dispatch(Actions.temporaryDataSuccess(unitId, response)),
        (error) => dispatch(Actions.temporaryDataFailure(unitId, error)),
    );

const removeData = (id) => ({ type: 'UNITS_REMOVE_DATA', id })

export default operations(Actions, UnitsApi, {
    data,
    update,
    download,
    temporaryData,
    removeData,
});
