import React from 'react';
import PropTypes from 'prop-types';
import { dateFilterDays, dateFilterMonths } from './constants';

import { Input } from '../../../../shared';
import { getDatePrecisionFromOperation } from '../../helpers';

export default function DateFilterMain(props) {
    const { value: filterValue, onChange, onSubmit } = props;
    const { option, operation, value } = filterValue;
    const dateFilterPrecision = getDatePrecisionFromOperation(operation);

    const handleValueChange = (id, val) => {
        if (option === 'between') {
            onChange('value', { from: value.from, to: value.to, [id]: val }); // eslint-disable-line react/prop-types
        } else {
            onChange('value', { [id]: val });
        }
    };

    const handleListChange = (id, val) => {
        let selectedDateItems = Object.keys(val);
        if (!selectedDateItems.length) selectedDateItems = null;
        onChange('value', { selectedDateItems });
    };

    if (option === 'empty' || option === 'not-empty') {
        return null;
    }

    if (option === 'between') {
        return (
            <React.Fragment>
                <Input
                    id="from"
                    type="date"
                    dark
                    placeholder="Select date"
                    max={value.to} // eslint-disable-line react/prop-types
                    value={value.from} // eslint-disable-line react/prop-types
                    onChange={handleValueChange}
                    onSubmit={onSubmit}
                    precision={dateFilterPrecision}
                />
                <span className="DateFilter__seperator">to</span>
                <Input
                    id="to"
                    type="date"
                    dark
                    placeholder="Select date"
                    min={value.from} // eslint-disable-line react/prop-types
                    value={value.to} // eslint-disable-line react/prop-types
                    onChange={handleValueChange}
                    onSubmit={onSubmit}
                    precision={dateFilterPrecision}
                />
            </React.Fragment>
        );
    }
    if (option === 'last') {
        return (
            <Input
                id="duration"
                type="number"
                autosize={false}
                autoFocus
                dark
                value={value.duration} // eslint-disable-line react/prop-types
                onChange={handleValueChange}
                onSubmit={onSubmit}
                precision={dateFilterPrecision}
            />
        );
    }
    if ((operation === 'dow' || operation === 'month') && option === 'on') {
        const inputValue = {};
        ((value || {}).selectedDateItems || []).forEach((itm) => {
            inputValue[itm] = true;
        });
        return operation === 'dow' ? (
            <Input
                id="selectedDateItems"
                type="list"
                dark
                multiSelect
                sm
                header={false}
                value={inputValue}
                items={dateFilterDays}
                border={false}
                onChange={handleListChange}
                onSubmit={onSubmit}
            />
        ) : (
            <Input
                id="selectedDateItems"
                type="list"
                dark
                multiSelect
                sm
                header={false}
                value={inputValue}
                items={dateFilterMonths}
                border={false}
                onChange={handleListChange}
                onSubmit={onSubmit}
            />
        );
    }
    let inputId;
    switch (option) {
        case 'before':
            inputId = 'to';
            break;
        case 'after':
            inputId = 'from';
            break;
        case 'on':
            inputId = 'on';
            break;
        default:
            inputId = 'on';
    }
    return (
        <Input
            id={inputId}
            type="date"
            dark
            placeholder="Select date"
            value={value[inputId]}
            onChange={handleValueChange}
            onSubmit={onSubmit}
            precision={dateFilterPrecision}
        />
    );
}

DateFilterMain.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    feature: PropTypes.shape({
        name: PropTypes.string,
        precision: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({
        option: PropTypes.string,
        operation: PropTypes.string,
        value: PropTypes.shape({
            to: PropTypes.string,
            from: PropTypes.string,
            duration: PropTypes.string,
        }),
    }).isRequired,
};
