import { Actions } from './reducers';
import OrganizationApi from '../organizations/api';
import UserApi from '../users/api';
import { PermissionModel } from '../../../Projects/_models';

const getMyUser = () => (dispatch) => {
    dispatch(Actions.getMyUserRequest());
    return UserApi.show('my').then(
        (user) => dispatch(Actions.getMyUserSuccess(user)),
        (error) => dispatch(Actions.getMyUserFailure(error)),
    );
};

const getMyOrganization = () => (dispatch) => {
    dispatch(Actions.getMyOrganizationRequest());
    return OrganizationApi.show('my').then(
        (organization) => dispatch(Actions.getMyOrganizationSuccess(organization)),
        (error) => dispatch(Actions.getMyOrganizationFailure(error)),
    );
};

const getMyProjectPermission = (projectId) => (dispatch) =>
    dispatch(PermissionModel.listByProject(projectId)).then((action) => {
        if (action.type.includes('SUCCESS')) {
            return dispatch(Actions.getPermissionsSuccess(projectId, action.permissions));
        }
        return action;
    });

export default {
    getMyUser,
    getMyOrganization,
    getMyProjectPermission,
};
