import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { Input, Button, ColorPicker } from '../../../../../shared';

require('./styles.scss');

export default function FeatureOverrideItem(props) {
    const { onClear, updateColor, features, featureId, colors, openPicker, setOpenPicker, onSelect } = props;

    const selectOptions = Object.values(features.byId)
        .filter((f) => f._id === featureId || !Object.keys(colors.features).includes(f._id))
        .map((f) => ({
            label: f.name,
            value: f._id,
        }));

    const color = featureId && colors?.features[featureId];

    return (
        <div className="featureOverride">
            {featureId ? (
                <div className="featureOverride__title">{features.byId[featureId]?.name}</div>
            ) : (
                <Input
                    type="select"
                    id="select-input"
                    onChange={(_, value) => onSelect(value)}
                    value={featureId}
                    options={selectOptions}
                    dark
                    sm
                />
            )}
            <div
                className={ClassNames('DashboardColors__themePreviewColor', {
                    'DashboardColors__themePreviewColor--active': openPicker,
                    'DashboardColors__themePreviewColor--noPicker': !featureId,
                })}
                style={{ backgroundColor: color }}
                onClick={() => setOpenPicker()}
                role="button"
                aria-label="Change Color" // needs to have a label, made it aria because we don't need it visible
                tabIndex={-1}
            />
            <span className="DashboardColors__themePreviewColorHex">{color}</span>
            {featureId && (
                <ColorPicker
                    color={color}
                    onPicked={(value) => updateColor(featureId, value)}
                    open={openPicker}
                    onClickOut={() => openPicker && setOpenPicker()}
                />
            )}
            <Button id="clearFeatureOverride" dark onClick={onClear} tabIndex={-1} small>
                Clear
            </Button>
        </div>
    );
}
FeatureOverrideItem.propTypes = {
    onClear: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    updateColor: PropTypes.func,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    featureId: PropTypes.string,
    colors: PropTypes.shape({ features: PropTypes.shape({}) }),
    openPicker: PropTypes.bool,
    setOpenPicker: PropTypes.func,
};

FeatureOverrideItem.defaultProps = {
    colors: {},
    featureId: undefined,
    openPicker: false,
    setOpenPicker: () => {},
    updateColor: () => {},
};
