import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import OrganizationModel from '../../../../_models/organizations';
import { ProjectModel } from '../../../../../Projects/_models';
import { Input } from '../../../../../shared';
import OrganizationLogo from './Logo';

require('./styles.scss');

export default function OrganizationGeneral({ organization, onSubmit }) {
    const name = organization?.name;
    const [stateName, setStateName] = useState(name);
    const organizations = useSelector((state) => state.organizations);
    const dispatch = useDispatch();
    const isPublic = organization?.publicSharing === undefined ? true : organization?.publicSharing;
    const [publicSharing, setPublicSharing] = useState(isPublic);
    const projects = Object.values(useSelector((state) => state.projects.byId)).filter(
        (project) => project.organizationId === organization._id,
    );

    useEffect(() => {
        if (organization.type === 'client' && !organizations.byId[organization.consultantId]) {
            dispatch(OrganizationModel.show(organization.consultantId));
        }
        dispatch(ProjectModel.listByOrganization(organization?._id));
    }, [organization.type, organization._id, organization.consultantId, organizations.byId, dispatch]);

    const handlePublicSharing = (id, value) => {
        dispatch(OrganizationModel.togglePublicSharing(organization?._id, { publicSharing: value }));
        Object.values(projects).forEach((project) => dispatch(ProjectModel.update(project._id, { public: value })));
        setPublicSharing(value);
    };

    const handleChangeName = (id, value) => {
        if (value && value !== organization?.name) {
            onSubmit(organization?._id, { name: value });
        } else {
            setStateName(organization?.name);
        }
    };

    let organizationTypeText = '--';
    if (organization.type === 'independent') organizationTypeText = 'Independent';
    if (organization.type === 'consultant') organizationTypeText = 'Consultant Organization';
    if (organization.type === 'client')
        organizationTypeText = `Client | managed by ${organizations.byId[organization?.consultantId]?.name}`;

    return (
        <div className="OrganizationGeneral">
            <Input
                id="name"
                value={stateName || name}
                type="text"
                label="Organization Name"
                placeholder="Enter name"
                gray
                submitOnBlur
                onChange={(id, value) => setStateName(value)}
                onSubmit={handleChangeName}
            />
            <Input id="type" type="text" value={organizationTypeText} label="Organization Type" gray disabled />
            <Input
                id="publicSharingInput"
                type="select"
                value={publicSharing}
                label="Share Outside This Organization"
                options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                ]}
                onChange={handlePublicSharing}
                gray
            />
            <OrganizationLogo
                logo={organization.logo}
                updateLogo={(form) => {
                    dispatch(OrganizationModel.updateLogo(organization?._id, form));
                }}
                hydrated={organization.hydrated}
            />
        </div>
    );
}

OrganizationGeneral.propTypes = {
    organization: PropTypes.shape(),
    onSubmit: PropTypes.func,
};

OrganizationGeneral.defaultProps = {
    organization: {},
    onSubmit: () => {},
};
