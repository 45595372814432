import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import {
    ContextMenu,
    ContextMenuItem,
    ContextSubMenu,
    Icon,
    IconButton,
    InfoToolTip,
    Loader,
    ConfirmDeleteMenu,
} from '../../../shared';
import { blue300 } from '../../../../styles/partials/colors';
import './styles.scss';

export default function ButtonWithOptions(props) {
    const {
        icon,
        label,
        name,
        description,
        options,
        selected,
        failed,
        loading,
        onClick,
        deleteFunc,
        deleteText,
        iconFunc
    } = props;
    const [open, setOpen] = useState(false);
    const [deleteTooltipOpen, setDeleteTooltip] = useState(false);

    const handleClickOut = () => {
        setOpen(false);
        setDeleteTooltip(false);
    };

    const toggleOpen = () => {
        setOpen(!open);
        setDeleteTooltip(false);
    };

    return (
        <div className="ButtonWithOptions">
            <div className="ButtonWithOptions__label">{label}</div>
            <div
                className={ClassNames('ButtonWithOptions__button', {
                    'ButtonWithOptions__button--selected': selected,
                    'ButtonWithOptions__button--clickable': onClick && !failed && !loading,
                    'ButtonWithOptions__button--failed': failed,
                    'ButtonWithOptions__button--options': options && options.length,
                })}
                onClick={onClick || undefined}
                role="button"
                tabIndex={0}
            >
                {icon && <IconButton icon={icon} size="20px" onClick={iconFunc} />}
                <div className="ButtonWithOptions__name">{name}</div>
                {loading && <Loader type="spinner" loader="bar" height={4} width="100%" color={blue300} />}
                {description && (
                    <InfoToolTip size="20px" place="left">
                        {description}
                    </InfoToolTip>
                )}
                <ContextMenu open={open} onClickOut={handleClickOut} position="bottom right">
                    <ContextSubMenu>
                        {options &&
                            options.map((option) => (
                                <ContextMenuItem
                                    key={option.id}
                                    {...option}
                                    onClick={() => {
                                        option.onClick();
                                        handleClickOut();
                                    }}
                                />
                            ))}
                        {deleteFunc && (
                            <ContextMenuItem
                                key="delete"
                                id="delete"
                                name={deleteText || 'Delete'}
                                onClick={() => setDeleteTooltip(!deleteTooltipOpen)}
                            >
                                <ConfirmDeleteMenu
                                    open={deleteTooltipOpen}
                                    onClose={() => setDeleteTooltip(false)}
                                    onDelete={() => deleteFunc()}
                                />
                            </ContextMenuItem>
                        )}
                    </ContextSubMenu>
                </ContextMenu>
            </div>
            {options && options.length > 0 && (
                <IconButton mini icon="menu-vertical" onClick={toggleOpen} active={open} />
            )}
        </div>
    );
}

ButtonWithOptions.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    selected: PropTypes.bool,
    failed: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    options: PropTypes.array,
    deleteFunc: PropTypes.func,
    deleteText: PropTypes.string,
    iconFunc: PropTypes.func,
};
ButtonWithOptions.defaultProps = {
    icon: '',
    label: '',
    name: '',
    description: null,
    selected: false,
    failed: false,
    loading: false,
    options: [],
    onClick: null,
    deleteFunc: null,
    deleteText: '',
    iconFunc: null
};
