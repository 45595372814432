import ApiBase from '../../../../models/base/api';

export class ModelRunApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('model-runs', addProjectIdToSearchParams);
    }
    cancel = (id) => this.request('PUT', `${this.endpoint}/${id}/cancel`);
}

export default new ModelRunApi();
