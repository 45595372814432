import ApiBase from '../../../../models/base/api';

export class PackageApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('packages', addProjectIdToSearchParams);
    }
    upload = (form) => this.request('POST', `${this.endpoint}/upload`, form, { contentType: 'FormData' });
    update = (id, form) => this.request('PUT', `${this.endpoint}/${id}/update`, form, { contentType: 'FormData' });
    getSourceCode = (id) => this.request('GET', `${this.endpoint}/${id}/code`);
    updateCode = (id, form) => this.request('PUT', `${this.endpoint}/${id}/code`, form);
    commitPackageImage = (id) => this.request('PUT', `${this.endpoint}/${id}/commit`);
    getDependencies = (id) => this.request('GET', `${this.endpoint}/${id}/dependencies`);
    updateDependencies = (id, form) =>
        this.request('PUT', `${this.endpoint}/${id}/dependencies`, form, { contentType: 'FormData' });
    getEnv = (id) => this.request('GET', `${this.endpoint}/${id}/env`);
    updateEnv = (id, form) => this.request('PUT', `${this.endpoint}/${id}/env`, form, { contentType: 'FormData' });
}

export default new PackageApi();
