import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('algorithm', {
    createModelRequest: ['id', 'form'],
    createModelSuccess: ['id', 'form', 'model'],
    createModelFailure: ['id', 'form', 'errors'],
});
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('algorithm', INITIAL_STATE, Schema, Types);
const additionalReducers = apiHandlers(['CREATE_MODEL'], Types, {
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('algorithms'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
