import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatasetModel from '../../_models/dataset';
import FeatureModel from '../../_models/feature';
import { formatDatasetData } from '../../helpers';
import { DataTable } from '../../../shared';
import FeatureTableHeader from '../../_components/FeatureTableHeader';
import { updateFeatureFilterArray, determineRequestPage } from '../../_components/FeatureTableHeader/helpers';

class DatasetTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastRequestedPage: 1,
            currentPage: 1,
            orderBy: [],
            filters: [],
        };
    }
    componentDidMount = () => {
        const { datasetId, features, getFeatures } = this.props;
        if (datasetId) this.updateStateAndFetchData({ filters: [], orderBy: [], currentPage: 1 });
        if (
            features.hydrated &&
            !features.allIds.find((featureId) => features.byId[featureId].dataset._id === datasetId)
        ) {
            // This is needed for model onboarding after you run a model for the first time
            getFeatures(datasetId);
        }
    };
    shouldComponentUpdate(nextProps) {
        const { dataset, features, jobId } = this.props;
        return dataset !== nextProps.dataset || features !== nextProps.features || jobId !== nextProps.jobId;
    }
    componentDidUpdate = (prevProps) => {
        const { datasetId, jobId } = this.props;
        if (datasetId && (prevProps.datasetId !== datasetId || jobId !== prevProps.jobId)) {
            // if the jobId has changed, it might be nicer to feltch the currentPage but we'll do this for now (return to page 1)
            this.updateStateAndFetchData({ filters: [], orderBy: [], currentPage: 1 });
        }
    };

    // ------------------ FETCH DATA -----------------
    updateStateAndFetchData = (updatedValues) => {
        const { getData, jobId, datasetId } = this.props;
        this.setState(updatedValues);
        const { filters, orderBy, currentPage } = { ...this.state, ...updatedValues };
        const temporaryQueryOptions = {
            filters: Object.values(filters).filter((filter) => filter.valid),
            orderBy,
        };
        const temporaryOptions = { page: currentPage, limit: 50, jobId };
        getData(datasetId, temporaryOptions, temporaryQueryOptions);
    };

    // ------------------ FILTER -----------------
    onFilterChange = (featureId, filter) => {
        const { filters } = this.state;
        const newFilters = updateFeatureFilterArray(filters, featureId, filter);
        this.updateStateAndFetchData({ filters: newFilters, currentPage: 1 });
    };

    // ----------------- ORDER BY -----------------
    onOrderChange = (featureId, type) => {
        const { orderBy } = this.state;
        const filteredOrderBy = orderBy.filter((order) => !(order.id === featureId));
        if (type) {
            filteredOrderBy.unshift({ id: featureId, value: featureId, operation: '', type, nulls: 'last' }); // This should probabaly be formatted in the vis service
        }
        this.updateStateAndFetchData({ orderBy: filteredOrderBy, currentPage: 1 });
    };

    // ------------------ PAGE CHANGE -----------------
    handlePaginate = (page) => {
        const { dataset } = this.props;
        const { currentPage, lastRequestedPage } = this.state;
        const requestPage = determineRequestPage(page, dataset.data);
        if (currentPage !== requestPage || lastRequestedPage !== requestPage) {
            this.updateStateAndFetchData({ currentPage: requestPage, lastRequestedPage: requestPage });
        }
    };
    // ----------------------------------------------------------------------------

    render() {
        const { dataset, features, getFeatureCategories, loadingNumber } = this.props;
        const data = dataset.data || {};
        const { orderBy, filters } = this.state;

        const titles = {};
        const datasetFeatureIds = features.allIds.filter(
            (featureId) => features.byId[featureId].dataset._id === dataset._id,
        );

        if (datasetFeatureIds.length) {
            datasetFeatureIds.forEach((featureId) => {
                const featureOrder = orderBy.find((order) => order.id === featureId);
                const orderType = featureOrder && featureOrder.type;
                const filter = filters.find((f) => f && f.feature && f.feature.id === featureId);
                const feature = features.byId[featureId];
                if (!feature.hidden) {
                    titles[feature._id] = (
                        <FeatureTableHeader
                            feature={feature}
                            filter={filter}
                            orderType={orderType}
                            getFeatureCategories={getFeatureCategories}
                            onFilterChange={this.onFilterChange}
                            onOrderChange={(updatedOrder) => this.onOrderChange(featureId, updatedOrder)}
                        />
                    );
                }
            });
        }

        // Use an empty row if there are temporary filters and the data returned is empty - allow edit/removal of filters
        const tableData =
            Object.values(filters).filter((filter) => filter && filter.valid).length !== 0 &&
            data.list &&
            data.list.length === 0
                ? [
                      Object.keys(titles).reduce((acc, current) => {
                          acc[current] = null;
                          return acc;
                      }, {}),
                  ]
                : formatDatasetData(dataset, features);

        return (
            <DataTable
                value={tableData}
                titles={titles}
                id="table"
                pagination={data.pages}
                onPaginate={this.handlePaginate}
                loading={!data.list || !datasetFeatureIds.length}
                loadingNumber={loadingNumber}
                pageLoading={dataset.loading && !!data.list && !!datasetFeatureIds.length}
            />
        );
    }
}

DatasetTable.propTypes = {
    dataset: PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
            jobId: PropTypes.string,
            pages: PropTypes.shape({
                total: PropTypes.number,
            }),
            list: PropTypes.array,
        }),
        features: PropTypes.arrayOf(PropTypes.string),
        loading: PropTypes.bool,
    }),
    features: PropTypes.shape({
        loading: PropTypes.bool,
        hydrated: PropTypes.bool,
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
    }).isRequired,
    loadingNumber: PropTypes.number,
    getData: PropTypes.func.isRequired,
    getFeatures: PropTypes.func.isRequired,
    datasetId: PropTypes.string.isRequired,
    jobId: PropTypes.string,
    getFeatureCategories: PropTypes.func,
};

DatasetTable.defaultProps = {
    dataset: { data: {}, loading: false },
    loadingNumber: 20,
    jobId: 'latest',
    getFeatureCategories: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const { datasetId } = ownProps;
    const dataset = state.datasets.byId[datasetId];
    return { dataset, features: state.features };
};

const mapDispatchToProps = (dispatch) => {
    const getFeatures = (datasetId) => dispatch(DatasetModel.features(datasetId));
    const getFeatureCategories = (featureId) => dispatch(FeatureModel.categories(featureId));
    const getData = (datasetId, queryParameters = {}, temporaryQueries = {}) =>
        dispatch(DatasetModel.data(datasetId, queryParameters, temporaryQueries));
    return {
        getFeatures,
        getFeatureCategories,
        getData,
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DatasetTable);
export default connectedComponent;
