import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PreviewCard from './PreviewCard';

import { Button, Input, Icon, Loader } from '../../shared';
import { fuzzySearch } from '../../../helpers/string';

require('./styles.scss');

export default class DashboardList extends Component {
    constructor(props) {
        super(props);
        this.state = { filter: '' };
    }
    handleFilterChange = (id, value) => this.setState({ filter: value });
    render() {
        const {
            dashboards,
            loading,
            onCreate,
            onEdit,
            onDelete,
            onNavigate,
            onDuplicate,
            onUpdatePreview,
            canEditDashboards,
        } = this.props;
        const { filter } = this.state;

        const filteredDashboards = fuzzySearch(filter, Object.values(dashboards.byId)).sort((d1, d2) =>
            d2.timeCreated > d1.timeCreated ? 1 : -1,
        );

        const emptyDashboardPage = (
            <div className="DashboardList__empty">
                <Icon icon="large_color/emptyDashboard" size="75px" />
                <div className="DashboardList__empty--Title">No data apps.</div>
                <div className="DashboardList__empty--Text">
                    Data apps can be used to run models and visaulize data.
                </div>
                {canEditDashboards && (
                    <Button gray onClick={onCreate}>
                        Create Data App
                    </Button>
                )}
            </div>
        );

        if (loading) {
            return (
                <div className="DashboardList--loading">
                    <Loader type="content" loader="content" />
                </div>
            );
        }

        return (
            <div className="DashboardList">
                {Object.values(dashboards.byId).length > 0 ? (
                    <div className="DashboardList__full">
                        <header className="ThreeColumnHeader">
                            <div className="DashboardList__title">Data Apps</div>
                            <Input
                                id="filter"
                                type="search"
                                placeholder="Search Apps"
                                value={filter}
                                onChange={this.handleFilterChange}
                            />
                            {canEditDashboards && (
                                <Button blue onClick={onCreate}>
                                    Create Data App
                                </Button>
                            )}
                        </header>
                        {filteredDashboards.length > 0 ? (
                            <div className="DashboardList__cards">
                                {filteredDashboards.map((dashboard) => (
                                    <PreviewCard
                                        dashboard={dashboard}
                                        key={dashboard._id}
                                        onDelete={onDelete}
                                        onNavigate={onNavigate}
                                        onEdit={onEdit}
                                        onDuplicate={onDuplicate}
                                        onUpdatePreview={onUpdatePreview}
                                        canEditDashboards={canEditDashboards}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="DashboardList__empty">
                                <Icon icon="large_color/question-circle" size="75px" />
                                <div className="DashboardList__empty--Title">No data apps found.</div>
                            </div>
                        )}
                    </div>
                ) : (
                    emptyDashboardPage
                )}
            </div>
        );
    }
}

DashboardList.propTypes = {
    dashboards: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    loading: PropTypes.bool,
    canEditDashboards: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onNavigate: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onUpdatePreview: PropTypes.func.isRequired,
};
DashboardList.defaultProps = {
    loading: false,
};
