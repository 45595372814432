export const typeOptions = [
    { value: 'date', label: 'date' },
    { value: 'time', label: 'time' },
    { value: 'category', label: 'category' },
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
];

export const typeIcons = {
    id: 'number',
    number: 'number',
    date: 'date',
    time: 'clock',
    category: 'category',
    text: 'string',
};
