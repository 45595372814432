import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OrganizationModel from '../../../_models/organizations';
import { Input, Button, Breadcrumb, Icon } from '../../../../shared';
import { fuzzySearch } from '../../../../../helpers/string';
import CreateOrganizationModal from './CreateOrganizationModal';
import OrganizationList from '../../_components/OrganizationList';

require('./styles.scss');

export default function RootOrganizationsList() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [filter, setFilter] = useState('');

    const myOrganizationId = useSelector((state) => state.app.organization?._id);
    const organizations = useSelector((state) => state.organizations);
    const { hydrated } = organizations;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(OrganizationModel.index());
    }, [dispatch]);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);
    const filteredOrganizations = fuzzySearch(filter, Object.values(organizations.byId), ['name', 'plan']);

    const breadcrumbItems = [{ label: 'DeepSea Administration' }];

    return (
        <div className="RootOrganizationsList">
            <div className="RootOrganizationsList__header ThreeColumnHeader">
                <Breadcrumb items={breadcrumbItems} />
                <Input
                    type="search"
                    editable
                    placeholder="Search by Organization Name"
                    id="searchOrganizations"
                    onChange={(el, value) => setFilter(value)}
                    value={filter}
                />
                <Button id="createOrganization" blue onClick={toggleCreateModal}>
                    Create Organization
                </Button>
                <CreateOrganizationModal onClose={toggleCreateModal} open={createModalOpen} />
            </div>
            <div className="RootOrganizationsList__prompt">
                <Icon icon="large_color/whale-blue" size="75px" />
                <br /> For White Whale Ops to manage organizations and related settings.
            </div>
            <div className="RootOrganizationsList__body">
                <OrganizationList
                    organizations={filteredOrganizations}
                    hydrated={hydrated}
                    myOrganizationId={myOrganizationId}
                />
            </div>
        </div>
    );
}
