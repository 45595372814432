import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Logo, Input, ModalCard, Button } from '../../../../../../shared';
import { logoValidations } from '../../helpers';

import './styles.scss';

export default function OrganizationLogo({ logo, hydrated, updateLogo }) {
    const [logoFile, setLogoFile] = useState(null);
    const [logoUpload, setLogoUpload] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleLogoChange = (id, value, errs) => {
        setErrors(errs);
        setLogoFile(value);
    };

    const handleLogoSubmit = () => {
        updateLogo({ logo: logoFile });
        setLogoUpload(false);
    };

    return (
        <div className="OrganizationLogo">
            Organization Icon
            <div
                style={{ display: 'inline-block' }}
                onClick={() => setLogoUpload(!logoUpload)}
                role="button"
                tabIndex={0}
            >
                <Logo logo={logo} hydrated={hydrated} />
            </div>
            <ModalCard open={logoUpload} onClose={() => setLogoUpload(false)} title="Update Logo">
                <div className="OrganizationLogo__uploadModal">
                    <Input
                        type="file"
                        id="logo"
                        validations={logoValidations}
                        accept="image/png"
                        errors={errors}
                        value={logoFile}
                        onChange={handleLogoChange}
                    />
                    <div className="OrganizationLogo__uploadModal__buttons">
                        <Button id="cancel" gray onClick={() => setLogoUpload(false)} tabIndex={-1}>
                            Cancel
                        </Button>
                        <Button id="submit" blue onClick={handleLogoSubmit}>
                            Upload
                        </Button>
                    </div>
                </div>
            </ModalCard>
        </div>
    );
}

OrganizationLogo.propTypes = {
    logo: PropTypes.string,
    updateLogo: PropTypes.func.isRequired,
    hydrated: PropTypes.bool,
};

OrganizationLogo.defaultProps = {
    logo: '',
    hydrated: true,
};
