import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import IconButton from '../IconButton';

require('./styles.scss');

export default class CollapsibleSection extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.expanded,
        };
    }

    toggleExpanded = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };

    render() {
        const { children, line, title, className } = this.props;
        const { expanded } = this.state;
        return (
            <div className={ClassNames('CollapsibleSection', className)}>
                <div
                    className={ClassNames('CollapsibleSection__title', {
                        'CollapsibleSection__title--open': expanded,
                        'CollapsibleSection__title--underline': line,
                    })}
                    onClick={this.toggleExpanded}
                    role="button"
                    tabIndex={0}
                >
                    {title}
                    <IconButton onClick={this.toggleExpanded} mini icon={expanded ? 'angle-down' : 'angle-up'} />
                </div>
                <div className="CollapsibleSection__main">{expanded && children}</div>
            </div>
        );
    }
}

CollapsibleSection.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    expanded: PropTypes.bool,
    line: PropTypes.bool,
};

CollapsibleSection.defaultProps = {
    title: null,
    className: '',
    expanded: false,
    line: false,
};
