import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../../shared';

require('./styles.scss');

const NumberConfig = (props) => {
    const { config, updateConfig } = props;

    return (
        <Input
            id="numberInputStep"
            type="number"
            label="Increment by"
            value={config.step}
            onChange={(_, value) => updateConfig({ ...config, step: value })}
            gray
        />
    );
};

NumberConfig.propTypes = {
    config: PropTypes.shape({
        step: PropTypes.number,
    }),
    updateConfig: PropTypes.func.isRequired,
};
NumberConfig.defaultProps = {
    config: {},
};

export default NumberConfig;
