import React from 'react';
import PropTypes from 'prop-types';

import { getSourceString } from '../../helpers';
import PackageFunctionSelect from '../../../_components/PackageFunctionSelect';

export default function SelectFunction(props) {
    const { packages, algorithms, model, metadata, updateMetaData } = props;
    return (
        <div className="SelectFunction">
            <div className="SelectFunction__title">Model</div>
            <div className="SelectFunction__info">{model?.name}</div>
            <div className="SelectFunction__title">Current Source</div>
            <div className="SelectFunction__info">{getSourceString(model, packages, algorithms)}</div>

            <PackageFunctionSelect
                metadata={metadata}
                updateMetaData={updateMetaData}
                packages={packages}
                algorithms={algorithms}
            />
        </div>
    );
}

SelectFunction.propTypes = {
    model: PropTypes.shape().isRequired,
    packages: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    algorithms: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    metadata: PropTypes.shape({}).isRequired,
    updateMetaData: PropTypes.func.isRequired,
};
