import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterMenuDataset from './dataset';
import { getDatasetIdFromFilter } from '../helpers';
import { ContextMenu, ContextMenuItem, ContextSubMenu, Icon } from '../../../shared';

import './styles.scss';

// the context menu for a filter
// fully controlled -- takes values from the value prop (a filter object)
// (the dataset that is open is not fully controlled as it is not in the filter object)
export default function FilterMenu(props) {
    const {
        datasets,
        datasetIds: propsDatasetIds,
        features,
        open,
        value,
        onChange,
        onSubmit,
        onClickOut,
        onFeatureSelect,
        isFilterBar,
        handleDelete,
        filterId,
    } = props;
    const datasetIds = (propsDatasetIds || datasets.allIds).filter((datasetId) => datasets.byId[datasetId]);
    const [openDatasetId, setOpenDatasetId] = useState(null);
    const defaultOpenDatasetId = getDatasetIdFromFilter(value, features) || datasetIds[0];
    if (defaultOpenDatasetId && !datasetIds.find((dId) => dId === defaultOpenDatasetId))
        datasetIds.push(defaultOpenDatasetId);
    const handleOpenDataset = (datasetId) => {
        setOpenDatasetId(datasetId);
    };

    return (
        <ContextMenu
            className="FilterMenu"
            open={open}
            onClickOut={onClickOut}
            position={isFilterBar ? 'bottom' : 'right'}
        >
            <div className="FilterMenu__maxHeight">
                {datasetIds.length ? (
                    datasetIds.map((datasetId) => (
                        <FilterMenuDataset
                            key={datasetId}
                            value={value}
                            dataset={datasets.byId[datasetId] || {}}
                            features={features}
                            open={datasetId === (openDatasetId || defaultOpenDatasetId)}
                            onSelect={handleOpenDataset}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            onFeatureSelect={onFeatureSelect}
                        />
                    ))
                ) : (
                    <div className="FilterMenu__empty"> No datasets </div>
                )}
            </div>
            {filterId ? (
                <ContextSubMenu>
                    <ContextMenuItem
                        id="clear"
                        name="Clear Filter"
                        type={<Icon size="16px" icon="trash" />}
                        onClick={handleDelete}
                    />
                </ContextSubMenu>
            ) : null}
        </ContextMenu>
    );
}

FilterMenu.propTypes = {
    value: PropTypes.shape({
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
    }),
    datasets: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
    }).isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({}).isRequired,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onClickOut: PropTypes.func,
    onFeatureSelect: PropTypes.func,
    isFilterBar: PropTypes.bool,
    handleDelete: PropTypes.func,
    filterId: PropTypes.string,
};
FilterMenu.defaultProps = {
    value: null,
    datasetIds: null,
    open: false,
    onChange: () => {},
    onSubmit: () => {},
    onClickOut: () => {},
    onFeatureSelect: null,
    isFilterBar: false,
    handleDelete: () => {},
    filterId: '',
};
