import React from 'react';
import PropTypes from 'prop-types';

export default function ModelRunError(props) {
    const { modelRun } = props;
    const lines = modelRun.error?.split('\n') || [];
    return (
        <div className="ModelRunErrors">
            {lines.map((line) => (
                <div className="ModelRunErrors__line" key={line}>
                    {line}
                    <br />
                </div>
            ))}
        </div>
    );
}

ModelRunError.propTypes = {
    modelRun: PropTypes.shape({
        error: PropTypes.string,
    }),
};

ModelRunError.defaultProps = {
    modelRun: null,
};
