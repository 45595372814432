import { createReducer, createActions } from 'reduxsauce';
import { apiHandlers } from '../../../../models/base/reducer';
import { Types as projectTypes } from '../../../Projects/_models/projects/reducers';
import { Types as publicPageTypes } from '../publicPages/reducers';
import INITIAL_STATE from './schema';

export const serverError = (state = INITIAL_STATE, action) => {
    if (action.statusCode > 400) return { ...state, statusCode: action.statusCode };
    return state;
};

export const getMyUserSuccess = (state = INITIAL_STATE, { user }) => ({
    ...state,
    errors: {},
    hydrated: true,
    loading: false,
    user,
});

export const getMyOrganizationSuccess = (state = INITIAL_STATE, { organization }) => ({
    ...state,
    errors: {},
    hydrated: true,
    loading: false,
    organization,
});

export const updateLogoSuccess = (state = INITIAL_STATE, action) => {
    if (action.id === state.organization._id) {
        return {
            ...state,
            organization: {
                ...state.organization,
                ...action.response,
            },
        };
    }
    return state;
};

export const updateUserSuccess = (state = INITIAL_STATE, action) => {
    if (action.id === state.user._id) {
        return {
            ...state,
            user: {
                ...state.user,
                ...action.user,
            },
        };
    }
    return state;
};

const getPermissionsSuccess = (state = INITIAL_STATE, action) => {
    const myPermission = action.permissions.find(
        (permission) => permission.userId === state.user._id && permission.projectId === action.projectId,
    );
    return { ...state, permission: myPermission };
};

const setPublicPermission = (state = INITIAL_STATE, { permission }) => ({
    ...state,
    public: true,
    permission,
});

const clearPermission = (state) => {
    const newState = { ...state };
    delete newState.permission;
    return newState;
};

const { Creators, Types } = createActions(
    {
        getMyUserRequest: [],
        getMyUserSuccess: ['user'],
        getMyUserFailure: ['errors'],
        getMyOrganizationRequest: [],
        getMyOrganizationSuccess: ['organization'],
        getMyOrganizationFailure: ['errors'],
        getPermissionsSuccess: ['projectId', 'permissions'],
    },
    { prefix: 'APP_' },
);

const HANDLERS = {
    SERVER_ERROR: serverError,
    ORGANIZATIONS_UPDATE_LOGO_SUCCESS: updateLogoSuccess,
    USERS_UPDATE_SUCCESS: updateUserSuccess,
    [Types.GET_MY_USER_SUCCESS]: getMyUserSuccess,
    [Types.GET_MY_ORGANIZATION_SUCCESS]: getMyOrganizationSuccess,
    [Types.GET_PERMISSIONS_SUCCESS]: getPermissionsSuccess,
    [projectTypes.CLEAR_RESOURCES]: clearPermission,
    [publicPageTypes.SHOW_SUCCESS]: setPublicPermission,
};

export default createReducer(INITIAL_STATE, apiHandlers(['GET_MY_USER', 'GET_MY_ORGANIZATION'], Types, HANDLERS));

export { Creators as Actions, Types };
