import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useDrop } from 'react-dnd';

import { IconButton } from '../../../shared';
import { usePreviewItems } from '../../../shared/NuggetBucket/helpers';
import NuggetBucketDropArea from '../../../shared/NuggetBucket/droparea';
import MappingBucketItem from './item';
import { makePreviewItem } from './helpers';

require('./styles.scss');

export default function MappingBucket({
    id,
    series,
    onChange,
    validation,
    horizontal,
    expandable,
    label,
    placeholder,
    additionalSerumValues,
    startsExpanded,
    allowMultiple,
    required,
    type,
    render,
    className,
}) {
    // Set the default state to expanded
    const [expanded, toggleExpanded] = useState(startsExpanded);

    const items = series.map((serum) => ({
        serum,
        containerId: id,
        id: serum.id,
        type,
    }));

    const [previewItems, _setPreviewItem, tempItem] = usePreviewItems(id, items);
    const setPreviewItem = (item, index) => _setPreviewItem(makePreviewItem(item, additionalSerumValues), index);
    const handleHoverAfter = (dragItem) => setPreviewItem(dragItem, items.length);

    const removeSerum = useCallback(
        (index) => {
            const nextSeries = [...series];
            nextSeries.splice(index, 1);
            onChange(id, nextSeries);
        },
        [id, series, onChange],
    );

    // add drag and drop interaction
    const [{ isOver, canDrop, hoveredItem }, drop] = useDrop({
        accept: ['feature', type],
        canDrop: (dragItem) =>
            (allowMultiple || items.length === 0) &&
            validation(
                items.filter((item) => item.id !== dragItem.value.id).map((item) => item.serum),
                makePreviewItem(dragItem.value, additionalSerumValues).serum,
            ),
        collect: (monitor) => ({
            hoveredItem: monitor.getItem() && monitor.getItem().value,
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        drop: () => {
            onChange(
                id,
                previewItems.map((item) => item.serum),
            );
            return { containerId: id };
        },
    });

    const itemsToRender = isOver && canDrop && tempItem && tempItem.id === hoveredItem.id ? previewItems : items;

    return (
        <div
            className={ClassNames('MappingBucket', className, {
                'MappingBucket--horizontal': horizontal,
                'MappingBucket--vertical': !horizontal,
                'MappingBucket--collapsed': !expanded,
                'MappingBucket--droppable': isOver && canDrop,
                'MappingBucket--empty': itemsToRender.length === 0,
                'MappingBucket--required': required,
            })}
        >
            <header className="MappingBucket__header">
                <div className="MappingBucket__title">{label}</div>
                {expandable && (
                    <IconButton
                        icon={`${expanded ? 'angle-up' : 'angle-down'}`}
                        onClick={() => toggleExpanded(!expanded)}
                        mini
                    />
                )}
            </header>
            {expanded && (
                <main ref={drop} className="MappingBucket__main">
                    <div className="MappingBucket__content">
                        {itemsToRender.map((item, i) => (
                            <MappingBucketItem
                                key={item.id}
                                serum={item.serum}
                                value={item} // the whole item is passed as a value so that we can preview the items correctly
                                containerIndex={i}
                                onRemove={() => removeSerum(i)}
                                onHover={setPreviewItem}
                                canDrop={canDrop}
                                horizontal={horizontal}
                                isPreview={item.containerId !== id}
                                render={render}
                            />
                        ))}
                    </div>
                    {(allowMultiple || itemsToRender.length === 0) && (
                        <NuggetBucketDropArea accept={['feature', type]} onHover={handleHoverAfter}>
                            <div className="MappingBucket__placeholder">{placeholder}</div>
                        </NuggetBucketDropArea>
                    )}
                </main>
            )}
        </div>
    );
}

MappingBucket.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    expandable: PropTypes.bool,
    horizontal: PropTypes.bool,
    series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    placeholder: PropTypes.string,
    allowMultiple: PropTypes.bool,
    label: PropTypes.string,
    startsExpanded: PropTypes.bool,
    validation: PropTypes.func,
    required: PropTypes.bool,
    additionalSerumValues: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    type: PropTypes.string,
    render: PropTypes.func.isRequired,
    className: PropTypes.string,
};

MappingBucket.defaultProps = {
    id: '',
    expandable: false,
    horizontal: false,
    placeholder: '',
    allowMultiple: false,
    label: '',
    startsExpanded: true,
    onChange: () => {},
    validation: () => true,
    required: false,
    additionalSerumValues: {},
    type: 'series',
    className: '',
};
