import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import AppRouter from './router';
import './i18n'; // Import the i18n configuration

require('./styles/index.scss');

const render = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <Component />
        </Provider>,
        document.getElementById('root'),
    );
};

if (!process.env.STORYBOOK_ACTIVE && process.env.NODE_ENV !== 'test') {
    render(AppRouter);
}
