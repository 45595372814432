import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import { Saturation, Hue, Alpha } from 'react-color/lib/components/common';
import ContextMenu from '../ContextMenu';
import ContextSubMenu from '../ContextMenu/ContextSubMenu';
import Input from '../Input';

require('./styles.scss');

// custom color picker using react-color components
const CustomReactColorPicker = CustomPicker((props) => {
    const { onInputBlur, inputValue, setInputValue } = props;
    return (
        <div className="CustomPicker">
            <div className="CustomPicker__Saturation">
                <Saturation {...props} />
            </div>
            <div className="CustomPicker__Hue">
                <Hue {...props} />
            </div>
            <div className="CustomPicker__Alpha">
                <Alpha {...props} />
            </div>
            <div className="CustomPicker__Input">
                <Input
                    id="CustomPicker__Input"
                    type="text"
                    submitOnBlur
                    onChange={(_, value) => setInputValue(value)}
                    onSubmit={onInputBlur}
                    dark
                    sm
                    value={inputValue}
                />
            </div>
        </div>
    );
});

export default function ColorPicker(props) {
    const { color, open, position, onClickOut, onPicked } = props;

    const [inputValue, setInputValue] = useState(color.toUpperCase());

    return (
        <ContextMenu
            className="ColorPicker"
            open={open}
            position={position}
            onClickOut={() => {
                if (inputValue !== color) onPicked(inputValue);
                onClickOut();
            }}
        >
            <div className="ColorPicker__label">Select Color</div>
            <ContextSubMenu>
                <CustomReactColorPicker
                    color={inputValue}
                    onChange={(newColor) => {
                        let hexString = newColor.hex;
                        const alpha = newColor.rgb.a;
                        if (alpha < 1) {
                            const alphaHex = Math.floor(alpha * 255).toString(16);
                            if (alphaHex.length === 1) hexString += '0';
                            hexString += alphaHex;
                        }
                        setInputValue(hexString);
                        onPicked(hexString);
                    }}
                    onInputBlur={(_, value) => onPicked(value)}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </ContextSubMenu>
        </ContextMenu>
    );
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    onPicked: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onClickOut: PropTypes.func,
    position: PropTypes.string,
};

ColorPicker.defaultProps = {
    color: '',
    open: false,
    onClickOut: () => {},
    position: 'left',
};
