import { createReducer } from 'reduxsauce';
import { makeCrudReducers, initialState, apiHandlers, actions } from '../../../../models/base';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('permission', {
    listByProjectRequest: ['projectId'],
    listByProjectSuccess: ['permissions'],
    listByProjectFailure: ['errors'],
});
export { Creators as Actions, Types };

// ---------------- ACTION HANDLERS ----------------

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('permission', INITIAL_STATE, {}, Types);
const additionalReducers = apiHandlers(['LIST_BY_PROJECT'], Types, {
    [Types.LIST_BY_PROJECT_SUCCESS]: (state, { permissions }) =>
        crudReducers[Types.INDEX_SUCCESS](state, { permissions: { list: permissions } }),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
