import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { ModelModel } from '../../_models';

import ModelInput from '../ModelInput';
import { Icon, Button, VerticalLine, ToolTip } from '../../../shared';
import SidePanelButton from '../../../shared/SidePanel/SidePanelButton';

require('./styles.scss');

export default function ModelBar(props) {
    const { modelId, disabled, hidden, runModel: runModelProp, setModelPanelId, showModelPanelButton } = props;
    const dispatch = useDispatch();
    const model = useSelector((state) => state.models.byId[modelId]);

    const inputs = model && model.inputs;
    const modelBarInputs = inputs ? inputs.filter((input) => input.display?.modelBar) : [];

    const runModel = runModelProp || (() => dispatch(ModelModel.run(model._id)));
    const handleInputsChange = (newInputs) => {
        dispatch(ModelModel.updateLocal(modelId, { inputs: newInputs }));
    };

    if (!model || !model.inputs) return null;

    return (
        <div
            className={ClassNames('ModelBar', {
                'ModelBar--showModelPanelButton': showModelPanelButton,
                'ModelBar--disabled': disabled,
                'ModelBar--hidden': hidden,
            })}
        >
            <ToolTip tooltip={model && model.name} place="left">
                <Icon icon="brain" size="20px" pulsing={model.running} />
            </ToolTip>
            <VerticalLine height="20px" />
            {modelBarInputs.length > 0 && (
                <div className="ModelBar__inputs">
                    {modelBarInputs.map((input) => (
                        <ModelInput
                            key={input.varName}
                            inputs={inputs}
                            input={input}
                            onInputsChange={handleInputsChange}
                            disabled={disabled}
                            inputProps={{ sm: true, autosize: true, inlineLabel: true, horizontal: true }}
                        />
                    ))}
                </div>
            )}
            <Button blue disabled={disabled || model.running} small onClick={runModel}>
                {model.running ? 'running...' : model.runButtonText || 'Run'}
            </Button>
            {showModelPanelButton && (
                <SidePanelButton id="openModelPanel" icon="menu-horizontal" onClick={() => setModelPanelId(modelId)} />
            )}
        </div>
    );
}

ModelBar.propTypes = {
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    modelId: PropTypes.string,
    runModel: PropTypes.func,
    setModelPanelId: PropTypes.func,
    showModelPanelButton: PropTypes.bool,
};

ModelBar.defaultProps = {
    disabled: false,
    hidden: false,
    modelId: null,
    runModel: null,
    setModelPanelId: () => {},
    showModelPanelButton: false,
};
