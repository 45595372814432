import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import UserModel from '../../../_models/users';
import AppModel from '../../../_models/app';
import UserProfile from './Tabs/Profile';
import UserPassword from './Tabs/Password';
import UserLoginTimeline from './Tabs/LoginHistory';
import { Tabs, Loader } from '../../../../shared';

require('./styles.scss');

export default function UserSettings(props) {
    const { userId: propsUserId, organizationId: propsOrganizationId } = props;

    const dispatch = useDispatch();
    const myUserId = useSelector((state) => state.app.user._id);
    const myOrganizationId = useSelector((state) => state.app?.organization?._id);
    const myRoles = useSelector((state) => state.app?.user?.roles);

    const userId = propsUserId || myUserId;
    const organizationId = propsOrganizationId || myOrganizationId;

    const user = useSelector((state) => state.users.byId[userId]);

    if (!userId || !user) return <Loader type="content" loader="content" />;

    const tabs = [
        {
            label: 'Profile',
            element: (
                <UserProfile
                    onSubmit={(form) => {
                        dispatch(UserModel.updateDetails(userId, form)).then(() => {
                            if (userId === myUserId) dispatch(AppModel.getMyUser()); // make sure user in app stays up to date
                        });
                    }}
                    user={user}
                    myRoles={myRoles}
                    onRoleChange={(value) => dispatch(UserModel.update(userId, { roles: value }))}
                    resendValidationEmail={() => dispatch(UserModel.resendValidationEmail(userId))}
                    showUserAdmin={
                        userId !== myUserId &&
                        (myRoles.manageAllOrganizations ||
                            (myRoles.manageOrganizationUsers && organizationId === myOrganizationId))
                    }
                />
            ),
        },
        {
            label: 'Password',
            element: <UserPassword onSubmit={(form) => dispatch(UserModel.updatePassword(userId, form))} />,
        },
        {
            label: 'Login History',
            element: <UserLoginTimeline userId={userId} />,
        },
    ];

    if (userId !== myUserId || propsUserId) tabs.splice(1, 1); // remove the 'password' tab if it's not your user settings page

    return (
        <div className="UserSettings">
            <Tabs useRoutes={false} tabs={tabs} />
        </div>
    );
}

UserSettings.propTypes = {
    userId: PropTypes.string,
    organizationId: PropTypes.string,
};

UserSettings.defaultProps = {
    userId: null,
    organizationId: null,
};
