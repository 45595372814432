import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { dateFilterOptions } from '../constants';
import FilterHeader from './header';
import DateFilterMain from './DateFilterMain';

// The filter component for date features
// Takes a filter object (value), and calls props.onChange with key:value updates for the object.  Fully controlled.
export default function DateFilterForm(props) {
    const { value: filterValue } = props;
    const { option, operation } = filterValue;
    const headerValue =
        option === 'empty' || option === 'not-empty' ? { option } : { option: `${option}/${operation}` };
    return (
        <div className="DateFilter">
            <FilterHeader {...props} options={dateFilterOptions} value={headerValue} />
            <main className={classNames({ 'DateFilter__main--between': option === 'between' })}>
                <DateFilterMain {...props} />
            </main>
        </div>
    );
}
DateFilterForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    feature: PropTypes.shape({
        name: PropTypes.string,
        precision: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({
        option: PropTypes.string,
        operation: PropTypes.string,
        value: PropTypes.shape({}),
    }).isRequired,
};
