import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Button } from '../../../shared';
import userModel from '../../_models/users';

require('./styles.scss');

export default function PopOver(props) {
    const { title, body, arrow, className, buttonText, style, link, type, neverShow } = props;

    const userPopover = useSelector(
        (state) => state.app.user.popOvers || { mappingPopovers: true, datasetPopovers: true, modelPopovers: true },
    );

    const isUserNew = userPopover[type] === true;
    const [openPopOver, setOpenPopOver] = useState(isUserNew);

    const userId = useSelector((state) => state.app.user._id);
    const dispatch = useDispatch();

    const popOver = (
        <div
            className={ClassNames('PopOver', className, {
                'PopOver--NoTitle': title === '',
                'PopOver--Top': arrow === 'top',
                'PopOver--Right': arrow === 'right',
                'PopOver--Bottom': arrow === 'bottom',
                'PopOver--Left': arrow === 'left',
            })}
            style={style}
        >
            <div className="PopOver__title">{title}</div>
            <div className="PopOver__body">
                {body}{' '}
                {link && (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        Learn more
                    </a>
                )}
            </div>
            <Button
                className="PopOver__button"
                id="PopOverId"
                onClick={() => {
                    if (neverShow) {
                        setOpenPopOver(false);
                        dispatch(userModel.update(userId, { popOvers: { ...userPopover, [type]: false } }));
                    } else {
                        setOpenPopOver(false);
                    }
                }}
                blue
                small
            >
                {buttonText}
            </Button>
        </div>
    );

    return openPopOver && userPopover && userPopover[type] ? popOver : null;
}

PopOver.propTypes = {
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    link: PropTypes.string,
    buttonText: PropTypes.string,
    arrow: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    type: PropTypes.string,
    neverShow: PropTypes.bool,
};

PopOver.defaultProps = {
    title: '',
    body: null,
    link: '',
    buttonText: 'Got it!',
    arrow: '',
    className: '',
    style: null,
    type: '',
    neverShow: false,
};
