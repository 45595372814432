import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../shared';

const decimalOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export default function NumberConfig({ allowDecimal, updateConfig, editable }) {
    const [decimalValue, setDecimalValue] = useState(allowDecimal);
    const updateDecimalValue = (_, value) => {
        setDecimalValue(value);
        updateConfig('allowDecimal', value);
    };

    return (
        <div className="FormQuestion__configInputs">
            <Input
                id="allowDecimal"
                type="select"
                gray
                label="Allow Decimal"
                value={decimalValue}
                options={decimalOptions}
                onChange={updateDecimalValue}
                disabled={!editable}
            />
        </div>
    );
}

NumberConfig.propTypes = {
    allowDecimal: PropTypes.bool,
    updateConfig: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
};

NumberConfig.defaultProps = {
    allowDecimal: true,
};
