import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../../shared';
import { createOptionsString } from '../../../../../shared/Input/helpers';

require('./styles.scss');

const OptionsConfig = (props) => {
    const { config, updateConfig } = props;

    const [optionsString, setOptionsString] = useState(createOptionsString(config?.options));

    return (
        <Input
            id="options"
            type="textarea"
            gray
            label="List Items (Separate with commas)"
            value={optionsString}
            onChange={(_, value) => setOptionsString(value)}
            onSubmit={() => updateConfig({ ...config, options: optionsString || '' })}
            submitOnBlur
        />
    );
};

OptionsConfig.propTypes = {
    config: PropTypes.shape({
        options: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
                }),
            ),
        ]),
    }),
    updateConfig: PropTypes.func.isRequired,
};
OptionsConfig.defaultProps = {
    config: {},
};

export default OptionsConfig;
