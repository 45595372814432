import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../shared';

const scaleOptions = [
    { label: '1 to 10', value: '1:10' },
    { label: '1 to 5', value: '1:5' },
    { label: '-5 to 5', value: '-5:5' },
];

export default function RatingScaleConfig({ scale, scaleText, updateConfig, editable }) {
    const [leftText, setLeftText] = useState(scaleText?.left);
    const [rightText, setRightText] = useState(scaleText?.right);
    const [scaleValue, setScaleValue] = useState(scale || scaleOptions[0].value);
    const updateScaleText = () => updateConfig('scaleText', { left: leftText, right: rightText });
    const updateScale = (_, value) => {
        setScaleValue(value);
        updateConfig('scale', value);
    };

    return (
        <div className="FormQuestion__ratingScaleConfig FormQuestion__configInputs">
            <Input
                id="leftText"
                type="text"
                gray
                label="Low Value Label"
                value={leftText}
                placeholder="Ex. Strongly Disagree"
                onChange={(_, value) => setLeftText(value)}
                onSubmit={updateScaleText}
                submitOnBlur
                disabled={!editable}
            />
            <Input
                id="rightText"
                type="text"
                gray
                label="High Value Label"
                value={rightText}
                placeholder="Ex. Strongly Agree"
                onChange={(_, value) => setRightText(value)}
                onSubmit={updateScaleText}
                submitOnBlur
                disabled={!editable}
            />
            <Input
                id="scale"
                type="select"
                gray
                label="Scale"
                value={scaleValue}
                options={scaleOptions}
                onChange={updateScale}
                disabled={!editable}
            />
        </div>
    );
}

RatingScaleConfig.propTypes = {
    updateConfig: PropTypes.func.isRequired,
    scale: PropTypes.string,
    scaleText: PropTypes.shape({ left: PropTypes.string, right: PropTypes.string }),
    editable: PropTypes.bool.isRequired,
};

RatingScaleConfig.defaultProps = {
    scale: null,
    scaleText: null,
};
