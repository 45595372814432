import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { IconButton, ContextMenu, ContextSubMenu, ContextMenuItem, Icon } from '..';

require('./styles.scss');

export default function SelectableItem(props) {
    const {
        onClick,
        type,
        name,
        info,
        style,
        selected,
        options,
        optionsHeading,
        deactivated,
        className,
        subName,
        flag,
        endDate,
        scheduledRun,
    } = props;

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div
            className={ClassNames('SelectableItem', className, {
                'SelectableItem--selected': selected,
                'SelectableItem--deactivated': deactivated,
            })}
            onClick={onClick}
            role="menuitem"
            tabIndex={0}
            style={style}
        >
            {type && <div className="SelectableItem__type">{type}</div>}
            <div className="SelectableItem__body">
                <div className="SelectableItem__name">
                    <div className="SelectableItem__name">
                        {name}
                        <div className="SelectableItem__subName"> {subName} </div>
                        {flag && <div className="SelectableItem__flag"> (Paused) </div>}
                    </div>
                    {flag && (
                        <div className="SelectableItem__endingSoon">
                            {' '}
                            <Icon
                                classname="SelectableItem__endingSoon__icon"
                                icon="exclamation-triangle"
                                size="16px"
                            />
                            Ending soon ({endDate})
                        </div>
                    )}
                </div>

                {info && <div className="SelectableItem__info"> {info} </div>}
            </div>
            {options?.length > 0 && (
                <IconButton
                    small
                    icon={scheduledRun ? 'trash' : 'menu-vertical'}
                    className="SelectableItem__menu"
                    onClick={() => setMenuOpen(!menuOpen)}
                    active={menuOpen}
                >
                    <ContextMenu
                        open={menuOpen}
                        onClickOut={() => setMenuOpen(false)}
                        position="right top"
                        listHeading={optionsHeading && name}
                    >
                        <ContextSubMenu>
                            {options.map((option) => (
                                <ContextMenuItem
                                    key={option.id}
                                    {...option}
                                    onClick={() => {
                                        option.onClick();
                                        if (!option.children) {
                                            // option.children is currently being used for the confirmDeleteMenu
                                            // we do not want to close the menu in this case
                                            setMenuOpen(false);
                                        }
                                    }}
                                />
                            ))}
                        </ContextSubMenu>
                    </ContextMenu>
                </IconButton>
            )}
        </div>
    );
}

SelectableItem.propTypes = {
    name: PropTypes.string.isRequired,
    subName: PropTypes.string,
    flag: PropTypes.bool,
    info: PropTypes.string,
    type: PropTypes.node,
    style: PropTypes.shape({}),
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    options: PropTypes.array,
    optionsHeading: PropTypes.bool,
    deactivated: PropTypes.bool,
    className: PropTypes.string,
    child: PropTypes.shape({}),
    scheduledRun: PropTypes.bool,
    endDate: PropTypes.string,
};
SelectableItem.defaultProps = {
    subName: null,
    flag: false,
    info: null,
    type: null,
    style: {},
    selected: false,
    options: [],
    optionsHeading: false,
    deactivated: false,
    className: '',
    onClick: null,
    child: {},
    scheduledRun: false,
    endDate: 'dasa',
};
