import React from 'react';
import FormMapping from './Mapping';
import FormList from './List';
import { ProjectProtectedRoute } from '../../helpers/router';

export default [
    {
        path: '',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ form: 'view' }}>
                <FormList />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: ':formId',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ form: 'edit' }}>
                <FormMapping />
            </ProjectProtectedRoute>
        ),
    },
];
