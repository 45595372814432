import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FormModel from '../redux';

import { PageWithSubNav } from '../../../helpers/layout';
import { Button, EditableTitle, Icon, IconButton, ModalCard, Input, Loader } from '../../shared';
import ElementMapping from '../Elements/Mapping';
import PublishModal from './PublishModal';
import FormPreview from './FormPreview';

import './styles.scss';

export default function FormMapping() {
    const { formId } = useParams();
    const form = useSelector((state) => state.forms.byId[formId]);
    const editable = form && !form.published;

    const dispatch = useDispatch();
    useEffect(() => dispatch(FormModel.show(formId)), [dispatch, formId]);

    const updateForm = (obj) => dispatch(FormModel.update(formId, obj));
    const updateElement = (elementId, element) =>
        editable && dispatch(FormModel.updateElement(formId, elementId, element));
    const deleteElement = (elementId) => editable && dispatch(FormModel.deleteElement(formId, elementId));
    const createElement = () => editable && dispatch(FormModel.createElement(formId));

    const [preview, setPreview] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [copiedUrl, setCopiedUrl] = useState(false);
    const moveElement = (elementId, step) => {
        const elements = [...form.elements];
        const elementIndex = elements.findIndex((element) => element._id === elementId);
        const element = elements.splice(elementIndex, 1)[0];
        elements.splice(elementIndex + step, 0, element);
        updateForm({ elements });
    };

    const [publishing, setPublishing] = useState(false);

    const onPublish = () => {
        setPublishModal(false);
        setPublishing(true);
        dispatch(FormModel.publish(formId));
    };

    const duplicateElement = (elementId) => {
        const elements = [...form.elements];
        const elementIndex = elements.findIndex((element) => element._id === elementId);
        const { _id, ...newElementObject } = elements[elementIndex];
        if (editable) dispatch(FormModel.createElement(formId, newElementObject, elementIndex + 1));
    };

    // eslint-disable-next-line no-restricted-globals
    const publicUrl = `${location.origin}/public/forms/${formId}`;
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(publicUrl);
        setCopiedUrl(true);
        setTimeout(() => {
            setCopiedUrl(false);
        }, 2000);
    };

    if (!form) return <Loader />;
    return (
        <PageWithSubNav>
            <div className="FormMapping">
                <header className="FormMapping__top">
                    <div className="FormMapping__topSection">
                        <EditableTitle defaultValue={form.name} onSubmit={(_, value) => updateForm({ name: value })} />
                        {form.published && (
                            <Input
                                id="closed"
                                gray
                                type="switch"
                                label="Collecting Responses"
                                inlineLabel
                                labelRight
                                value={!form.closed}
                                onChange={() => updateForm({ closed: !form.closed })}
                                className="FormMapping__closedInput"
                            />
                        )}
                    </div>
                    <div className="FormMapping__topSection">
                        <IconButton id="preview" icon="visible" onClick={() => setPreview(true)} tooltip="Preview" />
                        <Button
                            id="publish"
                            blue
                            onClick={() => setPublishModal(true)}
                            disabled={form.published || publishing}
                            loading={publishing && !form.published}
                            loadingText="Publishing"
                        >
                            {form.published ? 'Published' : 'Publish Form'}
                        </Button>
                    </div>
                </header>
                {form.published && !form.closed && (
                    <div className="FormMapping__prompt">
                        <Icon icon="large_color/form-green" size="75px" />
                        <h3>Your form is live!</h3>
                        <p>
                            You can now share the{' '}
                            <a href={publicUrl} target="_blank" rel="noreferrer">
                                link
                            </a>{' '}
                            with your audience and view the results as a dataset.
                        </p>
                        <div className="FormMapping__copyLink">
                            <Button
                                label="Copy link"
                                gray
                                onClick={copyLinkToClipboard}
                                tooltip={copiedUrl ? 'Copied' : null}
                            >
                                {copiedUrl ? 'Copied!' : 'Copy Link'}
                            </Button>
                        </div>
                    </div>
                )}
                {form.closed && form.published && (
                    <div className="FormMapping__prompt">
                        <Icon icon="large_color/form-orange" size="75px" />
                        <h3>Your form is closed.</h3>
                        <p>Reopen it to collect responses again.</p>
                    </div>
                )}
                <div className="FormMapping__elements">
                    {form.elements.map((element, i) => (
                        <ElementMapping
                            key={element._id || 'new'}
                            element={element}
                            updateElement={(updates) => updateElement(element._id, updates)}
                            deleteElement={() => deleteElement(element._id)}
                            moveUp={i > 1 ? () => moveElement(element._id, -1) : null}
                            moveDown={i < form.elements.length - 1 ? () => moveElement(element._id, 1) : null}
                            moveToTop={i > 1 ? () => moveElement(element._id, 1 - i) : null}
                            moveToBottom={
                                i < form.elements.length - 1
                                    ? () => moveElement(element._id, form.elements.length - i - 1)
                                    : null
                            }
                            duplicate={() => duplicateElement(element._id)}
                            editable={editable}
                        />
                    ))}
                    {editable && form.elements.length > 1 && (
                        <Button blue onClick={createElement}>
                            Add Question
                        </Button>
                    )}
                    {editable && form.elements.length === 1 && (
                        <div className="FormMapping__prompt">
                            <Icon icon="large_color/form-purple" size="75px" />
                            <p>
                                Add questions here, then <strong>publish your form</strong> the create a new dataset
                                from it.
                            </p>
                            <Button blue onClick={createElement}>
                                Add Question
                            </Button>
                        </div>
                    )}
                </div>
                <ModalCard
                    open={preview}
                    title={`Preview Form - ${form.name}`}
                    onClose={() => setPreview(false)}
                    className="FromPreviewModal"
                >
                    <div className="FormPreviewModal__preview">
                        <FormPreview form={form} />
                    </div>
                </ModalCard>

                {!form.published && (
                    <PublishModal
                        open={publishModal}
                        onClose={() => setPublishModal(false)}
                        onPublish={onPublish}
                        form={form}
                    />
                )}
            </div>
        </PageWithSubNav>
    );
}

FormMapping.propTypes = {};
FormMapping.defaultProps = {};
