import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DashboardModel from '../_models/dashboards';

require('./styles.scss');

const linearGradient = 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(191, 198, 220, 0.2) 100%)';

export default function DashboardImage(props) {
    const { dashboard } = props;

    const [image, setImage] = useState(null);
    const [message, setMessage] = useState(null);
    const [retry, setRetry] = useState(0);

    useEffect(() => {
        if (dashboard.timeCreated && new Date() - new Date(dashboard.timeCreated) < 10000) {
            setMessage('New dashboard');
        }
    }, [dashboard.timeCreated, setMessage]);

    const getPreview = (id) =>
        DashboardModel.getPreview(id).then((response) => {
            if (response.url) {
                setImage(response.url);
            } else {
                setMessage('Generating preview...');
                setRetry((r) => r + 1);
            }
        });

    useEffect(() => {
        if (dashboard.timeCreated && new Date() - new Date(dashboard.timeCreated) > 10000) {
            getPreview(dashboard._id);
        }
    }, [dashboard._id, dashboard.timeCreated]);

    useEffect(() => {
        if (!retry) return () => {};
        const fetchPreview = () => getPreview(dashboard._id);
        setTimeout(fetchPreview, 5000);
        return () => clearTimeout(fetchPreview); // don't execute fetchPreview if component unmounts
    }, [dashboard._id, retry]);

    return image ? (
        <div className="DashboardImage" style={{ backgroundImage: `${linearGradient}, url(${image})` }} />
    ) : (
        <div className="DashboardImage" style={{ backgroundImage: linearGradient }}>
            {message}
        </div>
    );
}

DashboardImage.propTypes = {
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        sourcesCount: PropTypes.number,
        user: PropTypes.shape({ _id: PropTypes.string, email: PropTypes.string }),
        timeCreated: PropTypes.string,
    }).isRequired,
};

DashboardImage.defaultProps = {};
