import { defaultGridProps } from './constants';

export function getResponsiveLayouts(desktopLayout) {
    const layouts = { desktop: desktopLayout };

    const sortedItems = desktopLayout.sort((a, b) => {
        const yDifference = a.y - b.y;
        if (yDifference !== 0) return yDifference;
        return a.x - b.x;
    });

    const mobileLayout = [];
    let y = 0;
    for (let i = 0; i < sortedItems.length; i++) {
        const item = sortedItems[i];
        mobileLayout.push({ ...item, x: 0, w: 2, y });
        y = y + item.h;
    }
    layouts.mobile = mobileLayout;

    return layouts;
}

export function getBreakPoint() {
    const width = window.innerWidth - 50; // 50px for grid margin
    let breakpointWidth = 0;
    let breakpointName;
    const { breakpoints } = defaultGridProps;
    Object.keys(breakpoints).forEach((bp) => {
        if (width > breakpoints[bp] && breakpoints[bp] >= breakpointWidth) {
            breakpointWidth = breakpoints[bp];
            breakpointName = bp;
        }
    });
    return breakpointName;
}

export function getColumnCount() {
    const breakpoint = getBreakPoint();
    return defaultGridProps.cols[breakpoint];
}
