//
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TimelineItem from './TimelineItem';

require('./styles.scss');

export default class Timeline extends PureComponent {
    render() {
        const { align, sort, expandDiv, extraDiv, items } = this.props;
        const sortedItems = items.sort(sort);

        return (
            <div className="Timeline">
                {sortedItems.map((item, index) => {
                    const { time, title, status, onExpand } = item;
                    const key = `TimeLine-${title}-${index}`;
                    const centerAlign = index % 2 === 0 ? 'left' : 'right';
                    const extraDivForItem = extraDiv && extraDiv(item);
                    const childDiv = expandDiv && expandDiv(item);
                    const drawLine = index < sortedItems.length - 1;

                    return (
                        <div
                            key={key}
                            className={`Timeline__itemContainer Timeline__itemContainer--${
                                align === 'center' ? align + centerAlign : align
                            }`}
                        >
                            <TimelineItem
                                align={align === 'center' ? centerAlign : align}
                                drawLine={drawLine}
                                status={status}
                                time={time}
                                title={title}
                                onExpand={onExpand}
                                extraDiv={extraDivForItem}
                            >
                                {childDiv}
                            </TimelineItem>
                        </div>
                    );
                })}
            </div>
        );
    }
}

Timeline.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.string,
            title: PropTypes.string,
            status: PropTypes.string,
            onExpand: PropTypes.func,
        }),
    ),
    align: PropTypes.string,
    sort: PropTypes.func,
    expandDiv: PropTypes.func,
    extraDiv: PropTypes.func,
};

Timeline.defaultProps = {
    items: [],
    align: 'right',
    sort: (a, b) => (a.time <= b.time ? 1 : -1),
    expandDiv: null,
    extraDiv: null,
};
