const pieMapping = {
    xAxis: { disabled: true },
    yAxis: {
        title: 'Value',
        id: 'yNugget',
        placeholder: 'Drag Y Data',
        operationInputId: 'yOperation',
        titleInputId: 'yAxisTitle',
    },
    groupBy: {},
    orderBy: {},
    chart: {
        xAxis: false,
        yAxis: true,
    },
    tooltip: {},
};

const radarMapping = {
    xAxis: {
        title: 'Axis',
        horizontal: true,
        id: 'xNugget',
        placeholder: 'Drag X Data',
        operationInputId: 'xOperation',
        titleInputId: 'xAxisTitle',
    },
    yAxis: {
        title: 'Value',
        id: 'yNugget',
        placeholder: 'Drag Y Data',
        operationInputId: 'yOperation',
        titleInputId: 'yAxisTitle',
    },
    groupBy: {},
    orderBy: {},
    chart: {
        xAxis: true,
        yAxis: true,
    },
    tooltip: { disabled: true }, // cannot pass any data other than independent & dependent to echarts radar component
};

const xyMapping = {
    xAxis: {
        title: 'X Axis',
        horizontal: true,
        id: 'xNugget',
        placeholder: 'Drag X Data',
        operationInputId: 'xOperation',
        titleInputId: 'xAxisTitle',
    },
    yAxis: {
        title: 'Y Axis',
        id: 'yNugget',
        placeholder: 'Drag Y Data',
        operationInputId: 'yOperation',
        titleInputId: 'yAxisTitle',
    },
    groupBy: {},
    orderBy: {},
    chart: {
        xAxis: true,
        yAxis: true,
    },
    tooltip: {},
};

export const xyChartTypes = {
    area: {
        icon: 'chart/area',
        dataType: 'xy',
        mapping: xyMapping,
        tooltip: 'Area',
    },
    line: {
        icon: 'chart/line',
        dataType: 'xy',
        mapping: xyMapping,
        tooltip: 'Line',
    },
    scatter: {
        icon: 'chart/scatter',
        dataType: 'xy',
        mapping: {
            ...xyMapping,
            zAxis: { display: true, title: 'Dot Size' },
            gradientAxis: { display: true, title: 'Gradient' },
        },
        tooltip: 'Scatter',
    },
    bar: {
        icon: 'chart/bar',
        dataType: 'xy',
        mapping: xyMapping,
        tooltip: 'Bar',
    },
};
export const otherChartTypes = {
    pie: {
        icon: 'chart/pie',
        dataType: 'y',
        mapping: pieMapping,
        tooltip: 'Pie',
    },
    radar: {
        icon: 'chart/radar',
        dataType: 'xy',
        mapping: radarMapping,
        tooltip: 'Radar',
    },
    boxplot: {
        icon: 'chart/boxplot',
        dataType: 'xy',
        mapping: {
            ...xyMapping,
            tooltip: { disabled: true }, // tooltip series does not make sense for boxplot (except outliers or some sort of aggregation across the dataset)
        },
        tooltip: 'Box Plot',
    },
};

export const chartTypes = {
    ...xyChartTypes,
    ...otherChartTypes,
};

export const getMultiSeriesOptionsForType = (currentChartType) => {
    if (Object.keys(xyChartTypes).includes(currentChartType)) {
        return Object.keys(xyChartTypes).map((chartType) => ({
            id: chartType,
            icon: xyChartTypes[chartType].icon,
        }));
    }
    return null;
};

// reduceFunction(acc, independentSerum, dependentSerum): function which will be reduced over axes and series, and is passed null if a serum does not exist
// accumulator: starting value of acc passed into reduce
// returns the accumulated value over all axes and series
export const reduceOverDependentAndIndependentAxes = (dependentAxes, independentAxes, accumulator, reduceFunction) => {
    if (!dependentAxes.length) return reduceFunction(accumulator, null, null);

    // loop through dependent axes
    return dependentAxes.reduce((accDependentAxes, dependentAxis) => {
        if (!dependentAxis.series.length) return reduceFunction(accDependentAxes, null, null);

        // loop through dependent series of this axis
        return dependentAxis.series.reduce((accDependentSeries, dependentSerum) => {
            if (!independentAxes.length) return reduceFunction(accDependentSeries, null, dependentSerum);

            // loop through independent axes (for this dependent serum)
            return independentAxes.reduce((accIndependentAxes, independentAxis) => {
                if (!independentAxis.series.length) return reduceFunction(accIndependentAxes, null, dependentSerum);

                // loop through independent series of this axis (for this dependent serum)
                return independentAxis.series.reduce(
                    (accIndependentSeries, independentSerum) =>
                        reduceFunction(accIndependentSeries, independentSerum, dependentSerum),
                    accIndependentAxes,
                );
            }, accDependentSeries);
        }, accDependentAxes);
    }, accumulator);
};

// default main chart type to 'area'
export const getMainChartType = (vis) => vis.chartType || 'area';
export const getMainChartTypeMetadata = (vis) => chartTypes[getMainChartType(vis)] || {};
