import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import InternalNugget from './dragdrop';

require('./styles.scss');

export default function Nugget(props) {
    // for the nugget buckets to preview the items correctly,
    // the 'value' of the nugget contains all the nugget props, with some additional metadata
    const { type } = props;
    return <InternalNugget {...props} value={{ nugget: props, containerId: '__none__', id: nanoid(), type }} />;
}

Nugget.propTypes = {
    type: PropTypes.string,
};

Nugget.defaultProps = {
    type: 'feature',
};
