import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import IconButton from '../IconButton';
import SidePanelButton from './SidePanelButton';

require('./styles.scss');

export default function SidePanel(props) {
    const { tabs, className, children, startOpen, width } = props;
    const [open, setOpen] = useState(startOpen);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const currentTab = tabs[currentTabIndex];

    const CurrentTabComponent = currentTab.component;

    return (
        <div className={ClassNames('SidePanel', className, { 'SidePanel--open': open })}>
            <div className="SidePanel__children">{children}</div>
            <div className="SidePanel__main" style={width ? { width } : {}}>
                {open && (
                    <React.Fragment>
                        <SidePanelButton icon="angle-right" onClick={() => setOpen(false)} />
                        <div className="SidePanel__tab">{CurrentTabComponent}</div>
                    </React.Fragment>
                )}
                <div className="SidePanel__sidebar">
                    {tabs.map((tab, i) => (
                        <IconButton
                            key={tab.icon}
                            selected={open && currentTabIndex === i}
                            active={open && currentTabIndex === i}
                            icon={tab.icon}
                            tooltip={tab.tooltip}
                            onClick={() => {
                                if (open && currentTabIndex === i) {
                                    // close
                                    setOpen(false);
                                } else {
                                    // open or navigate while open
                                    setCurrentTabIndex(i);
                                    setOpen(true);
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

SidePanel.propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        }),
    ),
    children: PropTypes.node.isRequired,
    startOpen: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SidePanel.defaultProps = {
    className: '',
    tabs: [],
    startOpen: false,
    width: null,
};
