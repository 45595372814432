/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EventEmitter } from 'events';

import { Loader, Input, Button } from '../../../shared';
import { PackageModel } from '../../_models';

import './styles.scss';
import CodeModal from '../CodeModal';

import { emitter } from '../../../App/Socket/actions';

export default function CodeEditor(props) {
    const { packageId, fileName } = props;
    const dispatch = useDispatch();
    const packages = useSelector((state) => state.packages);
    const packageObj = packages.byId[packageId];
    const [codeModalOpen, setCodeModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(fileName);
    const [fileContent, setFileContent] = useState('');

    if (!selectedFile && fileName) setSelectedFile(fileName); // takes care of initializing state after data is loaded
    const fileExtension = selectedFile?.split('.').pop();

    useEffect(() => {
        if (packageId && !packages.loading) {
            if (!packageObj) {
                dispatch(PackageModel.show(packageId));
            } else if (!packageObj.sourceCode && !packageObj.loading) {
                dispatch(PackageModel.getSourceCode(packageId));
            }
        }
    }, []);

    useEffect(() => {
        if (!packageObj?.sourceCode) {
            setFileContent('');
        } else if (packageObj.sourceCode && fileName === '__init__.py') {
            setFileContent(packageObj.sourceCode[selectedFile]);
        } else if (packageObj[fileName] && fileName !== '__init__.py') {
            setFileContent(packageObj[fileName].join('\r\n'));
        }
    }, [selectedFile]);

    useEffect(() => {
        const handleReset = (id) => {
            if (id === packageId) {
                setFileContent(packageObj.sourceCode[selectedFile]);
            }
        };
        emitter.on('resetCodeEditor', handleReset);
        return () => {
            emitter.off('resetCodeEditor', handleReset);
        };
    }, [packageId, selectedFile]);

    useEffect(() => {
        const handleReset = (id) => {
            if (id === packageId) {
                setFileContent(packageObj.sourceCode[selectedFile]);
            }
        };

        const handleOpen = (id) => {
            if (id === packageId) {
                setCodeModalOpen(true);
            }
        };

        emitter.on('resetCodeEditor', handleReset);
        emitter.on('openCodeEditor', handleOpen);

        return () => {
            emitter.off('resetCodeEditor', handleReset);
            emitter.off('openCodeEditor', handleOpen);
        };
    }, [packageId, selectedFile]);
    
    if (!packageId || !packageObj || !packageObj.sourceCode) {
        if (!packageId) {
            return (
                <Input
                    className="CodeEditor__codeBox"
                    id="algoCode"
                    type="codebox"
                    language="html"
                    value="The source code for this Model is protected and not accessible from this Project."
                    readOnly
                />
            );
        }
        return (
            <div className="CodeEditor">
                <Loader />
            </div>
        );
    }

    const renderHeader = () => {
        if (fileName === '__init__.py') {
            return (
                <div className="CodeEditor__fileMenu">
                    <div className="CodeEditor__title">{packageObj.name}</div>
                    <div className="CodeEditor__headerSlash">/</div>
                    <Input
                        id="sourceFiles"
                        type="select"
                        value={selectedFile}
                        options={Object.keys(packageObj?.sourceCode || {}).map((filename) => ({
                            value: filename,
                            label: filename,
                        }))}
                        onChange={(_, value) => {
                            setSelectedFile(value);
                        }}
                        sm
                    />
                </div>
            );
        }
        return (
            <div className="CodeEditor__fileMenu">
                <div className="CodeEditor__title">{fileName}</div>
            </div>
        );
    };

    const handleSubmit = (fileContentChild) => {
        if (selectedFile.includes('.py')) {
            dispatch(PackageModel.updateCode(packageId, selectedFile, fileContentChild));
        }
        if (selectedFile === 'env') {
            dispatch(PackageModel.updateEnv(packageId, fileContentChild));
        }
        if (selectedFile === 'dependencies') {
            dispatch(PackageModel.updateDependencies(packageId, fileContentChild));
        }

        setCodeModalOpen(false);
        setFileContent(fileContentChild);
    };

    const handleClose = () => {
        setCodeModalOpen(false);
    };

    return (
        <div className="CodeEditor">
            <div className="CodeEditor__header">
                {renderHeader()}
                <Button gray onClick={() => setCodeModalOpen(true)} small>
                    Edit
                </Button>
            </div>
            <Input
                className="CodeEditor__codeBox"
                id="algoCode"
                type="codebox"
                fileExtension={fileExtension}
                value={fileContent}
                readOnly
            />
            <CodeModal
                title={`Edit Code - ${selectedFile}`}
                open={codeModalOpen}
                fileName={fileName}
                fileContent={fileContent}
                fileExtension={fileExtension}
                onClose={handleClose}
                handleSubmit={handleSubmit}
            />
        </div>
    );
}

CodeEditor.propTypes = {
    packageId: PropTypes.string.isRequired,
    fileName: PropTypes.string,
};

CodeEditor.defaultProps = {
    fileName: '__init__.py',
};
