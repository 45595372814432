import memoize from 'memoize-one';
import { getSerumFormatter } from '../../serum';

export * from './redux';

export const formatData = memoize((data, series, features) => {
    const seriesFormatters = series.map((serum) => ({
        id: serum.id,
        formatter: getSerumFormatter(serum, features),
    }));
    return data.map((row) => {
        const formattedRow = {};
        seriesFormatters.forEach((column) => {
            formattedRow[column.id] = column.formatter(row[column.id]);
        });
        return formattedRow;
    });
});
