import React from 'react';
import { ProjectProtectedRoute } from '../../helpers/router';
import Dashboard from './Dashboard';
import DashboardList from './List';

export default [
    {
        path: '',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                <DashboardList />
            </ProjectProtectedRoute>
        ),
    },
    {
        // dashboard with no tabs route
        path: ':dashboardId/',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                <Dashboard />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: ':dashboardId/tabs/:tabId',
        element: (
            <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                <Dashboard />
            </ProjectProtectedRoute>
        ),
    },
];
