export const categoryFilterOptions = [
    { label: 'equals', value: 'equal' },
    { label: 'not equals', value: 'not-equal' },
    { label: 'is empty', value: 'empty' },
    { label: 'is not empty', value: 'not-empty' },
    { label: 'contains', value: 'contains' },
    { label: 'begins with', value: 'begins-with' },
    { label: 'ends with', value: 'ends-with' },
];

export const textFilterOptions = [
    { label: 'contains', value: 'contains' },
    { label: 'equals', value: 'equal' },
    { label: 'begins with', value: 'begins-with' },
    { label: 'ends with', value: 'ends-with' },
    { label: 'is empty', value: 'empty' },
    { label: 'is not empty', value: 'not-empty' },
];

export const dateFilterOptions = [
    { label: 'after day', value: 'after/year-day' },
    { label: 'after month', value: 'after/year-month' },
    { label: 'after year', value: 'after/year' },
    { label: 'before day', value: 'before/year-day' },
    { label: 'before month', value: 'before/year-month' },
    { label: 'before year', value: 'before/year' },
    { label: 'between day', value: 'between/year-day' },
    { label: 'between month', value: 'between/year-month' },
    { label: 'between year', value: 'between/year' },
    { label: 'on day', value: 'on/year-day' },
    { label: 'on day of week', value: 'on/dow' },
    { label: 'on month', value: 'on/year-month' },
    { label: 'on month of year', value: 'on/month' },
    { label: 'on year', value: 'on/year' },
    { label: 'is empty', value: 'empty' },
    { label: 'is not empty', value: 'not-empty' },
    { label: 'last days', value: 'last/year-day' },
    { label: 'last months', value: 'last/year-month' },
    { label: 'last years', value: 'last/year' },
    /*  ==========
    TODO - pending story.js update
    { label: 'on quarter', value: 'on/year-quarter' }
    */
];

export const timeFilterOptions = [
    { label: 'before time', value: 'before' },
    { label: 'after time', value: 'after' },
    { label: 'during time', value: 'equal' },
    { label: 'between time', value: 'between' },
];

export const numberFilterOptions = [
    { label: 'greater than', value: 'greater' },
    { label: 'less than', value: 'less' },
    { label: 'at least', value: 'greater-equal' },
    { label: 'at most', value: 'less-equal' },
    { label: 'between', value: 'between' },
    { label: 'equals', value: 'equal' },
    { label: 'not equals', value: 'not-equal' },
    { label: 'is empty', value: 'empty' },
    { label: 'is not empty', value: 'not-empty' },
];

export const optionsByFilterType = {
    value: numberFilterOptions,
    range: dateFilterOptions,
    category: categoryFilterOptions,
    text: textFilterOptions,
    time: timeFilterOptions,
};
