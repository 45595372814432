import { Actions } from './reducers';
import OrganizationApi from './api';
import { operations } from '../../../../models/base';

const toggleActivate = (id, form) => (dispatch) => {
    dispatch(Actions.toggleActivateRequest(id, form));
    return OrganizationApi.update(id, form).then(
        (resp) => dispatch(Actions.toggleActivateSuccess(id, resp)),
        (error) => dispatch(Actions.toggleActivateFailure(id, error)),
    );
};

const togglePublicSharing = (id, form) => (dispatch) => {
    dispatch(Actions.togglePublicSharingRequest(id, form));
    return OrganizationApi.update(id, form).then(
        (resp) => dispatch(Actions.togglePublicSharingSuccess(id, resp)),
        (error) => dispatch(Actions.togglePublicSharingFailure(id, error)),
    );
};

const showLogs = (id) => (dispatch) => {
    dispatch(Actions.showLogsRequest(id));
    return OrganizationApi.showLogs(id).then(
        (log) => dispatch(Actions.showLogsSuccess(id, log)),
        (error) => dispatch(Actions.showLogsFailure(id, error)),
    );
};

const updateLogo = (id, form) => (dispatch) => {
    dispatch(Actions.updateLogoRequest(id, form));
    return OrganizationApi.updateLogo(id, form).then(
        (organization) => dispatch(Actions.updateLogoSuccess(id, organization)),
        (error) => dispatch(Actions.updateLogoFailure(id, error)),
    );
};

const getClients = (id) => (dispatch) => {
    dispatch(Actions.getClientsRequest(id));
    return OrganizationApi.getClients(id).then(
        (response) => dispatch(Actions.getClientsSuccess(id, response)),
        (error) => dispatch(Actions.getClientsFailure(id, error)),
    );
};

export default operations(Actions, OrganizationApi, {
    toggleActivate,
    togglePublicSharing,
    showLogs,
    updateLogo,
    getClients,
});
