import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ModelModel, PackageModel } from '../../../_models';
import ModelSelector from '../../../_models/models/selectors';

import { DatasetModel, FeatureModel } from '../../../../Data/_models';
import { FullScreenModal, FullScreenModalWorkflowHeader } from '../../../../shared';

import ModelInputs from '../../../Onboard/ModelInputs';

import SelectFunction from './select';
import UpdateOutputs from './UpdateOutputs';

require('./styles.scss');

const UpdateModelModal = (props) => {
    const { open, model, onClose } = props;
    const dispatch = useDispatch();
    const models = useSelector((state) => state.models);
    const datasets = useSelector((state) => Object.values(state.datasets.byId)).filter(
        (dataset) => dataset.source.model?.id === model._id,
    );
    const features = useSelector((state) => state.features);
    // const model = models.byId[modelId]

    useEffect(() => {
        if (model) {
            if (!models.byId[model._id]) dispatch(ModelModel.show(model._id));
        }
    }, [models, model, dispatch]); // Guaranteed to be triggered *after* each update

    const packageId = useSelector(ModelSelector.getPackageId(model._id));
    const moduleFileName = useSelector(ModelSelector.getModuleFilename(model._id));

    const [workflowTabIndex, setWorkflowTabIndex] = useState(0);
    const [metadata, setMetadata] = useState({});
    const [outputMapping, setOutputMapping] = useState(null);

    const closeButton = () => {
        setMetadata({});
        const actualOutputs = model.outputs.map((element) => element.dataset._id);
        datasets
            .filter((dataset) => !actualOutputs.includes(dataset._id))
            .forEach((dataset) => {
                Object.values(features.byId)
                    .filter((feature) => feature.dataset._id === dataset._id)
                    .forEach((featureToDelete) => {
                        dispatch(FeatureModel.destroy(featureToDelete._id));
                    });
                dispatch(DatasetModel.destroy(dataset._id));
            });

        if (actualOutputs.length > 0) {
            // IF MODEL HAD BEEN PREVIOUSLY ONBOARDED, RESET onboarded TO true
            dispatch(ModelModel.update(model._id, { onboarded: true, tempOutputs: null }));
        }
        onClose();
    };

    const onSubmit = () => {
        // go through outputMapping and remove any temp datasets from 1.redux, 2.mongo, 3.sql
        dispatch(ModelModel.updateOutputs(model._id, outputMapping)).then((updatedModel, error) => {
            const actualOutputs = updatedModel.data.model.outputs.map((element) => element.dataset._id);
            // go through each feature related to model update, delete if not linked to datasets in actual outputs
            Object.keys(outputMapping).forEach((dataset) => {
                // for each dataset that is not part of actual outputs, delete
                if (!actualOutputs.includes(dataset)) {
                    // go through each feature related to model update, delete if not linked to datasets in actual outputs
                    if (outputMapping[dataset]?.features) {
                        Object.values(outputMapping[dataset]?.features).forEach((feature) => {
                            // deleted features are removed from 1.mongo and 2.redux (deletion from SQL happens in the backend)
                            if (!feature.tempFeatureId) {
                                dispatch(FeatureModel.destroy(feature.previousFeatureId));
                            }
                        });
                        dispatch(DatasetModel.destroy(dataset));
                    }
                }
            });

            datasets
                .filter((dataset) => !Object.keys(outputMapping).includes(dataset._id))
                .forEach((dataset) => {
                    // DELETE TEMPORARY DATASETS THAT WERE CREATED IF USERS RAN MULTIPLE TIMES
                    if (!actualOutputs.includes(dataset._id)) {
                        Object.values(features.byId)
                            .filter((feature) => feature.dataset._id === dataset._id)
                            .forEach((featureToDelete) => {
                                dispatch(FeatureModel.destroy(featureToDelete._id));
                            });
                        dispatch(DatasetModel.destroy(dataset._id));
                    }
                });
        });
        // CLEAR ANY OTHER TEMPORARY DATASETS AND FEATURES GENERATED
        dispatch(ModelModel.confirm(model._id));
        dispatch(PackageModel.commitPackageImage(packageId)); // COMMIT PACKAGE IMAGE TO S3 SO THE CHANGES ARE PERSISTED
        onClose();
    };

    const updateModelAlgorithm = () => {
        // only allow submit if algorithmId is defined
        if (metadata.algorithmId) {
            setWorkflowTabIndex(1);
            dispatch(ModelModel.updateAlgorithm(model?._id, metadata.algorithmId));
        }
    };
    const updateModel = (updateObj) => dispatch(ModelModel.update(model._id, updateObj));

    const allInputsValid = !model?.inputs?.some(
        (input) => !input.dataType || (!input.defaultValue && input.defaultValue !== false && input.defaultValue !== 0),
    );

    const tabs = [
        {
            label: 'Select Source',
            component: <SelectFunction {...props} metadata={metadata} updateMetaData={setMetadata} />,
            complete: !!metadata.algorithmId,
            backButton: { id: 'back', content: 'Back', onClick: closeButton },
            forwardButton: { id: 'submit', content: 'Continue', onClick: updateModelAlgorithm },
        },
        {
            label: 'Review Inputs',
            component: (
                <div className="UpdateInputs">
                    <ModelInputs
                        {...props}
                        updateModel={updateModel}
                        packageId={packageId}
                        moduleFileName={moduleFileName}
                    />
                </div>
            ),
            complete: allInputsValid,
            forwardButton: { id: 'submit', content: 'Continue', onClick: () => setWorkflowTabIndex(2) },
        },
        {
            label: 'Update Outputs',
            component: (
                <UpdateOutputs
                    {...props}
                    onSubmit={onSubmit}
                    outputMapping={outputMapping}
                    setOutputMapping={setOutputMapping}
                />
            ),
            complete: outputMapping != null,
            backButton: { id: 'back', content: 'Back', onClick: () => setWorkflowTabIndex(1) },
            forwardButton: { id: 'submit', content: 'Commit', onClick: onSubmit },
        },
    ];

    return (
        <FullScreenModal
            open={open}
            header={
                <FullScreenModalWorkflowHeader
                    onClose={closeButton}
                    tabIndex={workflowTabIndex}
                    tabs={tabs}
                    title="Update Source Function"
                />
            }
        >
            {tabs[workflowTabIndex].component}
        </FullScreenModal>
    );
};

UpdateModelModal.propTypes = {
    open: PropTypes.bool,
    model: PropTypes.shape().isRequired,
    packages: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    algorithms: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};
UpdateModelModal.defaultProps = {
    open: false,
};

export default UpdateModelModal;
