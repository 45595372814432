import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Input } from '../../../../../shared';
import { getImportedDatasets } from '../../../../../Data/helpers';

const DataframeInput = (props) => {
    const { value, disabled, onChange, ...inputProps } = props;

    const { datasets } = useSelector((state) => ({
        datasets: state.datasets,
    }));

    const handleUpdateDataset = (_, id) => {
        onChange({ _id: id });
    };

    return (
        <Input
            disabled={disabled}
            value={value && value._id}
            options={
                datasets
                    ? getImportedDatasets(datasets).map((dataset) => ({ label: dataset.name, value: dataset._id }))
                    : []
            }
            onChange={handleUpdateDataset}
            {...inputProps}
            type="select"
        />
    );
};

DataframeInput.propTypes = {
    value: PropTypes.shape({
        _id: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
DataframeInput.defaultProps = {
    value: null,
    disabled: false,
    onChange: () => {},
};

export default DataframeInput;
