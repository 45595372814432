import React from 'react';
import { useRoutes } from 'react-router-dom';

import ErrorBoundary from '../shared/ErrorBoundary';
import { ProjectProtectedRoute } from '../../helpers/router';
import DashboardList from './List';
import Report from './Report';

export default function Reports() {
    const routes = [
        {
            path: '',
            exact: true,
            element: (
                <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                    <DashboardList />
                </ProjectProtectedRoute>
            ),
        },
        {
            // dashboard with no tabs route
            path: ':dashboardId/',
            exact: true,
            element: (
                <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                    <Report />
                </ProjectProtectedRoute>
            ),
        },
        {
            path: ':dashboardId/tabs/:tabId',
            element: (
                <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }}>
                    <Report />
                </ProjectProtectedRoute>
            ),
        },
    ];

    const dashboardRoutes = useRoutes(routes);
    return <ErrorBoundary>{dashboardRoutes}</ErrorBoundary>;
}
