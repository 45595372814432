import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Logo, Input, ModalCard, Button } from '../../../../shared';
import { logoValidations } from '../../Organization/Tabs/helpers';

import './styles.scss';
import UserIcon from '../UserIcon';

export default function UserLogo({ user, hydrated, updateLogo, large }) {
    const [logoFile, setLogoFile] = useState(null);
    const [logoUpload, setLogoUpload] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleLogoChange = (id, value, errs) => {
        setErrors(errs);
        setLogoFile(value);
    };

    const handleLogoSubmit = () => {
        updateLogo({ logo: logoFile });
        setLogoUpload(false);
    };

    return (
        <div className={ClassNames('UserLogo', { 'UserLogo--large': large })}>
            <div
                style={{ display: 'inline-block' }}
                onClick={() => setLogoUpload(!logoUpload)}
                role="button"
                tabIndex={0}
            >
                {user.logo ? (
                    <Logo logo={user.logo} hydrated={hydrated} />
                ) : (
                    <UserIcon
                        user={user}
                        onClick={() => setLogoUpload(!logoUpload)}
                        userId={user._id}
                        firstName={user.name.first}
                        lastName={user.name.last}
                        large
                    />
                )}
            </div>
            <ModalCard open={logoUpload} onClose={() => setLogoUpload(false)} title="Update Logo">
                <div className="UserLogo__uploadModal">
                    <Input
                        type="file"
                        id="logo"
                        validations={logoValidations}
                        accept="image/png"
                        errors={errors}
                        value={logoFile}
                        onChange={handleLogoChange}
                    />
                    <div className="UserLogo__uploadModal__buttons">
                        <Button id="cancel" gray onClick={() => setLogoUpload(false)} tabIndex={-1}>
                            Cancel
                        </Button>
                        <Button id="submit" blue onClick={handleLogoSubmit}>
                            Upload
                        </Button>
                    </div>
                </div>
            </ModalCard>
        </div>
    );
}

UserLogo.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        logo: PropTypes.string,
        name: PropTypes.shape({
            first: PropTypes.string,
            last: PropTypes.string,
        }),
    }).isRequired,
    updateLogo: PropTypes.func.isRequired,
    hydrated: PropTypes.bool,
    large: PropTypes.bool,
};

UserLogo.defaultProps = {
    hydrated: true,
    large: false,
};
