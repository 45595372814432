import { Actions } from './reducers';
import { operations } from '../../../../models/base';

import UserApi from './api';

const create = (form, organizationId) => (dispatch) => {
    dispatch(Actions.createRequest(form, organizationId));
    return UserApi.create(form, organizationId).then(
        (response) => dispatch(Actions.createSuccess(response, organizationId)),
        (error) => dispatch(Actions.createFailure(error, organizationId)),
    );
};

const updateDetails = (userId, form) => (dispatch) => {
    dispatch(Actions.updateDetailsRequest(userId, form));
    return UserApi.update(userId, form).then(
        (user) => dispatch(Actions.updateDetailsSuccess(userId, user)),
        (error) => dispatch(Actions.updateDetailsFailure(userId, error)),
    );
};

const updatePassword = (id, form) => (dispatch) => {
    dispatch(Actions.updatePasswordRequest(id, form));
    return UserApi.updatePassword(form).then(
        (user) => dispatch(Actions.updatePasswordSuccess(id, user)),
        (error) => dispatch(Actions.updatePasswordFailure(id, error)),
    );
};

const showLogs = (userId) => (dispatch) => {
    dispatch(Actions.showLogsRequest(userId));
    return UserApi.getLogs(userId).then(
        (logs) => dispatch(Actions.showLogsSuccess(userId, logs)),
        (error) => dispatch(Actions.showLogsFailure(userId, error)),
    );
};

const updateLogo = (id, form) => (dispatch) => {
    dispatch(Actions.updateLogoRequest(id, form));
    return UserApi.updateLogo(id, form).then(
        (user) => dispatch(Actions.updateLogoSuccess(id, user)),
        (error) => dispatch(Actions.updateLogoFailure(id, error)),
    );
};

const resendValidationEmail = (userId) => (dispatch) => {
    dispatch(Actions.resendValidationEmailRequest(userId));
    return UserApi.resendValidationEmail(userId).then(
        (user) => dispatch(Actions.resendValidationEmailSuccess(user)),
        (error) => dispatch(Actions.resendValidationEmailFailure(error)),
    );
};

const getOrganizationUsers = (organizationId) => (dispatch) => {
    dispatch(Actions.getOrganizationUsersRequest(organizationId));
    return UserApi.indexByOrganization(organizationId).then(
        (response) => dispatch(Actions.getOrganizationUsersSuccess(organizationId, response)),
        (error) => dispatch(Actions.getOrganizationUsersFailure(organizationId, error)),
    );
};

export default operations(Actions, UserApi, {
    create,
    showLogs,
    updatePassword,
    updateDetails,
    updateLogo,
    resendValidationEmail,
    getOrganizationUsers,
});
