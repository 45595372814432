import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';

import AppSelectors from '../../Access/_models/app/selectors';
import TabSelectors from '../_models/tabs/selectors';
import { DashboardModel } from '../_models';
import ModelPanel from '../../Models/_components/ModelPanel';
import Header from '../Dashboard/Header';
import Empty from '../Dashboard/empty';
import SectionList from './SectionList';
import ReportCard from '../../Units/ReportCard';
import { Loader } from '../../shared';

require('./styles.scss');

// Prevent model panel from rerendering too many times if both arrays contain the same values (in whatever order):
let memoizedValue = [];
const memoize = (modelIds) => {
    if (lodash.isEmpty(lodash.xor(memoizedValue, modelIds))) return memoizedValue;
    memoizedValue = modelIds;
    return modelIds;
};

const useIntersectionObserver = (setCurrentSectionId) => {
    const observer = useRef(null);

    const handleIntersect = useCallback((entries) => {

        entries.forEach(entry => {
            if (entry.intersectionRatio > 0.7 || ((entry.intersectionRect.height / window.innerHeight) > 0.7)) {
                setCurrentSectionId(entry.target.id); // Update current section ID
            }
        });
    }, [setCurrentSectionId]);

    useEffect(() => {
        observer.current = new IntersectionObserver(handleIntersect, {
            root: null, // Use the viewport as the root
            rootMargin: '-20% 0% 0% 0%', // Only consider the lower 80% of the viewport
            threshold: Array.from({ length: 21 }, (v, i) => i / 20) // Thresholds at every 5% increment
        });

        return () => observer.current.disconnect();
    }, [handleIntersect]);

    const observe = useCallback((element) => {
        if (element) {
            observer.current.observe(element); // Start observing the element
        }
    }, []);

    return observe; // Return the observe function
};

export default function Report({ isPublic }) {
    const { projectId, dashboardId, tabId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showDashboard = useCallback((id) => id && dispatch(DashboardModel.show(id)), [dispatch]);
    const dashboards = useSelector((state) => state.dashboards);
    const tabs = useSelector((state) => state.tabs);
    const canRunModels = useSelector((state) => AppSelectors.canRunModels()(state));
    const canEditModels = useSelector((state) => AppSelectors.canEditModels()(state));
    const organizationId = useSelector((state) => state?.projects?.byId[projectId]?.organizationId);
    const publicSharing = useSelector((state) => state?.organizations?.byId[organizationId]?.publicSharing);
    const editable = useSelector((state) => state.app.permission?.roles.dashboard === 'edit');

    // dashboards
    const dashboard = dashboards.byId[dashboardId];
    const dashboardsHydrated = dashboards?.hydrated;
    const redirectToDashboardList = dashboardsHydrated && !dashboard && !isPublic;
    const [loadedDashboardId, setLoadedDashboardId] = useState('');

    // sections
    const [currentSectionId, setCurrentSectionId] = useState(tabId);
    const sectionsHydrated = tabs?.hydrated;
    const [sections, setSections] = useState([]);

    // header/models
    const [publicFilters, setPublicFilters] = useState({});
    const [modelPanelId, setModelPanelId] = useState('');
    const [modelBarTop, setModelBarTop] = useState(0);
    const sectionModels = useSelector((state) => memoize(TabSelectors.getModelIds(currentSectionId)(state)) || []);

    const observe = useIntersectionObserver(setCurrentSectionId); // Use the custom hook

    // Load dashboard and sections
    useEffect(() => {
        showDashboard(dashboardId).then(({ dashboard: returnedDashboard }) => {
            if (!tabId) {
                if (!returnedDashboard) return;
                if (returnedDashboard.tabs[0] && returnedDashboard.tabs[0]._id) {
                    if (isPublic) {
                        navigate(`/public/dashboards/${dashboardId}/tabs/${returnedDashboard.tabs[0]._id}`);
                    } else {
                        navigate(
                            `/projects/${projectId}/dashboards/${dashboardId}/tabs/${returnedDashboard.tabs[0]._id}`,
                        );
                    }
                }
            }
            setLoadedDashboardId(returnedDashboard._id);
        });
    }, [showDashboard, dashboardId, isPublic, navigate, projectId, tabId]);

    // Keep tabs up to date
    useEffect(() => {
        if (dashboard !== undefined) {
            if (sectionsHydrated) {
                const tabIds = Object.values(dashboards.byId[dashboardId]?.tabs).map((tab) => tab._id);
                const sectionsList = tabIds.map((id) => tabs.byId[id]);
                setSections(Object.values(sectionsList));
            }
        }
    }, [dashboard, dashboardId, dashboards.byId, sectionsHydrated, tabs]);

    // Redirect if the dashboard doesn't exist and we're not on the public page
    useEffect(() => {
        if (redirectToDashboardList) {
            navigate(`/projects/${projectId}/dashboards`);
        }
    }, [redirectToDashboardList, navigate, projectId]);

    const handleClick = (sectionId) => {
        setCurrentSectionId(sectionId);
        const ele = document.getElementById(sectionId);
        if (!ele) {
            console.error(`Element with ID ${sectionId} not found.`);
            return;
        }
    
        const extraOffset = 25; // Additional offset in pixels
        const header = document.querySelector('.DashboardHeader'); // Adjust the selector to match your header element
        const container = document.querySelector('.Report__page'); // Adjust the selector to match your scrollable container

        // Calculate the scroll offset
        const scrollOffset = ele.getBoundingClientRect().top - container.getBoundingClientRect().top - header.offsetHeight - extraOffset;
        
        // Scroll the container
        container.scrollBy({
            top: scrollOffset,
            behavior: 'smooth'
        });
    };
    
    const handleDuplicateSection = (sectionId) => {
        dispatch(DashboardModel.duplicateTab(dashboardId, sectionId)).then((action) => {
            showDashboard(action.id);
        });
    };
    const handleMeasureModelBar = (contentRect) => {
        if (contentRect.bounds.top !== modelBarTop) {
            setModelBarTop(contentRect.bounds.top);
        }
    };

    const handleReOrderSections = (newSections) => {
        const newDashboard = { ...dashboard, tabs: newSections };
        dispatch(DashboardModel.update(dashboardId, newDashboard));
    };

    const closeButtonStyle = modelPanelId &&
        sectionModels && {
            top: modelBarTop + 4 + 43 * sectionModels.findIndex((modelId) => modelId === modelPanelId),
        };

    const getLocationURL = (dId, tId) => {
        const publicURL = isPublic ? '/public' : `/projects/${projectId}`;
        const baseRoute = `${publicURL}/dashboards/report`;
        if (!dId) return baseRoute;
        if (!tId) return `${baseRoute}/${dId}`;
        return `${baseRoute}/${dId}/tabs/${tId}`;
    };

    const navigateToDashboard = (dId, tId) => {
        navigate(getLocationURL(dId, tId));
    };

    if (redirectToDashboardList) return null;

    return (
        <div className="Report">
            <div className="Report__page">
                <Header
                    className="Report__Header"
                    tabId={currentSectionId}
                    dashboardId={dashboardId}
                    projectId={projectId}
                    isPublic={isPublic}
                    publicFilters={publicFilters[currentSectionId] || []}
                    setPublicFilters={(newPublicFilters) =>
                        setPublicFilters({ ...publicFilters, [currentSectionId]: [...newPublicFilters] })
                    }
                    canRunModels={canRunModels}
                    modelPanelId={modelPanelId}
                    setModelPanelId={setModelPanelId}
                    handleMeasureModelBar={handleMeasureModelBar}
                    publicSharing={publicSharing}
                    report
                />
                {sectionsHydrated &&
                    (sections.length > 0 ? (
                        <div className="Report__body">
                            {sections[0] && (
                                <SectionList
                                    sectionId={tabId}
                                    sections={sections}
                                    handleReOrderSections={handleReOrderSections}
                                    dashboardId={dashboardId}
                                    onClick={handleClick}
                                    handleDuplicate={handleDuplicateSection}
                                    currentSectionId={currentSectionId} // Pass the currentSectionId
                                />
                            )}
                            <div className="Report__cards">
                                {loadedDashboardId === dashboardId ? (
                                    Object.values(sections).map((section) => {
                                        if (!section) return null; // Check if section is null or undefined

                                        return (
                                            <div className="Reports__card" key={section._id} ref={observe} id={section._id}>
                                                <ReportCard
                                                    sectionId={section._id}
                                                    unitIds={section.units}
                                                    dashboardId={dashboardId}
                                                    editable={editable}
                                                    publicFilters={publicFilters[section._id]}
                                                    section={section}
                                                    currentSectionId={currentSectionId}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Loader />
                                )}
                            </div>
                        </div>
                    ) : (
                        <Empty dashboardId={dashboardId} navigateToDashboard={navigateToDashboard} isReport />
                    ))}
            </div>
            {canRunModels && modelPanelId && sectionModels && sectionModels.includes(modelPanelId) && (
                <ModelPanel
                    modelId={modelPanelId}
                    closeModelPanel={() => setModelPanelId('')}
                    noEditLink={!canEditModels}
                    closeButtonStyle={closeButtonStyle}
                />
            )}
        </div>
    );
}

Report.propTypes = {
    isPublic: PropTypes.bool,
};

Report.defaultProps = {
    isPublic: false,
};
