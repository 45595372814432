/* eslint-disable */
import { OpenAI } from 'openai';

export const dalle = async (prompt, key) => {

  const openai = new OpenAI({
    apiKey: key, // This is also the default, can be omitted
    dangerouslyAllowBrowser: true
  });

  const response = await openai.images.generate({
    prompt: `${prompt}`,
    n: 1,
    size: '512x512',
    response_format: 'url',
  });

  const data = response.data[0].url;
  return data;
};
