// SELECTORS
const getDatasetIds = (featureIds, { nested } = { nested: false }) => (state) => {
    if (!featureIds) return [];
    const _featureIds = Array.isArray(featureIds) ? featureIds : [featureIds];
    const datasetIdMap = {};
    _featureIds.forEach((featureId) => {
        const feature = state.features.byId[featureId];
        const datasetId = feature && feature.dataset && feature.dataset._id;
        if (datasetId) datasetIdMap[datasetId] = true;
        const dataset = state.datasets.byId[datasetId];
        if (nested && dataset?.type === 'joined') {
            datasetIdMap[dataset.source.from._id] = true;
            dataset.source.join.forEach((join) => {
                datasetIdMap[join._id] = true;
            });
        }
    });
    return Object.keys(datasetIdMap);
};

export default {
    getDatasetIds,
};
