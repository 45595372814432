import React from 'react';

import DataTabs from './Tabs';
import DataEmpty from './empty';
import { ProjectProtectedRoute } from '../../helpers/router';

export default [
    {
        path: ':datasetId/*',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ dataset: 'view' }}>
                <DataTabs />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: '',
        exact: true,
        element: (
            <ProjectProtectedRoute requiredRole={{ dataset: 'view' }}>
                <DataEmpty />
            </ProjectProtectedRoute>
        ),
    },
];
