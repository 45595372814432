import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../shared/Loaders';
import UnitLoader from '../components/Loader';
import Placeholder from '../components/Placeholder';

import { chartComponents, handleLargerData, getChartProps, getMainChartType } from './helpers';

require('./styles.scss');

export default class Chart extends Component {
    shouldComponentUpdate = (nextProps) => nextProps.unit.hydrated;

    render() {
        const { unit, colors, mapping, height } = this.props;
        const { vis, hydrated, data } = unit;
        if (vis && unit && !data) return <Placeholder unit={unit} error="empty" mapping={mapping} height={height} />;
        if (!data || (data.error && !hydrated)) return <UnitLoader />;
        if (data.error) return <Placeholder unit={unit} error={unit.data.error} mapping={mapping} height={height} />;
        if (!data.data || !data.data.length) {
            return <Placeholder unit={unit} error="no-data" mapping={mapping} height={height} />;
        }

        const mainChartType = getMainChartType(vis);
        const chartProps = getChartProps(vis, data, colors);
        const ChartComponent = chartComponents(mainChartType);

        const { animationActive, renderChart } = handleLargerData(
            chartProps.hasAnimation,
            data.series.dependent,
            data.data.length,
            mainChartType,
        );

        if (!renderChart) return <Placeholder unit={unit} error="too-much-data" mapping={mapping} height={height} />;

        return (
            <div className="Chart">
                {!hydrated && <Loader type="spinner" loader="scale" />}
                <ChartComponent
                    {...chartProps}
                    data={data.data}
                    series={data.series}
                    isAnimationActive={animationActive}
                />
            </div>
        );
    }
}

Chart.propTypes = {
    unit: PropTypes.shape({
        vis: PropTypes.shape({
            dependent: PropTypes.arrayOf(PropTypes.shape({ series: PropTypes.array })),
        }),
        chart: PropTypes.shape({
            data: PropTypes.shape({
                series: PropTypes.shape({}),
                error: PropTypes.string,
            }),
            props: PropTypes.shape({}),
            categories: PropTypes.shape({}),
        }),
        hydrated: PropTypes.bool,
        data: PropTypes.shape({
            series: PropTypes.shape({
                dependent: PropTypes.array,
            }),
            error: PropTypes.string,
            data: PropTypes.array,
        }),
    }),
    mapping: PropTypes.bool,
    colors: PropTypes.shape({}),
    height: PropTypes.number,
};

Chart.defaultProps = {
    unit: {
        vis: null,
        chart: {
            data: {},
            props: {},
            categories: {},
        },
        hydrated: true,
    },
    mapping: false,
    colors: {},
    height: null,
};
