import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Filter from '../Filter';
import AddFilterButton from '../AddFilterButton';
import { Icon, IconButton, ContextMenu, ContextSubMenu, ContextMenuItem } from '../../../shared';

import './styles.scss';

export default function IconButtonFilterSkin(props) {
    const {
        id,
        value,
        onChange,
        onFilterChange,
        onFilterDelete,
        onFilterCreate,
        onClearAll,
        loading,
        left,
        label,
        listHeading,
        ...rest
    } = props;

    const [newFilterValue, setNewFilterValue] = useState({});
    const [openFilterContextMenu, setOpenFilterContextMenu] = useState(false);

    const handleChange = (filterId, newValue) => {
        const nextValue = [...value];
        let key = nextValue.findIndex((val) => val._id === filterId);
        if (key === -1) key = filterId;
        if (newValue) {
            nextValue.splice(key, 1, newValue);
        } else {
            nextValue.splice(key, 1);
        }
        if (onFilterChange) onFilterChange(filterId, newValue);
        else onChange(id, nextValue);
    };

    const clearAll = useCallback(() => {
        if (onClearAll) onClearAll();
        else onChange(id, []);
    }, [id, onChange, onClearAll]);

    const handleAddFilter = (_, newValue) => {
        setNewFilterValue({});
        if (onFilterCreate) onFilterCreate(newValue);
        else onChange(id, [...value, newValue]);
    };

    const toggleFilterContextMenu = () => {
        setOpenFilterContextMenu(!openFilterContextMenu);
    };

    if (loading) {
        return (
            <div className="FilterBar FilterBar--loading">
                <Icon icon="filter" size="20px" />
            </div>
        );
    }

    return (
        <IconButton
            id="addFilter"
            className="IconButtonFilter"
            icon="filter"
            onClick={toggleFilterContextMenu}
            tooltip="Add Filter"
            selected={value.length > 0}
            active={openFilterContextMenu}
            label={label}
        >
            <ContextMenu
                className="IconButtonFilter__contextMenu"
                open={openFilterContextMenu}
                onClickOut={() => setOpenFilterContextMenu(false)}
                listHeading={listHeading}
            >
                {value.length === 0 ? (
                    <ContextSubMenu>
                        <AddFilterButton
                            showTextContextMenu
                            value={newFilterValue}
                            onChange={handleAddFilter}
                            right={!left}
                            left={left}
                            dark
                            {...rest}
                        />
                        <ContextMenuItem
                            id="clearAll"
                            name="Clear All"
                            onClick={clearAll}
                            type={<Icon icon="times" size="16px" />}
                        />
                    </ContextSubMenu>
                ) : (
                    <React.Fragment>
                        <div className="IconButtonFilter__Array">
                            {value.map((filterValue, i) => (
                                <ContextSubMenu key={filterValue._id || i}>
                                    <Filter
                                        id={filterValue._id || i.toString()}
                                        value={filterValue}
                                        onDelete={onFilterDelete}
                                        onChange={handleChange}
                                        iconRight
                                        right={!left}
                                        left={left}
                                        dark
                                        arrayDisplay
                                        {...rest}
                                    />
                                </ContextSubMenu>
                            ))}
                        </div>
                        <ContextSubMenu>
                            <AddFilterButton
                                showTextContextMenu
                                value={newFilterValue}
                                onChange={handleAddFilter}
                                right={!left}
                                left={left}
                                dark
                                {...rest}
                            />
                            <ContextMenuItem
                                id="clearAll"
                                name="Clear All"
                                onClick={clearAll}
                                type={<Icon icon="times" size="16px" />}
                            />
                        </ContextSubMenu>
                    </React.Fragment>
                )}
            </ContextMenu>
        </IconButton>
    );
}

IconButtonFilterSkin.propTypes = {
    id: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            option: PropTypes.string,
            value: PropTypes.shape({}),
            feature: PropTypes.shape({ id: PropTypes.string }),
        }),
    ),
    datasets: PropTypes.shape({}).isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func,
    onClearAll: PropTypes.func,
    onFilterCreate: PropTypes.func,
    onFilterChange: PropTypes.func,
    onFilterDelete: PropTypes.func,
    onFeatureSelect: PropTypes.func,
    loading: PropTypes.bool,
    left: PropTypes.bool,
    label: PropTypes.string,
    listHeading: PropTypes.string,
};
IconButtonFilterSkin.defaultProps = {
    value: null,
    datasetIds: [],
    onChange: () => {},
    onClearAll: null,
    onFilterCreate: null,
    onFilterChange: null,
    onFilterDelete: null,
    onFeatureSelect: null,
    loading: false,
    id: '',
    left: false,
    label: null,
    listHeading: null,
};
