import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import DashboardImage from '../../Image';
import {
    Card,
    IconButton,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    ConfirmDeleteMenu,
    ScrollArea,
    Icon,
} from '../../../shared';

require('./styles.scss');

export default class PreviewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContextOpen: false,
            isDeleteToolTipOpen: false,
        };
    }

    toggleContext = () => {
        const { isContextOpen } = this.state;
        this.setState({ isContextOpen: !isContextOpen, isDeleteToolTipOpen: false });
    };

    toggleDeleteToolTip = () => {
        const { isDeleteToolTipOpen } = this.state;
        this.setState({ isDeleteToolTipOpen: !isDeleteToolTipOpen });
    };

    handleEdit = (id) => {
        const { onEdit } = this.props;
        this.setState({ isContextOpen: false });
        onEdit(id);
    };
    handleDelete = (id) => {
        const { onDelete } = this.props;
        this.setState({ isContextOpen: false });
        onDelete(id);
    };
    handleDuplicate = (id) => {
        const { onDuplicate } = this.props;
        this.setState({ isContextOpen: false });
        onDuplicate(id);
    };
    handleUpdatePreview = (id) => {
        const { onUpdatePreview } = this.props;
        this.setState({ isContextOpen: false });
        onUpdatePreview(id);
    }

    render() {
        const { dashboard, canEditDashboards, onNavigate } = this.props;
        const { isContextOpen, isDeleteToolTipOpen } = this.state;
        const { _id, name, description } = dashboard;

        return (
            <div
                className={ClassNames('PreviewCard', {
                    'PreviewCard--open': isContextOpen,
                })}
            >
                <Card>
                    <div className="PreviewCard__main" role="button" tabIndex={0} onClick={() => onNavigate(_id)}>
                        <DashboardImage dashboard={dashboard} />
                        {canEditDashboards && (
                            <IconButton
                                icon="menu-vertical"
                                onClick={this.toggleContext}
                                id={_id}
                                className="PreviewCard__button"
                                active={isContextOpen}
                            >
                                <ContextMenu
                                    className="PreviewCard__menu"
                                    open={isContextOpen}
                                    onClickOut={this.toggleContext}
                                >
                                    <ContextSubMenu>
                                        <ContextMenuItem
                                            id="editButton"
                                            key="editButton"
                                            name="Data App Settings"
                                            type={<Icon size="16px" icon="settings" />}
                                            onClick={() => this.handleEdit(_id)}
                                        />
                                        <ContextMenuItem
                                            id="duplicateButton"
                                            key="duplicateButton"
                                            name="Duplicate Dashboard"
                                            type={<Icon size="16px" icon="copy" />}
                                            onClick={() => this.handleDuplicate(_id)}
                                        />
                                        <ContextMenuItem
                                            id="updatePreviewButton"
                                            key="updatePreviewButton"
                                            name="Update Dashboard Preview"
                                            type={<Icon size="16px" icon="info" />}
                                            onClick={() => this.handleUpdatePreview(_id)}
                                        />
                                        <ContextMenuItem
                                            id="deleteButton"
                                            key="deleteButton"
                                            name="Delete Dashboard"
                                            type={<Icon size="16px" icon="trash" />}
                                            onClick={() => this.toggleDeleteToolTip()}
                                        >
                                            <ConfirmDeleteMenu
                                                open={isDeleteToolTipOpen}
                                                onClose={() => this.toggleDeleteToolTip()}
                                                onDelete={() => this.handleDelete(_id)}
                                            />
                                        </ContextMenuItem>
                                    </ContextSubMenu>
                                </ContextMenu>
                            </IconButton>
                        )}
                        <div className="PreviewCard__name">{name}</div>
                        <ScrollArea>
                            <div className="PreviewCard__description">{description}</div>
                        </ScrollArea>
                    </div>
                </Card>
            </div>
        );
    }
}

PreviewCard.propTypes = {
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        sourcesCount: PropTypes.number,
        user: PropTypes.shape({ _id: PropTypes.string, email: PropTypes.string }),
        timeCreated: PropTypes.string,
    }).isRequired,
    canEditDashboards: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onNavigate: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onUpdatePreview: PropTypes.func.isRequired,
};

PreviewCard.defaultProps = {};
