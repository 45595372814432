import React from 'react';
import Dashboard from '../Dashboard';

export default [
    {
        // dashboard with no tabs route
        path: '',
        exact: true,
        element: <Dashboard isPublic />,
    },
    {
        path: 'tabs/:tabId',
        element: <Dashboard isPublic />,
    },
];
