import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ClassNames from 'classnames';
import { PermissionModel } from '../../../_models';
import {
    ConfirmDeleteMenu,
    ContextMenu,
    ContextMenuItem,
    ContextSubMenu,
    Icon,
    IconButton,
    Input,
} from '../../../../shared';
import PermissionTable from '../../../_components/PermissionTable';
import { defaultRoles, defaultRolesText } from '../../../_models/permissions/constants';
import { validatePermissionRole } from '../../../../../helpers/router/helpers';

require('./styles.scss');

const validatePresetRoles = (roles, myRoles) => {
    const { editMembers, editProject, ...restOfRoles } = roles;
    if (editMembers && !myRoles.editMembers) return false;
    if (editProject && !myRoles.editProject) return false;
    return validatePermissionRole(restOfRoles, myRoles);
};

export default function AccordianItem(props) {
    const {
        className,
        disabled,
        onClick,
        logo,
        title,
        userEmail,
        subTitle,
        info,
        onChange,
        userPermissions,
        myRoles,
    } = props;

    // For deleting a user
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();

    // For User Permissions:
    const [expandPermissions, setExpandPermissions] = useState(false);
    const closeExpandPermissions = () => setExpandPermissions(false);

    // For Role info
    let roleText = defaultRolesText.custom;
    if (userPermissions && Object.values(defaultRoles).some((roles) => _.isEqual(roles, userPermissions.roles))) {
        const defaultRoleEntry = Object.entries(defaultRoles).find((entry) =>
            _.isEqual(entry[1], userPermissions.roles),
        );
        roleText = defaultRolesText[defaultRoleEntry[0]];
    }

    // For role select
    const [selectedRole, setSelectedRole] = useState(roleText);

    return (
        <div
            className={ClassNames('AccordianItem', className, {
                'AccordianItem--disabled': disabled,
                'AccordianItem--permissions': expandPermissions,
            })}
            onClick={onClick}
            role="menuitem"
            tabIndex={0}
        >
            <div className="AccordianItem__body">
                <div className="AccordianItem__logo">{logo}</div>
                <div className="AccordianItem__content">
                    <div className="AccordianItem__name">{title}</div>
                    <div className="AccordianItem__details">
                        {userEmail && <div className="AccordianItem__userEmail">{userEmail}</div>}
                        {title && subTitle && <div className="AccordianItem__yline" />}
                        {subTitle && <div className="AccordianItem__text"> {subTitle} </div>}
                    </div>
                </div>
                <div className="AccordianItem__info"> {info} </div>
                <div className="AccordianItem__userRoleinfo">{roleText.description}</div>
                <Input
                    className="EditMembers__SelectRoleInput"
                    id={`${userPermissions._id}-selectUserRoleID`}
                    type="select"
                    gray
                    value={selectedRole.value}
                    options={Object.values(defaultRolesText).filter((item) => validatePresetRoles(item.roles, myRoles))}
                    onChange={(id, roles) => {
                        setSelectedRole(defaultRolesText[roles]);
                        dispatch(PermissionModel.update(userPermissions._id, { roles: defaultRolesText[roles].roles }));
                    }}
                    disabled={disabled}
                />
                <IconButton
                    onClick={() => {
                        closeExpandPermissions(true);
                        setExpandPermissions(!expandPermissions);
                    }}
                    className="AccordianItem__permissionButton"
                    size="16px"
                    icon="settings"
                    selected={expandPermissions}
                    tooltip="Show Detailed Permissions"
                />

                <IconButton
                    icon="menu-vertical"
                    className="SelectableBar__menuIcon"
                    onClick={() => setMenuOpen(!menuOpen)}
                    active={menuOpen}
                    disabled={disabled}
                >
                    <ContextMenu open={menuOpen} onClickOut={() => setMenuOpen(false)}>
                        <ContextSubMenu>
                            <ContextMenuItem
                                id="removeMember"
                                name="Remove From Project"
                                type={<Icon icon="trash" size="16px" />}
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                    setMenuOpen(false);
                                }}
                            />
                        </ContextSubMenu>
                    </ContextMenu>
                    <ConfirmDeleteMenu
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        onDelete={() => {
                            dispatch(PermissionModel.destroy(userPermissions._id));
                            setDeleteModalOpen(false);
                        }}
                    />
                </IconButton>
            </div>
            {expandPermissions && (
                <PermissionTable
                    userId={userPermissions.userId}
                    userRoles={userPermissions.roles}
                    onChange={(id, value) => {
                        const update = { [id]: value };
                        if (id === 'model') update.package = value;
                        onChange(userPermissions._id, { ...userPermissions.roles, ...update });
                    }}
                    disabled={disabled}
                    myRoles={myRoles}
                />
            )}
        </div>
    );
}

AccordianItem.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    logo: PropTypes.shape({}),
    title: PropTypes.string,
    userEmail: PropTypes.string,
    subTitle: PropTypes.string,
    info: PropTypes.string,
    onChange: PropTypes.func,
    userPermissions: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
    myRoles: PropTypes.shape({}),
};
AccordianItem.defaultProps = {
    className: '',
    disabled: false,
    onClick: () => {},
    logo: null,
    title: '',
    subTitle: '',
    info: '',
    userEmail: '',
    onChange: () => {},
    userPermissions: [],
    myRoles: {},
};
