import React from 'react';

require('./styles.scss');

export default function TermsOfUse() {
    return (
        <div>
            <h1>Terms of Service</h1>

            <p>Last updated: August 21, 2018</p>
            <p>
                {`These Terms of Service ("Terms", "Terms of Service") govern your relationship with white-whale.io
                website (the "Service") operated by White Whale Analytics ("us", "we", or "our").`}
            </p>
            <p>Please read these Terms of Service carefully before using the Service.</p>
            <p>
                Your access to and use of the Service is based on your acceptance of and compliance with these Terms.
                These Terms apply to all visitors, users and others who access or use the Service.
            </p>
            <p>
                By accessing or using the Service you agree to be bound by these Terms and accept all legal
                consequences. If you do not agree to these terms and conditions, in whole or in part, please do not use
                the Service.
            </p>

            <h2>Accounts</h2>
            <p>
                When you create an account with us, you must provide us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of your account on our Service.
            </p>
            <p>
                You are responsible for safeguarding the password that you use to access the Service and for any
                activities or actions under your password, whether your password is with our Service or a third-party
                service.
            </p>
            <p>
                You agree not to disclose your password to any third party. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.
            </p>

            <h2>Intellectual Property</h2>
            <p>
                The Service and all contents, including but not limited to text, images, graphics or code are the
                property of White Whale Analytics and are protected by copyright, trademarks, database and other
                intellectual property rights. You may display and copy, download or print portions of the material from
                the different areas of the Service only for your own non-commercial use. Any other use is strictly
                prohibited and may violate copyright, trademark and other laws. These Terms do not grant you a license
                to use any trademark of White Whale Analytics or its affiliates. You further agree not to use, change or
                delete any proprietary notices from materials downloaded from the Service.
            </p>

            <h2>Links To Other Web Sites</h2>
            <p>
                The Service may contain links to third-party web sites or services that are not owned or controlled by
                White Whale Analytics.
            </p>
            <p>
                White Whale Analytics has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party web sites or services. You further acknowledge and agree that
                White Whale Analytics shall not be responsible or liable, directly or indirectly, for any damage or loss
                caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
                services available on or through any such websites or services.
            </p>
            <p>
                We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                sites or services that you visit.
            </p>

            <h2>Termination</h2>
            <p>
                We may terminate or suspend access to our Service immediately, without prior notice or liability, for
                any reason whatsoever, including, without limitation, if you breach the Terms.
            </p>
            <p>
                All provisions of the Terms shall survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
            <p>
                Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
                account, you may simply discontinue using the Service.
            </p>

            <h2>Indemnification</h2>
            <p>
                You agree to indemnify, defend and hold harmless White Whale Analytics, its principals, officers,
                directors, representatives, employees, contractors, licensors, licensees, suppliers and agents, from and
                against any claims, losses, damages, losses, obligations, costs, actions or demands.
            </p>
            <p>
                These include but are not limited to: (a) legal and accounting fees resulting from your use of the
                Service; (b) your breach of any of these Terms; (c) anything you post on or upload to the Service; and
                (d) any activity related to your account. This includes any negligent or illegal conduct by you, any
                person or entity accessing the Service using your account whether such access is obtained via fraudulent
                or illegal means.
            </p>

            <h2>Limitation Of Liability</h2>
            <p>
                White Whale Analytics, its directors, employees, partners, agents, suppliers, or affiliates, shall not
                be liable for any loss or damage, direct or indirect, incidental, special, consequential or punitive
                damages, including without limitation, economic loss, loss or damage to electronic media or data,
                goodwill, or other intangible losses, resulting from (i) your access to or use of the Service; (ii) your
                inability to access or use the Service; (iii) any conduct or content of any third-party on or related to
                the Service; (iiv) any content obtained from or through the Service; and (v) the unauthorized access to,
                use of or alteration of your transmissions or content, whether based on warranty, contract, tort
                (including negligence) or any other claim in law, whether or not we have been informed of the
                possibility of such damage, and even if a remedy set forth herein is found to have failed of its
                essential purpose.
            </p>

            <h2>Disclaimer And Non-Waiver of Rights</h2>
            <p>
                {`White Whale Analytics makes no guarantees, representations or warranties of any kind as regards the
                website and associated technology. Any purportedly applicable warranties, terms and conditions are
                excluded, to the fullest extent permitted by law. Your use of the Service is at your sole risk. The
                Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties
                of any kind, whether express or implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose, non-infringement or course of performance, except as
                provided for under the laws of any province in Canada. In such cases, the provincial law shall apply to
                the extent necessary.`}
            </p>
            <p>
                {`White Whale Analytics its subsidiaries, affiliates, and its licensors do not warrant that a) the Service
                will function uninterrupted, secure or available at any particular time or location; b) any errors or
                defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the
                results of using the Service will meet your requirements.`}
            </p>
            <p>
                If you breach any of these Terms and White Whale Analytics chooses not to immediately act, or chooses
                not to act at all, White Whale Analytics will still be entitled to all rights and remedies at any later
                date, or in any other situation, where you breach these Terms. White Whale Analytics does not waive any
                of its rights. White Whale Analytics shall not be responsible for any purported breach of these Terms
                caused by circumstances beyond its control. A person who is not a party to these Terms shall have no
                rights of enforcement.
            </p>
            <p>You may not assign, sub-license or otherwise transfer any of your rights under these Terms.</p>

            <h2>Exclusions</h2>
            <p>
                As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the exclusion
                or limitation of liability for consequential or incidental damages, so the limitations above may not
                apply to you. Provincial laws of Canada may apply to certain products and service provided.
            </p>

            <h2>Governing Law</h2>
            <p>
                These Terms shall be governed by, and interpreted and enforced in accordance with, the laws in the
                Province of Alberta and the laws of Canada, as applicable.
            </p>
            <p>
                If any provision of these Terms is held to be invalid or unenforceable by a court of competent
                jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute
                the entire agreement between us regarding our Service, and supersede and replace any prior agreements,
                oral or otherwise, regarding the Service.
            </p>

            <h2>Changes</h2>
            <p>
                {`We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any
                new terms taking effect. What constitutes a material change will be determined at our sole discretion.`}
            </p>
            <p>
                By continuing to access or use our Service after those revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the
                website and the Service.
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us.</p>
        </div>
    );
}
