import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';

/* eslint-disable */
import { MdComputer, MdPerson } from 'react-icons/md';
import moment from 'moment';
import { ChatContext } from '../../context/chatContext';
import Image from '../Image';
import Markdown from '../Markdown';

import './styles.css';

/**
 * A chat message component that displays a message with a timestamp and an icon.
 *
 * @param {Object} props - The properties for the component.
 */
const Message = (props) => {
  const { id, createdAt, text, ai = false, selected } = props.message;

  const [, , clearChat] = useContext(ChatContext);

  function clear() {
    clearChat();
  }

  return (
      <div key={id} className={`message-container ${ai ? 'flex-row-reverse' : 'flex-row'}`}>
          {selected === 'DALL·E' && ai ? (
              <Image url={text} />
          ) : (
              <div className={`w-screen chat ${ai ? 'chat-start' : 'chat-end'}`}>
                  <div className='chat-bubble'>
                      <Markdown markdownText={text} />
                      <div className={`${ai ? 'text-left' : 'text-right'} text-xs`}>
                          {moment(createdAt).calendar()}
                      </div>
                  </div>
              </div>
          )}
          <div className='avatar'>
              <div className='w-8'>
                  {ai ? (
                      <MdComputer className='w-6' />
                  ) : (
                      <MdPerson className='w-6' />
                  )}
              </div>
          </div>
      </div>
  );};

export default Message;

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.number.isRequired,
    text: PropTypes.string,
    ai: PropTypes.bool,
    selected: PropTypes.string,
  }).isRequired,
};
