export const defaultChartModel = {
    type: 'chart',
    vis: {
        chartType: 'area',
        independent: [],
        dependent: [],
        otherDimensions: [],
        gradientDimension: [],
        groupBy: [],
        orderBy: [],
        filters: [],
        display: {},
    },
};

// export const defaultChartModel = {
//     type: null,
//     vis: {
//         independent: [
//             {
//                 series: [
//                     {
//                         id: '',
//                         name: '',
//                         feature: { _id: '' },
//                         operation: '',
//                     },
//                 ],
//                 display: {
//                     // hidden      (boolean)
//                     // orientation (bottom || top)
//                     // hasTitle    (boolean)
//                     // hasLine     (boolean)
//                     // hasLabel    (boolean)
//                     // domain      (eg [datamin, datamax])
//                     // isReversed  (boolean)                 (axis goes from high to low)
//                     // scale       (string)                  (linear, log, .....)
//                     // ticks: {
//                     //    angle: '', // .45 would make the tick label slanted
//                     //      unit: '', // (i.e ft, km/ph, )
//                     //      count: ''
//                     //    ...
//                     // }
//                 },
//                 // title       (string)
//             },
//         ],
//         dependent: [
//             {
//                 series: [
//                     {
//                         id: '',
//                         name: '',
//                         feature: { _id: '' },
//                         operation: '',
//                         chartType: 'area',
//                         display: {
//                             // chartType-specific configuration:
//                             // AREA/LINE:
//                             // // lineType       ('basis', 'linear', 'normal', ...)
//                             // // dot            (bool)
//                             // // connectNulls   (bool)
//                             // // makeNullsZero  (bool)
//                             // BAR:
//                             // // barGap         (number)
//                             // // barCategoryGap (number)
//                             // AREA/BAR:
//                             // // isStacked        (bool)
//                             // PIE:
//                             // // outerRadius
//                             // // innerRadius
//                             // // startAngle
//                         },
//                     },
//                 ],
//                 display: {
//                     // hidden      (boolean)
//                     // orientation (bottom || top)
//                     // hasTitle    (boolean)
//                     // hasLine     (boolean)
//                     // hasLabel    (boolean)
//                     // domain      (eg [datamin, datamax])
//                     // isReversed  (boolean)                 (axis goes from high to low)
//                     // scale       (string)                  (linear, log, .....)
//                     // ticks: {
//                     //    angle: '', // .45 would make the tick label slanted
//                     //      unit: '', // (i.e ft, km/ph, )
//                     //      count: ''
//                     //    ...
//                     // }
//                 },
//                 // title       (string)
//             },
//         ],
//         otherDimensions: [
//             {
//                 series: [
//                     {
//                         id: '',
//                         name: '',
//                         feature: { _id: '' },
//                         operation: '',
//                     },
//                 ],
//                 type: '', // either dotSize or gradientColor. both can be used for different features on the same plot.
//             },
//         ],
//         tooltip: {
//             series: [
//                 {
//                     feature: { _id: '' },
//                 },
//             ],
//         },
//         groupBy: [
//             {
//                 series: [], // series to apply the group by to
//                 feature: { _id: '' },
//                 operation: '',
//                 show: {
//                     // limit: 10,
//                     sort: {
//                         feature: {
//                             _id: '',
//                         },
//                         type: 'ASC',
//                     },
//                 },
//                 // type: '', // monthly etc.
//                 // ranges: []
//             },
//         ],
//         orderBy: [
//             {
//                 series: [],
//                 feature: { _id: '' },
//                 operation: '',
//                 type: 'ASC',
//             },
//         ],
//         filters: [],
//         display: {
//             dependentAxis: 'y',
//             // tooltip: true,
//             // legend: true,
//             // animation: true,
//             grid: {
//                 // horizontal      (bool)
//                 // vertical        (bool)
//             },
//         },
//     },
// };
