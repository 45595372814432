import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../shared';

import { customUnitSubtypes } from '.';
import './styles.scss';

export default function CustomSubtypeMapping(props) {
    const { onChange } = props;
    const handleChange = (id) => onChange({ subtype: id }, { shouldGetData: true });
    return (
        <div className="CustomSubtypeMapping">
            Caution: these card types are not fully supported and may have some bugs!
            <div className="CustomSubtypeMapping__buttons">
                {Object.keys(customUnitSubtypes).map((subtype) => (
                    <Button key={subtype} id={subtype} blue onClick={handleChange}>
                        {subtype}
                    </Button>
                ))}
            </div>
        </div>
    );
}

CustomSubtypeMapping.propTypes = {
    onChange: PropTypes.func,
};

CustomSubtypeMapping.defaultProps = {
    onChange: () => {},
};
