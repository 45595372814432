/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import ClassNames from 'classnames';

import { DashboardModel } from '../../_models';
import TabSelectors from '../../_models/tabs/selectors';
import { blue300 } from '../../../../styles/partials/colors';
import { IconButton } from '../../../shared';

import ModelBar from '../../../Models/_components/ModelBar';
import TabFilter from './TabFilter';
import DashboardColors from './DashboardColors';

require('./styles.scss');

class DashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { colorMenuOpen: false, copiedUrl: false };
    }

    render() {
        const {
            features,
            dashboardId,
            tabId,
            tabModels,
            dashboard,
            project,
            updateColors,
            isPublic,
            publicFilters,
            setPublicFilters,
            canEditDashboards,
            setModelPanelId,
            canRunModels,
            modelPanelId,
            handleMeasureModelBar,
            publicSharing,
            report,
        } = this.props;
        const { colorMenuOpen } = this.state;
        const { copiedUrl } = this.state;
        const title = (dashboard || {}).name;
        const { display } = dashboard;
        const hideCommandBar = display && display.hide && display.hide.hideCommandBar;
        const hideDashboardColors = display && display.hide && display.hide.hideDashboardColors;
        const hideFilters = display && display.hide && display.hide.hideFilters;

        const filter =
            !hideFilters &&
            (isPublic ? (
                <TabFilter
                    key={tabId}
                    tabId={tabId}
                    isPublic
                    publicFilters={publicFilters}
                    setPublicFilters={setPublicFilters}
                />
            ) : (
                <TabFilter tabId={tabId} />
            ));

        const colors = canEditDashboards && !hideDashboardColors && (
            <IconButton
                icon="palette"
                tooltip="Data App Color Settings"
                onClick={() => this.setState({ colorMenuOpen: !colorMenuOpen })}
                active={colorMenuOpen}
            >
                <DashboardColors
                    open={colorMenuOpen}
                    onClickOut={() => this.setState({ colorMenuOpen: !colorMenuOpen })}
                    features={features}
                    colors={dashboard.colors}
                    addColor={(featureId) =>
                        updateColors(dashboardId, {
                            ...dashboard.colors,
                            features: { ...dashboard.colors.features, [featureId]: blue300 },
                        })
                    }
                    updateColor={(featureId, color) =>
                        updateColors(dashboardId, {
                            ...dashboard.colors,
                            features: { ...dashboard.colors.features, [featureId]: color },
                        })
                    }
                    removeColor={(featureId) => {
                        const newColors = { ...dashboard.colors };
                        delete newColors.features[featureId];
                        updateColors(dashboardId, newColors);
                    }}
                    resetColors={() => updateColors(dashboardId, { features: {} })}
                    updateColorScheme={(colorScheme) => updateColors(dashboardId, { ...dashboard.colors, colorScheme })}
                />
            </IconButton>
        );

        return (
            <header
                className={ClassNames('DashboardHeader', {
                    'DashboardHeader--modelPanelOpen': modelPanelId,
                })}
            >
                <div className="DashboardHeader__titleRow">
                    <div className="DashboardHeader__title">
                        <h2>{title}</h2>
                    </div>
                    {!isPublic && project?.public && publicSharing && (
                        <IconButton
                            icon="copy"
                            className="DashboardHeader__publicLink"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    report
                                        ? `${location.origin}/public/dashboards/report/${dashboardId}/tabs/${tabId}`
                                        : `${location.origin}/public/dashboards/${dashboardId}`,
                                );
                                this.setState({ copiedUrl: true });
                                setTimeout(() => {
                                    this.setState({ copiedUrl: false });
                                }, 2000);
                            }}
                            label={copiedUrl ? 'Copied!' : 'Copy Public Link'}
                        />
                    )}
                    {colors}
                </div>
                <div className="DashboardHeader__flex">
                    {filter}
                    {!hideCommandBar && (
                        <Measure bounds onResize={handleMeasureModelBar}>
                            {({ measureRef }) => (
                                <div className="DashboardHeader__modelBars" ref={measureRef}>
                                    {tabModels.map((modelId) => (
                                        <ModelBar
                                            key={modelId}
                                            modelId={modelId}
                                            disabled={!canRunModels}
                                            hidden={modelId === modelPanelId}
                                            setModelPanelId={setModelPanelId}
                                            showModelPanelButton={modelId !== modelPanelId}
                                        />
                                    ))}
                                </div>
                            )}
                        </Measure>
                    )}
                </div>
            </header>
        );
    }
}

DashboardHeader.propTypes = {
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    dashboards: PropTypes.shape({
        byId: PropTypes.shape(),
    }).isRequired,
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        display: PropTypes.shape({
            color: PropTypes.string,
            hide: PropTypes.shape({
                hideCommandBar: PropTypes.bool,
                hideDashboardColors: PropTypes.bool,
                hideFilters: PropTypes.bool,
            }),
        }),
        colors: PropTypes.shape({
            colorScheme: PropTypes.string,
            features: PropTypes.shape({}),
        }),
    }),
    tabId: PropTypes.string,
    dashboardId: PropTypes.string.isRequired,
    project: PropTypes.shape({ public: PropTypes.bool }),
    tab: PropTypes.shape({}),
    tabs: PropTypes.shape({}).isRequired,
    filters: PropTypes.shape({}).isRequired,
    canEditDashboards: PropTypes.bool,
    updateColors: PropTypes.func.isRequired,
    isPublic: PropTypes.bool,
    publicFilters: PropTypes.array,
    setPublicFilters: PropTypes.func.isRequired,
    tabModels: PropTypes.array,
    setModelPanelId: PropTypes.func,
    canRunModels: PropTypes.bool,
    modelPanelId: PropTypes.string,
    handleMeasureModelBar: PropTypes.func,
    publicSharing: PropTypes.bool,
    report: PropTypes.bool,
};

DashboardHeader.defaultProps = {
    tab: {},
    tabId: null,
    project: null,
    dashboard: {},
    isPublic: false,
    publicFilters: [],
    tabModels: [],
    setModelPanelId: () => {},
    canRunModels: false,
    modelPanelId: '',
    handleMeasureModelBar: undefined,
    canEditDashboards: false,
    publicSharing: true,
    report: false,
};

const mapStateToProps = (state, props) => {
    const { tabs, filters, features, dashboards, app, projects } = state;
    const tab = tabs.byId[props.tabId];
    const tabModels = TabSelectors.getModelIds(props.tabId)(state);
    const dashboard = dashboards.byId[props.dashboardId];
    const canEditDashboards = app.permission?.roles.dashboard === 'edit';
    const project = projects.byId[props.projectId];

    return {
        features,
        dashboards,
        dashboard,
        tabs,
        tab,
        tabModels,
        filters,
        project,
        canEditDashboards,
    };
};

const mapDispatchToProps = (dispatch) => {
    const updateColors = (dashboardId, newColors) => dispatch(DashboardModel.updateColors(dashboardId, newColors));

    return {
        updateColors,
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
export default connectedComponent;
