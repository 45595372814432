import ApiBase from '../../../../models/base/api';

export class JobApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('jobs', addProjectIdToSearchParams);
    }
}

export default new JobApi();
