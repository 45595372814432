import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fuzzySearch } from '../../../../../../helpers/string';
import { Input, Icon } from '../../../../../shared';
import SelectableBar from '../../../../../shared/SelectableBar';
import { ProjectModel } from '../../../../../Projects/_models';
import ProjectMenu from '../../../../../Projects/_components/ProjectMenu';

require('./styles.scss');

export default function OrganizationsProjectList(props) {
    const { organizationId } = props;

    const [filter, setFilter] = useState('');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ProjectModel.listByOrganization(organizationId));
    }, [dispatch, organizationId]);

    const projects = useSelector((state) => state.projects);
    const organizationProjects = Object.values(projects.byId).filter(
        (project) => project.organizationId === organizationId,
    );
    
    const filteredProjects = filter
        ? fuzzySearch(filter, organizationProjects)
        : organizationProjects;

    const sortedProjects = [...filteredProjects].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className="OrganizationsProjectList">
            <div className="OrganizationsProjectList__header">
                <div className="OrganizationsProjectList__title">Projects</div>
                <Input
                    className="OrganizationsProjectList__search"
                    type="search"
                    id="search"
                    placeholder="Search by Project Name"
                    value={filter}
                    onChange={(id, value) => setFilter(value)}
                />
            </div>
            <div className="OrganizationsProjectList__body">
                {sortedProjects.length ? (
                    sortedProjects.map((project) => (
                        <SelectableBar
                            key={project._id}
                            title={project.name}
                            menuComponent={ProjectMenu}
                            menuComponentProps={{ project, checkManageOrganizationProjects: true }}
                        />
                    ))
                ) : (
                    <div className="OrganizationsProjectList__empty">
                        <Icon icon="large_color/question-circle" size="75px" />
                        <div className="OrganizationsProjectList__empty--Title">No projects found.</div>
                    </div>
                )}
            </div>
        </div>
    );
}

OrganizationsProjectList.propTypes = {
    organizationId: PropTypes.string,
};
OrganizationsProjectList.defaultProps = {
    organizationId: '',
};
