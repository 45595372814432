import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DashboardModel } from '../_models';
import { Icon, TextButton } from '../../shared';
import { AddSectionList, AddTabModal } from '../_components/modals';

class DashboardEmpty extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isModalOpen: false };
    }
    toggleModal = () => {
        const { isModalOpen } = this.state;
        this.setState({ isModalOpen: !isModalOpen });
    };
    handleAddTab = (form) => {
        const { dashboardId, addTab, navigateToDashboard } = this.props;
        if (dashboardId) {
            addTab(dashboardId, form).then((action) => {
                if (action.errors) return;
                const tabs = action.dashboard.tabs;
                const newTab = tabs[tabs.length - 1];
                navigateToDashboard(dashboardId, newTab._id);
            });
        }
    };
    render() {
        const { isReport } = this.props;
        const { isModalOpen } = this.state;
        return (
            <div className="DashboardEmpty">
                <Icon icon="dashboard" size="125px" />
                <p> Somebody has deleted all the tabs or sections on this data app. </p>
                <TextButton blue onClick={this.toggleModal}>
                    Add one!
                </TextButton>
                {!isReport ? (
                    <AddTabModal
                        open={isModalOpen}
                        onClose={this.toggleModal}
                        onSubmit={this.handleAddTab}
                        loading={false}
                    />
                ) : (
                    <AddSectionList open={isModalOpen} onClose={this.toggleModal} onSubmit={this.handleAddTab} />
                )}
            </div>
        );
    }
}

DashboardEmpty.propTypes = {
    addTab: PropTypes.func.isRequired,
    dashboardId: PropTypes.string,
    navigateToDashboard: PropTypes.func,
    isReport: PropTypes.bool,
};

DashboardEmpty.defaultProps = {
    dashboardId: null,
    navigateToDashboard: () => {},
    isReport: false,
};

const mapDispatchToProps = (dispatch) => {
    const addTab = (groupId, name) => dispatch(DashboardModel.createTab(groupId, name));
    return {
        addTab,
    };
};

const connectedComponent = connect(null, mapDispatchToProps)(DashboardEmpty);
export default connectedComponent;
