import ApiBase from '../../../../models/base/api';
import { createQueryString } from '../../../../models/base/api/helpers';

class DatasetsApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('datasets', addProjectIdToSearchParams);
    }
    index = (ids) => this.request('GET', `${this.endpoint}?imported=true${ids ? `&id=${ids.join('&id=')}` : ''}`);

    data = (id, queryParameters, temporaryQueries) => {
        const searchString = createQueryString(queryParameters);
        return this.request('POST', `${this.endpoint}/${id}/data${searchString}`, temporaryQueries);
    };

    features = (id, options) => this.request('GET', `${this.endpoint}/${id}/features${createQueryString(options)}`);

    confirmData = (id) => this.request('PUT', `${this.endpoint}/${id}/confirm`);

    download = (id) => this.request('GET', `${this.endpoint}/${id}/download`);

    // logs = (id) => this.request('GET', `${this.endpoint}/${id}/logs`);

    handleCsvUpload = (id, file, method, routeName) => {
        // shows a confirm message whenever you refresh while uploading (regardless of page being viewed)
        // the message shown is determined by the browser and can't be changed :(
        const showConfirmOnRefresh = (event) => {
            event.preventDefault();
            // Chrome requires `returnValue` to be set.
            event.returnValue = ''; // eslint-disable-line no-param-reassign
            return 'A dataset is still uploading, do you want to leave the page?';
        };

        // slightly hacky way to fail the job when aborting dataset upload
        // failJob endpoint sets the job to failed and notifies the frontend via upload socket
        const failJobOnRefresh = () => {
            this.request('POST', `${this.endpoint}/${id}/failJob`);
        };

        const contentType = file?.blob ? 'FormBlob' : 'FormData';

        window.addEventListener('beforeunload', showConfirmOnRefresh);
        window.addEventListener('beforeunload', failJobOnRefresh);
        return this.request(
            method,
            `${this.endpoint}/${id}/${routeName}`,
            file, // changed this to { file }
            { contentType },
        ).finally(() => {
            window.removeEventListener('beforeunload', showConfirmOnRefresh);
            window.removeEventListener('beforeunload', failJobOnRefresh);
        });
    };

    upload = (id, file) => this.handleCsvUpload(id, file, 'POST', 'upload');
    append = (id, file, contentType) => this.handleCsvUpload(id, file, 'PUT', 'append');
    deleteJobData = (id, jobId) => this.request('DELETE', `${this.endpoint}/${id}/deleteData/${jobId}`);
}

export default new DatasetsApi();
