import React from 'react';
import PropTypes from 'prop-types';

import QuestionElement from './question';
import TitleElement from './title';
import { Card } from '../../../shared';
import { isQuestion } from '../helpers';

import './styles.scss';

const elements = {
    title: TitleElement,
};

export default function FormElementMapping({
    element,
    updateElement,
    deleteElement,
    moveUp,
    moveDown,
    moveToTop,
    moveToBottom,
    duplicate,
    editable,
}) {
    const ElementMapping = isQuestion(element) ? QuestionElement : elements[element.type];
    return (
        <Card className="FormElementMapping">
            <ElementMapping
                element={element}
                updateElement={updateElement}
                deleteElement={deleteElement}
                moveUp={moveUp}
                moveDown={moveDown}
                moveToTop={moveToTop}
                moveToBottom={moveToBottom}
                duplicate={duplicate}
                editable={editable}
            />
        </Card>
    );
}

FormElementMapping.propTypes = {
    element: PropTypes.shape({ type: PropTypes.string, config: PropTypes.shape({}) }),
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    moveUp: PropTypes.func,
    moveDown: PropTypes.func,
    moveToTop: PropTypes.func,
    moveToBottom: PropTypes.func,
    duplicate: PropTypes.func.isRequired,
    editable: PropTypes.bool,
};

FormElementMapping.defaultProps = {
    element: {},
    moveUp: null,
    moveDown: null,
    moveToTop: null,
    moveToBottom: null,
    editable: true,
};
