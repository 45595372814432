import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { typeIcons } from '../../../Data/constants';
import { getSerumType } from '../../serum';

import { IconButton, Icon } from '../../../shared';

require('./styles.scss');

export default function FeatureBasket({
    className,
    feature,
    children,
    onRemove,
    datasetName,
    isPreview,
    serum,
    isDragging,
    setPreviewRef,
    setDropRef,
    setDragRef,
}) {
    const serumType = getSerumType(serum, { byId: { [feature._id]: feature } });
    const typeModified = feature.type !== serumType;
    const typeIcon = typeIcons[serumType || feature.type] || '';

    return (
        <div
            className={ClassNames('FeatureBasket', className, {
                'FeatureBasket--preview': isDragging || isPreview,
            })}
            ref={(element) => {
                if (element) {
                    setDragRef(element);
                    setDropRef.current = element; // eslint-disable-line no-param-reassign
                }
            }}
        >
            <div className="FeatureBasket__preview" ref={setPreviewRef} />
            <div className="FeatureBasket__header">
                <div className="FeatureBasket__title">
                    <Icon
                        icon={typeIcon}
                        size="16px"
                        className={ClassNames('FeatureBasket__featureType', {
                            'FeatureBasket__featureType--changed': typeModified,
                        })}
                    />
                    <div className="FeatureBasket__featureName">{feature.name}</div>
                </div>
                {datasetName && <div className="FeatureBasket__sourceName">{datasetName}</div>}
                <IconButton id={serum.id} mini icon="times" onClick={onRemove} className="FeatureBasket__closeButton" />
            </div>
            {children}
        </div>
    );
}

FeatureBasket.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    serum: PropTypes.shape({
        id: PropTypes.string,
        feature: PropTypes.shape({ _id: PropTypes.string }),
    }),
    feature: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        dataset: PropTypes.shape({ _id: PropTypes.string }),
    }),
    datasetName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onRemove: PropTypes.func,
    isPreview: PropTypes.bool,
    isDragging: PropTypes.bool,
    setDropRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    setPreviewRef: PropTypes.func,
    setDragRef: PropTypes.func,
};

FeatureBasket.defaultProps = {
    className: '',
    children: null,
    feature: {},
    datasetName: '',
    serum: null,
    onRemove: () => {},
    isPreview: false,
    isDragging: false,
    setPreviewRef: () => {},
    setDropRef: () => {},
    setDragRef: () => {},
};
