const getModelRunId = (modelId) => (state) => {
    const model = state.models.byId[modelId];
    if (!model) return null;
    if (model.jobId === 'latest' || !model.jobId) {
        if (!model.runs) return null;
        const latestModelRun = model.runs
            .map((runId) => state.modelRuns.byId[runId])
            .filter((run) => run && run.status === 'finished')
            .reduce((acc, run) => (acc.executionDatetime > run.executionDatetime ? acc : run), {});
        return latestModelRun._id || null;
    }
    return model.jobId;
};

const getPackageId = (modelId) => (state) => {
    const { algorithms } = state;
    const model = state.models.byId[modelId];
    if (!model) return null;
    const algorithm = algorithms.byId[model.algorithmId];
    if (!algorithm) return null;
    return algorithm?.packageId;
};

const getModuleFilename = (modelId) => (state) => {
    const { packages, algorithms } = state;
    const model = state.models.byId[modelId];
    if (!model) return null;
    const algorithm = algorithms.byId[model?.algorithmId];
    if (!algorithm) return null;
    const packageObj = packages.byId[algorithm?.packageId];
    if (!packageObj || packageObj.hydrated === false) return null;
    const moduleObj = packageObj.modules.find((mod) => mod.algorithms.some((algo) => algo._id === algorithm._id));
    return moduleObj.filename;
};

export default {
    getModelRunId,
    getPackageId,
    getModuleFilename,
};
