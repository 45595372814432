/* eslint-disable react/prop-types */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { neutral70 } from '../../../../../styles/partials/colors';
import { defaultTooltip, textStyle, defaultLegend, defaultStyle } from '../../helpers/echarts';

const WWRadar = (props) => {
    const {
        data,
        series,
        hasLegend,
        hasTooltip,
        isAnimationActive,
        yValueFormatter,
        xValueFormatter,
        xAxisHasLabels,
        radarStartAngle,
        radarOuterRadius,
        colorFunction,
        serumNameFormatter,
        dependentSeriesProps,
    } = props;

    const axisNames = data.map((d) => ({
        name: xAxisHasLabels ? xValueFormatter(series.independent[0] && d[series.independent[0].name]) : '',
    }));

    return (
        <ReactEcharts
            notMerge
            style={defaultStyle}
            option={{
                tooltip: {
                    ...defaultTooltip,
                    show: hasTooltip,
                    trigger: 'item',
                    formatter(param) {
                        return `${param.data.name}<br>${param.value
                            .map(
                                (dataPoint, i) => `${param.marker} ${axisNames[i].name}: ${yValueFormatter(dataPoint)}`,
                            )
                            .join('<br>')}`;
                    },
                },
                legend: {
                    ...defaultLegend,
                    show: hasLegend,
                    data: series.dependent.map((serum) => serumNameFormatter(serum)),
                },
                radar: {
                    name: {
                        textStyle: {
                            color: neutral70,
                        },
                    },
                    shape: 'circle',
                    center: ['50%', '50%'],
                    radius: [0, `${radarOuterRadius}%`],
                    startAngle: radarStartAngle,
                    indicator: axisNames,
                    splitArea: {
                        areaStyle: {
                            opacity: 0.1,
                        },
                    },
                },
                series: {
                    type: 'radar',
                    color: series.dependent.map((serum) => colorFunction(serum)),
                    animation: isAnimationActive,
                    data: series.dependent.map((serum) => ({
                        areaStyle: {
                            opacity: dependentSeriesProps[serum.serumId].fillOpacity,
                        },
                        name: serumNameFormatter(serum),
                        value: data.map((d) => d[serum.name]),
                    })),
                },
                textStyle,
            }}
        />
    );
};

export default WWRadar;
