import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import OrganizationModel from '../../../../_models/organizations';

import { Input, ModalCard } from '../../../../../shared';

export default function CreateClientOrganizationModal(props) {
    const { onClose, open, consultantId } = props;
    const dispatch = useDispatch();

    const onSubmit = (form) => {
        dispatch(OrganizationModel.create({ ...form, type: 'client', consultantId }));
        onClose();
    };

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title="Create Client Organization"
            className="CreateOrganizationModal"
            formOptions={{ onSubmit }}
            autofocus
            primaryButtonOptions={{ content: 'Create Organization', id: 'submit' }}
            secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: onClose }}
        >
            <Input id="name" type="text" label="Organization Name" placeholder="name" gray required />
        </ModalCard>
    );
}

CreateClientOrganizationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    consultantId: PropTypes.string.isRequired,
};

CreateClientOrganizationModal.defaultProps = {
    onClose: () => {},
    open: false,
};
