import moment from 'moment/moment';

// SELECTORS
const getUploadJobsForActiveUser = (state) => {
    const { app, jobs } = state;
    const userId = app.user._id;
    const usersUploadJobIds = jobs.allIds.filter(
        (id) => jobs.byId[id].ownerType === 'dataset' && jobs.byId[id].user.id === userId,
    );
    const usersUploadJobs = usersUploadJobIds
        .map((id) => jobs.byId[id])
        .sort((a, b) => (moment(a.timeCreated).isAfter(b.timeCreated) ? -1 : 1));
    return usersUploadJobs;
};

export default {
    getUploadJobsForActiveUser,
};
