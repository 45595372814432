import UnitSelectors from '../../../Units/_model/selectors';

const getUnitIds = (tabIds) => (state) => {
    const _tabIds = Array.isArray(tabIds) ? tabIds : [tabIds]; // allow user to input a string or array
    const { tabs } = state;
    const unitIds = _tabIds.reduce((acc, tabId) => {
        const tab = tabs.byId[tabId];
        if (!tab || !tab.units) return acc;
        return acc.concat(tab.units);
    }, []);
    return unitIds;
};

// TODO: what is the best way to test this function?  (depends on tabs, units, features, models in state)
const getModelIds = (tabIds) => (state) => {
    const unitIds = getUnitIds(tabIds)(state);
    const modelIds = UnitSelectors.getModelIds(unitIds)(state);
    return [...new Set(modelIds)];
};

const allUnitsHydrated = (tabIds) => (state) => {
    const unitIds = getUnitIds(tabIds)(state);
    return UnitSelectors.allHydrated(unitIds)(state);
};

export default {
    getUnitIds,
    getModelIds,
    allUnitsHydrated,
};
