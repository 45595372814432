import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, ModalCard } from '../../shared';
import DatasetUpload from './Upload';
import DatasetJoin from './Join';

require('./styles.scss');

export default function DatasetAdd({ open, onClose }) {
    const [display, setDisplay] = useState('choose');

    if (display === 'choose')
        return (
            <ModalCard title="Add Dataset" open={open} onClose={onClose}>
                <div className="DataAdd">
                    <div className="DataAdd__choice" role="button" tabIndex={0} onClick={() => setDisplay('upload')}>
                        <Icon icon="large_color/file-csv" size="150px" />
                        <span className="DataAdd__title">Upload File</span>
                        <span className="DataAdd__subtitle">Upload your dataset as a .csv file.</span>
                    </div>
                    <div className="DataAdd__choice" role="button" tabIndex={0} onClick={() => setDisplay('joined')}>
                        <Icon icon="large_color/join-dataset" size="150px" />
                        <span className="DataAdd__title">Create Joined Dataset</span>
                        <span className="DataAdd__subtitle">Combine features from separate datasets.</span>
                    </div>
                </div>
            </ModalCard>
        );
    if (display === 'upload')
        return (
            <DatasetUpload
                open
                onClose={() => {
                    onClose();
                    setDisplay('choose');
                }}
                onBack={() => setDisplay('choose')}
            />
        );
    if (display === 'joined')
        return (
            <DatasetJoin
                open
                onClose={() => {
                    onClose();
                    setDisplay('choose');
                }}
                onBack={() => setDisplay('choose')}
            />
        );
}

DatasetAdd.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

DatasetAdd.defaultProps = {
    open: false,
};
