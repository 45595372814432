import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class Card extends PureComponent {
    render() {
        const { children, className } = this.props;
        return <div className={ClassNames('Card', className)}>{children}</div>;
    }
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
Card.defaultProps = {
    className: '',
};
