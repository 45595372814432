import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import OrganizationModel from '../../../_models/organizations';
import { Breadcrumb, Icon, Loader } from '../../../../shared';
import OrganizationSettings from '../../Organization';

require('./styles.scss');

export default function RootOrganizationView() {
    const { organizationId } = useParams();

    const organizations = useSelector((state) => state.organizations);
    const organization = organizations?.byId[organizationId];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(OrganizationModel.index());
    }, [dispatch]);

    const breadcrumbItems = [
        { label: 'DeepSea Administration', path: '/settings/root' },
        { label: organization?.name },
    ];

    return !organization ? (
        <Loader />
    ) : (
        <div className="RootOrganizationView">
            <div className="RootOrganizationView__header">
                <Icon icon="whale" size="24px" />
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="RootOrganizationView__body">
                <OrganizationSettings />
            </div>
        </div>
    );
}
