import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import _ from 'lodash';
import memoize from 'memoize-one';

import UnitModel from '../_model';
import UnitSelectors from '../_model/selectors';
import ModelSelector from '../../Models/_models/models/selectors';
import ErrorBoundary from '../../shared/ErrorBoundary';

import ChartUnit from '../Chart';
import KpiUnit from '../KPI';
import TableUnit from '../Table';
import CustomUnit from '../Custom';
import UnitLoader from '../components/Loader';

import {
    Card,
    Form,
    Icon,
    IconButton,
    ToolTip,
    Input,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    ConfirmDeleteMenu,
} from '../../shared';

const unitTypes = {
    chart: ChartUnit,
    kpi: KpiUnit,
    table: TableUnit,
    custom: CustomUnit,
};

require('./styles.scss');

class ReportUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dimensions: { width: null, height: null },
        };
    }

    componentDidMount = () => {
        const { id, tabId, dashboardId, publicFilters, getData, jobId } = this.props;
        getData(id, { tabId, dashboardId, jobId }, { filters: publicFilters });
    };

    componentDidUpdate = (prevProps) => {
        const {
            id,
            tabId,
            dashboardId,
            filters,
            publicFilters,
            getData,
            modelRunning,
            jobId,
            filtersLoading,
        } = this.props;
        const filtersChanged =
            (!_.isEqual(prevProps.filters, filters) || !_.isEqual(prevProps.publicFilters, publicFilters)) &&
            !filtersLoading &&
            prevProps.filtersHydrated;

        const modelDoneRunning = prevProps.modelRunning && !modelRunning;
        const jobIdChanged = prevProps.jobId === 'latest' ? modelDoneRunning : prevProps.jobId !== jobId;

        if (filtersChanged || jobIdChanged) {
            if (this.childGetData) {
                this.childGetData();
            } else {
                getData(id, { tabId, dashboardId, jobId }, { filters: publicFilters });
            }
        }
    };

    setChildDataFunction = (getData) => {
        this.childGetData = getData;
    };

    getTempData = (temporaryUnitQueries = {}, temporaryOptions = {}) => {
        const { id, tabId, dashboardId, publicFilters, jobId, getData } = this.props;
        const { page, limit } = temporaryOptions;
        getData(
            id,
            { tabId, dashboardId, jobId, page, limit },
            { filters: [...publicFilters, ...temporaryUnitQueries.filters], orderBy: temporaryUnitQueries.orderBy },
        );
    };

    render() {
        const { unit, dashboard, features } = this.props;
        const { dimensions } = this.state;
        const { width, height } = dimensions;
        const { type, hydrated } = unit;
        const UnitType = unitTypes[type];

        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.setState({ dimensions: contentRect.bounds });
                }}
            >
                {({ measureRef }) => (
                    <div ref={measureRef} className="ReportUnit">
                        <ErrorBoundary>
                            {!hydrated && <UnitLoader />}
                            <UnitType
                                setGetData={this.setChildDataFunction}
                                unit={unit}
                                features={features}
                                colors={dashboard?.colors}
                                getTempData={this.getTempData}
                                width={width}
                                height={height}
                            />
                        </ErrorBoundary>
                    </div>
                )}
            </Measure>
        );
    }
}

ReportUnit.propTypes = {
    id: PropTypes.string,
    unit: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        vis: PropTypes.shape({}),
        data: PropTypes.shape({
            data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
        }),
        hydrated: PropTypes.bool,
        name: PropTypes.string,
    }),
    modelRunning: PropTypes.bool,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
    }).isRequired,
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        filters: PropTypes.arrayOf(PropTypes.string),
        colors: PropTypes.shape({ colorScheme: PropTypes.string }),
    }),
    tabId: PropTypes.string,
    dashboardId: PropTypes.string,
    filters: PropTypes.array.isRequired,
    publicFilters: PropTypes.array,
    getData: PropTypes.func.isRequired,
    jobId: PropTypes.string.isRequired,
    filtersLoading: PropTypes.bool.isRequired,
    filtersHydrated: PropTypes.bool.isRequired,
};

ReportUnit.defaultProps = {
    id: null,
    unit: {
        _id: null,
        type: null,
        vis: {},
        data: {
            data: null,
        },
        hydrated: false,
        name: '',
    },
    modelRunning: false,
    dashboard: null,
    tabId: null,
    dashboardId: null,
    publicFilters: [],
};

const makeMapStateToProps = () => {
    const getMemoizedFilters = memoize(UnitSelectors.getFilters);
    return (state, props) => {
        const unit = state.units.byId[props.id];
        const dashboard = state.dashboards.byId[props.dashboardId];
        const { features, datasets, units, filters: stateFilters } = state;
        const filters = getMemoizedFilters(props.id, stateFilters, features, datasets, units);
        const filtersLoading = state.filters.loading;
        const filtersHydrated = state.filters.hydrated;
        const model = UnitSelectors.getModel(props.id)(state);
        const jobId = ModelSelector.getModelRunId(model && model._id)(state) || 'latest';
        return {
            unit,
            dashboard,
            filters,
            features,
            modelRunning: model?.running,
            jobId,
            filtersLoading,
            filtersHydrated,
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    const getData = (unitId, queryParameters, temporaryQueries) =>
        dispatch(UnitModel.data(unitId, queryParameters, temporaryQueries));
    return { getData };
};

const connectedComponent = connect(makeMapStateToProps, mapDispatchToProps)(ReportUnit);
export default connectedComponent;
