import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ClassNames from 'classnames';

import { Input, Icon, IconButton, Button } from '../../../shared';

import './styles.scss';

const JoinedDatasetSetup = (props) => {
    const { joinSetup, updateJoinSetup, updateJoinType } = props;
    const { datasets, features } = useSelector((state) => ({
        datasets: state.datasets,
        features: state.features,
    }));

    const onChangeJoinList = (joinPairIndex, datasetIndex, value) => {
        const updatedJoinList = [...joinSetup.joinList];
        updatedJoinList[joinPairIndex][datasetIndex] = value;
        updateJoinSetup({ joinList: updatedJoinList });
    };

    const addJoinPair = () => {
        updateJoinSetup({ joinList: [...joinSetup.joinList, [null, null]] });
    };

    const removeJoinPair = (joinPairIndex) => {
        const updatedJoinList = [...joinSetup.joinList];
        updatedJoinList.splice(joinPairIndex, 1);
        updateJoinSetup({ joinList: updatedJoinList });
    };

    return (
        <div className="JoinedDatasetSetup">
            <header className="JoinedDatasetWorkflow__datasets">
                <Input
                    type="select"
                    id="dataset1"
                    options={datasets.allIds.map((datasetId) => ({
                        label: datasets.byId[datasetId].name,
                        value: datasetId,
                    }))}
                    className="JoinedDatasetWorkflow__labelWithDot"
                    label="Base Dataset"
                    filterable
                    gray
                    placeholder="Select Dataset"
                    required
                    onChange={(id, value) => {
                        updateJoinSetup({
                            dataset1: value,
                            dataset2: value === joinSetup.dataset2 ? null : joinSetup.dataset2,
                        });
                    }}
                    value={joinSetup.dataset1}
                    disabled={!updateJoinSetup}
                />
                <div className="JoinedDatasetWorkflow__plus">+</div>
                <Input
                    type="select"
                    id="dataset2"
                    options={datasets.allIds.map((datasetId) => ({
                        label: datasets.byId[datasetId].name,
                        value: datasetId,
                    }))}
                    className="JoinedDatasetWorkflow__labelWithDot JoinedDatasetWorkflow__labelWithDot--secondary"
                    label="Dataset 2"
                    filterable
                    gray
                    placeholder="Select Dataset"
                    required
                    onChange={(id, value) => {
                        updateJoinSetup({
                            dataset2: value,
                            dataset1: value === joinSetup.dataset1 ? null : joinSetup.dataset1,
                        });
                    }}
                    value={joinSetup.dataset2}
                    disabled={!updateJoinSetup}
                />
            </header>
            <hr />
            {joinSetup.dataset1 && joinSetup.dataset2 ? (
                <React.Fragment>
                    <div className="JoinedDatasetWorkflow__title">
                        Joined Columns:
                        {updateJoinSetup && (
                            <Button onClick={addJoinPair} gray small>
                                Add Joined Column
                            </Button>
                        )}
                    </div>
                    {joinSetup.joinList.map((joinPair, i) => (
                        <div className="JoinedDatasetWorkflow__columns" key={`${joinPair[0]}_${joinPair[1]}`}>
                            <Input
                                type="select"
                                id={`dataset1Pair${i}`}
                                options={features.allIds
                                    .filter((id) => features.byId[id].dataset._id === joinSetup.dataset1)
                                    .map((featureId) => ({
                                        label: features.byId[featureId].name,
                                        value: featureId,
                                    }))}
                                className="JoinedDatasetWorkflow__labelWithDot"
                                label={datasets.byId[joinSetup.dataset1]?.name}
                                filterable
                                gray
                                placeholder="Select Column"
                                required
                                onChange={(_, value) => onChangeJoinList(i, 0, value)}
                                value={joinPair[0]}
                                disabled={!updateJoinSetup}
                            />
                            <div className="JoinedDatasetWorkflow__plus">+</div>
                            <Input
                                type="select"
                                id={`dataset2Pair${i}`}
                                options={features.allIds
                                    .filter((id) => features.byId[id].dataset._id === joinSetup.dataset2)
                                    .map((featureId) => ({
                                        label: features.byId[featureId].name,
                                        value: featureId,
                                    }))}
                                className="JoinedDatasetWorkflow__labelWithDot JoinedDatasetWorkflow__labelWithDot--secondary"
                                label={datasets.byId[joinSetup.dataset2]?.name}
                                filterable
                                gray
                                placeholder="Select Column"
                                required
                                onChange={(_, value) => onChangeJoinList(i, 1, value)}
                                value={joinPair[1]}
                                disabled={!updateJoinSetup}
                            />
                            {!!i && updateJoinSetup && <IconButton icon="trash" onClick={() => removeJoinPair(i)} />}
                        </div>
                    ))}
                    <div className="JoinedDatasetWorkflow__selectors">
                        {[
                            {
                                label: 'Full Join',
                                value: 'full',
                                icon: 'large_color/join-full',
                                description: 'Keep all rows from each dataset',
                            },
                            {
                                label: 'Left Join',
                                value: 'left',
                                icon: 'large_color/join-left',
                                description: 'Keep all rows from the base dataset',
                            },
                            {
                                label: 'Inner Join',
                                value: 'inner',
                                icon: 'large_color/join-inner',
                                description: 'Only keep matching rows',
                            },
                        ].map((option) => (
                            <div
                                className={ClassNames('JoinedDatasetWorkflow__selector', {
                                    'JoinedDatasetWorkflow__selector--selected': joinSetup.joinType === option.value,
                                })}
                                id="joinType"
                                key={option.value}
                                role="button"
                                tabIndex="0"
                                aria-pressed="false"
                                onClick={() => {
                                    if (updateJoinSetup) updateJoinSetup({ joinType: option.value });
                                    else updateJoinType(option.value);
                                }}
                                value={joinSetup.joinType}
                            >
                                <Icon icon={option.icon} size="50px" />
                                <div className="JoinedDatasetWorkflow__selector__description">
                                    <span>{option.label}</span>
                                    <span>{option.description}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ) : (
                <div className="JoinedDatasetWorkflow__messageIcon">
                    <Icon icon="large_color/select-datasets" size="100px" />
                    Select your datasets to start configuring join settings.
                </div>
            )}
        </div>
    );
};

JoinedDatasetSetup.propTypes = {
    joinSetup: PropTypes.shape({
        dataset1: PropTypes.string,
        dataset2: PropTypes.string,
        joinType: PropTypes.string,
        joinList: PropTypes.array,
    }).isRequired,
    updateJoinSetup: PropTypes.func,
    updateJoinType: PropTypes.func,
};

JoinedDatasetSetup.defaultProps = {
    updateJoinSetup: null,
    updateJoinType: null,
};

export default JoinedDatasetSetup;
