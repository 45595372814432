import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../shared';

export default function OptionsConfig({ options, updateConfig, editable }) {
    const [listOptions, setListOptions] = useState(options);
    return (
        <Input
            id="options"
            type="textarea"
            gray
            label="List Items (Separate with commas)"
            value={listOptions}
            onChange={(_, value) => setListOptions(value)}
            onSubmit={updateConfig}
            submitOnBlur
            disabled={!editable}
            // this is how I had to make the input required without using a Form
            required
            errors={listOptions === '' ? [{ id: 'options', message: 'This input is required.' }] : []}
        />
    );
}

OptionsConfig.propTypes = {
    options: PropTypes.string,
    updateConfig: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
};

OptionsConfig.defaultProps = {
    options: undefined,
};
