import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectModel } from '../../_models';
import { DatasetModel } from '../../../Data/_models';
import { DashboardModel } from '../../../Dashboards/_models';
import FormModel from '../../../Forms/redux';
import { ModelModel, PackageModel } from '../../../Models/_models';

import { Input, Icon, Notification } from '../../../shared';
import ModalCard from '../../../shared/Modal/ModalCard';

require('./styles.scss');

export default function TransferProjectAssets(props) {
    const { onClose, open, project } = props;
    const dispatch = useDispatch();

    sessionStorage.setItem('projectId', project._id);

    useEffect(() => {
        dispatch(DatasetModel.index());
        dispatch(DashboardModel.index());
        dispatch(FormModel.index());
        dispatch(ModelModel.index());
        dispatch(PackageModel.index());
    }, [dispatch]);

    const myUserId = useSelector((state) => state.app.user._id);
    const permissions = useSelector((state) => state.permissions);
    // if we want anything where user has edit access to resources
    // backend validation does not allow this
    // const assetTypes = ['dataset', 'dashboard', 'model', 'form', 'package'];
    // const myEditProjectPermissions = Object.values(permissions.byId).filter(
    //     (permission) => permission.userId === myUserId && assetTypes.some((item) => permission.roles[item] === 'edit'),
    // );
    const myEditProjectPermissions = Object.values(permissions.byId).filter(
        (permission) => permission.userId === myUserId && permission.roles.editProject,
    );
    const myProjectPermission = myEditProjectPermissions.find((permission) => permission.projectId === project._id);
    const projects = useSelector((state) => state.projects.byId);
    const myEditProjectIds = myEditProjectPermissions.map((permission) => permission.projectId);
    const myEditProjectOptions = Object.values(projects).filter(
        (proj) => myEditProjectIds.includes(proj._id) && proj._id !== project._id,
    );

    const onSubmit = (form) => {
        dispatch(ProjectModel.transferAssets(project._id, form));
        onClose();
    };

    const projectDatasets = useSelector((state) =>
        Object.values(state.datasets.byId).filter((dataset) => dataset.projectId === project._id),
    );
    const projectDashboards = useSelector((state) =>
        Object.values(state.dashboards.byId).filter((dataset) => dataset.projectId === project._id),
    );
    const projectForms = useSelector((state) =>
        Object.values(state.forms.byId).filter((dataset) => dataset.projectId === project._id),
    );
    const projectModels = useSelector((state) =>
        Object.values(state.models.byId).filter((dataset) => dataset.projectId === project._id),
    );
    const projectPackages = useSelector((state) =>
        Object.values(state.packages.byId).filter((dataset) => dataset.projectId === project._id),
    );

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title="Transfer Assets"
            className="TransferProjectAssets"
            formOptions={{ onSubmit }}
            autofocus
            primaryButtonOptions={{ content: 'Transfer Assets', id: 'submit' }}
            secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: onClose }}
        >
            <div className="TransferProjectAssets__container">
                <div className="TransferProjectAssets__notification">
                    <Icon icon="exclamation-triangle" size="75px" className="PublishModal__warningIcon" />
                    <p>
                        These assets will be moved to another project. For transferred dashboards to work, make sure to
                        transfer all datasets, packages, forms, & models being referenced.
                    </p>
                </div>
                {!myEditProjectOptions.length && (
                    <Notification status="warn" message="You have no other projects to transfer assets to." inline />
                )}
                <div className="TransferProjectAssets__projects">
                    <Input id="fromProjectName" type="text" label="Source Project" value={project.name} gray disabled />
                    <Input
                        id="toProjectId"
                        type="select"
                        options={myEditProjectOptions.map((proj) => ({ label: proj.name, value: proj._id }))}
                        label="Transfer to:"
                        placeholder="Select Project"
                        required
                        gray
                    />
                </div>
                <hr />
                <div className="TransferProjectAssets__assets">
                    <Input
                        id="dashboards"
                        type="multiSelect"
                        options={projectDashboards.map((item) => ({ label: item.name, value: item._id }))}
                        label="Dashboards"
                        placeholder="Select Dashboards"
                        value={[]}
                        gray
                        disabled={myProjectPermission.roles.dashboard !== 'edit'}
                    />
                    <Input
                        id="datasets"
                        type="multiSelect"
                        options={projectDatasets.map((item) => ({ label: item.name, value: item._id }))}
                        label="Datasets"
                        placeholder="Select Datasets"
                        value={[]}
                        gray
                        disabled={myProjectPermission.roles.dataset !== 'edit'}
                    />
                    <Input
                        id="packages"
                        type="multiSelect"
                        options={projectPackages.map((item) => ({ label: item.name, value: item._id }))}
                        label="Packages (Source Code)"
                        placeholder="Select Packages"
                        value={[]}
                        gray
                        disabled={myProjectPermission.roles.package !== 'edit'}
                    />
                    <Input
                        id="models"
                        type="multiSelect"
                        options={projectModels.map((item) => ({ label: item.name, value: item._id }))}
                        label="Models"
                        placeholder="Select Models"
                        value={[]}
                        gray
                        disabled={myProjectPermission.roles.model !== 'edit'}
                    />
                    <Input
                        id="forms"
                        type="multiSelect"
                        options={projectForms.map((item) => ({ label: item.name, value: item._id }))}
                        label="Forms"
                        placeholder="Select Forms"
                        value={[]}
                        gray
                        disabled={myProjectPermission.roles.form !== 'edit'}
                    />
                </div>
            </div>
        </ModalCard>
    );
}

TransferProjectAssets.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    project: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        public: PropTypes.bool,
    }),
};

TransferProjectAssets.defaultProps = {
    onClose: () => {},
    open: false,
    project: {},
};
