/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Loader, Input, Icon, ModalCard } from '../../../shared';

import './styles.scss';

export default function CodeModal(props) {
    const { title, fileContent, fileExtension, open, onClose, handleSubmit } = props;
    const [currentCode, setCurrentCode] = useState(fileContent);
    const [disableSave, setDisableSave] = useState(true);

    useEffect(() => {
        if (fileContent === currentCode || currentCode === '') {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [currentCode]);

    useEffect(() => {
        if (open === true) {
            setCurrentCode(fileContent);
            setDisableSave(true);
        }
    }, [open]);

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title={title}
            secondaryButtonOptions={{
                content: 'Discard Changes',
                id: 'cancel',
                onClick: () => onClose(),
            }}
            primaryButtonOptions={{
                content: 'Save Changes',
                id: 'submit',
                disabled: disableSave,
                onClick: () => {
                    handleSubmit(currentCode);
                },
            }}
        >
            <div className="CodeModal">
                <div className="CodeModal__header">
                    <div className="CodeModal__warningMessage">
                        <Icon size="25px" icon="exclamation-triangle" />
                        Changes will apply to all models that reference this code.
                    </div>
                </div>
                <Input
                    id="algoCode"
                    type="codebox"
                    className="CodeModal__codeBox"
                    fileExtension={fileExtension}
                    autofocus
                    value={fileContent}
                    onChange={(_, value) => setCurrentCode(value)}
                />
            </div>
        </ModalCard>
    );
}

CodeModal.propTypes = {
    title: PropTypes.string,
    fileContent: PropTypes.string,
    fileExtension: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
};

CodeModal.defaultProps = {
    title: 'Edit',
    fileContent: '',
    fileExtension: 'py',
    open: false,
    onClose: () => {},
};
