import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModelSelector from '../../_models/models/selectors';
import { ModelModel } from '../../_models';
import { DatasetModel } from '../../../Data/_models';

import DatasetTable from '../../../Data/Tabs/Data/table';
import SelectableItem from '../../../shared/SelectableItem';
import { Input, Loader } from '../../../shared';

require('./styles.scss');

export default function ModelOutputs(props) {
    const { modelId } = props;
    const model = useSelector((state) => state.models.byId[modelId]);
    const datasets = useSelector((state) => state.datasets);
    const dispatch = useDispatch();

    const [_selectedDatasetId, setSelectedDatasetId] = useState(null);
    let selectedDatasetId = _selectedDatasetId;
    if (model && model.outputs && !model.outputs.some((output) => output.dataset._id === selectedDatasetId)) {
        // datasetId not in model outputs -> default to the first model output (eg. when the modelId changes)
        selectedDatasetId = model.outputs[0] ? model.outputs[0].dataset._id : null;
    }
    const [datasetName, setDatasetName] = useState(datasets.byId[selectedDatasetId]?.name);
    const [datasetDescription, setDatasetDescription] = useState(datasets.byId[selectedDatasetId]?.description);

    const modelModelRunId = useSelector(ModelSelector.getModelRunId(modelId));

    useEffect(() => {
        dispatch(DatasetModel.show(selectedDatasetId)).then(({ dataset }) => {
            if (!datasetName) setDatasetName(dataset.name);
            if (!datasetDescription) setDatasetDescription(dataset.description);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedDatasetId]);

    return selectedDatasetId && modelModelRunId ? (
        <div className="ModelOutputs">
            <div className="ModelOutputs__header">
                {model &&
                    model.outputs &&
                    model.outputs.map(
                        (output) =>
                            datasets.byId[output.dataset._id] && (
                                <SelectableItem
                                    key={output.dataset._id}
                                    name={datasets.byId[output.dataset._id]?.name}
                                    selected={selectedDatasetId === output.dataset._id}
                                    onClick={() => setSelectedDatasetId(output.dataset._id)}
                                />
                            ),
                    )}
                <Input
                    id="resultStorage"
                    type="select"
                    value={model.resultStorage}
                    label="Run Data Storage"
                    options={[
                        { label: 'All runs', value: 'all' },
                        { label: 'Latest run', value: 'latest' },
                        { label: 'Merge runs', value: 'new' },
                    ]}
                    onChange={(_, value) => dispatch(ModelModel.update(model._id, { resultStorage: value }))}
                    sm
                    inlineLabel
                    gray
                />
            </div>
            <hr />
            <div className="ModelOutputs__main">
                {selectedDatasetId && (
                    <div className="ModelOutputs__datasetInputs">
                        <Input
                            id="datasetName"
                            className="ModelOutputs__datasetName"
                            type="text"
                            value={datasetName}
                            label="Dataset Name"
                            onChange={(_, value) => setDatasetName(value)}
                            onBlur={() => dispatch(DatasetModel.update(selectedDatasetId, { name: datasetName }))}
                            gray
                        />
                        <Input
                            id="datasetDescription"
                            className="ModelOutputs__datasetDescription"
                            type="text"
                            value={datasetDescription}
                            label="Description"
                            onChange={(_, value) => setDatasetDescription(value)}
                            onBlur={() =>
                                dispatch(DatasetModel.update(selectedDatasetId, { description: datasetDescription }))
                            }
                            gray
                        />
                    </div>
                )}
                <DatasetTable
                    key={selectedDatasetId} // remount the component when the selected dataset changes
                    datasetId={selectedDatasetId}
                    jobId={modelModelRunId}
                    loadingNumber={5}
                />
            </div>
        </div>
    ) : (
        <Loader type="content" loader="content" />
    );
}

ModelOutputs.propTypes = {
    modelId: PropTypes.string.isRequired,
};
