import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import LoginModel from '../_models/login';
import CurrentSVG from '../_components/Current';

import config from '../../../config';

require('./styles.scss');

export default function LoginRouter() {
    const version = config.app.version;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoginModel.logout());
    }, [dispatch]);

    return (
        <div className="Login">
            <CurrentSVG />
            <Outlet />
            <div className="Login__Footer">
                <span style={{ paddingLeft: 12 }} className="Input--gray Input__label">
                    {version}
                </span>
            </div>
        </div>
    );
}
