import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { typeIcons } from '../../constants';
import { useDatasetFeaturePage } from './helpers';

import { Input, Icon, KPI, EditableTitle, Loader, TextButton, ScrollArea } from '../../../shared';
import SelectableItem from '../../../shared/SelectableItem';
import DataEmpty from '../../empty';
import ChangeFeatureTypeModal from './ChangeTypeModal';
import FeaturePreview from './FeaturePreview';

import './styles.scss';
import { getFeatureFormatFunction } from '../../helpers';

export default function DataFeatures() {
    const { datasetId } = useParams();
    const {
        datasetFeatures,
        activeFeature,
        setActiveFeatureId,
        activeFeatureForm,
        canEditDatasets,
        onUpdateFeature,
    } = useDatasetFeaturePage(datasetId);
    const readOnly = !canEditDatasets;

    const handleFeatureNameChange = (_, name) => {
        onUpdateFeature(activeFeature._id, { name });
    };

    const [featureTypeModalOpen, setFeatureTypeModalOpen] = useState(false);
    const numberFormatter = getFeatureFormatFunction({ type: 'number' });

    return (
        <div className="DataFeatures">
            <div className="DataFeatures__content">
                <aside className="DataFeatures__list">
                    <span className="DataFeatures__listLabel">Features</span>
                    {!datasetFeatures &&
                        Array.from({ length: 15 }, (_, i) => (
                            <div key={`loader-${i}`} className="DataFeatures__list__loaders">
                                <Loader type="content" loader="content" />
                            </div>
                        ))}
                    {datasetFeatures?.map((feat) => (
                        <SelectableItem
                            key={feat._id}
                            onClick={() => setActiveFeatureId(feat._id)}
                            type={<Icon icon={feat.hidden ? 'hidden' : typeIcons[feat.type]} size="16px" />}
                            name={feat.name}
                            selected={feat._id === activeFeature?._id}
                            deactivated={feat.hidden}
                            optionsHeading
                            options={[
                                {
                                    id: feat._id,
                                    type: <Icon size="16px" icon={feat.hidden ? 'visible' : 'hidden'} />,
                                    name: feat.hidden ? 'Show Column' : 'Hide Column',
                                    onClick: () => onUpdateFeature(feat._id, { hidden: !feat.hidden }),
                                },
                            ]}
                        />
                    ))}
                </aside>
                <span className="DataFeatures__verticalRule" />
                {activeFeature ? (
                    <main className="DataFeatures__Feature">
                        <ScrollArea>
                            <div className="DataFeatures__topBar">
                                <EditableTitle
                                    defaultValue={activeFeature.name}
                                    key={activeFeature._id}
                                    disabled={readOnly}
                                    onSubmit={handleFeatureNameChange}
                                />
                            </div>
                            <div className="DataFeatures__row">
                                <section className="DataFeatures__dataSection">
                                    <div className="DataFeatures__inputs">
                                        <div className="DataFeatures__inputs__type">
                                            <Input
                                                id="type"
                                                type="text"
                                                label="Type"
                                                gray
                                                disabled
                                                value={activeFeature?.type}
                                            />
                                            {!readOnly && (
                                                <TextButton
                                                    blue
                                                    onClick={() => {
                                                        setFeatureTypeModalOpen(true);
                                                    }}
                                                >
                                                    Change Feature Type
                                                </TextButton>
                                            )}

                                            <ChangeFeatureTypeModal
                                                onClose={() => setFeatureTypeModalOpen(false)}
                                                open={featureTypeModalOpen}
                                                activeFeature={activeFeature}
                                                key={activeFeature._id} // used to force re-mount between features
                                            />
                                        </div>
                                        <Input
                                            id="description"
                                            type="text"
                                            label="Feature Description"
                                            placeholder="optional"
                                            gray
                                            submitOnBlur
                                            disabled={readOnly}
                                            {...activeFeatureForm.description}
                                        />
                                    </div>
                                </section>
                            </div>
                            <hr />
                            <div className="DataFeatures__row">
                                <FeaturePreview activeFeature={activeFeature} />
                            </div>
                            {activeFeature.type === 'number' ? (
                                <div className="DataFeatures__row">
                                    <section className="DataFeatures__dataSection DataFeatures__details">
                                        <header className="DataFeatures__details">Details</header>
                                        <div className="DataFeatures__details--number">
                                            <KPI label="Max" value={numberFormatter(activeFeature.stats?.max)} />
                                            <KPI label="Min" value={numberFormatter(activeFeature.stats?.min)} />
                                            <KPI label="Mean" value={numberFormatter(activeFeature.stats?.avg)} />
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                        </ScrollArea>
                    </main>
                ) : (
                    <main className="DataFeatures__Feature">
                        <DataEmpty featureEmpty />
                    </main>
                )}
            </div>
        </div>
    );
}
