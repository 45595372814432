import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ModelModel, ModelRunModel } from '../../_models';
import { DatasetModel } from '../../../Data/_models';

import { Button, Icon, Loader, ScrollArea, SidePanel } from '../../../shared';
import ModelOutputs from '../../_components/Outputs';
import ModelRunError from '../../_components/RunError';
import ModelPanel from '../../_components/ModelPanel';
import CodeEditor from '../../_components/CodeEditor';

require('./styles.scss');

const RunModel = (props) => {
    const { model, packageId, moduleFileName } = props;

    const modelRuns = useSelector((state) => state.modelRuns);
    const models = useSelector((state) => state.models);

    const dispatch = useDispatch();

    useEffect(() => {
        if (model && !model.runs) {
            dispatch(ModelModel.getRuns(model?._id), { limit: 20 });
        }
    }, [dispatch, model?._id, model.runs]); // eslint-disable-line react-hooks/exhaustive-deps

    const [prevRunning, setPrevRunning] = useState(false);
    const [lastModelRunId, setLastModelRunId] = useState(null);

    // When the model is run for the first time, we need to fetch the output datasets
    // -> I would like a different solution to this, but it may require some architecture changes
    const datasets = useSelector((state) => state.datasets);
    useEffect(() => {
        if (datasets.hydrated && model?.outputs) {
            model.outputs.forEach((output) => {
                const dataset = datasets.byId[output.dataset._id];
                if (!dataset || (!dataset.loading && !dataset.name)) {
                    dispatch(DatasetModel.show(output.dataset._id));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, model?.outputs, datasets.hydrated]);

    if (!model || !models.hydrated || !modelRuns.hydrated || datasets.loading) return <Loader type="spinner" />;

    // updates state if model has just started or ended running
    if (prevRunning !== model.running && model.running !== undefined && model.runs) {
        const modelRunId = model.runs[model.runs.length - 1];
        setLastModelRunId(modelRunId);
        setPrevRunning(model.running);
    }

    let content = (
        <div className="RunModel__prompt">
            <Icon icon="large_color/model-run" size="75px" />
            <div>
                <b>Run your model</b> to ensure it works in DeepSea, and preview the datasets created from its return
                values.
            </div>
        </div>
    );

    const lastModelRun = modelRuns.byId[lastModelRunId];

    if (model.running || ['queued', 'started'].includes(lastModelRun?.status)) {
        content = (
            <div className="RunModel__status  RunModel__status--running">
                <Icon icon="spinner" size="75px" />
                Running Model...
                <Button gray onClick={() => dispatch(ModelRunModel.cancel(model.runs[model.runs.length - 1]))}>
                    Cancel Run
                </Button>
            </div>
        );
    } else if (datasets.hydrated && model.outputs?.length > 0 && !datasets.byId[model.outputs[0]?.dataset._id]) {
        content = (
            <div className="RunModel__status RunModel__status--error">
                <Icon icon="exclamation-triangle" size="75px" />
                <div>Output Dataset Not Found</div>
            </div>
        );
    } else if (lastModelRunId) {
        switch (lastModelRun.status) {
            case 'finished':
                content = <ModelOutputs modelId={model._id} />;
                break;
            case 'cancelled':
                content = (
                    <div className="RunModel__status">
                        <Icon icon="times-o" size="75px" />
                        Model Run Cancelled
                    </div>
                );
                break;
            case 'failed':
            case 'expired':
            default:
                content = (
                    <div className="RunModel__status RunModel__status--error">
                        <Icon icon="exclamation-triangle" size="75px" />
                        <div className="RunModel__status__error__title">Model Run Error</div>
                        <ModelRunError modelRun={lastModelRun} />
                    </div>
                );
                break;
        }
    } else if (model.outputs?.length) {
        content = <ModelOutputs modelId={model._id} />;
    }

    return (
        <div className="RunModel">
            <SidePanel
                tabs={[
                    { icon: 'brain', component: <ModelPanel modelId={model._id} noEditLink />, tooltip: 'Run Model' },
                    {
                        icon: 'code',
                        component: <CodeEditor packageId={packageId} defaultFileName={moduleFileName} />,
                        tooltip: 'Source Code',
                    },
                ]}
                startOpen
            >
                <ScrollArea>{content}</ScrollArea>
            </SidePanel>
        </div>
    );
};

RunModel.propTypes = {
    model: PropTypes.shape({
        _id: PropTypes.string,
        runs: PropTypes.arrayOf(PropTypes.string),
        outputs: PropTypes.arrayOf(PropTypes.shape()),
        running: PropTypes.bool,
    }),
    packageId: PropTypes.string.isRequired,
    moduleFileName: PropTypes.string,
};
RunModel.defaultProps = {
    model: {},
    moduleFileName: null,
};

export default RunModel;
