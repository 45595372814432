import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import {
    ModalCard,
    Input,
    ContextMenu,
    ContextSubMenu,
    ContextMenuItem,
    Icon,
    IconButton,
    ConfirmDeleteMenu,
} from '../../../shared';

import CollectionModel from '../../_models/collections';

export default function CollectionsItemMenu(props) {
    const { collection } = props;

    const dispatch = useDispatch();

    const [menuOpen, setMenuOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

    const errors = useSelector((state) => state.collections.errors);
    const loading = useSelector((state) => state.collections.loading);

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
        setMenuOpen(false);
    };

    const handleDeleteCollection = () => {
        dispatch(CollectionModel.destroy(collection._id));
        setDeleteTooltipOpen(false);
        setMenuOpen(false);
    };

    const handleEditCollection = (obj) => {
        dispatch(CollectionModel.update(collection._id, obj));
        setEditModalOpen(false);
        setMenuOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton mini icon="menu-vertical" onClick={() => setMenuOpen(true)} active={menuOpen}>
                <ContextMenu
                    open={menuOpen}
                    onClickOut={() => {
                        setMenuOpen(false);
                        setDeleteTooltipOpen(false);
                    }}
                    listHeading={collection.name}
                    position="right"
                >
                    <ContextSubMenu>
                        <ContextMenuItem
                            key="edit"
                            id="edit"
                            name="Edit Info"
                            type={<Icon size="16px" icon="edit" />}
                            onClick={toggleEditModal}
                        />
                        <ContextMenuItem
                            key="delete"
                            id="delete"
                            name="Delete Folder"
                            type={<Icon size="16px" icon="trash" />}
                            onClick={() => setDeleteTooltipOpen(!deleteTooltipOpen)}
                        >
                            <ConfirmDeleteMenu
                                open={deleteTooltipOpen}
                                onClose={() => setDeleteTooltipOpen(false)}
                                onDelete={() => handleDeleteCollection()}
                            />
                        </ContextMenuItem>
                    </ContextSubMenu>
                </ContextMenu>
            </IconButton>
            <ModalCard
                title="Edit Folder"
                open={editModalOpen}
                onClose={toggleEditModal}
                formOptions={{ onSubmit: handleEditCollection }}
                primaryButtonOptions={{ content: 'Edit Name', id: 'submit', loading }}
            >
                <div className="CollectionsList__createModal">
                    <Input
                        type="text"
                        id="name"
                        placeholder="Folder Name"
                        value={collection.name}
                        label="Folder Name"
                        errors={errors.name}
                        submitOnBlur
                        gray
                    />
                </div>
            </ModalCard>
        </React.Fragment>
    );
}

CollectionsItemMenu.propTypes = {
    collection: PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
    }).isRequired,
};
CollectionsItemMenu.defaultProps = {};
