import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsibleSection, IconButton, Input, ModalCard, WorkflowStepper } from '../../../shared';
import {
    repeatOptions,
    weeklyRunScheduleOptions,
    monthlyRunScheduleOptions,
    convertTime,
    formatDate,
} from './constants';
import ModelInput from '../ModelInput';
import { ModelModel, TriggerModel } from '../../_models';

require('./styles.scss');

export default function CreateScheduledModal(props) {
    const { open, onClose, model } = props;
    const dispatch = useDispatch();
    const inputs = model && model.inputs;
    const primaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'primary') : [];
    const secondaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'secondary') : [];
    const userId = useSelector((state) => state?.app?.user?._id);
    const modelId = model?._id;
    const projectId = model?.projectId;

    const [tabIndex, setTabIndex] = useState(0);
    const [runName, setRunName] = useState('');
    const [hasDescription, setHasDescription] = useState(false);
    const [inputDescription, setInputDescription] = useState('');
    const [runStartTime, setRunStartTime] = useState('00:00 AM');
    const [runStartDate, setRunStartDate] = useState(`${moment().format('YYYY-MM-DDTHH:mm:ss.SSS')}`);
    const [runEndTime, setRunEndTime] = useState('11:59 PM');
    const [runEndDate, setRunEndDate] = useState(`${moment().add(1, 'y').format('YYYY-MM-DDTHH:mm:ss.SSS')}`);
    const [repeatFrequency, setRepeatFrequency] = useState(repeatOptions[0].value);
    const [minuteRunSchedule, setMinuteRunSchedule] = useState(1);
    const [hourlyRunSchedule, setHourlyRunSchedule] = useState(1);
    const [weeklyRunSchedule, setWeeklyRunSchedule] = useState([]);
    const [monthlyRunSchedule, setMonthlyRunSchedule] = useState([]);

    const handleModelInputChange = (newInputs) => dispatch(ModelModel.updateLocal(model._id, { inputs: newInputs }));

    const handleClose = () => {
        setTabIndex(0);
        onClose();
    };

    const onSubmit = () => {
        const triggerConfig = {
            inputs,
            name: runName,
            description: inputDescription,
            modelId,
            status: 'active',
            type: 'scheduled',
            pattern: {
                timezone: 'Canada/Mountain',
                start: {
                    effective_startdate: formatDate(runStartDate),
                    scheduled_starttime: convertTime(runStartTime),
                    scheduled_endtime: runEndTime ? convertTime(runEndTime) : null,
                    until_datetime: runEndDate,
                },
                repeat: {
                    unit: repeatFrequency,
                    value: repeatFrequency === 'minutes' ? minuteRunSchedule : hourlyRunSchedule,
                },
                filter: {
                    ...(monthlyRunSchedule.length > 0 && { day_of_the_month: monthlyRunSchedule }),
                    ...(weeklyRunSchedule.length > 0 && { day_of_the_week: weeklyRunSchedule }),
                },
            },
            userId,
            projectId,
        };
        dispatch(TriggerModel.create(triggerConfig));
        setTabIndex(0);
        onClose();
        dispatch(TriggerModel.index());
    };

    const workflowTabs = [
        {
            label: 'Schedule Run',
            component: (
                <div className="CreateScheduledModalWorkflow1">
                    <div className="CreateScheduledModalWorkflowInputs">
                        <Input
                            type="select"
                            id="RepeatID"
                            label="Repeats:"
                            required
                            value={repeatFrequency}
                            options={repeatOptions}
                            onChange={(_, value) => setRepeatFrequency(value)}
                            gray
                        />
                        {repeatFrequency === 'minutes' && (
                            <Input
                                type="number"
                                id="minuteRunSchedule"
                                label="Number of Minutes"
                                required
                                value={minuteRunSchedule}
                                onChange={(_, value) => {
                                    setMinuteRunSchedule(value)
                                    }
                                }
                                gray
                            />
                        )}
                        {repeatFrequency === 'hours' && (
                            <Input
                                type="number"
                                id="hourlyRunSchedule"
                                label="Number of Hours"
                                required
                                value={hourlyRunSchedule}
                                onChange={(_, value) => setHourlyRunSchedule(value)}
                                gray
                            />
                        )}
                        {repeatFrequency === 'weeks' && (
                            <Input
                                type="multiSelect"
                                id="weeklyRunSchedule"
                                label="Run Schedule"
                                required
                                options={weeklyRunScheduleOptions}
                                value={weeklyRunSchedule}
                                onChange={(_, value) => {
                                    setWeeklyRunSchedule(value);
                                }}
                                gray
                            />
                        )}
                        {repeatFrequency === 'months' && (
                            <Input
                                type="select"
                                id="monthlyRunSchedule"
                                label="Run Schedule"
                                required
                                options={monthlyRunScheduleOptions}
                                value={monthlyRunSchedule}
                                onChange={(_, value) => setMonthlyRunSchedule([value])}
                                gray
                            />
                        )}
                        <Input
                            type="time"
                            id="RunStartTimeID"
                            label={repeatFrequency === 'hours' || repeatFrequency === 'minutes' ? "Between (start time):" : "Start Time"}
                            required
                            value={runStartTime}
                            onChange={(_, value) => setRunStartTime(value)}
                            gray
                        />

                        {(repeatFrequency === 'minutes' || repeatFrequency === 'hours') && (
                            <Input
                                type="time"
                                id="RunEndTimeID"
                                label="and (end time):"
                                required
                                value={runEndTime}
                                onChange={(_, value) => setRunEndTime(value)}
                                gray
                            />
                        )}
                        
                        <Input
                            type="date"
                            id="RunStartDateID"
                            label="Run Start Date:"
                            required
                            value={runStartDate}
                            onChange={(_, value) => setRunStartDate(value)}
                            gray
                        />
                        {repeatFrequency !== 'never' && (
                            <Input
                                type="date"
                                id="RunStartTimeID"
                                label="End Date:"
                                required
                                value={runEndDate}
                                onChange={(_, value) => setRunEndDate(value)}
                                info="Model runs can be scheduled up to a year in advance."
                                gray
                            />
                        )}
                    </div>
                    <hr />
                    <div className="CreateScheduledModalWorkflowInputs">
                        <Input
                            type="text"
                            id="runName"
                            label="Run Name"
                            placeholder="Optional"
                            value={runName}
                            onChange={(_, value) => setRunName(value)}
                            gray
                        />
                        {hasDescription ? (
                            <Input
                                id="description"
                                className="CreateScheduledModal__description"
                                type="text"
                                gray
                                label="Description"
                                value={inputDescription}
                                onChange={(_, value) => setInputDescription(value)}
                                submitOnBlur
                            />
                        ) : (
                            <IconButton
                                className="CreateScheduledModal__addDescription"
                                icon="plus"
                                onClick={() => setHasDescription(true)}
                                label="Edit Description"
                            />
                        )}
                    </div>
                </div>
            ),
            complete: true,
            forwardButton: { id: 'submit', content: 'Continue', onClick: () => setTabIndex(1) },
        },
        {
            label: 'Configure Inputs',
            component: (
                <div className="CreateScheduledModalWorkflow2">
                    <CollapsibleSection
                        className="CreateScheduledModal-collapsibleSection"
                        title="Model Run Inputs"
                        line
                        expanded
                    >
                        {primaryInputs.length > 0 ? (
                            <div className="CreateScheduledModalWorkflowInputs">
                                {primaryInputs.map((input) => (
                                    <ModelInput
                                        key={input.varName}
                                        inputs={inputs}
                                        input={input}
                                        onInputsChange={handleModelInputChange}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="CreateScheduledModalWorkflowInputs--emptyLabel">
                                There are no input settings for this model.
                            </div>
                        )}
                    </CollapsibleSection>
                    <CollapsibleSection
                        className="CreateScheduledModal-collapsibleSection"
                        title="Advanced Settings"
                        line
                    >
                        {secondaryInputs.length > 0 ? (
                            <div className="CreateScheduledModalWorkflowInputs">
                                {secondaryInputs.map((input) => (
                                    <ModelInput
                                        key={input.varName}
                                        inputs={inputs}
                                        input={input}
                                        onInputsChange={handleModelInputChange}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="CreateScheduledModalWorkflowInputs--emptyLabel">
                                There are no advanced input settings for this model.
                            </div>
                        )}
                    </CollapsibleSection>
                </div>
            ),
            complete: tabIndex === 1,
            backButton: { id: 'back', content: 'Back', onClick: () => setTabIndex(0) },
            forwardButton: { id: 'submit', content: 'Done', onClick: onSubmit },
        },
    ];

    return (
        <ModalCard
            className="CreateScheduledModal"
            open={open}
            onClose={handleClose}
            title={`Schedule Model Runs - ${model.name}`}
        >
            <div className="CreateScheduledModalWorkflow">{workflowTabs[tabIndex].component}</div>
            <WorkflowStepper tabs={workflowTabs} tabIndex={tabIndex} />
        </ModalCard>
    );
}

CreateScheduledModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    model: PropTypes.shape({
        _id: PropTypes.string,
        projectId: PropTypes.string,
        name: PropTypes.string,
        inputs: PropTypes.array,
    }),
};
CreateScheduledModal.defaultProps = {
    open: false,
    onClose: () => {},
    model: {},
};
