import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Input } from '../../../shared';

// The common header for date/number/category filters.
// The onChange prop attaches to the Input id="option" (corresponding to the filter object option key)
export default function FilterHeader({ feature, options, value, noClickBackIcon, onClickBack, onChange }) {
    return (
        <header className="FilterHeader">
            {!noClickBackIcon && <IconButton dark icon="arrow-circle-left" onClick={onClickBack} />}
            <span className="FilterHeader__name">{feature.name}</span>
            <Input dark id="option" type="select" options={options} value={value.option} onChange={onChange} />
        </header>
    );
}

FilterHeader.propTypes = {
    onClickBack: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    feature: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({
        option: PropTypes.string,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ).isRequired,
    noClickBackIcon: PropTypes.bool,
};
FilterHeader.defaultProps = {
    noClickBackIcon: false,
    onClickBack: () => {},
};
