import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon } from '../../../shared';

require('./styles.scss');

export function getDatasetTypeInfo(dataset, form, model) {
    switch (dataset?.type) {
        case 'file':
            return { icon: 'database', label: 'CSV Dataset' };
        case 'joined':
            return { icon: 'join', label: 'Joined Dataset' };
        case 'modelResult':
            return {
                icon: 'brain',
                label: 'Model Output',
                to: `/projects/${dataset?.projectId}/models/${dataset.source.model.id}`,
                text: model?.name,
            };
        case 'formData':
            return {
                icon: 'form',
                label: 'Form Responses',
                to: `/projects/${dataset?.projectId}/forms/${dataset.source.form.id}`,
                text: form?.name,
            };
        default:
            return { icon: 'question-circle', label: 'Unknown type' };
    }
}

export default function DatasetType({ canEditForms, canEditPackages, dataset, form, model }) {
    if (!dataset?.type) return null;
    const { icon, label, text, to } = getDatasetTypeInfo(dataset, canEditForms && form, canEditPackages && model);
    return (
        <div className="DatasetType">
            <Icon size="20px" icon={icon} />
            {label}
            {to && text && (
                <React.Fragment>
                    {' '}
                    | from <Link to={to}>{text}</Link>
                </React.Fragment>
            )}
        </div>
    );
}

DatasetType.propTypes = {
    canEditForms: PropTypes.bool,
    canEditPackages: PropTypes.bool,
    dataset: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({}),
    model: PropTypes.shape({}),
};

DatasetType.defaultProps = {
    canEditForms: false,
    canEditPackages: false,
    form: null,
    model: null,
};
