import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, ModalCard, Input, Loader } from '../../shared';
import { fuzzySearch } from '../../../helpers/string';
import FormModel from '../redux';
import FormCard from './Card';
import EditFormModal from './Card/EditFormModal';

require('./styles.scss');

export default function FormList() {
    const [createFormModal, setCreateFormModal] = useState(false);
    const [filter, setFilter] = useState('');
    const [renderEdit, setRenderEdit] = useState(false);
    const [formToEdit, setFormToEdit] = useState(null);
    const canEditForms = useSelector((state) => state.app.permission?.roles?.form === 'edit');
    const forms = useSelector((state) => state.forms);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        // should dispatch once when component mounts
        dispatch(FormModel.index());
    }, [dispatch]);
    const createForm = (form) => {
        dispatch(FormModel.create(form)).then((response) => {
            const newId = response && response.form && response.form._id;
            if (newId) navigate(newId);
        });
    };

    const filteredForms = fuzzySearch(filter, Object.values(forms.byId), ['name', 'description']);

    let content;
    if (filteredForms.length)
        content = filteredForms.map((form) => (
            <FormCard
                key={form._id}
                onEdit={() => {
                    setRenderEdit(true);
                    setFormToEdit(form._id);
                }}
                form={form}
                canEditForms={canEditForms}
                onClick={() => navigate(`${form._id}`)}
                onDelete={() => dispatch(FormModel.destroy(form._id))}
                onDuplicate={() => dispatch(FormModel.duplicate(form._id))}
            />
        ));

    const emptyFormsPage = (
        <div className="FormsList__empty">
            <Icon icon="large_color/emptyForms" size="75px" />
            <div className="FormsList__empty--Title">No forms.</div>
            <div className="FormsList__empty--Text">
                Forms let you design custom surveys and capture the response data in near-real-time.
            </div>
            {canEditForms && (
                <Button gray onClick={() => setCreateFormModal(true)}>
                    Create Form
                </Button>
            )}
        </div>
    );

    const noFormsFound = (
        <div className="FormsList__empty">
            <Icon icon="large_color/emptyForms" size="75px" />
            <div className="FormsList__empty--Title">No forms found.</div>
        </div>
    );

    if (!forms.hydrated && forms.allIds.length === 0) {
        return (
            <div className="Forms--loading">
                <Loader type="content" loader="content" />
            </div>
        );
    }

    return (
        <div className="FormsList">
            {Object.values(forms.byId).length > 0 ? (
                <div className="FormsList__full">
                    <header className="ThreeColumnHeader">
                        <div className="FormsList__title">Forms</div>
                        <Input
                            type="search"
                            id="search"
                            className="FormsList__searchBar"
                            placeholder="Search forms"
                            value={filter}
                            onChange={(id, value) => setFilter(value)}
                        />
                        {canEditForms && (
                            <Button blue onClick={() => setCreateFormModal(true)}>
                                Create Form
                            </Button>
                        )}
                    </header>
                    {filteredForms.length > 0 ? (
                        <div className="FormsListContent">
                            <div className="FormsList__cards">{content}</div>
                            <EditFormModal
                                open={renderEdit}
                                form={forms.byId[formToEdit]}
                                onClose={() => setRenderEdit(false)}
                                onSubmit={(form) => {
                                    dispatch(FormModel.update(formToEdit, form));
                                    setRenderEdit(false);
                                }}
                            />
                        </div>
                    ) : (
                        noFormsFound
                    )}
                </div>
            ) : (
                emptyFormsPage
            )}
            <ModalCard
                open={createFormModal}
                title="Create Form"
                onClose={() => setCreateFormModal(false)}
                primaryButtonOptions={{ content: 'Create', id: 'submit' }}
                formOptions={{ onSubmit: createForm }}
                autofocus
            >
                <div className="CreateFormModal">
                    <Input id="name" type="text" gray label="Name" required />
                    <Input id="description" type="textarea" gray label="Description" />
                </div>
            </ModalCard>
        </div>
    );
}

FormList.propTypes = {};

FormList.defaultProps = {};
