import { Actions } from './reducers';
import CollectionApiInstance from './api';
import { operations } from '../../../../models/base';
import { Actions as DatasetActions } from '../dataset/reducers';

const destroy = (collectionId) => (dispatch) => {
    dispatch(Actions.destroyRequest(collectionId));
    CollectionApiInstance.destroy(collectionId).then(
        (response) => {
            response.datasets.forEach((dataset) =>
                dispatch(DatasetActions.updateDeletedCollection(dataset._id, dataset)),
            );
            dispatch(Actions.destroySuccess(collectionId, response));
        },
        (error) => dispatch(Actions.destroyFailure(collectionId, error)),
    );
};

export default operations(Actions, CollectionApiInstance, { destroy });
