import React from 'react';

import Login from './Login';
import LoginRecover from './Recover';
import LoginReset from './Reset';

export default [
    {
        path: '',
        exact: true,
        element: <Login />,
        public: true,
    },
    {
        path: 'microsoft',
        exact: true,
        element: <Login />,
        public: true,
    },
    {
        path: 'recover',
        exact: true,
        element: <LoginRecover />,
        public: true,
    },
    {
        path: 'reset/:resetCode',
        exact: true,
        element: <LoginReset />,
        public: true,
    },
];
