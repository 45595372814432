import ApiBase from '../../../../models/base/api';
import { createQueryString } from '../../../../models/base/api/helpers';

export class ModelApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('models', addProjectIdToSearchParams);
    }
    run = (id, inputs, options = {}) =>
        this.request('POST', `${this.endpoint}/${id}/execute${createQueryString(options)}`, inputs);
    editOutput = (id, datasetId, inputs) =>
        this.request('POST', `${this.endpoint}/${id}/edit_output/${datasetId}`, inputs);
    modelRuns = (id, options) => this.request('GET', `${this.endpoint}/${id}/model-runs${createQueryString(options)}`);
    duplicate = (id) => this.request('POST', `${this.endpoint}/${id}/duplicate`);
    updateAlgorithm = (id, algorithmId) => this.request('PUT', `${this.endpoint}/${id}/algorithm/${algorithmId}`);
    updateOutputs = (id, updates) => this.request('POST', `${this.endpoint}/${id}/outputs`, updates);
    confirm = (id) => this.request('POST', `${this.endpoint}/${id}/confirm`);
}

export default new ModelApi();
