import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ClassNames from 'classnames';

import { IconButton } from '../../../shared';
import KpiUnit from '..';
import UnitLoader from '../../components/Loader';
import Settings from '../../components/Settings';
import { getKpiSettings, updateSerum } from './helpers';
import FeatureBasket from '../../components/FeatureBasket';
import MappingBucket from '../../components/MappingBucket';
import ChipFilter from '../../../Data/Filter/ChipFilter';
import SerumOperationButton from '../../components/SerumButton/operation';

require('./styles.scss');

function KpiMapping(props) {
    const features = useSelector((state) => state.features);

    const { unit, onChange } = props;
    const { vis } = unit;
    const series = vis.kpi;
    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleSeriesChange = (_, value) => {
        onChange({ vis: { ...vis, kpi: value } }, { shouldGetData: true });
    };

    const handleSerumChange = (serumId, key, value) => {
        const serumIndex = series.findIndex((serum) => serum.id === serumId);
        const result = updateSerum(vis.kpi[serumIndex], key, value, features);

        if (result) {
            const [newSerum, options] = result;
            const kpi = [...vis.kpi];
            kpi.splice(serumIndex, 1, newSerum);
            onChange({ vis: { ...vis, kpi } }, options);
        }
    };

    return (
        <div className={ClassNames('KpiMapping', { 'KpiMapping--settingsOpen': settingsOpen })}>
            <div className="KpiMapping__main">
                <div className="KpiMapping__preview">
                    <header>
                        <IconButton
                            icon="settings"
                            onClick={() => setSettingsOpen(!settingsOpen)}
                            label="Settings"
                            disabled={!(unit.data && unit.data.data && unit.data.data.length)}
                            active={settingsOpen}
                        />
                    </header>
                    <main>
                        {!unit.hydrated && <UnitLoader />}
                        <KpiUnit unit={unit} features={features} mapping />
                    </main>
                </div>
                <MappingBucket
                    // values
                    id="series"
                    onChange={handleSeriesChange}
                    series={series || []}
                    // dnd behaviour
                    horizontal
                    allowMultiple
                    // display
                    label="Data"
                    placeholder="Drag data here."
                    render={(dndProps) => {
                        const serum = dndProps.serum;
                        const handleThisSerumChange = handleSerumChange.bind(this, serum.id);
                        return (
                            <FeatureBasket {...dndProps} feature={features.byId[serum.feature._id]}>
                                <div className="CustomUnitBasket__config">
                                    <SerumOperationButton
                                        id="operation"
                                        value={serum.operation}
                                        onChange={handleThisSerumChange}
                                        feature={features.byId[serum.feature._id]}
                                    />
                                    <ChipFilter
                                        id="filters"
                                        onChange={handleThisSerumChange}
                                        value={serum.filters || []}
                                        datasetIds={[serum.feature && features.byId[serum.feature._id]?.dataset._id]}
                                    />
                                </div>
                            </FeatureBasket>
                        );
                    }}
                />
            </div>
            {settingsOpen && (
                <div className="KpiMapping__settings">
                    <Settings
                        unit={unit}
                        onChange={(inputId, value) => {
                            const [key, serumId] = inputId.split('_');
                            handleSerumChange(serumId, key, value);
                        }}
                        toggleExpanded={() => setSettingsOpen(false)}
                        settings={getKpiSettings(vis, features)}
                    />
                </div>
            )}
        </div>
    );
}

KpiMapping.propTypes = {
    unit: PropTypes.shape({
        data: PropTypes.shape({
            data: PropTypes.array,
        }),
        vis: PropTypes.shape({
            kpi: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    feature: PropTypes.shape({
                        _id: PropTypes.string,
                    }),
                    label: PropTypes.string,
                    unitOfMeasure: PropTypes.string,
                    filters: PropTypes.array,
                    operation: PropTypes.string,
                }),
            ),
        }),
        hydrated: PropTypes.bool,
        _id: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func,
};
KpiMapping.defaultProps = {
    onChange: () => {},
};

export default KpiMapping;
