import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Filter from '../Filter';
import AddFilterButton from '../AddFilterButton';
import { Icon, IconButton, VerticalLine } from '../../../shared';

import './styles.scss';

// an array of filters.  Includes add filter component and a clear all button.
// Takes a value prop (an array of filter objects)
// there are two options for action handlers:
//   - onChange will be called with an updated array of filters any time a change happens
//   - onFilterChange, onFilterDelete, onFilterCreate, onClearAll.
//     - If any of these are included, they will be called instead of onChange when the corresponding event happens.
export default function FilterBarSkin(props) {
    const { id, value, onChange, onFilterChange, onFilterDelete, onFilterCreate, onClearAll, loading, ...rest } = props;

    const [newFilterValue, setNewFilterValue] = useState({});

    const handleChange = (filterId, newValue) => {
        const nextValue = [...value];
        let key = nextValue.findIndex((val) => val._id === filterId);
        if (key === -1) key = filterId; // filterId defaults to index if no id provided
        if (newValue) {
            nextValue.splice(key, 1, newValue);
        } else {
            nextValue.splice(key, 1);
        }
        if (onFilterChange) onFilterChange(filterId, newValue);
        else onChange(id, nextValue);
    };

    const clearAll = useCallback(() => {
        if (onClearAll) onClearAll();
        else onChange(id, []);
    }, [id, onChange, onClearAll]);

    const handleAddFilter = (_, newValue) => {
        setNewFilterValue({});
        if (onFilterCreate) onFilterCreate(newValue);
        else onChange(id, [...value, newValue]);
    };

    if (loading) {
        // TODO: a better loading state?
        return (
            <div className="FilterBar FilterBar--loading">
                <Icon icon="filter" size="20px" />
            </div>
        );
    }

    return (
        <div className="FilterBar">
            <Icon icon="filter" size="20px" />
            <div className="FilterBar__Array">
                {value.map((filterValue, i) => (
                    <React.Fragment key={filterValue._id || i}>
                        <VerticalLine />
                        <Filter
                            id={filterValue._id || i.toString()}
                            value={filterValue}
                            onDelete={onFilterDelete}
                            onChange={handleChange}
                            right
                            isFilterBar
                            {...rest}
                        />
                        {i === value.length - 1 && <VerticalLine />}
                    </React.Fragment>
                ))}
            </div>
            {value.length === 0 && <VerticalLine />}
            <AddFilterButton
                value={newFilterValue}
                onChange={handleAddFilter}
                showText={value.length === 0}
                right
                isFilterBar
                {...rest}
            />
            {value.length > 0 && (
                <div className="FilterBar__clear">
                    <VerticalLine />
                    <IconButton icon="times" tooltip="Clear All" onClick={clearAll} {...rest} />
                </div>
            )}
        </div>
    );
}

FilterBarSkin.propTypes = {
    id: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            option: PropTypes.string,
            value: PropTypes.shape({}),
            feature: PropTypes.shape({ id: PropTypes.string }),
        }),
    ),
    datasets: PropTypes.shape({}).isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func,
    onClearAll: PropTypes.func,
    onFilterCreate: PropTypes.func,
    onFilterChange: PropTypes.func,
    onFilterDelete: PropTypes.func,
    onFeatureSelect: PropTypes.func,
    loading: PropTypes.bool,
    vertical: PropTypes.bool,
};
FilterBarSkin.defaultProps = {
    value: null,
    datasetIds: [],
    onChange: () => {},
    onClearAll: null,
    onFilterCreate: null,
    onFilterChange: null,
    onFilterDelete: null,
    onFeatureSelect: null,
    loading: false,
    vertical: false,
    id: '',
};
