import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { numberFilterOptions } from '../constants';
import { Input } from '../../../shared';
import FilterHeader from './header';

// The filter component for numeric features
// Takes a filter object (value), and calls props.onChange with key:value updates for the object.  Fully controlled.
export default function NumberFilter(props) {
    const { value: filterValue, onChange, onSubmit } = props;
    const { value, option } = filterValue;
    const handleValueChange = (id, val) => {
        if (option === 'between') {
            onChange('value', { from: value.from, to: value.to, [id]: val }); // eslint-disable-line react/prop-types
        } else {
            onChange('value', { [id]: val });
        }
    };

    let optionInputs = (
        <Input
            id="value"
            type="number"
            dark
            placeholder="Value"
            autoFocus
            autosize={false}
            value={value.value}
            onChange={handleValueChange}
            onSubmit={onSubmit}
        />
    );
    if (option === 'empty' || option === 'not-empty') {
        optionInputs = null;
    } else if (option === 'between') {
        optionInputs = (
            <React.Fragment>
                <Input
                    id="from"
                    type="number"
                    dark
                    placeholder="Value"
                    max={value.to} // eslint-disable-line react/prop-types
                    autoFocus
                    autosize={false}
                    value={value.from} // eslint-disable-line react/prop-types
                    onChange={handleValueChange}
                    onSubmit={onSubmit}
                />
                <span className="NumberFilter__seperator">to</span>
                <Input
                    id="to"
                    type="number"
                    dark
                    placeholder="Value"
                    min={value.from} // eslint-disable-line react/prop-types
                    autoFocus
                    autosize={false}
                    value={value.to} // eslint-disable-line react/prop-types
                    onChange={handleValueChange}
                    onSubmit={onSubmit}
                />
            </React.Fragment>
        );
    }

    return (
        <div className="NumberFilter">
            <FilterHeader {...props} options={numberFilterOptions} />
            <main className={classNames({ 'NumberFilter__main--range': option === 'between' })}>{optionInputs}</main>
        </div>
    );
}

NumberFilter.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.shape({
            input: PropTypes.string,
            to: PropTypes.number,
            from: PropTypes.number,
            value: PropTypes.number,
        }),
        option: PropTypes.string,
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};
NumberFilter.defaultProps = {
    value: {
        value: {
            input: '',
        },
    },
    onChange: () => {},
    onSubmit: () => {},
};
