import { Actions } from './types';
import ModelRunApiInstance from './api';
import { operations } from '../../../../models/base';

const cancel = (id) => (dispatch) => {
    dispatch(Actions.cancelRequest(id));
    return ModelRunApiInstance.cancel(id).then(
        (response) => dispatch(Actions.cancelSuccess(id, response)),
        (errors) => dispatch(Actions.cancelFailure(id, errors)),
    );
};

export default operations(Actions, ModelRunApiInstance, {
    cancel,
});
