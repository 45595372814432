import React from 'react';
import { useRoutes } from 'react-router-dom';
import ErrorBoundary from '../shared/ErrorBoundary';
import { Card } from '../shared';
import routes from './routes';

require('./styles.scss');

const Packages = () => {
    const packageRoutes = useRoutes(routes);
    return (
        <ErrorBoundary>
            <div className="Packages__main">
                <Card style={{ padding: 0 }}>{packageRoutes}</Card>
            </div>
        </ErrorBoundary>
    );
};

Packages.propTypes = {};
Packages.defaultProps = {};

export default Packages;
