import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Icon } from '../../../../shared';

require('./styles.scss');

export default function LayoutButton({ selected, icon, size, title, onClick }) {
    return (
        <div
            className={classNames('LayoutButton', {
                'LayoutButton--selected': selected,
            })}
            onClick={(value, idk) => onClick(value, idk)}
            role="button"
            tabIndex={0}
            id={title}
        >
            <Card id={title} className={title}>
                <Icon icon={icon} size={size} />
                {title}
            </Card>
        </div>
    );
}

LayoutButton.propTypes = {
    selected: PropTypes.bool,
    icon: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
LayoutButton.defaultProps = {
    selected: false,
    icon: '',
    size: '',
    title: '',
};
