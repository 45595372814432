/* eslint-disable */
import React from 'react';
import { MdComputer } from 'react-icons/md';

const Thinking = () => {


  const ai = true;

  return (

    <div className={`thinking-container ${ai ? 'flex-row-reverse' : 'flex-row'}`}>

        <div className={`w-screen chat ${ai ? 'chat-start' : 'chat-end'}`}>
            <div className='chat-bubble'>
                <div className='animate-pulse'> ●  ●  ● </div>
            </div>
        </div>


        <div className='avatar'>
            <div className='w-8'>
                {ai ? (
                    <MdComputer className='w-6' />
                ) : (
                    <MdPerson className='w-6' />
                )}
            </div>
        </div>

    </div>

  );
};

export default Thinking;
