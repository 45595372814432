import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../shared';

require('./styles.scss');

export default class UnitTypeSelection extends Component {
    _renderVisTypeSelector = (type) => {
        const { height, editable, onSelect } = this.props;
        return (
            <div className="UnitTypeSelection__type">
                <div
                    className="UnitTypeSelection__type__circle"
                    role="button"
                    tabIndex={-1}
                    onClick={() => editable && onSelect(type.toLowerCase())}
                >
                    <Icon icon={`large_color/${type.toLowerCase()}_placeholder`} size="30px" />
                </div>
                {height > 1 ? <div className="UnitTypeSelection__type__title">{type}</div> : <br />}
            </div>
        );
    };
    render() {
        const { height, editable } = this.props;
        return (
            <div className="UnitTypeSelection">
                {!(height <= 2) ? (
                    <div>
                        <div className="UnitTypeSelection__title">
                            {editable ? 'Map data or models to a visualization' : 'Owner has not mapped data'}
                        </div>
                        <div className="UnitTypeSelection__subtitle">
                            {editable
                                ? 'Press one of the visualization types below to begin'
                                : 'You cannot add a visualization to a card with read only permission'}
                        </div>
                    </div>
                ) : null}
                <div className="UnitTypeSelection__type__row">
                    {this._renderVisTypeSelector('KPI')}
                    {this._renderVisTypeSelector('Chart')}
                    {this._renderVisTypeSelector('Table')}
                </div>
            </div>
        );
    }
}
UnitTypeSelection.propTypes = {
    onSelect: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    height: PropTypes.number,
};

UnitTypeSelection.defaultProps = {
    height: 3,
    editable: true,
};
