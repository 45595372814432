import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import FeatureModel from '../../Data/_models/feature';
import { DataTable } from '../../shared';
import Placeholder from '../components/Placeholder';
import { formatData } from './helpers';
import FeatureTableHeader from '../../Data/_components/FeatureTableHeader';
import { updateFeatureFilterArray, determineRequestPage } from '../../Data/_components/FeatureTableHeader/helpers';
import './styles.scss';

const TableUnit = ({ unit, features, mapping, height, jobId, getTempData, getFeatureCategories, setGetData }) => {
    const [lastRequestedPage, setLastRequestedPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderBy, setOrderBy] = useState([]);
    const [filters, setFilters] = useState([]);
    const datasets = useSelector((state) => state.datasets);

    const updateStateAndFetchData = useCallback(
        (updatedValues) => {
            setLastRequestedPage((prev) => updatedValues?.lastRequestedPage || prev);
            setCurrentPage((prev) => updatedValues?.currentPage || prev);
            setOrderBy((prev) => updatedValues?.orderBy || prev);
            setFilters((prev) => updatedValues?.filters || prev);

            const {
                filters: updatedFilters,
                orderBy: updatedOrderBy,
                currentPage: updatedCurrentPage,
            } = { filters, orderBy, currentPage, ...updatedValues };
            const temporaryQueryOptions = {
                filters: Object.values(updatedFilters).filter((filter) => filter.valid),
                orderBy: updatedOrderBy,
            };
            const temporaryOptions = { page: updatedCurrentPage, limit: 100, temporaryJobId: jobId || 'latest' };
            getTempData(temporaryQueryOptions, temporaryOptions);
        },
        [filters, orderBy, jobId, currentPage, getTempData],
    );

    useEffect(() => {
        setGetData(updateStateAndFetchData);
    }, [setGetData, updateStateAndFetchData]);

    useEffect(() => {
        if (unit.data && unit.data.pages && unit.data.pages.current) {
            setCurrentPage(unit.data.pages.current);
        }
    }, [unit]);

    const onFilterChange = useCallback(
        (featureId, filter) => {
            const newFilters = updateFeatureFilterArray(filters, featureId, filter);
            updateStateAndFetchData({ filters: newFilters, currentPage: 1 });
        },
        [filters, updateStateAndFetchData],
    );

    const onOrderChange = useCallback(
        (serumId, type) => {
            const serum = unit.vis.table.find((s) => s.id === serumId);
            const filteredOrderBy = orderBy.filter((order) => !(order.id === serumId));
            if (type) {
                filteredOrderBy.unshift({ id: serum.id, value: serum.feature._id, operation: '', type, nulls: 'last' });
            }
            updateStateAndFetchData({ orderBy: filteredOrderBy, currentPage: 1 });
        },
        [unit.vis.table, orderBy, updateStateAndFetchData],
    );

    const handlePaginate = useCallback(
        (page) => {
            const requestPage = determineRequestPage(page, unit.data);
            if (currentPage !== requestPage || lastRequestedPage !== requestPage) {
                updateStateAndFetchData({ currentPage: requestPage, lastRequestedPage: requestPage });
            }
        },
        [currentPage, lastRequestedPage, unit.data, updateStateAndFetchData],
    );

    // Render logic remains the same
    const { hydrated, vis } = unit;
    if (!unit.data || (unit.data.error && !hydrated)) return <div className="TableUnit" />;
    if (unit.data.error) {
        return <Placeholder unit={unit} error={unit.data.error} mapping={mapping} height={height} />;
    }
    if (!vis.table || vis.table.length === 0)
        return <Placeholder unit={unit} error="empty" mapping={mapping} height={height} />;

    const { data } = unit.data;
    const titles = {};
    vis.table.forEach((serum) => {
        const feature = features.byId[serum.feature._id];
        const serumOrder = orderBy.find((order) => order.id === serum.id);
        const orderType = serumOrder && serumOrder.type;
        const filter = filters.find((f) => f.feature.id === feature.id);
        titles[serum.id] = (
            <FeatureTableHeader
                feature={feature}
                filter={filter}
                orderType={orderType}
                getFeatureCategories={getFeatureCategories}
                onFilterChange={onFilterChange}
                onOrderChange={(updatedOrder) => onOrderChange(serum.id, updatedOrder)}
            />
        );
    });

    const tableData =
        Object.values(filters).filter((filter) => filter && filter.valid).length !== 0 && data.length === 0
            ? [
                  Object.keys(titles).reduce((acc, current) => {
                      acc[current] = null;
                      return acc;
                  }, {}),
              ]
            : formatData(data, vis.table, features);

    return (
        <div className="TableUnit">
            <DataTable
                value={tableData}
                loading={!hydrated || features.loading}
                loadingNumber={10}
                pagination={unit.data.pages}
                titles={titles}
                onPaginate={handlePaginate}
            />
        </div>
    );
};

TableUnit.propTypes = {
    unit: PropTypes.shape({
        vis: PropTypes.shape({ table: PropTypes.arrayOf(PropTypes.shape({})) }),
        data: PropTypes.shape({
            data: PropTypes.array,
            pages: PropTypes.shape({
                current: PropTypes.number,
                total: PropTypes.number,
            }),
            error: PropTypes.string,
        }),
        hydrated: PropTypes.bool,
    }),
    features: PropTypes.shape({
        loading: PropTypes.bool,
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
    }).isRequired,
    mapping: PropTypes.bool,
    height: PropTypes.number,
    getTempData: PropTypes.func,
    getFeatureCategories: PropTypes.func,
    setGetData: PropTypes.func,
};
TableUnit.defaultProps = {
    unit: {
        vis: { table: [] },
        data: {
            data: [],
            pages: {},
        },
        hydrated: true,
    },
    mapping: false,
    height: null,
    getTempData: () => {},
    getFeatureCategories: () => {},
    setGetData: () => {},
};

const mapDispatchToProps = (dispatch) => {
    const getFeatureCategories = (featureId) => dispatch(FeatureModel.categories(featureId));
    return {
        getFeatureCategories,
    };
};

export default connect(undefined, mapDispatchToProps)(TableUnit);
