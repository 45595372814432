import { Actions } from './reducers';
import ProjectApiInstance from './api';
import { operations } from '../../../../models/base';

const create = (form, options) => (dispatch) => {
    dispatch(Actions.createRequest(form));
    return ProjectApiInstance.create(form, options).then(
        (model) => dispatch(Actions.createSuccess(model)),
        (errors) => dispatch(Actions.createFailure(errors)),
    );
};

const clearResources = () => (dispatch) => {
    dispatch(Actions.clearResources());
};

const listMy = () => (dispatch) => {
    dispatch(Actions.listMyRequest());
    return ProjectApiInstance.listMy().then(
        (projects) => dispatch(Actions.listMySuccess(projects)),
        (errors) => dispatch(Actions.listMyFailure(errors)),
    );
};

const listByOrganization = (organizationId) => (dispatch) => {
    dispatch(Actions.listOrganizationRequest(organizationId));
    return ProjectApiInstance.listByOrganization(organizationId).then(
        (projects) => dispatch(Actions.listOrganizationSuccess(projects)),
        (errors) => dispatch(Actions.listOrganizationFailure(errors)),
    );
};

const transferAssets = (projectId, form) => (dispatch) => {
    dispatch(Actions.transferAssetsRequest());
    return ProjectApiInstance.transferTo(projectId, form).then(
        (project) => dispatch(Actions.transferAssetsSuccess(project)),
        (errors) => dispatch(Actions.transferAssetsFailure(errors)),
    );
};

export default operations(Actions, ProjectApiInstance, {
    create,
    clearResources,
    listMy,
    listByOrganization,
    transferAssets,
});
