import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DatasetModel, CollectionModel } from '../../../Data/_models';
import Table from './Table';
import DatasetSelection from './DatasetSelection';
import PopOver from '../../../Access/_components/PopOver';

require('./styles.scss');

export default function DatasetMapping(props) {
    const { selectedDatasetId, updateSelectedDataset } = props;

    const dispatch = useDispatch();
    const collections = useSelector((state) => state.collections);
    const features = useSelector((state) => state.features);
    const datasets = useSelector((state) => state.datasets);

    const dataset = useSelector((state) => state.datasets.byId[selectedDatasetId]);
    const getDatasetData = (datasetId, queryParameters = {}, temporaryQueries = {}) =>
        dispatch(DatasetModel.data(datasetId, queryParameters, temporaryQueries));

    useEffect(() => {
        if (!collections.hydrated) dispatch(CollectionModel.index());
    }, [dispatch, collections.hydrated]);
    useEffect(() => {
        if (selectedDatasetId) {
            dispatch(DatasetModel.features(selectedDatasetId)); // this is here if a dataset was just uploaded
            dispatch(DatasetModel.data(selectedDatasetId, { page: null, jobId: 'latest' }));
        }
    }, [dispatch, selectedDatasetId]);

    return (
        <div className="DatasetMapping">
            <div className="DatasetMapping__header">
                {!selectedDatasetId && (
                    <PopOver
                        body="Select a dataset to get started."
                        arrow="bottom"
                        type="mappingPopovers"
                        buttonText="Rock on!"
                        link="https://www.whitewhale.ai/getting-started-tutorials/5-build-your-first-dashboard#editing-a-visualization"
                        style={{ bottom: '187px', left: '10px', width: '200px' }}
                    />
                )}
                <DatasetSelection
                    collections={collections}
                    datasets={datasets}
                    onSelectDataset={(datasetId) => {
                        updateSelectedDataset(datasetId);
                    }}
                    value={selectedDatasetId}
                />
            </div>
            <Table dataset={dataset} datasets={datasets} features={features} getDatasetData={getDatasetData} />
        </div>
    );
}

DatasetMapping.propTypes = {
    selectedDatasetId: PropTypes.string,
    updateSelectedDataset: PropTypes.func,
};
DatasetMapping.defaultProps = {
    selectedDatasetId: null,
    updateSelectedDataset: () => {},
};
