import { Actions } from './reducers';
import AlgorithmApiInstance from './api';
import { operations } from '../../../../models/base';

const createModel = (id, form) => (dispatch) => {
    dispatch(Actions.createModelRequest(id, form));
    return AlgorithmApiInstance.createModel(id, form).then(
        (response) => dispatch(Actions.createModelSuccess(id, form, response)),
        (errors) => dispatch(Actions.createModelFailure(id, form, errors)),
    );
};

export default operations(Actions, AlgorithmApiInstance, {
    createModel,
});
