import React from 'react';
import PropTypes from 'prop-types';
import { ModalCard, Input } from '../../../../shared';
import PermissionTable from '../../../_components/PermissionTable';

require('./styles.scss');

export default function RoleDetails(props) {
    const { title, open, onClose, role, roleName } = props;

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title={title}
            primaryButtonOptions={{ content: 'Done', onClick: onClose }}
        >
            <div className="RoleDetails__Modal">
                <Input id="roleName" label="Your Role:" type="text" value={roleName} disabled gray sm />
                <PermissionTable userRoles={role} disabled />
            </div>
        </ModalCard>
    );
}

RoleDetails.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    role: PropTypes.shape({}),
    roleName: PropTypes.string,
};
RoleDetails.defaultProps = {
    title: '',
    open: false,
    onClose: () => {},
    role: null,
    roleName: '',
};
