import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useInput } from '../../../helpers/hooks/form';
import Input from '../Input';

require('./styles.scss');

export default function EditableTitle(props) {
    const { id, defaultValue, onSubmit, placeholder, allowEmptyString, disabled, maxWidth } = props;
    const handleSubmit = (_, value) => {
        if ((value || allowEmptyString) && value !== defaultValue) onSubmit(id, value);
    };
    const [nameInput, setNameInput] = useInput(defaultValue, { onSubmit: handleSubmit });
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (defaultValue && !initialized) {
            setInitialized(true);
            setNameInput(defaultValue);
        }
    }, [defaultValue, initialized, setNameInput]);
    const onBlur = () => {
        if (!allowEmptyString && !nameInput.value) setNameInput(defaultValue);
    };

    return (
        <Input
            id={id}
            type="editable-text"
            className="EditableTitle"
            disabled={disabled}
            {...nameInput}
            onBlur={onBlur}
            placeholder={placeholder}
            placeholderIsMinWidth
            submitOnBlur
            autosize
            maxWidth={maxWidth}
        />
    );
}

EditableTitle.propTypes = {
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    allowEmptyString: PropTypes.bool,
    disabled: PropTypes.bool,
    maxWidth: PropTypes.number,
};

EditableTitle.defaultProps = {
    id: 'name',
    defaultValue: '',
    placeholder: 'Name',
    allowEmptyString: false,
    disabled: false,
    maxWidth: undefined,
};
