import { createReducer } from 'reduxsauce';
import { uniq } from 'lodash';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import { Types as DatasetTypes } from '../dataset/reducers';
import { Types as UnitTypes } from '../../../Units/_model/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

const showFailure = (state = INITIAL_STATE, action) => {
    const byId = { ...state.byId };
    if (action.errors && action.errors.statusCode === 404) {
        byId[action.id] = { ...byId[action.id], loading: false, error: 'feature not found' };
        return { ...state, errors: action.errors, byId };
    }
    // If not 404, use default behavior - same as base showFailure
    delete byId[action.id];
    const allIds = state.allIds.filter((id) => id !== action.id);
    return { ...state, errors: action.errors, allIds, byId };

    // The base showFailure can also be changed as well, for example:
    // const byId = { ...state.byId, [action.id]: { ...cloneDeep(model), _id: action.id, loading: false } };
};

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('feature', {
    categoriesRequest: ['id'],
    categoriesSuccess: ['id', 'categories'],
    categoriesFailure: ['error'],
    previewRequest: ['id'],
    previewSuccess: ['id', 'preview'],
    previewFailure: ['error'],
    statsRequest: ['id'],
    statsSuccess: ['id', 'stats'],
    statsFailure: ['id', 'error'],
});

export { Creators as Actions, Types };

// ---------------- CREATE CRUD REDUCERS ----------------

const crudReducers = makeCrudReducers('feature', INITIAL_STATE, Schema, Types);

// ---------------- ACTION HANDLERS ----------------

const categoriesSuccess = (state = INITIAL_STATE, { id, categories }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], categories, loading: false };
    return { ...state, errors: {}, byId };
};

const previewSuccess = (state, { id, preview }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], preview, loading: false };
    return { ...state, errors: {}, byId };
};

const statsSuccess = (state, { id, stats }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], stats, loading: false };
    return { ...state, errors: {}, byId };
};

const unitDataSuccess = (state, { data }) => {
    if (data && data.features) {
        return crudReducers[Types.INDEX_SUCCESS](state, { features: { list: Object.values(data.features) } });
    }
    return state;
};

// makes sure that features don't get lost if there are multiple requests at once
const datasetFeaturesSuccess = (state = INITIAL_STATE, { features }) => {
    const allIds = [...state.allIds];
    const byId = { ...state.byId };

    features.list.forEach((feature, i) => {
        byId[feature._id] = { ...byId[feature._id], ...feature, listIndex: i };
        allIds.push(feature._id);
    });

    return { ...state, hydrated: true, loading: false, errors: {}, byId, allIds: uniq(allIds) };
};

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(['CATEGORIES', 'PREVIEW', 'STATS'], Types, {
    [UnitTypes.DATA_SUCCESS]: unitDataSuccess,
    [DatasetTypes.FEATURES_SUCCESS]: datasetFeaturesSuccess,
    [Types.CATEGORIES_SUCCESS]: categoriesSuccess,
    [Types.PREVIEW_SUCCESS]: previewSuccess,
    [Types.SHOW_FAILURE]: showFailure,
    [Types.STATS_SUCCESS]: statsSuccess,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('features'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
