import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon, IconButton, ContextMenu, ContextSubMenu, ContextMenuItem } from '../../../shared';
import UserIcon from '../../../Access/Management/User/UserIcon';

require('./styles.scss');

export default function NavbarSkin({ user, homeItem, navItems, menuItems, platform, title, hasSubNav }) {
    const [openMenuContext, setOpenMenuContext] = useState(false);
    const toggleMenuContext = () => {
        setOpenMenuContext(!openMenuContext);
    };

    const createContextMenuItems = (items) => (
        <ContextSubMenu>
            {items.map(({ label, icon, onClick }) => (
                <ContextMenuItem
                    id={label}
                    key={label}
                    name={label}
                    type={<Icon size="20px" icon={icon} />}
                    onClick={() => {
                        onClick();
                        toggleMenuContext();
                    }}
                />
            ))}
        </ContextSubMenu>
    );

    // eslint-disable-next-line react/prop-types
    const SettingsButton = ({ children }) => {
        if (user && platform !== 'phone') {
            return (
                <UserIcon
                    firstName={user.name.first}
                    lastName={user.name.last}
                    userId={user._id}
                    user={user}
                    onClick={toggleMenuContext}
                    hasTooltip={!openMenuContext}
                >
                    {children}
                </UserIcon>
            );
        }
        return (
            <IconButton
                mini
                icon={platform === 'phone' ? 'hamburger' : 'account'}
                onClick={toggleMenuContext}
                tooltip={openMenuContext || 'Settings'}
                active={openMenuContext}
            >
                {children}
            </IconButton>
        );
    };

    return (
        <div className={ClassNames('Navbar', { 'NavBar--withSubNav': hasSubNav })}>
            <div
                className={ClassNames('Navbar__nav', {
                    'Navbar__nav--active': homeItem?.active,
                })}
            >
                <IconButton
                    mini
                    icon={homeItem?.icon || 'home'}
                    onClick={homeItem?.onClick}
                    tooltip={homeItem?.label || 'home'}
                />
            </div>
            {title && (
                <span
                    className={ClassNames('Navbar__title', {
                        'Navbar__title--Left': navItems.length > 0 && platform !== 'phone',
                    })}
                >
                    {title}
                </span>
            )}

            {platform !== 'phone' && navItems.length > 0 && (
                <div className="Navbar__navigationButtons">
                    {navItems.map(({ label, icon, active, onClick }) => (
                        <div
                            key={label}
                            className={ClassNames('Navbar__nav', {
                                'Navbar__nav--active': active,
                            })}
                        >
                            <IconButton
                                mini
                                icon={icon}
                                onClick={onClick}
                                tooltip={platform !== 'desktop' && label}
                                label={platform === 'desktop' ? label : null}
                            />
                        </div>
                    ))}
                </div>
            )}
            <div
                className={ClassNames('Navbar__nav', 'Navbar__nav__menu', {
                    'Navbar__nav--active': openMenuContext,
                })}
            >
                <SettingsButton>
                    <ContextMenu
                        className="Navbar__settings"
                        position="top right"
                        open={openMenuContext}
                        onClickOut={toggleMenuContext}
                    >
                        {platform === 'phone' && createContextMenuItems(navItems)}
                        {createContextMenuItems(menuItems)}
                    </ContextMenu>
                </SettingsButton>
            </div>
        </div>
    );
}
NavbarSkin.propTypes = {
    homeItem: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        active: PropTypes.bool,
    }),
    user: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.shape({ first: PropTypes.string, last: PropTypes.string }),
    }),
    platform: PropTypes.string,
    title: PropTypes.node,
    navItems: PropTypes.array,
    menuItems: PropTypes.array,
    hasSubNav: PropTypes.bool,
};

NavbarSkin.defaultProps = {
    homeItem: PropTypes.shape({
        label: 'home',
        icon: 'home',
        onClick: () => {},
        active: false,
    }),
    user: null,
    platform: 'desktop',
    title: null,
    navItems: [],
    menuItems: [],
    hasSubNav: false,
};
