import React from 'react';
import PropTypes from 'prop-types';
import FeatureBasket from '../../../components/FeatureBasket';
import SerumOperationButton from '../../../components/SerumButton/operation';
import { removePeriods } from '../../../../../helpers/string';
import ChipColorPicker from '../components/color';

import './styles.scss';
import { getBinNames } from '../../../../Data/helpers';

export default function GroupByBasket({
    features,
    datasets,
    showSourceNames,
    groupColors,
    operationId,
    formatter,
    getColorForSeries,
    data,
    onChange,
    dndProps,
}) {
    const { serum, containerIndex } = dndProps;
    const feature = features.byId[serum.feature?._id];
    const dataset = datasets.byId[feature?.dataset._id];
    const datasetName = showSourceNames && dataset?.name;
    const groupedFeature = data?.features[feature._id];

    const updateUnitGroupByColors = (name, color) => {
        onChange('addGroupByColor', { name: removePeriods(name), color });
    };
    const names = getBinNames(groupedFeature, serum);
    const groups = [...new Set(data?.series?.dependent?.map((dataSerum) => dataSerum.group))].sort((a, b) => a - b);

    return (
        <FeatureBasket className="GroupByBucket" {...dndProps} datasetName={datasetName} feature={feature}>
            <div className="GroupByBucket__operation">
                {(feature?.type === 'date' || feature?.type === 'number') && (
                    <SerumOperationButton
                        id={`${operationId}-${containerIndex}`}
                        value={serum.operation}
                        onChange={onChange}
                        feature={feature}
                        aggregation={false}
                        binValues={{ id: `${operationId}NumberOfBins-${containerIndex}`, value: serum.numberOfBins }}
                    />
                )}
            </div>
            <div className="GroupByBucket__groups">
                {groups.map((group) => {
                    const dataSerum = data.series.dependent.find((ds) => ds.group === group);
                    const color = getColorForSeries(dataSerum);
                    const id = formatter(group);
                    const name = serum?.operation === 'bin' ? names[group - 1] : id;
                    return (
                        <div className="GroupByBucket__color" key={group || 'loading'}>
                            <ChipColorPicker
                                setColor={(updatedColor) => updateUnitGroupByColors(group, updatedColor)}
                                changed={!!groupColors[group]}
                                color={color}
                            />
                            <div>{name}</div>
                        </div>
                    );
                })}
            </div>
        </FeatureBasket>
    );
}

GroupByBasket.propTypes = {
    onChange: PropTypes.func.isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        loading: PropTypes.bool,
    }).isRequired,
    datasets: PropTypes.shape({
        byId: PropTypes.shape({}),
    }).isRequired,
    showSourceNames: PropTypes.bool,
    groupColors: PropTypes.shape({}),
    operationId: PropTypes.string,
    dndProps: PropTypes.shape({
        serum: PropTypes.shape({
            id: PropTypes.string,
            feature: PropTypes.shape({ _id: PropTypes.string }),
            operation: PropTypes.string,
            numberOfBins: PropTypes.number,
        }),
        containerIndex: PropTypes.number,
    }).isRequired,
    formatter: PropTypes.func.isRequired,
    getColorForSeries: PropTypes.func.isRequired,
    data: PropTypes.shape({
        series: PropTypes.shape({
            dependent: PropTypes.array,
        }),
        features: PropTypes.shape({}),
    }),
};

GroupByBasket.defaultProps = {
    operationId: '',
    showSourceNames: false,
    data: null,
    groupColors: {},
};
