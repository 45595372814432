import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';
import { Types as tabTypes } from '../tabs/reducers';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('filter');
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('filter', INITIAL_STATE, Schema, Types);

// ---------------- ACTION HANDLERS ----------------

const tabShowSuccess = (state, { tab }) => crudReducers[Types.INDEX_SUCCESS](state, { filters: { list: tab.filters } });

const tabFiltersUpdateRequest = (state) => state; // prevent filter updates from calling data twice.
// alternatively, we can make updateRequest set the filter state to loading.  This is not the default for `updateReqest`, which applies loading state byId
const tabFiltersUpdateSuccess = (state, { filter, filterId }) => {
    const state1 = crudReducers[Types.UPDATE_REQUEST](state, { filter, id: filterId });
    return crudReducers[Types.UPDATE_SUCCESS](state1, { filter, id: filterId });
};
const tabFiltersUpdateFailure = (state, { errors, filterId }) =>
    crudReducers[Types.UPDATE_FAILURE](state, { errors, id: filterId });
const tabFiltersDestroyRequest = (state, { filterId }) => crudReducers[Types.DESTROY_REQUEST](state, { id: filterId });
const tabFiltersDestroySuccess = (state, { result, filterId }) =>
    crudReducers[Types.DESTROY_SUCCESS](state, { result, id: filterId });
const tabFiltersDestroyFailure = (state, { errors, filterId }) =>
    crudReducers[Types.DESTROY_FAILURE](state, { errors, id: filterId });
const tabFiltersDestroyAllRequest = (state = INITIAL_STATE, action) =>
    state.allIds
        .filter((id) => state.byId[id].tab.id === action.id)
        .reduce((nextState, id) => crudReducers[Types.DESTROY_REQUEST](nextState, { id }), state);
const tabFiltersDestroyAllSuccess = (state, action) =>
    Object.keys(state._tmp)
        .filter((id) => state._tmp[id].tab.id === action.id)
        .reduce((nextState, id) => crudReducers[Types.DESTROY_SUCCESS](nextState, { id }), state);
const tabFiltersDestroyAllFailure = (state, action) =>
    Object.keys(state._tmp)
        .filter((id) => state._tmp[id].tab.id === action.id)
        .reduce(
            (nextState, id) => crudReducers[Types.DESTROY_FAILURE](nextState, { id, errors: action.errors }),
            state,
        );

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = {
    [tabTypes.SHOW_SUCCESS]: tabShowSuccess,
    [tabTypes.FILTERS_CREATE_REQUEST]: crudReducers[Types.CREATE_REQUEST],
    [tabTypes.FILTERS_CREATE_SUCCESS]: crudReducers[Types.CREATE_SUCCESS],
    [tabTypes.FILTERS_CREATE_FAILURE]: crudReducers[Types.CREATE_FAILURE],
    [tabTypes.FILTERS_UPDATE_REQUEST]: tabFiltersUpdateRequest,
    [tabTypes.FILTERS_UPDATE_SUCCESS]: tabFiltersUpdateSuccess,
    [tabTypes.FILTERS_UPDATE_FAILURE]: tabFiltersUpdateFailure,
    [tabTypes.FILTERS_DESTROY_REQUEST]: tabFiltersDestroyRequest,
    [tabTypes.FILTERS_DESTROY_SUCCESS]: tabFiltersDestroySuccess,
    [tabTypes.FILTERS_DESTROY_FAILURE]: tabFiltersDestroyFailure,
    [tabTypes.FILTERS_DESTROY_ALL_REQUEST]: tabFiltersDestroyAllRequest,
    [tabTypes.FILTERS_DESTROY_ALL_SUCCESS]: tabFiltersDestroyAllSuccess,
    [tabTypes.FILTERS_DESTROY_ALL_FAILURE]: tabFiltersDestroyAllFailure,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('filters'),
};

export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
