import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import UnitLoader from '../components/Loader';
import Placeholder from '../components/Placeholder';
import SingleKpiUnit from './kpi';

require('./styles.scss');

class KpiUnit extends Component {
    shouldComponentUpdate = (nextProps) => nextProps.unit.hydrated;
    render() {
        const { unit, features, width, height } = this.props;

        if (!unit.hydrated || !unit.data) return <UnitLoader />;
        if (unit.data.error) return <Placeholder unit={unit} error={unit.data.error} height={height} />;

        const { data } = unit.data;

        return (
            <div
                className={ClassNames('KPIUnit', {
                    'KPIUnit--narrow': height > width && width < 600 && unit.vis.kpi.length > 1,
                    'KPIUnit--smallest': height < 300 && width < 600 && unit.vis.kpi.length > 1,
                })}
            >
                {unit.vis &&
                    unit.vis.kpi.map((serum, i) => (
                        <SingleKpiUnit key={serum.id} serum={serum} data={data[i]} features={features} />
                    ))}
            </div>
        );
    }
}

KpiUnit.propTypes = {
    unit: PropTypes.shape({
        data: PropTypes.shape({
            data: PropTypes.array,
            error: PropTypes.string,
        }),
        vis: PropTypes.shape({
            kpi: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        hydrated: PropTypes.bool,
    }),
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
    }).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};
KpiUnit.defaultProps = {
    unit: {
        data: {
            data: [],
        },
        vis: {},
    },
    width: null,
    height: null,
};

export default KpiUnit;
