import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { categoryFilterOptions } from '../constants';
import { Input } from '../../../shared';
import FilterHeader from './header';

// The filter component for categorical features
// Takes a filter object (value), and calls props.onChange with key:value updates for the object.  Fully controlled.
export default class CategoryFilterForm extends Component {
    handleValueChange = (id, value) => {
        const { onChange } = this.props;
        let category = Object.keys(value);
        if (!category.length) category = undefined;
        onChange('value', { category });
    };
    handleTextValueChange = (id, value) => {
        const { onChange } = this.props;
        onChange('value', { text: value });
    };

    render() {
        const { feature, value, onSubmit } = this.props;
        const inputValue = {};
        ((value.value || {}).category || []).forEach((cat) => {
            inputValue[cat] = true;
        });
        const textValue = value.value.text;
        let input = null;
        if (['equal', 'not-equal'].includes(value.option)) {
            input = (
                <Input
                    id="category"
                    type="list"
                    items={feature.categories}
                    loading={!feature.categories?.length}
                    multiSelect
                    dark
                    autoFocus
                    header={feature.categories?.length > 10}
                    border={false}
                    onChange={this.handleValueChange}
                    value={inputValue}
                />
            );
        } else if (['contains', 'begins-with', 'ends-with'].includes(value.option)) {
            input = (
                <Input
                    id="categoryTextInput"
                    className="categoryTextInput"
                    type="text"
                    dark
                    autoFocus
                    onChange={this.handleTextValueChange}
                    onSubmit={onSubmit}
                    value={textValue}
                />
            );
        }

        return (
            <div className="CategoryFilter">
                <FilterHeader {...this.props} options={categoryFilterOptions} />
                {input}
            </div>
        );
    }
}

CategoryFilterForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    feature: PropTypes.shape({
        categories: PropTypes.array,
        name: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({
        option: PropTypes.string,
        value: PropTypes.shape({
            category: PropTypes.arrayOf(PropTypes.string),
            text: PropTypes.string,
        }),
    }),
    categories: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func.isRequired,
};
CategoryFilterForm.defaultProps = {
    categories: [],
    value: {
        value: {
            category: [],
        },
    },
};
