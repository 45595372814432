import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { IconButton } from '../../../../shared';

require('./styles.scss');

export default function SectionListItem(props) {
    const { item, index, currentTab, onClick, handleMenuClick, children, isActive } = props;

    return (
        <div
            className={ClassNames('SectionListItem', {
                'SectionListItem--complete': index <= currentTab,
                'SectionListItem--active': isActive,
                'SectionListItem--upcoming': index > currentTab,
            })}
            key={`${item.name}`}
            role="button"
            onClick={onClick}
            tabIndex={0}
        >
            <div className="SectionListItem__body">
                <div className="SectionListItem__lines">
                    <div className="SectionListItem__line" />
                    <div className="SectionListItem__line" />
                </div>
                <div className="SectionListItem__circle" />
                <div className="SectionListItem__label">{item.name}</div>
            </div>
            <IconButton
                mini
                icon="menu-vertical"
                className="SectionListItem__menu"
                onClick={() => {
                    handleMenuClick();
                }}
            >
                {children}
            </IconButton>
        </div>
    );
}

SectionListItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    currentTab: PropTypes.number.isRequired,
    handleMenuClick: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
    isActive: PropTypes.bool.isRequired, // Add isActive prop type
};

SectionListItem.defaultProps = {
    handleMenuClick: () => {},
    onClick: () => {},
    children: null,
};
