import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Input, Icon, IconButton } from '../../../../shared';
import { getConfigComponent } from './types';
import { inputDataTypeOptions, inputSetupOptions } from './options';
import { updateInputDataType } from './helpers';
import ModelInputDisplay from '../../../_components/ModelInput/display';

require('./styles.scss');

const ModelInputConfig = (props) => {
    const { model, updateModel, inputId } = props;

    const input = model.inputs.find((i) => i._id === inputId);
    const ConfigComponent = getConfigComponent(input);

    const { inputTypeOptions } = inputSetupOptions[input?.dataType] || {};
    const hasDefaultValue = input?.defaultValue || input?.defaultValue === false || input?.defaultValue === 0;

    const [inputDescription, setInputDescription] = useState(input?.description);
    const [hasDescription, setHasDescription] = useState(Boolean(input?.description));

    const updateInput = (value) => {
        updateModel({ inputs: model.inputs.map((i) => (i._id === inputId ? { ...i, ...value } : i)) });
    };

    const updateInputDisplay = (value) => {
        updateInput({ display: { ...input.display, ...value } });
    };

    const onSubmit = (id, value) => updateInput({ [id]: value });

    const updateInputDefaultValue = (value) => {
        updateModel({
            inputs: model.inputs.map((i) => (i._id === inputId ? { ...i, defaultValue: value, value } : i)),
        });
    };
    const onChangeDataType = (value) => {
        if (value === input.dataType) return;
        updateModel({
            inputs: model.inputs.map((i) => (i._id === inputId ? updateInputDataType(i, value) : i)),
        });
    };

    return (
        <div className="ModelInputConfig">
            {input ? (
                <div className="ModelInputConfig__main">
                    <div className={ClassNames('InputRow', 'InputRow--2col--right')}>
                        <Input id="varName" label="Parameter Name" type="text" value={input.varName} gray disabled />
                        <Input
                            id="dataType"
                            label="Parameter Type"
                            type="select"
                            options={inputDataTypeOptions}
                            value={input.dataType}
                            onChange={(_, value) => onChangeDataType(value)}
                            gray
                            required
                            info="This is the type of parameter DeepSea will pass into your function."
                            errors={input.dataType ? [] : [{ message: 'Select a type for this input' }]}
                        />
                    </div>

                    {input.dataType && (
                        <React.Fragment>
                            <hr />
                            <div className="ModelInputConfig__subtitle">Dashboard UI Options</div>
                            <div className={ClassNames('InputRow', 'InputRow--2col--right')}>
                                <Input
                                    id="modelBar"
                                    type="select"
                                    label="Model Bar Display"
                                    options={[
                                        { value: true, label: 'Visible' },
                                        { value: false, label: 'Hidden' },
                                    ]}
                                    value={input.display?.modelBar}
                                    onChange={(_, value) => updateInputDisplay({ modelBar: value })}
                                    gray
                                    info="Whether this parameter is visible in a dashboard’s ‘model bar’."
                                />
                                <Input
                                    id="modelPanel"
                                    type="select"
                                    label="Model Panel Display"
                                    value={input.display?.modelPanel}
                                    options={[
                                        { value: 'hidden', label: 'Hidden' },
                                        { value: 'primary', label: 'Visible' },
                                        { value: 'secondary', label: 'Advanced Setting' },
                                    ]}
                                    onChange={(_, value) => updateInputDisplay({ modelPanel: value })}
                                    gray
                                    info="Whether this parameter is visible in a dashboard’s ‘model panel’."
                                />
                            </div>
                            <hr />
                            <div className="InputRow">
                                <Input
                                    id="inputType"
                                    type="select"
                                    label="Input Style"
                                    value={input.display?.inputType}
                                    options={inputTypeOptions}
                                    disabled={inputTypeOptions?.length === 1}
                                    onChange={(_, value) => updateInputDisplay({ inputType: value })}
                                    gray
                                />
                                <Input
                                    id="name"
                                    type="text"
                                    label="Input Title"
                                    value={input.name}
                                    onChange={onSubmit}
                                    gray
                                />
                                <ModelInputDisplay
                                    input={input}
                                    handleChange={(_, updateValue) => updateInputDefaultValue(updateValue)}
                                    inputProps={{
                                        label: 'Default Value',
                                        value: input.defaultValue,
                                        required: true,
                                        errors: hasDefaultValue
                                            ? []
                                            : [{ message: 'This input requires a default value' }],
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    {input.dataType && (
                        <React.Fragment>
                            {ConfigComponent && (
                                <div
                                    className={ClassNames('InputRow', {
                                        'InputRow--1col': ['constraint', 'select', 'multiSelect'].includes(
                                            input.display?.inputType,
                                        ),
                                    })}
                                >
                                    <ConfigComponent
                                        config={input?.display?.config}
                                        updateConfig={(updateValue) =>
                                            updateInputDisplay({ ...input.display, config: updateValue })
                                        }
                                    />
                                </div>
                            )}

                            {hasDescription ? (
                                <div className="InputRow InputRow--1col">
                                    <Input
                                        id="description"
                                        type="text"
                                        label="Description"
                                        placeholder="Optional"
                                        value={inputDescription}
                                        onChange={(_, value) => setInputDescription(value)}
                                        onSubmit={onSubmit}
                                        submitOnBlur
                                        gray
                                        className="ModelInputConfig__description"
                                    />
                                </div>
                            ) : (
                                <IconButton
                                    icon="plus"
                                    onClick={() => setHasDescription(true)}
                                    label="Edit Description"
                                    className="ModelInputConfig__addDescription"
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <div className="ModelInputConfig__prompt">
                    <Icon icon="large_color/model-select-inputs" size="150px" />
                    <span>
                        <b>Select your model inputs</b> to set variable types & display options.
                    </span>
                </div>
            )}
        </div>
    );
};

ModelInputConfig.propTypes = {
    model: PropTypes.shape({
        inputs: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    updateModel: PropTypes.func.isRequired,
    inputId: PropTypes.string,
};
ModelInputConfig.defaultProps = {
    inputId: null,
};

export default ModelInputConfig;
