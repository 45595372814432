import React from 'react';
import PropTypes from 'prop-types';
import ErrorApi from './api';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error) {
        const { silent } = this.props;
        // eslint-disable-next-line no-console
        console.log('ErrorBoundary did catch', error);
        if (!silent) {
            const formattedError = {
                stack: error.stack,
            }; // formatted so it can be JSONified
            ErrorApi.error(formattedError);
        }
    }

    render() {
        const { error: propsError, invisible, errorComponent, children } = this.props;
        const { error: stateError } = this.state;
        const error = propsError || stateError;
        return error && !invisible ? errorComponent : children;
    }
}

ErrorBoundary.propTypes = {
    error: PropTypes.instanceOf(Error),
    errorComponent: PropTypes.node,
    children: PropTypes.node.isRequired,
    invisible: PropTypes.bool,
    silent: PropTypes.bool,
};
ErrorBoundary.defaultProps = {
    error: null,
    errorComponent: <h1>Something went wrong.</h1>,
    invisible: false,
    silent: false,
};
