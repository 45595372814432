import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { Input, Icon } from '../../../shared';

require('./styles.scss');

const radioOptions = [
    {
        label: 'Hidden',
        value: 'hidden',
    },
    {
        label: 'View Access',
        value: 'view',
    },
    {
        label: 'Edit Access',
        value: 'edit',
    },
];

const viewOnlyRadioOptions = [
    {
        label: 'Hidden',
        value: 'hidden',
    },
    {
        label: 'View Access',
        value: 'view',
    },
    {
        label: 'Edit Access',
        value: 'edit',
        disabled: true,
    },
];

export default function PermissionTable(props) {
    const { userId, disabled, userRoles, myRoles, onChange } = props;
    const handleChange = (id, val) => {
        // when multiple permission tables are on screen at the same time
        // the radio buttons all need distinct ids for the html components to be independent
        onChange(id.replace(userId, ''), val);
    };

    return (
        <div
            className={ClassNames('PermissionTable', {
                'PermissionTable--disabled': disabled,
            })}
        >
            <div className="PermissionTable__header">
                <div className="columnNames">
                    <div className="text">Project Permissions</div>
                </div>
                <div className="columnNames">
                    <Icon size="16px" icon="dashboard" />
                    <div className="text">Dashboard</div>
                </div>
                <div className="columnNames">
                    <Icon size="16px" icon="database" />
                    <div className="text">Datasets</div>
                </div>
                <div className="columnNames">
                    <Icon size="16px" icon="form" />
                    <div className="text">Forms</div>
                </div>
                <div className="columnNames">
                    <Icon size="16px" icon="brain" />
                    <div className="text">Models</div>
                </div>
            </div>
            <div className="PermissionTable__options">
                <div className="PermissionTable__checkBox">
                    <Input
                        id={`${userId}editMembers`}
                        type="checkbox"
                        label="Edit Members"
                        value={userRoles.editMembers}
                        onChange={handleChange}
                        disabled={disabled || !myRoles.editMembers}
                        inlineLabel
                        labelRight
                    />
                    <Input
                        id={`${userId}editProject`}
                        type="checkbox"
                        label="Edit/Delete Project"
                        value={userRoles.editProject}
                        onChange={handleChange}
                        disabled={disabled || !myRoles.editProject}
                        inlineLabel
                        labelRight
                    />
                </div>
                <div className="PermissionTable__radio">
                    <Input
                        id={`${userId}dashboard`}
                        type="radio"
                        value={userRoles.dashboard}
                        options={myRoles.dashboard === 'view' ? viewOnlyRadioOptions : radioOptions}
                        disabled={disabled || myRoles.dashboard === 'hidden'}
                        onChange={handleChange}
                    />
                </div>
                <div className="PermissionTable__radio">
                    <Input
                        id={`${userId}dataset`}
                        type="radio"
                        value={userRoles.dataset}
                        options={myRoles.dataset === 'view' ? viewOnlyRadioOptions : radioOptions}
                        disabled={disabled || myRoles.dataset === 'hidden'}
                        onChange={handleChange}
                    />
                </div>
                <div className="PermissionTable__radio">
                    <Input
                        id={`${userId}form`}
                        type="radio"
                        value={userRoles.form}
                        options={myRoles.form === 'view' ? viewOnlyRadioOptions : radioOptions}
                        disabled={disabled || myRoles.form === 'hidden'}
                        onChange={handleChange}
                    />
                </div>
                <div className="PermissionTable__radio">
                    <Input
                        id={`${userId}model`}
                        type="radio"
                        value={userRoles.model}
                        options={myRoles.model === 'view' ? viewOnlyRadioOptions : radioOptions}
                        disabled={disabled || myRoles.model === 'hidden'}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

PermissionTable.propTypes = {
    disabled: PropTypes.bool,
    userId: PropTypes.string,
    userRoles: PropTypes.shape({
        editMembers: PropTypes.bool,
        editProject: PropTypes.bool,
        dashboard: PropTypes.string,
        dataset: PropTypes.string,
        form: PropTypes.string,
        model: PropTypes.string,
    }),
    onChange: PropTypes.func,
    myRoles: PropTypes.shape({
        editProject: PropTypes.bool,
        editMembers: PropTypes.bool,
        dashboard: PropTypes.string,
        dataset: PropTypes.string,
        form: PropTypes.string,
        model: PropTypes.string,
        package: PropTypes.string,
    }),
};
PermissionTable.defaultProps = {
    userId: '',
    disabled: false,
    onChange: () => {},
    userRoles: null,
    myRoles: {},
};
