import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
/* eslint-disable */

import { ChatContextProvider } from './context/chatContext';
import SideBar from './_components/SideBar';
import ChatView from './_components/ChatView';
import Modal from './_components/Modal';
import Setting from './_components/Settings';


import ErrorBoundary from '../shared/ErrorBoundary';
import routes from './routes';

// require('./daisy.css');
require('./styles.scss');

const Mobi = () => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const apiKey = window.localStorage.getItem('api-key');
    if (!apiKey) {
      // setModalOpen(true);
    }
  }, []);

  return (
    <ChatContextProvider>
      <div className='ChatBox'>
        <ChatView />
      </div>
    </ChatContextProvider>
  );
};

Mobi.propTypes = {};
Mobi.defaultProps = {};

export default Mobi;
