import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';

import TabHeader from './header';
import { makePath } from './helpers';

require('./styles.scss');

export default function Tabs({ tabs, className, useRoutes }) {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const currentTab = tabs && tabs[currentTabIndex];
    if (useRoutes) {
        return (
            <div className={`Tabs ${className}`}>
                <header className="TabHeader">
                    {tabs.map((tab) => (
                        <NavLink
                            className={({ isActive }) => `Tabs__tab${isActive ? ' Tabs__tab--active' : ''}`}
                            key={tab.label}
                            to={makePath(tab.path, tab.params)}
                        >
                            {tab.label}
                        </NavLink>
                    ))}
                </header>
                <main className="Tabs__main">
                    <Routes>
                        {tabs.map((tab) => (
                            <Route key={tab.label} exact path={tab.path} element={tab.element} />
                        ))}
                        <Route path="*" element={<Navigate to={makePath(tabs[0].path, tabs[0].params)} replace />} />
                    </Routes>
                </main>
            </div>
        );
    }

    // uses state instead of routes
    const CurrentTabComponent = currentTab.element;
    return (
        <div className={`Tabs ${className}`} key={currentTabIndex}>
            <TabHeader
                tabs={tabs}
                onSelect={setCurrentTabIndex}
                tabIndex={tabs.findIndex((tab) => tab.label === currentTab.label)}
            />
            <main className="Tabs__main">{CurrentTabComponent}</main>
        </div>
    );
}

Tabs.propTypes = {
    className: PropTypes.string,
    useRoutes: PropTypes.bool,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({}),
            label: PropTypes.string,
            component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        }),
    ),
};

Tabs.defaultProps = {
    className: '',
    useRoutes: true,
    tabs: [],
};
