import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ModalCard, Input } from '../../../../shared';

export default function AddSectionList(props) {
    const { title, open, onClose, onSubmit } = props;
    const [sectionName, setSectionName] = useState(''); // State to track the section name

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onSubmit(sectionName); // Pass the section name to the onSubmit handler
    };

    const handleButtonClick = () => {
        onSubmit(sectionName); // Pass the section name to the onSubmit handler
    };

    const handleChange = (id, value) => {
        setSectionName(value); // Update state with input value
    };

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title={title}
            className="AddSectionListModal"
            autofocus
            primaryButtonOptions={{ content: 'Create', id: 'create', onClick: handleButtonClick }} // Use handleButtonClick here
            secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: onClose }}
        >
            <form onSubmit={handleFormSubmit} className="AddSectionListModal__form">
                <Input
                    id="name"
                    type="text"
                    label="Section Name"
                    placeholder="Name"
                    gray
                    required
                    value={sectionName}
                    onChange={handleChange} // Pass handleChange function
                />
            </form>
        </ModalCard>
    );
}

AddSectionList.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

AddSectionList.defaultProps = {
    title: 'Add Section',
    open: false,
    onClose: () => {},
    onSubmit: () => {},
};
