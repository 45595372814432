import React from 'react';
import PropTypes from 'prop-types';

const PlatformContext = React.createContext('desktop'); // can be "desktop", "tablet" or "phone"

// from _responsive shared style
// $desktop: '(min-width: 1024px)';
// $tablet: '(min-width: 768px) and (max-width: 1023px)';
// $phone: '(min-width: 0px) and (max-width: 767px)';

const PlatformProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    let platform = 'desktop';
    if (width < 767) {
        platform = 'phone';
    } else if (width < 1024) {
        platform = 'tablet';
    }

    return <PlatformContext.Provider value={platform}>{children}</PlatformContext.Provider>;
};

PlatformProvider.propTypes = {
    children: PropTypes.node,
};
PlatformProvider.defaultProps = {
    children: PropTypes.node,
};

export { PlatformContext, PlatformProvider };
