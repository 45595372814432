import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Sidebar from 'react-sidebar';
import { neutral90 } from '../../../styles/partials/colors';
import IconButton from '../IconButton';

require('./styles.scss');

const collapseSideBar = window.matchMedia && window.matchMedia`(min-width: 1023px)`;
const collapseTopBar = window.matchMedia && window.matchMedia`(min-width: 767px)`;

export default class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarDocked: collapseSideBar.matches,
            sidebarOpen: true,
        };

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }
    componentDidMount() {
        collapseTopBar.addListener(this.mediaQueryChanged);
        collapseSideBar.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        collapseSideBar.removeListener(this.mediaQueryChanged);
        collapseTopBar.removeListener(this.mediaQueryChanged);
    }

    onSetSidebarOpen = (open) => {
        this.setState({ sidebarOpen: open });
    };

    mediaQueryChanged = () => {
        this.setState({
            sidebarDocked: collapseSideBar.matches,
            sidebarOpen: true,
        });
    };
    toggleSideBar = () => {
        const { sidebarOpen } = this.state;
        this.setState({ sidebarOpen: !sidebarOpen });
    };

    render() {
        const { title, children, className, listPane, topbar, bottomPane } = this.props;
        const { sidebarOpen, sidebarDocked } = this.state;
        const main = (
            <div className="Page__mainWrapper">
                {topbar && <div className="Page__topbarContainer">{topbar}</div>}
                <main
                    className={ClassNames(className, 'Page__main', {
                        'Page__main--topbar': topbar,
                        'Page__main--listPane': listPane,
                    })}
                >
                    {title ? <div className="Page__title">{title}</div> : null}
                    {children}
                </main>
            </div>
        );
        return (
            <div className="Page">
                {listPane && !sidebarDocked ? (
                    <div className="Page__sidebarExpand">
                        <IconButton icon="database" label="Show Datasets" onClick={this.toggleSideBar} />
                    </div>
                ) : null}
                {listPane ? (
                    <Sidebar
                        open={sidebarOpen}
                        docked={sidebarDocked}
                        onSetOpen={this.onSetSidebarOpen}
                        styles={{
                            // react-sidebar sets many styles inline.
                            // These are the ones we have to overwrite:
                            // additional styles are in the scss file.
                            root: {
                                position: 'relative',
                                overflow: 'visible',
                            },
                            sidebar: {
                                top: sidebarDocked ? 0 : -50,
                            },
                            overlay: {
                                position: 'absolute',
                                top: -40,
                                backgroundColor: `rgba(${neutral90}, 0.5)`,
                            },
                        }}
                        // TODO: remove the rootClassName `className`.
                        // I can't do this now because the page styles are super nested
                        rootClassName={ClassNames('Page__sidebar_root', className)}
                        sidebarClassName="Page__sidebar_listPane"
                        contentClassName="Page__sidebar_content"
                        touchHandleWidth={0}
                        shadow={!sidebarDocked}
                        sidebar={listPane}
                        defaultSidebarWidth={274}
                    >
                        {main}
                    </Sidebar>
                ) : (
                    main
                )}
                {bottomPane && <div className="Page__bottomPaneContainer">{bottomPane}</div>}
            </div>
        );
    }
}

Page.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    topbar: PropTypes.node,
    listPane: PropTypes.node,
    bottomPane: PropTypes.node,
    className: PropTypes.string,
};

Page.defaultProps = {
    title: null,
    topbar: null,
    listPane: null,
    bottomPane: null,
    className: '',
};
