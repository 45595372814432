import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from '../../../../shared';
import { ModelRunModel } from '../../../_models';

export default function RunInfoDescription(props) {
    const { modelRun } = props;
    const [description, setDescription] = useState(modelRun.description);
    const dispatch = useDispatch();
    return (
        <div>
            <Input
                id="description"
                label="Model Run Description"
                type="textarea"
                gray
                value={description}
                submitOnBlur
                onSubmit={() => dispatch(ModelRunModel.update(modelRun._id, { description }))}
                onChange={(_, value) => setDescription(value)}
            />
        </div>
    );
}

RunInfoDescription.propTypes = {
    modelRun: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
    }),
};
RunInfoDescription.defaultProps = {
    modelRun: {},
};
