import { createReducer } from 'reduxsauce';
import { cloneDeep } from 'lodash';

import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';

import Schema from './schema';
import { Types as dashboardTypes } from '../dashboards/reducers';

const INITIAL_STATE = initialState();

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('tab', {
    filtersCreateRequest: ['id', 'filter', 'tempId'],
    filtersCreateSuccess: ['id', 'filter', 'tempId'],
    filtersCreateFailure: ['id', 'errors', 'tempId'],
    filtersUpdateRequest: ['id', 'filterId', 'filter'],
    filtersUpdateSuccess: ['id', 'filterId', 'filter'],
    filtersUpdateFailure: ['id', 'filterId', 'errors'],
    filtersDestroyRequest: ['id', 'filterId'],
    filtersDestroySuccess: ['id', 'filterId', 'result'],
    filtersDestroyFailure: ['id', 'filterId', 'errors'],
    filtersDestroyAllRequest: ['id'],
    filtersDestroyAllSuccess: ['id'],
    filtersDestroyAllFailure: ['id', 'errors'],
    sharingCreateRequest: ['id'],
    sharingCreateSuccess: ['id', 'shareObj'],
    sharingCreateFailure: ['id', 'errors'],
    sharingDestroyRequest: ['id'],
    sharingDestroySuccess: ['id', 'shareObj'],
    sharingDestroyFailure: ['id', 'errors'],
    sharingUpdateRequest: ['id'],
    sharingUpdateSuccess: ['id', 'shareObj'],
    sharingUpdateFailure: ['id', 'errors'],
    updateLayoutRequest: ['id', 'tab'],
    updateLayoutSuccess: ['id', 'tab'],
    updateLayoutFailure: ['id', 'errors'],
    createUnitRequest: ['id', 'body', 'tempId'],
    createUnitSuccess: ['id', 'tab', 'tempId'],
    createUnitFailure: ['id', 'errors', 'tempId'],
    destroyUnitRequest: ['id', 'unitId'],
    destroyUnitSuccess: ['id', 'unitId', 'tab'],
    destroyUnitFailure: ['id', 'unitId', 'errors'],
    duplicateUnitRequest: ['id', 'unitId', 'body'],
    duplicateUnitSuccess: ['id', 'tab', 'unitId'],
    duplicateUnitFailure: ['id', 'errors', 'unitId'],
    moveUnit: ['sectionId', 'unitIds'], // New action
});
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('tab', INITIAL_STATE, Schema, Types);

// ---------------- ACTION HANDLERS ----------------

const showSuccess = (state, action) =>
    crudReducers[Types.SHOW_SUCCESS](state, {
        ...action,
        tab: {
            ...action.tab,
            units: action.tab.units.map((u) => u._id),
            filters: action.tab.filters.map((f) => f._id),
        },
    });

const indexSuccess = (state, action) =>
    crudReducers[Types.INDEX_SUCCESS](state, {
        ...action,
        tabs: {
            list: action.tabs.list.map((tab) => ({
                ...tab,
                units: tab.units && tab.units.map((u) => u.id),
            })),
        },
    });

const dashboardShowSuccess = (state, { dashboard }) => {
    const tabs = dashboard.tabs;
    return indexSuccess(state, { tabs: { list: tabs } });
};

const sharingCreateSuccess = (state = INITIAL_STATE, { shareObj }) => {
    const { resourceId, accessorId } = shareObj;

    const byId = { ...state.byId };
    const dashb = { ...byId[resourceId] };
    const users = [...dashb.users];
    const userIndex = users.findIndex((u) => u.user._id === accessorId);
    users[userIndex] = { ...users[userIndex], sharing: shareObj };
    return { ...state, byId: { ...byId, [resourceId]: { ...dashb, users } } };
};
const sharingDestroySuccess = (state = INITIAL_STATE, { shareObj }) => {
    const { resourceId, accessorId } = shareObj;

    const byId = { ...state.byId };
    const tab = { ...byId[resourceId] };
    const users = [...tab.users];
    const userIndex = users.findIndex((u) => u.user._id === accessorId);
    const newUserObject = { ...users[userIndex] };
    delete newUserObject.sharing;
    users[userIndex] = newUserObject;
    return { ...state, byId: { ...byId, [resourceId]: { ...tab, users } } };
};

// We don't want to pass loading flag to layout storing
const updateLayoutRequest = (state, action) => {
    const intermediateState = crudReducers[Types.UPDATE_REQUEST](state, action);
    intermediateState.byId[action.id].loading = false;
    return intermediateState;
};

const createUnitSuccess = (state = INITIAL_STATE, action) => {
    const { layout, units } = action.tab;
    const tab = {
        ...state.byId[action.id],
        layout,
        units: units.map((unit) => unit._id),
        // hydrated: true,
        loading: false,
    };
    return { ...state, byId: { ...state.byId, [action.id]: tab } };
};

// Move unit handler
const moveUnitHandler = (state = INITIAL_STATE, action) => {
    const { sectionId, unitIds } = action.payload;
    const tab = { ...state.byId[sectionId] };
    tab.units = unitIds.map((unit) => unit.id);
    return { ...state, byId: { ...state.byId, [sectionId]: tab } };
};

const filtersCreateRequest = (state, { id, tempId }) =>
    crudReducers[Types.UPDATE_REQUEST](state, { id, tab: { filters: [...state.byId[id].filters, tempId] } });
const filtersCreateSuccess = (state, { id, filter, tempId }) => {
    const filters = [...state.byId[id].filters];
    const tempFilterIndex = filters.findIndex((fId) => fId === tempId);
    filters[tempFilterIndex] = filter._id;
    const intermediateState = crudReducers[Types.UPDATE_SUCCESS](state, { id, tab: { filters } });
    // currently, update success do anything with the 'tab' key
    intermediateState.byId[id].filters = filters; // so we'll update it here.
    return intermediateState;
};

const filtersDestroyRequest = (state, { id, filterId }) =>
    crudReducers[Types.UPDATE_REQUEST](state, {
        id,
        tab: { filters: state.byId[id].filters.filter((fId) => fId !== filterId) },
    });

const filtersDestroyAllRequest = (state, { id }) =>
    crudReducers[Types.UPDATE_REQUEST](state, { id, tab: { filters: [] } });

const dashboardCreateTabSuccess = (state = INITIAL_STATE, { dashboard }) => {
    const { tabs } = dashboard;
    const newTab = tabs.find((tab) => !state.byId[tab._id]);
    const allIds = [...state.allIds, newTab._id];
    const byId = { ...state.byId, [newTab._id]: { ...cloneDeep(Schema), ...newTab } };
    return { ...state, allIds, byId };
};
const dashboardDestroyTabSuccess = (state = INITIAL_STATE, { tabId }) => {
    const allIds = state.allIds.filter((id) => id !== tabId);
    const byId = { ...state.byId };
    delete byId[tabId];
    return { ...state, allIds, byId };
};

// ---------------- CREATE REDUCERS ----------------

const additionalReducers = apiHandlers(
    [
        'SHARING_CREATE',
        'SHARING_DESTROY',
        'SHARING_UPDATE',
        'UPDATE_LAYOUT',
        'CREATE_UNIT',
        'DESTROY_UNIT',
        'DUPLICATE_UNIT',
    ],
    Types,
    {
        [dashboardTypes.SHOW_SUCCESS]: dashboardShowSuccess,
        [dashboardTypes.CREATE_TAB_SUCCESS]: dashboardCreateTabSuccess,
        [dashboardTypes.DESTROY_TAB_SUCCESS]: dashboardDestroyTabSuccess,

        [Types.SHOW_SUCCESS]: showSuccess,
        [Types.INDEX_SUCCESS]: indexSuccess,

        [Types.SHARING_CREATE_SUCCESS]: sharingCreateSuccess,
        [Types.SHARING_UPDATE_SUCCESS]: sharingCreateSuccess,
        [Types.SHARING_DESTROY_SUCCESS]: sharingDestroySuccess,

        [Types.UPDATE_LAYOUT_REQUEST]: updateLayoutRequest,
        [Types.UPDATE_LAYOUT_SUCCESS]: crudReducers[Types.UPDATE_SUCCESS],
        [Types.UPDATE_LAYOUT_FAILURE]: crudReducers[Types.UPDATE_FAILURE],

        [Types.CREATE_UNIT_SUCCESS]: createUnitSuccess,
        [Types.DESTROY_UNIT_SUCCESS]: createUnitSuccess,
        [Types.DUPLICATE_UNIT_SUCCESS]: createUnitSuccess,

        [Types.FILTERS_CREATE_REQUEST]: filtersCreateRequest,
        [Types.FILTERS_CREATE_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
        [Types.FILTERS_CREATE_SUCCESS]: filtersCreateSuccess,
        [Types.FILTERS_DESTROY_REQUEST]: filtersDestroyRequest,
        [Types.FILTERS_DESTROY_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
        [Types.FILTERS_DESTROY_SUCCESS]: crudReducers[Types.UPDATE_SUCCESS],
        [Types.FILTERS_DESTROY_ALL_REQUEST]: filtersDestroyAllRequest,
        [Types.FILTERS_DESTROY_ALL_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
        [Types.FILTERS_DESTROY_ALL_SUCCESS]: crudReducers[Types.UPDATE_SUCCESS],

        [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
        [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('tabs'),

        // Handle the move unit action
        [Types.MOVE_UNIT]: moveUnitHandler,
    },
);

export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
