import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import { Table, Input, IconButton, ConfirmDeleteMenu } from '../../../shared';
import DatasetModel from '../../_models/dataset';

require('./styles.scss');

export default function DatasetHistory(props) {
    const { datasetId } = props;

    const dispatch = useDispatch();
    const dataset = useSelector((state) => state.datasets.byId[datasetId]);
    const jobs = useSelector((state) => state.jobs);

    const [confirmDeleteId, setConfirmDeleteId] = useState(null);

    const datasetJobs = Object.values(jobs.byId).filter(
        (job) => job.ownerType === 'dataset' && job.ownerId === dataset._id,
    );
    return (
        <div className="DatasetHistory">
            <div className="DatasetHistory__info">
                <Input type="text" disabled gray label="Original file name" value={dataset.source.originalFileName} />
                <Input
                    type="text"
                    disabled
                    gray
                    label="Time created"
                    value={moment.utc(dataset.timeCreated).local().format('lll')}
                />
                <Input
                    type="text"
                    disabled
                    gray
                    label="Last updated"
                    value={moment.utc(dataset.lastUpdated).local().format('lll')}
                />
            </div>
            <hr />
            <Table
                id="jobsTable"
                value={datasetJobs.map((job) => ({
                    name: job.name,
                    status: job.status,
                    timeCreated: moment.utc(job.timeCreated).local().format('lll'),
                    deleteJob: (
                        <IconButton
                            icon="trash"
                            redAlert
                            label="Delete Data"
                            onClick={() => setConfirmDeleteId(job._id)}
                            active={confirmDeleteId === job._id}
                        >
                            <ConfirmDeleteMenu
                                open={confirmDeleteId === job._id}
                                position="bottom"
                                onClose={() => setConfirmDeleteId(null)}
                                onDelete={() => dispatch(DatasetModel.deleteJobData(dataset._id, job._id))}
                                message="Remove job data? This cannot be undone."
                            />
                        </IconButton>
                    ),
                }))}
                titles={{
                    name: 'Job',
                    timeCreated: 'Date',
                    status: 'Status',
                    deleteJob: ' ',
                }}
            />
        </div>
    );
}

DatasetHistory.propTypes = {
    datasetId: PropTypes.string.isRequired,
};

DatasetHistory.defaultProps = {};
