import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import OrganizationModel from '../../_models/organizations';
import { Logo } from '../../../shared';

export default function OrganizationLogo({ id, small }) {
    const organization = useSelector((state) => state.organizations.byId[id]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (id && !organization) dispatch(OrganizationModel.show(id));
    }, [dispatch, id, organization]);

    return <Logo logo={organization?.logo} small={small} />;
}

OrganizationLogo.propTypes = {
    id: PropTypes.string.isRequired,
    small: PropTypes.bool,
};
OrganizationLogo.defaultProps = {
    small: false,
};
