import ApiBase from '../../../../models/base/api';

class User extends ApiBase {
    constructor() {
        super('users');
    }
    create = (form, organizationId) => this.request('POST', `users?organizationId=${organizationId}`, form);

    updatePassword = (form) => this.request('PUT', 'users/my/password', form);

    getLogs = (id) => this.request('GET', `logs/users/${id}`);

    updateLogo = (id, obj) => this.request('PUT', `users/${id}/logo`, obj, { contentType: 'FormData' });

    resendValidationEmail = (userId) => this.request('POST', `users/${userId}/resendEmail`);

    indexByOrganization = (organizationId) => this.request('GET', `users/organizations/${organizationId}`);
}

export default new User();
