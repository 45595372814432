import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, ConfirmDeleteMenu, ContextMenu, ContextMenuItem, ContextSubMenu, IconButton } from '../../../shared';

require('./styles.scss');

export default function FormCard(props) {
    const { form, onClick, onDelete, onDuplicate, canEditForms } = props;
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

    let status = <div className="FormCard__status FormCard__draft">Draft</div>;
    if (form.published && !form.closed) {
        status = <div className="FormCard__status FormCard__published">Published</div>;
    } else if (form.closed && form.published) {
        status = <div className="FormCard__status FormCard__closed">Closed</div>;
    }

    const handleEdit = (id) => {
        const { onEdit } = props;
        setContextMenuOpen(false);
        onEdit(id);
    };

    return (
        // css classname stolen from dashboards
        <div className="FormCard">
            <Card>
                <div className="FormCard__main" role="button" tabIndex={0} onClick={canEditForms && onClick}>
                    <div className="FormCard__title">{form.name}</div>
                    {/* note: the description in the form mapping is NOT the description that is displayed here */}
                    <div className="FormCard__description">{form.description}</div>
                </div>
                <div className="FormCard__sidebar">
                    <IconButton
                        icon="menu-vertical"
                        onClick={() => setContextMenuOpen(true)}
                        active={contextMenuOpen}
                        className="PreviewCard__button"
                        disabled={!canEditForms}
                    >
                        <ContextMenu
                            open={contextMenuOpen}
                            onClickOut={() => {
                                setDeleteTooltipOpen(false);
                                setContextMenuOpen(false);
                            }}
                        >
                            <ContextSubMenu>
                                <ContextMenuItem
                                    id="editButton"
                                    key="editButton"
                                    name="Edit"
                                    onClick={() => handleEdit(form._id)}
                                />
                                <ContextMenuItem
                                    id="duplicateButton"
                                    key="duplicateButton"
                                    name="Duplicate"
                                    onClick={() => {
                                        onDuplicate();
                                        setContextMenuOpen(false);
                                    }}
                                />
                                <ContextMenuItem
                                    id="deleteButton"
                                    key="deleteButton"
                                    name="Delete"
                                    onClick={() => setDeleteTooltipOpen(!deleteTooltipOpen)}
                                >
                                    <ConfirmDeleteMenu
                                        open={deleteTooltipOpen}
                                        onClose={() => setDeleteTooltipOpen(false)}
                                        onDelete={onDelete}
                                    />
                                </ContextMenuItem>
                            </ContextSubMenu>
                        </ContextMenu>
                    </IconButton>
                </div>
                {status}
            </Card>
        </div>
    );
}

FormCard.propTypes = {
    form: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        published: PropTypes.bool,
        closed: PropTypes.bool,
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    canEditForms: PropTypes.bool,
};

FormCard.defaultProps = {
    canEditForms: false,
};
