import { nanoid } from 'nanoid';
import { Actions } from './reducers';
import DatasetApiInstance from './api';
import { operations } from '../../../../models/base';
import { Actions as JobActions } from '../job/reducers';
import { showNotificationPopUp } from '../../../shared/NotificationPopUp/helpers';

const create = (dispatch, datasetConfig, datasetFile) =>
    new Promise((resolve) => {
        const tempId = nanoid();
        dispatch(Actions.createRequest(datasetConfig, tempId));
        return DatasetApiInstance.create(datasetConfig).then(
            (dataset) => {
                dispatch(Actions.createSuccess(dataset, tempId));
                resolve(dataset);
                // Upload the file if it is type: 'file'
                if (datasetConfig.type === 'file') {
                    DatasetApiInstance.upload(dataset._id, datasetFile).catch((errors) =>
                        dispatch(Actions.createFailure(errors, tempId)),
                    );
                }
            },
            (errors) => dispatch(Actions.createFailure(errors, tempId)),
        );
    });

const append = (datasetId, form) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(Actions.createRequest(form));
        return DatasetApiInstance.append(datasetId, form).then(
            (dataset) => {
                dispatch(Actions.createSuccess(dataset));
                resolve(dataset);
            },
            (errors) => dispatch(Actions.createFailure(errors)),
        );
    });

const data = (datasetId, options = {}, temporaryQueries = {}) => {
    const { jobId, page, limit } = options;
    const { filters, orderBy } = temporaryQueries;
    return (dispatch) => {
        dispatch(Actions.dataRequest(datasetId));
        DatasetApiInstance.data(datasetId, { jobId, page, limit }, { filters, orderBy }).then(
            (response) => dispatch(Actions.dataSuccess(datasetId, response)),
            (error) => dispatch(Actions.dataFailure(datasetId, error)),
        );
    };
};

const features =
    (datasetId, options = {}) =>
    (dispatch) => {
        dispatch(Actions.featuresRequest(datasetId));
        return DatasetApiInstance.features(datasetId, options).then(
            (featuresResponse) => {
                // this action is also being looked for in the features reducers:
                dispatch(Actions.featuresSuccess(datasetId, featuresResponse));
                // return Promise.resolve(featuresResponse);
            },
            (errors) => dispatch(Actions.featuresFailure(errors)),
        );
    };

const confirmData = (datasetId) => (dispatch) => {
    dispatch(Actions.confirmDataRequest(datasetId));
    return DatasetApiInstance.confirmData(datasetId).then(
        () => dispatch(Actions.confirmDataSuccess(datasetId)),
        (errors) => dispatch(Actions.confirmDataFailure(datasetId, errors)),
    );
};

const deleteJobData = (id, jobId) => (dispatch) => {
    dispatch(Actions.deleteJobDataRequest(id, jobId));
    DatasetApiInstance.deleteJobData(id, jobId).then(
        (response) => {
            dispatch(Actions.deleteJobDataSuccess(id, jobId, response));
        },
        (error) => dispatch(Actions.deleteJobDataFailure(id, error)),
    );
};

const updateUploadJob = (payload) => (dispatch, getState) => {
    const state = getState();
    dispatch(JobActions.updateSuccess(payload._id, payload));
    if (state.jobs.byId[payload._id.status] === 'completed') return; // for some reason the backend sends 2 completed socket notifications
    if (payload.type === 'featureDetection' && payload.status === 'completed') {
        dispatch(Actions.updateImport(payload.ownerId));
        showNotificationPopUp({
            type: 'success',
            title: 'Dataset uploaded:',
            subTitle: `${state.datasets.byId[payload.ownerId]?.name}`,
            autoDismiss: 7000,
        });
    }
    if (payload.type === 'append' && payload.status === 'completed') {
        dispatch(Actions.updateAppend(payload.ownerId, payload.dataset));
        dispatch(data(payload.ownerId, { jobId: 'latest', limit: 1000 }));
        showNotificationPopUp({
            type: 'success',
            title: 'Dataset appended:',
            subTitle: `${state.datasets.byId[payload.ownerId]?.name}`,
            autoDismiss: 7000,
        });
    }
};

export default operations(Actions, DatasetApiInstance, {
    create,
    data,
    features,
    confirmData,
    append,
    deleteJobData,
    updateUploadJob,
});
