import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, ModalCard } from '../../../../shared';
import LayoutButton from './LayoutButton';

export default function CreateDashboardModal(props) {
    const { open, onClose, createDashboard, loading } = props;
    const [selected, setSelected] = useState('Dashboard');

    const handleLayoutChange = (e) => {
        setSelected(e.currentTarget.id);
    };

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title="Create Data App"
            formOptions={{ onSubmit: (form) => createDashboard({ ...form, type: selected }), loading }}
            primaryButtonOptions={{
                content: 'Create',
                id: 'submit',
                loading,
            }}
        >
            <div className="DashboardList__Modal">
                <div className="DashboardList__Row1">
                    <Input
                        className="DashBoardListInput"
                        id="name"
                        value=""
                        type="text"
                        label="App Name"
                        required
                        gray
                    />
                    <Input
                        className="DashBoardListInput--Description"
                        id="description"
                        value=""
                        type="text"
                        label="Description"
                        gray
                    />
                </div>
                <hr />
                <div className="DashboardList__Row2">
                    Layout:
                    <div className="DashboardList__Layout">
                        <LayoutButton
                            onClick={handleLayoutChange}
                            icon="large_color/dashboard"
                            size="150px"
                            title="Dashboard"
                            selected={selected === 'Dashboard'}
                        />
                        <LayoutButton
                            onClick={handleLayoutChange}
                            icon="large_color/report"
                            size="150px"
                            title="Report"
                            selected={selected === 'Report'}
                        />
                    </div>
                </div>
            </div>
        </ModalCard>
    );
}

CreateDashboardModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    createDashboard: PropTypes.func.isRequired,
};

CreateDashboardModal.defaultProps = {
    loading: false,
};
