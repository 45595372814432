import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SectionListItem from './SectionListItem';
import { IconButton, ContextMenu, ContextMenuItem, ConfirmDeleteMenu, Icon } from '../../../shared';
import AddSectionList from '../../_components/modals/AddSectionList';
import { DashboardModel, TabModel } from '../../_models';

require('./styles.scss');

export default function SectionList(props) {
    const { sectionId, sections, handleReOrderSections, dashboardId, onClick, handleDuplicate, currentSectionId } = props;
    const dispatch = useDispatch();

    const [menuOpen, setMenuOpen] = useState(null);
    const [deleteTooltip, setDeleteTooltip] = useState(false);
    const [addSection, setAddSection] = useState(false);
    const [renameSection, setRenameSection] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);
    const [currentTabId, setCurrentTabId] = useState(sectionId);

    const handleAddSection = (name) => {
        setAddSection(false);
        dispatch(DashboardModel.createTab(dashboardId, { name }));
    };

    const handleRenameSection = (form) => {
        setRenameSection(false);
        dispatch(TabModel.update(currentTabId, { name: form }));
        const tabIndex = sections.findIndex((e) => e._id === currentTabId);
        sections[tabIndex].name = form.name;
    };

    const handleDeleteSection = () => {
        dispatch(DashboardModel.destroyTab(dashboardId, currentTabId));
        if (sections.length > 1) {
            if (currentTab === 0) {
                setCurrentTab(0);
            } else if (currentTab === sections.length - 1) {
                setCurrentTab(currentTab - 1);
            }
        }
    };

    const handleClick = (index) => {
        onClick(sections[index]._id);
        setCurrentTab(index);
        setCurrentTabId(sections[index]._id);
    };

    const handleMenuClick = (index) => {
        onClick(sections[index]._id);
        setCurrentTab(index);
        setCurrentTabId(sections[index]._id);
        setMenuOpen(index);
    };

    const handleMove = (index, direction) => {
        const updatedSections = [...sections];
        if (direction === 'up' && index > 0) {
            [updatedSections[index - 1], updatedSections[index]] = [updatedSections[index], updatedSections[index - 1]];
            handleReOrderSections(updatedSections);
        } else if (direction === 'down' && index < sections.length - 1) {
            [updatedSections[index], updatedSections[index + 1]] = [updatedSections[index + 1], updatedSections[index]];
            handleReOrderSections(updatedSections);
        }
    };

    return (
        <div className="SectionList">
            {sections.map((item, index) => {
                if (!item) return null; // Check if item is null or undefined
    
                return (
                    <SectionListItem
                        key={item._id}
                        item={item}
                        index={index}
                        currentTab={currentTab}
                        onClick={() => handleClick(index)}
                        handleMenuClick={() => handleMenuClick(index)}
                        isActive={item._id === currentSectionId} // Pass isActive prop
                    >
                        <ContextMenu
                            className="SectionList__Menu"
                            open={menuOpen === index}
                            onClickOut={() => {
                                setMenuOpen(null);
                                setDeleteTooltip(false);
                            }}
                        >
                            <ContextMenuItem
                                id={`${index}`}
                                name="Rename Section"
                                type={<Icon icon="string" size="16px" />}
                                onClick={() => {
                                    setRenameSection(true);
                                    setMenuOpen(null);
                                }}
                            />
                            <ContextMenuItem
                                id={`${index}`}
                                name="Duplicate Section"
                                type={<Icon icon="copy" size="16px" />}
                                onClick={() => {
                                    handleDuplicate(sections[index]._id);
                                    setMenuOpen(null);
                                }}
                            />
                            <ContextMenuItem
                                id={`${index}`}
                                name="Up one"
                                type={<Icon icon="arrow-up" size="16px" />}
                                onClick={() => {
                                    handleMove(index, 'up');
                                    setMenuOpen(null);
                                }}
                            />
                            <ContextMenuItem
                                id={`${index}`}
                                name="Down One"
                                type={<Icon icon="arrow-down" size="16px" />}
                                onClick={() => {
                                    handleMove(index, 'down');
                                    setMenuOpen(null);
                                }}
                            />
                            <ContextMenuItem
                                id={`${index}`}
                                name="Delete Section"
                                type={<Icon icon="trash" size="16px" />}
                                onClick={() => {
                                    setDeleteTooltip(true);
                                }}
                            >
                                <ConfirmDeleteMenu
                                    open={deleteTooltip}
                                    onClose={() => {
                                        setDeleteTooltip(false);
                                        setMenuOpen(null);
                                    }}
                                    onDelete={() => {
                                        setMenuOpen(null);
                                        setDeleteTooltip(false);
                                        handleDeleteSection();
                                    }}
                                />
                            </ContextMenuItem>
                        </ContextMenu>
                    </SectionListItem>
                );
            })}
            <IconButton className="SectionList__addSection" mini icon="plus" onClick={() => setAddSection(true)} />
            <AddSectionList open={addSection} onClose={() => setAddSection(false)} onSubmit={handleAddSection} />
            <AddSectionList open={renameSection} onClose={() => setRenameSection(false)} onSubmit={handleRenameSection} title="Rename Section" />
        </div>
    );
    
    
}

SectionList.propTypes = {
    sections: PropTypes.array.isRequired,
    handleReOrderSections: PropTypes.func,
    sectionId: PropTypes.string,
    dashboardId: PropTypes.string,
    handleDuplicate: PropTypes.func,
    onClick: PropTypes.func,
    currentSectionId: PropTypes.string, // Add currentSectionId prop
};

SectionList.defaultProps = {
    handleDuplicate: () => {},
    handleReOrderSections: () => {},
    onClick: () => {},
    sectionId: '',
    dashboardId: '',
    currentSectionId: '', // Add default prop
};
