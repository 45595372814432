import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import formatFilterValue, { isFilterValid } from '../helpers';
import FilterMenu from '../Menu';
import { Icon } from '../../../shared';

import './styles.scss';

// a single clickable filter button.
// Displays the text of the filter passed down in `value`
// Has state, open or closed
// This component is uncontrolled (value is used as initial state);
// After closing the filter, if it has a new value, it will call `onChange` with the new filter value.
export default function Filter(props) {
    const {
        id,
        value,
        onChange,
        datasets,
        datasetIds,
        features,
        top,
        iconRight,
        onDelete,
        onFeatureSelect,
        right,
        left,
        isFilterBar,
        dark,
        arrayDisplay,
    } = props;

    const [tempValue, setTempValue] = useState({
        value: value.value,
        type: value.type,
        option: value.option,
        feature: value.feature,
        operation: value.operation,
    });

    const handleDeleteFilter = () => {
        if (onDelete) onDelete(id);
        else onChange(id, null);
    };

    const [open, setOpen] = useState(false);

    const setClosed = () => {
        if (!open) return;
        setOpen(false);

        if (
            tempValue.value === value.value &&
            tempValue.feature === value.feature &&
            tempValue.type === value.type &&
            tempValue.option === value.option &&
            tempValue.operation === value.operation
        ) {
            return;
        }
        if (isFilterValid(tempValue)) {
            onChange(id, tempValue);
        } else {
            handleDeleteFilter();
        }
    };
    const handleToggleFilter = () => {
        if (open) setClosed();
        else {
            if (value && value.feature && value.feature.id && onFeatureSelect) onFeatureSelect(value.feature.id);
            setOpen(true);
        }
    };

    const feature = features.byId[tempValue.feature && tempValue.feature.id];
    const dataset = datasets.byId[feature && feature.dataset._id] || {};

    // load features on initial load:
    useEffect(() => {
        if (value && value.feature && value.feature.id && onFeatureSelect) onFeatureSelect(value.feature.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feature?._id, value]);

    const filterHasError = feature && feature.error;

    const filterLabel = filterHasError ? (
        <Icon size="20px" icon="exclamation-triangle" />
    ) : (
        <React.Fragment>
            <span className="Filter__Label--bold">{(feature && feature.name) || ''}</span>
            <span className="Filter__Label--normal">{' from '}</span>
            <span className="Filter__Label--bold">{dataset.name || ''}:</span>
        </React.Fragment>
    );

    return (
        <div
            className={classNames('Filter', {
                'Filter--menuOpen': open,
                'Filter--dark': dark,
                'Filter--arrayDisplay': arrayDisplay,
                'Filter--error': filterHasError,
            })}
            onClick={handleToggleFilter}
            role="button"
            tabIndex={0}
        >
            <div className="Filter__Display">
                <div className="Filter__Label">{filterLabel}</div>
                <div className="Filter__Value">{formatFilterValue(tempValue, feature)}</div>
                <div className="Filter__Value--icon">
                    {!iconRight ? (
                        <Icon size="20px" icon={open ? 'angle-up' : 'angle-down'} />
                    ) : (
                        <Icon size="18px" icon={open ? 'angle-down' : 'angle-right'} />
                    )}
                </div>
            </div>
            <FilterMenu
                open={open}
                datasets={datasets}
                datasetIds={datasetIds}
                features={features}
                value={tempValue}
                top={top}
                onChange={setTempValue}
                onSubmit={setClosed}
                onClickOut={setClosed}
                onFeatureSelect={onFeatureSelect}
                right={right}
                left={left}
                isFilterBar={isFilterBar}
                filterId={id}
                handleDelete={handleDeleteFilter}
            />
        </div>
    );
}

Filter.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        option: PropTypes.string,
        value: PropTypes.shape({}),
        feature: PropTypes.shape({ id: PropTypes.string }),
        operation: PropTypes.string,
    }),
    datasets: PropTypes.shape({
        allIds: PropTypes.array,
        byId: PropTypes.shape({}),
        hydrated: PropTypes.bool,
    }).isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({ byId: PropTypes.shape({}) }).isRequired,
    iconRight: PropTypes.bool,
    top: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onFeatureSelect: PropTypes.func,
    right: PropTypes.bool,
    left: PropTypes.bool,
    isFilterBar: PropTypes.bool,
    dark: PropTypes.bool,
    arrayDisplay: PropTypes.bool,
};
Filter.defaultProps = {
    value: null,
    datasetIds: [],
    iconRight: false,
    top: false,
    onChange: () => {},
    onDelete: null,
    onFeatureSelect: () => {},
    right: false,
    left: false,
    isFilterBar: false,
    dark: false,
    arrayDisplay: false,
};
