import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { ModalCard, Icon, Input, Notification } from '../../../shared';
import OrganizationModel from '../../../Access/_models/organizations';
import { ProjectModel } from '../../_models';

require('./styles.scss');

// this modal shows client orgs to people in consultant orgs, and it shows all orgs to white whale admins
export default function TransferProject({ open, onClose, project }) {
    const organizations = useSelector((state) => state.organizations.byId);
    const myUserRoles = useSelector((state) => state.app.user.roles);
    const myOrganization = useSelector((state) => state.app.organization);
    const dispatch = useDispatch();
    useEffect(() => {
        if (open) {
            if (myUserRoles.manageAllOrganizations) {
                dispatch(OrganizationModel.index());
            } else if (myUserRoles.manageClientOrganizations && myOrganization.type === 'consultant') {
                dispatch(OrganizationModel.getClients(myOrganization._id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, open]);
    const clientOrganizations = Object.values(organizations).filter(
        (consultants) => consultants.consultantId === myOrganization._id,
    );

    const organizationList = myUserRoles.manageAllOrganizations ? organizations : clientOrganizations;

    const [selectedOrg, setSelectedOrg] = useState('');

    return (
        <ModalCard
            className="TransferProject"
            open={open}
            onClose={onClose}
            title={
                <div className="TransferProject__title">
                    <Icon icon="whale" size="30px" />
                    {`Transfer Project - ${project?.name}`}
                </div>
            }
            primaryButtonOptions={{
                content: 'Transfer Project',
                id: 'submit',
                onClick: onClose,
            }}
            secondaryButtonOptions={{
                content: 'Cancel',
                id: 'cancel',
                onClick: onClose,
            }}
            formOptions={{
                onSubmit: (value) => {
                    dispatch(ProjectModel.update(project._id, { organizationId: value.TransferProject }));
                    onClose();
                },
            }}
        >
            <Notification
                inline
                message="After you transfer this project, the organization that currently owns it may lose administrative access."
                status="warn"
            />
            <Input
                id="TransferProject"
                label="Transfer to:"
                type="select"
                value={selectedOrg}
                options={Object.values(organizationList).map((orgs) => ({
                    value: orgs._id,
                    label: orgs.name,
                }))}
                onChange={(id, value) => setSelectedOrg(value)}
            />
        </ModalCard>
    );
}

TransferProject.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    project: PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
    }),
};
TransferProject.defaultProps = {
    open: false,
    onClose: () => {},
    project: null,
};
