import { Actions } from './reducers';
import ModelApiInstance from './api';
import { operations } from '../../../../models/base';

// run model
const run = (id, options) => (dispatch, getState) => {
    const model = getState().models.byId[id];
    const inputs = model && model.inputs;
    dispatch(Actions.runRequest(id));
    return ModelApiInstance.run(id, inputs, options).then(
        (response) => dispatch(Actions.runSuccess(id, response)),
        (errors) => dispatch(Actions.runFailure(id, errors)),
    );
};

const getRuns = (id, options) => (dispatch) => {
    dispatch(Actions.getRunsRequest(id));
    return ModelApiInstance.modelRuns(id, options).then(
        (response) => dispatch(Actions.getRunsSuccess(id, response)),
        (errors) => dispatch(Actions.getRunsFailure(id, errors)),
    );
};

const duplicate = (id) => (dispatch) => {
    dispatch(Actions.duplicateRequest(id));
    return ModelApiInstance.duplicate(id).then(
        (response) => dispatch(Actions.duplicateSuccess(id, response)),
        (errors) => dispatch(Actions.duplicateFailure(id, errors)),
    );
};

const updateAlgorithm = (id, algorithmId) => (dispatch) => {
    dispatch(Actions.updateAlgorithmRequest(id, algorithmId));
    return ModelApiInstance.updateAlgorithm(id, algorithmId).then(
        (response) => dispatch(Actions.updateAlgorithmSuccess(id, response)),
        (errors) => dispatch(Actions.updateAlgorithmFailure(id, errors)),
    );
};

const updateModel = (id, updatedModel) => (dispatch) => {
    dispatch(Actions.updateModelRequest(id));
    return ModelApiInstance.update(id, updatedModel).then(
        (response) => dispatch(Actions.updateModelSuccess(id, response)),
        (errors) => dispatch(Actions.updateModelFailure(id, errors)),
    );
};

const updateOutputs = (id, updates) => (dispatch) => {
    dispatch(Actions.updateOutputsRequest(id, updates));
    return ModelApiInstance.updateOutputs(id, updates).then(
        (response) => dispatch(Actions.updateOutputsSuccess(id, response)),
        (errors) => dispatch(Actions.updateOutputsFailure(id, errors)),
    );
};

const editOutput = (id, datasetId, updates) => (dispatch) => 
    // dispatch(Actions.editOutputRequest(id, datasetId, updates));
    ModelApiInstance.editOutput(id, datasetId, updates).then(
        (response) => dispatch(Actions.updateModelSuccess(id, response)),
        (errors) => dispatch(Actions.updateModelFailure(id, errors)),
    );

const confirm = (id) => (dispatch) => {
    dispatch(Actions.confirmRequest(id));
    return ModelApiInstance.confirm(id).then(
        (response) => dispatch(Actions.confirmSuccess(id, response)),
        (errors) => dispatch(Actions.confirmFailure(id, errors)),
    );
};

// this action will locally update the store without sending a request to our api
const updateLocal = (id, updates) => Actions.updateLocal(id, updates);

export default operations(Actions, ModelApiInstance, {
    getRuns,
    run,
    duplicate,
    updateAlgorithm,
    updateOutputs,
    editOutput,
    updateLocal,
    updateModel,
    confirm,
});
