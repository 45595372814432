import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Placeholder from '../components/Placeholder';
import UnitLoader from '../components/Loader';
import SubtypeMapping from './subtypes/mapping';

import { customUnitSubtypes } from './subtypes';

export default function CustomUnitMapping(props) {
    const { unit, onChange } = props;
    const customUnit = customUnitSubtypes[unit.subtype];

    if (!customUnit) return <SubtypeMapping onChange={onChange} />;
    if (!customUnit.mapping) return <Placeholder unit={unit} error="not-mappable" />;

    if (!unit.data) return <UnitLoader />;

    const CustomMappingComponent = customUnit.mapping;

    return (
        <Suspense fallback={<UnitLoader />}>
            <CustomMappingComponent {...props} />
        </Suspense>
    );
}

CustomUnitMapping.propTypes = {
    unit: PropTypes.shape({
        subtype: PropTypes.string,
        data: PropTypes.shape({}),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};
