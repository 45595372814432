import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TabModel } from '../../../_models';
import { FeatureModel } from '../../../../Data/_models';
import UnitSelectors from '../../../../Units/_model/selectors';
import FilterBar from '../../../../Data/Filter/FilterBar/skin';
import FeatureSelectors from '../../../../Data/_models/feature/selectors';

import './styles.scss';

// A connected component
// usage:  <TabFilter tabId={tabId} isPublic={bool} />
export default function TabFilter({ tabId, isPublic, publicFilters, setPublicFilters }) {
    const dispatch = useDispatch();

    const datasets = useSelector((state) => state.datasets);
    const features = useSelector((state) => state.features);

    const tabUnitIds = useSelector((state) => {
        const tab = state.tabs.byId[tabId];
        if (!tab || !tab.name) return [];
        return tab.units || [];
    });
    const tabFeatureIds = useSelector(UnitSelectors.getFeatureIds(tabUnitIds));
    const datasetIds = useSelector(FeatureSelectors.getDatasetIds(tabFeatureIds));

    const loadCategories = (id) => dispatch(FeatureModel.categories(id)); // load categories if not already there

    const handleFeatureSelect = (id) => {
        const feature = features.byId[id];
        if (feature.type === 'category' && !feature.loading) loadCategories(id);
    };

    // code that handles user filters stored in mongo
    const tabFilters = useSelector((state) => {
        const tab = state.tabs.byId[tabId];
        if (!tab || !tab.name) return null; // loading
        return tab.filters.map((filterId) => state.filters.byId[filterId]);
    });
    const createFilter = (obj) => dispatch(TabModel.createFilter(tabId, obj));
    const updateFilter = (id, obj) => dispatch(TabModel.updateFilter(tabId, id, obj));
    const destroyFilter = (id) => dispatch(TabModel.destroyFilter(tabId, id));
    const destroyAllFilters = () => dispatch(TabModel.destroyAllFilters(tabId));

    const sharedFilterBarProps = {
        datasets,
        datasetIds: datasetIds.length ? datasetIds : null,
        features,
        onFeatureSelect: handleFeatureSelect,
    };
    return isPublic ? (
        <FilterBar
            value={publicFilters}
            onChange={(_, v) => setPublicFilters(v)}
            loading={!datasetIds.length}
            {...sharedFilterBarProps}
        />
    ) : (
        <FilterBar
            value={tabFilters}
            onFilterCreate={createFilter}
            onFilterChange={updateFilter}
            onFilterDelete={destroyFilter}
            onClearAll={destroyAllFilters}
            loading={!datasets.hydrated || !tabFilters}
            {...sharedFilterBarProps}
        />
    );
}

TabFilter.propTypes = {
    tabId: PropTypes.string.isRequired,
    isPublic: PropTypes.bool,
    publicFilters: PropTypes.array,
    setPublicFilters: PropTypes.func,
};
TabFilter.defaultProps = {
    isPublic: false,
    publicFilters: [],
    setPublicFilters: () => {},
};
