import React from 'react';
import { ProjectProtectedRoute } from '../../helpers/router';

export default [
    {
        path: 'mobi',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ dashboard: 'view' }} />
        )
    }
];
