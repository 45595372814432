import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import WorkflowStepper from '../WorkflowStepper';

export default function FullScreenModalWorkflowHeader(props) {
    const { tabIndex, tabs, onClose, title } = props;
    return (
        <header className="FullScreenModalWorkflowHeader">
            <IconButton className="FullScreenModalWorkflowHeader__close" icon="times" onClick={onClose} redAlert />
            {title && <div className="FullScreenModalWorkflowHeader__title">{title}</div>}
            <div className="FullScreenModalWorkflowHeader__flex">
                <WorkflowStepper tabIndex={tabIndex} tabs={tabs} />
            </div>
        </header>
    );
}

FullScreenModalWorkflowHeader.propTypes = {
    tabIndex: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
};

FullScreenModalWorkflowHeader.defaultProps = {
    tabIndex: 0,
    title: null,
};
