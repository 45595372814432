import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IconButtonFilter from '../../../Data/Filter/IconButtonFilter';
import TableUnit from '..';
import MappingBucket from '../../components/MappingBucket';
import FeatureBasket from '../../components/FeatureBasket';
import { updateTableObject } from '../helpers';

import UnitLoader from '../../components/Loader';

require('./styles.scss');

class TableUnitMapping extends PureComponent {
    handleChange = (id, value) => {
        const { onChange, unit, features } = this.props;
        const result = updateTableObject(id, value, unit, features);
        if (result) {
            const { updateObj, shouldGetData } = result;
            onChange(updateObj, { shouldGetData });
        }
    };

    render() {
        const { unit, features, getTempData, datasetIds, handleFilterChange } = this.props;
        const { vis } = unit;

        const unitFilters = unit?.vis?.filters || [];

        return (
            <div className="TableUnitMapping">
                <div className="TableUnitMapping__preview">
                    <header>
                        <IconButtonFilter
                            onChange={handleFilterChange}
                            value={unitFilters}
                            datasetIds={datasetIds}
                            label={unitFilters.length ? `Filter (${unitFilters.length})` : 'Filter'}
                            listHeading="Card Filters"
                        />
                    </header>
                    <main>
                        {!unit.hydrated && <UnitLoader />}
                        <TableUnit unit={unit} features={features} mapping getTempData={getTempData} />
                    </main>
                </div>
                <MappingBucket
                    // values
                    id="tableColumns"
                    onChange={this.handleChange}
                    series={vis?.table || []}
                    // dnd behaviour
                    horizontal
                    allowMultiple
                    validation={
                        (series, item) =>
                            !series.find((serum) => serum.feature._id === item.feature._id) && // can't map same feature twice
                            (series.length < 1 ||
                                features.byId[item.feature._id].dataset._id ===
                                    features.byId[series[0].feature._id].dataset._id) // only allow one data source
                    }
                    // display
                    label="Columns"
                    placeholder="Drag data"
                    render={(dndProps) => (
                        <FeatureBasket
                            {...dndProps}
                            className="TableUnitBasket"
                            feature={features.byId[dndProps.serum.feature._id]}
                        />
                    )}
                />
            </div>
        );
    }
}

TableUnitMapping.propTypes = {
    unit: PropTypes.shape({
        data: PropTypes.shape({
            data: PropTypes.array,
        }),
        vis: PropTypes.shape({
            table: PropTypes.arrayOf(PropTypes.shape({})),
            filters: PropTypes.array,
        }),
        hydrated: PropTypes.bool,
        _id: PropTypes.string,
    }).isRequired,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        hydrated: PropTypes.bool,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    getTempData: PropTypes.func,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    handleFilterChange: PropTypes.func.isRequired,
};
TableUnitMapping.defaultProps = {
    getTempData: () => {},
    datasetIds: [],
};

const mapStateToProps = (state) => {
    const { features } = state;
    return { features };
};

const connectedComponent = connect(mapStateToProps)(TableUnitMapping);
export default connectedComponent;
