import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { ContextMenu, ContextMenuItem, ContextSubMenu, IconButton } from '..';

require('./styles.scss');

export default function SelectableBar(props) {
    const {
        onClick,
        className,
        selected,
        child,
        deactivated,
        logo,
        title,
        subTitle,
        info,
        options,
        menuComponent,
        menuComponentProps,
    } = props;
    const [menuOpen, setMenuOpen] = useState(false);

    const MenuComponent = menuComponent || ContextMenu;

    return (
        <div
            className={ClassNames('SelectableBar', className, {
                'SelectableBar--selected': selected,
                'SelectableBar--child': child,
                'SelectableBar--deactivated': deactivated,
            })}
            onClick={onClick}
            role="menuitem"
            tabIndex={0}
        >
            <div className="SelectableBar__logo">{logo}</div>
            <div className="SelectableBar__content">
                <div className="SelectableBar__name"> {title} </div>
                <div className="SelectableBar__details">
                    {title && subTitle && <div className="SelectableBar__yline" />}
                    {subTitle && <div className="SelectableBar__text"> {deactivated ? 'Deactivated' : subTitle} </div>}
                </div>
            </div>

            {info && <div className="SelectableBar__info"> {info} </div>}
            {(options?.length > 0 || menuComponent) && (
                <IconButton
                    icon="menu-vertical"
                    className="SelectableBar__menuIcon"
                    onClick={() => setMenuOpen(!menuOpen)}
                    active={menuOpen}
                >
                    <MenuComponent
                        open={menuOpen}
                        onClickOut={() => setMenuOpen(false)}
                        position="right top"
                        {...menuComponentProps}
                    >
                        <ContextSubMenu>
                            {options.map((option) => (
                                <ContextMenuItem
                                    key={option.id}
                                    {...option}
                                    onClick={() => {
                                        option.onClick();
                                        setMenuOpen(false);
                                    }}
                                />
                            ))}
                        </ContextSubMenu>
                    </MenuComponent>
                </IconButton>
            )}
        </div>
    );
}

SelectableBar.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    info: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    options: PropTypes.array,
    menuComponent: PropTypes.func, // note: options & menuComponent can't render at the same time
    menuComponentProps: PropTypes.shape({}),
    deactivated: PropTypes.bool,
    className: PropTypes.string,
    logo: PropTypes.node,
    child: PropTypes.bool,
};
SelectableBar.defaultProps = {
    subTitle: '',
    info: '',
    selected: false,
    onClick: () => {},
    options: [],
    menuComponent: null,
    menuComponentProps: {},
    deactivated: false,
    className: '',
    logo: null,
    child: false,
};
