import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardModel from '../_models/dashboards';
import DashboardListPage from './page';
import { CreateDashboardModal, EditDashboardModal } from '../_components/modals';

require('./styles.scss');

export default function DashboardList() {
    const dashboards = useSelector((state) => state.dashboards);
    const canEditDashboards = useSelector((state) => state.app.permission?.roles?.dashboard === 'edit');

    const hydrating = dashboards.allIds.length === 0 && !dashboards.hydrated;

    const dispatch = useDispatch();
    const onDestroy = (id) => dispatch(DashboardModel.destroy(id));
    const onCreateDashboard = (obj) => dispatch(DashboardModel.create(obj));
    const onUpdate = (id, form) => dispatch(DashboardModel.update(id, form));
    const onDuplicate = (id) => dispatch(DashboardModel.duplicate(id));
    const onUpdatePreview = (id) => dispatch(DashboardModel.updatePreview(id));

    const [renderEdit, setRenderEdit] = useState(false);
    const [renderNew, setRenderNew] = useState(false);
    const [dashboardToEdit, setDashbaordToEdit] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        DashboardModel.index();
    }, []);

    const handleCreate = (obj) => {
        if (!dashboards.loading) {
            onCreateDashboard(obj).then(() => setRenderNew(false));
        }
    };

    const handleNavigate = (id) => {
        const dashboard = dashboards.byId[id];
        if (!dashboard) return;
        const tabId = dashboard.tabs[0] && dashboard.tabs[0]._id;
        if (dashboard.type === 'Report') {
            if (tabId) {
                navigate(`${location.pathname}/report/${id}/tabs/${tabId}`);
            } else {
                navigate(`${location.pathname}/report/${id}`);
            }
        }
        if (dashboard.type !== 'Report') {
            if (tabId) {
                navigate(`${location.pathname}/${id}/tabs/${tabId}`);
            } else {
                navigate(`${location.pathname}/${id}`);
            }
        }
    };

    const toggleNew = () => {
        setRenderNew(!renderNew);
    };
    const toggleEdit = () => {
        setRenderEdit(!renderEdit);
    };

    const handleEdit = (id) => {
        setRenderEdit(true);
        setDashbaordToEdit(dashboards.byId[id]);
    };
    const handleDelete = (id) => {
        if (id) onDestroy(id);
    };
    const handleEditSubmit = (form) => {
        const finalForm = {
            name: form.name,
            description: form.description,
            display: {
                hide: {
                    hideCommandBar: form.hideCommandBar || false,
                    hideDashboardColors: form.hideDashboardColors || false,
                    hideFilters: form.hideFilters || false,
                },
            },
        };
        if (!dashboards.loading) {
            toggleEdit();
            onUpdate(dashboardToEdit._id, finalForm);
        }
    };

    return (
        <React.Fragment>
            <DashboardListPage
                dashboards={dashboards}
                onCreate={toggleNew}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onNavigate={handleNavigate}
                onDuplicate={onDuplicate}
                onUpdatePreview={onUpdatePreview}
                canEditDashboards={canEditDashboards}
                loading={hydrating}
            />
            <CreateDashboardModal
                key={renderNew}
                open={renderNew}
                onClose={toggleNew}
                loading={dashboards.loading}
                createDashboard={handleCreate}
            />
            <EditDashboardModal
                open={renderEdit}
                dashboard={dashboardToEdit}
                onClose={toggleEdit}
                onSubmit={handleEditSubmit}
            />
        </React.Fragment>
    );
}
