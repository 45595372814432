// import React, { Component } from 'react';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Measure from 'react-measure';
import _ from 'lodash';
import memoize from 'memoize-one';

import UnitModel from '../_model';
import UnitSelectors from '../_model/selectors';
import ModelSelector from '../../Models/_models/models/selectors';
import ErrorBoundary from '../../shared/ErrorBoundary';

import ChartUnit from '../Chart';
import KpiUnit from '../KPI';
import TableUnit from '../Table';
import CustomUnit from '../Custom';
import UnitLoader from '../components/Loader';

const unitTypes = {
    chart: ChartUnit,
    kpi: KpiUnit,
    table: TableUnit,
    custom: CustomUnit,
};

require('./styles.scss');

const Unit = ({
    id,
    tabId,
    dashboardId,
    filters,
    publicFilters,
    getData: fetchData, // Rename to avoid shadowing
    modelRunning,
    jobId,
    filtersLoading,
    unit,
    dashboard,
    features,
}) => {
    const [dimensions, setDimensions] = useState({ width: null, height: null });
    const childGetDataRef = useRef(null);

    const modelRuns = useSelector((state) => state.modelRuns.byId[jobId]); // Adjust this path to your state structure
    const filtersHydrated = useSelector((state) => state.filters.hydrated); // Adjust this path to your state structure

    const datasets = useSelector((state) => state.datasets.byId); // Adjust this path to your state structure
    const prevProps = useRef({ modelRunning, jobId, filters, publicFilters, filtersLoading, datasets });

    // const datasets = useSelector((state) => state.datasets.byId['65e7760fdf0d5ed2832f1fee']); // Adjust this path to your state structure
    const affectedDatasets = useMemo(
        () =>
            unit.data?.features && !unit.data.error
                ? Object.values(unit.data.features).map((v) => v.dataset._id)[0]
                : null,
        [unit],
    );

    useEffect(() => {
        fetchData(id, { tabId, dashboardId, jobId }, { filters: publicFilters });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const filtersChanged =
            (!_.isEqual(prevProps.current.filters, filters) ||
                !_.isEqual(prevProps.current.publicFilters, publicFilters)) &&
            !filtersLoading &&
            prevProps.current.filtersHydrated;

        const modelDoneRunning = prevProps.current.modelRunning && !modelRunning;
        const jobIdChanged =
            prevProps.current.jobId === 'latest' ? modelDoneRunning : prevProps.current.jobId !== jobId;

        if (filtersChanged || jobIdChanged) {
            if (childGetDataRef.current) {
                childGetDataRef.current();
            } else {
                fetchData(id, { tabId, dashboardId, jobId }, { filters: publicFilters });
            }
        }

        // THIS IS FOR EDITABLE WHEN THE DATA UPDATES
        if (datasets[affectedDatasets]?.data?.jobId !== prevProps.current.datasets[affectedDatasets]?.data?.jobId) {
            fetchData(id, { tabId, dashboardId, jobId }, { filters: publicFilters });
        }

        prevProps.current = { filters, publicFilters, jobId, modelRunning, filtersLoading, filtersHydrated, datasets };
    }, [
        filters,
        publicFilters,
        modelRunning,
        jobId,
        filtersLoading,
        filtersHydrated,
        fetchData,
        id,
        tabId,
        dashboardId,
        modelRuns,
        affectedDatasets,
        datasets,
    ]);

    const setChildDataFunction = (getData) => {
        childGetDataRef.current = getData;
    };

    const getTempData = (temporaryUnitQueries = {}, temporaryOptions = {}) => {
        const { page, limit, jobId: temporaryJobId } = temporaryOptions;
        const effectiveJobId = jobId || temporaryJobId;
        fetchData(
            id,
            { tabId, dashboardId, jobId: effectiveJobId, page, limit },
            { filters: [...publicFilters, ...temporaryUnitQueries.filters], orderBy: temporaryUnitQueries.orderBy },
        );
    };

    const { type, hydrated } = unit;
    const UnitType = unitTypes[type];

    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setDimensions(contentRect.bounds);
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef} className="Unit">
                    <ErrorBoundary>
                        {!hydrated && <UnitLoader />}
                        <UnitType
                            setGetData={setChildDataFunction}
                            unit={unit}
                            features={features}
                            colors={dashboard?.colors}
                            getTempData={getTempData}
                            width={dimensions.width}
                            height={dimensions.height}
                        />
                    </ErrorBoundary>
                </div>
            )}
        </Measure>
    );
};

Unit.propTypes = {
    id: PropTypes.string,
    unit: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        vis: PropTypes.shape({}),
        data: PropTypes.shape({
            data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
        }),
        hydrated: PropTypes.bool,
        name: PropTypes.string,
    }),
    modelRunning: PropTypes.bool,
    features: PropTypes.shape({
        byId: PropTypes.shape({}),
        allIds: PropTypes.array,
    }).isRequired,
    dashboard: PropTypes.shape({
        _id: PropTypes.string,
        filters: PropTypes.arrayOf(PropTypes.string),
        colors: PropTypes.shape({ colorScheme: PropTypes.string }),
    }),
    tabId: PropTypes.string,
    dashboardId: PropTypes.string,
    filters: PropTypes.array.isRequired,
    publicFilters: PropTypes.array,
    getData: PropTypes.func.isRequired,
    jobId: PropTypes.string.isRequired,
    filtersLoading: PropTypes.bool.isRequired,
    filtersHydrated: PropTypes.bool.isRequired,
};

Unit.defaultProps = {
    id: null,
    unit: {
        _id: null,
        type: null,
        vis: {},
        data: {
            data: null,
        },
        hydrated: false,
        name: '',
    },
    modelRunning: false,
    dashboard: null,
    tabId: null,
    dashboardId: null,
    publicFilters: [],
};

const makeMapStateToProps = () => {
    const getMemoizedFilters = memoize(UnitSelectors.getFilters);
    return (state, props) => {
        const unit = state.units.byId[props.id];
        const dashboard = state.dashboards.byId[props.dashboardId];
        const { features, datasets, units, filters: stateFilters } = state;
        const filters = getMemoizedFilters(props.id, stateFilters, features, datasets, units);
        const filtersLoading = state.filters.loading;
        const filtersHydrated = state.filters.hydrated;
        const model = UnitSelectors.getModel(props.id)(state);
        const jobId = ModelSelector.getModelRunId(model && model._id)(state) || 'latest';
        return {
            unit,
            dashboard,
            filters,
            features,
            modelRunning: model?.running,
            jobId,
            filtersLoading,
            filtersHydrated,
        };
    };
};
const mapDispatchToProps = (dispatch) => {
    const getData = (unitId, queryParameters, temporaryQueries) => {
        dispatch(UnitModel.data(unitId, queryParameters, temporaryQueries));
    };
    return { getData };
};
const connectedComponent = connect(makeMapStateToProps, mapDispatchToProps)(Unit);
export default connectedComponent;
