import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import OrganizationModel from '../../../../_models/organizations';

import { Input, ModalCard } from '../../../../../shared';

require('./styles.scss');

export default function CreateOrganizationModal(props) {
    const { onClose, open } = props;
    const dispatch = useDispatch();

    const onSubmit = (form) => {
        dispatch(OrganizationModel.create(form));
        onClose();
    };

    return (
        <ModalCard
            open={open}
            onClose={onClose}
            title="Create Organization"
            className="CreateOrganizationModal"
            formOptions={{ onSubmit }}
            autofocus
            primaryButtonOptions={{ content: 'Create Organization', id: 'submit' }}
            secondaryButtonOptions={{ content: 'Cancel', id: 'cancel', onClick: onClose }}
        >
            <div className="CreateOrganizationModal__form">
                <Input id="name" type="text" label="Organization Name" placeholder="name" gray required />
                <Input
                    id="type"
                    type="select"
                    options={[
                        { label: 'Independent Organization', value: 'independent' },
                        { label: 'Consultant Organization', value: 'consultant' },
                    ]}
                    label="Organization Type"
                    value=" -- "
                    gray
                />
                {/* <Input
                    id="plan"
                    type="select"
                    options={[
                        { label: 'Enterprise', value: 'enterprise' },
                        { label: 'Starter', value: 'starter' },
                        { label: 'Core', value: 'core' },
                    ]}
                    label="Organization Plan"
                    value=" -- "
                    gray
                /> */}
            </div>
        </ModalCard>
    );
}

CreateOrganizationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

CreateOrganizationModal.defaultProps = {
    onClose: () => {},
    open: false,
};
