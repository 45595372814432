import React from 'react';
import PropTypes from 'prop-types';

import FeatureFilterIcon from '../../Filter/FeatureFilterIcon';

import { IconButton } from '../../../shared';

require('./styles.scss');

export default function FeatureTableHeader(props) {
    const { feature, orderType, filter, onFilterChange, onOrderChange, getFeatureCategories } = props;

    let sortIcon = 'sort';
    if (orderType === 'ASC') sortIcon = 'ascending';
    if (orderType === 'DESC') sortIcon = 'descending';

    return (
        <div className="FeatureTableHeader">
            <div className="FeatureTableHeader__title">{feature && feature.name}</div>
            <IconButton
                selected={!!orderType}
                icon={sortIcon}
                onClick={() => {
                    let newOrder = null;
                    if (!orderType) newOrder = 'ASC';
                    if (orderType === 'ASC') newOrder = 'DESC';
                    onOrderChange(newOrder);
                }}
            />
            <FeatureFilterIcon
                feature={feature}
                filter={filter}
                onFilterChange={onFilterChange}
                getFeatureCategories={getFeatureCategories}
            />
        </div>
    );
}

FeatureTableHeader.propTypes = {
    feature: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        categories: PropTypes.array,
        name: PropTypes.string,
    }).isRequired,
    orderType: PropTypes.string,
    filter: PropTypes.shape(),
    onFilterChange: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    getFeatureCategories: PropTypes.func,
};

FeatureTableHeader.defaultProps = {
    getFeatureCategories: () => {},
    orderType: null,
    filter: {},
};
