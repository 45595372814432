import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import { Button, TextButton } from '../../../shared';

require('./styles.scss');

function LanguageToggle() {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      setCurrentLanguage(lang); // Update state to trigger re-render
    });
    localStorage.setItem('language', lang); // Persist language choice
  };

  // Listen for language change events
  useEffect(() => {
    const handleLanguageChange = (lang) => {
      setCurrentLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <div className="language-toggle">
        <TextButton gray onClick={() => changeLanguage('en')} >
            EN
        </TextButton>

        <TextButton gray onClick={() => changeLanguage('fr')} >
            FR
        </TextButton>

    </div>
  );
}

export default LanguageToggle;