import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

export default function DataEmpty(props) {
    const { featureEmpty } = props;
    return (
        <div className="DataEmpty">
            {featureEmpty ? (
                <React.Fragment>
                    <Icon icon="large_color/features" size="75px" />
                    <span className="DataEmpty__message">
                        <span className="DataEmpty__message--bold">Select your features</span> to edit column types &
                        display options.
                    </span>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Icon icon="large_color/dataset" size="75px" />
                    <span className="DataEmpty__message">
                        <span className="DataEmpty__message--bold">Select a dataset,</span> or add a new one to use it
                        in your dashboards.
                    </span>
                </React.Fragment>
            )}
        </div>
    );
}

DataEmpty.propTypes = {
    featureEmpty: PropTypes.bool,
};

DataEmpty.defaultProps = {
    featureEmpty: false,
};
