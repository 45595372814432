import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardModel, TabModel } from '../_models';
import UnitCard from '../../Units/Card';

import './styles.scss';

const columns = 12;

const DashboardImagePreview = () => {
    const dispatch = useDispatch();
    const { projectId, dashboardId, tabId } = useParams();
    const tab = useSelector((state) => state.tabs.byId[tabId]);

    sessionStorage.setItem('projectId', projectId);

    useEffect(() => {
        dispatch(TabModel.show(tabId));
        dispatch(DashboardModel.show(dashboardId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!tab?.name) return 'loading';

    return (
        <div className="DashboardImagePreview">
            {tab.units.map((unitId) => {
                const card = tab.layout.find((l) => l.i === unitId);
                if (card.y > 3) return false; // don't render units lower down the screen
                const style = {
                    gridColumn: `${card.x + 1} / ${card.x + card.w + 1}`,
                    gridRow: `${card.y + 1} / ${card.y + card.h + 1}`,
                };
                return (
                    <div style={style} key={unitId}>
                        <UnitCard
                            id={unitId}
                            tabId={tabId}
                            dashboardId={dashboardId}
                            editable={false}
                            layout={card}
                            width={((1400 - 20 * (columns - 1)) / columns) * card.w + 20 * (card.w - 1)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

DashboardImagePreview.propTypes = {};
DashboardImagePreview.defaultProps = {};

export default DashboardImagePreview;
