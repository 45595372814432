import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import FeatureModel from '../../_models/feature';
import FilterBarSkin from './skin';

import './styles.scss';

export default function FilterBar({ id, value, onChange, datasetIds }) {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.features);
    const datasets = useSelector((state) => state.datasets);
    const loadCategories = (featureId) => dispatch(FeatureModel.categories(featureId)); // load categories if not already there

    const handleFeatureSelect = (featureId) => {
        const feature = features.byId[featureId];
        if (feature && feature.type === 'category' && !feature.categories?.length && !feature.loading)
            loadCategories(featureId);
    };

    return (
        <FilterBarSkin
            id={id}
            value={value}
            onChange={onChange}
            datasets={datasets}
            datasetIds={datasetIds}
            features={features}
            onFeatureSelect={handleFeatureSelect}
        />
    );
}

FilterBar.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func.isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
