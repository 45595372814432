import { Actions } from './reducers';
import FeatureApiInstance from './api';
import { operations } from '../../../../models/base';

const categories = (featureId) => (dispatch) => {
    dispatch(Actions.categoriesRequest(featureId));
    FeatureApiInstance.data(featureId, { unique: true, jobId: 'all' }).then(
        (rawCategories) => {
            const stringCategories = rawCategories.map((category) => category || 'null').sort();
            dispatch(Actions.categoriesSuccess(featureId, stringCategories));
            return Promise.resolve(stringCategories);
        },
        (errors) => dispatch(Actions.categoriesFailure(errors)),
    );
};
const bulkUpdate = (dispatch, form) =>
    new Promise((resolve) => {
        dispatch(Actions.bulkUpdateRequest(form));
        FeatureApiInstance.bulkUpdate(form).then(
            (model) => {
                dispatch(Actions.bulkUpdateSuccess(model));
                resolve(model);
            },
            (errors) => dispatch(Actions.bulkUpdateFailure(errors)),
        );
    });

const preview = (featureId, options = {}) => (dispatch) => {
    dispatch(Actions.previewRequest(featureId));
    FeatureApiInstance.data(featureId, { unique: false, limit: 4, castedType: options.castedType }).then(
        (response) => {
            dispatch(Actions.previewSuccess(featureId, response));
        },
        (errors) => dispatch(Actions.previewFailure(errors)),
    );
};

const stats = (featureId) => (dispatch) => {
    dispatch(Actions.statsRequest(featureId));
    FeatureApiInstance.stats(featureId).then(
        (response) => dispatch(Actions.statsSuccess(featureId, response)),
        (error) => dispatch(Actions.statsFailure(featureId, error)),
    );
};

const destroy = (featureId) => (dispatch) => {
    dispatch(Actions.destroyRequest(featureId));
    FeatureApiInstance.destroy(featureId).then(
        (response) => dispatch(Actions.destroySuccess(featureId, response)),
        (error) => dispatch(Actions.destroyFailure(featureId, error)),
    );
};

export default operations(Actions, FeatureApiInstance, {
    bulkUpdate,
    categories,
    preview,
    stats,
    destroy,
});
