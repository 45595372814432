import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ModelModel } from '../_models';
import ModelSelector from '../_models/models/selectors';

import { Card, ConfirmDeleteMenu, TabHeader, Breadcrumb } from '../../shared';

import ModelInfo from './info';
import ModelInputs from '../Onboard/ModelInputs';
import RunModel from '../Onboard/RunModel';
import DetailHeader from '../_components/DetailHeader';
import UpdateModelModal from '../List/modals/UpdateModel';

require('./styles.scss');

const ModelDetails = () => {
    const { modelId, projectId } = useParams();
    const [updateSourceModal, setUpdateSourceModal] = useState(false);
    const [deleteModelModal, setDeleteModelModal] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const packages = useSelector((state) => state.packages);
    const algorithms = useSelector((state) => state.algorithms);

    useEffect(() => {
        dispatch(ModelModel.show(modelId));
    }, [dispatch, modelId, packages]);

    const updateModel = (updateObj) => dispatch(ModelModel.update(modelId, updateObj));

    let model = useSelector((state) => state.models.byId[modelId]);
    if (model && !model.name && model.loading) model = null; // I don't want to have to deal with this weird loading state

    const canEditModels = useSelector((state) => state.app.permission?.roles?.model === 'edit');

    const [tabIndex, setTabIndex] = useState(0);

    const packageId = useSelector(ModelSelector.getPackageId(model?._id));
    const moduleFileName = useSelector(ModelSelector.getModuleFilename(model?._id));

    return (
        <div className="ModelDetails">
            <Card>
                {model && (
                    <UpdateModelModal
                        open={updateSourceModal}
                        onClose={() => setUpdateSourceModal(false)}
                        packages={packages}
                        algorithms={algorithms}
                        model={model}
                    />
                )}
                <DetailHeader
                    title={
                        <Breadcrumb
                            items={[{ label: 'Models', path: `/projects/${projectId}/models` }, { label: model?.name }]}
                        />
                    }
                    onBack={() => navigate(`/projects/${projectId}/models`)}
                    icon="large_color/model"
                    menuItems={
                        canEditModels
                            ? [
                                  {
                                      id: 'duplicate',
                                      name: 'Duplicate Model',
                                      onClick: () => dispatch(ModelModel.duplicate(model._id)),
                                  },
                                  {
                                      id: 'update',
                                      name: 'Update Source',
                                      onClick: () => setUpdateSourceModal(true),
                                  },
                                  {
                                      id: 'delete',
                                      name: 'Delete Model',
                                      onClick: () => setDeleteModelModal(true),
                                      children: (
                                          <ConfirmDeleteMenu
                                              open={deleteModelModal}
                                              onClose={() => setDeleteModelModal(false)}
                                              onDelete={() => {
                                                  dispatch(ModelModel.destroy(model._id));
                                                  navigate(`/projects/${projectId}/models`);
                                              }}
                                          />
                                      ),
                                  },
                              ]
                            : []
                    }
                />
                <TabHeader
                    tabs={[{ label: 'Model Info' }, { label: 'Inputs' }, { label: 'Outputs' }]}
                    onSelect={setTabIndex}
                    tabIndex={tabIndex}
                />
                <div className="ModelDetails__main">
                    {tabIndex === 0 && (
                        <ModelInfo
                            model={model}
                            openUpdateSource={() => setUpdateSourceModal(true)}
                            canEditModels={canEditModels}
                        />
                    )}
                    {tabIndex === 1 && (
                        <ModelInputs
                            model={model}
                            updateModel={updateModel}
                            packageId={packageId}
                            moduleFileName={moduleFileName}
                        />
                    )}
                    {tabIndex === 2 && <RunModel model={model} packageId={packageId} moduleFileName={moduleFileName} />}
                </div>
            </Card>
        </div>
    );
};

ModelDetails.propTypes = {};
ModelDetails.defaultProps = {};

export default ModelDetails;
