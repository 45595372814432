import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import UnitMapping from './Unit';
import DatasetMapping from './Data';
import { getDefaultUnit } from './helpers';
import UnitModel from '../_model';
import UnitSelectors from '../_model/selectors';
import FeatureSelectors from '../../Data/_models/feature/selectors';
import { TabModel } from '../../Dashboards/_models';

import { Button, EditableTitle, FullScreenModal, Loader } from '../../shared';
import ErrorBoundary from '../../shared/ErrorBoundary';
import FilterBar from '../../Data/Filter/FilterBar';

require('./styles.scss');

export default function MappingModal() {
    const { units, tabs, dashboards } = useSelector((state) => state);
    const { unitId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const tabId = searchParams.get('tab');
    const unitType = searchParams.get('type');
    const defaultUnit = getDefaultUnit(unitType);

    const dashboard = Object.values(dashboards.byId).find((d) => d.tabs.find((tab) => tab._id === tabId));
    const unit = units.byId[unitId] || undefined; // null gave a warning from EditableTitle
    const tab = tabs.byId[tabId] || {};

    const unitFeatureIds = useSelector((state) => UnitSelectors.getFeatureIds(unitId)(state));
    const datasetIds = useSelector((state) => FeatureSelectors.getDatasetIds(unitFeatureIds)(state));

    // TODO: update this based on new dashboard logic?
    // TODO: make sure user has edit dashboard role in the project
    const redirectToDashboardList = (dashboards.hydrated && !dashboard) || (tab.hydrated && !unit);
    const dashboardId = dashboard?._id || '';

    const [stateUnit, setStateUnit] = useState({ hydrated: true });
    const [stateData, setStateData] = useState(unit?.data);
    const [selectedDatasetId, setSelectedDatasetId] = useState(null);

    const dispatch = useDispatch();
    const getTemporaryData = (id, obj, options) => dispatch(UnitModel.temporaryData(id, obj, options));

    const combinedUnit = { ...defaultUnit, ...unit, ...stateUnit, data: stateData };

    const selectType = (type) => {
        navigate(`${location.pathname}${location.search || '?'}&type=${type}`);
    };

    const getData = (temporaryQueries = {}, temporaryOptions = {}) => {
        const { type, subtype, vis } = combinedUnit;
        const { page, limit, jobId } = temporaryOptions;
        const { filters, orderBy } = temporaryQueries;
        const effectiveJobId = jobId || 'latest';
        setStateUnit((_stateUnit) => ({ ..._stateUnit, hydrated: false, shouldGetData: false }));
        getTemporaryData(
            unitId,
            { tabId, jobId: effectiveJobId, page, limit },
            { type, subtype, vis, filters, orderBy },
        ).then(({ data }) => {
            setStateData(data);
            setStateUnit((_stateUnit) => ({ ..._stateUnit, hydrated: true }));
        });
    };

    const firstDataset = datasetIds[0];

    useEffect(() => {
        // set selectedDataset to firstDataset when redux is populated
        if (!selectedDatasetId && firstDataset) {
            setSelectedDatasetId(firstDataset);
        }
    }, [selectedDatasetId, setSelectedDatasetId, firstDataset]);

    useEffect(() => {
        // fetch tab on initial load
        if (!tab?._id) {
            dispatch(TabModel.show(tabId));
        }
    }, [dispatch, tabId, tab?._id]);

    useEffect(() => {
        // fetch unit on initial load
        if (!unit?._id) {
            dispatch(UnitModel.show(unitId));
        }
    }, [dispatch, unit?._id, unitId]);

    useEffect(() => {
        // fetch unit data on initial load
        if (!stateData && !unit?.loading && combinedUnit.type && combinedUnit.hydrated) {
            getData();
        }
    });

    useEffect(() => {
        // fetch data after a change in the vis
        // doing this in a useEffect because `setStateUnit({ vis }).then(getData)` is not a thing
        if (stateUnit.shouldGetData) {
            getData();
        }
    });

    useEffect(() => {
        if (redirectToDashboardList) navigate(`/projects/${projectId}/dashboards`);
    }, [navigate, redirectToDashboardList, projectId]);

    const handleChange = (value, options = {}) => {
        setStateUnit((_stateUnit) => ({
            ..._stateUnit,
            ...value,
            shouldGetData: options.shouldGetData,
            hydrated: stateUnit.hydrated && !options.shouldGetData,
            // setting hydrated here prevents the chart from rendering before the new data is returned
            // without setting hydrated to false, there is an error when you remove the last dependent series of a chart, for instance
        }));
    };

    const handleFilterChange = (_, value) => {
        handleChange({ vis: { ...combinedUnit.vis, filters: value } }, { shouldGetData: true });
    };

    const navigateToDashboard = () => {
        if (dashboard.type === 'Report') {
            if (!tabId) {
                navigate(`/projects/${projectId}/dashboards/report/${dashboardId}`);
            } else {
                navigate(`/projects/${projectId}/dashboards/report/${dashboardId}/tabs/${tabId}`);
            }
        }
        if (dashboard.type !== 'Report') {
            if (!tabId) {
                navigate(`/projects/${projectId}/dashboards/${dashboardId}`);
            } else {
                navigate(`/projects/${projectId}/dashboards/${dashboardId}/tabs/${tabId}`);
            }
        }
    };

    const handleSave = () => {
        const { name, subtype, type, vis, data } = combinedUnit;

        // if (subtype === 'editable') {
        //     console.log('subtype', subtype);
        //     // PASS IN SOME FEATURE SO UNIT HAS FEATURE DATA FOR FUTURE RENDERING
        // }

        dispatch(UnitModel.update(unitId, { name, subtype, type, vis, data }));

        // LOGIC TO UPDATE DATASET IF EdiTable

        navigateToDashboard();
    };

    const handleCancel = () => {
        dispatch(UnitModel.removeData(unitId));
        navigateToDashboard();
    };

    if (redirectToDashboardList) return null;

    const showFilterBar = !['kpi', 'chart', 'table'].includes(unit?.type || unitType);

    const modalHeader = (
        <header className="MappingModal__header">
            <div className="MappingModal__title">
                <EditableTitle
                    onSubmit={(_, name) => handleChange({ name })}
                    defaultValue={unit?.name}
                    allowEmptyString
                    maxWidth={
                        showFilterBar
                            ? window.innerWidth * 0.3 // title takes up a third of the screen
                            : window.innerWidth - 247 // title takes everything except the buttons
                    }
                />
            </div>
            <div className="MappingModal__centerHeader">
                {showFilterBar && (
                    <FilterBar
                        id="filters"
                        value={combinedUnit?.vis?.filters || []}
                        onChange={handleFilterChange}
                        datasetIds={Array.from(new Set([selectedDatasetId, ...datasetIds]))} // send selectedDatasetId to allow for filtering in draft state
                    />
                )}
            </div>
            <div className="MappingModal__buttons">
                <Button gray onClick={handleCancel}>
                    Cancel
                </Button>
                <Button blue onClick={handleSave}>
                    Save changes
                </Button>
            </div>
        </header>
    );

    return (
        <ErrorBoundary>
            <FullScreenModal open className="MappingModal" header={modalHeader}>
                <div className="MappingModal__main">
                    {dashboardId && unit?.timeCreated ? (
                        <React.Fragment key={combinedUnit}>
                            <UnitMapping
                                unit={combinedUnit}
                                dashboardId={dashboardId}
                                onChange={handleChange}
                                getTempData={getData}
                                selectType={selectType}
                                datasetIds={datasetIds}
                                handleFilterChange={handleFilterChange}
                                selectedDatasetId={selectedDatasetId}
                            />
                            <DatasetMapping
                                selectedDatasetId={selectedDatasetId}
                                updateSelectedDataset={setSelectedDatasetId}
                            />
                        </React.Fragment>
                    ) : (
                        <Loader type="content" loader="content" />
                    )}
                </div>
            </FullScreenModal>
        </ErrorBoundary>
    );
}
